import React from 'react';

import { Flex, Text } from '@radix-ui/themes';


import {FooterComponent} from './Footers.jsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Headers() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='relative bg-teal-900 pb-12 md:pb-64 overflow-hidden'>
          <img
            className='absolute top-0 right-0 mr-40'
            src='aurora-assets/headers/dots-background-light.png'
            alt=''
          />
          <img
            className='absolute top-0 right-0'
            src='aurora-assets/headers/light-shadow-right.png'
            alt=''
          />
          <nav className='relative bg-transparent mb-16'>
            <div className='container mx-auto px-4'>
              <div className='relative flex h-24 items-center'>
                <a className='inline-block' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo-white.svg'
                    alt=''
                  />
                </a>
                <button variant="classic" className='lg:hidden navbar-burger flex items-center justify-center h-10 w-10 ml-auto border border-teal-900 bg-teal-200 bg-opacity-30 hover:bg-teal-700 rounded-lg'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3 5H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 12H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 19H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </button>
                <div className='hidden lg:flex ml-12 items-center'>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='#'
                  >
                    <div className='flex items-center'>
                      <span className='mr-3'>Product</span>
                      <svg
                        width={10}
                        height={6}
                        viewBox='0 0 10 6'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1 1L5 5L9 1'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='#'
                  >
                    Features
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='#'
                  >
                    Pricing
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400'
                    href='#'
                  >
                    Resources
                  </a>
                </div>
                <div className='hidden lg:block ml-auto'>
                  <a
                    className='inline-flex items-center justify-center h-10 mr-4 px-4 text-center text-sm text-white hover:text-teal-400 font-semibold transition duration-200'
                    href='#'
                  >
                    Login
                  </a>
                  <a
                    className='group inline-block justify-center p-1 text-center text-sm text-white font-semibold rounded-lg'
                    href='#'
                  >
                    <div className='inline-flex items-stretch h-10 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                      <div className='flex items-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                        <span>Get Started</span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </nav>
          <div className='relative container px-4 mx-auto'>
            <div className='max-w-sm sm:max-w-lg lg:max-w-2xl lg:pt-10 lg:pr-10 mx-auto lg:mx-0'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-semibold text-white mb-6'>
                <span>Your marketing assets,</span>
                <span className='text-transparent bg-clip-text bg-gradient-to-b from-yellowGreen-200 via-teal-400 to-teal-900'>
                  at your fingertips.
                </span>
              </h1>
              <p className='text-lg text-white mb-10'>
                Easily store, organize, and access valuable assets from
                anywhere.
              </p>
              <div className='xs:flex items-center max-w-md mb-6'>
                <input
                  className='w-full h-12 mb-3 xs:mb-0 xs:mr-3 p-3 text-gray-400 placeholder-gray-500 bg-teal-800 outline-none ring ring-transparent focus:ring-green-900 rounded-lg'
                  type='email'
                  placeholder='Your email'
                />
                <button variant="classic"
                  className='xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center h-12 px-5 text-center text-base text-teal-800 font-bold bg-white rounded-lg transition duration-300 focus:outline-none'
                  type='submit'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-600 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span>Request Access</span>
                </button>
              </div>
              <div className='flex flex-col xs:flex-row items-center'>
                <span className='block text-sm text-gray-400 opacity-50'>
                  30-days free trial
                </span>
                <span className='inline-block my-4 xs:my-0 xs:mx-4'>
                  <svg
                    width={12}
                    height={12}
                    viewBox='0 0 12 12'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g clipPath='url(#clip0_3008_28918)'>
                      <mask
                        id='mask0_3008_28918'
                        style={{ maskType: 'luminance' }}
                        maskUnits='userSpaceOnUse'
                        x={0}
                        y={0}
                        width={12}
                        height={12}
                      >
                        <path d='M12 0H0V12H12V0Z' fill='white' />
                      </mask>
                      <g mask='url(#mask0_3008_28918)'>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M0 6L4.57763e-07 0H6H12V6C8.6868 6 6.00078 3.3145 6 0.00143555C5.99922 3.3145 3.31322 6 0 6ZM6 12C6 8.68626 8.68632 6 12 6V12H6ZM6 12C6 8.68626 3.31371 6 0 6V12H6Z'
                          fill='#4B6354'
                        />
                      </g>
                    </g>
                  </svg>
                </span>
                <span className='block text-sm text-gray-400 opacity-50'>
                  No credit card required
                </span>
              </div>
            </div>
            <img
              className='hidden lg:block absolute top-0 right-0 mr-3 xl:mr-0 max-w-sm xl:max-w-xl w-full'
              src='aurora-assets/headers/header-right-template.png'
              alt=''
            />
            <img
              className='lg:hidden block mt-20 w-full max-w-lg mx-auto'
              src='aurora-assets/headers/header-right-template.png'
              alt=''
            />
          </div>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-teal-800 opacity-70' />
            <nav className='relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Product
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Features
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className='inline-block text-black' href='#'>
                      Resources
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200'
                  href='#'
                >
                  Login
                </a>
                <a
                  className='flex items-center justify-center h-12 px-4 text-center text-base text-white font-semibold bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg transition duration-200'
                  href='#'
                >
                  Get Started
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section className='bg-gray-900 pb-24'>
          <div className='hidden md:block relative h-16 px-8 bg-gray-900'>
            <img
              className='absolute bottom-0 left-1/2 transform -translate-x-1/2'
              src='aurora-assets/headers/shadow-bottom-green.png'
              alt=''
            />
            <div className='relative flex h-full items-center justify-center'>
              <span className='text-sm lg:text-base text-white'>
                This announcement bar can be used to inform visitors of
                something important
              </span>
              <div className='h-6 w-px mx-4 bg-white opacity-20' />
              <a
                className='inline-flex items-center font-semibold text-yellowGreen-500 hover:text-yellowGreen-800'
                href='#'
              >
                <span className='mr-2'>Read more</span>
                <svg
                  width={16}
                  height={16}
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M4.00016 8H12.3335M12.3335 8L8.3335 4M12.3335 8L8.3335 12'
                    stroke='currentColor'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </a>
            </div>
          </div>
          <nav className='relative mb-12 md:mb-24'>
            <div className='container mx-auto px-4'>
              <div className='relative flex h-24 items-center'>
                <a className='inline-block' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo-white.svg'
                    alt=''
                  />
                </a>
                <button variant="classic" className='lg:hidden navbar-burger flex items-center justify-center h-10 w-10 ml-auto border border-teal-900 bg-teal-200 bg-opacity-30 hover:bg-teal-700 rounded-lg'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3 5H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 12H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 19H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </button>
                <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden lg:flex items-center'>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='#'
                  >
                    <div className='flex items-center'>
                      <span className='mr-3'>Product</span>
                      <svg
                        width={10}
                        height={6}
                        viewBox='0 0 10 6'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1 1L5 5L9 1'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='#'
                  >
                    Features
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='#'
                  >
                    Pricing
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400'
                    href='#'
                  >
                    Resources
                  </a>
                </div>
                <div className='hidden lg:block ml-auto'>
                  <a
                    className='inline-flex items-center justify-center h-10 mr-4 px-4 text-center text-sm text-white hover:text-teal-400 font-semibold transition duration-200'
                    href='#'
                  >
                    Login
                  </a>
                  <a
                    className='group inline-block justify-center p-1 text-center text-sm text-white font-semibold rounded-lg'
                    href='#'
                  >
                    <div className='inline-flex items-stretch h-10 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                      <div className='flex items-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                        <span className='-mb-px'>Get Started</span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </nav>
          <div className='relative'>
            <img
              className='hidden lg:block absolute top-0 right-0 w-full max-w-lg xl:max-w-xl'
              src='aurora-assets/headers/header-dark-right-half-side.png'
              alt=''
            />
            <div className='container px-4 mx-auto'>
              <div className='max-w-sm sm:max-w-lg xl:max-w-2xl mx-auto lg:mx-0 lg:pt-10 lg:pr-10 pb-12 lg:pb-28'>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-bold text-white mb-6'>
                  Your marketing assets at your fingertips
                </h1>
                <p className='max-w-sm xl:max-w-none text-lg text-white mb-10'>
                  Easily store, organize, and access valuable assets from
                  anywhere.
                </p>
                <div className='flex flex-col xs:flex-row items-center mb-10'>
                  <a
                    className='group relative mb-4 xs:mb-0 xs:mr-8 flex w-full xs:w-auto items-center justify-center h-12 px-4 text-center text-base text-teal-800 font-bold bg-white rounded-lg transition duration-300'
                    href='#'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-700 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Try Aurora for Free</span>
                  </a>
                  <a
                    className='group inline-flex items-center font-bold text-white hover:text-teal-400 transition duration-300'
                    href='#'
                  >
                    <span className='mr-2'>Request a Demo</span>
                    <span>
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M7.5 5L12.5 10L7.5 15'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                  </a>
                </div>
                <div className='flex flex-col xs:flex-row items-center'>
                  <span className='block text-sm text-gray-400 opacity-50'>
                    30-days free trial
                  </span>
                  <span className='inline-block my-4 xs:my-0 mx-auto xs:mx-4'>
                    <svg
                      width={12}
                      height={12}
                      viewBox='0 0 12 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clipPath='url(#clip0_3008_28918)'>
                        <mask
                          id='mask0_3008_28918'
                          style={{ maskType: 'luminance' }}
                          maskUnits='userSpaceOnUse'
                          x={0}
                          y={0}
                          width={12}
                          height={12}
                        >
                          <path d='M12 0H0V12H12V0Z' fill='white' />
                        </mask>
                        <g mask='url(#mask0_3008_28918)'>
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M0 6L4.57763e-07 0H6H12V6C8.6868 6 6.00078 3.3145 6 0.00143555C5.99922 3.3145 3.31322 6 0 6ZM6 12C6 8.68626 8.68632 6 12 6V12H6ZM6 12C6 8.68626 3.31371 6 0 6V12H6Z'
                            fill='#4B6354'
                          />
                        </g>
                      </g>
                    </svg>
                  </span>
                  <span className='block text-sm text-gray-400 opacity-50'>
                    No credit card required
                  </span>
                </div>
              </div>
            </div>
            <img
              className='lg:hidden block w-full pl-8 ml-auto max-w-3xl'
              src='aurora-assets/headers/header-dark-right-half-side.png'
              alt=''
            />
          </div>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-teal-800 opacity-70' />
            <nav className='relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Product
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Features
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className='inline-block text-black' href='#'>
                      Resources
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200'
                  href='#'
                >
                  Login
                </a>
                <a
                  className='flex items-center justify-center h-12 px-4 text-center text-base text-white font-semibold bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg transition duration-200'
                  href='#'
                >
                  Get Started
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section className='bg-gray-900 pb-24'>
          <nav className='relative'>
            <div className='container mx-auto px-4'>
              <div className='relative flex h-24 items-center'>
                <a className='inline-block' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo-white.svg'
                    alt=''
                  />
                </a>
                <button variant="classic" className='lg:hidden navbar-burger flex items-center justify-center h-10 w-10 ml-auto border border-teal-900 bg-teal-200 bg-opacity-30 hover:bg-teal-700 rounded-lg'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3 5H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 12H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 19H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </button>
                <div className='hidden lg:flex ml-auto items-center'>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='#'
                  >
                    <div className='flex items-center'>
                      <span className='mr-3'>Product</span>
                      <svg
                        width={10}
                        height={6}
                        viewBox='0 0 10 6'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1 1L5 5L9 1'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='#'
                  >
                    Features
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='#'
                  >
                    Pricing
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400'
                    href='#'
                  >
                    Resources
                  </a>
                </div>
                <div className='hidden lg:block ml-12'>
                  <a
                    className='inline-flex items-center justify-center h-10 mr-4 px-4 text-center text-sm text-white hover:text-teal-400 font-semibold transition duration-200'
                    href='#'
                  >
                    Login
                  </a>
                  <a
                    className='group inline-block justify-center p-1 text-center text-sm text-white font-semibold rounded-lg'
                    href='#'
                  >
                    <div className='inline-flex items-stretch h-10 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                      <div className='flex items-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                        <span>Get Started</span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </nav>
          <div className='relative pt-12 md:pt-24'>
            <div className='container px-4 mx-auto'>
              <div className='relative max-w-lg md:max-w-3xl mx-auto text-center'>
                <h1 className='font-heading tracking-tight text-4xl xs:text-5xl md:text-7xl font-semibold text-white mb-6'>
                  <span>Your marketing assets,</span>
                  <span className='text-gray-400'>at your fingertips.</span>
                </h1>
                <p className='md:max-w-md lg:max-w-lg mx-auto text-lg text-gray-400 mb-10'>
                  Empowers marketing team to easily store, organize, and access
                  valuable assets from anywhere.
                </p>
                <div className='xs:flex items-center max-w-md mx-auto mb-6'>
                  <input
                    className='w-full h-12 mb-3 xs:mb-0 xs:mr-3 p-3 text-gray-400 placeholder-gray-500 bg-gray-800 outline-none ring ring-transparent focus:ring-yellowGreen-700 rounded-lg'
                    type='email'
                    placeholder='Your email'
                  />
                  <button variant="classic"
                    className='xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center px-5 h-12 font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
                    type='submit'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Get Started</span>
                  </button>
                </div>
                <div className='flex flex-col xs:flex-row items-center justify-center'>
                  <span className='block text-sm text-gray-400 opacity-50'>
                    30-days free trial
                  </span>
                  <span className='inline-block my-4 xs:my-0 mx-auto xs:mx-4'>
                    <svg
                      width={12}
                      height={12}
                      viewBox='0 0 12 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clipPath='url(#clip0_3008_28918)'>
                        <mask
                          id='mask0_3008_28918'
                          style={{ maskType: 'luminance' }}
                          maskUnits='userSpaceOnUse'
                          x={0}
                          y={0}
                          width={12}
                          height={12}
                        >
                          <path d='M12 0H0V12H12V0Z' fill='white' />
                        </mask>
                        <g mask='url(#mask0_3008_28918)'>
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M0 6L4.57763e-07 0H6H12V6C8.6868 6 6.00078 3.3145 6 0.00143555C5.99922 3.3145 3.31322 6 0 6ZM6 12C6 8.68626 8.68632 6 12 6V12H6ZM6 12C6 8.68626 3.31371 6 0 6V12H6Z'
                            fill='#4B6354'
                          />
                        </g>
                      </g>
                    </svg>
                  </span>
                  <span className='block text-sm text-gray-400 opacity-50'>
                    No credit card required
                  </span>
                </div>
              </div>
              <img
                className='block mx-auto sm:-mt-24 lg:-mt-32 xl:-mt-52'
                src='aurora-assets/headers/header-dark-theme-center-light-lines.png'
                alt=''
              />
            </div>
          </div>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-teal-800 opacity-70' />
            <nav className='relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Product
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Features
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className='inline-block text-black' href='#'>
                      Resources
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200'
                  href='#'
                >
                  Login
                </a>
                <a
                  className='flex items-center justify-center h-12 px-4 text-center text-base text-white font-semibold bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg transition duration-200'
                  href='#'
                >
                  Get Started
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section className='relative bg-gray-900 pb-12 md:pb-64 overflow-hidden'>
          <img
            className='absolute top-0 right-0 mr-40'
            src='aurora-assets/headers/dots-background-light.png'
            alt=''
          />
          <img
            className='absolute top-0 right-0'
            src='aurora-assets/headers/light-shadow-right.png'
            alt=''
          />
          <nav className='relative bg-transparent mb-16'>
            <div className='container mx-auto px-4'>
              <div className='relative flex h-24 items-center'>
                <a className='inline-block' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo-white.svg'
                    alt=''
                  />
                </a>
                <button variant="classic" className='lg:hidden navbar-burger flex items-center justify-center h-10 w-10 ml-auto border border-teal-900 bg-teal-200 bg-opacity-30 hover:bg-teal-700 rounded-lg'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3 5H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 12H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 19H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </button>
                <div className='hidden lg:flex ml-12 items-center'>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='#'
                  >
                    <div className='flex items-center'>
                      <span className='mr-3'>Product</span>
                      <svg
                        width={10}
                        height={6}
                        viewBox='0 0 10 6'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1 1L5 5L9 1'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='#'
                  >
                    Features
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='#'
                  >
                    Pricing
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400'
                    href='#'
                  >
                    Resources
                  </a>
                </div>
                <div className='hidden lg:block ml-auto'>
                  <a
                    className='inline-flex items-center justify-center h-10 mr-4 px-4 text-center text-sm text-white hover:text-teal-400 font-semibold transition duration-200'
                    href='#'
                  >
                    Login
                  </a>
                  <a
                    className='group inline-block justify-center p-1 text-center text-sm text-white font-semibold rounded-lg'
                    href='#'
                  >
                    <div className='inline-flex items-stretch h-10 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                      <div className='flex items-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                        <span>Get Started</span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </nav>
          <div className='relative container px-4 mx-auto'>
            <div className='max-w-sm sm:max-w-lg lg:max-w-2xl lg:pt-10 lg:pr-10 mx-auto lg:mx-0'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-semibold text-white mb-6'>
                <span>Your marketing assets,</span>
                <span className='text-transparent bg-clip-text bg-gradient-to-t from-yellowGreen-200 via-teal-700 to-teal-800'>
                  at your fingertips.
                </span>
              </h1>
              <p className='text-lg text-white mb-10'>
                Easily store, organize, and access valuable assets from
                anywhere.
              </p>
              <div className='xs:flex items-center max-w-md mb-6'>
                <input
                  className='w-full h-12 mb-3 xs:mb-0 xs:mr-3 p-3 text-gray-400 placeholder-gray-500 bg-gray-800 outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                  type='email'
                  placeholder='Your email'
                />
                <button variant="classic"
                  className='xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center h-12 px-5 text-center text-base text-teal-800 font-bold bg-white rounded-lg transition duration-300 focus:outline-none'
                  type='submit'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-700 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span>Request Access</span>
                </button>
              </div>
              <div className='flex flex-col xs:flex-row items-center'>
                <span className='block text-sm text-teal-400 opacity-50'>
                  30-days free trial
                </span>
                <span className='inline-block my-4 xs:my-0 xs:mx-4'>
                  <svg
                    width={12}
                    height={12}
                    viewBox='0 0 12 12'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g clipPath='url(#clip0_3008_28889)'>
                      <mask
                        id='mask0_3008_28889'
                        style={{ maskType: 'luminance' }}
                        maskUnits='userSpaceOnUse'
                        x={0}
                        y={0}
                        width={12}
                        height={12}
                      >
                        <path d='M12 0H0V12H12V0Z' fill='white' />
                      </mask>
                      <g mask='url(#mask0_3008_28889)'>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M0 6L4.57763e-07 0H6H12V6C8.6868 6 6.00078 3.3145 6 0.00143555C5.99922 3.3145 3.31322 6 0 6ZM6 12C6 8.68626 8.68632 6 12 6V12H6ZM6 12C6 8.68626 3.31371 6 0 6V12H6Z'
                          fill='#4B6354'
                        />
                      </g>
                    </g>
                  </svg>
                </span>
                <span className='block text-sm text-teal-400 opacity-50'>
                  No credit card required
                </span>
              </div>
            </div>
            <img
              className='hidden lg:block absolute top-0 right-0 mr-3 xl:mr-0 max-w-sm xl:max-w-xl w-full'
              src='aurora-assets/headers/header-dark-right-avatars.png'
              alt=''
            />
            <img
              className='lg:hidden block mt-20 w-full max-w-lg mx-auto'
              src='aurora-assets/headers/header-dark-right-avatars.png'
              alt=''
            />
          </div>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-teal-800 opacity-70' />
            <nav className='relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Product
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Features
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className='inline-block text-black' href='#'>
                      Resources
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200'
                  href='#'
                >
                  Login
                </a>
                <a
                  className='flex items-center justify-center h-12 px-4 text-center text-base text-white font-semibold bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg transition duration-200'
                  href='#'
                >
                  Get Started
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section className='bg-teal-900 pb-24'>
          <nav className='relative'>
            <div className='container mx-auto px-4'>
              <div className='relative flex h-24 items-center'>
                <a className='inline-block' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo-white.svg'
                    alt=''
                  />
                </a>
                <button variant="classic" className='lg:hidden navbar-burger flex items-center justify-center h-10 w-10 ml-auto border border-teal-900 bg-teal-200 bg-opacity-30 hover:bg-teal-700 rounded-lg'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3 5H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 12H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 19H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </button>
                <div className='hidden lg:flex ml-auto items-center'>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='#'
                  >
                    <div className='flex items-center'>
                      <span className='mr-3'>Product</span>
                      <svg
                        width={10}
                        height={6}
                        viewBox='0 0 10 6'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1 1L5 5L9 1'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='#'
                  >
                    Features
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='#'
                  >
                    Pricing
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400'
                    href='#'
                  >
                    Resources
                  </a>
                </div>
                <div className='hidden lg:block ml-12'>
                  <a
                    className='inline-flex items-center justify-center h-10 mr-4 px-4 text-center text-sm text-white hover:text-teal-400 font-semibold transition duration-200'
                    href='#'
                  >
                    Login
                  </a>
                  <a
                    className='group inline-block justify-center p-1 text-center text-sm text-white font-semibold rounded-lg'
                    href='#'
                  >
                    <div className='inline-flex items-stretch h-10 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                      <div className='flex items-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                        <span>Get Started</span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </nav>
          <div className='relative pt-12 md:pt-24'>
            <div className='container px-4 mx-auto'>
              <div className='relative max-w-lg md:max-w-3xl mx-auto text-center'>
                <h1 className='font-heading tracking-tight text-4xl xs:text-5xl md:text-7xl font-semibold text-white mb-6'>
                  <span>Your marketing assets,</span>
                  <span className='text-gray-400'>at your fingertips.</span>
                </h1>
                <p className='md:max-w-md lg:max-w-lg mx-auto text-lg text-gray-400 mb-10'>
                  Empowers marketing team to easily store, organize, and access
                  valuable assets from anywhere.
                </p>
                <div className='xs:flex items-center max-w-md mx-auto mb-6'>
                  <input
                    className='w-full h-12 mb-3 xs:mb-0 xs:mr-3 p-3 text-gray-400 placeholder-gray-500 bg-teal-800 outline-none ring ring-transparent focus:ring-green-900 rounded-lg'
                    type='email'
                    placeholder='Your email'
                  />
                  <button variant="classic"
                    className='xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300 focus:outline-none'
                    type='submit'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-600 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Get Started</span>
                  </button>
                </div>
                <div className='flex flex-col xs:flex-row items-center justify-center'>
                  <span className='block text-sm text-gray-400 opacity-50'>
                    30-days free trial
                  </span>
                  <span className='inline-block my-4 xs:my-0 mx-auto xs:mx-4'>
                    <svg
                      width={12}
                      height={12}
                      viewBox='0 0 12 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clipPath='url(#clip0_3008_28918)'>
                        <mask
                          id='mask0_3008_28918'
                          style={{ maskType: 'luminance' }}
                          maskUnits='userSpaceOnUse'
                          x={0}
                          y={0}
                          width={12}
                          height={12}
                        >
                          <path d='M12 0H0V12H12V0Z' fill='white' />
                        </mask>
                        <g mask='url(#mask0_3008_28918)'>
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M0 6L4.57763e-07 0H6H12V6C8.6868 6 6.00078 3.3145 6 0.00143555C5.99922 3.3145 3.31322 6 0 6ZM6 12C6 8.68626 8.68632 6 12 6V12H6ZM6 12C6 8.68626 3.31371 6 0 6V12H6Z'
                            fill='#4B6354'
                          />
                        </g>
                      </g>
                    </svg>
                  </span>
                  <span className='block text-sm text-gray-400 opacity-50'>
                    No credit card required
                  </span>
                </div>
              </div>
              <img
                className='block mx-auto sm:-mt-24 lg:-mt-32 xl:-mt-52'
                src='aurora-assets/headers/center-green-light-photo.png'
                alt=''
              />
            </div>
          </div>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-teal-800 opacity-70' />
            <nav className='relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Product
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Features
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className='inline-block text-black' href='#'>
                      Resources
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200'
                  href='#'
                >
                  Login
                </a>
                <a
                  className='flex items-center justify-center h-12 px-4 text-center text-base text-white font-semibold bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg transition duration-200'
                  href='#'
                >
                  Get Started
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section className='relative pb-12 md:pb-24 lg:pb-64 overflow-hidden'>
          <img
            className='absolute top-0 right-0'
            src='aurora-assets/headers/header-3-light-top-right.png'
            alt=''
          />
          <nav className='relative mb-16'>
            <div className='container mx-auto px-4'>
              <div className='relative flex h-24 items-center'>
                <a className='inline-block mr-12' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <button variant="classic" className='lg:hidden navbar-burger flex items-center justify-center h-10 w-10 ml-auto bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3 5H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 12H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 19H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </button>
                <div className='hidden lg:flex items-center'>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    <div className='flex items-center'>
                      <span className='mr-3'>Product</span>
                      <svg
                        width={10}
                        height={6}
                        viewBox='0 0 10 6'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1 1L5 5L9 1'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    Features
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    Pricing
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700'
                    href='#'
                  >
                    Resources
                  </a>
                </div>
                <div className='hidden lg:block ml-auto'>
                  <a
                    className='inline-flex items-center justify-center h-10 mr-4 px-4 text-center leading-loose text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold transition duration-200'
                    href='#'
                  >
                    Login
                  </a>
                  <a
                    className='inline-flex items-center justify-center h-10 px-4 text-center leading-loose text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold bg-white border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                    href='#'
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </nav>
          <div className='relative container px-4 mx-auto'>
            <div className='max-w-sm sm:max-w-lg md:max-w-2xl lg:pt-10 md:pr-10 mx-auto lg:mx-0'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl md:text-6xl font-semibold mb-6'>
                <span>Your marketing assets,</span>
                <span className='inline-block ml-2 text-teal-500'>
                  at your fingertips.
                </span>
              </h1>
              <p className='text-lg text-gray-500 mb-10'>
                Easily store, organize, and access valuable assets from
                anywhere.
              </p>
              <div className='xs:flex items-center max-w-md mb-6'>
                <input
                  className='w-full h-12 mb-3 xs:mb-0 xs:mr-3 p-3 text-gray-500 placeholder-gray-500 border border-gray-200 focus:border-yellowGreen-500 outline-none ring ring-transparent focus:ring-yellowGreen-500 rounded-lg'
                  type='email'
                  placeholder='Your email'
                />
                <button variant="classic"
                  className='xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300 focus:outline-none'
                  type='submit'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span>Request Access</span>
                </button>
              </div>
              <div className='flex flex-col xs:flex-row items-center'>
                <span className='block text-sm text-gray-500'>
                  30-days free trial
                </span>
                <span className='inline-block my-4 xs:my-0 xs:mx-4'>
                  <svg
                    width={12}
                    height={12}
                    viewBox='0 0 12 12'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g clipPath='url(#clip0_3008_28964)'>
                      <mask
                        id='mask0_3008_28964'
                        style={{ maskType: 'luminance' }}
                        maskUnits='userSpaceOnUse'
                        x={0}
                        y={0}
                        width={12}
                        height={12}
                      >
                        <path d='M12 0H0V12H12V0Z' fill='white' />
                      </mask>
                      <g mask='url(#mask0_3008_28964)'>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M0 6L4.57763e-07 0H6H12V6C8.6868 6 6.00078 3.3145 6 0.00143555C5.99922 3.3145 3.31322 6 0 6ZM6 12C6 8.68626 8.68632 6 12 6V12H6ZM6 12C6 8.68626 3.31371 6 0 6V12H6Z'
                          fill='#D2DAD5'
                        />
                      </g>
                    </g>
                  </svg>
                </span>
                <span className='block text-sm text-gray-500'>
                  No credit card required
                </span>
              </div>
            </div>
            <img
              className='hidden lg:block absolute top-0 right-0 mr-3 xl:mr-0 max-w-sm xl:max-w-xl w-full'
              src='aurora-assets/headers/header-right-template.png'
              alt=''
            />
            <img
              className='lg:hidden block mt-20 w-full max-w-lg mx-auto'
              src='aurora-assets/headers/header-right-template.png'
              alt=''
            />
          </div>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-teal-800 opacity-70' />
            <nav className='relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Product
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Features
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className='inline-block text-black' href='#'>
                      Resources
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='flex items-center justify-center h-10 mb-4 px-4 text-center text-sm text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-sm rounded-lg transition duration-200'
                  href='#'
                >
                  Login
                </a>
                <a
                  className='flex items-center justify-center h-10 px-4 text-center text-sm text-white font-semibold border border-yellowGreen-600 bg-yellowGreen-500 hover:bg-yellowGreen-600 rounded-lg transition duration-200'
                  href='#'
                >
                  Get Started
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section className='relative'>
          <img
            className='absolute top-0 left-0 w-full h-full'
            src='aurora-assets/headers/green-blur-bg-circle.png'
            alt=''
          />
          <nav className='relative bg-white'>
            <div className='container mx-auto px-4'>
              <div className='relative flex h-24 items-center'>
                <a className='inline-block' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <button variant="classic" className='lg:hidden navbar-burger flex items-center justify-center h-10 w-10 ml-auto bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3 5H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 12H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 19H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </button>
                <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden lg:flex items-center'>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    <div className='flex items-center'>
                      <span className='mr-3'>Product</span>
                      <svg
                        width={10}
                        height={6}
                        viewBox='0 0 10 6'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1 1L5 5L9 1'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    Features
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    Pricing
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700'
                    href='#'
                  >
                    Resources
                  </a>
                </div>
                <div className='hidden lg:block ml-auto'>
                  <a
                    className='inline-flex items-center justify-center h-10 mr-4 px-4 text-center leading-loose text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold transition duration-200'
                    href='#'
                  >
                    Login
                  </a>
                  <a
                    className='inline-flex items-center justify-center h-10 px-4 text-center leading-loose text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                    href='#'
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </nav>
          <div className='relative pt-12 sm:pt-24'>
            <div className='container px-4 mx-auto'>
              <div className='max-w-2xl xl:px-5 mb-14 mx-auto text-center'>
                <a
                  className='group relative inline-flex mb-6 items-center rounded-full shadow-md overflow-hidden'
                  href='#'
                >
                  <div className='animate-showLine absolute bottom-0 left-1/2 transform -translate-x-1/2 p-px mx-auto w-auto bg-gradient-to-r from-transparent via-yellowGreen-500 to-transparent group-hover:via-teal-600' />
                  <div className='flex flex-wrap sm:flex-nowrap items-center px-6 sm:px-4 py-4 sm:py-2 bg-white group-hover:bg-cyanGreen-50 transition duration-300 rounded-full'>
                    <span className='text-sm'>
                      Get ahead of the game with insights from 1200+ top
                      marketers.
                    </span>
                    <div className='mt-1 sm:mt-0 mx-auto sm:mr-0 sm:ml-2 flex items-center'>
                      <span className='mr-3 inline-block text-transparent bg-clip-text bg-gradient-to-b font-bold from-green-500 via-green-500 to-teal-400 group-hover:from-cyan-400 group-hover:via-cyan-700 group-hover:to-cyan-600'>
                        Read more
                      </span>
                      <span className='group-hover:hidden'>
                        <svg
                          width={11}
                          height={10}
                          viewBox='0 0 11 10'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M1.00004 5H9.33337M9.33337 5L5.33337 1M9.33337 5L5.33337 9'
                            stroke='url(#paint0_linear_3112_14890)'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <defs>
                            <linearGradient
                              id='paint0_linear_3112_14890'
                              x1='9.46358'
                              y1='-1.5'
                              x2='5.46163'
                              y2='9.11123'
                              gradientUnits='userSpaceOnUse'
                            >
                              <stop stopColor='#ACE348'>
                                <stop offset={1} stopColor='#25ACA0' />
                              </stop>
                            </linearGradient>
                          </defs>
                        </svg>
                      </span>
                      <span className='hidden group-hover:block'>
                        <svg
                          width={11}
                          height={10}
                          viewBox='0 0 11 10'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M1.00004 5H9.33337M9.33337 5L5.33337 1M9.33337 5L5.33337 9'
                            stroke='#25ACA0'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </a>
                <div className='max-w-lg sm:max-w-xl lg:max-w-none mx-auto'>
                  <h1 className='font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-bold mb-6'>
                    Your marketing assets, at your fingertips.
                  </h1>
                  <p className='max-w-lg mx-auto text-lg text-gray-700 mb-10'>
                    Empowers marketing team to easily store, organize, and
                    access valuable assets from anywhere.
                  </p>
                  <a
                    className='group relative flex xs:inline-flex items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                    href='#'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring-4 ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span className='mr-3'>Request Access</span>
                    <span className='transform group-hover:translate-x-1 transition duration-100'>
                      <svg
                        width={7}
                        height={12}
                        viewBox='0 0 7 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1 1L6 6L1 11'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
              <img
                className='block mx-auto'
                src='aurora-assets/headers/header-center.png'
                alt=''
              />
            </div>
          </div>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-teal-800 opacity-70' />
            <nav className='relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Product
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Features
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className='inline-block text-black' href='#'>
                      Resources
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200'
                  href='#'
                >
                  Login
                </a>
                <a
                  className='flex items-center justify-center h-12 px-4 text-center text-base text-white font-semibold bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg transition duration-200'
                  href='#'
                >
                  Get Started
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section className='bg-gray-50 pb-24'>
          <nav className='bg-white'>
            <div className='container mx-auto px-4'>
              <div className='relative flex h-24 items-center'>
                <a className='inline-block' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <button variant="classic" className='lg:hidden navbar-burger ml-auto flex items-center justify-center h-10 w-10 bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3 5H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 12H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 19H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </button>
                <div className='hidden lg:flex items-center ml-auto mr-12'>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    <div className='flex items-center'>
                      <span className='mr-3'>Product</span>
                      <svg
                        width={10}
                        height={6}
                        viewBox='0 0 10 6'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1 1L5 5L9 1'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    Features
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    Pricing
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700'
                    href='#'
                  >
                    Resources
                  </a>
                </div>
                <div className='hidden lg:block'>
                  <a
                    className='inline-flex items-center justify-center h-10 mr-4 px-4 text-center text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold transition duration-200'
                    href='#'
                  >
                    Login
                  </a>
                  <a
                    className='inline-flex items-center justify-center h-10 px-4 text-center text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                    href='#'
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </nav>
          <div className='relative pt-24'>
            <div className='container px-4 mx-auto'>
              <div className='max-w-lg md:max-w-3xl mx-auto text-center'>
                <h1 className='font-heading tracking-tight text-4xl xs:text-5xl md:text-7xl font-semibold mb-6'>
                  <span>Your marketing assets,</span>
                  <span className='text-gray-400'>at your fingertips.</span>
                </h1>
                <p className='md:max-w-md lg:max-w-lg mx-auto text-lg text-gray-500 mb-10'>
                  Empowers marketing team to easily store, organize, and access
                  valuable assets from anywhere.
                </p>
                <div className='xs:flex items-center max-w-md mx-auto mb-6'>
                  <input
                    className='w-full mb-3 xs:mb-0 xs:mr-3 p-3 text-gray-500 placeholder-gray-500 rounded-lg border border-gray-200 focus:border-green-200 outline-none ring ring-transparent focus:ring-green-300'
                    type='email'
                    placeholder='Your email'
                  />
                  <button variant="classic"
                    className='xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300 focus:outline-none'
                    type='submit'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring-4 ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Get Started</span>
                  </button>
                </div>
                <div className='flex flex-col xs:flex-row items-center justify-center'>
                  <span className='block text-sm text-gray-500'>
                    30-days free trial
                  </span>
                  <span className='inline-block my-4 xs:my-0 mx-auto xs:mx-4'>
                    <svg
                      width={12}
                      height={12}
                      viewBox='0 0 12 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clipPath='url(#clip0_3008_28964)'>
                        <mask
                          id='mask0_3008_28964'
                          style={{ maskType: 'luminance' }}
                          maskUnits='userSpaceOnUse'
                          x={0}
                          y={0}
                          width={12}
                          height={12}
                        >
                          <path d='M12 0H0V12H12V0Z' fill='white' />
                        </mask>
                        <g mask='url(#mask0_3008_28964)'>
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M0 6L4.57763e-07 0H6H12V6C8.6868 6 6.00078 3.3145 6 0.00143555C5.99922 3.3145 3.31322 6 0 6ZM6 12C6 8.68626 8.68632 6 12 6V12H6ZM6 12C6 8.68626 3.31371 6 0 6V12H6Z'
                            fill='#D2DAD5'
                          />
                        </g>
                      </g>
                    </svg>
                  </span>
                  <span className='block text-sm text-gray-500'>
                    No credit card required
                  </span>
                </div>
              </div>
              <img
                className='block mx-auto'
                src='aurora-assets/headers/header-photos-light-top.png'
                alt=''
              />
            </div>
          </div>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-teal-800 opacity-70' />
            <nav className='relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Product
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Features
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className='inline-block text-black' href='#'>
                      Resources
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200'
                  href='#'
                >
                  Login
                </a>
                <a
                  className='flex items-center justify-center h-12 px-4 text-center text-base text-white font-semibold bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg transition duration-200'
                  href='#'
                >
                  Get Started
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section className='relative pb-12 md:pb-64 overflow-hidden'>
          <img
            className='absolute top-0 right-0'
            src='aurora-assets/headers/header-3-light-top-right.png'
            alt=''
          />
          <nav className='relative mb-16 bg-white'>
            <div className='container mx-auto px-4'>
              <div className='relative flex h-24 items-center'>
                <a className='inline-block mr-12' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <button variant="classic" className='lg:hidden navbar-burger flex items-center justify-center h-10 w-10 ml-auto bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3 5H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 12H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 19H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </button>
                <div className='hidden lg:flex items-center'>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    <div className='flex items-center'>
                      <span className='mr-3'>Product</span>
                      <svg
                        width={10}
                        height={6}
                        viewBox='0 0 10 6'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1 1L5 5L9 1'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    Features
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    Pricing
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700'
                    href='#'
                  >
                    Resources
                  </a>
                </div>
                <div className='hidden lg:block ml-auto'>
                  <a
                    className='inline-flex items-center justify-center h-10 mr-4 px-4 text-center text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold transition duration-200'
                    href='#'
                  >
                    Login
                  </a>
                  <a
                    className='inline-flex items-center justify-center h-10 px-4 text-center text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold bg-white border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                    href='#'
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </nav>
          <div className='relative container px-4 mx-auto'>
            <div className='max-w-sm sm:max-w-lg lg:max-w-2xl lg:pt-10 lg:pr-10 mx-auto lg:mx-0'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-semibold mb-6'>
                <span>Your marketing assets,</span>
                <span className='text-teal-500'>at your fingertips.</span>
              </h1>
              <p className='text-lg text-gray-500 mb-10'>
                Easily store, organize, and access valuable assets from
                anywhere.
              </p>
              <div className='xs:flex items-center max-w-md mb-6'>
                <input
                  className='w-full h-12 mb-3 xs:mb-0 xs:mr-3 p-3 text-gray-500 placeholder-gray-500 rounded-lg border border-gray-200 focus:border-green-200 outline-none ring ring-transparent focus:ring-green-300'
                  type='email'
                  placeholder='Your email'
                />
                <button variant="classic"
                  className='xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300 focus:outline-none'
                  type='submit'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring-4 ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span>Request Access</span>
                </button>
              </div>
              <div className='flex flex-col xs:flex-row items-center'>
                <span className='block text-sm text-gray-500'>
                  30-days free trial
                </span>
                <span className='inline-block my-4 xs:my-0 xs:mx-4'>
                  <svg
                    width={12}
                    height={12}
                    viewBox='0 0 12 12'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g clipPath='url(#clip0_3008_28964)'>
                      <mask
                        id='mask0_3008_28964'
                        style={{ maskType: 'luminance' }}
                        maskUnits='userSpaceOnUse'
                        x={0}
                        y={0}
                        width={12}
                        height={12}
                      >
                        <path d='M12 0H0V12H12V0Z' fill='white' />
                      </mask>
                      <g mask='url(#mask0_3008_28964)'>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M0 6L4.57763e-07 0H6H12V6C8.6868 6 6.00078 3.3145 6 0.00143555C5.99922 3.3145 3.31322 6 0 6ZM6 12C6 8.68626 8.68632 6 12 6V12H6ZM6 12C6 8.68626 3.31371 6 0 6V12H6Z'
                          fill='#D2DAD5'
                        />
                      </g>
                    </g>
                  </svg>
                </span>
                <span className='block text-sm text-gray-500'>
                  No credit card required
                </span>
              </div>
            </div>
            <img
              className='hidden lg:block absolute top-0 right-0 mr-3 xl:mr-0 max-w-sm xl:max-w-xl w-full'
              src='aurora-assets/headers/header-right-template.png'
              alt=''
            />
            <img
              className='lg:hidden block mt-20 w-full max-w-lg mx-auto'
              src='aurora-assets/headers/header-right-template.png'
              alt=''
            />
          </div>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-teal-800 opacity-70' />
            <nav className='relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Product
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Features
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className='inline-block text-black' href='#'>
                      Resources
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200'
                  href='#'
                >
                  Login
                </a>
                <a
                  className='flex items-center justify-center h-12 px-4 text-center text-base text-white font-semibold bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg transition duration-200'
                  href='#'
                >
                  Get Started
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section className='relative bg-gray-50 pb-24'>
          <nav className='relative bg-gray-50'>
            <div className='container mx-auto px-4'>
              <div className='relative flex h-24 items-center'>
                <a className='inline-block' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <button variant="classic" className='lg:hidden navbar-burger flex items-center justify-center h-10 w-10 ml-auto bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3 5H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 12H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 19H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </button>
                <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden lg:flex items-center'>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    <div className='flex items-center'>
                      <span className='mr-3'>Product</span>
                      <svg
                        width={10}
                        height={6}
                        viewBox='0 0 10 6'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1 1L5 5L9 1'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    Features
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    Pricing
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700'
                    href='#'
                  >
                    Resources
                  </a>
                </div>
                <div className='hidden lg:block ml-auto'>
                  <a
                    className='inline-flex items-center justify-center h-10 mr-4 px-4 text-center text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold transition duration-200'
                    href='#'
                  >
                    Login
                  </a>
                  <a
                    className='inline-flex items-center justify-center h-10 px-4 text-center text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                    href='#'
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </nav>
          <div className='container px-4 mx-auto'>
            <div className='relative py-16 pb-40 md:pb-72 px-2 xs:px-8 bg-white rounded-3xl overflow-hidden'>
              <div className='relative max-w-lg lg:max-w-3xl mb-14 mx-auto text-center'>
                <div className='max-w-md md:max-w-2xl px-4 mx-auto'>
                  <h1 className='font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-bold mb-6'>
                    Your marketing assets, at your fingertips.
                  </h1>
                  <p className='max-w-lg mx-auto text-lg text-gray-700 mb-10'>
                    Empowers marketing team to easily store, organize, and
                    access valuable assets from anywhere.
                  </p>
                  <a
                    className='group relative flex xs:inline-flex items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                    href='#'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring-4 ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Get Started</span>
                  </a>
                </div>
              </div>
            </div>
            <img
              className='relative block mx-auto px-12 lg:px-8 -mt-40 md:-mt-64'
              src='aurora-assets/headers/header-2-center.png'
              alt=''
            />
          </div>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-teal-800 opacity-70' />
            <nav className='relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Product
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Features
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className='inline-block text-black' href='#'>
                      Resources
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200'
                  href='#'
                >
                  Login
                </a>
                <a
                  className='flex items-center justify-center h-12 px-4 text-center text-base text-white font-semibold bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg transition duration-200'
                  href='#'
                >
                  Get Started
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section className='relative bg-gray-50'>
          <img
            className='absolute top-0 left-0 w-full h-full'
            src='aurora-assets/headers/green-blur-bg-circle.png'
            alt=''
          />
          <nav className='relative bg-white'>
            <div className='container mx-auto px-4'>
              <div className='relative flex h-24 items-center'>
                <a className='inline-block' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <button variant="classic" className='lg:hidden navbar-burger flex items-center justify-center h-10 w-10 ml-auto bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3 5H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 12H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 19H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </button>
                <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden lg:flex items-center'>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    <div className='flex items-center'>
                      <span className='mr-3'>Product</span>
                      <svg
                        width={10}
                        height={6}
                        viewBox='0 0 10 6'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1 1L5 5L9 1'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    Features
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    Pricing
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700'
                    href='#'
                  >
                    Resources
                  </a>
                </div>
                <div className='hidden lg:block ml-auto'>
                  <a
                    className='inline-flex items-center justify-center h-10 mr-4 px-4 text-center text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold transition duration-200'
                    href='#'
                  >
                    Login
                  </a>
                  <a
                    className='inline-flex items-center justify-center h-10 px-4 text-center text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                    href='#'
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </nav>
          <div className='relative pt-24'>
            <div className='container px-4 mx-auto'>
              <div className='max-w-2xl mb-14 mx-auto text-center'>
                <a
                  className='group relative inline-flex mb-6 items-center rounded-full shadow-md overflow-hidden'
                  href='#'
                >
                  <div className='animate-showLine absolute bottom-0 left-1/2 transform -translate-x-1/2 p-px mx-auto w-auto bg-gradient-to-r from-transparent via-yellowGreen-500 to-transparent group-hover:via-teal-600' />
                  <div className='flex flex-wrap sm:flex-nowrap items-center px-6 sm:px-4 py-4 sm:py-2 bg-white group-hover:bg-cyanGreen-50 transition duration-300 rounded-full'>
                    <span className='text-sm'>
                      Get ahead of the game with insights from 1200+ top
                      marketers.
                    </span>
                    <div className='mt-1 sm:mt-0 mx-auto sm:mr-0 sm:ml-2 flex items-center'>
                      <span className='mr-3 inline-block text-transparent bg-clip-text bg-gradient-to-b font-bold from-green-500 via-green-500 to-teal-400 group-hover:from-cyan-400 group-hover:via-cyan-700 group-hover:to-cyan-600'>
                        Read more
                      </span>
                      <span className='group-hover:hidden'>
                        <svg
                          width={11}
                          height={10}
                          viewBox='0 0 11 10'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M1.00004 5H9.33337M9.33337 5L5.33337 1M9.33337 5L5.33337 9'
                            stroke='url(#paint0_linear_3112_14890)'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <defs>
                            <linearGradient
                              id='paint0_linear_3112_14890'
                              x1='9.46358'
                              y1='-1.5'
                              x2='5.46163'
                              y2='9.11123'
                              gradientUnits='userSpaceOnUse'
                            >
                              <stop stopColor='#ACE348'>
                                <stop offset={1} stopColor='#25ACA0' />
                              </stop>
                            </linearGradient>
                          </defs>
                        </svg>
                      </span>
                      <span className='hidden group-hover:block'>
                        <svg
                          width={11}
                          height={10}
                          viewBox='0 0 11 10'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M1.00004 5H9.33337M9.33337 5L5.33337 1M9.33337 5L5.33337 9'
                            stroke='#25ACA0'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </a>
                <div className='max-w-lg sm:max-w-xl lg:max-w-none mx-auto'>
                  <h1 className='font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-bold mb-6'>
                    Your marketing assets, at your fingertips.
                  </h1>
                  <p className='max-w-lg mx-auto text-lg text-gray-700 mb-10'>
                    Empowers marketing team to easily store, organize, and
                    access valuable assets from anywhere.
                  </p>
                  <a
                    className='group relative flex xs:inline-flex items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                    href='#'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring-4 ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span className='mr-3'>Request Access</span>
                    <span className='transform group-hover:translate-x-1 transition duration-100'>
                      <svg
                        width={7}
                        height={12}
                        viewBox='0 0 7 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1 1L6 6L1 11'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
              <img
                className='block mx-auto'
                src='aurora-assets/headers/header-center.png'
                alt=''
              />
            </div>
          </div>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-teal-800 opacity-70' />
            <nav className='relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Product
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Features
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className='inline-block text-black' href='#'>
                      Resources
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200'
                  href='#'
                >
                  Login
                </a>
                <a
                  className='flex items-center justify-center h-12 px-4 text-center text-base text-white font-semibold bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg transition duration-200'
                  href='#'
                >
                  Get Started
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section className='relative bg-gray-50'>
          <img
            className='absolute top-0 left-0 w-full h-full'
            src='aurora-assets/headers/green-blur-bg-circle.png'
            alt=''
          />
          <nav className='relative bg-white'>
            <div className='container mx-auto px-4'>
              <div className='relative flex h-24 items-center'>
                <a className='inline-block' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <button variant="classic" className='lg:hidden navbar-burger flex items-center justify-center h-10 w-10 ml-auto bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3 5H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 12H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 19H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </button>
                <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden lg:flex items-center'>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    <div className='flex items-center'>
                      <span className='mr-3'>Product</span>
                      <svg
                        width={10}
                        height={6}
                        viewBox='0 0 10 6'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1 1L5 5L9 1'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    Features
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700 mr-10'
                    href='#'
                  >
                    Pricing
                  </a>
                  <a
                    className='inline-block hover:text-yellowGreen-700'
                    href='#'
                  >
                    Resources
                  </a>
                </div>
                <div className='hidden lg:block ml-auto'>
                  <a
                    className='inline-flex items-center justify-center h-10 mr-4 px-4 text-center text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold transition duration-200'
                    href='#'
                  >
                    Login
                  </a>
                  <a
                    className='inline-flex items-center justify-center h-10 px-4 text-center text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                    href='#'
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </nav>
          <div className='relative pt-24'>
            <div className='container px-4 mx-auto'>
              <div className='max-w-2xl mb-14 mx-auto text-center'>
                <a
                  className='group relative inline-flex mb-6 items-center rounded-full shadow-md overflow-hidden'
                  href='#'
                >
                  <div className='animate-showLine absolute bottom-0 left-1/2 transform -translate-x-1/2 p-px mx-auto w-auto bg-gradient-to-r from-transparent via-yellowGreen-500 to-transparent group-hover:via-teal-600' />
                  <div className='flex flex-wrap sm:flex-nowrap items-center px-6 sm:px-4 py-4 sm:py-2 bg-white group-hover:bg-cyanGreen-50 transition duration-300 rounded-full'>
                    <span className='text-sm'>
                      Get ahead of the game with insights from 1200+ top
                      marketers.
                    </span>
                    <div className='mt-1 sm:mt-0 mx-auto sm:mr-0 sm:ml-2 flex items-center'>
                      <span className='mr-3 inline-block text-transparent bg-clip-text bg-gradient-to-b font-bold from-green-500 via-green-500 to-teal-400 group-hover:from-cyan-400 group-hover:via-cyan-700 group-hover:to-cyan-600'>
                        Read more
                      </span>
                      <span className='group-hover:hidden'>
                        <svg
                          width={11}
                          height={10}
                          viewBox='0 0 11 10'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M1.00004 5H9.33337M9.33337 5L5.33337 1M9.33337 5L5.33337 9'
                            stroke='url(#paint0_linear_3112_14890)'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <defs>
                            <linearGradient
                              id='paint0_linear_3112_14890'
                              x1='9.46358'
                              y1='-1.5'
                              x2='5.46163'
                              y2='9.11123'
                              gradientUnits='userSpaceOnUse'
                            >
                              <stop stopColor='#ACE348'>
                                <stop offset={1} stopColor='#25ACA0' />
                              </stop>
                            </linearGradient>
                          </defs>
                        </svg>
                      </span>
                      <span className='hidden group-hover:block'>
                        <svg
                          width={11}
                          height={10}
                          viewBox='0 0 11 10'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M1.00004 5H9.33337M9.33337 5L5.33337 1M9.33337 5L5.33337 9'
                            stroke='#25ACA0'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </a>
                <div className='max-w-lg sm:max-w-xl lg:max-w-none mx-auto'>
                  <h1 className='font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-bold mb-6'>
                    Your marketing assets, at your fingertips.
                  </h1>
                  <p className='max-w-lg mx-auto text-lg text-gray-700 mb-10'>
                    Empowers marketing team to easily store, organize, and
                    access valuable assets from anywhere.
                  </p>
                  <a
                    className='group relative flex xs:inline-flex items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                    href='#'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring-4 ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span className='mr-3'>Request Access</span>
                    <span className='transform group-hover:translate-x-1 transition duration-100'>
                      <svg
                        width={7}
                        height={12}
                        viewBox='0 0 7 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1 1L6 6L1 11'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
              <img
                className='block mx-auto'
                src='aurora-assets/headers/header-center.png'
                alt=''
              />
            </div>
          </div>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-teal-800 opacity-70' />
            <nav className='relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Product
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Features
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className='inline-block text-black' href='#'>
                      Resources
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200'
                  href='#'
                >
                  Login
                </a>
                <a
                  className='flex items-center justify-center h-12 px-4 text-center text-base text-white font-semibold bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg transition duration-200'
                  href='#'
                >
                  Get Started
                </a>
              </div>
            </nav>
          </div>
        </section>
      </>
    <FooterComponent /></React.Fragment>
  );
}

