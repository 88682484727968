import React from 'react';

import { Flex, Text } from '@radix-ui/themes';


import {FooterComponent} from './Footers.jsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Newsletter() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='relative py-12 md:py-24 lg:pt-32 bg-radial-dark-green'>
          <img
            className='absolute top-0 left-1/2 transform -translate-x-1/2'
            src='aurora-assets/newsletter/top-gradient-shadow-light.png'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <div className='max-w-6xl text-center mx-auto'>
              <div className='max-w-md mx-auto'>
                <h3 className='font-heading tracking-tight text-4xl font-bold text-white mb-4'>
                  Join Newsletter
                </h3>
                <p className='text-lg text-gray-200 mb-10'>
                  Get the latest updates delivered into your inbox.
                </p>
                <div className='xs:flex mb-5 items-center'>
                  <input
                    className='py-3 px-4 h-12 w-full mb-3 xs:mb-0 xs:mr-3 text-gray-200 placeholder-gray-200 bg-white bg-opacity-10 border border-white border-opacity-20 outline-none ring ring-transparent focus:ring-teal-600 rounded-lg'
                    type='email'
                    placeholder='Your email'
                  />
                  <button variant="classic"
                    className='xs:flex-shrink-0 group relative w-full xs:w-auto h-12 flex xs:inline-flex items-center justify-center px-4 p-px font-bold text-gray-700 bg-white rounded-lg transition-all duration-300 focus:outline-none'
                    type='submit'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-700 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Subscribe</span>
                  </button>
                </div>
                <span className='block text-gray-300 text-sm'>
                  No spam. You can unsubscribe anytime.
                </span>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <div className='relative bg-gray-50 border border-gray-200 rounded-xl overflow-hidden'>
              <div className='flex flex-wrap'>
                <div className='w-full md:w-7/12 xl:w-1/2 py-16 px-8 lg:px-18'>
                  <div className='max-w-sm mx-auto lg:max-w-lg lg:pr-10'>
                    <h3 className='font-heading tracking-tight text-4xl font-bold mb-4'>
                      Join Newsletter
                    </h3>
                    <p className='text-lg text-gray-500 mb-10'>
                      Get the latest updates delivered into your inbox monthly.
                      No spam. Unsubscribe anytime.
                    </p>
                    <div className='sm:flex mb-5 items-center'>
                      <input
                        className='py-3 px-4 h-12 w-full mb-3 sm:mb-0 sm:mr-3 text-gray-500 placeholder-gray-500 border border-gray-200 focus:border-yellowGreen-500 outline-none ring ring-transparent focus:ring-yellowGreen-500 rounded-lg'
                        type='email'
                        placeholder='Your email'
                      />
                      <button variant="classic"
                        className='xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300 focus:outline-none'
                        type='submit'
                      >
                        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                        <span>Subscribe</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className='relative w-full md:w-5/12 xl:w-1/2 py-32 lg:py-0'>
                  <div className='absolute top-0 left-0 w-24 h-24 md:mt-8 bg-white' />
                  <div className='absolute top-0 left-0 w-24 h-24 mt-32 md:ml-24 bg-white' />
                  <div className='absolute top-0 right-0 w-24 h-24 mr-24 xl:mr-32 -mt-8 lg:mt-8 bg-white' />
                  <div className='absolute bottom-0 right-0 w-24 h-24 mb-24 bg-white' />
                  <div className='hidden sm:block md:hidden absolute bottom-0 left-0 w-24 h-24 mb-32 ml-52 bg-white' />
                  <div className='absolute bottom-0 right-1/2 transform translate-x-1/2 -mb-5 w-24 h-24 bg-white' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className='fixed z-50 top-0 w-full py-24'>
          <div className='container px-4 mx-auto'>
            <div className='p-8 max-w-lg bg-gray-800 rounded-xl border-2 border-gray-100 shadow-md'>
              <h3 className='font-heading tracking-tight text-xl font-semibold text-white mb-3'>
                Join Newsletter
              </h3>
              <p className='text-gray-400'>
                Get the latest updates delivered into your inbox monthly.
              </p>
              <p className='text-gray-400 mb-10'>No spam.</p>
              <div className='sm:flex items-center'>
                <input
                  className='py-3 px-4 h-12 w-full mb-3 sm:mb-0 sm:mr-3 text-gray-400 placeholder-gray-400 bg-gray-800 border border-gray-700 outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                  type='email'
                  placeholder='Your email'
                />
                <button variant="classic"
                  className='sm:flex-shrink-0 group relative w-full sm:w-auto h-12 flex xs:inline-flex items-center justify-center px-4 p-px font-bold text-gray-700 bg-white rounded-lg transition-all duration-300 focus:outline-none'
                  type='submit'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-gray-500 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span>Subscribe</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <section className='py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <div className='relative bg-teal-900 rounded-xl overflow-hidden'>
              <div className='flex flex-wrap'>
                <div className='w-full md:w-7/12 xl:w-1/2 py-16 px-8 lg:px-18'>
                  <div className='max-w-sm mx-auto lg:max-w-lg lg:pr-10'>
                    <h3 className='font-heading tracking-tight text-4xl font-bold text-white mb-4'>
                      Join Newsletter
                    </h3>
                    <p className='text-lg text-gray-500 mb-10'>
                      Get the latest updates delivered into your inbox monthly.
                      No spam. Unsubscribe anytime.
                    </p>
                    <div className='sm:flex mb-5 items-center'>
                      <input
                        className='py-3 px-4 h-12 w-full mb-3 sm:mb-0 sm:mr-3 text-gray-400 placeholder-gray-400 bg-teal-800 border border-transparent focus:border-yellowGreen-800 rounded-lg shadow-sm outline-none ring ring-transparent focus:ring-yellowGreen-800'
                        type='email'
                        placeholder='Your email'
                      />
                      <button variant="classic"
                        className='sm:flex-shrink-0 group relative w-full sm:w-auto h-12 flex xs:inline-flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
                        type='submit'
                      >
                        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                        <span>Subscribe</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className='relative w-full md:w-5/12 xl:w-1/2 py-32 lg:py-0'>
                  <div className='absolute top-0 left-0 w-24 h-24 md:mt-8 bg-teal-800' />
                  <div className='absolute top-0 left-0 w-24 h-24 mt-32 md:ml-24 bg-teal-800' />
                  <div className='absolute top-0 right-0 w-24 h-24 mr-24 xl:mr-32 -mt-8 lg:mt-8 bg-teal-800' />
                  <div className='absolute bottom-0 right-0 w-24 h-24 mb-24 bg-teal-800' />
                  <div className='hidden sm:block md:hidden absolute bottom-0 left-0 w-24 h-24 mb-32 ml-52 bg-teal-800' />
                  <div className='absolute bottom-0 right-1/2 transform translate-x-1/2 -mb-5 w-24 h-24 bg-teal-800' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 sm:py-24 xl:py-32 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-md mx-auto lg:max-w-none'>
              <div className='flex flex-wrap items-center -mx-4'>
                <div className='w-full lg:w-1/2 px-4 mb-10 lg:mb-0'>
                  <div className='max-w-sm xl:max-w-none'>
                    <h3 className='font-heading tracking-tight text-4xl font-bold text-white'>
                      Get the latest updates from Aurora
                    </h3>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 px-4'>
                  <div className='max-w-md lg:ml-auto'>
                    <div className='sm:flex items-center'>
                      <input
                        className='py-3 px-4 h-12 w-full mb-3 sm:mb-0 sm:mr-3 text-gray-400 placeholder-gray-400 bg-teal-800 border border-transparent focus:border-yellowGreen-800 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-800'
                        type='email'
                        placeholder='Your email'
                      />
                      <button variant="classic"
                        className='sm:flex-shrink-0 group relative w-full sm:w-auto h-12 flex xs:inline-flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
                        type='submit'
                      >
                        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                        <span>Subscribe</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className='fixed z-50 top-0 w-full py-24'>
          <div className='container px-4 mx-auto'>
            <div className='p-8 max-w-lg bg-gray-800 rounded-xl border-2 border-gray-100 shadow-md'>
              <h3 className='font-heading tracking-tight text-xl font-semibold text-white mb-3'>
                Join Newsletter
              </h3>
              <p className='text-gray-400'>
                Get the latest updates delivered into your inbox monthly.
              </p>
              <p className='text-gray-400 mb-10'>No spam.</p>
              <div className='sm:flex items-center'>
                <input
                  className='py-3 px-4 h-12 w-full mb-3 sm:mb-0 sm:mr-3 text-gray-400 placeholder-gray-400 bg-gray-800 border border-gray-700 outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                  type='email'
                  placeholder='Your email'
                />
                <button variant="classic"
                  className='sm:flex-shrink-0 group relative w-full sm:w-auto h-12 flex xs:inline-flex items-center justify-center px-4 p-px font-bold text-gray-700 bg-white rounded-lg transition-all duration-300 focus:outline-none'
                  type='submit'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-gray-500 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span>Subscribe</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <section className='py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <div className='py-16 px-8 md:px-12 xl:px-18 bg-gray-50 border border-gray-200 rounded-xl'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/2 px-4 mb-10 lg:mb-0'>
                  <div>
                    <h3 className='font-heading tracking-tight text-4xl font-bold mb-4'>
                      Stay in the loop
                    </h3>
                    <p className='text-lg text-gray-500'>
                      Get the latest updates delivered into your inbox.
                    </p>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 px-4'>
                  <div className='lg:max-w-md lg:ml-auto'>
                    <div className='sm:flex mb-5 items-center'>
                      <input
                        className='py-3 px-4 h-12 w-full mb-3 sm:mb-0 sm:mr-3 text-gray-500 placeholder-gray-500 bg-white border border-gray-200 focus:border-yellowGreen-500 outline-none ring ring-transparent focus:ring-yellowGreen-500 rounded-lg'
                        type='email'
                        placeholder='Your email'
                      />
                      <button variant="classic"
                        className='xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300 focus:outline-none'
                        type='submit'
                      >
                        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                        <span>Subscribe</span>
                      </button>
                    </div>
                    <span className='block text-sm text-gray-400'>
                      No spam. You can unsubscribe anytime.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <div className='relative bg-gray-900 rounded-xl overflow-hidden'>
              <div className='flex flex-wrap'>
                <div className='w-full md:w-7/12 xl:w-1/2 py-16 px-8 lg:px-18'>
                  <div className='max-w-sm mx-auto lg:max-w-lg lg:pr-10'>
                    <h3 className='font-heading tracking-tight text-4xl font-bold text-white mb-4'>
                      Join Newsletter
                    </h3>
                    <p className='text-lg text-gray-500 mb-10'>
                      Get the latest updates delivered into your inbox monthly.
                      No spam. Unsubscribe anytime.
                    </p>
                    <div className='sm:flex mb-5 items-center'>
                      <input
                        className='py-3 px-4 h-12 w-full mb-3 sm:mb-0 sm:mr-3 text-gray-400 placeholder-gray-400 bg-gray-800 border border-gray-700 outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                        type='email'
                        placeholder='Your email'
                      />
                      <button variant="classic"
                        className='sm:flex-shrink-0 group relative w-full sm:w-auto h-12 flex sm:inline-flex items-center justify-center px-4 p-px font-bold text-gray-700 bg-white rounded-lg transition-all duration-300 focus:outline-none'
                        type='submit'
                      >
                        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-gray-500 animate-pulse group-hover:ring-0 transition duration-300' />
                        <span>Subscribe</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className='relative w-full md:w-5/12 xl:w-1/2 py-32 lg:py-0'>
                  <div className='absolute top-0 left-0 w-24 h-24 md:mt-8 bg-gray-800' />
                  <div className='absolute top-0 left-0 w-24 h-24 mt-32 md:ml-24 bg-gray-800' />
                  <div className='absolute top-0 right-0 w-24 h-24 mr-24 xl:mr-32 -mt-8 lg:mt-8 bg-gray-800' />
                  <div className='absolute bottom-0 right-0 w-24 h-24 mb-24 bg-gray-800' />
                  <div className='hidden sm:block md:hidden absolute bottom-0 left-0 w-24 h-24 mb-32 ml-52 bg-gray-800' />
                  <div className='absolute bottom-0 right-1/2 transform translate-x-1/2 -mb-5 w-24 h-24 bg-gray-800' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-gray-900'>
          <img
            className='absolute top-0 left-1/2 transform -translate-x-1/2 -ml-10'
            src='aurora-assets/newsletter/newsletter-light-top.png'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <div className='py-10 px-4 sm:px-10 text-center bg-gray-900 rounded-xl'>
                <div className='max-w-md mx-auto'>
                  <h3 className='font-heading tracking-tight text-4xl font-bold text-white mb-4'>
                    Join Newsletter
                  </h3>
                  <p className='text-lg text-gray-400 mb-10'>
                    Get the latest updates delivered into your inbox.
                  </p>
                  <div className='xs:flex mb-5 items-center'>
                    <input
                      className='py-3 px-4 h-12 w-full mb-3 xs:mb-0 xs:mr-3 text-gray-400 placeholder-gray-400 bg-gray-800 border border-gray-700 outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                      type='email'
                      placeholder='Your email'
                    />
                    <button variant="classic"
                      className='xs:flex-shrink-0 group relative w-full xs:w-auto h-12 flex xs:inline-flex items-center justify-center px-4 p-px font-bold text-gray-700 bg-white rounded-lg transition-all duration-300 focus:outline-none'
                      type='submit'
                    >
                      <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-gray-500 animate-pulse group-hover:ring-0 transition duration-300' />
                      <span>Subscribe</span>
                    </button>
                  </div>
                  <span className='block text-gray-400 text-sm'>
                    No spam. You can unsubscribe anytime.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    <FooterComponent /></React.Fragment>
  );
}

