import React from 'react';

import { Flex, Text, Button } from '@radix-ui/themes';


import {FooterComponent} from './Footers.jsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Team() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <h1 className='text-center font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-20'>
                Meet our team
              </h1>
              <div className='flex flex-wrap -mx-4 -mb-8'>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-6.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Armand Bretmann
                      </span>
                      <span className='block text-lg text-gray-300 font-medium'>
                        CEO &amp; Founder
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-5.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Ann Franci
                      </span>
                      <span className='block text-lg text-gray-300 font-medium'>
                        Co-Founder
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-4.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Ryan Rosser
                      </span>
                      <span className='block text-lg text-gray-300 font-medium'>
                        COO
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-3.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Marley Dokidis
                      </span>
                      <span className='block text-lg text-gray-300 font-medium'>
                        People Ops
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-2.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Kaiya Saris
                      </span>
                      <span className='block text-lg text-gray-300 font-medium'>
                        Talent
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-1.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Emerson Kenter
                      </span>
                      <span className='block text-lg text-gray-300 font-medium'>
                        Engineering
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-radial-dark-green'>
          <img
            className='absolute top-0 right-0'
            src='aurora-assets/team/light-green-shadow.png'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
              Meet our team
            </h1>
            <p className='text-lg text-gray-300 mb-20'>
              Our team has led and delivered large-scale software and design
              projects in multiple startups.
            </p>
            <div className='flex flex-wrap -mx-4 -mb-8'>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-6.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold text-white mb-2'>
                      Armand Bretmann
                    </span>
                    <span className='block text-lg text-gray-300 font-medium'>
                      CEO &amp; Founder
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-5.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold text-white mb-2'>
                      Ann Franci
                    </span>
                    <span className='block text-lg text-gray-300 font-medium'>
                      Co-Founder
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-4.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold text-white mb-2'>
                      Ryan Rosser
                    </span>
                    <span className='block text-lg text-gray-300 font-medium'>
                      COO
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-7.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold text-white mb-2'>
                      Jakob Gouse
                    </span>
                    <span className='block text-lg text-gray-300 font-medium'>
                      Design
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-8.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold text-white mb-2'>
                      Tiana Culhane
                    </span>
                    <span className='block text-lg text-gray-300 font-medium'>
                      Customer Experience
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-3.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold text-white mb-2'>
                      Marley Dokidis
                    </span>
                    <span className='block text-lg text-gray-300 font-medium'>
                      People Ops
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-2.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold text-white mb-2'>
                      Kaiya Saris
                    </span>
                    <span className='block text-lg text-gray-300 font-medium'>
                      Talent
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-1.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold text-white mb-2'>
                      Emerson Kenter
                    </span>
                    <span className='block text-lg text-gray-300 font-medium'>
                      Engineering
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <h1 className='text-center font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-20'>
              Meet our team
            </h1>
            <div className='flex flex-wrap -mx-4 -mb-8'>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-8.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Armand Bretmann
                    </span>
                    <span className='block text-lg text-gray-200 font-medium'>
                      CEO &amp; Founder
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-7.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Ann Franci
                    </span>
                    <span className='block text-lg text-gray-200 font-medium'>
                      Co-Founder
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-6.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Ryan Rosser
                    </span>
                    <span className='block text-lg text-gray-200 font-medium'>
                      COO
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-5.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Jakob Gouse
                    </span>
                    <span className='block text-lg text-gray-200 font-medium'>
                      Design
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-4.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Tiana Culhane
                    </span>
                    <span className='block text-lg text-gray-200 font-medium'>
                      Customer Experience
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-3.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Kaiya Saris
                    </span>
                    <span className='block text-lg text-gray-200 font-medium'>
                      Talent
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-2.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Emerson Kenter
                    </span>
                    <span className='block text-lg text-gray-200 font-medium'>
                      Engineering
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-1.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Marley Dokidis
                    </span>
                    <span className='block text-lg text-gray-200 font-medium'>
                      People Ops
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <h1 className='text-center font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-20'>
              Meet our team
            </h1>
            <div className='flex flex-wrap -mx-4 -mb-8'>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-8.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Armand Bretmann
                    </span>
                    <span className='block text-lg text-gray-400 font-medium'>
                      CEO &amp; Founder
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-7.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Ann Franci
                    </span>
                    <span className='block text-lg text-gray-400 font-medium'>
                      Co-Founder
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-6.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Ryan Rosser
                    </span>
                    <span className='block text-lg text-gray-400 font-medium'>
                      COO
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-5.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Jakob Gouse
                    </span>
                    <span className='block text-lg text-gray-400 font-medium'>
                      Design
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-4.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Tiana Culhane
                    </span>
                    <span className='block text-lg text-gray-400 font-medium'>
                      Customer Experience
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-3.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Kaiya Saris
                    </span>
                    <span className='block text-lg text-gray-400 font-medium'>
                      Talent
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-2.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Emerson Kenter
                    </span>
                    <span className='block text-lg text-gray-400 font-medium'>
                      Engineering
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-1.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Marley Dokidis
                    </span>
                    <span className='block text-lg text-gray-400 font-medium'>
                      People Ops
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <h1 className='text-center font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-20'>
                Meet our team
              </h1>
              <div className='flex flex-wrap -mx-4 -mb-8'>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-6.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Armand Bretmann
                      </span>
                      <span className='block text-lg text-gray-300 font-medium'>
                        CEO &amp; Founder
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-5.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Ann Franci
                      </span>
                      <span className='block text-lg text-gray-300 font-medium'>
                        Co-Founder
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-4.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Ryan Rosser
                      </span>
                      <span className='block text-lg text-gray-300 font-medium'>
                        COO
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-3.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Marley Dokidis
                      </span>
                      <span className='block text-lg text-gray-300 font-medium'>
                        People Ops
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-2.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Kaiya Saris
                      </span>
                      <span className='block text-lg text-gray-300 font-medium'>
                        Talent
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-1.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Emerson Kenter
                      </span>
                      <span className='block text-lg text-gray-300 font-medium'>
                        Engineering
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <div className='max-w-lg text-center mx-auto mb-20'>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                  Meet our team
                </h1>
                <p className='text-lg text-gray-400'>
                  Our team has led and delivered large-scale software and design
                  projects in multiple startups.
                </p>
              </div>
              <div className='flex flex-wrap -mx-4 -mb-8'>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-gray-800 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-6.png'
                      alt=''
                    />
                    <div className='text-center'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Armand Bretmann
                      </span>
                      <span className='block text-lg text-gray-400 font-medium'>
                        CEO &amp; Founder
                      </span>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-gray-800 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-5.png'
                      alt=''
                    />
                    <div className='text-center'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Ann Franci
                      </span>
                      <span className='block text-lg text-gray-400 font-medium'>
                        Co-Founder
                      </span>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-gray-800 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-4.png'
                      alt=''
                    />
                    <div className='text-center'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Ryan Rosser
                      </span>
                      <span className='block text-lg text-gray-400 font-medium'>
                        COO
                      </span>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-gray-800 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-3.png'
                      alt=''
                    />
                    <div className='text-center'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Marley Dokidis
                      </span>
                      <span className='block text-lg text-gray-400 font-medium'>
                        People Ops
                      </span>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-gray-800 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-2.png'
                      alt=''
                    />
                    <div className='text-center'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Kaiya Saris
                      </span>
                      <span className='block text-lg text-gray-400 font-medium'>
                        Talent
                      </span>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-gray-800 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-1.png'
                      alt=''
                    />
                    <div className='text-center'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Emerson Kenter
                      </span>
                      <span className='block text-lg text-gray-400 font-medium'>
                        Engineering
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-teal-900'>
          <img
            className='absolute top-0 right-0'
            src='aurora-assets/team/light-green-shadow.png'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
              Meet our team
            </h1>
            <p className='text-lg text-gray-400 mb-20'>
              Our team has led and delivered large-scale software and design
              projects in multiple startups.
            </p>
            <div className='flex flex-wrap -mx-4 -mb-8'>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-6.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Armand Bretmann
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      CEO &amp; Founder
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-5.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Ann Franci
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      Co-Founder
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-4.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Ryan Rosser
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      COO
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-7.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Jakob Gouse
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      Design
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-8.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Tiana Culhane
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      Customer Experience
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-3.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Marley Dokidis
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      People Ops
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-2.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Kaiya Saris
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      Talent
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-1.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Emerson Kenter
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      Engineering
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <h1 className='text-center font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-20'>
              Meet our team
            </h1>
            <div className='flex flex-wrap -mx-4 -mb-8'>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-8.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Armand Bretmann
                    </span>
                    <span className='block text-lg text-gray-400 font-medium'>
                      CEO &amp; Founder
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-7.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Ann Franci
                    </span>
                    <span className='block text-lg text-gray-400 font-medium'>
                      Co-Founder
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-6.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Ryan Rosser
                    </span>
                    <span className='block text-lg text-gray-400 font-medium'>
                      COO
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-5.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Jakob Gouse
                    </span>
                    <span className='block text-lg text-gray-400 font-medium'>
                      Design
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-4.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Tiana Culhane
                    </span>
                    <span className='block text-lg text-gray-400 font-medium'>
                      Customer Experience
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-3.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Kaiya Saris
                    </span>
                    <span className='block text-lg text-gray-400 font-medium'>
                      Talent
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-2.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Emerson Kenter
                    </span>
                    <span className='block text-lg text-gray-400 font-medium'>
                      Engineering
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8'>
                  <div className='relative h-40 w-40 mb-8 mx-auto p-2'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-contain'
                      src='aurora-assets/team/circle-avatar-green-dark.svg'
                      alt=''
                    />
                    <img
                      className='block mx-auto'
                      src='aurora-assets/team/avatar-gray-1.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <span className='block text-2xl font-semibold text-white mb-2'>
                      Marley Dokidis
                    </span>
                    <span className='block text-lg text-gray-400 font-medium'>
                      People Ops
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <div className='max-w-lg mx-auto mb-24 text-center'>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                  Meet our team
                </h1>
                <p className='text-lg text-gray-400'>
                  Our team has led and delivered large-scale software and design
                  projects in multiple startups.
                </p>
              </div>
              <div className='flex flex-wrap -mx-4 -mb-8'>
                <div className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-8'>
                  <div className='p-4'>
                    <img
                      className='block w-full mb-6 mx-auto'
                      src='aurora-assets/team/avatar-square-darkgreen8.png'
                      alt=''
                    />
                    <div>
                      <span className='block text-xl text-white font-semibold mb-2'>
                        Armand Bretmann
                      </span>
                      <div className='flex items-center justify-between'>
                        <span className='block text-gray-400 font-medium'>
                          CEO &amp; Founder
                        </span>
                        <div className='flex items-center justify-center'>
                          <a
                            className='inline-block text-gray-400 hover:text-yellowGreen-600 mr-2'
                            href='#'
                          >
                            <svg
                              width={25}
                              height={20}
                              viewBox='0 0 25 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2984_23486)'>
                                <path
                                  d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                          </a>
                          <a
                            className='inline-block text-gray-400 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <svg
                              width={25}
                              height={20}
                              viewBox='0 0 25 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M7.33398 17V13.5V10'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M7.33398 7.01L7.34398 6.99889'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-8'>
                  <div className='p-4'>
                    <img
                      className='block w-full mb-6 mx-auto'
                      src='aurora-assets/team/avatar-square-darkgreen7.png'
                      alt=''
                    />
                    <div>
                      <span className='block text-xl text-white font-semibold mb-2'>
                        Ann Franci
                      </span>
                      <div className='flex items-center justify-between'>
                        <span className='block text-gray-400 font-medium'>
                          Co-Founder
                        </span>
                        <div className='flex items-center justify-center'>
                          <a
                            className='inline-block text-gray-400 hover:text-yellowGreen-600 mr-2'
                            href='#'
                          >
                            <svg
                              width={25}
                              height={20}
                              viewBox='0 0 25 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2984_23486)'>
                                <path
                                  d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                          </a>
                          <a
                            className='inline-block text-gray-400 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <svg
                              width={25}
                              height={20}
                              viewBox='0 0 25 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M7.33398 17V13.5V10'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M7.33398 7.01L7.34398 6.99889'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-8'>
                  <div className='p-4'>
                    <img
                      className='block w-full mb-6 mx-auto'
                      src='aurora-assets/team/avatar-square-darkgreen6.png'
                      alt=''
                    />
                    <div>
                      <span className='block text-xl text-white font-semibold mb-2'>
                        Ryan Rosser
                      </span>
                      <div className='flex items-center justify-between'>
                        <span className='block text-gray-400 font-medium'>
                          COO
                        </span>
                        <div className='flex items-center justify-center'>
                          <a
                            className='inline-block text-gray-400 hover:text-yellowGreen-600 mr-2'
                            href='#'
                          >
                            <svg
                              width={25}
                              height={20}
                              viewBox='0 0 25 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2984_23486)'>
                                <path
                                  d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                          </a>
                          <a
                            className='inline-block text-gray-400 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <svg
                              width={25}
                              height={20}
                              viewBox='0 0 25 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M7.33398 17V13.5V10'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M7.33398 7.01L7.34398 6.99889'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-8'>
                  <div className='p-4'>
                    <img
                      className='block w-full mb-6 mx-auto'
                      src='aurora-assets/team/avatar-square-darkgreen5.png'
                      alt=''
                    />
                    <div>
                      <span className='block text-xl text-white font-semibold mb-2'>
                        Jakob Gouse
                      </span>
                      <div className='flex items-center justify-between'>
                        <span className='block text-gray-400 font-medium'>
                          Design
                        </span>
                        <div className='flex items-center justify-center'>
                          <a
                            className='inline-block text-gray-400 hover:text-yellowGreen-600 mr-2'
                            href='#'
                          >
                            <svg
                              width={25}
                              height={20}
                              viewBox='0 0 25 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2984_23486)'>
                                <path
                                  d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                          </a>
                          <a
                            className='inline-block text-gray-400 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <svg
                              width={25}
                              height={20}
                              viewBox='0 0 25 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M7.33398 17V13.5V10'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M7.33398 7.01L7.34398 6.99889'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-8'>
                  <div className='p-4'>
                    <img
                      className='block w-full mb-6 mx-auto'
                      src='aurora-assets/team/avatar-square-darkgreen4.png'
                      alt=''
                    />
                    <div>
                      <span className='block text-xl text-white font-semibold mb-2'>
                        Tiana Culhane
                      </span>
                      <div className='flex items-center justify-between'>
                        <span className='block text-gray-400 font-medium'>
                          Customer
                        </span>
                        <div className='flex items-center justify-center'>
                          <a
                            className='inline-block text-gray-400 hover:text-yellowGreen-600 mr-2'
                            href='#'
                          >
                            <svg
                              width={25}
                              height={20}
                              viewBox='0 0 25 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2984_23486)'>
                                <path
                                  d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                          </a>
                          <a
                            className='inline-block text-gray-400 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <svg
                              width={25}
                              height={20}
                              viewBox='0 0 25 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M7.33398 17V13.5V10'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M7.33398 7.01L7.34398 6.99889'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-8'>
                  <div className='p-4'>
                    <img
                      className='block w-full mb-6 mx-auto'
                      src='aurora-assets/team/avatar-square-darkgreen3.png'
                      alt=''
                    />
                    <div>
                      <span className='block text-xl text-white font-semibold mb-2'>
                        Kaiya Saris
                      </span>
                      <div className='flex items-center justify-between'>
                        <span className='block text-gray-400 font-medium'>
                          Talent
                        </span>
                        <div className='flex items-center justify-center'>
                          <a
                            className='inline-block text-gray-400 hover:text-yellowGreen-600 mr-2'
                            href='#'
                          >
                            <svg
                              width={25}
                              height={20}
                              viewBox='0 0 25 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2984_23486)'>
                                <path
                                  d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                          </a>
                          <a
                            className='inline-block text-gray-400 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <svg
                              width={25}
                              height={20}
                              viewBox='0 0 25 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M7.33398 17V13.5V10'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M7.33398 7.01L7.34398 6.99889'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-8'>
                  <div className='p-4'>
                    <img
                      className='block w-full mb-6 mx-auto'
                      src='aurora-assets/team/avatar-square-darkgreen2.png'
                      alt=''
                    />
                    <div>
                      <span className='block text-xl text-white font-semibold mb-2'>
                        Emerson Kenter
                      </span>
                      <div className='flex items-center justify-between'>
                        <span className='block text-gray-400 font-medium'>
                          Engineering
                        </span>
                        <div className='flex items-center justify-center'>
                          <a
                            className='inline-block text-gray-400 hover:text-yellowGreen-600 mr-2'
                            href='#'
                          >
                            <svg
                              width={25}
                              height={20}
                              viewBox='0 0 25 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2984_23486)'>
                                <path
                                  d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                          </a>
                          <a
                            className='inline-block text-gray-400 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <svg
                              width={25}
                              height={20}
                              viewBox='0 0 25 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M7.33398 17V13.5V10'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M7.33398 7.01L7.34398 6.99889'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-8'>
                  <div className='p-4'>
                    <img
                      className='block w-full mb-6 mx-auto'
                      src='aurora-assets/team/avatar-square-darkgreen1.png'
                      alt=''
                    />
                    <div>
                      <span className='block text-xl text-white font-semibold mb-2'>
                        Marley Dokidis
                      </span>
                      <div className='flex items-center justify-between'>
                        <span className='block text-gray-400 font-medium'>
                          People Ops
                        </span>
                        <div className='flex items-center justify-center'>
                          <a
                            className='inline-block text-gray-400 hover:text-yellowGreen-600 mr-2'
                            href='#'
                          >
                            <svg
                              width={25}
                              height={20}
                              viewBox='0 0 25 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2984_23486)'>
                                <path
                                  d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                          </a>
                          <a
                            className='inline-block text-gray-400 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <svg
                              width={25}
                              height={20}
                              viewBox='0 0 25 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M7.33398 17V13.5V10'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M7.33398 7.01L7.34398 6.99889'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-teal-900'>
          <img
            className='absolute top-0 right-0'
            src='aurora-assets/team/light-green-shadow.png'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
              Meet our team
            </h1>
            <p className='text-lg text-gray-400 mb-20'>
              Our team has led and delivered large-scale software and design
              projects in multiple startups.
            </p>
            <div className='flex flex-wrap -mx-4 -mb-8'>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-6.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Armand Bretmann
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      CEO &amp; Founder
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-5.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Ann Franci
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      Co-Founder
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-4.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Ryan Rosser
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      COO
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-7.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Jakob Gouse
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      Design
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-8.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Tiana Culhane
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      Customer Experience
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-3.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Marley Dokidis
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      People Ops
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-2.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Kaiya Saris
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      Talent
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8'>
                <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                  <img
                    className='block h-36 w-36 mb-8 mx-auto'
                    src='aurora-assets/team/avatar-1.png'
                    alt=''
                  />
                  <div className='text-center'>
                    <span className='block text-2xl font-bold mb-2'>
                      Emerson Kenter
                    </span>
                    <span className='block text-lg text-gray-700 font-medium'>
                      Engineering
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <h1 className='text-center font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-20'>
                Meet our team
              </h1>
              <div className='flex flex-wrap -mx-4 -mb-8'>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 border-2 border-gray-800 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-6.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Armand Bretmann
                      </span>
                      <span className='block text-lg text-gray-500 font-medium'>
                        CEO &amp; Founder
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 border-2 border-gray-800 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-5.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Ann Franci
                      </span>
                      <span className='block text-lg text-gray-500 font-medium'>
                        Co-Founder
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 border-2 border-gray-800 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-4.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Ryan Rosser
                      </span>
                      <span className='block text-lg text-gray-500 font-medium'>
                        COO
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 border-2 border-gray-800 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-3.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Marley Dokidis
                      </span>
                      <span className='block text-lg text-gray-500 font-medium'>
                        People Ops
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 border-2 border-gray-800 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-2.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Kaiya Saris
                      </span>
                      <span className='block text-lg text-gray-500 font-medium'>
                        Talent
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 border-2 border-gray-800 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-1.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold text-white mb-2'>
                        Emerson Kenter
                      </span>
                      <span className='block text-lg text-gray-500 font-medium'>
                        Engineering
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-white hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <h1 className='text-center font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-20'>
                Meet our team
              </h1>
              <div className='flex flex-wrap -mx-4 -mb-8'>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-6.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold mb-2'>
                        Armand Bretmann
                      </span>
                      <span className='block text-lg text-gray-700 font-medium'>
                        CEO &amp; Founder
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-gray-900 hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-gray-900 hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-5.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold mb-2'>
                        Ann Franci
                      </span>
                      <span className='block text-lg text-gray-700 font-medium'>
                        Co-Founder
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-gray-900 hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-gray-900 hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-4.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold mb-2'>
                        Ryan Rosser
                      </span>
                      <span className='block text-lg text-gray-700 font-medium'>
                        COO
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-gray-900 hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-gray-900 hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-3.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold mb-2'>
                        Marley Dokidis
                      </span>
                      <span className='block text-lg text-gray-700 font-medium'>
                        People Ops
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-gray-900 hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-gray-900 hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-2.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold mb-2'>
                        Kaiya Saris
                      </span>
                      <span className='block text-lg text-gray-700 font-medium'>
                        Talent
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-gray-900 hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-gray-900 hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-8'>
                  <div className='p-8 bg-white border-2 border-gray-100 rounded-xl'>
                    <img
                      className='block h-36 w-36 mb-8 mx-auto'
                      src='aurora-assets/team/avatar-1.png'
                      alt=''
                    />
                    <div className='text-center mb-4'>
                      <span className='block text-2xl font-bold mb-2'>
                        Emerson Kenter
                      </span>
                      <span className='block text-lg text-gray-700 font-medium'>
                        Engineering
                      </span>
                    </div>
                    <div className='flex items-center justify-center'>
                      <a
                        className='inline-block text-gray-900 hover:text-yellowGreen-600 mr-4'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2984_23486)'>
                            <path
                              d='M23.334 3.01006C23.334 3.01006 21.3161 4.20217 20.194 4.54006C19.5917 3.84757 18.7913 3.35675 17.901 3.13398C17.0107 2.91122 16.0735 2.96725 15.2161 3.29451C14.3587 3.62177 13.6224 4.20446 13.107 4.96377C12.5915 5.72309 12.3217 6.62239 12.334 7.54006V8.54006C10.5766 8.58562 8.83526 8.19587 7.26499 7.4055C5.69473 6.61513 4.3443 5.44869 3.33398 4.01006C3.33398 4.01006 -0.666016 13.0101 8.33398 17.0101C6.27451 18.408 3.82114 19.109 1.33398 19.0101C10.334 24.0101 21.334 19.0101 21.334 7.51006C21.3331 7.23151 21.3063 6.95365 21.254 6.68006C22.2746 5.67355 23.334 3.01006 23.334 3.01006Z'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </a>
                      <a
                        className='inline-block text-gray-900 hover:text-yellowGreen-600'
                        href='#'
                      >
                        <svg
                          width={25}
                          height={24}
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.334 8V16C21.334 18.7614 19.0954 21 16.334 21H8.33398C5.57256 21 3.33398 18.7614 3.33398 16V8C3.33398 5.23858 5.57256 3 8.33398 3H16.334C19.0954 3 21.334 5.23858 21.334 8Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 17V13.5V10'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M11.334 17V13.75M11.334 10V13.75M11.334 13.75C11.334 10 17.334 10 17.334 13.75V17'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.33398 7.01L7.34398 6.99889'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    <FooterComponent /></React.Fragment>
  );
}

