import React from 'react';

import { Flex, Text, Button } from '@radix-ui/themes';


import {FooterComponent} from './Footers.jsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Career() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='py-12 md:py-24 bg-radial-dark-green overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <div className='text-center mb-28'>
                <span className='inline-flex items-center h-7 mb-6 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                  CAREERS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                  Join our global team
                </h1>
                <p className='text-lg text-gray-400 mb-10'>
                  We are on a mission to make organizing assets a delightful
                  experience.
                </p>
                <a
                  className='xs:flex-shrink-0 group relative w-full xs:w-auto h-12 flex xs:inline-flex items-center justify-center px-5 font-bold text-gray-700 bg-white rounded-lg transition-all duration-300 focus:outline-none'
                  href='#'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-gray-500 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span>See open positions</span>
                </a>
              </div>
              <img
                className='block w-full mx-auto mb-28'
                src='aurora-assets/careers/map-green.png'
                alt=''
              />
              <div className='mb-28'>
                <span className='inline-flex items-center h-7 mb-4 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                  OUR CULTURE
                </span>
                <ul>
                  <li className='animate-pulseTextGreen text-7xl font-bold animate-delay-xs'>
                    Collaborative.
                  </li>
                  <li className='animate-pulseTextGreen text-7xl font-bold animate-delay-sm'>
                    Enthusiasm.
                  </li>
                  <li className='animate-pulseTextGreen text-7xl font-bold animate-delay-md'>
                    Dynamic.
                  </li>
                </ul>
              </div>
              <div className='pb-20 mb-20 border-b border-white border-opacity-20'>
                <div className='text-center mb-16'>
                  <span className='inline-flex items-center h-7 mb-4 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                    BENEFITS
                  </span>
                  <h3 className='font-heading text-4xl font-bold text-white mb-4'>
                    We help our employees thrive
                  </h3>
                </div>
                <div className='flex flex-wrap -mx-4 -mb-8'>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/hospital-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Inclusive Health Package
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We care for your well-being with dental and vision
                            benefits. No health expense stress.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/hospital-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Learn and Grow
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We foster your career growth through mentorship and
                            networking events.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/hospital-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Flexible Working Style
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We are 100% remote-friendly, work with team mates
                            from different time zones.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/hospital-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Balanced Working Life
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We offer a flexible 4-weeks vacation policy.
                            Recharge whenever you need to.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/hospital-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Diversity and Inclusion
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            Our strength lies in team members diversity and
                            inclusive culture.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/hospital-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Generous Perks Program
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We provide perk stipends for home office, learning,
                            wellness, and connectivity.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='text-center'>
                  <div className='flex items-center justify-center mb-4'>
                    <h3 className='font-heading mr-2 text-4xl font-bold text-white'>
                      Open Positions
                    </h3>
                    <span className='inline-block px-3 py-1 text-white bg-white bg-opacity-10 rounded-full'>
                      6
                    </span>
                  </div>
                  <p className='text-lg text-gray-400 mb-16'>
                    Join our team and create an amazing bookmarking tool
                    together!
                  </p>
                </div>
                <div className='max-w-4xl mx-auto'>
                  <div className='mb-16 pb-8 border-b border-white border-opacity-20'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                      DESIGN
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row mb-6 items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Head of Product Design
                          </h5>
                          <div className='flex items-center text-gray-300'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='currentColor'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-300'>
                              US, Mexico, Canada
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-700'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Senior Product Designer
                          </h5>
                          <div className='flex items-center text-gray-300'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='currentColor'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-300'>
                              United Kingdom
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-700'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-white border-opacity-20'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                      ENGINEERING
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row mb-6 items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Staff Engineer
                          </h5>
                          <div className='flex items-center text-gray-300'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='currentColor'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-300'>
                              Europe
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-700'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Senior Frontend Engineer
                          </h5>
                          <div className='flex items-center text-gray-300'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='currentColor'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-300'>
                              Asia
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-700'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-white border-opacity-20'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                      MARKETING
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Marketing Lead
                          </h5>
                          <div className='flex items-center text-gray-300'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='currentColor'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-300'>
                              Australia, New Zealand
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-700'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-white border-opacity-20'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                      DESIGN
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Customer Experience Lead
                          </h5>
                          <div className='flex items-center text-gray-300'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='currentColor'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-300'>
                              Australia, New Zealand
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-700'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='p-6 bg-white bg-opacity-10 border border-yellowGreen-500 rounded-xl'>
                    <p className='block text-lg text-white'>
                      Know someone who would love to work at Aurora? Refer by
                      writing to us at
                    </p>
                    <a
                      className='inline-block text-lg text-white hover:text-yellowGreen-500'
                      href='#'
                    >
                      careers@aurora.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-teal-900 overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <div className='text-center mb-28'>
                <span className='inline-flex items-center h-7 mb-6 px-2 text-sm font-medium text-white bg-teal-800 rounded-full'>
                  CAREERS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                  Join our global team
                </h1>
                <p className='text-lg text-gray-400 mb-10'>
                  We are on a mission to make organizing assets a delightful
                  experience.
                </p>
                <a
                  className='xs:flex-shrink-0 group relative w-full xs:w-auto h-12 flex xs:inline-flex items-center justify-center px-5 font-bold text-gray-700 bg-white rounded-lg transition-all duration-300 focus:outline-none'
                  href='#'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-gray-500 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span>See open positions</span>
                </a>
              </div>
              <img
                className='block w-full mx-auto mb-28'
                src='aurora-assets/careers/map-green.png'
                alt=''
              />
              <div className='mb-28'>
                <span className='inline-flex items-center h-7 mb-4 px-2 text-sm font-medium text-white bg-teal-800 rounded-full'>
                  CAREERS
                </span>
                <ul>
                  <li className='animate-pulseTextGreen text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-bold animate-delay-xs'>
                    Collaborative.
                  </li>
                  <li className='animate-pulseTextGreen text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-bold animate-delay-sm'>
                    Enthusiasm.
                  </li>
                  <li className='animate-pulseTextGreen text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-bold animate-delay-md'>
                    Dynamic.
                  </li>
                </ul>
              </div>
              <div className='pb-20 mb-20 border-b border-teal-800'>
                <div className='text-center mb-16'>
                  <span className='inline-flex items-center h-7 mb-4 px-2 text-sm font-medium text-white bg-teal-800 rounded-full'>
                    BENEFITS
                  </span>
                  <h3 className='font-heading text-4xl font-bold text-white mb-4'>
                    We help our employees thrive
                  </h3>
                </div>
                <div className='flex flex-wrap -mx-4 -mb-8'>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 border-2 border-teal-800 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/hospital-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Inclusive Health Package
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We care for your well-being with dental and vision
                            benefits. No health expense stress.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 border-2 border-teal-800 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/rocket-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Learn and Grow
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We foster your career growth through mentorship and
                            networking events.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 border-2 border-teal-800 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/clock-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Flexible Working Style
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We are 100% remote-friendly, work with team mates
                            from different time zones.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 border-2 border-teal-800 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/beach-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Balanced Working Life
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We offer a flexible 4-weeks vacation policy.
                            Recharge whenever you need to.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 border-2 border-teal-800 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/earth-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Diversity and Inclusion
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            Our strength lies in team members diversity and
                            inclusive culture.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 border-2 border-teal-800 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/money-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Generous Perks Program
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We provide perk stipends for home office, learning,
                            wellness, and connectivity.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='text-center'>
                  <div className='flex items-center justify-center mb-4'>
                    <h3 className='font-heading mr-2 text-4xl font-bold text-white'>
                      Open Positions
                    </h3>
                    <span className='inline-block px-3 py-1 text-white bg-teal-800 rounded-full'>
                      6
                    </span>
                  </div>
                  <p className='text-lg text-gray-400 mb-16'>
                    Join our team and create an amazing bookmarking tool
                    together!
                  </p>
                </div>
                <div className='max-w-4xl mx-auto'>
                  <div className='mb-16 pb-8 border-b border-teal-800'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-white bg-teal-800 rounded-full'>
                      DESIGN
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row mb-6 items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Head of Product Design
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-400'>
                              US, Mexico, Canada
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Senior Product Designer
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-400'>
                              United Kingdom
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-teal-800'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-white bg-teal-800 rounded-full'>
                      ENGINEERING
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row mb-6 items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Staff Engineer
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-400'>
                              Europe
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Senior Frontend Engineer
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-400'>
                              Asia
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-teal-800'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-white bg-teal-800 rounded-full'>
                      MARKETING
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Marketing Lead
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-400'>
                              Australia, New Zealand
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-teal-800'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-white bg-teal-800 rounded-full'>
                      DESIGN
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Customer Experience Lead
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-400'>
                              Australia, New Zealand
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='p-6 bg-teal-800 border border-yellowGreen-500 rounded-xl'>
                    <p className='block text-lg text-gray-400'>
                      Know someone who would love to work at Aurora? Refer by
                      writing to us at
                    </p>
                    <a
                      className='inline-block text-lg text-gray-400 hover:text-gray-300'
                      href='#'
                    >
                      careers@aurora.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <div className='text-center mb-28'>
                <span className='inline-flex items-center h-7 mb-6 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                  CAREERS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                  Join our global team
                </h1>
                <p className='text-lg text-gray-400 mb-10'>
                  We are on a mission to make organizing assets a delightful
                  experience.
                </p>
                <a
                  className='xs:flex-shrink-0 group relative w-full xs:w-auto h-12 flex xs:inline-flex items-center justify-center px-5 font-bold text-gray-700 bg-white rounded-lg transition-all duration-300 focus:outline-none'
                  href='#'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-gray-500 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span>See open positions</span>
                </a>
              </div>
              <img
                className='block w-full mx-auto mb-28'
                src='aurora-assets/careers/map-green.png'
                alt=''
              />
              <div className='mb-28'>
                <span className='inline-flex items-center h-7 mb-4 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                  OUR CULTURE
                </span>
                <ul>
                  <li className='animate-pulseTextGreen text-7xl font-bold animate-delay-xs'>
                    Collaborative.
                  </li>
                  <li className='animate-pulseTextGreen text-7xl font-bold animate-delay-sm'>
                    Enthusiasm.
                  </li>
                  <li className='animate-pulseTextGreen text-7xl font-bold animate-delay-md'>
                    Dynamic.
                  </li>
                </ul>
              </div>
              <div className='pb-20 mb-20 border-b border-white border-opacity-20'>
                <div className='text-center mb-16'>
                  <span className='inline-flex items-center h-7 mb-4 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                    BENEFITS
                  </span>
                  <h3 className='font-heading text-4xl font-bold text-white mb-4'>
                    We help our employees thrive
                  </h3>
                </div>
                <div className='flex flex-wrap -mx-4 -mb-8'>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/hospital-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Inclusive Health Package
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We care for your well-being with dental and vision
                            benefits. No health expense stress.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/hospital-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Learn and Grow
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We foster your career growth through mentorship and
                            networking events.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/hospital-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Flexible Working Style
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We are 100% remote-friendly, work with team mates
                            from different time zones.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/hospital-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Balanced Working Life
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We offer a flexible 4-weeks vacation policy.
                            Recharge whenever you need to.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/hospital-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Diversity and Inclusion
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            Our strength lies in team members diversity and
                            inclusive culture.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/hospital-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Generous Perks Program
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We provide perk stipends for home office, learning,
                            wellness, and connectivity.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='text-center'>
                  <div className='flex items-center justify-center mb-4'>
                    <h3 className='font-heading mr-2 text-4xl font-bold text-white'>
                      Open Positions
                    </h3>
                    <span className='inline-block px-3 py-1 text-white bg-white bg-opacity-10 rounded-full'>
                      6
                    </span>
                  </div>
                  <p className='text-lg text-gray-400 mb-16'>
                    Join our team and create an amazing bookmarking tool
                    together!
                  </p>
                </div>
                <div className='max-w-4xl mx-auto'>
                  <div className='mb-16 pb-8 border-b border-white border-opacity-20'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                      DESIGN
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row mb-6 items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Head of Product Design
                          </h5>
                          <div className='flex items-center text-gray-300'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='currentColor'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-300'>
                              US, Mexico, Canada
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-700'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Senior Product Designer
                          </h5>
                          <div className='flex items-center text-gray-300'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='currentColor'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-300'>
                              United Kingdom
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-700'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-white border-opacity-20'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                      ENGINEERING
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row mb-6 items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Staff Engineer
                          </h5>
                          <div className='flex items-center text-gray-300'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='currentColor'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-300'>
                              Europe
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-700'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Senior Frontend Engineer
                          </h5>
                          <div className='flex items-center text-gray-300'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='currentColor'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-300'>
                              Asia
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-700'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-white border-opacity-20'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                      MARKETING
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Marketing Lead
                          </h5>
                          <div className='flex items-center text-gray-300'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='currentColor'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-300'>
                              Australia, New Zealand
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-700'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-white border-opacity-20'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                      DESIGN
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Customer Experience Lead
                          </h5>
                          <div className='flex items-center text-gray-300'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='currentColor'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-300'>
                              Australia, New Zealand
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-700'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='p-6 bg-white bg-opacity-10 border border-yellowGreen-500 rounded-xl'>
                    <p className='block text-lg text-white'>
                      Know someone who would love to work at Aurora? Refer by
                      writing to us at
                    </p>
                    <a
                      className='inline-block text-lg text-white hover:text-yellowGreen-500'
                      href='#'
                    >
                      careers@aurora.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-50 overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <div className='text-center mb-28'>
                <span className='inline-flex items-center h-7 mb-6 px-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-full'>
                  CAREERS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-4'>
                  Join our global team
                </h1>
                <p className='text-lg text-gray-500 mb-10'>
                  We are on a mission to make organizing assets a delightful
                  experience.
                </p>
                <a
                  className='group relative flex items-center justify-center px-5 h-12 xs:inline-flex text-base font-semibold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                  href='#'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span>See open positions</span>
                </a>
              </div>
              <img
                className='block w-full mx-auto mb-28'
                src='aurora-assets/careers/map.png'
                alt=''
              />
              <div className='mb-28'>
                <span className='inline-flex items-center h-7 mb-4 px-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-full'>
                  CAREERS
                </span>
                <ul>
                  <li className='animate-pulseText text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-bold animate-delay-xs'>
                    Collaborative.
                  </li>
                  <li className='animate-pulseText text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-bold animate-delay-sm'>
                    Enthusiasm.
                  </li>
                  <li className='animate-pulseText text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-bold animate-delay-md'>
                    Dynamic.
                  </li>
                </ul>
              </div>
              <div className='pb-20 mb-20 border-b border-gray-300'>
                <div className='text-center mb-16'>
                  <span className='inline-flex items-center h-7 mb-4 px-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-full'>
                    BENEFITS
                  </span>
                  <h3 className='font-heading text-4xl font-bold text-gray-900 mb-4'>
                    We help our employees thrive
                  </h3>
                </div>
                <div className='flex flex-wrap -mx-4 -mb-8'>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='bg-white p-8 border-2 border-gray-100 rounded-xl'>
                      <div className='sm:flex'>
                        <img
                          className='block w-16 h-16'
                          src='aurora-assets/careers/icon-circle-1.png'
                          alt=''
                        />
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-gray-900 mb-4'>
                            Inclusive Health Package
                          </h5>
                          <p className='max-w-sm text-gray-500'>
                            We care for your well-being with dental and vision
                            benefits. No health expense stress.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='bg-white p-8 border-2 border-gray-100 rounded-xl'>
                      <div className='sm:flex'>
                        <img
                          className='block w-16 h-16 mb-8'
                          src='aurora-assets/careers/icon-circle-6.png'
                          alt=''
                        />
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-gray-900 mb-4'>
                            Learn and Grow
                          </h5>
                          <p className='max-w-sm text-gray-500'>
                            We foster your career growth through mentorship and
                            networking events.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='bg-white p-8 border-2 border-gray-100 rounded-xl'>
                      <div className='sm:flex'>
                        <img
                          className='block w-16 h-16 mb-8'
                          src='aurora-assets/careers/icon-circle-5.png'
                          alt=''
                        />
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-gray-900 mb-4'>
                            Flexible Working Style
                          </h5>
                          <p className='max-w-sm text-gray-500'>
                            We are 100% remote-friendly, work with team mates
                            from different time zones.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='bg-white p-8 border-2 border-gray-100 rounded-xl'>
                      <div className='sm:flex'>
                        <img
                          className='block w-16 h-16 mb-8'
                          src='aurora-assets/careers/icon-circle-4.png'
                          alt=''
                        />
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-gray-900 mb-4'>
                            Balanced Working Life
                          </h5>
                          <p className='max-w-sm text-gray-500'>
                            We offer a flexible 4-weeks vacation policy.
                            Recharge whenever you need to.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='bg-white p-8 border-2 border-gray-100 rounded-xl'>
                      <div className='sm:flex'>
                        <img
                          className='block w-16 h-16 mb-8'
                          src='aurora-assets/careers/icon-circle-3.png'
                          alt=''
                        />
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-gray-900 mb-4'>
                            Diversity and Inclusion
                          </h5>
                          <p className='max-w-sm text-gray-500'>
                            Our strength lies in team members diversity and
                            inclusive culture.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='bg-white p-8 border-2 border-gray-100 rounded-xl'>
                      <div className='sm:flex'>
                        <img
                          className='block w-16 h-16 mb-8'
                          src='aurora-assets/careers/icon-circle-2.png'
                          alt=''
                        />
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-gray-900 mb-4'>
                            Generous Perks Program
                          </h5>
                          <p className='max-w-sm text-gray-500'>
                            We provide perk stipends for home office, learning,
                            wellness, and connectivity.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='text-center'>
                  <div className='flex items-center justify-center mb-4'>
                    <h3 className='font-heading mr-2 text-4xl font-bold text-gray-900'>
                      Open Positions
                    </h3>
                    <span className='inline-block px-3 py-1 text-gray-700 bg-gray-100 rounded-full'>
                      6
                    </span>
                  </div>
                  <p className='text-lg text-gray-500 mb-16'>
                    Join our team and create an amazing bookmarking tool
                    together!
                  </p>
                </div>
                <div className='max-w-4xl mx-auto'>
                  <div className='mb-16 pb-8 border-b border-gray-300'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-full'>
                      DESIGN
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row mb-6 items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold mb-2'>
                            Head of Product Design
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-500'>
                              US, Mexico, Canada
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold mb-2'>
                            Senior Product Designer
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-500'>
                              United Kingdom
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-gray-300'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-full'>
                      ENGINEERING
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row mb-6 items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold mb-2'>
                            Staff Engineer
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-500'>
                              Europe
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold mb-2'>
                            Senior Frontend Engineer
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-500'>
                              Asia
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-gray-300'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-full'>
                      MARKETING
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold mb-2'>
                            Marketing Lead
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-500'>
                              Australia, New Zealand
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-gray-300'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-full'>
                      DESIGN
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold mb-2'>
                            Customer Experience Lead
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-500'>
                              Australia, New Zealand
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='p-6 bg-white border border-yellowGreen-500 rounded-xl'>
                    <p className='block text-lg text-gray-700'>
                      Know someone who would love to work at Aurora? Refer by
                      writing to us at
                    </p>
                    <a
                      className='inline-block text-lg text-gray-700 hover:text-gray-900'
                      href='#'
                    >
                      careers@aurora.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-50 overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <div className='text-center mb-28'>
                <span className='inline-flex items-center h-7 mb-6 px-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-full'>
                  CAREERS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-4'>
                  Join our global team
                </h1>
                <p className='text-lg text-gray-500 mb-10'>
                  We are on a mission to make organizing assets a delightful
                  experience.
                </p>
                <a
                  className='group relative flex items-center justify-center px-5 h-12 xs:inline-flex text-base font-semibold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                  href='#'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span>See open positions</span>
                </a>
              </div>
              <img
                className='block w-full mx-auto mb-28'
                src='aurora-assets/careers/map.png'
                alt=''
              />
              <div className='mb-28'>
                <span className='inline-flex items-center h-7 mb-4 px-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-full'>
                  CAREERS
                </span>
                <ul>
                  <li className='animate-pulseText text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-bold animate-delay-xs'>
                    Collaborative.
                  </li>
                  <li className='animate-pulseText text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-bold animate-delay-sm'>
                    Enthusiasm.
                  </li>
                  <li className='animate-pulseText text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-bold animate-delay-md'>
                    Dynamic.
                  </li>
                </ul>
              </div>
              <div className='pb-20 mb-20 border-b border-gray-300'>
                <div className='text-center mb-16'>
                  <span className='inline-flex items-center h-7 mb-4 px-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-full'>
                    BENEFITS
                  </span>
                  <h3 className='font-heading text-4xl font-bold text-gray-900 mb-4'>
                    We help our employees thrive
                  </h3>
                </div>
                <div className='flex flex-wrap -mx-4 -mb-8'>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='bg-white p-8 border-2 border-gray-100 rounded-xl'>
                      <div className='sm:flex'>
                        <img
                          className='block w-16 h-16'
                          src='aurora-assets/careers/icon-circle-1.png'
                          alt=''
                        />
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-gray-900 mb-4'>
                            Inclusive Health Package
                          </h5>
                          <p className='max-w-sm text-gray-500'>
                            We care for your well-being with dental and vision
                            benefits. No health expense stress.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='bg-white p-8 border-2 border-gray-100 rounded-xl'>
                      <div className='sm:flex'>
                        <img
                          className='block w-16 h-16 mb-8'
                          src='aurora-assets/careers/icon-circle-6.png'
                          alt=''
                        />
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-gray-900 mb-4'>
                            Learn and Grow
                          </h5>
                          <p className='max-w-sm text-gray-500'>
                            We foster your career growth through mentorship and
                            networking events.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='bg-white p-8 border-2 border-gray-100 rounded-xl'>
                      <div className='sm:flex'>
                        <img
                          className='block w-16 h-16 mb-8'
                          src='aurora-assets/careers/icon-circle-5.png'
                          alt=''
                        />
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-gray-900 mb-4'>
                            Flexible Working Style
                          </h5>
                          <p className='max-w-sm text-gray-500'>
                            We are 100% remote-friendly, work with team mates
                            from different time zones.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='bg-white p-8 border-2 border-gray-100 rounded-xl'>
                      <div className='sm:flex'>
                        <img
                          className='block w-16 h-16 mb-8'
                          src='aurora-assets/careers/icon-circle-4.png'
                          alt=''
                        />
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-gray-900 mb-4'>
                            Balanced Working Life
                          </h5>
                          <p className='max-w-sm text-gray-500'>
                            We offer a flexible 4-weeks vacation policy.
                            Recharge whenever you need to.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='bg-white p-8 border-2 border-gray-100 rounded-xl'>
                      <div className='sm:flex'>
                        <img
                          className='block w-16 h-16 mb-8'
                          src='aurora-assets/careers/icon-circle-3.png'
                          alt=''
                        />
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-gray-900 mb-4'>
                            Diversity and Inclusion
                          </h5>
                          <p className='max-w-sm text-gray-500'>
                            Our strength lies in team members diversity and
                            inclusive culture.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='bg-white p-8 border-2 border-gray-100 rounded-xl'>
                      <div className='sm:flex'>
                        <img
                          className='block w-16 h-16 mb-8'
                          src='aurora-assets/careers/icon-circle-2.png'
                          alt=''
                        />
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-gray-900 mb-4'>
                            Generous Perks Program
                          </h5>
                          <p className='max-w-sm text-gray-500'>
                            We provide perk stipends for home office, learning,
                            wellness, and connectivity.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='text-center'>
                  <div className='flex items-center justify-center mb-4'>
                    <h3 className='font-heading mr-2 text-4xl font-bold text-gray-900'>
                      Open Positions
                    </h3>
                    <span className='inline-block px-3 py-1 text-gray-700 bg-gray-100 rounded-full'>
                      6
                    </span>
                  </div>
                  <p className='text-lg text-gray-500 mb-16'>
                    Join our team and create an amazing bookmarking tool
                    together!
                  </p>
                </div>
                <div className='max-w-4xl mx-auto'>
                  <div className='mb-16 pb-8 border-b border-gray-300'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-full'>
                      DESIGN
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row mb-6 items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold mb-2'>
                            Head of Product Design
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-500'>
                              US, Mexico, Canada
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold mb-2'>
                            Senior Product Designer
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-500'>
                              United Kingdom
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-gray-300'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-full'>
                      ENGINEERING
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row mb-6 items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold mb-2'>
                            Staff Engineer
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-500'>
                              Europe
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold mb-2'>
                            Senior Frontend Engineer
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-500'>
                              Asia
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-gray-300'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-full'>
                      MARKETING
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold mb-2'>
                            Marketing Lead
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-500'>
                              Australia, New Zealand
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-gray-300'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-full'>
                      DESIGN
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold mb-2'>
                            Customer Experience Lead
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-500'>
                              Australia, New Zealand
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='p-6 bg-white border border-yellowGreen-500 rounded-xl'>
                    <p className='block text-lg text-gray-700'>
                      Know someone who would love to work at Aurora? Refer by
                      writing to us at
                    </p>
                    <a
                      className='inline-block text-lg text-gray-700 hover:text-gray-900'
                      href='#'
                    >
                      careers@aurora.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <div className='text-center mb-28'>
                <span className='inline-flex items-center h-7 mb-6 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                  CAREERS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                  Join our global team
                </h1>
                <p className='text-lg text-gray-400 mb-10'>
                  We are on a mission to make organizing assets a delightful
                  experience.
                </p>
                <a
                  className='xs:flex-shrink-0 group relative w-full xs:w-auto h-12 flex xs:inline-flex items-center justify-center px-5 font-bold text-gray-700 bg-white rounded-lg transition-all duration-300 focus:outline-none'
                  href='#'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-gray-500 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span>See open positions</span>
                </a>
              </div>
              <img
                className='block w-full mx-auto mb-28'
                src='aurora-assets/careers/map-green.png'
                alt=''
              />
              <div className='mb-28'>
                <span className='inline-flex items-center h-7 mb-4 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                  OUR CULTURE
                </span>
                <ul>
                  <li className='animate-pulseTextGreen text-7xl font-bold animate-delay-xs'>
                    Collaborative.
                  </li>
                  <li className='animate-pulseTextGreen text-7xl font-bold animate-delay-sm'>
                    Enthusiasm.
                  </li>
                  <li className='animate-pulseTextGreen text-7xl font-bold animate-delay-md'>
                    Dynamic.
                  </li>
                </ul>
              </div>
              <div className='pb-20 mb-20 border-b border-white border-opacity-20'>
                <div className='text-center mb-16'>
                  <span className='inline-flex items-center h-7 mb-4 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                    BENEFITS
                  </span>
                  <h3 className='font-heading text-4xl font-bold text-white mb-4'>
                    We help our employees thrive
                  </h3>
                </div>
                <div className='flex flex-wrap -mx-4 -mb-8'>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/hospital-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Inclusive Health Package
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We care for your well-being with dental and vision
                            benefits. No health expense stress.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/hospital-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Learn and Grow
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We foster your career growth through mentorship and
                            networking events.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/hospital-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Flexible Working Style
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We are 100% remote-friendly, work with team mates
                            from different time zones.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/hospital-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Balanced Working Life
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We offer a flexible 4-weeks vacation policy.
                            Recharge whenever you need to.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/hospital-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Diversity and Inclusion
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            Our strength lies in team members diversity and
                            inclusive culture.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-teal-800'>
                          <img
                            src='aurora-assets/careers/hospital-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Generous Perks Program
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We provide perk stipends for home office, learning,
                            wellness, and connectivity.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='text-center'>
                  <div className='flex items-center justify-center mb-4'>
                    <h3 className='font-heading mr-2 text-4xl font-bold text-white'>
                      Open Positions
                    </h3>
                    <span className='inline-block px-3 py-1 text-white bg-white bg-opacity-10 rounded-full'>
                      6
                    </span>
                  </div>
                  <p className='text-lg text-gray-400 mb-16'>
                    Join our team and create an amazing bookmarking tool
                    together!
                  </p>
                </div>
                <div className='max-w-4xl mx-auto'>
                  <div className='mb-16 pb-8 border-b border-white border-opacity-20'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                      DESIGN
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row mb-6 items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Head of Product Design
                          </h5>
                          <div className='flex items-center text-gray-300'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='currentColor'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-300'>
                              US, Mexico, Canada
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-700'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Senior Product Designer
                          </h5>
                          <div className='flex items-center text-gray-300'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='currentColor'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-300'>
                              United Kingdom
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-700'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-white border-opacity-20'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                      ENGINEERING
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row mb-6 items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Staff Engineer
                          </h5>
                          <div className='flex items-center text-gray-300'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='currentColor'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-300'>
                              Europe
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-700'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Senior Frontend Engineer
                          </h5>
                          <div className='flex items-center text-gray-300'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='currentColor'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-300'>
                              Asia
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-700'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-white border-opacity-20'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                      MARKETING
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Marketing Lead
                          </h5>
                          <div className='flex items-center text-gray-300'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='currentColor'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-300'>
                              Australia, New Zealand
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-700'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-white border-opacity-20'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                      DESIGN
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Customer Experience Lead
                          </h5>
                          <div className='flex items-center text-gray-300'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='currentColor'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-300'>
                              Australia, New Zealand
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-700'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='p-6 bg-white bg-opacity-10 border border-yellowGreen-500 rounded-xl'>
                    <p className='block text-lg text-white'>
                      Know someone who would love to work at Aurora? Refer by
                      writing to us at
                    </p>
                    <a
                      className='inline-block text-lg text-white hover:text-yellowGreen-500'
                      href='#'
                    >
                      careers@aurora.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900 overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <div className='text-center mb-28'>
                <span className='inline-flex items-center h-7 mb-6 px-2 text-sm font-medium text-white bg-gray-800 rounded-full'>
                  CAREERS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                  Join our global team
                </h1>
                <p className='text-lg text-gray-400 mb-10'>
                  We are on a mission to make organizing assets a delightful
                  experience.
                </p>
                <a
                  className='group inline-block justify-center p-1 text-center text-base text-white font-semibold rounded-lg'
                  href='#'
                >
                  <div className='inline-flex items-stretch h-12 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                    <div className='flex items-center px-5 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                      <span>See open positions</span>
                    </div>
                  </div>
                </a>
              </div>
              <img
                className='block w-full mx-auto mb-28'
                src='aurora-assets/careers/map-green.png'
                alt=''
              />
              <div className='mb-28'>
                <span className='inline-flex items-center h-7 mb-4 px-2 text-sm font-medium text-white bg-gray-800 rounded-full'>
                  CAREERS
                </span>
                <ul>
                  <li className='animate-pulseTextDark text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-bold animate-delay-xs'>
                    Collaborative.
                  </li>
                  <li className='animate-pulseTextDark text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-bold animate-delay-sm'>
                    Enthusiasm.
                  </li>
                  <li className='animate-pulseTextDark text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-bold animate-delay-md'>
                    Dynamic.
                  </li>
                </ul>
              </div>
              <div className='pb-20 mb-20 border-b border-gray-700'>
                <div className='text-center mb-16'>
                  <span className='inline-flex items-center h-7 mb-4 px-2 text-sm font-medium text-white bg-gray-800 rounded-full'>
                    BENEFITS
                  </span>
                  <h3 className='font-heading text-4xl font-bold text-white mb-4'>
                    We help our employees thrive
                  </h3>
                </div>
                <div className='flex flex-wrap -mx-4 -mb-8'>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 border-2 border-gray-800 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-gray-800'>
                          <img
                            src='aurora-assets/careers/hospital-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Inclusive Health Package
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We care for your well-being with dental and vision
                            benefits. No health expense stress.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 border-2 border-gray-800 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-gray-800'>
                          <img
                            src='aurora-assets/careers/rocket-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Learn and Grow
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We foster your career growth through mentorship and
                            networking events.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 border-2 border-gray-800 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-gray-800'>
                          <img
                            src='aurora-assets/careers/clock-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Flexible Working Style
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We are 100% remote-friendly, work with team mates
                            from different time zones.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 border-2 border-gray-800 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-gray-800'>
                          <img
                            src='aurora-assets/careers/beach-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Balanced Working Life
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We offer a flexible 4-weeks vacation policy.
                            Recharge whenever you need to.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 border-2 border-gray-800 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-gray-800'>
                          <img
                            src='aurora-assets/careers/earth-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Diversity and Inclusion
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            Our strength lies in team members diversity and
                            inclusive culture.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='p-8 border-2 border-gray-800 rounded-xl'>
                      <div className='sm:flex'>
                        <div className='flex-shrink-0 flex w-16 h-16 items-center justify-center rounded-full bg-gray-800'>
                          <img
                            src='aurora-assets/careers/money-icon-circle.png'
                            alt=''
                          />
                        </div>
                        <div className='mt-5 sm:mt-0 sm:ml-8'>
                          <h5 className='text-2xl font-bold text-white mb-4'>
                            Generous Perks Program
                          </h5>
                          <p className='max-w-sm text-gray-400'>
                            We provide perk stipends for home office, learning,
                            wellness, and connectivity.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='text-center'>
                  <div className='flex items-center justify-center mb-4'>
                    <h3 className='font-heading mr-2 text-4xl font-bold text-white'>
                      Open Positions
                    </h3>
                    <span className='inline-block px-3 py-1 text-white bg-gray-800 rounded-full'>
                      6
                    </span>
                  </div>
                  <p className='text-lg text-gray-400 mb-16'>
                    Join our team and create an amazing bookmarking tool
                    together!
                  </p>
                </div>
                <div className='max-w-4xl mx-auto'>
                  <div className='mb-16 pb-8 border-b border-gray-700'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-white bg-gray-800 rounded-full'>
                      DESIGN
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row mb-6 items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Head of Product Design
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-400'>
                              US, Mexico, Canada
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Senior Product Designer
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-400'>
                              United Kingdom
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-gray-700'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-white bg-gray-800 rounded-full'>
                      ENGINEERING
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row mb-6 items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Staff Engineer
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-400'>
                              Europe
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Senior Frontend Engineer
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-400'>
                              Asia
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-gray-700'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-white bg-gray-800 rounded-full'>
                      MARKETING
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Marketing Lead
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-400'>
                              Australia, New Zealand
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mb-16 pb-8 border-b border-gray-700'>
                    <span className='inline-flex items-center h-7 px-2 text-sm font-medium text-white bg-gray-800 rounded-full'>
                      DESIGN
                    </span>
                    <div className='mt-6'>
                      <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                        <div className='mb-4 sm:mb-0'>
                          <h5 className='text-xl font-bold text-white mb-2'>
                            Customer Experience Lead
                          </h5>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_2999_35085)'>
                                <path
                                  d='M16.6663 8.33348C16.6663 12.0154 9.99967 18.3335 9.99967 18.3335C9.99967 18.3335 3.33301 12.0154 3.33301 8.33348C3.33301 4.65158 6.31778 1.66681 9.99967 1.66681C13.6816 1.66681 16.6663 4.65158 16.6663 8.33348Z'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                />
                                <path
                                  d='M10.0003 9.16679C10.4606 9.16679 10.8337 8.79369 10.8337 8.33346C10.8337 7.87322 10.4606 7.50012 10.0003 7.50012C9.54009 7.50012 9.16699 7.87322 9.16699 8.33346C9.16699 8.79369 9.54009 9.16679 10.0003 9.16679Z'
                                  fill='#6C7F73'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='inline-block ml-2 text-gray-400'>
                              Australia, New Zealand
                            </span>
                          </div>
                        </div>
                        <div>
                          <a
                            className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                            href='#'
                          >
                            <span className='mr-2'>View job</span>
                            <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                                  stroke='currentColor'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='p-6 bg-gray-800 border border-gray-700 rounded-xl'>
                    <p className='block text-lg text-white'>
                      Know someone who would love to work at Aurora? Refer by
                      writing to us at
                    </p>
                    <a
                      className='inline-block text-lg text-white hover:text-yellowGreen-600'
                      href='#'
                    >
                      careers@aurora.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-teal-900 overflow-hidden'>
          <div className='container px-4 mb-28 mx-auto'>
            <div className='text-center'>
              <span className='inline-flex items-center h-7 mb-4 px-2 text-sm font-medium text-white bg-teal-800 rounded-full'>
                CAREERS
              </span>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                Join our global team
              </h1>
              <p className='text-lg text-gray-400 mb-10'>
                We are on a mission to make organizing assets a delightful
                experience.
              </p>
              <a
                className='xs:flex-shrink-0 group relative w-full xs:w-auto h-12 flex xs:inline-flex items-center justify-center px-5 font-bold text-gray-700 bg-white rounded-lg transition-all duration-300 focus:outline-none'
                href='#'
              >
                <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-gray-500 animate-pulse group-hover:ring-0 transition duration-300' />
                <span>See open positions</span>
              </a>
            </div>
          </div>
          <div className='flex -mx-4 mb-28 items-center justify-center'>
            <img
              className='block w-full h-40 md:h-96 mr-4 md:mr-8 object-cover rounded-xl'
              src='aurora-assets/careers/image-careers-people1.png'
              alt=''
            />
            <img
              className='block w-full h-40 md:h-96 mr-4 md:mr-8 object-cover rounded-xl'
              src='aurora-assets/careers/image-careers-people3.png'
              alt=''
            />
            <img
              className='block w-full h-40 md:h-96 object-cover rounded-xl'
              src='aurora-assets/careers/image-careers-people2.png'
              alt=''
            />
          </div>
          <div className='container px-4 mb-28 mx-auto'>
            <div className='max-w-4xl mx-auto'>
              <div className='pb-20 mb-20 border-b border-teal-800'>
                <h3 className='font-heading text-4xl font-bold text-white mb-4'>
                  Benefits
                </h3>
                <p className='text-lg text-gray-400 mb-16'>
                  We help our employees thrive
                </p>
                <div className='flex flex-wrap -mx-4 -mb-8'>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='h-full p-8 border-2 border-teal-800 rounded-xl'>
                      <div className='flex w-16 h-16 mb-8 items-center justify-center rounded-full bg-teal-800'>
                        <img
                          src='aurora-assets/careers/hospital-icon-circle.png'
                          alt=''
                        />
                      </div>
                      <h5 className='text-2xl font-bold text-white mb-4'>
                        Inclusive Health Package
                      </h5>
                      <p className='max-w-sm text-gray-400'>
                        We care for your well-being with dental and vision
                        benefits. No health expense stress.
                      </p>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='h-full p-8 border-2 border-teal-800 rounded-xl'>
                      <div className='flex w-16 h-16 mb-8 items-center justify-center rounded-full bg-teal-800'>
                        <img
                          src='aurora-assets/careers/rocket-icon-circle.png'
                          alt=''
                        />
                      </div>
                      <h5 className='text-2xl font-bold text-white mb-4'>
                        Learn and Grow
                      </h5>
                      <p className='max-w-sm text-gray-400'>
                        We foster your career growth through mentorship and
                        networking events.
                      </p>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='h-full p-8 border-2 border-teal-800 rounded-xl'>
                      <div className='flex w-16 h-16 mb-8 items-center justify-center rounded-full bg-teal-800'>
                        <img
                          src='aurora-assets/careers/clock-icon-circle.png'
                          alt=''
                        />
                      </div>
                      <h5 className='text-2xl font-bold text-white mb-4'>
                        Flexible Working Style
                      </h5>
                      <p className='max-w-sm text-gray-400'>
                        We are 100% remote-friendly, work with team mates from
                        different time zones.
                      </p>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='h-full p-8 border-2 border-teal-800 rounded-xl'>
                      <div className='flex w-16 h-16 mb-8 items-center justify-center rounded-full bg-teal-800'>
                        <img
                          src='aurora-assets/careers/beach-icon-circle.png'
                          alt=''
                        />
                      </div>
                      <h5 className='text-2xl font-bold text-white mb-4'>
                        Balanced Working Life
                      </h5>
                      <p className='max-w-sm text-gray-400'>
                        We offer a flexible 4-weeks vacation policy. Recharge
                        whenever you need to.
                      </p>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='h-full p-8 border-2 border-teal-800 rounded-xl'>
                      <div className='flex w-16 h-16 mb-8 items-center justify-center rounded-full bg-teal-800'>
                        <img
                          src='aurora-assets/careers/earth-icon-circle.png'
                          alt=''
                        />
                      </div>
                      <h5 className='text-2xl font-bold text-white mb-4'>
                        Diversity and Inclusion
                      </h5>
                      <p className='max-w-sm text-gray-400'>
                        Our strength lies in team members diversity and
                        inclusive culture.
                      </p>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='h-full p-8 border-2 border-teal-800 rounded-xl'>
                      <div className='flex w-16 h-16 mb-8 items-center justify-center rounded-full bg-teal-800'>
                        <img
                          src='aurora-assets/careers/money-icon-circle.png'
                          alt=''
                        />
                      </div>
                      <h5 className='text-2xl font-bold text-white mb-4'>
                        Generous Perks Program
                      </h5>
                      <p className='max-w-sm text-gray-400'>
                        We provide perk stipends for home office, learning,
                        wellness, and connectivity.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='flex items-center mb-4'>
                  <h3 className='font-heading mr-2 text-4xl font-bold text-white'>
                    Open Positions
                  </h3>
                  <span className='inline-block px-3 py-1 text-white bg-teal-800 rounded-full'>
                    6
                  </span>
                </div>
                <p className='text-lg text-gray-400 mb-16'>
                  We believe that the freedom to work remotely allows for deeper
                  and more meaningful work.
                </p>
                <div className='mb-16 pb-8 border-b border-teal-800'>
                  <h6 className='text-lg font-medium text-gray-500 mb-6'>
                    Design
                  </h6>
                  <div className='flex flex-col xs:flex-row mb-6 xs:items-center xs:justify-between'>
                    <h5 className='text-lg text-white font-medium mb-3 xs:mb-0'>
                      Head of Product Design
                    </h5>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                      href='#'
                    >
                      <span className='mr-2'>View job</span>
                      <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                  <div className='flex flex-col xs:flex-row xs:items-center xs:justify-between'>
                    <h5 className='text-lg text-white font-medium mb-3 xs:mb-0'>
                      Senior Product Designer
                    </h5>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                      href='#'
                    >
                      <span className='mr-2'>View job</span>
                      <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <div className='mb-16 pb-8 border-b border-teal-800'>
                  <h6 className='text-lg font-medium text-gray-500 mb-6'>
                    Engineering
                  </h6>
                  <div className='flex flex-col xs:flex-row mb-6 xs:items-center xs:justify-between'>
                    <h5 className='text-lg text-white font-medium mb-3 xs:mb-0'>
                      Staff Engineer
                    </h5>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                      href='#'
                    >
                      <span className='mr-2'>View job</span>
                      <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                  <div className='flex flex-col xs:flex-row xs:items-center xs:justify-between'>
                    <h5 className='text-lg text-white font-medium mb-3 xs:mb-0'>
                      Senior Frontend Engineer
                    </h5>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                      href='#'
                    >
                      <span className='mr-2'>View job</span>
                      <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <div className='mb-16 pb-8 border-b border-teal-800'>
                  <h6 className='text-lg font-medium text-gray-500 mb-6'>
                    Marketing
                  </h6>
                  <div className='flex flex-col xs:flex-row xs:items-center xs:justify-between'>
                    <h5 className='text-lg text-white font-medium mb-3 xs:mb-0'>
                      Marketing Lead
                    </h5>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                      href='#'
                    >
                      <span className='mr-2'>View job</span>
                      <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <div className='pb-8 border-b border-teal-800'>
                  <h6 className='text-lg font-medium text-gray-500 mb-6'>
                    Customer Experience
                  </h6>
                  <div className='flex flex-col xs:flex-row xs:items-center xs:justify-between'>
                    <h5 className='text-lg text-white font-medium mb-3 xs:mb-0'>
                      Customer Experience Lead
                    </h5>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                      href='#'
                    >
                      <span className='mr-2'>View job</span>
                      <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900 overflow-hidden'>
          <div className='container px-4 mb-28 mx-auto'>
            <div className='text-center'>
              <span className='inline-flex items-center h-7 mb-4 px-2 text-sm font-medium text-white bg-gray-800 rounded-full'>
                CAREERS
              </span>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                Join our global team
              </h1>
              <p className='text-lg text-gray-400 mb-10'>
                We are on a mission to make organizing assets a delightful
                experience.
              </p>
              <a
                className='group inline-block justify-center p-1 text-center text-base text-white font-semibold rounded-lg'
                href='#'
              >
                <div className='inline-flex items-stretch h-12 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                  <div className='flex items-center px-5 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                    <span>See open positions</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className='flex -mx-4 mb-28 items-center justify-center'>
            <img
              className='block w-full h-40 md:h-96 mr-4 md:mr-8 object-cover rounded-xl'
              src='aurora-assets/careers/image-careers-people1.png'
              alt=''
            />
            <img
              className='block w-full h-40 md:h-96 mr-4 md:mr-8 object-cover rounded-xl'
              src='aurora-assets/careers/image-careers-people3.png'
              alt=''
            />
            <img
              className='block w-full h-40 md:h-96 object-cover rounded-xl'
              src='aurora-assets/careers/image-careers-people2.png'
              alt=''
            />
          </div>
          <div className='container px-4 mb-28 mx-auto'>
            <div className='max-w-4xl mx-auto'>
              <div className='pb-20 mb-20 border-b border-gray-800'>
                <h3 className='font-heading text-4xl font-bold text-white mb-4'>
                  Benefits
                </h3>
                <p className='text-lg text-gray-400 mb-16'>
                  We help our employees thrive
                </p>
                <div className='flex flex-wrap -mx-4 -mb-8'>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='h-full p-8 border-2 border-gray-800 rounded-xl'>
                      <div className='flex w-16 h-16 mb-8 items-center justify-center rounded-full bg-gray-800'>
                        <img
                          src='aurora-assets/careers/hospital-icon-circle.png'
                          alt=''
                        />
                      </div>
                      <h5 className='text-2xl font-bold text-white mb-4'>
                        Inclusive Health Package
                      </h5>
                      <p className='max-w-sm text-gray-400'>
                        We care for your well-being with dental and vision
                        benefits. No health expense stress.
                      </p>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='h-full p-8 border-2 border-gray-800 rounded-xl'>
                      <div className='flex w-16 h-16 mb-8 items-center justify-center rounded-full bg-gray-800'>
                        <img
                          src='aurora-assets/careers/rocket-icon-circle.png'
                          alt=''
                        />
                      </div>
                      <h5 className='text-2xl font-bold text-white mb-4'>
                        Learn and Grow
                      </h5>
                      <p className='max-w-sm text-gray-400'>
                        We foster your career growth through mentorship and
                        networking events.
                      </p>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='h-full p-8 border-2 border-gray-800 rounded-xl'>
                      <div className='flex w-16 h-16 mb-8 items-center justify-center rounded-full bg-gray-800'>
                        <img
                          src='aurora-assets/careers/clock-icon-circle.png'
                          alt=''
                        />
                      </div>
                      <h5 className='text-2xl font-bold text-white mb-4'>
                        Flexible Working Style
                      </h5>
                      <p className='max-w-sm text-gray-400'>
                        We are 100% remote-friendly, work with team mates from
                        different time zones.
                      </p>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='h-full p-8 border-2 border-gray-800 rounded-xl'>
                      <div className='flex w-16 h-16 mb-8 items-center justify-center rounded-full bg-gray-800'>
                        <img
                          src='aurora-assets/careers/beach-icon-circle.png'
                          alt=''
                        />
                      </div>
                      <h5 className='text-2xl font-bold text-white mb-4'>
                        Balanced Working Life
                      </h5>
                      <p className='max-w-sm text-gray-400'>
                        We offer a flexible 4-weeks vacation policy. Recharge
                        whenever you need to.
                      </p>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='h-full p-8 border-2 border-gray-800 rounded-xl'>
                      <div className='flex w-16 h-16 mb-8 items-center justify-center rounded-full bg-gray-800'>
                        <img
                          src='aurora-assets/careers/earth-icon-circle.png'
                          alt=''
                        />
                      </div>
                      <h5 className='text-2xl font-bold text-white mb-4'>
                        Diversity and Inclusion
                      </h5>
                      <p className='max-w-sm text-gray-400'>
                        Our strength lies in team members diversity and
                        inclusive culture.
                      </p>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='h-full p-8 border-2 border-gray-800 rounded-xl'>
                      <div className='flex w-16 h-16 mb-8 items-center justify-center rounded-full bg-gray-800'>
                        <img
                          src='aurora-assets/careers/money-icon-circle.png'
                          alt=''
                        />
                      </div>
                      <h5 className='text-2xl font-bold text-white mb-4'>
                        Generous Perks Program
                      </h5>
                      <p className='max-w-sm text-gray-400'>
                        We provide perk stipends for home office, learning,
                        wellness, and connectivity.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='flex items-center mb-4'>
                  <h3 className='font-heading mr-2 text-4xl font-bold text-white'>
                    Open Positions
                  </h3>
                  <span className='inline-block px-3 py-1 text-white bg-gray-800 rounded-full'>
                    6
                  </span>
                </div>
                <p className='text-lg text-gray-400 mb-16'>
                  We believe that the freedom to work remotely allows for deeper
                  and more meaningful work.
                </p>
                <div className='mb-16 pb-8 border-b border-gray-800'>
                  <h6 className='text-lg font-medium text-gray-500 mb-6'>
                    Design
                  </h6>
                  <div className='flex flex-col xs:flex-row mb-6 xs:items-center xs:justify-between'>
                    <h5 className='text-lg text-white font-medium mb-3 xs:mb-0'>
                      Head of Product Design
                    </h5>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                      href='#'
                    >
                      <span className='mr-2'>View job</span>
                      <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                  <div className='flex flex-col xs:flex-row xs:items-center xs:justify-between'>
                    <h5 className='text-lg text-white font-medium mb-3 xs:mb-0'>
                      Senior Product Designer
                    </h5>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                      href='#'
                    >
                      <span className='mr-2'>View job</span>
                      <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <div className='mb-16 pb-8 border-b border-gray-800'>
                  <h6 className='text-lg font-medium text-gray-500 mb-6'>
                    Engineering
                  </h6>
                  <div className='flex flex-col xs:flex-row mb-6 xs:items-center xs:justify-between'>
                    <h5 className='text-lg text-white font-medium mb-3 xs:mb-0'>
                      Staff Engineer
                    </h5>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                      href='#'
                    >
                      <span className='mr-2'>View job</span>
                      <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                  <div className='flex flex-col xs:flex-row xs:items-center xs:justify-between'>
                    <h5 className='text-lg text-white font-medium mb-3 xs:mb-0'>
                      Senior Frontend Engineer
                    </h5>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                      href='#'
                    >
                      <span className='mr-2'>View job</span>
                      <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <div className='mb-16 pb-8 border-b border-gray-800'>
                  <h6 className='text-lg font-medium text-gray-500 mb-6'>
                    Marketing
                  </h6>
                  <div className='flex flex-col xs:flex-row xs:items-center xs:justify-between'>
                    <h5 className='text-lg text-white font-medium mb-3 xs:mb-0'>
                      Marketing Lead
                    </h5>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                      href='#'
                    >
                      <span className='mr-2'>View job</span>
                      <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <div className='pb-8 border-b border-gray-800'>
                  <h6 className='text-lg font-medium text-gray-500 mb-6'>
                    Customer Experience
                  </h6>
                  <div className='flex flex-col xs:flex-row xs:items-center xs:justify-between'>
                    <h5 className='text-lg text-white font-medium mb-3 xs:mb-0'>
                      Customer Experience Lead
                    </h5>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                      href='#'
                    >
                      <span className='mr-2'>View job</span>
                      <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green overflow-hidden'>
          <div className='container px-4 mb-28 mx-auto'>
            <div className='text-center'>
              <span className='inline-flex items-center h-7 mb-4 px-2 text-sm font-medium text-white bg-teal-800 rounded-full'>
                CAREERS
              </span>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                Join our global team
              </h1>
              <p className='text-lg text-gray-300 mb-10'>
                We are on a mission to make organizing assets a delightful
                experience.
              </p>
              <a
                className='xs:flex-shrink-0 group relative w-full xs:w-auto h-12 flex xs:inline-flex items-center justify-center px-5 font-bold text-gray-700 bg-white rounded-lg transition-all duration-300 focus:outline-none'
                href='#'
              >
                <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-gray-500 animate-pulse group-hover:ring-0 transition duration-300' />
                <span>See open positions</span>
              </a>
            </div>
          </div>
          <div className='flex -mx-4 mb-28 items-center justify-center'>
            <img
              className='block w-full h-40 md:h-96 mr-4 md:mr-8 object-cover rounded-xl'
              src='aurora-assets/careers/image-careers-people1.png'
              alt=''
            />
            <img
              className='block w-full h-40 md:h-96 mr-4 md:mr-8 object-cover rounded-xl'
              src='aurora-assets/careers/image-careers-people3.png'
              alt=''
            />
            <img
              className='block w-full h-40 md:h-96 object-cover rounded-xl'
              src='aurora-assets/careers/image-careers-people2.png'
              alt=''
            />
          </div>
          <div className='container px-4 mb-28 mx-auto'>
            <div className='max-w-4xl mx-auto'>
              <div className='pb-20 mb-20 border-b border-white border-opacity-20'>
                <h3 className='font-heading text-4xl font-bold text-white mb-4'>
                  Benefits
                </h3>
                <p className='text-lg text-gray-300 mb-16'>
                  We help our employees thrive
                </p>
                <div className='flex flex-wrap -mx-4 -mb-8'>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='h-full p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='flex w-16 h-16 mb-8 items-center justify-center rounded-full bg-teal-800'>
                        <img
                          src='aurora-assets/careers/hospital-icon-circle.png'
                          alt=''
                        />
                      </div>
                      <h5 className='text-2xl font-bold text-white mb-4'>
                        Inclusive Health Package
                      </h5>
                      <p className='max-w-sm text-gray-400'>
                        We care for your well-being with dental and vision
                        benefits. No health expense stress.
                      </p>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='h-full p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='flex w-16 h-16 mb-8 items-center justify-center rounded-full bg-teal-800'>
                        <img
                          src='aurora-assets/careers/rocket-icon-circle.png'
                          alt=''
                        />
                      </div>
                      <h5 className='text-2xl font-bold text-white mb-4'>
                        Learn and Grow
                      </h5>
                      <p className='max-w-sm text-gray-400'>
                        We foster your career growth through mentorship and
                        networking events.
                      </p>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='h-full p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='flex w-16 h-16 mb-8 items-center justify-center rounded-full bg-teal-800'>
                        <img
                          src='aurora-assets/careers/clock-icon-circle.png'
                          alt=''
                        />
                      </div>
                      <h5 className='text-2xl font-bold text-white mb-4'>
                        Flexible Working Style
                      </h5>
                      <p className='max-w-sm text-gray-400'>
                        We are 100% remote-friendly, work with team mates from
                        different time zones.
                      </p>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='h-full p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='flex w-16 h-16 mb-8 items-center justify-center rounded-full bg-teal-800'>
                        <img
                          src='aurora-assets/careers/beach-icon-circle.png'
                          alt=''
                        />
                      </div>
                      <h5 className='text-2xl font-bold text-white mb-4'>
                        Balanced Working Life
                      </h5>
                      <p className='max-w-sm text-gray-400'>
                        We offer a flexible 4-weeks vacation policy. Recharge
                        whenever you need to.
                      </p>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='h-full p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='flex w-16 h-16 mb-8 items-center justify-center rounded-full bg-teal-800'>
                        <img
                          src='aurora-assets/careers/earth-icon-circle.png'
                          alt=''
                        />
                      </div>
                      <h5 className='text-2xl font-bold text-white mb-4'>
                        Diversity and Inclusion
                      </h5>
                      <p className='max-w-sm text-gray-400'>
                        Our strength lies in team members diversity and
                        inclusive culture.
                      </p>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4 mb-8'>
                    <div className='h-full p-8 bg-white bg-opacity-10 border-2 border-white border-opacity-20 rounded-xl'>
                      <div className='flex w-16 h-16 mb-8 items-center justify-center rounded-full bg-teal-800'>
                        <img
                          src='aurora-assets/careers/money-icon-circle.png'
                          alt=''
                        />
                      </div>
                      <h5 className='text-2xl font-bold text-white mb-4'>
                        Generous Perks Program
                      </h5>
                      <p className='max-w-sm text-gray-400'>
                        We provide perk stipends for home office, learning,
                        wellness, and connectivity.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='flex items-center mb-4'>
                  <h3 className='font-heading mr-2 text-4xl font-bold text-white'>
                    Open Positions
                  </h3>
                  <span className='inline-block px-3 py-1 text-white bg-teal-800 rounded-full'>
                    6
                  </span>
                </div>
                <p className='text-lg text-gray-300 mb-16'>
                  We believe that the freedom to work remotely allows for deeper
                  and more meaningful work.
                </p>
                <div className='mb-16 pb-8 border-b border-white border-opacity-20'>
                  <h6 className='text-lg font-medium text-gray-200 mb-6'>
                    Design
                  </h6>
                  <div className='flex flex-col xs:flex-row mb-6 xs:items-center xs:justify-between'>
                    <h5 className='text-lg text-white font-medium mb-3 xs:mb-0'>
                      Head of Product Design
                    </h5>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-500'
                      href='#'
                    >
                      <span className='mr-2'>View job</span>
                      <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                  <div className='flex flex-col xs:flex-row xs:items-center xs:justify-between'>
                    <h5 className='text-lg text-white font-medium mb-3 xs:mb-0'>
                      Senior Product Designer
                    </h5>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-500'
                      href='#'
                    >
                      <span className='mr-2'>View job</span>
                      <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <div className='mb-16 pb-8 border-b border-white border-opacity-20'>
                  <h6 className='text-lg font-medium text-gray-200 mb-6'>
                    Engineering
                  </h6>
                  <div className='flex flex-col xs:flex-row mb-6 xs:items-center xs:justify-between'>
                    <h5 className='text-lg text-white font-medium mb-3 xs:mb-0'>
                      Staff Engineer
                    </h5>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-500'
                      href='#'
                    >
                      <span className='mr-2'>View job</span>
                      <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                  <div className='flex flex-col xs:flex-row xs:items-center xs:justify-between'>
                    <h5 className='text-lg text-white font-medium mb-3 xs:mb-0'>
                      Senior Frontend Engineer
                    </h5>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-500'
                      href='#'
                    >
                      <span className='mr-2'>View job</span>
                      <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <div className='mb-16 pb-8 border-b border-white border-opacity-20'>
                  <h6 className='text-lg font-medium text-gray-200 mb-6'>
                    Marketing
                  </h6>
                  <div className='flex flex-col xs:flex-row xs:items-center xs:justify-between'>
                    <h5 className='text-lg text-white font-medium mb-3 xs:mb-0'>
                      Marketing Lead
                    </h5>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-500'
                      href='#'
                    >
                      <span className='mr-2'>View job</span>
                      <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <div className='pb-8 border-b border-white border-opacity-20'>
                  <h6 className='text-lg font-medium text-gray-200 mb-6'>
                    Customer Experience
                  </h6>
                  <div className='flex flex-col xs:flex-row xs:items-center xs:justify-between'>
                    <h5 className='text-lg text-white font-medium mb-3 xs:mb-0'>
                      Customer Experience Lead
                    </h5>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-500'
                      href='#'
                    >
                      <span className='mr-2'>View job</span>
                      <span className='transform group-hover:translate-x-0.5 transition duration-200'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10.0001H15.4165M15.4165 10.0001L10.4165 5.00012M15.4165 10.0001L10.4165 15.0001'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    <FooterComponent /></React.Fragment>
  );
}

