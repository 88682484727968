import React from 'react';

import { Flex, Text, Button } from '@radix-ui/themes';


import {FooterComponent} from './Footers.jsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Stats() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <div className='text-center'>
              <h3 className='font-heading tracking-tight text-4xl md:text-5xl font-bold mb-20'>
                Let the numbers do the talking
              </h3>
              <div className='flex flex-wrap -mx-4 -mb-12'>
                <div className='w-full xs:w-1/2 lg:w-1/4 px-4 mb-12'>
                  <div>
                    <span className='block mb-4 text-5xl text-transparent bg-clip-text bg-gradient-to-b font-bold from-cyanGreen-800 via-green-700 to-cyan-600'>
                      4.7
                    </span>
                    <span className='text-lg text-gray-500'>
                      Stars on App Store
                    </span>
                  </div>
                </div>
                <div className='w-full xs:w-1/2 lg:w-1/4 px-4 mb-12'>
                  <div>
                    <span className='block mb-4 text-5xl text-transparent bg-clip-text bg-gradient-to-b font-bold from-cyanGreen-800 via-green-700 to-cyan-600'>
                      2M+
                    </span>
                    <span className='text-lg text-gray-500'>
                      Happy Global Users
                    </span>
                  </div>
                </div>
                <div className='w-full xs:w-1/2 lg:w-1/4 px-4 mb-12'>
                  <div>
                    <span className='block mb-4 text-5xl text-transparent bg-clip-text bg-gradient-to-b font-bold from-cyanGreen-800 via-green-700 to-cyan-600'>
                      150+
                    </span>
                    <span className='text-lg text-gray-500'>
                      File Extensions Supported
                    </span>
                  </div>
                </div>
                <div className='w-full xs:w-1/2 lg:w-1/4 px-4 mb-12'>
                  <div>
                    <span className='block mb-4 text-5xl text-transparent bg-clip-text bg-gradient-to-b font-bold from-cyanGreen-800 via-green-700 to-cyan-600'>
                      80%
                    </span>
                    <span className='text-lg text-gray-500'>
                      Productivity Increased
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-5/12 xl:w-1/2 px-4 mb-10 lg:mb-0'>
                <div className='max-w-md'>
                  <h3 className='font-heading tracking-tight text-4xl md:text-5xl font-bold text-white mb-4'>
                    Let the numbers do the talking
                  </h3>
                  <p className='text-lg text-gray-400'>
                    Aurora makes saving and organizing marketing assets a
                    delightful experience.
                  </p>
                </div>
              </div>
              <div className='w-full lg:w-7/12 xl:w-1/2 px-4'>
                <div className='pt-10 lg:pt-0 lg:pl-10 border-t lg:border-t-0 lg:border-l border-gray-700'>
                  <div className='flex flex-wrap -mx-4'>
                    <div className='w-full xs:w-1/2 px-4 mb-10'>
                      <div className='text-center xs:text-left'>
                        <span className='block mb-3 text-4xl font-bold text-white'>
                          4.7
                        </span>
                        <span className='text-lg text-gray-400'>
                          Stars on App Store
                        </span>
                      </div>
                    </div>
                    <div className='w-full xs:w-1/2 px-4 mb-10'>
                      <div className='text-center xs:text-left'>
                        <span className='block mb-3 text-4xl font-bold text-white'>
                          2M+
                        </span>
                        <span className='text-lg text-gray-400'>
                          Happy Global Users
                        </span>
                      </div>
                    </div>
                    <div className='w-full xs:w-1/2 px-4 mb-10'>
                      <div className='text-center xs:text-left'>
                        <span className='block mb-3 text-4xl font-bold text-white'>
                          150+
                        </span>
                        <span className='text-lg text-gray-400'>
                          File Extensions Supported
                        </span>
                      </div>
                    </div>
                    <div className='w-full xs:w-1/2 px-4 mb-10'>
                      <div className='text-center xs:text-left'>
                        <span className='block mb-3 text-4xl font-bold text-white'>
                          80%
                        </span>
                        <span className='text-lg text-gray-400'>
                          Productivity Increased
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-xs mx-auto md:max-w-none'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full md:w-1/3 px-4 mb-6 md:mb-0'>
                  <div className='pt-6 text-center pb-12 md:pb-6 md:pr-4 lg:pr-0 border-b md:border-b-0 md:border-r border-gray-200'>
                    <div className='flex mb-4 items-center justify-center'>
                      <span className='flex items-center justify-center w-10 h-10 mr-4 border border-gray-200 rounded-full'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M7.15647 6.8633L9.32107 2.50313C9.59895 1.9434 10.4017 1.9434 10.6796 2.50313L12.8442 6.8633L17.6849 7.5668C18.306 7.65706 18.5535 8.41614 18.1039 8.85159L14.6018 12.2431L15.4283 17.0345C15.5344 17.6498 14.8848 18.119 14.3291 17.8284L10.0003 15.565L5.67155 17.8284C5.11583 18.119 4.46623 17.6498 4.57237 17.0345L5.39887 12.2431L1.89678 8.85159C1.44714 8.41614 1.69468 7.65706 2.31576 7.5668L7.15647 6.8633Z'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <span className='text-5xl font-bold'>4.7</span>
                    </div>
                    <span className='text-lg'>Stars on App Store</span>
                  </div>
                </div>
                <div className='w-full md:w-1/3 px-4 mb-6 md:mb-0'>
                  <div className='pt-6 text-center pb-12 md:pb-6 md:pr-4 lg:pr-0 border-b md:border-b-0 md:border-r border-gray-200'>
                    <div className='flex mb-4 items-center justify-center'>
                      <span className='flex items-center justify-center w-10 h-10 mr-4 border border-gray-200 rounded-full'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.16699 16.6667V15.8333C4.16699 12.6117 6.77866 10 10.0003 10V10C13.222 10 15.8337 12.6117 15.8337 15.8333V16.6667'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M10.0003 10C11.8413 10 13.3337 8.50761 13.3337 6.66666C13.3337 4.82571 11.8413 3.33333 10.0003 3.33333C8.15938 3.33333 6.66699 4.82571 6.66699 6.66666C6.66699 8.50761 8.15938 10 10.0003 10Z'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <span className='text-5xl font-bold'>2M+</span>
                    </div>
                    <span className='text-lg'>Happy Global Users</span>
                  </div>
                </div>
                <div className='w-full md:w-1/3 px-4'>
                  <div className='py-6 text-center'>
                    <div className='flex mb-4 items-center justify-center'>
                      <span className='flex items-center justify-center w-10 h-10 mr-4 border border-gray-200 rounded-full'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2987_20812)'>
                            <path
                              d='M10 5L10 10L15 10'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M10.0003 18.3333C14.6027 18.3333 18.3337 14.6024 18.3337 10C18.3337 5.39763 14.6027 1.66667 10.0003 1.66667C5.39795 1.66667 1.66699 5.39763 1.66699 10C1.66699 14.6024 5.39795 18.3333 10.0003 18.3333Z'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </span>
                      <span className='text-5xl font-bold'>80%</span>
                    </div>
                    <span className='text-lg'>Productivity Increased</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='text-center'>
              <h3 className='font-heading tracking-tight text-4xl md:text-5xl font-bold text-white mb-20'>
                Let the numbers do the talking
              </h3>
              <div className='flex flex-wrap -mx-4 -mb-12'>
                <div className='w-full xs:w-1/2 lg:w-1/4 px-4 mb-12'>
                  <div>
                    <span className='block mb-4 text-5xl text-transparent bg-clip-text bg-gradient-to-b font-bold from-cyanGreen-800 via-green-700 to-cyan-600'>
                      4.7
                    </span>
                    <span className='text-lg text-gray-200'>
                      Stars on App Store
                    </span>
                  </div>
                </div>
                <div className='w-full xs:w-1/2 lg:w-1/4 px-4 mb-12'>
                  <div>
                    <span className='block mb-4 text-5xl text-transparent bg-clip-text bg-gradient-to-b font-bold from-cyanGreen-800 via-green-700 to-cyan-600'>
                      2M+
                    </span>
                    <span className='text-lg text-gray-200'>
                      Happy Global Users
                    </span>
                  </div>
                </div>
                <div className='w-full xs:w-1/2 lg:w-1/4 px-4 mb-12'>
                  <div>
                    <span className='block mb-4 text-5xl text-transparent bg-clip-text bg-gradient-to-b font-bold from-cyanGreen-800 via-green-700 to-cyan-600'>
                      150+
                    </span>
                    <span className='text-lg text-gray-200'>
                      File Extensions Supported
                    </span>
                  </div>
                </div>
                <div className='w-full xs:w-1/2 lg:w-1/4 px-4 mb-12'>
                  <div>
                    <span className='block mb-4 text-5xl text-transparent bg-clip-text bg-gradient-to-b font-bold from-cyanGreen-800 via-green-700 to-cyan-600'>
                      80%
                    </span>
                    <span className='text-lg text-gray-200'>
                      Productivity Increased
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <div className='text-center mb-20'>
              <h3 className='font-heading tracking-tight text-4xl md:text-5xl font-bold'>
                Let the numbers do the talking
              </h3>
            </div>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 mb-10'>
                <div className='max-w-xs mx-auto text-center'>
                  <span className='block mb-4 text-4xl font-bold'>#1</span>
                  <span className='text-lg text-gray-500'>
                    Useful for large collections of files with 24/7 support.
                  </span>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 mb-10'>
                <div className='max-w-xs mx-auto text-center'>
                  <span className='block mb-4 text-4xl font-bold'>2M+</span>
                  <span className='text-lg text-gray-500'>
                    Happy users spread across the globe, and keep growing.
                  </span>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 mb-10'>
                <div className='max-w-xs mx-auto text-center'>
                  <span className='block mb-4 text-4xl font-bold'>150+</span>
                  <span className='text-lg text-gray-500'>
                    Type of file extensions supported, added regularly.
                  </span>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 mb-10'>
                <div className='max-w-xs mx-auto text-center'>
                  <span className='block mb-4 text-4xl font-bold'>135 hrs</span>
                  <span className='text-lg text-gray-500'>
                    Saved hours by marketing teams yearly.
                  </span>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 mb-10'>
                <div className='max-w-xs mx-auto text-center'>
                  <span className='block mb-4 text-4xl font-bold'>80%</span>
                  <span className='text-lg text-gray-500'>
                    Improved team productivity, focus on what matters.
                  </span>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 mb-10'>
                <div className='max-w-xs mx-auto text-center'>
                  <span className='block mb-4 text-4xl font-bold'>4.7</span>
                  <span className='text-lg text-gray-500'>
                    Stars out of 5.0 from 32K+ reviews on App Store.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='text-center mb-20'>
              <h3 className='font-heading tracking-tight text-4xl md:text-5xl font-bold text-white'>
                Let the numbers do the talking
              </h3>
            </div>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 mb-10'>
                <div className='max-w-xs mx-auto text-center'>
                  <span className='block mb-4 text-4xl font-bold text-white'>
                    #1
                  </span>
                  <span className='text-lg text-gray-400'>
                    Useful for large collections of files with 24/7 support.
                  </span>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 mb-10'>
                <div className='max-w-xs mx-auto text-center'>
                  <span className='block mb-4 text-4xl font-bold text-white'>
                    2M+
                  </span>
                  <span className='text-lg text-gray-400'>
                    Happy users spread across the globe, and keep growing.
                  </span>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 mb-10'>
                <div className='max-w-xs mx-auto text-center'>
                  <span className='block mb-4 text-4xl font-bold text-white'>
                    150+
                  </span>
                  <span className='text-lg text-gray-400'>
                    Type of file extensions supported, added regularly.
                  </span>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 mb-10'>
                <div className='max-w-xs mx-auto text-center'>
                  <span className='block mb-4 text-4xl font-bold text-white'>
                    135 hrs
                  </span>
                  <span className='text-lg text-gray-400'>
                    Saved hours by marketing teams yearly.
                  </span>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 mb-10'>
                <div className='max-w-xs mx-auto text-center'>
                  <span className='block mb-4 text-4xl font-bold text-white'>
                    80%
                  </span>
                  <span className='text-lg text-gray-400'>
                    Improved team productivity, focus on what matters.
                  </span>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 mb-10'>
                <div className='max-w-xs mx-auto text-center'>
                  <span className='block mb-4 text-4xl font-bold text-white'>
                    4.7
                  </span>
                  <span className='text-lg text-gray-400'>
                    Stars out of 5.0 from 32K+ reviews on App Store.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-xs mx-auto md:max-w-none'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full md:w-1/3 px-4 mb-6 md:mb-0'>
                  <div className='pt-6 text-center pb-12 md:pb-6 md:pr-4 lg:pr-0 border-b md:border-b-0 md:border-r border-gray-700'>
                    <div className='flex mb-4 items-center justify-center'>
                      <span className='flex items-center justify-center w-10 h-10 mr-4 bg-gray-800 border border-gray-700 rounded-full'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M7.15647 6.8633L9.32107 2.50313C9.59895 1.9434 10.4017 1.9434 10.6796 2.50313L12.8442 6.8633L17.6849 7.5668C18.306 7.65706 18.5535 8.41614 18.1039 8.85159L14.6018 12.2431L15.4283 17.0345C15.5344 17.6498 14.8848 18.119 14.3291 17.8284L10.0003 15.565L5.67155 17.8284C5.11583 18.119 4.46623 17.6498 4.57237 17.0345L5.39887 12.2431L1.89678 8.85159C1.44714 8.41614 1.69468 7.65706 2.31576 7.5668L7.15647 6.8633Z'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <span className='text-5xl font-bold text-white'>4.7</span>
                    </div>
                    <span className='text-lg text-white'>
                      Stars on App Store
                    </span>
                  </div>
                </div>
                <div className='w-full md:w-1/3 px-4 mb-6 md:mb-0'>
                  <div className='pt-6 text-center pb-12 md:pb-6 md:pr-4 lg:pr-0 border-b md:border-b-0 md:border-r border-gray-700'>
                    <div className='flex mb-4 items-center justify-center'>
                      <span className='flex items-center justify-center w-10 h-10 mr-4 bg-gray-800 border border-gray-700 rounded-full'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.16699 16.6667V15.8333C4.16699 12.6117 6.77866 10 10.0003 10V10C13.222 10 15.8337 12.6117 15.8337 15.8333V16.6667'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M10.0003 10C11.8413 10 13.3337 8.50761 13.3337 6.66666C13.3337 4.82571 11.8413 3.33333 10.0003 3.33333C8.15938 3.33333 6.66699 4.82571 6.66699 6.66666C6.66699 8.50761 8.15938 10 10.0003 10Z'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <span className='text-5xl font-bold text-white'>2M+</span>
                    </div>
                    <span className='text-lg text-white'>
                      Happy Global Users
                    </span>
                  </div>
                </div>
                <div className='w-full md:w-1/3 px-4'>
                  <div className='py-6 text-center'>
                    <div className='flex mb-4 items-center justify-center'>
                      <span className='flex items-center justify-center w-10 h-10 mr-4 bg-gray-800 border border-gray-700 rounded-full'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2987_20812)'>
                            <path
                              d='M10 5L10 10L15 10'
                              stroke='white'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M10.0003 18.3333C14.6027 18.3333 18.3337 14.6024 18.3337 10C18.3337 5.39763 14.6027 1.66667 10.0003 1.66667C5.39795 1.66667 1.66699 5.39763 1.66699 10C1.66699 14.6024 5.39795 18.3333 10.0003 18.3333Z'
                              stroke='white'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </span>
                      <span className='text-5xl font-bold text-white'>80%</span>
                    </div>
                    <span className='text-lg text-white'>
                      Productivity Increased
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='text-center'>
              <h3 className='font-heading tracking-tight text-4xl md:text-5xl font-bold text-white mb-20'>
                Let the numbers do the talking
              </h3>
              <div className='flex flex-wrap -mx-4 -mb-12'>
                <div className='w-full xs:w-1/2 lg:w-1/4 px-4 mb-12'>
                  <div>
                    <span className='block mb-4 text-5xl text-transparent bg-clip-text bg-gradient-to-b font-bold from-cyanGreen-800 via-green-700 to-cyan-600'>
                      4.7
                    </span>
                    <span className='text-lg text-gray-400'>
                      Stars on App Store
                    </span>
                  </div>
                </div>
                <div className='w-full xs:w-1/2 lg:w-1/4 px-4 mb-12'>
                  <div>
                    <span className='block mb-4 text-5xl text-transparent bg-clip-text bg-gradient-to-b font-bold from-cyanGreen-800 via-green-700 to-cyan-600'>
                      2M+
                    </span>
                    <span className='text-lg text-gray-400'>
                      Happy Global Users
                    </span>
                  </div>
                </div>
                <div className='w-full xs:w-1/2 lg:w-1/4 px-4 mb-12'>
                  <div>
                    <span className='block mb-4 text-5xl text-transparent bg-clip-text bg-gradient-to-b font-bold from-cyanGreen-800 via-green-700 to-cyan-600'>
                      150+
                    </span>
                    <span className='text-lg text-gray-400'>
                      File Extensions Supported
                    </span>
                  </div>
                </div>
                <div className='w-full xs:w-1/2 lg:w-1/4 px-4 mb-12'>
                  <div>
                    <span className='block mb-4 text-5xl text-transparent bg-clip-text bg-gradient-to-b font-bold from-cyanGreen-800 via-green-700 to-cyan-600'>
                      80%
                    </span>
                    <span className='text-lg text-gray-400'>
                      Productivity Increased
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='text-center'>
              <h3 className='font-heading tracking-tight text-4xl md:text-5xl font-bold text-white mb-20'>
                Let the numbers do the talking
              </h3>
              <div className='flex flex-wrap -mx-4 -mb-12'>
                <div className='w-full xs:w-1/2 lg:w-1/4 px-4 mb-12'>
                  <div>
                    <span className='block mb-4 text-5xl text-transparent bg-clip-text bg-gradient-to-b font-bold from-cyanGreen-800 via-green-700 to-cyan-600'>
                      4.7
                    </span>
                    <span className='text-lg text-gray-200'>
                      Stars on App Store
                    </span>
                  </div>
                </div>
                <div className='w-full xs:w-1/2 lg:w-1/4 px-4 mb-12'>
                  <div>
                    <span className='block mb-4 text-5xl text-transparent bg-clip-text bg-gradient-to-b font-bold from-cyanGreen-800 via-green-700 to-cyan-600'>
                      2M+
                    </span>
                    <span className='text-lg text-gray-200'>
                      Happy Global Users
                    </span>
                  </div>
                </div>
                <div className='w-full xs:w-1/2 lg:w-1/4 px-4 mb-12'>
                  <div>
                    <span className='block mb-4 text-5xl text-transparent bg-clip-text bg-gradient-to-b font-bold from-cyanGreen-800 via-green-700 to-cyan-600'>
                      150+
                    </span>
                    <span className='text-lg text-gray-200'>
                      File Extensions Supported
                    </span>
                  </div>
                </div>
                <div className='w-full xs:w-1/2 lg:w-1/4 px-4 mb-12'>
                  <div>
                    <span className='block mb-4 text-5xl text-transparent bg-clip-text bg-gradient-to-b font-bold from-cyanGreen-800 via-green-700 to-cyan-600'>
                      80%
                    </span>
                    <span className='text-lg text-gray-200'>
                      Productivity Increased
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='py-12 md:py-18 px-8 md:px-10 lg:px-18 bg-radial-dark-green rounded-xl'>
              <div className='text-center mb-20'>
                <h3 className='font-heading tracking-tight text-4xl md:text-5xl font-bold text-white'>
                  Let the numbers do the talking
                </h3>
              </div>
              <div className='flex flex-wrap -mx-4 -mb-10'>
                <div className='w-full md:w-1/3 px-4 mb-10'>
                  <div className='p-6 text-center border-2 border-white border-opacity-20 bg-white bg-opacity-10 rounded-xl'>
                    <span className='block mb-4 text-4xl font-bold text-white'>
                      4.7
                    </span>
                    <span className='text-lg text-gray-200'>
                      Stars on App Store
                    </span>
                  </div>
                </div>
                <div className='w-full md:w-1/3 px-4 mb-10'>
                  <div className='p-6 text-center border-2 border-white border-opacity-20 bg-white bg-opacity-10 rounded-xl'>
                    <span className='block mb-4 text-4xl font-bold text-white'>
                      2M+
                    </span>
                    <span className='text-lg text-gray-200'>
                      Happy Global Users
                    </span>
                  </div>
                </div>
                <div className='w-full md:w-1/3 px-4 mb-10'>
                  <div className='p-6 text-center border-2 border-white border-opacity-20 bg-white bg-opacity-10 rounded-xl'>
                    <span className='block mb-4 text-4xl font-bold text-white'>
                      80%
                    </span>
                    <span className='text-lg text-gray-200'>
                      Productivity Increased
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='text-center mb-20'>
              <h3 className='font-heading tracking-tight text-4xl md:text-5xl font-bold text-white'>
                Let the numbers do the talking
              </h3>
            </div>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 mb-10'>
                <div className='max-w-xs mx-auto text-center'>
                  <span className='block mb-4 text-4xl font-bold text-white'>
                    #1
                  </span>
                  <span className='text-lg text-gray-200'>
                    Useful for large collections of files with 24/7 support.
                  </span>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 mb-10'>
                <div className='max-w-xs mx-auto text-center'>
                  <span className='block mb-4 text-4xl font-bold text-white'>
                    2M+
                  </span>
                  <span className='text-lg text-gray-200'>
                    Happy users spread across the globe, and keep growing.
                  </span>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 mb-10'>
                <div className='max-w-xs mx-auto text-center'>
                  <span className='block mb-4 text-4xl font-bold text-white'>
                    150+
                  </span>
                  <span className='text-lg text-gray-200'>
                    Type of file extensions supported, added regularly.
                  </span>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 mb-10'>
                <div className='max-w-xs mx-auto text-center'>
                  <span className='block mb-4 text-4xl font-bold text-white'>
                    135 hrs
                  </span>
                  <span className='text-lg text-gray-200'>
                    Saved hours by marketing teams yearly.
                  </span>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 mb-10'>
                <div className='max-w-xs mx-auto text-center'>
                  <span className='block mb-4 text-4xl font-bold text-white'>
                    80%
                  </span>
                  <span className='text-lg text-gray-200'>
                    Improved team productivity, focus on what matters.
                  </span>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 mb-10'>
                <div className='max-w-xs mx-auto text-center'>
                  <span className='block mb-4 text-4xl font-bold text-white'>
                    4.7
                  </span>
                  <span className='text-lg text-gray-200'>
                    Stars out of 5.0 from 32K+ reviews on App Store.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-5/12 xl:w-1/2 px-4 mb-10 lg:mb-0'>
                <div className='max-w-md'>
                  <h3 className='font-heading tracking-tight text-4xl md:text-5xl font-bold text-white mb-4'>
                    Let the numbers do the talking
                  </h3>
                  <p className='text-lg text-gray-400'>
                    Aurora makes saving and organizing marketing assets a
                    delightful experience.
                  </p>
                </div>
              </div>
              <div className='w-full lg:w-7/12 xl:w-1/2 px-4'>
                <div className='pt-10 lg:pt-0 lg:pl-10 border-t lg:border-t-0 lg:border-l border-gray-700'>
                  <div className='flex flex-wrap -mx-4'>
                    <div className='w-full xs:w-1/2 px-4 mb-10'>
                      <div className='text-center xs:text-left'>
                        <span className='block mb-3 text-4xl font-bold text-white'>
                          4.7
                        </span>
                        <span className='text-lg text-gray-400'>
                          Stars on App Store
                        </span>
                      </div>
                    </div>
                    <div className='w-full xs:w-1/2 px-4 mb-10'>
                      <div className='text-center xs:text-left'>
                        <span className='block mb-3 text-4xl font-bold text-white'>
                          2M+
                        </span>
                        <span className='text-lg text-gray-400'>
                          Happy Global Users
                        </span>
                      </div>
                    </div>
                    <div className='w-full xs:w-1/2 px-4 mb-10'>
                      <div className='text-center xs:text-left'>
                        <span className='block mb-3 text-4xl font-bold text-white'>
                          150+
                        </span>
                        <span className='text-lg text-gray-400'>
                          File Extensions Supported
                        </span>
                      </div>
                    </div>
                    <div className='w-full xs:w-1/2 px-4 mb-10'>
                      <div className='text-center xs:text-left'>
                        <span className='block mb-3 text-4xl font-bold text-white'>
                          80%
                        </span>
                        <span className='text-lg text-gray-400'>
                          Productivity Increased
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-xs mx-auto md:max-w-none'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full md:w-1/3 px-4 mb-6 md:mb-0'>
                  <div className='pt-6 text-center pb-12 md:pb-6 md:pr-4 lg:pr-0 border-b md:border-b-0 md:border-r border-gray-700'>
                    <div className='flex mb-4 items-center justify-center'>
                      <span className='flex items-center justify-center w-10 h-10 mr-4 bg-gray-800 border border-gray-700 rounded-full'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M7.15647 6.8633L9.32107 2.50313C9.59895 1.9434 10.4017 1.9434 10.6796 2.50313L12.8442 6.8633L17.6849 7.5668C18.306 7.65706 18.5535 8.41614 18.1039 8.85159L14.6018 12.2431L15.4283 17.0345C15.5344 17.6498 14.8848 18.119 14.3291 17.8284L10.0003 15.565L5.67155 17.8284C5.11583 18.119 4.46623 17.6498 4.57237 17.0345L5.39887 12.2431L1.89678 8.85159C1.44714 8.41614 1.69468 7.65706 2.31576 7.5668L7.15647 6.8633Z'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <span className='text-5xl font-bold text-white'>4.7</span>
                    </div>
                    <span className='text-lg text-white'>
                      Stars on App Store
                    </span>
                  </div>
                </div>
                <div className='w-full md:w-1/3 px-4 mb-6 md:mb-0'>
                  <div className='pt-6 text-center pb-12 md:pb-6 md:pr-4 lg:pr-0 border-b md:border-b-0 md:border-r border-gray-700'>
                    <div className='flex mb-4 items-center justify-center'>
                      <span className='flex items-center justify-center w-10 h-10 mr-4 bg-gray-800 border border-gray-700 rounded-full'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.16699 16.6667V15.8333C4.16699 12.6117 6.77866 10 10.0003 10V10C13.222 10 15.8337 12.6117 15.8337 15.8333V16.6667'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M10.0003 10C11.8413 10 13.3337 8.50761 13.3337 6.66666C13.3337 4.82571 11.8413 3.33333 10.0003 3.33333C8.15938 3.33333 6.66699 4.82571 6.66699 6.66666C6.66699 8.50761 8.15938 10 10.0003 10Z'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <span className='text-5xl font-bold text-white'>2M+</span>
                    </div>
                    <span className='text-lg text-white'>
                      Happy Global Users
                    </span>
                  </div>
                </div>
                <div className='w-full md:w-1/3 px-4'>
                  <div className='py-6 text-center'>
                    <div className='flex mb-4 items-center justify-center'>
                      <span className='flex items-center justify-center w-10 h-10 mr-4 bg-gray-800 border border-gray-700 rounded-full'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_2987_20812)'>
                            <path
                              d='M10 5L10 10L15 10'
                              stroke='white'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M10.0003 18.3333C14.6027 18.3333 18.3337 14.6024 18.3337 10C18.3337 5.39763 14.6027 1.66667 10.0003 1.66667C5.39795 1.66667 1.66699 5.39763 1.66699 10C1.66699 14.6024 5.39795 18.3333 10.0003 18.3333Z'
                              stroke='white'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                        </svg>
                      </span>
                      <span className='text-5xl font-bold text-white'>80%</span>
                    </div>
                    <span className='text-lg text-white'>
                      Productivity Increased
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='py-12 md:py-18 px-8 md:px-10 lg:px-18 border-2 border-teal-800 rounded-xl'>
              <div className='text-center mb-20'>
                <h3 className='font-heading tracking-tight text-4xl md:text-5xl font-bold text-white'>
                  Let the numbers do the talking
                </h3>
              </div>
              <div className='flex flex-wrap -mx-4 -mb-10'>
                <div className='w-full md:w-1/3 px-4 mb-10'>
                  <div className='p-6 text-center bg-teal-800 border-2 border-teal-700 rounded-xl'>
                    <span className='block mb-4 text-4xl font-bold text-white'>
                      4.7
                    </span>
                    <span className='text-lg text-gray-400'>
                      Stars on App Store
                    </span>
                  </div>
                </div>
                <div className='w-full md:w-1/3 px-4 mb-10'>
                  <div className='p-6 text-center bg-teal-800 border-2 border-teal-700 rounded-xl'>
                    <span className='block mb-4 text-4xl font-bold text-white'>
                      2M+
                    </span>
                    <span className='text-lg text-gray-400'>
                      Happy Global Users
                    </span>
                  </div>
                </div>
                <div className='w-full md:w-1/3 px-4 mb-10'>
                  <div className='p-6 text-center bg-teal-800 border-2 border-teal-700 rounded-xl'>
                    <span className='block mb-4 text-4xl font-bold text-white'>
                      80%
                    </span>
                    <span className='text-lg text-gray-400'>
                      Productivity Increased
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    <FooterComponent /></React.Fragment>
  );
}

