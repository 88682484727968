import React from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';

import IndexPage from './pages/Index.js';
import HeadersPage from './pages/Headers.js';
import FeaturesPage from './pages/Features.js';
import Features2Page from './pages/Features2.js';
import PricingPage from './pages/Pricing.js';
import TestimonialsPage from './pages/Testimonials.js';
import FaqPage from './pages/Faq.js';
import BlogPage from './pages/Blog.js';
import ContentPage from './pages/Content.js';
import TeamPage from './pages/Team.js';
import CareerPage from './pages/Career.js';
import NewsletterPage from './pages/Newsletter.js';
import CallToActionPage from './pages/Call-to-action.js';
import SignUpPage from './pages/Sign-up.js';
import SignInPage from './pages/Sign-in.js';
import LogoCloudsPage from './pages/Logo-clouds.js';
import StatsPage from './pages/Stats.js';
import ContactsPage from './pages/Contacts.js';
import HttpCodesPage from './pages/Http-codes.js';
import CookiesPage from './pages/Cookies.js';
import FootersPage, {FooterComponent} from './pages/Footers.jsx';
import NavigationsPage from './pages/Navigations.js';


function App() {
    return (

    <Routes>
      <Route path="/" element={<IndexPage />} />
         
      <Route path="/headers" element={<HeadersPage />} />
         
      <Route path="/features" element={<FeaturesPage />} />
         
      <Route path="/features2" element={<Features2Page />} />
         
      <Route path="/pricing" element={<PricingPage />} />
         
      <Route path="/testimonials" element={<TestimonialsPage />} />
         
      <Route path="/faq" element={<FaqPage />} />
         
      <Route path="/blog" element={<BlogPage />} />
         
      <Route path="/content" element={<ContentPage />} />
         
      <Route path="/team" element={<TeamPage />} />
         
      <Route path="/career" element={<CareerPage />} />
         
      <Route path="/newsletter" element={<NewsletterPage />} />
         
      <Route path="/calltoaction" element={<CallToActionPage />} />
         
      <Route path="/signup" element={<SignUpPage />} />
         
      <Route path="/signin" element={<SignInPage />} />
         
      <Route path="/logoclouds" element={<LogoCloudsPage />} />
         
      <Route path="/stats" element={<StatsPage />} />
         
      <Route path="/contacts" element={<ContactsPage />} />
         
      <Route path="/httpcodes" element={<HttpCodesPage />} />
         
      <Route path="/cookies" element={<CookiesPage />} />
         
      <Route path="/footers" element={<FootersPage />} />
         
      <Route path="/navigations" element={<NavigationsPage />} />
      </Routes>

  );
}

export default App;
