import React from 'react';

import { Flex, Text } from '@radix-ui/themes';


import {FooterComponent} from './Footers.jsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Blog() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='relative pt-12 md:pt-24 bg-gray-50'>
          <div className='container px-4 pb-12 lg:pb-24 mx-auto'>
            <div className='text-center mb-24'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-bold mb-4'>
                Blog &amp; Articles
              </h1>
              <p className='text-lg text-gray-500'>
                Articles about tips, resources, and strategy.
              </p>
            </div>
            <a className='group block mb-16' href='#'>
              <div className='flex flex-wrap -mx-4 items-center'>
                <div className='w-full md:w-1/2 lg:w-5/12 px-4 mt-10 lg:mt-0 order-last md:order-first'>
                  <div className='max-w-sm'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-500'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-500'>4 min read</span>
                    </div>
                    <h2 className='group-hover:text-yellowGreen-600 font-heading tracking-tight text-3xl md:text-4xl text-gray-900 font-semibold mb-6'>
                      Effortless Asset Organization: Simplify with Bookmarking
                    </h2>
                    <p className='text-lg text-gray-500 mb-4'>
                      We've all experienced the chaos of multiple spreadsheets,
                      tracking and insight tools...
                    </p>
                    <div className='flex items-center'>
                      <img
                        className='block w-12 h-12 rounded-full object-contain'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <div className='ml-3'>
                        <span className='block text-sm font-semibold'>
                          Max Ranver
                        </span>
                        <span className='block text-sm font-medium text-gray-500'>
                          Content Writer, Aurora
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-7/12 px-4'>
                  <div className='flex h-full w-full items-center justify-end'>
                    <img
                      className='block w-full object-cover rounded-3xl group-hover:border-yellowGreen-500 border shadow-lg ring ring-transparent group-hover:ring-yellowGreen-500 group-hover:shadow-none'
                      src='aurora-assets/blog/blog-2-medium3-green.png'
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className='py-12 md:py-24 bg-white'>
            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-3/12 px-4 mb-10 lg:mb-0'>
                  <div className='max-w-xs pr-10'>
                    <div className='flex h-12 py-3 px-4 mb-6 items-center bg-white border rounded-lg'>
                      <button variant="classic"
                        className='inline-block flex-shrink-0 mr-4 text-gray-900 hover:text-yellowGreen-600 focus:outline-none'
                        type='submit'
                      >
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M14.167 14.1667L17.5003 17.5001'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333C11.0108 15.8333 12.6801 15.0846 13.887 13.8744C15.0897 12.6685 15.8333 11.0044 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </button>
                      <input
                        className='w-full text-gray-500 placeholder-gray-500 bg-transparent outline-none'
                        type='search'
                        placeholder='Search'
                      />
                    </div>
                    <ul className='pr-6'>
                      <li className='mb-3'>
                        <a
                          className='block px-6 text-gray-900 font-semibold border-l-2 border-yellowGreen-500'
                          href='#'
                        >
                          All
                        </a>
                      </li>
                      <li className='mb-3'>
                        <a
                          className='relative group block px-6 text-gray-500 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                          href='#'
                        >
                          <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-gray-50' />
                          <span className='relative'>Featured</span>
                        </a>
                      </li>
                      <li className='mb-3'>
                        <a
                          className='relative group block px-6 text-gray-500 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                          href='#'
                        >
                          <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-gray-50' />
                          <span className='relative'>Tutorials</span>
                        </a>
                      </li>
                      <li className='mb-3'>
                        <a
                          className='relative group block px-6 text-gray-500 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                          href='#'
                        >
                          <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-gray-50' />
                          <span className='relative'>Product</span>
                        </a>
                      </li>
                      <li className='mb-3'>
                        <a
                          className='relative group block px-6 text-gray-500 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                          href='#'
                        >
                          <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-gray-50' />
                          <span className='relative'>Tips</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className='relative group block px-6 text-gray-500 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                          href='#'
                        >
                          <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-gray-50' />
                          <span className='relative'>Insights</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='w-full lg:w-9/12 px-4'>
                  <div className='flex flex-wrap -mx-4'>
                    <div className='w-full md:w-1/2 px-4 mb-16'>
                      <a
                        className='flex flex-col h-full p-2 bg-white rounded-xl shadow-xl overflow-hidden border border-gray-100 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                        href='#'
                      >
                        <div className='h-64 w-full rounded-xl overflow-hidden'>
                          <img
                            className='block w-full h-full object-cover'
                            src='aurora-assets/blog/blog-2-medium2-green.png'
                            alt=''
                          />
                        </div>
                        <div className='flex-grow-1 pt-6 pb-8 px-6'>
                          <div className='flex mb-2 items-center'>
                            <span className='text-sm text-gray-500'>
                              20 Jul 2023
                            </span>
                            <span className='mx-3'>
                              <svg
                                width={4}
                                height={4}
                                viewBox='0 0 4 4'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                              </svg>
                            </span>
                            <span className='text-sm text-gray-500'>
                              4 min read
                            </span>
                          </div>
                          <h2 className='font-heading tracking-tight text-xl text-gray-900 font-semibold mb-6'>
                            Save Time, Stay Organized: Uncover the Magic
                          </h2>
                          <p className='text-sm text-gray-500 mb-4'>
                            Discover transformative hacks that will
                            revolutionize your productivity
                          </p>
                          <div className='flex items-center'>
                            <span className='text-sm text-gray-500'>By</span>
                            <img
                              className='block w-5 h-5 mx-2 rounded-full'
                              src='aurora-assets/blog/avatar-medium.png'
                              alt=''
                            />
                            <span className='text-sm font-medium'>
                              Max Ranver
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='w-full md:w-1/2 px-4 mb-16'>
                      <a
                        className='flex flex-col h-full p-2 bg-white rounded-xl shadow-xl overflow-hidden border border-gray-100 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                        href='#'
                      >
                        <div className='h-64 rounded-xl overflow-hidden'>
                          <img
                            className='block w-full h-full object-cover'
                            src='aurora-assets/blog/blog-3-medium3-green.png'
                            alt=''
                          />
                        </div>
                        <div className='flex-grow-1 pt-6 pb-8 px-6'>
                          <div className='flex mb-2 items-center'>
                            <span className='text-sm text-gray-500'>
                              20 Jul 2023
                            </span>
                            <span className='mx-3'>
                              <svg
                                width={4}
                                height={4}
                                viewBox='0 0 4 4'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                              </svg>
                            </span>
                            <span className='text-sm text-gray-500'>
                              4 min read
                            </span>
                          </div>
                          <h2 className='font-heading tracking-tight text-xl text-gray-900 font-semibold mb-6'>
                            How We Built AI-Collections: Wrapping Up
                          </h2>
                          <p className='text-sm text-gray-500 mb-4'>
                            Remarkable journey behind AI-Collections as we
                            reflect on the challenges and invaluable lessons
                          </p>
                          <div className='flex items-center'>
                            <span className='text-sm text-gray-500'>By</span>
                            <img
                              className='block w-5 h-5 mx-2 rounded-full'
                              src='aurora-assets/blog/avatar-medium.png'
                              alt=''
                            />
                            <span className='text-sm font-medium'>
                              Max Ranver
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='w-full md:w-1/2 px-4 mb-16'>
                      <a
                        className='flex flex-col h-full p-2 bg-white rounded-xl shadow-xl overflow-hidden border border-gray-100 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                        href='#'
                      >
                        <div className='h-64 rounded-xl overflow-hidden'>
                          <img
                            className='block w-full h-full object-cover'
                            src='aurora-assets/blog/blog-element6-green.png'
                            alt=''
                          />
                        </div>
                        <div className='flex-grow-1 pt-6 pb-8 px-6'>
                          <div className='flex mb-2 items-center'>
                            <span className='text-sm text-gray-500'>
                              20 Jul 2023
                            </span>
                            <span className='mx-3'>
                              <svg
                                width={4}
                                height={4}
                                viewBox='0 0 4 4'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                              </svg>
                            </span>
                            <span className='text-sm text-gray-500'>
                              4 min read
                            </span>
                          </div>
                          <h2 className='font-heading tracking-tight text-xl text-gray-900 font-semibold mb-6'>
                            How We Built AI-Collections: Wrapping Upc
                          </h2>
                          <p className='text-sm text-gray-500 mb-4'>
                            Remarkable journey behind AI-Collections as we
                            reflect on the challenges and invaluable lessons
                          </p>
                          <div className='flex items-center'>
                            <span className='text-sm text-gray-500'>By</span>
                            <img
                              className='block w-5 h-5 mx-2 rounded-full'
                              src='aurora-assets/blog/avatar-medium.png'
                              alt=''
                            />
                            <span className='text-sm font-medium'>
                              Max Ranver
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='w-full md:w-1/2 px-4 mb-16'>
                      <a
                        className='flex flex-col h-full p-2 bg-white rounded-xl shadow-xl overflow-hidden border border-gray-100 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                        href='#'
                      >
                        <div className='h-64 rounded-xl overflow-hidden'>
                          <img
                            className='block w-full h-full object-cover'
                            src='aurora-assets/blog/blog-element6-green.png'
                            alt=''
                          />
                        </div>
                        <div className='flex-grow-1 pt-6 pb-8 px-6'>
                          <div className='flex mb-2 items-center'>
                            <span className='text-sm text-gray-500'>
                              20 Jul 2023
                            </span>
                            <span className='mx-3'>
                              <svg
                                width={4}
                                height={4}
                                viewBox='0 0 4 4'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                              </svg>
                            </span>
                            <span className='text-sm text-gray-500'>
                              4 min read
                            </span>
                          </div>
                          <h2 className='font-heading tracking-tight text-xl text-gray-900 font-semibold mb-6'>
                            Product Update June 2023
                          </h2>
                          <p className='text-sm text-gray-500 mb-4'>
                            Discover transformative hacks that will
                            revolutionize your productivity
                          </p>
                          <div className='flex items-center'>
                            <span className='text-sm text-gray-500'>By</span>
                            <img
                              className='block w-5 h-5 mx-2 rounded-full'
                              src='aurora-assets/blog/avatar-medium.png'
                              alt=''
                            />
                            <span className='text-sm font-medium'>
                              Max Ranver
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='py-12 md:py-24 bg-radial-dark-green'>
            <div className='container px-4 pb-12 lg:pb-24 mx-auto'>
              <div className='text-center mb-24'>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-bold text-white mb-4'>
                  Blog &amp; Articles
                </h1>
                <p className='text-lg text-gray-400'>
                  Articles about tips, resources, and strategy.
                </p>
              </div>
              <a className='group block' href='#'>
                <div className='flex flex-wrap -mx-4 items-center'>
                  <div className='w-full md:w-1/2 lg:w-5/12 px-4 mt-10 lg:mt-0 order-last md:order-first'>
                    <div className='max-w-sm'>
                      <div className='flex mb-4 items-center'>
                        <span className='text-sm text-gray-400'>
                          20 Jul 2023
                        </span>
                        <span className='mx-3'>
                          <svg
                            width={4}
                            height={4}
                            viewBox='0 0 4 4'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                          </svg>
                        </span>
                        <span className='text-sm text-gray-400'>
                          4 min read
                        </span>
                      </div>
                      <h2 className='group-hover:text-yellowGreen-600 font-heading tracking-tight text-3xl md:text-4xl text-white font-semibold mb-6'>
                        Effortless Asset Organization: Simplify with Bookmarking
                      </h2>
                      <p className='text-lg text-gray-300 mb-4'>
                        We've all experienced the chaos of multiple
                        spreadsheets, tracking and insight tools...
                      </p>
                      <div className='flex items-center'>
                        <img
                          className='block w-12 h-12 rounded-full object-contain'
                          src='aurora-assets/blog/avatar-medium.png'
                          alt=''
                        />
                        <div className='ml-3'>
                          <span className='block text-sm font-semibold text-white'>
                            Max Ranver
                          </span>
                          <span className='block text-sm font-medium text-gray-300'>
                            Content Writer, Aurora
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 lg:w-7/12 px-4'>
                    <div className='flex h-full w-full items-center justify-end'>
                      <img
                        className='block w-full object-cover rounded-3xl group-hover:border-yellowGreen-500 ring ring-transparent group-hover:ring-yellowGreen-500'
                        src='aurora-assets/blog/blog-2-medium3-green.png'
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className='py-12 md:py-24 bg-white'>
            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-3/12 px-4 mb-10 lg:mb-0'>
                  <div className='max-w-xs pr-10'>
                    <div className='flex h-12 py-3 px-4 mb-6 items-center bg-gray-50 bg-opacity-50 border rounded-lg'>
                      <button variant="classic"
                        className='inline-block flex-shrink-0 mr-4 text-gray-900 hover:text-yellowGreen-600 focus:outline-none'
                        type='submit'
                      >
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M14.167 14.1667L17.5003 17.5001'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333C11.0108 15.8333 12.6801 15.0846 13.887 13.8744C15.0897 12.6685 15.8333 11.0044 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </button>
                      <input
                        className='w-full text-gray-400 placeholder-gray-500 bg-transparent outline-none'
                        type='search'
                        placeholder='Search'
                      />
                    </div>
                    <ul className='pr-6'>
                      <li className='mb-3'>
                        <a
                          className='block px-6 text-gray-900 font-semibold border-l-2 border-yellowGreen-500'
                          href='#'
                        >
                          All
                        </a>
                      </li>
                      <li className='mb-3'>
                        <a
                          className='relative group block px-6 text-gray-400 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                          href='#'
                        >
                          <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-gray-50' />
                          <span className='relative'>Featured</span>
                        </a>
                      </li>
                      <li className='mb-3'>
                        <a
                          className='relative group block px-6 text-gray-400 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                          href='#'
                        >
                          <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-gray-50' />
                          <span className='relative'>Tutorials</span>
                        </a>
                      </li>
                      <li className='mb-3'>
                        <a
                          className='relative group block px-6 text-gray-400 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                          href='#'
                        >
                          <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-gray-50' />
                          <span className='relative'>Product</span>
                        </a>
                      </li>
                      <li className='mb-3'>
                        <a
                          className='relative group block px-6 text-gray-400 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                          href='#'
                        >
                          <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-gray-50' />
                          <span className='relative'>Tips</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className='relative group block px-6 text-gray-400 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                          href='#'
                        >
                          <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-gray-50' />
                          <span className='relative'>Insights</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='w-full lg:w-9/12 px-4'>
                  <div className='flex flex-wrap -mx-4'>
                    <div className='w-full md:w-1/2 px-4 mb-16'>
                      <a
                        className='flex flex-col h-full p-2 bg-white rounded-xl shadow-xl overflow-hidden border border-gray-100 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                        href='#'
                      >
                        <div className='h-64 w-full rounded-xl overflow-hidden'>
                          <img
                            className='block w-full h-full object-cover'
                            src='aurora-assets/blog/blog-2-medium2-green.png'
                            alt=''
                          />
                        </div>
                        <div className='flex-grow-1 pt-6 pb-8 px-6'>
                          <div className='flex mb-2 items-center'>
                            <span className='text-sm text-gray-400'>
                              20 Jul 2023
                            </span>
                            <span className='mx-3'>
                              <svg
                                width={4}
                                height={4}
                                viewBox='0 0 4 4'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                              </svg>
                            </span>
                            <span className='text-sm text-gray-400'>
                              4 min read
                            </span>
                          </div>
                          <h2 className='font-heading tracking-tight text-xl text-gray-900 font-semibold mb-6'>
                            Save Time, Stay Organized: Uncover the Magic
                          </h2>
                          <p className='text-sm text-gray-400 mb-4'>
                            Discover transformative hacks that will
                            revolutionize your productivity
                          </p>
                          <div className='flex items-center'>
                            <span className='text-sm text-gray-400'>By</span>
                            <img
                              className='block w-5 h-5 mx-2 rounded-full'
                              src='aurora-assets/blog/avatar-medium.png'
                              alt=''
                            />
                            <span className='text-sm font-medium'>
                              Max Ranver
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='w-full md:w-1/2 px-4 mb-16'>
                      <a
                        className='flex flex-col h-full p-2 bg-white rounded-xl shadow-xl overflow-hidden border border-gray-100 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                        href='#'
                      >
                        <div className='h-64 rounded-xl overflow-hidden'>
                          <img
                            className='block w-full h-full object-cover'
                            src='aurora-assets/blog/blog-3-medium3-green.png'
                            alt=''
                          />
                        </div>
                        <div className='flex-grow-1 pt-6 pb-8 px-6'>
                          <div className='flex mb-2 items-center'>
                            <span className='text-sm text-gray-400'>
                              20 Jul 2023
                            </span>
                            <span className='mx-3'>
                              <svg
                                width={4}
                                height={4}
                                viewBox='0 0 4 4'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                              </svg>
                            </span>
                            <span className='text-sm text-gray-400'>
                              4 min read
                            </span>
                          </div>
                          <h2 className='font-heading tracking-tight text-xl text-gray-900 font-semibold mb-6'>
                            How We Built AI-Collections: Wrapping Up
                          </h2>
                          <p className='text-sm text-gray-400 mb-4'>
                            Remarkable journey behind AI-Collections as we
                            reflect on the challenges and invaluable lessons
                          </p>
                          <div className='flex items-center'>
                            <span className='text-sm text-gray-400'>By</span>
                            <img
                              className='block w-5 h-5 mx-2 rounded-full'
                              src='aurora-assets/blog/avatar-medium.png'
                              alt=''
                            />
                            <span className='text-sm font-medium'>
                              Max Ranver
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='w-full md:w-1/2 px-4 mb-16'>
                      <a
                        className='flex flex-col h-full p-2 bg-white rounded-xl shadow-xl overflow-hidden border border-gray-100 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                        href='#'
                      >
                        <div className='h-64 rounded-xl overflow-hidden'>
                          <img
                            className='block w-full h-full object-cover'
                            src='aurora-assets/blog/blog-element6-green.png'
                            alt=''
                          />
                        </div>
                        <div className='flex-grow-1 pt-6 pb-8 px-6'>
                          <div className='flex mb-2 items-center'>
                            <span className='text-sm text-gray-400'>
                              20 Jul 2023
                            </span>
                            <span className='mx-3'>
                              <svg
                                width={4}
                                height={4}
                                viewBox='0 0 4 4'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                              </svg>
                            </span>
                            <span className='text-sm text-gray-400'>
                              4 min read
                            </span>
                          </div>
                          <h2 className='font-heading tracking-tight text-xl text-gray-900 font-semibold mb-6'>
                            How We Built AI-Collections: Wrapping Upc
                          </h2>
                          <p className='text-sm text-gray-400 mb-4'>
                            Remarkable journey behind AI-Collections as we
                            reflect on the challenges and invaluable lessons
                          </p>
                          <div className='flex items-center'>
                            <span className='text-sm text-gray-400'>By</span>
                            <img
                              className='block w-5 h-5 mx-2 rounded-full'
                              src='aurora-assets/blog/avatar-medium.png'
                              alt=''
                            />
                            <span className='text-sm font-medium'>
                              Max Ranver
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='w-full md:w-1/2 px-4 mb-16'>
                      <a
                        className='flex flex-col h-full p-2 bg-white rounded-xl shadow-xl overflow-hidden border border-gray-100 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                        href='#'
                      >
                        <div className='h-64 rounded-xl overflow-hidden'>
                          <img
                            className='block w-full h-full object-cover'
                            src='aurora-assets/blog/blog-element6-green.png'
                            alt=''
                          />
                        </div>
                        <div className='flex-grow-1 pt-6 pb-8 px-6'>
                          <div className='flex mb-2 items-center'>
                            <span className='text-sm text-gray-400'>
                              20 Jul 2023
                            </span>
                            <span className='mx-3'>
                              <svg
                                width={4}
                                height={4}
                                viewBox='0 0 4 4'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                              </svg>
                            </span>
                            <span className='text-sm text-gray-400'>
                              4 min read
                            </span>
                          </div>
                          <h2 className='font-heading tracking-tight text-xl text-gray-900 font-semibold mb-6'>
                            Product Update June 2023
                          </h2>
                          <p className='text-sm text-gray-400 mb-4'>
                            Discover transformative hacks that will
                            revolutionize your productivity
                          </p>
                          <div className='flex items-center'>
                            <span className='text-sm text-gray-400'>By</span>
                            <img
                              className='block w-5 h-5 mx-2 rounded-full'
                              src='aurora-assets/blog/avatar-medium.png'
                              alt=''
                            />
                            <span className='text-sm font-medium'>
                              Max Ranver
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='text-center mb-24'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-bold text-white mb-4'>
                Blog &amp; Articles
              </h1>
              <p className='text-lg text-gray-500'>
                Curated articles, resources, tips and trends from the Aurora
                team.
              </p>
            </div>
            <div className='flex flex-wrap -mx-4 mb-24 items-start'>
              <div className='w-full lg:w-7/12 px-4 mb-16 lg:mb-0'>
                <div>
                  <a
                    className='relative pt-18 pb-10 px-12 block rounded-xl bg-green-100 border border-transparent hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                    href='#'
                  >
                    <img
                      className='block mb-20 mx-auto'
                      src='aurora-assets/blog/folders.png'
                      alt=''
                    />
                    <span className='inline-flex items-center mb-4 px-2 leading-loose text-xs text-white font-medium bg-teal-800 rounded-full'>
                      Product
                    </span>
                    <h4 className='max-w-xl text-3xl font-semibold text-gray-900 mb-4'>
                      Effortless Asset Organization: Simplify with Bookmarking
                    </h4>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-500'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-500'>4 min read</span>
                    </div>
                  </a>
                </div>
              </div>
              <div className='w-full lg:w-5/12 px-4'>
                <div>
                  <a className='group block mb-6' href='#'>
                    <span className='inline-flex items-center mb-4 px-2 leading-loose text-xs text-white font-medium bg-teal-800 rounded-full'>
                      Insights
                    </span>
                    <h4 className='text-lg font-semibold text-white group-hover:text-yellowGreen-600 mb-4'>
                      The Benefits of Bookmarking Your Marketing Assets
                    </h4>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-500'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-500'>4 min read</span>
                    </div>
                  </a>
                  <div className='w-full h-px mb-6 bg-teal-800' />
                  <a className='group block mb-6' href='#'>
                    <span className='inline-flex items-center mb-4 px-2 leading-loose text-xs text-white font-medium bg-teal-800 rounded-full'>
                      Insights
                    </span>
                    <h4 className='text-lg font-semibold text-white group-hover:text-yellowGreen-600 mb-4'>
                      Discover the Power of Bookmarking Platforms
                    </h4>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-500'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-500'>4 min read</span>
                    </div>
                  </a>
                  <div className='w-full h-px mb-6 bg-teal-800' />
                  <a className='group block' href='#'>
                    <span className='inline-flex items-center mb-4 px-2 leading-loose text-xs text-white font-medium bg-teal-800 rounded-full'>
                      Product
                    </span>
                    <h4 className='text-lg font-semibold text-white group-hover:text-yellowGreen-600 mb-4'>
                      Save, Share, and Succeed: Discover the Power of
                      Bookmarking Platforms
                    </h4>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-500'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-500'>4 min read</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-3/12 px-4 mb-10 lg:mb-0'>
                <div className='max-w-xs pr-10'>
                  <div className='flex h-12 py-3 px-4 mb-6 items-center bg-teal-800 rounded-lg'>
                    <button variant="classic"
                      className='inline-block flex-shrink-0 mr-4 text-white hover:text-yellowGreen-600 focus:outline-none'
                      type='submit'
                    >
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M14.167 14.1667L17.5003 17.5001'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333C11.0108 15.8333 12.6801 15.0846 13.887 13.8744C15.0897 12.6685 15.8333 11.0044 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667Z'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </button>
                    <input
                      className='w-full text-gray-500 placeholder-gray-500 bg-transparent outline-none'
                      type='search'
                      placeholder='Search'
                    />
                  </div>
                  <ul className='pr-6'>
                    <li className='mb-3'>
                      <a
                        className='block px-6 text-white font-semibold border-l-2 border-yellowGreen-500'
                        href='#'
                      >
                        All
                      </a>
                    </li>
                    <li className='mb-3'>
                      <a
                        className='relative group block px-6 text-gray-500 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                        href='#'
                      >
                        <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-teal-800' />
                        <span className='relative'>Featured</span>
                      </a>
                    </li>
                    <li className='mb-3'>
                      <a
                        className='relative group block px-6 text-gray-500 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                        href='#'
                      >
                        <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-teal-800' />
                        <span className='relative'>Tutorials</span>
                      </a>
                    </li>
                    <li className='mb-3'>
                      <a
                        className='relative group block px-6 text-gray-500 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                        href='#'
                      >
                        <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-teal-800' />
                        <span className='relative'>Product</span>
                      </a>
                    </li>
                    <li className='mb-3'>
                      <a
                        className='relative group block px-6 text-gray-500 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                        href='#'
                      >
                        <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-teal-800' />
                        <span className='relative'>Tips</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className='relative group block px-6 text-gray-500 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                        href='#'
                      >
                        <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-teal-800' />
                        <span className='relative'>Insights</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='w-full lg:w-9/12 px-4'>
                <div className='flex flex-wrap -mx-4'>
                  <div className='w-full sm:w-1/2 px-4 mb-16'>
                    <a
                      className='group flex flex-col h-full rounded-xl'
                      href='#'
                    >
                      <div className='h-72 rounded-xl overflow-hidden'>
                        <img
                          className='block w-full h-full object-cover'
                          src='aurora-assets/blog/blog-2-medium2.png'
                          alt=''
                        />
                      </div>
                      <div className='flex-grow-1 pt-6 pb-8'>
                        <span className='inline-flex items-center mb-4 px-2 leading-loose text-xs text-white font-medium bg-teal-800 rounded-full'>
                          Insights
                        </span>
                        <h2 className='font-heading tracking-tight text-2xl text-white group-hover:text-yellowGreen-600 font-semibold mb-6'>
                          Save Time, Stay Organized: Uncover the Magic
                        </h2>
                        <div className='flex items-center'>
                          <span className='text-sm text-gray-500'>
                            20 Jul 2023
                          </span>
                          <span className='mx-3'>
                            <svg
                              width={4}
                              height={4}
                              viewBox='0 0 4 4'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                            </svg>
                          </span>
                          <span className='text-sm text-gray-500'>
                            4 min read
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className='w-full sm:w-1/2 px-4 mb-16'>
                    <a
                      className='group flex flex-col h-full rounded-xl'
                      href='#'
                    >
                      <div className='h-72 rounded-xl overflow-hidden'>
                        <img
                          className='block w-full h-full object-cover'
                          src='aurora-assets/blog/blog-2-medium1.png'
                          alt=''
                        />
                      </div>
                      <div className='flex-grow-1 pt-6 pb-8'>
                        <span className='inline-flex items-center mb-4 px-2 leading-loose text-xs text-white font-medium bg-teal-800 rounded-full'>
                          Insights
                        </span>
                        <h2 className='font-heading tracking-tight text-2xl text-white group-hover:text-yellowGreen-600 font-semibold mb-6'>
                          How We Built AI-Collections: Wrapping Up
                        </h2>
                        <div className='flex items-center'>
                          <span className='text-sm text-gray-500'>
                            20 Jul 2023
                          </span>
                          <span className='mx-3'>
                            <svg
                              width={4}
                              height={4}
                              viewBox='0 0 4 4'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                            </svg>
                          </span>
                          <span className='text-sm text-gray-500'>
                            4 min read
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className='w-full sm:w-1/2 px-4 mb-16'>
                    <a
                      className='group flex flex-col h-full rounded-xl'
                      href='#'
                    >
                      <div className='h-72 rounded-xl overflow-hidden'>
                        <img
                          className='block w-full h-full object-cover'
                          src='aurora-assets/blog/blog-element5.png'
                          alt=''
                        />
                      </div>
                      <div className='flex-grow-1 pt-6 pb-8'>
                        <span className='inline-flex items-center mb-4 px-2 leading-loose text-xs text-white font-medium bg-teal-800 rounded-full'>
                          Insights
                        </span>
                        <h2 className='font-heading tracking-tight text-2xl text-white group-hover:text-yellowGreen-600 font-semibold mb-6'>
                          Optimizing Loading Indicator
                        </h2>
                        <div className='flex items-center'>
                          <span className='text-sm text-gray-500'>
                            20 Jul 2023
                          </span>
                          <span className='mx-3'>
                            <svg
                              width={4}
                              height={4}
                              viewBox='0 0 4 4'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                            </svg>
                          </span>
                          <span className='text-sm text-gray-500'>
                            4 min read
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className='w-full sm:w-1/2 px-4 mb-16'>
                    <a
                      className='group flex flex-col h-full rounded-xl'
                      href='#'
                    >
                      <div className='h-72 rounded-xl overflow-hidden'>
                        <img
                          className='block w-full h-full object-cover'
                          src='aurora-assets/blog/blog-element6.png'
                          alt=''
                        />
                      </div>
                      <div className='flex-grow-1 pt-6 pb-8'>
                        <span className='inline-flex items-center mb-4 px-2 leading-loose text-xs text-white font-medium bg-teal-800 rounded-full'>
                          Insights
                        </span>
                        <h2 className='font-heading tracking-tight text-2xl text-white group-hover:text-yellowGreen-600 font-semibold mb-6'>
                          Product Update July 2023
                        </h2>
                        <div className='flex items-center'>
                          <span className='text-sm text-gray-500'>
                            20 Jul 2023
                          </span>
                          <span className='mx-3'>
                            <svg
                              width={4}
                              height={4}
                              viewBox='0 0 4 4'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                            </svg>
                          </span>
                          <span className='text-sm text-gray-500'>
                            4 min read
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative pt-12 md:pt-24 bg-gray-800'>
          <div className='container px-4 pb-12 lg:pb-24 mx-auto'>
            <div className='text-center mb-24'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-bold text-white mb-4'>
                Blog &amp; Articles
              </h1>
              <p className='text-lg text-gray-400'>
                Articles about tips, resources, and strategy.
              </p>
            </div>
            <a className='group block mb-16' href='#'>
              <div className='flex flex-wrap -mx-4 items-center'>
                <div className='w-full md:w-1/2 lg:w-5/12 px-4 mt-10 lg:mt-0 order-last md:order-first'>
                  <div className='max-w-sm'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-400'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-400'>4 min read</span>
                    </div>
                    <h2 className='group-hover:text-yellowGreen-600 font-heading tracking-tight text-3xl md:text-4xl text-white font-semibold mb-6'>
                      Effortless Asset Organization: Simplify with Bookmarking
                    </h2>
                    <p className='text-lg text-gray-400 mb-4'>
                      We've all experienced the chaos of multiple spreadsheets,
                      tracking and insight tools...
                    </p>
                    <div className='flex items-center'>
                      <img
                        className='block w-12 h-12 rounded-full object-contain'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <div className='ml-3'>
                        <span className='block text-sm font-semibold text-white'>
                          Max Ranver
                        </span>
                        <span className='block text-sm font-medium text-gray-400'>
                          Content Writer, Aurora
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-7/12 px-4'>
                  <div className='flex h-full w-full items-center justify-end'>
                    <img
                      className='block w-full object-cover rounded-3xl border border-transparent group-hover:border-yellowGreen-500 ring ring-transparent group-hover:ring-yellowGreen-500 group-hover:shadow-none'
                      src='aurora-assets/blog/blog-2-medium3-green.png'
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className='py-12 md:py-24 bg-gray-900'>
            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-3/12 px-4 mb-10 lg:mb-0'>
                  <div className='max-w-xs pr-10'>
                    <div className='flex h-12 py-3 px-4 mb-6 items-center bg-gray-800 rounded-lg'>
                      <button variant="classic"
                        className='inline-block flex-shrink-0 mr-4 text-white hover:text-yellowGreen-600 focus:outline-none'
                        type='submit'
                      >
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M14.167 14.1665L17.5003 17.4998'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333C11.0108 15.8333 12.6801 15.0846 13.887 13.8744C15.0897 12.6685 15.8333 11.0044 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </button>
                      <input
                        className='w-full text-gray-400 placeholder-gray-400 bg-transparent outline-none'
                        type='search'
                        placeholder='Search'
                      />
                    </div>
                    <ul className='pr-6'>
                      <li className='mb-3'>
                        <a
                          className='block px-6 text-white font-semibold border-l-2 border-yellowGreen-500'
                          href='#'
                        >
                          All
                        </a>
                      </li>
                      <li className='mb-3'>
                        <a
                          className='relative group block px-6 text-gray-400 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                          href='#'
                        >
                          <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-gray-800' />
                          <span className='relative'>Featured</span>
                        </a>
                      </li>
                      <li className='mb-3'>
                        <a
                          className='relative group block px-6 text-gray-400 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                          href='#'
                        >
                          <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-gray-800' />
                          <span className='relative'>Tutorials</span>
                        </a>
                      </li>
                      <li className='mb-3'>
                        <a
                          className='relative group block px-6 text-gray-400 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                          href='#'
                        >
                          <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-gray-800' />
                          <span className='relative'>Product</span>
                        </a>
                      </li>
                      <li className='mb-3'>
                        <a
                          className='relative group block px-6 text-gray-400 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                          href='#'
                        >
                          <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-gray-800' />
                          <span className='relative'>Tips</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className='relative group block px-6 text-gray-400 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                          href='#'
                        >
                          <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-gray-800' />
                          <span className='relative'>Insights</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='w-full lg:w-9/12 px-4'>
                  <div className='flex flex-wrap -mx-4'>
                    <div className='w-full md:w-1/2 px-4 mb-16'>
                      <a
                        className='flex flex-col h-full p-2 rounded-xl border border-gray-800 overflow-hidden hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                        href='#'
                      >
                        <div className='h-64 w-full rounded-xl overflow-hidden'>
                          <img
                            className='block w-full h-full object-cover'
                            src='aurora-assets/blog/blog-2-medium2-green.png'
                            alt=''
                          />
                        </div>
                        <div className='flex-grow-1 pt-6 pb-8 px-6'>
                          <div className='flex mb-2 items-center'>
                            <span className='text-sm text-gray-400'>
                              20 Jul 2023
                            </span>
                            <span className='mx-3'>
                              <svg
                                width={4}
                                height={4}
                                viewBox='0 0 4 4'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                              </svg>
                            </span>
                            <span className='text-sm text-gray-400'>
                              4 min read
                            </span>
                          </div>
                          <h2 className='font-heading tracking-tight text-xl text-white font-semibold mb-6'>
                            Save Time, Stay Organized: Uncover the Magic
                          </h2>
                          <p className='text-sm text-gray-400 mb-4'>
                            Discover transformative hacks that will
                            revolutionize your productivity
                          </p>
                          <div className='flex items-center'>
                            <span className='text-sm text-gray-400'>By</span>
                            <img
                              className='block w-6 h-6 mx-2 rounded-full'
                              src='aurora-assets/blog/avatar-medium.png'
                              alt=''
                            />
                            <span className='text-sm font-medium text-white'>
                              Max Ranver
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='w-full md:w-1/2 px-4 mb-16'>
                      <a
                        className='flex flex-col h-full p-2 rounded-xl border border-gray-800 overflow-hidden hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                        href='#'
                      >
                        <div className='h-64 rounded-xl overflow-hidden'>
                          <img
                            className='block w-full h-full object-cover'
                            src='aurora-assets/blog/blog-3-medium3-green.png'
                            alt=''
                          />
                        </div>
                        <div className='flex-grow-1 pt-6 pb-8 px-6'>
                          <div className='flex mb-2 items-center'>
                            <span className='text-sm text-gray-400'>
                              20 Jul 2023
                            </span>
                            <span className='mx-3'>
                              <svg
                                width={4}
                                height={4}
                                viewBox='0 0 4 4'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                              </svg>
                            </span>
                            <span className='text-sm text-gray-400'>
                              4 min read
                            </span>
                          </div>
                          <h2 className='font-heading tracking-tight text-xl text-white font-semibold mb-6'>
                            How We Built AI-Collections: Wrapping Up
                          </h2>
                          <p className='text-sm text-gray-400 mb-4'>
                            Remarkable journey behind AI-Collections as we
                            reflect on the challenges and invaluable lessons
                          </p>
                          <div className='flex items-center'>
                            <span className='text-sm text-gray-400'>By</span>
                            <img
                              className='block w-6 h-6 mx-2 rounded-full'
                              src='aurora-assets/blog/avatar-medium.png'
                              alt=''
                            />
                            <span className='text-sm font-medium text-white'>
                              Max Ranver
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='w-full md:w-1/2 px-4 mb-16'>
                      <a
                        className='flex flex-col h-full p-2 rounded-xl border border-gray-800 overflow-hidden hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                        href='#'
                      >
                        <div className='h-64 rounded-xl overflow-hidden'>
                          <img
                            className='block w-full h-full object-cover'
                            src='aurora-assets/blog/blog-element6-green.png'
                            alt=''
                          />
                        </div>
                        <div className='flex-grow-1 pt-6 pb-8 px-6'>
                          <div className='flex mb-2 items-center'>
                            <span className='text-sm text-gray-400'>
                              20 Jul 2023
                            </span>
                            <span className='mx-3'>
                              <svg
                                width={4}
                                height={4}
                                viewBox='0 0 4 4'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                              </svg>
                            </span>
                            <span className='text-sm text-gray-400'>
                              4 min read
                            </span>
                          </div>
                          <h2 className='font-heading tracking-tight text-xl text-white font-semibold mb-6'>
                            How We Built AI-Collections: Wrapping Upc
                          </h2>
                          <p className='text-sm text-gray-400 mb-4'>
                            Remarkable journey behind AI-Collections as we
                            reflect on the challenges and invaluable lessons
                          </p>
                          <div className='flex items-center'>
                            <span className='text-sm text-gray-400'>By</span>
                            <img
                              className='block w-6 h-6 mx-2 rounded-full'
                              src='aurora-assets/blog/avatar-medium.png'
                              alt=''
                            />
                            <span className='text-sm font-medium text-white'>
                              Max Ranver
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='w-full md:w-1/2 px-4 mb-16'>
                      <a
                        className='flex flex-col h-full p-2 rounded-xl border border-gray-800 overflow-hidden hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                        href='#'
                      >
                        <div className='h-64 rounded-xl overflow-hidden'>
                          <img
                            className='block w-full h-full object-cover'
                            src='aurora-assets/blog/blog-element6-green.png'
                            alt=''
                          />
                        </div>
                        <div className='flex-grow-1 pt-6 pb-8 px-6'>
                          <div className='flex mb-2 items-center'>
                            <span className='text-sm text-gray-400'>
                              20 Jul 2023
                            </span>
                            <span className='mx-3'>
                              <svg
                                width={4}
                                height={4}
                                viewBox='0 0 4 4'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                              </svg>
                            </span>
                            <span className='text-sm text-gray-400'>
                              4 min read
                            </span>
                          </div>
                          <h2 className='font-heading tracking-tight text-xl text-white font-semibold mb-6'>
                            Product Update June 2023
                          </h2>
                          <p className='text-sm text-gray-400 mb-4'>
                            Discover transformative hacks that will
                            revolutionize your productivity
                          </p>
                          <div className='flex items-center'>
                            <span className='text-sm text-gray-400'>By</span>
                            <img
                              className='block w-6 h-6 mx-2 rounded-full'
                              src='aurora-assets/blog/avatar-medium.png'
                              alt=''
                            />
                            <span className='text-sm font-medium text-white'>
                              Max Ranver
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative pt-12 md:pt-24 bg-gray-800'>
          <div className='container px-4 pb-12 lg:pb-24 mx-auto'>
            <div className='text-center mb-24'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-bold text-white mb-4'>
                Blog &amp; Articles
              </h1>
              <p className='text-lg text-gray-400'>
                Articles about tips, resources, and strategy.
              </p>
            </div>
            <a className='group block mb-16' href='#'>
              <div className='flex flex-wrap -mx-4 items-center'>
                <div className='w-full md:w-1/2 lg:w-5/12 px-4 mt-10 lg:mt-0 order-last md:order-first'>
                  <div className='max-w-sm'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-400'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-400'>4 min read</span>
                    </div>
                    <h2 className='group-hover:text-yellowGreen-600 font-heading tracking-tight text-3xl md:text-4xl text-white font-semibold mb-6'>
                      Effortless Asset Organization: Simplify with Bookmarking
                    </h2>
                    <p className='text-lg text-gray-400 mb-4'>
                      We've all experienced the chaos of multiple spreadsheets,
                      tracking and insight tools...
                    </p>
                    <div className='flex items-center'>
                      <img
                        className='block w-12 h-12 rounded-full object-contain'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <div className='ml-3'>
                        <span className='block text-sm font-semibold text-white'>
                          Max Ranver
                        </span>
                        <span className='block text-sm font-medium text-gray-400'>
                          Content Writer, Aurora
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-7/12 px-4'>
                  <div className='flex h-full w-full items-center justify-end'>
                    <img
                      className='block w-full object-cover rounded-3xl border border-transparent group-hover:border-yellowGreen-500 ring ring-transparent group-hover:ring-yellowGreen-500 group-hover:shadow-none'
                      src='aurora-assets/blog/blog-2-medium3-green.png'
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className='py-12 md:py-24 bg-gray-900'>
            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-3/12 px-4 mb-10 lg:mb-0'>
                  <div className='max-w-xs pr-10'>
                    <div className='flex h-12 py-3 px-4 mb-6 items-center bg-gray-800 rounded-lg'>
                      <button variant="classic"
                        className='inline-block flex-shrink-0 mr-4 text-white hover:text-yellowGreen-600 focus:outline-none'
                        type='submit'
                      >
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M14.167 14.1665L17.5003 17.4998'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333C11.0108 15.8333 12.6801 15.0846 13.887 13.8744C15.0897 12.6685 15.8333 11.0044 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </button>
                      <input
                        className='w-full text-gray-400 placeholder-gray-400 bg-transparent outline-none'
                        type='search'
                        placeholder='Search'
                      />
                    </div>
                    <ul className='pr-6'>
                      <li className='mb-3'>
                        <a
                          className='block px-6 text-white font-semibold border-l-2 border-yellowGreen-500'
                          href='#'
                        >
                          All
                        </a>
                      </li>
                      <li className='mb-3'>
                        <a
                          className='relative group block px-6 text-gray-400 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                          href='#'
                        >
                          <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-gray-800' />
                          <span className='relative'>Featured</span>
                        </a>
                      </li>
                      <li className='mb-3'>
                        <a
                          className='relative group block px-6 text-gray-400 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                          href='#'
                        >
                          <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-gray-800' />
                          <span className='relative'>Tutorials</span>
                        </a>
                      </li>
                      <li className='mb-3'>
                        <a
                          className='relative group block px-6 text-gray-400 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                          href='#'
                        >
                          <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-gray-800' />
                          <span className='relative'>Product</span>
                        </a>
                      </li>
                      <li className='mb-3'>
                        <a
                          className='relative group block px-6 text-gray-400 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                          href='#'
                        >
                          <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-gray-800' />
                          <span className='relative'>Tips</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className='relative group block px-6 text-gray-400 font-base border-l-2 border-transparent hover:border-yellowGreen-500'
                          href='#'
                        >
                          <div className='group-hover:animate-fill absolute top-0 left-0 h-full bg-gray-800' />
                          <span className='relative'>Insights</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='w-full lg:w-9/12 px-4'>
                  <div className='flex flex-wrap -mx-4'>
                    <div className='w-full md:w-1/2 px-4 mb-16'>
                      <a
                        className='flex flex-col h-full p-2 rounded-xl border border-gray-800 overflow-hidden hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                        href='#'
                      >
                        <div className='h-64 w-full rounded-xl overflow-hidden'>
                          <img
                            className='block w-full h-full object-cover'
                            src='aurora-assets/blog/blog-2-medium2-green.png'
                            alt=''
                          />
                        </div>
                        <div className='flex-grow-1 pt-6 pb-8 px-6'>
                          <div className='flex mb-2 items-center'>
                            <span className='text-sm text-gray-400'>
                              20 Jul 2023
                            </span>
                            <span className='mx-3'>
                              <svg
                                width={4}
                                height={4}
                                viewBox='0 0 4 4'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                              </svg>
                            </span>
                            <span className='text-sm text-gray-400'>
                              4 min read
                            </span>
                          </div>
                          <h2 className='font-heading tracking-tight text-xl text-white font-semibold mb-6'>
                            Save Time, Stay Organized: Uncover the Magic
                          </h2>
                          <p className='text-sm text-gray-400 mb-4'>
                            Discover transformative hacks that will
                            revolutionize your productivity
                          </p>
                          <div className='flex items-center'>
                            <span className='text-sm text-gray-400'>By</span>
                            <img
                              className='block w-6 h-6 mx-2 rounded-full'
                              src='aurora-assets/blog/avatar-medium.png'
                              alt=''
                            />
                            <span className='text-sm font-medium text-white'>
                              Max Ranver
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='w-full md:w-1/2 px-4 mb-16'>
                      <a
                        className='flex flex-col h-full p-2 rounded-xl border border-gray-800 overflow-hidden hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                        href='#'
                      >
                        <div className='h-64 rounded-xl overflow-hidden'>
                          <img
                            className='block w-full h-full object-cover'
                            src='aurora-assets/blog/blog-3-medium3-green.png'
                            alt=''
                          />
                        </div>
                        <div className='flex-grow-1 pt-6 pb-8 px-6'>
                          <div className='flex mb-2 items-center'>
                            <span className='text-sm text-gray-400'>
                              20 Jul 2023
                            </span>
                            <span className='mx-3'>
                              <svg
                                width={4}
                                height={4}
                                viewBox='0 0 4 4'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                              </svg>
                            </span>
                            <span className='text-sm text-gray-400'>
                              4 min read
                            </span>
                          </div>
                          <h2 className='font-heading tracking-tight text-xl text-white font-semibold mb-6'>
                            How We Built AI-Collections: Wrapping Up
                          </h2>
                          <p className='text-sm text-gray-400 mb-4'>
                            Remarkable journey behind AI-Collections as we
                            reflect on the challenges and invaluable lessons
                          </p>
                          <div className='flex items-center'>
                            <span className='text-sm text-gray-400'>By</span>
                            <img
                              className='block w-6 h-6 mx-2 rounded-full'
                              src='aurora-assets/blog/avatar-medium.png'
                              alt=''
                            />
                            <span className='text-sm font-medium text-white'>
                              Max Ranver
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='w-full md:w-1/2 px-4 mb-16'>
                      <a
                        className='flex flex-col h-full p-2 rounded-xl border border-gray-800 overflow-hidden hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                        href='#'
                      >
                        <div className='h-64 rounded-xl overflow-hidden'>
                          <img
                            className='block w-full h-full object-cover'
                            src='aurora-assets/blog/blog-element6-green.png'
                            alt=''
                          />
                        </div>
                        <div className='flex-grow-1 pt-6 pb-8 px-6'>
                          <div className='flex mb-2 items-center'>
                            <span className='text-sm text-gray-400'>
                              20 Jul 2023
                            </span>
                            <span className='mx-3'>
                              <svg
                                width={4}
                                height={4}
                                viewBox='0 0 4 4'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                              </svg>
                            </span>
                            <span className='text-sm text-gray-400'>
                              4 min read
                            </span>
                          </div>
                          <h2 className='font-heading tracking-tight text-xl text-white font-semibold mb-6'>
                            How We Built AI-Collections: Wrapping Upc
                          </h2>
                          <p className='text-sm text-gray-400 mb-4'>
                            Remarkable journey behind AI-Collections as we
                            reflect on the challenges and invaluable lessons
                          </p>
                          <div className='flex items-center'>
                            <span className='text-sm text-gray-400'>By</span>
                            <img
                              className='block w-6 h-6 mx-2 rounded-full'
                              src='aurora-assets/blog/avatar-medium.png'
                              alt=''
                            />
                            <span className='text-sm font-medium text-white'>
                              Max Ranver
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='w-full md:w-1/2 px-4 mb-16'>
                      <a
                        className='flex flex-col h-full p-2 rounded-xl border border-gray-800 overflow-hidden hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                        href='#'
                      >
                        <div className='h-64 rounded-xl overflow-hidden'>
                          <img
                            className='block w-full h-full object-cover'
                            src='aurora-assets/blog/blog-element6-green.png'
                            alt=''
                          />
                        </div>
                        <div className='flex-grow-1 pt-6 pb-8 px-6'>
                          <div className='flex mb-2 items-center'>
                            <span className='text-sm text-gray-400'>
                              20 Jul 2023
                            </span>
                            <span className='mx-3'>
                              <svg
                                width={4}
                                height={4}
                                viewBox='0 0 4 4'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                              </svg>
                            </span>
                            <span className='text-sm text-gray-400'>
                              4 min read
                            </span>
                          </div>
                          <h2 className='font-heading tracking-tight text-xl text-white font-semibold mb-6'>
                            Product Update June 2023
                          </h2>
                          <p className='text-sm text-gray-400 mb-4'>
                            Discover transformative hacks that will
                            revolutionize your productivity
                          </p>
                          <div className='flex items-center'>
                            <span className='text-sm text-gray-400'>By</span>
                            <img
                              className='block w-6 h-6 mx-2 rounded-full'
                              src='aurora-assets/blog/avatar-medium.png'
                              alt=''
                            />
                            <span className='text-sm font-medium text-white'>
                              Max Ranver
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='text-center border-b border-teal-800 mb-16'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-bold text-white mb-24'>
                Blog &amp; Articles
              </h1>
              <div className='flex -mx-4 w-full lg:w-auto flex-wrap items-bottom justify-center'>
                <div className='px-4'>
                  <a
                    className='inline-block px-2 sm:px-8 pb-8 leading-none font-semibold text-white sm:border-b-2 border-yellowGreen-500'
                    href='#'
                  >
                    All
                  </a>
                </div>
                <div className='px-4'>
                  <a
                    className='inline-block px-2 pb-8 leading-none text-gray-500 hover:text-white sm:border-b-2 border-transparent hover:border-yellowGreen-500'
                    href='#'
                  >
                    Featured
                  </a>
                </div>
                <div className='px-4'>
                  <a
                    className='inline-block px-2 pb-8 leading-none text-gray-500 hover:text-white sm:border-b-2 border-transparent hover:border-yellowGreen-500'
                    href='#'
                  >
                    Tutorials
                  </a>
                </div>
                <div className='px-4'>
                  <a
                    className='inline-block px-2 pb-8 leading-none text-gray-500 hover:text-white sm:border-b-2 border-transparent hover:border-yellowGreen-500'
                    href='#'
                  >
                    Tips
                  </a>
                </div>
                <div className='px-4'>
                  <a
                    className='inline-block px-2 pb-8 leading-none text-gray-500 hover:text-white sm:border-b-2 border-transparent hover:border-yellowGreen-500'
                    href='#'
                  >
                    Insights
                  </a>
                </div>
                <div className='px-4'>
                  <a
                    className='inline-block px-2 pb-8 leading-none text-gray-500 hover:text-white sm:border-b-2 border-transparent hover:border-yellowGreen-500'
                    href='#'
                  >
                    Other
                  </a>
                </div>
              </div>
            </div>
            <div className='relative rounded-xl mb-16 bg-white overflow-hidden'>
              <div className='flex flex-wrap'>
                <div className='w-full md:w-7/12'>
                  <div className='py-12 px-6 lg:py-24 lg:pl-20 lg:pr-24'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-500'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-500'>4 min read</span>
                    </div>
                    <div className='max-w-md lg:max-w-lg'>
                      <h2 className='font-heading tracking-tight text-3xl md:text-4xl text-gray-900 font-semibold mb-6'>
                        Effortless Asset Organization: Simplify with Bookmarking
                      </h2>
                      <p className='text-lg text-gray-500 mb-4'>
                        We've all experienced the chaos of multiple
                        spreadsheets, tracking and insight tools...
                      </p>
                      <div className='flex items-center mb-8'>
                        <span className='text-sm text-gray-500'>By</span>
                        <img
                          className='block w-5 h-5 mx-2 rounded-full'
                          src='aurora-assets/blog/avatar-medium.png'
                          alt=''
                        />
                        <span className='text-sm font-medium'>Max Ranver</span>
                      </div>
                      <a
                        className='group relative flex xs:inline-flex h-10 px-4 items-center justify-center text-sm font-semibold text-gray-700 hover:text-yellowGreen-600 border border-gray-200 hover:border-yellowGreen-600 rounded-lg transition duration-100'
                        href='#'
                      >
                        <span className='mr-2'>Read more</span>
                        <span className='transform group-hover:translate-x-1'>
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M4.00016 8H12.3335M12.3335 8L8.3335 4M12.3335 8L8.3335 12'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-5/12'>
                  <div className='flex h-full w-full items-center justify-end'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element-main.png'
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-16'>
                <a
                  className='group flex flex-col h-full md:max-w-sm rounded-xl bg-white border border-transparent hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                  href='#'
                >
                  <div className='flex-shrink-0 h-68 rounded-t-lg overflow-hidden'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element-2.png'
                      alt=''
                    />
                  </div>
                  <div className='h-full py-10 md:pt-6 md:pb-8 px-5 border-gray-200 border-l border-b border-r rounded-b-xl'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-500'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-500'>4 min read</span>
                    </div>
                    <h2 className='font-heading tracking-tight text-xl text-gray-900 font-semibold mb-4'>
                      Save Time, Stay Organized: Uncover the Magic
                    </h2>
                    <div className='flex items-center'>
                      <span className='text-sm text-gray-500'>By</span>
                      <img
                        className='block w-5 h-5 mx-2 rounded-full'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <span className='text-sm font-medium'>Max Ranver</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-16'>
                <a
                  className='group flex flex-col h-full md:max-w-sm rounded-xl bg-white border border-transparent hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                  href='#'
                >
                  <div className='flex-shrink-0 h-68 rounded-t-lg overflow-hidden'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element3.png'
                      alt=''
                    />
                  </div>
                  <div className='h-full py-10 md:pt-6 md:pb-8 px-5 border-gray-200 border-l border-b border-r rounded-b-xl'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-500'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-500'>4 min read</span>
                    </div>
                    <h2 className='font-heading tracking-tight text-xl text-gray-900 font-semibold mb-4'>
                      How We Built AI-Collections: Wrapping Up
                    </h2>
                    <div className='flex items-center'>
                      <span className='text-sm text-gray-500'>By</span>
                      <img
                        className='block w-5 h-5 mx-2 rounded-full'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <span className='text-sm font-medium'>Max Ranver</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-16'>
                <a
                  className='group flex flex-col h-full md:max-w-sm rounded-xl bg-white border border-transparent hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                  href='#'
                >
                  <div className='flex-shrink-0 h-68 rounded-t-lg overflow-hidden'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element4.png'
                      alt=''
                    />
                  </div>
                  <div className='h-full py-10 md:pt-6 md:pb-8 px-5 border-gray-200 border-l border-b border-r rounded-b-xl'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-500'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-500'>4 min read</span>
                    </div>
                    <h2 className='font-heading tracking-tight text-xl text-gray-900 font-semibold mb-4'>
                      5 Common Mistakes while Bookmarking Assets
                    </h2>
                    <div className='flex items-center'>
                      <span className='text-sm text-gray-500'>By</span>
                      <img
                        className='block w-5 h-5 mx-2 rounded-full'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <span className='text-sm font-medium'>Max Ranver</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-16'>
                <a
                  className='group flex flex-col h-full md:max-w-sm rounded-xl bg-white border border-transparent hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                  href='#'
                >
                  <div className='flex-shrink-0 h-68 rounded-t-lg overflow-hidden'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element5.png'
                      alt=''
                    />
                  </div>
                  <div className='h-full py-10 md:pt-6 md:pb-8 px-5 border-gray-200 border-l border-b border-r rounded-b-xl'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-500'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-500'>4 min read</span>
                    </div>
                    <h2 className='font-heading tracking-tight text-xl text-gray-900 font-semibold mb-4'>
                      Optimizing Loading Indicator
                    </h2>
                    <div className='flex items-center'>
                      <span className='text-sm text-gray-500'>By</span>
                      <img
                        className='block w-5 h-5 mx-2 rounded-full'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <span className='text-sm font-medium'>Max Ranver</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-16'>
                <a
                  className='group flex flex-col h-full md:max-w-sm rounded-xl bg-white border border-transparent hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                  href='#'
                >
                  <div className='flex-shrink-0 h-68 rounded-t-lg overflow-hidden'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element6.png'
                      alt=''
                    />
                  </div>
                  <div className='h-full py-10 md:pt-6 md:pb-8 px-5 border-gray-200 border-l border-b border-r rounded-b-xl'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-500'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-500'>4 min read</span>
                    </div>
                    <h2 className='font-heading tracking-tight text-xl text-gray-900 font-semibold mb-4'>
                      Product Update July 2023
                    </h2>
                    <div className='flex items-center'>
                      <span className='text-sm text-gray-500'>By</span>
                      <img
                        className='block w-5 h-5 mx-2 rounded-full'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <span className='text-sm font-medium'>Max Ranver</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-16'>
                <a
                  className='group flex flex-col h-full md:max-w-sm rounded-xl bg-white border border-transparent hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                  href='#'
                >
                  <div className='flex-shrink-0 h-68 rounded-t-lg overflow-hidden'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element6.png'
                      alt=''
                    />
                  </div>
                  <div className='h-full py-10 md:pt-6 md:pb-8 px-5 border-gray-200 border-l border-b border-r rounded-b-xl'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-500'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-500'>4 min read</span>
                    </div>
                    <h2 className='font-heading tracking-tight text-xl text-gray-900 font-semibold mb-4'>
                      Product Update July 2023
                    </h2>
                    <div className='flex items-center'>
                      <span className='text-sm text-gray-500'>By</span>
                      <img
                        className='block w-5 h-5 mx-2 rounded-full'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <span className='text-sm font-medium'>Max Ranver</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div>
              <nav>
                <ul className='flex items-center justify-center'>
                  <li className='mr-2'>
                    <a
                      className='group inline-block justify-center p-1 text-center text-sm text-white font-semibold rounded-lg'
                      href='#'
                    >
                      <div className='inline-flex items-stretch h-10 w-10 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                        <div className='flex h-full w-full items-center justify-center bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                          <span>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M12.5 5L7.5 10L12.5 15'
                                stroke='white'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className='mx-px'>
                    <a
                      className='inline-flex items-center justify-center w-9 h-9 text-sm text-gray-500 rounded-md hover:text-white'
                      href='#'
                    >
                      1
                    </a>
                  </li>
                  <li className='mx-px'>
                    <a
                      className='inline-flex items-center justify-center w-9 h-9 text-sm text-white font-semibold rounded-md bg-teal-800'
                      href='#'
                    >
                      2
                    </a>
                  </li>
                  <li className='mx-px'>
                    <a
                      className='inline-flex items-center justify-center w-9 h-9 text-sm text-gray-500 rounded-md hover:text-white'
                      href='#'
                    >
                      3
                    </a>
                  </li>
                  <li className='mx-px'>
                    <a
                      className='inline-flex items-center justify-center w-9 h-9 text-sm text-gray-500 rounded-md hover:text-white'
                      href='#'
                    >
                      ...
                    </a>
                  </li>
                  <li className='mx-px'>
                    <a
                      className='inline-flex items-center justify-center w-9 h-9 text-sm text-gray-500 rounded-md hover:text-white'
                      href='#'
                    >
                      10
                    </a>
                  </li>
                  <li className='ml-2'>
                    <a
                      className='group inline-block justify-center p-1 text-center text-sm text-white font-semibold rounded-lg'
                      href='#'
                    >
                      <div className='inline-flex items-stretch h-10 w-10 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                        <div className='flex h-full w-full items-center justify-center bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                          <span>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M7.5 5L12.5 10L7.5 15'
                                stroke='white'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='text-center border-b border-gray-800 mb-16'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-bold text-white mb-24'>
                Blog &amp; Articles
              </h1>
              <div className='flex -mx-4 w-full lg:w-auto flex-wrap items-bottom justify-center'>
                <div className='px-4'>
                  <a
                    className='inline-block px-2 sm:px-8 pb-8 leading-none font-semibold text-white sm:border-b-2 border-yellowGreen-500'
                    href='#'
                  >
                    All
                  </a>
                </div>
                <div className='px-4'>
                  <a
                    className='inline-block px-2 pb-8 leading-none text-gray-400 hover:text-white sm:border-b-2 border-transparent hover:border-yellowGreen-500'
                    href='#'
                  >
                    Featured
                  </a>
                </div>
                <div className='px-4'>
                  <a
                    className='inline-block px-2 pb-8 leading-none text-gray-400 hover:text-white sm:border-b-2 border-transparent hover:border-yellowGreen-500'
                    href='#'
                  >
                    Tutorials
                  </a>
                </div>
                <div className='px-4'>
                  <a
                    className='inline-block px-2 pb-8 leading-none text-gray-400 hover:text-white sm:border-b-2 border-transparent hover:border-yellowGreen-500'
                    href='#'
                  >
                    Tips
                  </a>
                </div>
                <div className='px-4'>
                  <a
                    className='inline-block px-2 pb-8 leading-none text-gray-400 hover:text-white sm:border-b-2 border-transparent hover:border-yellowGreen-500'
                    href='#'
                  >
                    Insights
                  </a>
                </div>
                <div className='px-4'>
                  <a
                    className='inline-block px-2 pb-8 leading-none text-gray-400 hover:text-white sm:border-b-2 border-transparent hover:border-yellowGreen-500'
                    href='#'
                  >
                    Other
                  </a>
                </div>
              </div>
            </div>
            <div className='relative rounded-xl shadow-xl mb-16 border border-gray-800 overflow-hidden'>
              <div className='flex flex-wrap'>
                <div className='w-full md:w-7/12'>
                  <div className='py-12 px-6 lg:py-24 lg:pl-20 lg:pr-24'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-400'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-400'>4 min read</span>
                    </div>
                    <div className='max-w-md lg:max-w-xl'>
                      <h2 className='font-heading tracking-tight text-3xl md:text-4xl text-white font-semibold mb-6'>
                        Effortless Asset Organization: Simplify with Bookmarking
                      </h2>
                      <p className='text-lg text-gray-400 mb-4'>
                        We've all experienced the chaos of multiple
                        spreadsheets, tracking and insight tools...
                      </p>
                      <div className='flex items-center mb-8'>
                        <span className='text-sm text-gray-400'>By</span>
                        <img
                          className='block w-5 h-5 mx-2 rounded-full'
                          src='aurora-assets/blog/avatar-medium.png'
                          alt=''
                        />
                        <span className='text-sm font-medium text-white'>
                          Max Ranver
                        </span>
                      </div>
                      <a
                        className='group relative flex xs:inline-flex h-10 px-4 items-center justify-center text-sm font-semibold text-gray-700 hover:text-white hover:bg-teal-800 bg-white rounded-lg transition duration-200'
                        href='#'
                      >
                        <span className='mr-2'>Read more</span>
                        <span className='transform group-hover:translate-x-1'>
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M4.00016 8H12.3335M12.3335 8L8.3335 4M12.3335 8L8.3335 12'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-5/12'>
                  <div className='flex h-full w-full items-center justify-end'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element-main-green.png'
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-16'>
                <a
                  className='group flex flex-col h-full md:max-w-sm rounded-xl border border-transparent hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                  href='#'
                >
                  <div className='flex-shrink-0 h-68 rounded-t-lg overflow-hidden'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element3-green.png'
                      alt=''
                    />
                  </div>
                  <div className='h-full py-10 md:pt-6 md:pb-8 px-5 shadow-xl border-gray-800 border-l border-b border-r rounded-b-xl'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-400'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-400'>4 min read</span>
                    </div>
                    <h2 className='font-heading tracking-tight text-xl text-white font-semibold mb-4'>
                      Save Time, Stay Organized: Uncover the Magic
                    </h2>
                    <div className='flex items-center'>
                      <span className='text-sm text-gray-400'>By</span>
                      <img
                        className='block w-5 h-5 mx-2 rounded-full'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <span className='text-sm font-medium text-white'>
                        Max Ranver
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-16'>
                <a
                  className='group flex flex-col h-full md:max-w-sm rounded-xl border border-transparent hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                  href='#'
                >
                  <div className='flex-shrink-0 h-68 rounded-t-lg overflow-hidden'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element4-green.png'
                      alt=''
                    />
                  </div>
                  <div className='h-full py-10 md:pt-6 md:pb-8 px-5 shadow-xl border-gray-800 border-l border-b border-r rounded-b-xl'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-400'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-400'>4 min read</span>
                    </div>
                    <h2 className='font-heading tracking-tight text-xl text-white font-semibold mb-4'>
                      How We Built AI-Collections: Wrapping Up
                    </h2>
                    <div className='flex items-center'>
                      <span className='text-sm text-gray-400'>By</span>
                      <img
                        className='block w-5 h-5 mx-2 rounded-full'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <span className='text-sm font-medium text-white'>
                        Max Ranver
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-16'>
                <a
                  className='group flex flex-col h-full md:max-w-sm rounded-xl border border-transparent hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                  href='#'
                >
                  <div className='flex-shrink-0 h-68 rounded-t-lg overflow-hidden'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element5-green.png'
                      alt=''
                    />
                  </div>
                  <div className='h-full py-10 md:pt-6 md:pb-8 px-5 shadow-xl border-gray-800 border-l border-b border-r rounded-b-xl'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-400'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-400'>4 min read</span>
                    </div>
                    <h2 className='font-heading tracking-tight text-xl text-white font-semibold mb-4'>
                      5 Common Mistakes while Bookmarking Assets
                    </h2>
                    <div className='flex items-center'>
                      <span className='text-sm text-gray-400'>By</span>
                      <img
                        className='block w-5 h-5 mx-2 rounded-full'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <span className='text-sm font-medium text-white'>
                        Max Ranver
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-16'>
                <a
                  className='group flex flex-col h-full md:max-w-sm rounded-xl border border-transparent hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                  href='#'
                >
                  <div className='flex-shrink-0 h-68 rounded-t-lg overflow-hidden'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element7-green.png'
                      alt=''
                    />
                  </div>
                  <div className='h-full py-10 md:pt-6 md:pb-8 px-5 shadow-xl border-gray-800 border-l border-b border-r rounded-b-xl'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-400'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-400'>4 min read</span>
                    </div>
                    <h2 className='font-heading tracking-tight text-xl text-white font-semibold mb-4'>
                      Optimizing Loading Indicator
                    </h2>
                    <div className='flex items-center'>
                      <span className='text-sm text-gray-400'>By</span>
                      <img
                        className='block w-5 h-5 mx-2 rounded-full'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <span className='text-sm font-medium text-white'>
                        Max Ranver
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-16'>
                <a
                  className='group flex flex-col h-full md:max-w-sm rounded-xl border border-transparent hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                  href='#'
                >
                  <div className='flex-shrink-0 h-68 rounded-t-lg overflow-hidden'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element6-green.png'
                      alt=''
                    />
                  </div>
                  <div className='h-full py-10 md:pt-6 md:pb-8 px-5 shadow-xl border-gray-800 border-l border-b border-r rounded-b-xl'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-400'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-400'>4 min read</span>
                    </div>
                    <h2 className='font-heading tracking-tight text-xl text-white font-semibold mb-4'>
                      Product Update July 2023
                    </h2>
                    <div className='flex items-center'>
                      <span className='text-sm text-gray-400'>By</span>
                      <img
                        className='block w-5 h-5 mx-2 rounded-full'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <span className='text-sm font-medium text-white'>
                        Max Ranver
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-16'>
                <a
                  className='group flex flex-col h-full md:max-w-sm rounded-xl border border-transparent hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                  href='#'
                >
                  <div className='flex-shrink-0 h-68 rounded-t-lg overflow-hidden'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element6-green.png'
                      alt=''
                    />
                  </div>
                  <div className='h-full py-10 md:pt-6 md:pb-8 px-5 shadow-xl border-gray-800 border-l border-b border-r rounded-b-xl'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-400'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-400'>4 min read</span>
                    </div>
                    <h2 className='font-heading tracking-tight text-xl text-white font-semibold mb-4'>
                      Product Update July 2023
                    </h2>
                    <div className='flex items-center'>
                      <span className='text-sm text-gray-400'>By</span>
                      <img
                        className='block w-5 h-5 mx-2 rounded-full'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <span className='text-sm font-medium text-white'>
                        Max Ranver
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div>
              <nav>
                <ul className='flex items-center justify-center'>
                  <li className='mr-2'>
                    <a
                      className='group inline-block justify-center p-1 text-center text-sm text-white font-semibold rounded-lg'
                      href='#'
                    >
                      <div className='inline-flex items-stretch h-10 w-10 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                        <div className='flex h-full w-full items-center justify-center bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                          <span>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M12.5 5L7.5 10L12.5 15'
                                stroke='white'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className='mx-px'>
                    <a
                      className='inline-flex items-center justify-center w-9 h-9 text-sm text-gray-400 rounded-md hover:text-white'
                      href='#'
                    >
                      1
                    </a>
                  </li>
                  <li className='mx-px'>
                    <a
                      className='inline-flex items-center justify-center w-9 h-9 text-sm text-white font-semibold rounded-md bg-gray-800'
                      href='#'
                    >
                      2
                    </a>
                  </li>
                  <li className='mx-px'>
                    <a
                      className='inline-flex items-center justify-center w-9 h-9 text-sm text-gray-400 rounded-md hover:text-white'
                      href='#'
                    >
                      3
                    </a>
                  </li>
                  <li className='mx-px'>
                    <a
                      className='inline-flex items-center justify-center w-9 h-9 text-sm text-gray-400 rounded-md hover:text-white'
                      href='#'
                    >
                      ...
                    </a>
                  </li>
                  <li className='mx-px'>
                    <a
                      className='inline-flex items-center justify-center w-9 h-9 text-sm text-gray-400 rounded-md hover:text-white'
                      href='#'
                    >
                      10
                    </a>
                  </li>
                  <li className='ml-2'>
                    <a
                      className='group inline-block justify-center p-1 text-center text-sm text-white font-semibold rounded-lg'
                      href='#'
                    >
                      <div className='inline-flex items-stretch h-10 w-10 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                        <div className='flex h-full w-full items-center justify-center bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                          <span>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M7.5 5L12.5 10L7.5 15'
                                stroke='white'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-white'>
          <div className='container px-4 mx-auto'>
            <div className='text-center border-b mb-16'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-bold mb-24'>
                Blog &amp; Articles
              </h1>
              <div className='flex -mx-4 w-full lg:w-auto flex-wrap items-bottom justify-center'>
                <div className='px-4'>
                  <a
                    className='inline-block px-2 sm:px-8 pb-8 leading-none font-semibold text-gray-900 sm:border-b-2 border-yellowGreen-500'
                    href='#'
                  >
                    All
                  </a>
                </div>
                <div className='px-4'>
                  <a
                    className='inline-block px-2 pb-8 leading-none text-gray-500 hover:text-gray-900 sm:border-b-2 border-transparent hover:border-yellowGreen-500'
                    href='#'
                  >
                    Featured
                  </a>
                </div>
                <div className='px-4'>
                  <a
                    className='inline-block px-2 pb-8 leading-none text-gray-500 hover:text-gray-900 sm:border-b-2 border-transparent hover:border-yellowGreen-500'
                    href='#'
                  >
                    Tutorials
                  </a>
                </div>
                <div className='px-4'>
                  <a
                    className='inline-block px-2 pb-8 leading-none text-gray-500 hover:text-gray-900 sm:border-b-2 border-transparent hover:border-yellowGreen-500'
                    href='#'
                  >
                    Tips
                  </a>
                </div>
                <div className='px-4'>
                  <a
                    className='inline-block px-2 pb-8 leading-none text-gray-500 hover:text-gray-900 sm:border-b-2 border-transparent hover:border-yellowGreen-500'
                    href='#'
                  >
                    Insights
                  </a>
                </div>
                <div className='px-4'>
                  <a
                    className='inline-block px-2 pb-8 leading-none text-gray-500 hover:text-gray-900 sm:border-b-2 border-transparent hover:border-yellowGreen-500'
                    href='#'
                  >
                    Other
                  </a>
                </div>
              </div>
            </div>
            <div className='relative rounded-xl shadow-xl mb-16 border border-gray-200 bg-radial-dark-green overflow-hidden'>
              <div className='flex flex-wrap'>
                <div className='w-full md:w-7/12'>
                  <div className='py-12 px-6 lg:py-24 lg:pl-20 lg:pr-24'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-400'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-400'>4 min read</span>
                    </div>
                    <div className='max-w-md lg:max-w-xl'>
                      <h2 className='font-heading tracking-tight text-3xl md:text-4xl text-white font-semibold mb-6'>
                        Effortless Asset Organization: Simplify with Bookmarking
                      </h2>
                      <p className='text-lg text-gray-200 mb-4'>
                        We've all experienced the chaos of multiple
                        spreadsheets, tracking and insight tools...
                      </p>
                      <div className='flex items-center mb-8'>
                        <span className='text-sm text-gray-200'>By</span>
                        <img
                          className='block w-5 h-5 mx-2 rounded-full'
                          src='aurora-assets/blog/avatar-medium.png'
                          alt=''
                        />
                        <span className='text-sm font-medium text-white'>
                          Max Ranver
                        </span>
                      </div>
                      <a
                        className='group relative flex xs:inline-flex h-10 px-4 items-center justify-center text-sm font-semibold text-gray-700 hover:text-white hover:bg-teal-800 bg-white rounded-lg transition duration-300'
                        href='#'
                      >
                        <span className='mr-2'>Read more</span>
                        <span className='transform group-hover:translate-x-1'>
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M4.00016 8H12.3335M12.3335 8L8.3335 4M12.3335 8L8.3335 12'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-5/12'>
                  <div className='flex h-full w-full items-center justify-end'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element-main-green.png'
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-16'>
                <a
                  className='group flex flex-col h-full md:max-w-sm rounded-xl bg-white border border-transparent hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                  href='#'
                >
                  <div className='flex-shrink-0 h-68 rounded-t-lg overflow-hidden'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element3-green.png'
                      alt=''
                    />
                  </div>
                  <div className='h-full py-10 md:pt-6 md:pb-8 px-5 shadow-xl border-gray-200 border-l border-b border-r rounded-b-xl'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-400'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-400'>4 min read</span>
                    </div>
                    <h2 className='font-heading tracking-tight text-xl text-gray-900 font-semibold mb-4'>
                      Save Time, Stay Organized: Uncover the Magic
                    </h2>
                    <div className='flex items-center'>
                      <span className='text-sm text-gray-400'>By</span>
                      <img
                        className='block w-5 h-5 mx-2 rounded-full'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <span className='text-sm font-medium'>Max Ranver</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-16'>
                <a
                  className='group flex flex-col h-full md:max-w-sm rounded-xl bg-white border border-transparent hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                  href='#'
                >
                  <div className='flex-shrink-0 h-68 rounded-t-lg overflow-hidden'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element4-green.png'
                      alt=''
                    />
                  </div>
                  <div className='h-full py-10 md:pt-6 md:pb-8 px-5 shadow-xl border-gray-200 border-l border-b border-r rounded-b-xl'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-400'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-400'>4 min read</span>
                    </div>
                    <h2 className='font-heading tracking-tight text-xl text-gray-900 font-semibold mb-4'>
                      How We Built AI-Collections: Wrapping Up
                    </h2>
                    <div className='flex items-center'>
                      <span className='text-sm text-gray-400'>By</span>
                      <img
                        className='block w-5 h-5 mx-2 rounded-full'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <span className='text-sm font-medium'>Max Ranver</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-16'>
                <a
                  className='group flex flex-col h-full md:max-w-sm rounded-xl bg-white border border-transparent hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                  href='#'
                >
                  <div className='flex-shrink-0 h-68 rounded-t-lg overflow-hidden'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element5-green.png'
                      alt=''
                    />
                  </div>
                  <div className='h-full py-10 md:pt-6 md:pb-8 px-5 shadow-xl border-gray-200 border-l border-b border-r rounded-b-xl'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-400'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-400'>4 min read</span>
                    </div>
                    <h2 className='font-heading tracking-tight text-xl text-gray-900 font-semibold mb-4'>
                      5 Common Mistakes while Bookmarking Assets
                    </h2>
                    <div className='flex items-center'>
                      <span className='text-sm text-gray-400'>By</span>
                      <img
                        className='block w-5 h-5 mx-2 rounded-full'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <span className='text-sm font-medium'>Max Ranver</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-16'>
                <a
                  className='group flex flex-col h-full md:max-w-sm rounded-xl bg-white border border-transparent hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                  href='#'
                >
                  <div className='flex-shrink-0 h-68 rounded-t-lg overflow-hidden'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element7-green.png'
                      alt=''
                    />
                  </div>
                  <div className='h-full py-10 md:pt-6 md:pb-8 px-5 shadow-xl border-gray-200 border-l border-b border-r rounded-b-xl'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-400'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-400'>4 min read</span>
                    </div>
                    <h2 className='font-heading tracking-tight text-xl text-gray-900 font-semibold mb-4'>
                      Optimizing Loading Indicator
                    </h2>
                    <div className='flex items-center'>
                      <span className='text-sm text-gray-400'>By</span>
                      <img
                        className='block w-5 h-5 mx-2 rounded-full'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <span className='text-sm font-medium'>Max Ranver</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-16'>
                <a
                  className='group flex flex-col h-full md:max-w-sm rounded-xl bg-white border border-transparent hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                  href='#'
                >
                  <div className='flex-shrink-0 h-68 rounded-t-lg overflow-hidden'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element6-green.png'
                      alt=''
                    />
                  </div>
                  <div className='h-full py-10 md:pt-6 md:pb-8 px-5 shadow-xl border-gray-200 border-l border-b border-r rounded-b-xl'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-400'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-400'>4 min read</span>
                    </div>
                    <h2 className='font-heading tracking-tight text-xl text-gray-900 font-semibold mb-4'>
                      Product Update July 2023
                    </h2>
                    <div className='flex items-center'>
                      <span className='text-sm text-gray-400'>By</span>
                      <img
                        className='block w-5 h-5 mx-2 rounded-full'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <span className='text-sm font-medium'>Max Ranver</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-16'>
                <a
                  className='group flex flex-col h-full md:max-w-sm rounded-xl bg-white border border-transparent hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500'
                  href='#'
                >
                  <div className='flex-shrink-0 h-68 rounded-t-lg overflow-hidden'>
                    <img
                      className='block w-full'
                      src='aurora-assets/blog/blog-element6-green.png'
                      alt=''
                    />
                  </div>
                  <div className='h-full py-10 md:pt-6 md:pb-8 px-5 shadow-xl border-gray-200 border-l border-b border-r rounded-b-xl'>
                    <div className='flex mb-4 items-center'>
                      <span className='text-sm text-gray-400'>20 Jul 2023</span>
                      <span className='mx-3'>
                        <svg
                          width={4}
                          height={4}
                          viewBox='0 0 4 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                        </svg>
                      </span>
                      <span className='text-sm text-gray-400'>4 min read</span>
                    </div>
                    <h2 className='font-heading tracking-tight text-xl text-gray-900 font-semibold mb-4'>
                      Product Update July 2023
                    </h2>
                    <div className='flex items-center'>
                      <span className='text-sm text-gray-400'>By</span>
                      <img
                        className='block w-5 h-5 mx-2 rounded-full'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                      <span className='text-sm font-medium'>Max Ranver</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div>
              <nav>
                <ul className='flex items-center justify-center'>
                  <li className='mr-2'>
                    <a
                      className='inline-flex items-center justify-center w-9 h-9 text-sm text-gray-500 rounded-md border border-gray-200 shadow-sm hover:bg-gray-50'
                      href='#'
                    >
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M12.5 5L7.5 10L12.5 15'
                          stroke='#3B5444'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </a>
                  </li>
                  <li className='mx-px'>
                    <a
                      className='inline-flex items-center justify-center w-9 h-9 text-sm text-gray-500 rounded-md hover:bg-gray-50'
                      href='#'
                    >
                      1
                    </a>
                  </li>
                  <li className='mx-px'>
                    <a
                      className='inline-flex items-center justify-center w-9 h-9 text-sm text-gray-900 font-semibold rounded-md bg-gray-50'
                      href='#'
                    >
                      2
                    </a>
                  </li>
                  <li className='mx-px'>
                    <a
                      className='inline-flex items-center justify-center w-9 h-9 text-sm text-gray-500 rounded-md hover:bg-gray-50'
                      href='#'
                    >
                      3
                    </a>
                  </li>
                  <li className='mx-px'>
                    <a
                      className='inline-flex items-center justify-center w-9 h-9 text-sm text-gray-500 rounded-md hover:bg-gray-50'
                      href='#'
                    >
                      ...
                    </a>
                  </li>
                  <li className='mx-px'>
                    <a
                      className='inline-flex items-center justify-center w-9 h-9 text-sm text-gray-500 rounded-md hover:bg-gray-50'
                      href='#'
                    >
                      10
                    </a>
                  </li>
                  <li className='ml-2'>
                    <a
                      className='inline-flex items-center justify-center w-9 h-9 text-sm text-gray-500 rounded-md border border-gray-200 shadow-sm hover:bg-gray-50'
                      href='#'
                    >
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M7.5 5L12.5 10L7.5 15'
                          stroke='#3B5444'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </section>
      </>
    <FooterComponent /></React.Fragment>
  );
}

