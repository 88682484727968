import React from 'react';

import { Flex, Text } from '@radix-ui/themes';


import {FooterComponent} from './Footers.jsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function SignUp() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section>
          <div className='flex flex-wrap'>
            <div className='w-full lg:w-1/2 px-5 bg-gray-50'>
              <div className='pt-8 pb-12 lg:pb-32 md:px-8'>
                <div className='flex items-center justify-end mb-20'>
                  <span className='mr-2 text-sm text-gray-500'>
                    Already have an account?
                  </span>
                  <a
                    className='inline-flex items-center justify-center h-10 px-4 text-center text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold bg-white border border-gray-200 hover:border-yellowGreen-600 rounded-lg shadow-sm hover:shadow-none transition duration-200'
                    href='#'
                  >
                    Login
                  </a>
                </div>
                <div className='max-w-sm mx-auto'>
                  <h3 className='font-heading tracking-tight text-4xl font-bold mb-4'>
                    Sign up
                  </h3>
                  <p className='text-gray-500 mb-8'>
                    Create your account with your work email.
                  </p>
                  <form action=''>
                    <a
                      className='inline-flex w-full h-12 py-2 px-5 items-center justify-center text-base font-bold text-gray-700 hover:text-yellowGreen-700 bg-white border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                      href='#'
                    >
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M10 1.91663C12.0354 1.91663 13.89 2.67488 15.3114 3.91834L13.3034 5.92639C12.4018 5.19322 11.2539 4.74996 10 4.74996C7.10068 4.74996 4.75 7.10064 4.75 9.99996C4.75 12.8993 7.10068 15.25 10 15.25C12.286 15.25 14.2241 13.7867 14.9452 11.7501L15.0633 11.4166H14.7096H10.25V8.58329H17.25V8.61788H17.5H17.9634C18.0412 9.06748 18.0833 9.52903 18.0833 9.99996C18.0833 14.464 14.464 18.0833 10 18.0833C5.53599 18.0833 1.91667 14.464 1.91667 9.99996C1.91667 5.53595 5.53599 1.91663 10 1.91663Z'
                          fill='#FFC107'
                          stroke='#3B5444'
                          strokeWidth='0.5'
                        />
                        <path
                          d='M2.6275 6.12121L5.36542 8.12913C6.10625 6.29496 7.90042 4.99996 10 4.99996C11.2746 4.99996 12.4342 5.48079 13.3171 6.26621L15.6742 3.90913C14.1858 2.52204 12.195 1.66663 10 1.66663C6.79917 1.66663 4.02333 3.47371 2.6275 6.12121Z'
                          fill='#FF3D00'
                        />
                        <path
                          d='M10 18.3334C12.1525 18.3334 14.1083 17.5096 15.5871 16.17L13.0079 13.9875C12.1431 14.6452 11.0864 15.0009 10 15C7.8325 15 5.99208 13.618 5.29875 11.6892L2.58125 13.783C3.96042 16.4817 6.76125 18.3334 10 18.3334Z'
                          fill='#4CAF50'
                        />
                        <path
                          d='M18.1713 8.36796H17.5V8.33337H10V11.6667H14.7096C14.3809 12.5902 13.7889 13.3972 13.0067 13.988L13.0079 13.9871L15.5871 16.1696C15.4046 16.3355 18.3333 14.1667 18.3333 10C18.3333 9.44129 18.2758 8.89587 18.1713 8.36796Z'
                          fill='#1976D2'
                        />
                      </svg>
                      <span className='ml-2'>Continue with Google</span>
                    </a>
                    <div className='my-8 flex items-center'>
                      <div className='h-px w-full bg-gray-200' />
                      <span className='inline-block mx-4 text-xs font-medium text-gray-500'>
                        OR
                      </span>
                      <div className='h-px w-full bg-gray-200' />
                    </div>
                    <div className='mb-8'>
                      <label
                        className='block mb-2 text-sm font-medium'
                        htmlFor=''
                      >
                        Email
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-white border border-gray-200 focus:border-yellowGreen-500 rounded-lg shadow-sm outline-none ring ring-transparent focus:ring-yellowGreen-500'
                        type='email'
                        placeholder='Enter your email address'
                      />
                    </div>
                    <button variant="classic"
                      className='group relative flex items-center justify-center px-5 h-12 mb-8 w-full font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300 focus:outline-none'
                      type='submit'
                    >
                      <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                      <span>Sign up</span>
                    </button>
                    <p className='text-sm'>
                      <span className='mr-1 text-gray-500'>
                        By signing up, you agree to our
                      </span>
                      <a
                        className='inline-block text-teal-500 hover:text-yellowGreen-600 font-semibold'
                        href='#'
                      >
                        Terms and Privacy Policy
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <div className='w-full lg:w-1/2 px-5'>
              <div className='flex h-full py-12 items-center justify-center'>
                <div className='max-w-md sm:max-w-lg px-2'>
                  <div className='py-12 xs:px-10 border-t border-b border-gray-200'>
                    <span className='block mb-5'>
                      <svg
                        width={18}
                        height={12}
                        viewBox='0 0 18 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M9.072 0.351998L6.192 11.2H0.528L4.848 0.351998H9.072ZM17.568 0.351998L14.736 11.2H9.072L13.392 0.351998H17.568Z'
                          fill='#041109'
                        />
                      </svg>
                    </span>
                    <p className='text-2xl font-medium mb-10'>
                      I've tried numerous assets manager platforms, but this one
                      truly stands out!
                    </p>
                    <div className='flex items-center justify-between'>
                      <div>
                        <span className='block mb-1 text-sm font-semibold'>
                          Cooper Levin
                        </span>
                        <span className='block text-xs text-gray-500'>
                          Founder of AIME Inc.
                        </span>
                      </div>
                      <img
                        src='aurora-assets/logos/logo-aurora-dark.svg'
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='flex flex-wrap'>
            <div className='w-full lg:w-5/12 px-5 bg-gray-50'>
              <div className='py-12 md:pt-8 h-full'>
                <div className='flex flex-col h-full max-w-xs mx-auto'>
                  <a className='inline-block mb-24' href='#'>
                    <img
                      className='h-8'
                      src='aurora-assets/logos/aurora-logo.svg'
                      alt=''
                    />
                  </a>
                  <img
                    className='block w-64 mb-10'
                    src='aurora-assets/sign-up/circle-image.png'
                    alt=''
                  />
                  <h3 className='font-heading tracking-tight text-4xl font-bold mb-4'>
                    Sign up
                  </h3>
                  <p className='mb-24 text-gray-500'>
                    Create your account with your work email.
                  </p>
                  <a
                    className='inline-flex mt-auto items-center text-sm text-gray-500 hover:text-yellowGreen-600 font-semibold'
                    href='#'
                  >
                    <svg
                      width={20}
                      height={20}
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M12.5 5L7.5 10L12.5 15'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                    <span className='ml-2'>Back</span>
                  </a>
                </div>
              </div>
            </div>
            <div className='w-full lg:w-7/12 px-5 bg-white'>
              <div className='py-12 md:pt-8 md:px-8'>
                <div className='max-w-sm lg:max-w-md xl:max-w-lg mx-auto lg:mr-0 xl:mx-auto'>
                  <div className='flex items-center justify-end mb-20'>
                    <a
                      className='inline-flex mr-10 items-center leading-loose text-gray-500 text-sm font-semibold hover:text-yellowGreen-600'
                      href='#'
                    >
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3478_15610)'>
                          <path
                            d='M10.0001 18.3333C14.6025 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6025 1.66663 10.0001 1.66663C5.39771 1.66663 1.66675 5.39759 1.66675 9.99996C1.66675 14.6023 5.39771 18.3333 10.0001 18.3333Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M2.08325 10.4166L6.66658 12.0833L5.83325 15L6.66658 17.5'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M14.1667 17.0833L13.7501 15L11.6667 14.1666V11.25L14.1667 10.4166L17.9167 10.8333'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M15.8333 4.58337L15.4167 5.83337L12.5 6.25004V8.75004L14.5833 7.91671H16.25L17.9167 8.75004'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M2.08325 8.75L4.16659 7.08333L6.24992 6.66667L7.91659 4.16667L7.08325 2.5'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                      <span className='ml-2'>English</span>
                    </a>
                    <a
                      className='inline-flex items-center justify-center h-10 px-4 text-center leading-loose text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold border border-gray-200 hover:border-yellowGreen-600 rounded-lg shadow-sm hover:shadow-none transition duration-200'
                      href='#'
                    >
                      Login
                    </a>
                  </div>
                  <div className='max-w-sm'>
                    <form action=''>
                      <div className='mb-8'>
                        <label
                          className='block mb-2 text-sm font-medium'
                          htmlFor=''
                        >
                          Name
                        </label>
                        <input
                          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg shadow-sm outline-none ring ring-transparent focus:ring-yellowGreen-500'
                          type='text'
                          placeholder='Enter your name'
                        />
                      </div>
                      <div className='mb-8'>
                        <label
                          className='block mb-2 text-sm font-medium'
                          htmlFor=''
                        >
                          Email
                        </label>
                        <input
                          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg shadow-sm outline-none ring ring-transparent focus:ring-yellowGreen-500'
                          type='email'
                          placeholder='Enter your email address'
                        />
                      </div>
                      <div className='mb-8'>
                        <label
                          className='block mb-2 text-sm font-medium'
                          htmlFor=''
                        >
                          What’s your role
                        </label>
                        <div className='relative bg-gray-50 bg-opacity-40 rounded-lg'>
                          <select
                            className='relative z-10 py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-transparent border border-gray-200 focus:border-yellowGreen-500 rounded-lg shadow-sm outline-none ring ring-transparent focus:ring-yellowGreen-500 appearance-none'
                            name=''
                            id=''
                          >
                            <option value={1}>Select role</option>
                            <option value={1}>Marketing</option>
                            <option value={1}>Sales</option>
                          </select>
                          <span className='absolute top-1/2 right-0 mr-3 transform -translate-y-1/2'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M5 7.5L10 12.5L15 7.5'
                                stroke='#6C7F73'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <div className='mb-8'>
                        <label
                          className='block mb-2 text-sm font-medium'
                          htmlFor=''
                        >
                          Password
                        </label>
                        <div className='relative'>
                          <input
                            className='relative py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg shadow-sm outline-none ring ring-transparent focus:ring-yellowGreen-500'
                            type='password'
                            placeholder='Enter password'
                          />
                          <button variant="classic" className='absolute top-1/2 right-0 mr-3 transform -translate-y-1/2 inline-block focus:outline-none text-gray-500 hover:text-yellowGreen-600'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M2.5 10.8334C5.5 4.16671 14.5 4.16671 17.5 10.8334'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M10 14.1666C8.61929 14.1666 7.5 13.0473 7.5 11.6666C7.5 10.2859 8.61929 9.16663 10 9.16663C11.3807 9.16663 12.5 10.2859 12.5 11.6666C12.5 13.0473 11.3807 14.1666 10 14.1666Z'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <button variant="classic"
                        className='group relative flex items-center justify-center px-5 h-12 w-full font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300 focus:outline-none'
                        type='submit'
                      >
                        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                        <span>Sign up</span>
                      </button>
                      <div className='my-4 flex items-center'>
                        <div className='h-px w-full bg-gray-200' />
                        <span className='inline-block mx-4 text-xs font-medium text-gray-500'>
                          OR
                        </span>
                        <div className='h-px w-full bg-gray-200' />
                      </div>
                      <a
                        className='inline-flex w-full h-12 mb-18 py-2 px-5 items-center justify-center text-base font-bold text-gray-700 hover:text-yellowGreen-700 border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                        href='#'
                      >
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M10 1.91663C12.0354 1.91663 13.89 2.67488 15.3114 3.91834L13.3034 5.92639C12.4018 5.19322 11.2539 4.74996 10 4.74996C7.10068 4.74996 4.75 7.10064 4.75 9.99996C4.75 12.8993 7.10068 15.25 10 15.25C12.286 15.25 14.2241 13.7867 14.9452 11.7501L15.0633 11.4166H14.7096H10.25V8.58329H17.25V8.61788H17.5H17.9634C18.0412 9.06748 18.0833 9.52903 18.0833 9.99996C18.0833 14.464 14.464 18.0833 10 18.0833C5.53599 18.0833 1.91667 14.464 1.91667 9.99996C1.91667 5.53595 5.53599 1.91663 10 1.91663Z'
                            fill='#FFC107'
                            stroke='#3B5444'
                            strokeWidth='0.5'
                          />
                          <path
                            d='M2.6275 6.12121L5.36542 8.12913C6.10625 6.29496 7.90042 4.99996 10 4.99996C11.2746 4.99996 12.4342 5.48079 13.3171 6.26621L15.6742 3.90913C14.1858 2.52204 12.195 1.66663 10 1.66663C6.79917 1.66663 4.02333 3.47371 2.6275 6.12121Z'
                            fill='#FF3D00'
                          />
                          <path
                            d='M10 18.3334C12.1525 18.3334 14.1083 17.5096 15.5871 16.17L13.0079 13.9875C12.1431 14.6452 11.0864 15.0009 10 15C7.8325 15 5.99208 13.618 5.29875 11.6892L2.58125 13.783C3.96042 16.4817 6.76125 18.3334 10 18.3334Z'
                            fill='#4CAF50'
                          />
                          <path
                            d='M18.1713 8.36796H17.5V8.33337H10V11.6667H14.7096C14.3809 12.5902 13.7889 13.3972 13.0067 13.988L13.0079 13.9871L15.5871 16.1696C15.4046 16.3355 18.3333 14.1667 18.3333 10C18.3333 9.44129 18.2758 8.89587 18.1713 8.36796Z'
                            fill='#1976D2'
                          />
                        </svg>
                        <span className='ml-2'>Continue with Google</span>
                      </a>
                      <p className='text-sm'>
                        <span className='mr-1 text-gray-500'>
                          By signing up, you agree to our
                        </span>
                        <a
                          className='inline-block text-teal-500 hover:text-yellowGreen-600 font-semibold'
                          href='#'
                        >
                          Terms and Privacy Policy
                        </a>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='flex flex-wrap'>
            <div className='w-full lg:w-1/2 px-5 bg-gray-50'>
              <div className='pt-8 pb-12 lg:pb-32 md:px-8'>
                <div className='flex items-center justify-end mb-20'>
                  <span className='mr-2 text-sm text-gray-500'>
                    Already have an account?
                  </span>
                  <a
                    className='inline-flex items-center justify-center h-10 px-4 text-center text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold bg-white border border-gray-200 hover:border-yellowGreen-600 rounded-lg shadow-sm hover:shadow-none transition duration-200'
                    href='#'
                  >
                    Login
                  </a>
                </div>
                <div className='max-w-sm mx-auto'>
                  <h3 className='font-heading tracking-tight text-4xl font-bold mb-4'>
                    Sign up
                  </h3>
                  <p className='text-gray-500 mb-8'>
                    Create your account with your work email.
                  </p>
                  <form action=''>
                    <a
                      className='inline-flex w-full h-12 py-2 px-5 items-center justify-center text-base font-bold text-gray-700 hover:text-yellowGreen-700 bg-white border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                      href='#'
                    >
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M10 1.91663C12.0354 1.91663 13.89 2.67488 15.3114 3.91834L13.3034 5.92639C12.4018 5.19322 11.2539 4.74996 10 4.74996C7.10068 4.74996 4.75 7.10064 4.75 9.99996C4.75 12.8993 7.10068 15.25 10 15.25C12.286 15.25 14.2241 13.7867 14.9452 11.7501L15.0633 11.4166H14.7096H10.25V8.58329H17.25V8.61788H17.5H17.9634C18.0412 9.06748 18.0833 9.52903 18.0833 9.99996C18.0833 14.464 14.464 18.0833 10 18.0833C5.53599 18.0833 1.91667 14.464 1.91667 9.99996C1.91667 5.53595 5.53599 1.91663 10 1.91663Z'
                          fill='#FFC107'
                          stroke='#3B5444'
                          strokeWidth='0.5'
                        />
                        <path
                          d='M2.6275 6.12121L5.36542 8.12913C6.10625 6.29496 7.90042 4.99996 10 4.99996C11.2746 4.99996 12.4342 5.48079 13.3171 6.26621L15.6742 3.90913C14.1858 2.52204 12.195 1.66663 10 1.66663C6.79917 1.66663 4.02333 3.47371 2.6275 6.12121Z'
                          fill='#FF3D00'
                        />
                        <path
                          d='M10 18.3334C12.1525 18.3334 14.1083 17.5096 15.5871 16.17L13.0079 13.9875C12.1431 14.6452 11.0864 15.0009 10 15C7.8325 15 5.99208 13.618 5.29875 11.6892L2.58125 13.783C3.96042 16.4817 6.76125 18.3334 10 18.3334Z'
                          fill='#4CAF50'
                        />
                        <path
                          d='M18.1713 8.36796H17.5V8.33337H10V11.6667H14.7096C14.3809 12.5902 13.7889 13.3972 13.0067 13.988L13.0079 13.9871L15.5871 16.1696C15.4046 16.3355 18.3333 14.1667 18.3333 10C18.3333 9.44129 18.2758 8.89587 18.1713 8.36796Z'
                          fill='#1976D2'
                        />
                      </svg>
                      <span className='ml-2'>Continue with Google</span>
                    </a>
                    <div className='my-8 flex items-center'>
                      <div className='h-px w-full bg-gray-200' />
                      <span className='inline-block mx-4 text-xs font-medium text-gray-500'>
                        OR
                      </span>
                      <div className='h-px w-full bg-gray-200' />
                    </div>
                    <div className='mb-8'>
                      <label
                        className='block mb-2 text-sm font-medium'
                        htmlFor=''
                      >
                        Email
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-white border border-gray-200 focus:border-yellowGreen-500 rounded-lg shadow-sm outline-none ring ring-transparent focus:ring-yellowGreen-500'
                        type='email'
                        placeholder='Enter your email address'
                      />
                    </div>
                    <button variant="classic"
                      className='group relative flex items-center justify-center px-5 h-12 mb-8 w-full font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300 focus:outline-none'
                      type='submit'
                    >
                      <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                      <span>Sign up</span>
                    </button>
                    <p className='text-sm'>
                      <span className='mr-1 text-gray-500'>
                        By signing up, you agree to our
                      </span>
                      <a
                        className='inline-block text-teal-500 hover:text-yellowGreen-600 font-semibold'
                        href='#'
                      >
                        Terms and Privacy Policy
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <div className='w-full lg:w-1/2 px-5'>
              <div className='flex h-full py-12 items-center justify-center'>
                <div className='max-w-md sm:max-w-lg px-2'>
                  <div className='py-12 xs:px-10 border-t border-b border-gray-200'>
                    <span className='block mb-5'>
                      <svg
                        width={18}
                        height={12}
                        viewBox='0 0 18 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M9.072 0.351998L6.192 11.2H0.528L4.848 0.351998H9.072ZM17.568 0.351998L14.736 11.2H9.072L13.392 0.351998H17.568Z'
                          fill='#041109'
                        />
                      </svg>
                    </span>
                    <p className='text-2xl font-medium mb-10'>
                      I've tried numerous assets manager platforms, but this one
                      truly stands out!
                    </p>
                    <div className='flex items-center justify-between'>
                      <div>
                        <span className='block mb-1 text-sm font-semibold'>
                          Cooper Levin
                        </span>
                        <span className='block text-xs text-gray-500'>
                          Founder of AIME Inc.
                        </span>
                      </div>
                      <img
                        src='aurora-assets/logos/logo-aurora-dark.svg'
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 overflow-hidden'>
          <img
            className='absolute bottom-0 left-0 w-full xl:-mb-24'
            src='aurora-assets/sign-up/eclipse-light-green.png'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <div>
              <div className='text-center'>
                <a className='inline-block mx-auto mb-8' href='#'>
                  <img
                    className='h-8'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
              </div>
              <div className='max-w-md p-8 mb-8 mx-auto bg-white border border-gray-200 rounded-2xl shadow-lg'>
                <h3 className='font-heading tracking-tight text-4xl font-bold mb-4'>
                  Sign up
                </h3>
                <p className='text-gray-500 mb-8'>
                  Create your account with your work email.
                </p>
                <form action=''>
                  <div className='mb-8'>
                    <label
                      className='block mb-2 text-sm font-medium'
                      htmlFor=''
                    >
                      Email
                    </label>
                    <input
                      className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg shadow-sm outline-none ring ring-transparent focus:ring-yellowGreen-500'
                      type='email'
                      placeholder='Enter your email address'
                    />
                  </div>
                  <div className='mb-8'>
                    <label
                      className='block mb-2 text-sm font-medium'
                      htmlFor=''
                    >
                      Password
                    </label>
                    <div className='relative'>
                      <input
                        className='relative py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg shadow-sm outline-none ring ring-transparent focus:ring-yellowGreen-500'
                        type='password'
                        placeholder='Enter password'
                      />
                      <button variant="classic" className='absolute top-1/2 right-0 mr-3 transform -translate-y-1/2 inline-block focus:outline-none text-gray-500 hover:text-yellowGreen-600'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M2.5 10.8334C5.5 4.16671 14.5 4.16671 17.5 10.8334'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M10 14.1666C8.61929 14.1666 7.5 13.0473 7.5 11.6666C7.5 10.2859 8.61929 9.16663 10 9.16663C11.3807 9.16663 12.5 10.2859 12.5 11.6666C12.5 13.0473 11.3807 14.1666 10 14.1666Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <button variant="classic"
                    className='group relative flex items-center justify-center px-5 h-12 w-full font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300 focus:outline-none'
                    type='submit'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Sign up</span>
                  </button>
                </form>
              </div>
              <div className='text-center'>
                <p className='text-sm'>
                  <span className='mr-1 text-gray-500'>
                    Already have an account?
                  </span>
                  <a
                    className='inline-block text-teal-500 hover:text-yellowGreen-600 font-semibold'
                    href='#'
                  >
                    Login
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='relative pt-8 pb-12 md:pb-24 overflow-hidden'>
          <img
            className='absolute top-0 left-0 md:ml-20'
            src='aurora-assets/sign-up/shadow-light-top.png'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <div>
              <a className='inline-block mx-auto mb-8' href='#'>
                <img
                  className='h-8'
                  src='aurora-assets/logos/aurora-logo.svg'
                  alt=''
                />
              </a>
              <div className='max-w-sm mx-auto'>
                <div className='text-center'>
                  <h3 className='font-heading tracking-tight text-4xl font-bold mb-4'>
                    Sign up
                  </h3>
                  <p className='text-gray-500 mb-8'>
                    Create your account with your work email.
                  </p>
                </div>
                <form action=''>
                  <div className='mb-8'>
                    <label
                      className='block mb-2 text-sm font-medium'
                      htmlFor=''
                    >
                      Email
                    </label>
                    <input
                      className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-white border border-gray-200 focus:border-yellowGreen-500 rounded-lg shadow-sm outline-none ring ring-transparent focus:ring-yellowGreen-500'
                      type='email'
                      placeholder='Enter your email address'
                    />
                  </div>
                  <div className='mb-8'>
                    <label
                      className='block mb-2 text-sm font-medium'
                      htmlFor=''
                    >
                      Password
                    </label>
                    <div className='relative'>
                      <input
                        className='relative py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-white border border-gray-200 focus:border-yellowGreen-500 rounded-lg shadow-sm outline-none ring ring-transparent focus:ring-yellowGreen-500'
                        type='password'
                        placeholder='Enter password'
                      />
                      <button variant="classic" className='absolute top-1/2 right-0 mr-3 transform -translate-y-1/2 inline-block focus:outline-none text-gray-500 hover:text-yellowGreen-600'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M2.5 10.8334C5.5 4.16671 14.5 4.16671 17.5 10.8334'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M10 14.1666C8.61929 14.1666 7.5 13.0473 7.5 11.6666C7.5 10.2859 8.61929 9.16663 10 9.16663C11.3807 9.16663 12.5 10.2859 12.5 11.6666C12.5 13.0473 11.3807 14.1666 10 14.1666Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <button variant="classic"
                    className='group relative flex items-center justify-center px-5 h-12 mb-8 w-full font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300 focus:outline-none'
                    type='submit'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Sign up</span>
                  </button>
                  <div className='my-4 flex items-center'>
                    <div className='h-px w-full bg-gray-200' />
                    <span className='inline-block mx-4 text-xs font-medium text-gray-500'>
                      OR
                    </span>
                    <div className='h-px w-full bg-gray-200' />
                  </div>
                  <a
                    className='inline-flex w-full h-12 mb-8 py-2 px-5 items-center justify-center text-base font-bold text-gray-700 hover:text-yellowGreen-700 border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                    href='#'
                  >
                    <svg
                      width={20}
                      height={20}
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M10 1.91663C12.0354 1.91663 13.89 2.67488 15.3114 3.91834L13.3034 5.92639C12.4018 5.19322 11.2539 4.74996 10 4.74996C7.10068 4.74996 4.75 7.10064 4.75 9.99996C4.75 12.8993 7.10068 15.25 10 15.25C12.286 15.25 14.2241 13.7867 14.9452 11.7501L15.0633 11.4166H14.7096H10.25V8.58329H17.25V8.61788H17.5H17.9634C18.0412 9.06748 18.0833 9.52903 18.0833 9.99996C18.0833 14.464 14.464 18.0833 10 18.0833C5.53599 18.0833 1.91667 14.464 1.91667 9.99996C1.91667 5.53595 5.53599 1.91663 10 1.91663Z'
                        fill='#FFC107'
                        stroke='#3B5444'
                        strokeWidth='0.5'
                      />
                      <path
                        d='M2.6275 6.12121L5.36542 8.12913C6.10625 6.29496 7.90042 4.99996 10 4.99996C11.2746 4.99996 12.4342 5.48079 13.3171 6.26621L15.6742 3.90913C14.1858 2.52204 12.195 1.66663 10 1.66663C6.79917 1.66663 4.02333 3.47371 2.6275 6.12121Z'
                        fill='#FF3D00'
                      />
                      <path
                        d='M10 18.3334C12.1525 18.3334 14.1083 17.5096 15.5871 16.17L13.0079 13.9875C12.1431 14.6452 11.0864 15.0009 10 15C7.8325 15 5.99208 13.618 5.29875 11.6892L2.58125 13.783C3.96042 16.4817 6.76125 18.3334 10 18.3334Z'
                        fill='#4CAF50'
                      />
                      <path
                        d='M18.1713 8.36796H17.5V8.33337H10V11.6667H14.7096C14.3809 12.5902 13.7889 13.3972 13.0067 13.988L13.0079 13.9871L15.5871 16.1696C15.4046 16.3355 18.3333 14.1667 18.3333 10C18.3333 9.44129 18.2758 8.89587 18.1713 8.36796Z'
                        fill='#1976D2'
                      />
                    </svg>
                    <span className='ml-2'>Continue with Google</span>
                  </a>
                  <p className='text-sm text-center'>
                    <span className='mr-1 text-gray-500'>
                      Already have an account?
                    </span>
                    <a
                      className='inline-block text-teal-500 hover:text-yellowGreen-600 font-semibold'
                      href='#'
                    >
                      Login
                    </a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className='bg-gray-900'>
          <div className='flex flex-wrap'>
            <div className='w-full lg:w-5/12 px-5'>
              <div className='py-12 md:pt-8 h-full'>
                <div className='flex flex-col h-full max-w-xs mx-auto'>
                  <a className='inline-block mb-24' href='#'>
                    <img
                      className='h-8'
                      src='aurora-assets/logos/aurora-logo-white.svg'
                      alt=''
                    />
                  </a>
                  <img
                    className='block w-64 mb-10'
                    src='aurora-assets/sign-up/circle-image-dark.png'
                    alt=''
                  />
                  <h3 className='font-heading tracking-tight text-4xl font-bold text-white mb-4'>
                    Sign up
                  </h3>
                  <p className='mb-24 text-gray-400'>
                    Create your account with your work email.
                  </p>
                  <a
                    className='inline-flex mt-auto items-center text-sm text-white hover:text-yellowGreen-600 font-semibold'
                    href='#'
                  >
                    <svg
                      width={20}
                      height={20}
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M12.5 5L7.5 10L12.5 15'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                    <span className='ml-2'>Back</span>
                  </a>
                </div>
              </div>
            </div>
            <div className='w-full lg:w-7/12 px-5'>
              <div className='py-12 md:pt-8 md:px-8'>
                <div className='max-w-sm lg:max-w-md xl:max-w-lg mx-auto lg:mr-0 xl:mx-auto'>
                  <div className='flex items-center justify-end mb-20'>
                    <a
                      className='inline-flex mr-10 items-center leading-loose text-white text-sm font-semibold hover:text-yellowGreen-600'
                      href='#'
                    >
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3478_15610)'>
                          <path
                            d='M10.0001 18.3333C14.6025 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6025 1.66663 10.0001 1.66663C5.39771 1.66663 1.66675 5.39759 1.66675 9.99996C1.66675 14.6023 5.39771 18.3333 10.0001 18.3333Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M2.08325 10.4166L6.66658 12.0833L5.83325 15L6.66658 17.5'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M14.1667 17.0833L13.7501 15L11.6667 14.1666V11.25L14.1667 10.4166L17.9167 10.8333'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M15.8333 4.58337L15.4167 5.83337L12.5 6.25004V8.75004L14.5833 7.91671H16.25L17.9167 8.75004'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M2.08325 8.75L4.16659 7.08333L6.24992 6.66667L7.91659 4.16667L7.08325 2.5'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                      <span className='ml-2'>English</span>
                    </a>
                    <a
                      className='group flex justify-center h-11 p-1 text-center leading-loose text-sm text-white font-semibold rounded-lg'
                      href='#'
                    >
                      <div className='inline-flex items-stretch w-full h-full p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                        <div className='flex w-full items-center justify-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                          <span>Login</span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className='max-w-sm'>
                    <form action=''>
                      <div className='mb-8'>
                        <label
                          className='block mb-2 text-sm font-medium text-white'
                          htmlFor=''
                        >
                          Name
                        </label>
                        <input
                          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                          type='text'
                          placeholder='Enter your name'
                        />
                      </div>
                      <div className='mb-8'>
                        <label
                          className='block mb-2 text-sm font-medium text-white'
                          htmlFor=''
                        >
                          Email
                        </label>
                        <input
                          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                          type='email'
                          placeholder='Enter your email address'
                        />
                      </div>
                      <div className='mb-8'>
                        <label
                          className='block mb-2 text-sm font-medium text-white'
                          htmlFor=''
                        >
                          What’s your role
                        </label>
                        <div className='relative bg-gray-50 bg-opacity-40 rounded-lg'>
                          <select
                            className='relative z-10 py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-transparent bg-gray-800 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg appearance-none'
                            name=''
                            id=''
                          >
                            <option value={1}>Select role</option>
                            <option value={1}>Marketing</option>
                            <option value={1}>Sales</option>
                          </select>
                          <span className='absolute top-1/2 right-0 mr-3 transform -translate-y-1/2'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M5 7.5L10 12.5L15 7.5'
                                stroke='#6C7F73'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <div className='mb-8'>
                        <label
                          className='block mb-2 text-sm font-medium text-white'
                          htmlFor=''
                        >
                          Password
                        </label>
                        <div className='relative'>
                          <input
                            className='relative py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                            type='password'
                            placeholder='Enter password'
                          />
                          <button variant="classic" className='absolute top-1/2 right-0 mr-3 transform -translate-y-1/2 inline-block focus:outline-none text-gray-500 hover:text-yellowGreen-600'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M2.5 10.8334C5.5 4.16671 14.5 4.16671 17.5 10.8334'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M10 14.1666C8.61929 14.1666 7.5 13.0473 7.5 11.6666C7.5 10.2859 8.61929 9.16663 10 9.16663C11.3807 9.16663 12.5 10.2859 12.5 11.6666C12.5 13.0473 11.3807 14.1666 10 14.1666Z'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <button variant="classic"
                        className='xs:flex-shrink-0 group relative w-full h-12 flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
                        type='submit'
                      >
                        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                        <span>Sign up</span>
                      </button>
                      <div className='my-4 flex items-center'>
                        <div className='h-px w-full bg-white bg-opacity-20' />
                        <span className='inline-block mx-4 text-xs font-medium text-gray-400'>
                          OR
                        </span>
                        <div className='h-px w-full bg-white bg-opacity-20' />
                      </div>
                      <a
                        className='inline-flex w-full h-12 mb-18 py-2 px-5 items-center justify-center text-base font-bold text-gray-700 hover:text-yellowGreen-700 bg-white rounded-lg transition duration-200'
                        href='#'
                      >
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M10 1.91663C12.0354 1.91663 13.89 2.67488 15.3114 3.91834L13.3034 5.92639C12.4018 5.19322 11.2539 4.74996 10 4.74996C7.10068 4.74996 4.75 7.10064 4.75 9.99996C4.75 12.8993 7.10068 15.25 10 15.25C12.286 15.25 14.2241 13.7867 14.9452 11.7501L15.0633 11.4166H14.7096H10.25V8.58329H17.25V8.61788H17.5H17.9634C18.0412 9.06748 18.0833 9.52903 18.0833 9.99996C18.0833 14.464 14.464 18.0833 10 18.0833C5.53599 18.0833 1.91667 14.464 1.91667 9.99996C1.91667 5.53595 5.53599 1.91663 10 1.91663Z'
                            fill='#FFC107'
                            stroke='#3B5444'
                            strokeWidth='0.5'
                          />
                          <path
                            d='M2.6275 6.12121L5.36542 8.12913C6.10625 6.29496 7.90042 4.99996 10 4.99996C11.2746 4.99996 12.4342 5.48079 13.3171 6.26621L15.6742 3.90913C14.1858 2.52204 12.195 1.66663 10 1.66663C6.79917 1.66663 4.02333 3.47371 2.6275 6.12121Z'
                            fill='#FF3D00'
                          />
                          <path
                            d='M10 18.3334C12.1525 18.3334 14.1083 17.5096 15.5871 16.17L13.0079 13.9875C12.1431 14.6452 11.0864 15.0009 10 15C7.8325 15 5.99208 13.618 5.29875 11.6892L2.58125 13.783C3.96042 16.4817 6.76125 18.3334 10 18.3334Z'
                            fill='#4CAF50'
                          />
                          <path
                            d='M18.1713 8.36796H17.5V8.33337H10V11.6667H14.7096C14.3809 12.5902 13.7889 13.3972 13.0067 13.988L13.0079 13.9871L15.5871 16.1696C15.4046 16.3355 18.3333 14.1667 18.3333 10C18.3333 9.44129 18.2758 8.89587 18.1713 8.36796Z'
                            fill='#1976D2'
                          />
                        </svg>
                        <span className='ml-2'>Continue with Google</span>
                      </a>
                      <p className='text-sm'>
                        <span className='mr-1 text-gray-500'>
                          By signing up, you agree to our
                        </span>
                        <a
                          className='inline-block text-yellowGreen-600 hover:text-yellowGreen-500 font-semibold'
                          href='#'
                        >
                          Terms and Privacy Policy
                        </a>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative pt-8 pb-12 md:pb-24 bg-gray-50 overflow-hidden'>
          <img
            className='absolute top-0 left-0 md:ml-20'
            src='aurora-assets/sign-up/shadow-light-top.png'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <div>
              <a className='inline-block mx-auto mb-8' href='#'>
                <img
                  className='h-8'
                  src='aurora-assets/logos/aurora-logo.svg'
                  alt=''
                />
              </a>
              <div className='max-w-sm mx-auto'>
                <div className='text-center'>
                  <h3 className='font-heading tracking-tight text-4xl font-bold mb-4'>
                    Sign up
                  </h3>
                  <p className='text-gray-500 mb-8'>
                    Create your account with your work email.
                  </p>
                </div>
                <form action=''>
                  <div className='mb-8'>
                    <label
                      className='block mb-2 text-sm font-medium'
                      htmlFor=''
                    >
                      Email
                    </label>
                    <input
                      className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-white border border-gray-200 focus:border-yellowGreen-500 rounded-lg shadow-sm outline-none ring ring-transparent focus:ring-yellowGreen-500'
                      type='email'
                      placeholder='Enter your email address'
                    />
                  </div>
                  <div className='mb-8'>
                    <label
                      className='block mb-2 text-sm font-medium'
                      htmlFor=''
                    >
                      Password
                    </label>
                    <div className='relative'>
                      <input
                        className='relative py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-white border border-gray-200 focus:border-yellowGreen-500 rounded-lg shadow-sm outline-none ring ring-transparent focus:ring-yellowGreen-500'
                        type='password'
                        placeholder='Enter password'
                      />
                      <button variant="classic" className='absolute top-1/2 right-0 mr-3 transform -translate-y-1/2 inline-block focus:outline-none text-gray-500 hover:text-yellowGreen-600'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M2.5 10.8334C5.5 4.16671 14.5 4.16671 17.5 10.8334'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M10 14.1666C8.61929 14.1666 7.5 13.0473 7.5 11.6666C7.5 10.2859 8.61929 9.16663 10 9.16663C11.3807 9.16663 12.5 10.2859 12.5 11.6666C12.5 13.0473 11.3807 14.1666 10 14.1666Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <button variant="classic"
                    className='group relative flex items-center justify-center px-5 h-12 w-full font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300 focus:outline-none'
                    type='submit'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Sign up</span>
                  </button>
                  <div className='my-4 flex items-center'>
                    <div className='h-px w-full bg-gray-200' />
                    <span className='inline-block mx-4 text-xs font-medium text-gray-500'>
                      OR
                    </span>
                    <div className='h-px w-full bg-gray-200' />
                  </div>
                  <a
                    className='inline-flex w-full h-12 mb-8 py-2 px-5 items-center justify-center text-base font-bold text-gray-700 hover:text-yellowGreen-700 bg-white border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                    href='#'
                  >
                    <svg
                      width={20}
                      height={20}
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M10 1.91663C12.0354 1.91663 13.89 2.67488 15.3114 3.91834L13.3034 5.92639C12.4018 5.19322 11.2539 4.74996 10 4.74996C7.10068 4.74996 4.75 7.10064 4.75 9.99996C4.75 12.8993 7.10068 15.25 10 15.25C12.286 15.25 14.2241 13.7867 14.9452 11.7501L15.0633 11.4166H14.7096H10.25V8.58329H17.25V8.61788H17.5H17.9634C18.0412 9.06748 18.0833 9.52903 18.0833 9.99996C18.0833 14.464 14.464 18.0833 10 18.0833C5.53599 18.0833 1.91667 14.464 1.91667 9.99996C1.91667 5.53595 5.53599 1.91663 10 1.91663Z'
                        fill='#FFC107'
                        stroke='#3B5444'
                        strokeWidth='0.5'
                      />
                      <path
                        d='M2.6275 6.12121L5.36542 8.12913C6.10625 6.29496 7.90042 4.99996 10 4.99996C11.2746 4.99996 12.4342 5.48079 13.3171 6.26621L15.6742 3.90913C14.1858 2.52204 12.195 1.66663 10 1.66663C6.79917 1.66663 4.02333 3.47371 2.6275 6.12121Z'
                        fill='#FF3D00'
                      />
                      <path
                        d='M10 18.3334C12.1525 18.3334 14.1083 17.5096 15.5871 16.17L13.0079 13.9875C12.1431 14.6452 11.0864 15.0009 10 15C7.8325 15 5.99208 13.618 5.29875 11.6892L2.58125 13.783C3.96042 16.4817 6.76125 18.3334 10 18.3334Z'
                        fill='#4CAF50'
                      />
                      <path
                        d='M18.1713 8.36796H17.5V8.33337H10V11.6667H14.7096C14.3809 12.5902 13.7889 13.3972 13.0067 13.988L13.0079 13.9871L15.5871 16.1696C15.4046 16.3355 18.3333 14.1667 18.3333 10C18.3333 9.44129 18.2758 8.89587 18.1713 8.36796Z'
                        fill='#1976D2'
                      />
                    </svg>
                    <span className='ml-2'>Continue with Google</span>
                  </a>
                  <p className='text-sm text-center'>
                    <span className='mr-1 text-gray-500'>
                      Already have an account?
                    </span>
                    <a
                      className='inline-block text-teal-500 hover:text-yellowGreen-600 font-semibold'
                      href='#'
                    >
                      Login
                    </a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className='bg-gray-900'>
          <div className='flex flex-wrap'>
            <div className='w-full lg:w-1/2 px-5'>
              <div className='pt-12 pb-12 md:pt-24 lg:pt-32 md:pb-24 xl:pb-56 md:px-8'>
                <div className='max-w-sm mx-auto'>
                  <h3 className='font-heading tracking-tight text-4xl font-bold text-white mb-4'>
                    Sign up
                  </h3>
                  <p className='text-gray-400 mb-8'>
                    Create your account with your work email.
                  </p>
                  <form action=''>
                    <a
                      className='inline-flex w-full h-12 py-2 px-5 items-center justify-center text-base font-bold text-gray-700 hover:text-yellowGreen-700 bg-white border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                      href='#'
                    >
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M10 1.91663C12.0354 1.91663 13.89 2.67488 15.3114 3.91834L13.3034 5.92639C12.4018 5.19322 11.2539 4.74996 10 4.74996C7.10068 4.74996 4.75 7.10064 4.75 9.99996C4.75 12.8993 7.10068 15.25 10 15.25C12.286 15.25 14.2241 13.7867 14.9452 11.7501L15.0633 11.4166H14.7096H10.25V8.58329H17.25V8.61788H17.5H17.9634C18.0412 9.06748 18.0833 9.52903 18.0833 9.99996C18.0833 14.464 14.464 18.0833 10 18.0833C5.53599 18.0833 1.91667 14.464 1.91667 9.99996C1.91667 5.53595 5.53599 1.91663 10 1.91663Z'
                          fill='#FFC107'
                          stroke='#3B5444'
                          strokeWidth='0.5'
                        />
                        <path
                          d='M2.6275 6.12121L5.36542 8.12913C6.10625 6.29496 7.90042 4.99996 10 4.99996C11.2746 4.99996 12.4342 5.48079 13.3171 6.26621L15.6742 3.90913C14.1858 2.52204 12.195 1.66663 10 1.66663C6.79917 1.66663 4.02333 3.47371 2.6275 6.12121Z'
                          fill='#FF3D00'
                        />
                        <path
                          d='M10 18.3334C12.1525 18.3334 14.1083 17.5096 15.5871 16.17L13.0079 13.9875C12.1431 14.6452 11.0864 15.0009 10 15C7.8325 15 5.99208 13.618 5.29875 11.6892L2.58125 13.783C3.96042 16.4817 6.76125 18.3334 10 18.3334Z'
                          fill='#4CAF50'
                        />
                        <path
                          d='M18.1713 8.36796H17.5V8.33337H10V11.6667H14.7096C14.3809 12.5902 13.7889 13.3972 13.0067 13.988L13.0079 13.9871L15.5871 16.1696C15.4046 16.3355 18.3333 14.1667 18.3333 10C18.3333 9.44129 18.2758 8.89587 18.1713 8.36796Z'
                          fill='#1976D2'
                        />
                      </svg>
                      <span className='ml-2'>Continue with Google</span>
                    </a>
                    <div className='my-8 flex items-center'>
                      <div className='h-px w-full bg-gray-700' />
                      <span className='inline-block mx-4 text-xs font-medium text-gray-500'>
                        OR
                      </span>
                      <div className='h-px w-full bg-gray-700' />
                    </div>
                    <div className='mb-8'>
                      <label
                        className='block mb-2 text-sm font-medium text-white'
                        htmlFor=''
                      >
                        Email
                      </label>
                      <input
                        className='py-2 px-4 h-12 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                        type='email'
                        placeholder='Enter your email address'
                      />
                    </div>
                    <button variant="classic"
                      className='xs:flex-shrink-0 group relative w-full h-12 mb-8 flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
                      type='submit'
                    >
                      <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                      <span>Sign up</span>
                    </button>
                    <p className='text-sm'>
                      <span className='mr-1 text-gray-500'>
                        By signing up, you agree to our
                      </span>
                      <a
                        className='inline-block text-yellowGreen-600 hover:text-yellowGreen-500 font-semibold'
                        href='#'
                      >
                        Terms and Privacy Policy
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <div className='w-full lg:w-1/2'>
              <div className='relative flex flex-col max-w-md mx-auto lg:max-w-none h-full pt-8 pb-12 px-10 overflow-hidden'>
                <div className='relative z-10 flex items-center justify-end'>
                  <a
                    className='inline-flex mr-10 items-center leading-loose text-white text-sm font-semibold hover:text-yellowGreen-600'
                    href='#'
                  >
                    <svg
                      width={20}
                      height={20}
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clipPath='url(#clip0_3478_15610)'>
                        <path
                          d='M10.0001 18.3333C14.6025 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6025 1.66663 10.0001 1.66663C5.39771 1.66663 1.66675 5.39759 1.66675 9.99996C1.66675 14.6023 5.39771 18.3333 10.0001 18.3333Z'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M2.08325 10.4166L6.66658 12.0833L5.83325 15L6.66658 17.5'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M14.1667 17.0833L13.7501 15L11.6667 14.1666V11.25L14.1667 10.4166L17.9167 10.8333'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M15.8333 4.58337L15.4167 5.83337L12.5 6.25004V8.75004L14.5833 7.91671H16.25L17.9167 8.75004'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M2.08325 8.75L4.16659 7.08333L6.24992 6.66667L7.91659 4.16667L7.08325 2.5'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </g>
                    </svg>
                    <span className='ml-2'>English</span>
                  </a>
                  <a
                    className='group flex justify-center h-12 p-1 text-center leading-loose text-sm text-white font-semibold rounded-lg'
                    href='#'
                  >
                    <div className='inline-flex items-stretch w-full h-full p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                      <div className='flex w-full items-center justify-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                        <span>Login</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div className='relative z-10 my-auto py-24'>
                  <img
                    className='block mx-auto'
                    src='aurora-assets/sign-up/logo-center-green.png'
                    alt=''
                  />
                </div>
                <div className='relative z-10'>
                  <h5 className='text-gray-400 text-sm mb-6'>
                    Trusted by the best
                  </h5>
                  <div className='flex flex-wrap -mx-4 -mb-10'>
                    <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                      <img
                        className='block h-7'
                        src='aurora-assets/logo-clouds/logo-white-8.svg'
                        alt=''
                      />
                    </div>
                    <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                      <img
                        className='block h-7'
                        src='aurora-assets/logo-clouds/logo-white-7.svg'
                        alt=''
                      />
                    </div>
                    <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                      <img
                        className='block h-7'
                        src='aurora-assets/logo-clouds/logo-white-6.svg'
                        alt=''
                      />
                    </div>
                    <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                      <img
                        className='block h-7'
                        src='aurora-assets/logo-clouds/logo-white-2.svg'
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <img
                  className='absolute top-0 left-0 w-full xl:-mt-52'
                  src='aurora-assets/sign-up/pattern-line-dark1.png'
                  alt=''
                />
                <img
                  className='absolute bottom-0 left-0 w-full'
                  src='aurora-assets/sign-up/pattern-line-dark2.png'
                  alt=''
                />
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-gray-50 overflow-hidden'>
          <img
            className='absolute bottom-0 left-0 w-full xl:-mb-24'
            src='aurora-assets/sign-up/half-circle-white.svg'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <div>
              <div className='text-center'>
                <a className='inline-block mx-auto mb-8' href='#'>
                  <img
                    className='h-8'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
              </div>
              <div className='max-w-md p-8 mb-8 mx-auto bg-white border border-gray-200 rounded-2xl shadow-lg'>
                <h3 className='font-heading tracking-tight text-4xl font-bold mb-4'>
                  Sign up
                </h3>
                <p className='text-gray-500 mb-8'>
                  Create your account with your work email.
                </p>
                <form action=''>
                  <div className='mb-8'>
                    <label
                      className='block mb-2 text-sm font-medium'
                      htmlFor=''
                    >
                      Email
                    </label>
                    <input
                      className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg shadow-small outline-none ring ring-transparent focus:ring-yellowGreen-500'
                      type='email'
                      placeholder='Enter your email address'
                    />
                  </div>
                  <div className='mb-8'>
                    <label
                      className='block mb-2 text-sm font-medium'
                      htmlFor=''
                    >
                      Password
                    </label>
                    <div className='relative'>
                      <input
                        className='relative py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg shadow-small outline-none ring ring-transparent focus:ring-yellowGreen-500'
                        type='password'
                        placeholder='Enter password'
                      />
                      <button variant="classic" className='absolute top-1/2 right-0 mr-3 transform -translate-y-1/2 inline-block focus:outline-none text-gray-500 hover:text-yellowGreen-600'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M2.5 10.8334C5.5 4.16671 14.5 4.16671 17.5 10.8334'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M10 14.1666C8.61929 14.1666 7.5 13.0473 7.5 11.6666C7.5 10.2859 8.61929 9.16663 10 9.16663C11.3807 9.16663 12.5 10.2859 12.5 11.6666C12.5 13.0473 11.3807 14.1666 10 14.1666Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <button variant="classic"
                    className='group relative flex items-center justify-center px-5 h-12 mb-8 w-full font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300 focus:outline-none'
                    type='submit'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Sign up</span>
                  </button>
                  <p className='text-sm'>
                    <span className='mr-1 text-gray-500'>
                      By signing up, you agree to our
                    </span>
                    <a
                      className='inline-block text-teal-500 hover:text-yellowGreen-600 font-semibold'
                      href='#'
                    >
                      Terms and Privacy Policy
                    </a>
                  </p>
                </form>
              </div>
              <div className='text-center'>
                <p className='text-sm'>
                  <span className='mr-1 text-gray-500'>
                    Already have an account?
                  </span>
                  <a
                    className='inline-block text-teal-500 hover:text-yellowGreen-600 font-semibold'
                    href='#'
                  >
                    Login
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='flex flex-wrap'>
            <div className='w-full lg:w-1/2 px-5 bg-gray-900'>
              <div className='pt-8 pb-12 lg:pb-32 md:px-8'>
                <div className='flex items-center justify-end mb-20'>
                  <span className='mr-2 text-sm text-white'>
                    Already have an account?
                  </span>
                  <a
                    className='group flex justify-center h-11 p-1 text-center text-sm text-white font-semibold rounded-lg'
                    href='#'
                  >
                    <div className='inline-flex items-stretch w-full h-full p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                      <div className='flex w-full items-center justify-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                        <span>Login</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div className='max-w-sm mx-auto'>
                  <h3 className='font-heading tracking-tight text-4xl font-bold text-white mb-4'>
                    Sign up
                  </h3>
                  <p className='text-gray-400 mb-8'>
                    Create your account with your work email.
                  </p>
                  <form action=''>
                    <a
                      className='inline-flex w-full h-12 py-2 px-5 items-center justify-center text-base font-bold text-gray-700 hover:text-yellowGreen-700 bg-white rounded-lg transition duration-200'
                      href='#'
                    >
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M10 1.91663C12.0354 1.91663 13.89 2.67488 15.3114 3.91834L13.3034 5.92639C12.4018 5.19322 11.2539 4.74996 10 4.74996C7.10068 4.74996 4.75 7.10064 4.75 9.99996C4.75 12.8993 7.10068 15.25 10 15.25C12.286 15.25 14.2241 13.7867 14.9452 11.7501L15.0633 11.4166H14.7096H10.25V8.58329H17.25V8.61788H17.5H17.9634C18.0412 9.06748 18.0833 9.52903 18.0833 9.99996C18.0833 14.464 14.464 18.0833 10 18.0833C5.53599 18.0833 1.91667 14.464 1.91667 9.99996C1.91667 5.53595 5.53599 1.91663 10 1.91663Z'
                          fill='#FFC107'
                          stroke='#3B5444'
                          strokeWidth='0.5'
                        />
                        <path
                          d='M2.6275 6.12121L5.36542 8.12913C6.10625 6.29496 7.90042 4.99996 10 4.99996C11.2746 4.99996 12.4342 5.48079 13.3171 6.26621L15.6742 3.90913C14.1858 2.52204 12.195 1.66663 10 1.66663C6.79917 1.66663 4.02333 3.47371 2.6275 6.12121Z'
                          fill='#FF3D00'
                        />
                        <path
                          d='M10 18.3334C12.1525 18.3334 14.1083 17.5096 15.5871 16.17L13.0079 13.9875C12.1431 14.6452 11.0864 15.0009 10 15C7.8325 15 5.99208 13.618 5.29875 11.6892L2.58125 13.783C3.96042 16.4817 6.76125 18.3334 10 18.3334Z'
                          fill='#4CAF50'
                        />
                        <path
                          d='M18.1713 8.36796H17.5V8.33337H10V11.6667H14.7096C14.3809 12.5902 13.7889 13.3972 13.0067 13.988L13.0079 13.9871L15.5871 16.1696C15.4046 16.3355 18.3333 14.1667 18.3333 10C18.3333 9.44129 18.2758 8.89587 18.1713 8.36796Z'
                          fill='#1976D2'
                        />
                      </svg>
                      <span className='ml-2'>Continue with Google</span>
                    </a>
                    <div className='my-8 flex items-center'>
                      <div className='h-px w-full bg-gray-700' />
                      <span className='inline-block mx-4 text-xs font-medium text-gray-500'>
                        OR
                      </span>
                      <div className='h-px w-full bg-gray-700' />
                    </div>
                    <div className='mb-8'>
                      <label
                        className='block mb-2 text-sm font-medium text-white'
                        htmlFor=''
                      >
                        Email
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                        type='email'
                        placeholder='Enter your email address'
                      />
                    </div>
                    <button variant="classic"
                      className='xs:flex-shrink-0 group relative w-full h-12 mb-8 flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
                      type='submit'
                    >
                      <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                      <span>Sign up</span>
                    </button>
                    <p className='text-sm'>
                      <span className='mr-1 text-gray-500'>
                        By signing up, you agree to our
                      </span>
                      <a
                        className='inline-block text-yellowGreen-600 hover:text-yellowGreen-500 font-semibold'
                        href='#'
                      >
                        Terms and Privacy Policy
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <div className='w-full lg:w-1/2 px-5 bg-gray-800'>
              <div className='flex h-full py-12 items-center justify-center'>
                <div className='max-w-md sm:max-w-lg px-2'>
                  <div className='py-12 xs:px-10 border-t-2 border-b-2 border-gray-700'>
                    <span className='block mb-5'>
                      <svg
                        width={18}
                        height={12}
                        viewBox='0 0 18 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M9.072 0.351998L6.192 11.2H0.528L4.848 0.351998H9.072ZM17.568 0.351998L14.736 11.2H9.072L13.392 0.351998H17.568Z'
                          fill='white'
                        />
                      </svg>
                    </span>
                    <p className='text-2xl font-medium text-white mb-10'>
                      I've tried numerous assets manager platforms, but this one
                      truly stands out!
                    </p>
                    <div className='flex items-center justify-between'>
                      <div>
                        <span className='block mb-1 text-sm font-semibold text-white'>
                          Cooper Levin
                        </span>
                        <span className='block text-xs text-gray-400'>
                          Founder of AIME Inc.
                        </span>
                      </div>
                      <img
                        src='aurora-assets/logos/logo-aurora-white.svg'
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='bg-gray-900'>
          <div className='flex flex-wrap'>
            <div className='w-full lg:w-5/12 px-5'>
              <div className='py-12 md:pt-8 h-full'>
                <div className='flex flex-col h-full max-w-xs mx-auto'>
                  <a className='inline-block mb-24' href='#'>
                    <img
                      className='h-8'
                      src='aurora-assets/logos/aurora-logo-white.svg'
                      alt=''
                    />
                  </a>
                  <img
                    className='block w-64 mb-10'
                    src='aurora-assets/sign-up/circle-image-dark.png'
                    alt=''
                  />
                  <h3 className='font-heading tracking-tight text-4xl font-bold text-white mb-4'>
                    Sign up
                  </h3>
                  <p className='mb-24 text-gray-400'>
                    Create your account with your work email.
                  </p>
                  <a
                    className='inline-flex mt-auto items-center text-sm text-white hover:text-yellowGreen-600 font-semibold'
                    href='#'
                  >
                    <svg
                      width={20}
                      height={20}
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M12.5 5L7.5 10L12.5 15'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                    <span className='ml-2'>Back</span>
                  </a>
                </div>
              </div>
            </div>
            <div className='w-full lg:w-7/12 px-5'>
              <div className='py-12 md:pt-8 md:px-8'>
                <div className='max-w-sm lg:max-w-md xl:max-w-lg mx-auto lg:mr-0 xl:mx-auto'>
                  <div className='flex items-center justify-end mb-20'>
                    <a
                      className='inline-flex mr-10 items-center leading-loose text-white text-sm font-semibold hover:text-yellowGreen-600'
                      href='#'
                    >
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3478_15610)'>
                          <path
                            d='M10.0001 18.3333C14.6025 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6025 1.66663 10.0001 1.66663C5.39771 1.66663 1.66675 5.39759 1.66675 9.99996C1.66675 14.6023 5.39771 18.3333 10.0001 18.3333Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M2.08325 10.4166L6.66658 12.0833L5.83325 15L6.66658 17.5'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M14.1667 17.0833L13.7501 15L11.6667 14.1666V11.25L14.1667 10.4166L17.9167 10.8333'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M15.8333 4.58337L15.4167 5.83337L12.5 6.25004V8.75004L14.5833 7.91671H16.25L17.9167 8.75004'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M2.08325 8.75L4.16659 7.08333L6.24992 6.66667L7.91659 4.16667L7.08325 2.5'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                      <span className='ml-2'>English</span>
                    </a>
                    <a
                      className='group flex justify-center h-11 p-1 text-center leading-loose text-sm text-white font-semibold rounded-lg'
                      href='#'
                    >
                      <div className='inline-flex items-stretch w-full h-full p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                        <div className='flex w-full items-center justify-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                          <span>Login</span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className='max-w-sm'>
                    <form action=''>
                      <div className='mb-8'>
                        <label
                          className='block mb-2 text-sm font-medium text-white'
                          htmlFor=''
                        >
                          Name
                        </label>
                        <input
                          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                          type='text'
                          placeholder='Enter your name'
                        />
                      </div>
                      <div className='mb-8'>
                        <label
                          className='block mb-2 text-sm font-medium text-white'
                          htmlFor=''
                        >
                          Email
                        </label>
                        <input
                          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                          type='email'
                          placeholder='Enter your email address'
                        />
                      </div>
                      <div className='mb-8'>
                        <label
                          className='block mb-2 text-sm font-medium text-white'
                          htmlFor=''
                        >
                          What’s your role
                        </label>
                        <div className='relative bg-gray-50 bg-opacity-40 rounded-lg'>
                          <select
                            className='relative z-10 py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-transparent bg-gray-800 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg appearance-none'
                            name=''
                            id=''
                          >
                            <option value={1}>Select role</option>
                            <option value={1}>Marketing</option>
                            <option value={1}>Sales</option>
                          </select>
                          <span className='absolute top-1/2 right-0 mr-3 transform -translate-y-1/2'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M5 7.5L10 12.5L15 7.5'
                                stroke='#6C7F73'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <div className='mb-8'>
                        <label
                          className='block mb-2 text-sm font-medium text-white'
                          htmlFor=''
                        >
                          Password
                        </label>
                        <div className='relative'>
                          <input
                            className='relative py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                            type='password'
                            placeholder='Enter password'
                          />
                          <button variant="classic" className='absolute top-1/2 right-0 mr-3 transform -translate-y-1/2 inline-block focus:outline-none text-gray-500 hover:text-yellowGreen-600'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M2.5 10.8334C5.5 4.16671 14.5 4.16671 17.5 10.8334'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M10 14.1666C8.61929 14.1666 7.5 13.0473 7.5 11.6666C7.5 10.2859 8.61929 9.16663 10 9.16663C11.3807 9.16663 12.5 10.2859 12.5 11.6666C12.5 13.0473 11.3807 14.1666 10 14.1666Z'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <button variant="classic"
                        className='xs:flex-shrink-0 group relative w-full h-12 flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
                        type='submit'
                      >
                        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                        <span>Sign up</span>
                      </button>
                      <div className='my-4 flex items-center'>
                        <div className='h-px w-full bg-white bg-opacity-20' />
                        <span className='inline-block mx-4 text-xs font-medium text-gray-400'>
                          OR
                        </span>
                        <div className='h-px w-full bg-white bg-opacity-20' />
                      </div>
                      <a
                        className='inline-flex w-full h-12 mb-18 py-2 px-5 items-center justify-center text-base font-bold text-gray-700 hover:text-yellowGreen-700 bg-white rounded-lg transition duration-200'
                        href='#'
                      >
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M10 1.91663C12.0354 1.91663 13.89 2.67488 15.3114 3.91834L13.3034 5.92639C12.4018 5.19322 11.2539 4.74996 10 4.74996C7.10068 4.74996 4.75 7.10064 4.75 9.99996C4.75 12.8993 7.10068 15.25 10 15.25C12.286 15.25 14.2241 13.7867 14.9452 11.7501L15.0633 11.4166H14.7096H10.25V8.58329H17.25V8.61788H17.5H17.9634C18.0412 9.06748 18.0833 9.52903 18.0833 9.99996C18.0833 14.464 14.464 18.0833 10 18.0833C5.53599 18.0833 1.91667 14.464 1.91667 9.99996C1.91667 5.53595 5.53599 1.91663 10 1.91663Z'
                            fill='#FFC107'
                            stroke='#3B5444'
                            strokeWidth='0.5'
                          />
                          <path
                            d='M2.6275 6.12121L5.36542 8.12913C6.10625 6.29496 7.90042 4.99996 10 4.99996C11.2746 4.99996 12.4342 5.48079 13.3171 6.26621L15.6742 3.90913C14.1858 2.52204 12.195 1.66663 10 1.66663C6.79917 1.66663 4.02333 3.47371 2.6275 6.12121Z'
                            fill='#FF3D00'
                          />
                          <path
                            d='M10 18.3334C12.1525 18.3334 14.1083 17.5096 15.5871 16.17L13.0079 13.9875C12.1431 14.6452 11.0864 15.0009 10 15C7.8325 15 5.99208 13.618 5.29875 11.6892L2.58125 13.783C3.96042 16.4817 6.76125 18.3334 10 18.3334Z'
                            fill='#4CAF50'
                          />
                          <path
                            d='M18.1713 8.36796H17.5V8.33337H10V11.6667H14.7096C14.3809 12.5902 13.7889 13.3972 13.0067 13.988L13.0079 13.9871L15.5871 16.1696C15.4046 16.3355 18.3333 14.1667 18.3333 10C18.3333 9.44129 18.2758 8.89587 18.1713 8.36796Z'
                            fill='#1976D2'
                          />
                        </svg>
                        <span className='ml-2'>Continue with Google</span>
                      </a>
                      <p className='text-sm'>
                        <span className='mr-1 text-gray-500'>
                          By signing up, you agree to our
                        </span>
                        <a
                          className='inline-block text-yellowGreen-600 hover:text-yellowGreen-500 font-semibold'
                          href='#'
                        >
                          Terms and Privacy Policy
                        </a>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative pt-8 pb-12 md:pb-24 bg-radial-dark-green overflow-hidden'>
          <img
            className='absolute top-0 left-0 md:ml-20'
            src='aurora-assets/sign-up/shadow-top-green-light.png'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <div>
              <a className='inline-block mx-auto mb-8' href='#'>
                <img
                  className='h-8'
                  src='aurora-assets/logos/aurora-logo-white.svg'
                  alt=''
                />
              </a>
              <div className='max-w-sm mx-auto'>
                <div className='text-center'>
                  <h3 className='font-heading tracking-tight text-4xl font-bold text-white mb-4'>
                    Sign up
                  </h3>
                  <p className='text-gray-200 mb-8'>
                    Create your account with your work email.
                  </p>
                </div>
                <form action=''>
                  <div className='mb-8'>
                    <label
                      className='block mb-2 text-sm font-medium text-white'
                      htmlFor=''
                    >
                      Name
                    </label>
                    <input
                      className='py-2 px-4 h-11 w-full text-gray-400 placeholder-gray-400 bg-white bg-opacity-10 border border-white border-opacity-20 focus:border-yellowGreen-800 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-800'
                      type='text'
                      placeholder='Enter your name'
                    />
                  </div>
                  <div className='mb-8'>
                    <label
                      className='block mb-2 text-sm font-medium text-white'
                      htmlFor=''
                    >
                      Password
                    </label>
                    <div className='relative'>
                      <input
                        className='relative py-2 px-4 h-11 w-full text-gray-400 placeholder-gray-400 bg-white bg-opacity-10 border border-white border-opacity-20 focus:border-yellowGreen-800 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-800'
                        type='password'
                        placeholder='Enter password'
                      />
                      <button variant="classic" className='absolute top-1/2 right-0 mr-3 transform -translate-y-1/2 inline-block focus:outline-none text-gray-500 hover:text-yellowGreen-600'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M2.5 10.8334C5.5 4.16671 14.5 4.16671 17.5 10.8334'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M10 14.1666C8.61929 14.1666 7.5 13.0473 7.5 11.6666C7.5 10.2859 8.61929 9.16663 10 9.16663C11.3807 9.16663 12.5 10.2859 12.5 11.6666C12.5 13.0473 11.3807 14.1666 10 14.1666Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <button variant="classic"
                    className='xs:flex-shrink-0 group relative flex w-full h-12 items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
                    type='submit'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Sign up</span>
                  </button>
                  <div className='my-4 flex items-center'>
                    <div className='h-px w-full bg-white bg-opacity-20' />
                    <span className='inline-block mx-4 text-xs font-medium text-gray-500'>
                      OR
                    </span>
                    <div className='h-px w-full bg-white bg-opacity-20' />
                  </div>
                  <button variant="classic"
                    className='inline-flex w-full h-12 mb-8 py-2 px-5 items-center justify-center text-base font-bold text-gray-700 hover:text-yellowGreen-700 bg-white rounded-lg transition duration-200'
                    type='submit'
                  >
                    <svg
                      width={20}
                      height={20}
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M10 1.91663C12.0354 1.91663 13.89 2.67488 15.3114 3.91834L13.3034 5.92639C12.4018 5.19322 11.2539 4.74996 10 4.74996C7.10068 4.74996 4.75 7.10064 4.75 9.99996C4.75 12.8993 7.10068 15.25 10 15.25C12.286 15.25 14.2241 13.7867 14.9452 11.7501L15.0633 11.4166H14.7096H10.25V8.58329H17.25V8.61788H17.5H17.9634C18.0412 9.06748 18.0833 9.52903 18.0833 9.99996C18.0833 14.464 14.464 18.0833 10 18.0833C5.53599 18.0833 1.91667 14.464 1.91667 9.99996C1.91667 5.53595 5.53599 1.91663 10 1.91663Z'
                        fill='#FFC107'
                        stroke='#3B5444'
                        strokeWidth='0.5'
                      />
                      <path
                        d='M2.6275 6.12121L5.36542 8.12913C6.10625 6.29496 7.90042 4.99996 10 4.99996C11.2746 4.99996 12.4342 5.48079 13.3171 6.26621L15.6742 3.90913C14.1858 2.52204 12.195 1.66663 10 1.66663C6.79917 1.66663 4.02333 3.47371 2.6275 6.12121Z'
                        fill='#FF3D00'
                      />
                      <path
                        d='M10 18.3334C12.1525 18.3334 14.1083 17.5096 15.5871 16.17L13.0079 13.9875C12.1431 14.6452 11.0864 15.0009 10 15C7.8325 15 5.99208 13.618 5.29875 11.6892L2.58125 13.783C3.96042 16.4817 6.76125 18.3334 10 18.3334Z'
                        fill='#4CAF50'
                      />
                      <path
                        d='M18.1713 8.36796H17.5V8.33337H10V11.6667H14.7096C14.3809 12.5902 13.7889 13.3972 13.0067 13.988L13.0079 13.9871L15.5871 16.1696C15.4046 16.3355 18.3333 14.1667 18.3333 10C18.3333 9.44129 18.2758 8.89587 18.1713 8.36796Z'
                        fill='#1976D2'
                      />
                    </svg>
                    <span className='ml-2'>Continue with Google</span>
                  </button>
                  <p className='text-sm text-center'>
                    <span className='mr-1 text-gray-200'>
                      Already have an account?
                    </span>
                    <a
                      className='inline-block text-white hover:text-gray-200 font-semibold'
                      href='#'
                    >
                      Login
                    </a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className='bg-gray-900'>
          <div className='flex flex-wrap'>
            <div className='w-full lg:w-1/2 px-5'>
              <div className='pt-12 pb-12 md:pt-24 lg:pt-32 md:pb-24 xl:pb-56 md:px-8'>
                <div className='max-w-sm mx-auto'>
                  <h3 className='font-heading tracking-tight text-4xl font-bold text-white mb-4'>
                    Sign up
                  </h3>
                  <p className='text-gray-400 mb-8'>
                    Create your account with your work email.
                  </p>
                  <form action=''>
                    <a
                      className='inline-flex w-full h-12 py-2 px-5 items-center justify-center text-base font-bold text-gray-700 hover:text-yellowGreen-700 bg-white border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                      href='#'
                    >
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M10 1.91663C12.0354 1.91663 13.89 2.67488 15.3114 3.91834L13.3034 5.92639C12.4018 5.19322 11.2539 4.74996 10 4.74996C7.10068 4.74996 4.75 7.10064 4.75 9.99996C4.75 12.8993 7.10068 15.25 10 15.25C12.286 15.25 14.2241 13.7867 14.9452 11.7501L15.0633 11.4166H14.7096H10.25V8.58329H17.25V8.61788H17.5H17.9634C18.0412 9.06748 18.0833 9.52903 18.0833 9.99996C18.0833 14.464 14.464 18.0833 10 18.0833C5.53599 18.0833 1.91667 14.464 1.91667 9.99996C1.91667 5.53595 5.53599 1.91663 10 1.91663Z'
                          fill='#FFC107'
                          stroke='#3B5444'
                          strokeWidth='0.5'
                        />
                        <path
                          d='M2.6275 6.12121L5.36542 8.12913C6.10625 6.29496 7.90042 4.99996 10 4.99996C11.2746 4.99996 12.4342 5.48079 13.3171 6.26621L15.6742 3.90913C14.1858 2.52204 12.195 1.66663 10 1.66663C6.79917 1.66663 4.02333 3.47371 2.6275 6.12121Z'
                          fill='#FF3D00'
                        />
                        <path
                          d='M10 18.3334C12.1525 18.3334 14.1083 17.5096 15.5871 16.17L13.0079 13.9875C12.1431 14.6452 11.0864 15.0009 10 15C7.8325 15 5.99208 13.618 5.29875 11.6892L2.58125 13.783C3.96042 16.4817 6.76125 18.3334 10 18.3334Z'
                          fill='#4CAF50'
                        />
                        <path
                          d='M18.1713 8.36796H17.5V8.33337H10V11.6667H14.7096C14.3809 12.5902 13.7889 13.3972 13.0067 13.988L13.0079 13.9871L15.5871 16.1696C15.4046 16.3355 18.3333 14.1667 18.3333 10C18.3333 9.44129 18.2758 8.89587 18.1713 8.36796Z'
                          fill='#1976D2'
                        />
                      </svg>
                      <span className='ml-2'>Continue with Google</span>
                    </a>
                    <div className='my-8 flex items-center'>
                      <div className='h-px w-full bg-gray-700' />
                      <span className='inline-block mx-4 text-xs font-medium text-gray-500'>
                        OR
                      </span>
                      <div className='h-px w-full bg-gray-700' />
                    </div>
                    <div className='mb-8'>
                      <label
                        className='block mb-2 text-sm font-medium text-white'
                        htmlFor=''
                      >
                        Email
                      </label>
                      <input
                        className='py-2 px-4 h-12 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                        type='email'
                        placeholder='Enter your email address'
                      />
                    </div>
                    <button variant="classic"
                      className='xs:flex-shrink-0 group relative w-full h-12 mb-8 flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
                      type='submit'
                    >
                      <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                      <span>Sign up</span>
                    </button>
                    <p className='text-sm'>
                      <span className='mr-1 text-gray-500'>
                        By signing up, you agree to our
                      </span>
                      <a
                        className='inline-block text-yellowGreen-600 hover:text-yellowGreen-500 font-semibold'
                        href='#'
                      >
                        Terms and Privacy Policy
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <div className='w-full lg:w-1/2'>
              <div className='relative flex flex-col max-w-md mx-auto lg:max-w-none h-full pt-8 pb-12 px-10 overflow-hidden'>
                <div className='relative z-10 flex items-center justify-end'>
                  <a
                    className='inline-flex mr-10 items-center leading-loose text-white text-sm font-semibold hover:text-yellowGreen-600'
                    href='#'
                  >
                    <svg
                      width={20}
                      height={20}
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clipPath='url(#clip0_3478_15610)'>
                        <path
                          d='M10.0001 18.3333C14.6025 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6025 1.66663 10.0001 1.66663C5.39771 1.66663 1.66675 5.39759 1.66675 9.99996C1.66675 14.6023 5.39771 18.3333 10.0001 18.3333Z'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M2.08325 10.4166L6.66658 12.0833L5.83325 15L6.66658 17.5'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M14.1667 17.0833L13.7501 15L11.6667 14.1666V11.25L14.1667 10.4166L17.9167 10.8333'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M15.8333 4.58337L15.4167 5.83337L12.5 6.25004V8.75004L14.5833 7.91671H16.25L17.9167 8.75004'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M2.08325 8.75L4.16659 7.08333L6.24992 6.66667L7.91659 4.16667L7.08325 2.5'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </g>
                    </svg>
                    <span className='ml-2'>English</span>
                  </a>
                  <a
                    className='group flex justify-center h-12 p-1 text-center leading-loose text-sm text-white font-semibold rounded-lg'
                    href='#'
                  >
                    <div className='inline-flex items-stretch w-full h-full p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                      <div className='flex w-full items-center justify-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                        <span>Login</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div className='relative z-10 my-auto py-24'>
                  <img
                    className='block mx-auto'
                    src='aurora-assets/sign-up/logo-center-green.png'
                    alt=''
                  />
                </div>
                <div className='relative z-10'>
                  <h5 className='text-gray-400 text-sm mb-6'>
                    Trusted by the best
                  </h5>
                  <div className='flex flex-wrap -mx-4 -mb-10'>
                    <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                      <img
                        className='block h-7'
                        src='aurora-assets/logo-clouds/logo-white-8.svg'
                        alt=''
                      />
                    </div>
                    <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                      <img
                        className='block h-7'
                        src='aurora-assets/logo-clouds/logo-white-7.svg'
                        alt=''
                      />
                    </div>
                    <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                      <img
                        className='block h-7'
                        src='aurora-assets/logo-clouds/logo-white-6.svg'
                        alt=''
                      />
                    </div>
                    <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                      <img
                        className='block h-7'
                        src='aurora-assets/logo-clouds/logo-white-2.svg'
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <img
                  className='absolute top-0 left-0 w-full xl:-mt-52'
                  src='aurora-assets/sign-up/pattern-line-dark1.png'
                  alt=''
                />
                <img
                  className='absolute bottom-0 left-0 w-full'
                  src='aurora-assets/sign-up/pattern-line-dark2.png'
                  alt=''
                />
              </div>
            </div>
          </div>
        </section>
        <section className='bg-gray-900'>
          <div className='flex flex-wrap'>
            <div className='w-full lg:w-1/2 px-5'>
              <div className='pt-12 pb-12 md:pt-24 lg:pt-32 md:pb-24 xl:pb-56 md:px-8'>
                <div className='max-w-sm mx-auto'>
                  <h3 className='font-heading tracking-tight text-4xl font-bold text-white mb-4'>
                    Sign up
                  </h3>
                  <p className='text-gray-400 mb-8'>
                    Create your account with your work email.
                  </p>
                  <form action=''>
                    <a
                      className='inline-flex w-full h-12 py-2 px-5 items-center justify-center text-base font-bold text-gray-700 hover:text-yellowGreen-700 bg-white border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                      href='#'
                    >
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M10 1.91663C12.0354 1.91663 13.89 2.67488 15.3114 3.91834L13.3034 5.92639C12.4018 5.19322 11.2539 4.74996 10 4.74996C7.10068 4.74996 4.75 7.10064 4.75 9.99996C4.75 12.8993 7.10068 15.25 10 15.25C12.286 15.25 14.2241 13.7867 14.9452 11.7501L15.0633 11.4166H14.7096H10.25V8.58329H17.25V8.61788H17.5H17.9634C18.0412 9.06748 18.0833 9.52903 18.0833 9.99996C18.0833 14.464 14.464 18.0833 10 18.0833C5.53599 18.0833 1.91667 14.464 1.91667 9.99996C1.91667 5.53595 5.53599 1.91663 10 1.91663Z'
                          fill='#FFC107'
                          stroke='#3B5444'
                          strokeWidth='0.5'
                        />
                        <path
                          d='M2.6275 6.12121L5.36542 8.12913C6.10625 6.29496 7.90042 4.99996 10 4.99996C11.2746 4.99996 12.4342 5.48079 13.3171 6.26621L15.6742 3.90913C14.1858 2.52204 12.195 1.66663 10 1.66663C6.79917 1.66663 4.02333 3.47371 2.6275 6.12121Z'
                          fill='#FF3D00'
                        />
                        <path
                          d='M10 18.3334C12.1525 18.3334 14.1083 17.5096 15.5871 16.17L13.0079 13.9875C12.1431 14.6452 11.0864 15.0009 10 15C7.8325 15 5.99208 13.618 5.29875 11.6892L2.58125 13.783C3.96042 16.4817 6.76125 18.3334 10 18.3334Z'
                          fill='#4CAF50'
                        />
                        <path
                          d='M18.1713 8.36796H17.5V8.33337H10V11.6667H14.7096C14.3809 12.5902 13.7889 13.3972 13.0067 13.988L13.0079 13.9871L15.5871 16.1696C15.4046 16.3355 18.3333 14.1667 18.3333 10C18.3333 9.44129 18.2758 8.89587 18.1713 8.36796Z'
                          fill='#1976D2'
                        />
                      </svg>
                      <span className='ml-2'>Continue with Google</span>
                    </a>
                    <div className='my-8 flex items-center'>
                      <div className='h-px w-full bg-gray-700' />
                      <span className='inline-block mx-4 text-xs font-medium text-gray-500'>
                        OR
                      </span>
                      <div className='h-px w-full bg-gray-700' />
                    </div>
                    <div className='mb-8'>
                      <label
                        className='block mb-2 text-sm font-medium text-white'
                        htmlFor=''
                      >
                        Email
                      </label>
                      <input
                        className='py-2 px-4 h-12 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                        type='email'
                        placeholder='Enter your email address'
                      />
                    </div>
                    <button variant="classic"
                      className='xs:flex-shrink-0 group relative w-full h-12 mb-8 flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
                      type='submit'
                    >
                      <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                      <span>Sign up</span>
                    </button>
                    <p className='text-sm'>
                      <span className='mr-1 text-gray-500'>
                        By signing up, you agree to our
                      </span>
                      <a
                        className='inline-block text-yellowGreen-600 hover:text-yellowGreen-500 font-semibold'
                        href='#'
                      >
                        Terms and Privacy Policy
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <div className='w-full lg:w-1/2'>
              <div className='relative flex flex-col max-w-md mx-auto lg:max-w-none h-full pt-8 pb-12 px-10 overflow-hidden'>
                <div className='relative z-10 flex items-center justify-end'>
                  <a
                    className='inline-flex mr-10 items-center leading-loose text-white text-sm font-semibold hover:text-yellowGreen-600'
                    href='#'
                  >
                    <svg
                      width={20}
                      height={20}
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clipPath='url(#clip0_3478_15610)'>
                        <path
                          d='M10.0001 18.3333C14.6025 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6025 1.66663 10.0001 1.66663C5.39771 1.66663 1.66675 5.39759 1.66675 9.99996C1.66675 14.6023 5.39771 18.3333 10.0001 18.3333Z'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M2.08325 10.4166L6.66658 12.0833L5.83325 15L6.66658 17.5'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M14.1667 17.0833L13.7501 15L11.6667 14.1666V11.25L14.1667 10.4166L17.9167 10.8333'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M15.8333 4.58337L15.4167 5.83337L12.5 6.25004V8.75004L14.5833 7.91671H16.25L17.9167 8.75004'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M2.08325 8.75L4.16659 7.08333L6.24992 6.66667L7.91659 4.16667L7.08325 2.5'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </g>
                    </svg>
                    <span className='ml-2'>English</span>
                  </a>
                  <a
                    className='group flex justify-center h-12 p-1 text-center leading-loose text-sm text-white font-semibold rounded-lg'
                    href='#'
                  >
                    <div className='inline-flex items-stretch w-full h-full p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                      <div className='flex w-full items-center justify-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                        <span>Login</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div className='relative z-10 my-auto py-24'>
                  <img
                    className='block mx-auto'
                    src='aurora-assets/sign-up/logo-center-green.png'
                    alt=''
                  />
                </div>
                <div className='relative z-10'>
                  <h5 className='text-gray-400 text-sm mb-6'>
                    Trusted by the best
                  </h5>
                  <div className='flex flex-wrap -mx-4 -mb-10'>
                    <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                      <img
                        className='block h-7'
                        src='aurora-assets/logo-clouds/logo-white-8.svg'
                        alt=''
                      />
                    </div>
                    <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                      <img
                        className='block h-7'
                        src='aurora-assets/logo-clouds/logo-white-7.svg'
                        alt=''
                      />
                    </div>
                    <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                      <img
                        className='block h-7'
                        src='aurora-assets/logo-clouds/logo-white-6.svg'
                        alt=''
                      />
                    </div>
                    <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                      <img
                        className='block h-7'
                        src='aurora-assets/logo-clouds/logo-white-2.svg'
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <img
                  className='absolute top-0 left-0 w-full xl:-mt-52'
                  src='aurora-assets/sign-up/pattern-line-dark1.png'
                  alt=''
                />
                <img
                  className='absolute bottom-0 left-0 w-full'
                  src='aurora-assets/sign-up/pattern-line-dark2.png'
                  alt=''
                />
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-radial-dark-green overflow-hidden'>
          <img
            className='absolute bottom-0 left-0 w-full xl:-mb-24'
            src='aurora-assets/sign-up/half-circle-gradient.png'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <div>
              <div className='text-center'>
                <a className='inline-block mx-auto mb-8' href='#'>
                  <img
                    className='h-8'
                    src='aurora-assets/logos/aurora-logo-white.svg'
                    alt=''
                  />
                </a>
              </div>
              <div className='max-w-md p-8 mb-8 mx-auto bg-white border border-gray-200 rounded-2xl shadow-lg'>
                <h3 className='font-heading tracking-tight text-4xl font-bold mb-4'>
                  Sign up
                </h3>
                <p className='text-gray-500 mb-8'>
                  Create your account with your work email.
                </p>
                <form action=''>
                  <div className='mb-8'>
                    <label
                      className='block mb-2 text-sm font-medium'
                      htmlFor=''
                    >
                      Email
                    </label>
                    <input
                      className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg shadow-sm outline-none ring ring-transparent focus:ring-yellowGreen-500'
                      type='email'
                      placeholder='Enter your email address'
                    />
                  </div>
                  <div className='mb-8'>
                    <label
                      className='block mb-2 text-sm font-medium'
                      htmlFor=''
                    >
                      Password
                    </label>
                    <div className='relative'>
                      <input
                        className='relative py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg shadow-sm outline-none ring ring-transparent focus:ring-yellowGreen-500'
                        type='password'
                        placeholder='Enter password'
                      />
                      <button variant="classic" className='absolute top-1/2 right-0 mr-3 transform -translate-y-1/2 inline-block focus:outline-none text-gray-500 hover:text-yellowGreen-600'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M2.5 10.8334C5.5 4.16671 14.5 4.16671 17.5 10.8334'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M10 14.1666C8.61929 14.1666 7.5 13.0473 7.5 11.6666C7.5 10.2859 8.61929 9.16663 10 9.16663C11.3807 9.16663 12.5 10.2859 12.5 11.6666C12.5 13.0473 11.3807 14.1666 10 14.1666Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <button variant="classic"
                    className='group relative flex items-center justify-center px-5 h-12 mb-8 w-full font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300 focus:outline-none'
                    type='submit'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Sign up</span>
                  </button>
                  <p className='text-sm'>
                    <span className='mr-1 text-gray-500'>
                      By signing up, you agree to our
                    </span>
                    <a
                      className='inline-block text-teal-500 hover:text-yellowGreen-600 font-semibold'
                      href='#'
                    >
                      Terms and Privacy Policy
                    </a>
                  </p>
                </form>
              </div>
              <div className='text-center'>
                <p className='text-sm'>
                  <span className='text-gray-200'>
                    Already have an account?
                  </span>
                  <a
                    className='inline-block text-yellowGreen-600 hover:text-yellowGreen-500 font-semibold'
                    href='#'
                  >
                    Login
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-gray-900 overflow-hidden'>
          <img
            className='absolute bottom-0 left-0 w-full xl:-mb-24'
            src='aurora-assets/sign-up/half-circle-dark.png'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <div>
              <div className='text-center'>
                <a className='inline-block mx-auto mb-8' href='#'>
                  <img
                    className='h-8'
                    src='aurora-assets/logos/aurora-logo-white.svg'
                    alt=''
                  />
                </a>
              </div>
              <div className='max-w-md p-8 mb-8 mx-auto bg-gray-900 border border-gray-700 rounded-2xl shadow-lg'>
                <h3 className='font-heading tracking-tight text-4xl font-bold text-white mb-4'>
                  Sign up
                </h3>
                <p className='text-gray-400 mb-8'>
                  Create your account with your work email.
                </p>
                <form action=''>
                  <div className='mb-8'>
                    <label
                      className='block mb-2 text-sm font-medium text-white'
                      htmlFor=''
                    >
                      Email
                    </label>
                    <input
                      className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-900 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                      type='email'
                      placeholder='Enter your email address'
                    />
                  </div>
                  <div className='mb-8'>
                    <label
                      className='block mb-2 text-sm font-medium text-white'
                      htmlFor=''
                    >
                      Password
                    </label>
                    <div className='relative'>
                      <input
                        className='relative py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-900 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                        type='password'
                        placeholder='Enter password'
                      />
                      <button variant="classic" className='absolute top-1/2 right-0 mr-3 transform -translate-y-1/2 inline-block focus:outline-none text-gray-500 hover:text-yellowGreen-600'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M2.5 10.8334C5.5 4.16671 14.5 4.16671 17.5 10.8334'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M10 14.1666C8.61929 14.1666 7.5 13.0473 7.5 11.6666C7.5 10.2859 8.61929 9.16663 10 9.16663C11.3807 9.16663 12.5 10.2859 12.5 11.6666C12.5 13.0473 11.3807 14.1666 10 14.1666Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <button variant="classic"
                    className='xs:flex-shrink-0 group relative w-full h-12 mb-8 flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
                    type='submit'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Sign up</span>
                  </button>
                  <p className='text-sm'>
                    <span className='mr-1 text-gray-500'>
                      By signing up, you agree to our
                    </span>
                    <a
                      className='inline-block text-yellowGreen-600 hover:text-yellowGreen-500 font-semibold'
                      href='#'
                    >
                      Terms and Privacy Policy
                    </a>
                  </p>
                </form>
              </div>
              <div className='text-center'>
                <p className='text-sm'>
                  <span className='mr-1 text-gray-500'>
                    Already have an account?
                  </span>
                  <a
                    className='inline-block text-yellowGreen-600 hover:text-yellowGreen-500 font-semibold'
                    href='#'
                  >
                    Login
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='bg-gray-900'>
          <div className='flex flex-wrap'>
            <div className='w-full lg:w-5/12 px-5'>
              <div className='py-12 md:pt-8 h-full'>
                <div className='flex flex-col h-full max-w-xs mx-auto'>
                  <a className='inline-block mb-24' href='#'>
                    <img
                      className='h-8'
                      src='aurora-assets/logos/aurora-logo-white.svg'
                      alt=''
                    />
                  </a>
                  <img
                    className='block w-64 mb-10'
                    src='aurora-assets/sign-up/circle-image-dark.png'
                    alt=''
                  />
                  <h3 className='font-heading tracking-tight text-4xl font-bold text-white mb-4'>
                    Sign up
                  </h3>
                  <p className='mb-24 text-gray-400'>
                    Create your account with your work email.
                  </p>
                  <a
                    className='inline-flex mt-auto items-center text-sm text-white hover:text-yellowGreen-600 font-semibold'
                    href='#'
                  >
                    <svg
                      width={20}
                      height={20}
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M12.5 5L7.5 10L12.5 15'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                    <span className='ml-2'>Back</span>
                  </a>
                </div>
              </div>
            </div>
            <div className='w-full lg:w-7/12 px-5'>
              <div className='py-12 md:pt-8 md:px-8'>
                <div className='max-w-sm lg:max-w-md xl:max-w-lg mx-auto lg:mr-0 xl:mx-auto'>
                  <div className='flex items-center justify-end mb-20'>
                    <a
                      className='inline-flex mr-10 items-center leading-loose text-white text-sm font-semibold hover:text-yellowGreen-600'
                      href='#'
                    >
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3478_15610)'>
                          <path
                            d='M10.0001 18.3333C14.6025 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6025 1.66663 10.0001 1.66663C5.39771 1.66663 1.66675 5.39759 1.66675 9.99996C1.66675 14.6023 5.39771 18.3333 10.0001 18.3333Z'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M2.08325 10.4166L6.66658 12.0833L5.83325 15L6.66658 17.5'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M14.1667 17.0833L13.7501 15L11.6667 14.1666V11.25L14.1667 10.4166L17.9167 10.8333'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M15.8333 4.58337L15.4167 5.83337L12.5 6.25004V8.75004L14.5833 7.91671H16.25L17.9167 8.75004'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M2.08325 8.75L4.16659 7.08333L6.24992 6.66667L7.91659 4.16667L7.08325 2.5'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                      <span className='ml-2'>English</span>
                    </a>
                    <a
                      className='group flex justify-center h-11 p-1 text-center leading-loose text-sm text-white font-semibold rounded-lg'
                      href='#'
                    >
                      <div className='inline-flex items-stretch w-full h-full p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                        <div className='flex w-full items-center justify-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                          <span>Login</span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className='max-w-sm'>
                    <form action=''>
                      <div className='mb-8'>
                        <label
                          className='block mb-2 text-sm font-medium text-white'
                          htmlFor=''
                        >
                          Name
                        </label>
                        <input
                          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                          type='text'
                          placeholder='Enter your name'
                        />
                      </div>
                      <div className='mb-8'>
                        <label
                          className='block mb-2 text-sm font-medium text-white'
                          htmlFor=''
                        >
                          Email
                        </label>
                        <input
                          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                          type='email'
                          placeholder='Enter your email address'
                        />
                      </div>
                      <div className='mb-8'>
                        <label
                          className='block mb-2 text-sm font-medium text-white'
                          htmlFor=''
                        >
                          What’s your role
                        </label>
                        <div className='relative bg-gray-50 bg-opacity-40 rounded-lg'>
                          <select
                            className='relative z-10 py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-transparent bg-gray-800 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg appearance-none'
                            name=''
                            id=''
                          >
                            <option value={1}>Select role</option>
                            <option value={1}>Marketing</option>
                            <option value={1}>Sales</option>
                          </select>
                          <span className='absolute top-1/2 right-0 mr-3 transform -translate-y-1/2'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M5 7.5L10 12.5L15 7.5'
                                stroke='#6C7F73'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <div className='mb-8'>
                        <label
                          className='block mb-2 text-sm font-medium text-white'
                          htmlFor=''
                        >
                          Password
                        </label>
                        <div className='relative'>
                          <input
                            className='relative py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                            type='password'
                            placeholder='Enter password'
                          />
                          <button variant="classic" className='absolute top-1/2 right-0 mr-3 transform -translate-y-1/2 inline-block focus:outline-none text-gray-500 hover:text-yellowGreen-600'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M2.5 10.8334C5.5 4.16671 14.5 4.16671 17.5 10.8334'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M10 14.1666C8.61929 14.1666 7.5 13.0473 7.5 11.6666C7.5 10.2859 8.61929 9.16663 10 9.16663C11.3807 9.16663 12.5 10.2859 12.5 11.6666C12.5 13.0473 11.3807 14.1666 10 14.1666Z'
                                stroke='currentColor'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <button variant="classic"
                        className='xs:flex-shrink-0 group relative w-full h-12 flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
                        type='submit'
                      >
                        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                        <span>Sign up</span>
                      </button>
                      <div className='my-4 flex items-center'>
                        <div className='h-px w-full bg-white bg-opacity-20' />
                        <span className='inline-block mx-4 text-xs font-medium text-gray-400'>
                          OR
                        </span>
                        <div className='h-px w-full bg-white bg-opacity-20' />
                      </div>
                      <a
                        className='inline-flex w-full h-12 mb-18 py-2 px-5 items-center justify-center text-base font-bold text-gray-700 hover:text-yellowGreen-700 bg-white rounded-lg transition duration-200'
                        href='#'
                      >
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M10 1.91663C12.0354 1.91663 13.89 2.67488 15.3114 3.91834L13.3034 5.92639C12.4018 5.19322 11.2539 4.74996 10 4.74996C7.10068 4.74996 4.75 7.10064 4.75 9.99996C4.75 12.8993 7.10068 15.25 10 15.25C12.286 15.25 14.2241 13.7867 14.9452 11.7501L15.0633 11.4166H14.7096H10.25V8.58329H17.25V8.61788H17.5H17.9634C18.0412 9.06748 18.0833 9.52903 18.0833 9.99996C18.0833 14.464 14.464 18.0833 10 18.0833C5.53599 18.0833 1.91667 14.464 1.91667 9.99996C1.91667 5.53595 5.53599 1.91663 10 1.91663Z'
                            fill='#FFC107'
                            stroke='#3B5444'
                            strokeWidth='0.5'
                          />
                          <path
                            d='M2.6275 6.12121L5.36542 8.12913C6.10625 6.29496 7.90042 4.99996 10 4.99996C11.2746 4.99996 12.4342 5.48079 13.3171 6.26621L15.6742 3.90913C14.1858 2.52204 12.195 1.66663 10 1.66663C6.79917 1.66663 4.02333 3.47371 2.6275 6.12121Z'
                            fill='#FF3D00'
                          />
                          <path
                            d='M10 18.3334C12.1525 18.3334 14.1083 17.5096 15.5871 16.17L13.0079 13.9875C12.1431 14.6452 11.0864 15.0009 10 15C7.8325 15 5.99208 13.618 5.29875 11.6892L2.58125 13.783C3.96042 16.4817 6.76125 18.3334 10 18.3334Z'
                            fill='#4CAF50'
                          />
                          <path
                            d='M18.1713 8.36796H17.5V8.33337H10V11.6667H14.7096C14.3809 12.5902 13.7889 13.3972 13.0067 13.988L13.0079 13.9871L15.5871 16.1696C15.4046 16.3355 18.3333 14.1667 18.3333 10C18.3333 9.44129 18.2758 8.89587 18.1713 8.36796Z'
                            fill='#1976D2'
                          />
                        </svg>
                        <span className='ml-2'>Continue with Google</span>
                      </a>
                      <p className='text-sm'>
                        <span className='mr-1 text-gray-500'>
                          By signing up, you agree to our
                        </span>
                        <a
                          className='inline-block text-yellowGreen-600 hover:text-yellowGreen-500 font-semibold'
                          href='#'
                        >
                          Terms and Privacy Policy
                        </a>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='flex flex-wrap'>
            <div className='w-full lg:w-1/2 px-5 bg-gray-900'>
              <div className='pt-8 pb-12 lg:pb-32 md:px-8'>
                <div className='flex items-center justify-end mb-20'>
                  <span className='mr-2 text-sm text-white'>
                    Already have an account?
                  </span>
                  <a
                    className='group flex justify-center h-11 p-1 text-center text-sm text-white font-semibold rounded-lg'
                    href='#'
                  >
                    <div className='inline-flex items-stretch w-full h-full p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                      <div className='flex w-full items-center justify-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                        <span>Login</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div className='max-w-sm mx-auto'>
                  <h3 className='font-heading tracking-tight text-4xl font-bold text-white mb-4'>
                    Sign up
                  </h3>
                  <p className='text-gray-400 mb-8'>
                    Create your account with your work email.
                  </p>
                  <form action=''>
                    <a
                      className='group flex justify-center p-1 text-center text-sm text-white font-semibold rounded-lg'
                      href='#'
                    >
                      <div className='inline-flex items-stretch w-full h-12 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                        <div className='flex w-full items-center justify-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M10 1.91663C12.0354 1.91663 13.89 2.67488 15.3114 3.91834L13.3034 5.92639C12.4018 5.19322 11.2539 4.74996 10 4.74996C7.10068 4.74996 4.75 7.10064 4.75 9.99996C4.75 12.8993 7.10068 15.25 10 15.25C12.286 15.25 14.2241 13.7867 14.9452 11.7501L15.0633 11.4166H14.7096H10.25V8.58329H17.25V8.61788H17.5H17.9634C18.0412 9.06748 18.0833 9.52903 18.0833 9.99996C18.0833 14.464 14.464 18.0833 10 18.0833C5.53599 18.0833 1.91667 14.464 1.91667 9.99996C1.91667 5.53595 5.53599 1.91663 10 1.91663Z'
                              fill='#FFC107'
                              stroke='#3B5444'
                              strokeWidth='0.5'
                            />
                            <path
                              d='M2.6275 6.12121L5.36542 8.12913C6.10625 6.29496 7.90042 4.99996 10 4.99996C11.2746 4.99996 12.4342 5.48079 13.3171 6.26621L15.6742 3.90913C14.1858 2.52204 12.195 1.66663 10 1.66663C6.79917 1.66663 4.02333 3.47371 2.6275 6.12121Z'
                              fill='#FF3D00'
                            />
                            <path
                              d='M10 18.3334C12.1525 18.3334 14.1083 17.5096 15.5871 16.17L13.0079 13.9875C12.1431 14.6452 11.0864 15.0009 10 15C7.8325 15 5.99208 13.618 5.29875 11.6892L2.58125 13.783C3.96042 16.4817 6.76125 18.3334 10 18.3334Z'
                              fill='#4CAF50'
                            />
                            <path
                              d='M18.1713 8.36796H17.5V8.33337H10V11.6667H14.7096C14.3809 12.5902 13.7889 13.3972 13.0067 13.988L13.0079 13.9871L15.5871 16.1696C15.4046 16.3355 18.3333 14.1667 18.3333 10C18.3333 9.44129 18.2758 8.89587 18.1713 8.36796Z'
                              fill='#1976D2'
                            />
                          </svg>
                          <span className='ml-2'>Continue with Google</span>
                        </div>
                      </div>
                    </a>
                    <div className='my-8 flex items-center'>
                      <div className='h-px w-full bg-gray-700' />
                      <span className='inline-block mx-4 text-xs font-medium text-gray-500'>
                        OR
                      </span>
                      <div className='h-px w-full bg-gray-700' />
                    </div>
                    <div className='mb-8'>
                      <label
                        className='block mb-2 text-sm font-medium text-white'
                        htmlFor=''
                      >
                        Email
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-teal-800 shadow-sm outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                        type='email'
                        placeholder='Enter your email address'
                      />
                    </div>
                    <button variant="classic"
                      className='xs:flex-shrink-0 group relative w-full h-12 mb-8 flex items-center justify-center px-4 p-px font-bold text-gray-700 bg-white rounded-lg transition-all duration-300 focus:outline-none'
                      type='submit'
                    >
                      <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-gray-500 animate-pulse group-hover:ring-0 transition duration-300' />
                      <span>Sign up</span>
                    </button>
                    <p className='text-sm'>
                      <span className='mr-1 text-gray-500'>
                        By signing up, you agree to our
                      </span>
                      <a
                        className='inline-block text-yellowGreen-600 hover:text-yellowGreen-500 font-semibold'
                        href='#'
                      >
                        Terms and Privacy Policy
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <div className='w-full lg:w-1/2 px-5 bg-radial-dark-green'>
              <div className='flex h-full py-12 items-center justify-center'>
                <div className='max-w-md sm:max-w-lg px-2'>
                  <div className='py-12 xs:px-10 border-t-2 border-b-2 border-white border-opacity-20'>
                    <span className='block mb-5'>
                      <svg
                        width={18}
                        height={12}
                        viewBox='0 0 18 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M9.072 0.351998L6.192 11.2H0.528L4.848 0.351998H9.072ZM17.568 0.351998L14.736 11.2H9.072L13.392 0.351998H17.568Z'
                          fill='white'
                        />
                      </svg>
                    </span>
                    <p className='text-2xl font-medium text-white mb-10'>
                      I've tried numerous assets manager platforms, but this one
                      truly stands out!
                    </p>
                    <div className='flex items-center justify-between'>
                      <div>
                        <span className='block mb-1 text-sm font-semibold text-white'>
                          Cooper Levin
                        </span>
                        <span className='block text-xs text-gray-300'>
                          Founder of AIME Inc.
                        </span>
                      </div>
                      <img
                        src='aurora-assets/logos/logo-aurora-white.svg'
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    <FooterComponent /></React.Fragment>
  );
}

