import React from 'react';

import { Flex, Text } from '@radix-ui/themes';


import {FooterComponent} from './Footers.jsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Contacts() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-5/12 xl:w-1/2 px-4 mb-16 lg:mb-0'>
                <div className='max-w-sm'>
                  <span className='inline-flex items-center h-7 mb-6 px-2 text-sm font-medium text-white bg-gray-800 rounded-full'>
                    <svg
                      width={12}
                      height={12}
                      viewBox='0 0 12 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M10 5.5C10 3.29086 8.20914 1.5 6 1.5C3.79086 1.5 2 3.29086 2 5.5'
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M2.34038 8.83509L2.21268 8.80317C1.49998 8.62499 1 7.98463 1 7.25C1 6.51537 1.49998 5.87501 2.21268 5.69683L2.34038 5.66491C2.67543 5.58114 3 5.83456 3 6.17992V8.32008C3 8.66544 2.67543 8.91886 2.34038 8.83509Z'
                        stroke='currentColor'
                      />
                      <path
                        d='M9.65962 8.83509L9.78732 8.80317C10.5 8.62499 11 7.98463 11 7.25C11 6.51537 10.5 5.87501 9.78732 5.69683L9.65962 5.66491C9.32457 5.58114 9 5.83456 9 6.17992V8.32008C9 8.66544 9.32457 8.91886 9.65962 8.83509Z'
                        stroke='currentColor'
                      />
                      <path
                        d='M10 9V9C10 9.69036 9.44036 10.25 8.75 10.25H7.25'
                        stroke='currentColor'
                      />
                      <path
                        d='M6.75 11H5.25C4.83579 11 4.5 10.6642 4.5 10.25C4.5 9.83579 4.83579 9.5 5.25 9.5H6.75C7.16421 9.5 7.5 9.83579 7.5 10.25C7.5 10.6642 7.16421 11 6.75 11Z'
                        stroke='currentColor'
                      />
                    </svg>
                    <span className='ml-1'>Contact Support</span>
                  </span>
                  <h1 className='font-heading tracking-tight text-4xl xs:text-5xl font-bold text-white mb-4'>
                    How can we help you today?
                  </h1>
                  <p className='text-lg text-gray-400 mb-10'>
                    Submit your info and we’ll get back to you as soon as
                    possible.
                  </p>
                  <div className='flex items-center'>
                    <img
                      className='block w-10 h-10 rounded-full'
                      src='aurora-assets/blog/avatar-medium.png'
                      alt=''
                    />
                    <span className='ml-4 text-sm font-medium text-white'>
                      Responds in &amp;pr; 1-2 hours
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full lg:w-7/12 xl:w-1/2 px-4'>
                <div className='p-8 bg-white rounded-xl'>
                  <form action=''>
                    <div className='flex flex-wrap -mx-4 mb-8 items-center'>
                      <div className='w-full md:w-1/2 px-4 mb-4'>
                        <label
                          className='block mb-1 text-sm font-medium'
                          htmlFor=''
                        >
                          First name
                        </label>
                        <input
                          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                          type='text'
                          placeholder='First name'
                        />
                      </div>
                      <div className='w-full md:w-1/2 px-4 mb-4'>
                        <label
                          className='block mb-1 text-sm font-medium'
                          htmlFor=''
                        >
                          Last name
                        </label>
                        <input
                          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                          type='text'
                          placeholder='Last name'
                        />
                      </div>
                      <div className='w-full md:w-1/2 px-4 mb-4'>
                        <label
                          className='block mb-1 text-sm font-medium'
                          htmlFor=''
                        >
                          Email
                        </label>
                        <input
                          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                          type='email'
                          placeholder='john@email.com'
                        />
                      </div>
                      <div className='w-full md:w-1/2 px-4 mb-4'>
                        <label
                          className='block mb-1 text-sm font-medium'
                          htmlFor=''
                        >
                          Phone number
                        </label>
                        <div className='flex h-11 bg-gray-50 bg-opacity-40 border border-gray-200 rounded-md'>
                          <div className='relative'>
                            <select
                              className='relative z-10 pl-3 pr-6 w-full h-full bg-transparent focus:outline-none appearance-none cursor-pointer'
                              name=''
                              id=''
                            >
                              <option value=''>US</option>
                              <option value=''>GB</option>
                              <option value=''>EU</option>
                            </select>
                            <div className='absolute top-1/2 right-0 transform -translate-y-1/2'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M5 7.5L10 12.5L15 7.5'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </div>
                          </div>
                          <input
                            className='py-2 pl-2 pr-4 h-full text-gray-500 placeholder-gray-500 border-0 bg-transparent focus:outline-none'
                            type='text'
                            placeholder='+1 (555) 000-0000'
                          />
                        </div>
                      </div>
                      <div className='w-full px-4'>
                        <label
                          className='block mb-1 text-sm font-medium'
                          htmlFor=''
                        >
                          Message
                        </label>
                        <textarea
                          className='block py-2 px-4 w-full h-44 text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500 resize-none'
                          placeholder='Enter your message'
                          defaultValue={''}
                        />
                      </div>
                    </div>
                    <button variant="classic"
                      className='group relative flex items-center justify-center px-5 h-12 w-full mb-8 text-base font-semibold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                      type='submit'
                    >
                      <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                      <span>Submit</span>
                    </button>
                    <p className='text-sm text-gray-500'>
                      <span className='mr-1'>
                        We process your information in accordance with our
                      </span>
                      <a
                        className='inline-block font-semibold text-yellowGreen-700 hover:text-yellowGreen-600'
                        href='#'
                      >
                        Privacy Policy
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-5/12 xl:w-1/2 px-4 mb-16 lg:mb-0'>
                <div className='max-w-sm'>
                  <span className='inline-flex items-center h-7 mb-6 px-2 text-sm font-medium text-yellowGreen-700 bg-yellowGreen-200 rounded-full'>
                    <svg
                      width={12}
                      height={12}
                      viewBox='0 0 12 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M10 5.5C10 3.29086 8.20914 1.5 6 1.5C3.79086 1.5 2 3.29086 2 5.5'
                        stroke='#70A617'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M2.34038 8.83509L2.21268 8.80317C1.49998 8.62499 1 7.98463 1 7.25C1 6.51537 1.49998 5.87501 2.21268 5.69683L2.34038 5.66491C2.67543 5.58114 3 5.83456 3 6.17992V8.32008C3 8.66544 2.67543 8.91886 2.34038 8.83509Z'
                        stroke='#70A617'
                      />
                      <path
                        d='M9.65962 8.83509L9.78732 8.80317C10.5 8.62499 11 7.98463 11 7.25C11 6.51537 10.5 5.87501 9.78732 5.69683L9.65962 5.66491C9.32457 5.58114 9 5.83456 9 6.17992V8.32008C9 8.66544 9.32457 8.91886 9.65962 8.83509Z'
                        stroke='#70A617'
                      />
                      <path
                        d='M10 9V9C10 9.69036 9.44036 10.25 8.75 10.25H7.25'
                        stroke='#70A617'
                      />
                      <path
                        d='M6.75 11H5.25C4.83579 11 4.5 10.6642 4.5 10.25C4.5 9.83579 4.83579 9.5 5.25 9.5H6.75C7.16421 9.5 7.5 9.83579 7.5 10.25C7.5 10.6642 7.16421 11 6.75 11Z'
                        stroke='#70A617'
                      />
                    </svg>
                    <span className='ml-1'>Contact Support</span>
                  </span>
                  <h1 className='font-heading tracking-tight text-4xl xs:text-5xl font-bold mb-4'>
                    How can we help you today?
                  </h1>
                  <p className='text-lg text-gray-500 mb-10'>
                    Submit your info and we’ll get back to you as soon as
                    possible.
                  </p>
                  <div className='flex items-center'>
                    <img
                      className='block w-10 h-10 rounded-full'
                      src='aurora-assets/blog/avatar-medium.png'
                      alt=''
                    />
                    <span className='ml-4 text-sm font-medium'>
                      Responds in &amp;pr; 1-2 hours
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full lg:w-7/12 xl:w-1/2 px-4'>
                <div className='p-8 border border-gray-100 rounded-xl'>
                  <form action=''>
                    <div className='flex flex-wrap -mx-4 mb-8 items-center'>
                      <div className='w-full md:w-1/2 px-4 mb-4'>
                        <label
                          className='block mb-1 text-sm font-medium'
                          htmlFor=''
                        >
                          First name
                        </label>
                        <input
                          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                          type='text'
                          placeholder='First name'
                        />
                      </div>
                      <div className='w-full md:w-1/2 px-4 mb-4'>
                        <label
                          className='block mb-1 text-sm font-medium'
                          htmlFor=''
                        >
                          Last name
                        </label>
                        <input
                          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                          type='text'
                          placeholder='Last name'
                        />
                      </div>
                      <div className='w-full md:w-1/2 px-4 mb-4'>
                        <label
                          className='block mb-1 text-sm font-medium'
                          htmlFor=''
                        >
                          Email
                        </label>
                        <input
                          className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                          type='email'
                          placeholder='john@email.com'
                        />
                      </div>
                      <div className='w-full md:w-1/2 px-4 mb-4'>
                        <label
                          className='block mb-1 text-sm font-medium'
                          htmlFor=''
                        >
                          Phone number
                        </label>
                        <div className='flex h-11 bg-gray-50 bg-opacity-40 border border-gray-200 rounded-md'>
                          <div className='relative'>
                            <select
                              className='relative z-10 pl-3 pr-6 w-full h-full bg-transparent focus:outline-none appearance-none cursor-pointer'
                              name=''
                              id=''
                            >
                              <option value=''>US</option>
                              <option value=''>GB</option>
                              <option value=''>EU</option>
                            </select>
                            <div className='absolute top-1/2 right-0 transform -translate-y-1/2'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M5 7.5L10 12.5L15 7.5'
                                  stroke='#6C7F73'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </div>
                          </div>
                          <input
                            className='py-2 pl-2 pr-4 h-full text-gray-500 placeholder-gray-500 border-0 bg-transparent focus:outline-none'
                            type='text'
                            placeholder='+1 (555) 000-0000'
                          />
                        </div>
                      </div>
                      <div className='w-full px-4'>
                        <label
                          className='block mb-1 text-sm font-medium'
                          htmlFor=''
                        >
                          Message
                        </label>
                        <textarea
                          className='block py-2 px-4 w-full h-44 text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500 resize-none'
                          placeholder='Enter your message'
                          defaultValue={''}
                        />
                      </div>
                    </div>
                    <button variant="classic"
                      className='group relative flex items-center justify-center px-5 h-12 w-full mb-8 text-base font-semibold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                      type='submit'
                    >
                      <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                      <span>Submit</span>
                    </button>
                    <p className='text-sm text-gray-500'>
                      <span className='mr-1'>
                        We process your information in accordance with our
                      </span>
                      <a
                        className='inline-block font-semibold text-yellowGreen-700 hover:text-yellowGreen-600'
                        href='#'
                      >
                        Privacy Policy
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 overflow-hidden'>
          <div className='relative container px-4 mx-auto'>
            <div className='absolute top-0 left-0 w-full h-full px-4 xs:px-0'>
              <div className='px-8 pt-96 bg-gray-50 rounded-3xl'>
                <img
                  className='block mx-auto'
                  src='aurora-assets/contact/lines-background.png'
                  alt=''
                />
              </div>
            </div>
            <div className='relative px-10 py-16'>
              <div className='max-w-lg mb-12 mx-auto text-center'>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-4'>
                  Get in touch with us for more information
                </h1>
                <p className='text-lg text-gray-500'>
                  If you need help or have a question, we’re here for you
                </p>
              </div>
              <div className='max-w-xl mx-auto p-8 bg-white border border-gray-100 rounded-xl'>
                <form action=''>
                  <div className='mb-4'>
                    <label
                      className='block mb-1 text-sm font-medium'
                      htmlFor=''
                    >
                      Name
                    </label>
                    <input
                      className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                      type='text'
                      placeholder='Your name'
                    />
                  </div>
                  <div className='mb-4'>
                    <label
                      className='block mb-1 text-sm font-medium'
                      htmlFor=''
                    >
                      Email
                    </label>
                    <input
                      className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                      type='email'
                      placeholder='john@email.com'
                    />
                  </div>
                  <div className='mb-4'>
                    <label
                      className='block mb-1 text-sm font-medium'
                      htmlFor=''
                    >
                      Subject
                    </label>
                    <div className='relative w-full'>
                      <select
                        className='relative z-10 py-2 px-4 h-11 w-full cursor-pointer bg-transparent text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 outline-none ring ring-transparent focus:ring-yellowGreen-500 rounded-md appearance-none'
                        name=''
                        id=''
                      >
                        <option value=''>Select subject</option>
                        <option value=''>Marketing</option>
                        <option value=''>Development</option>
                      </select>
                      <span className='absolute top-1/2 right-0 mr-3 transform -translate-y-1/2'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M5 7.5L10 12.5L15 7.5'
                            stroke='#9CABA2'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div className='mb-8'>
                    <label
                      className='block mb-1 text-sm font-medium'
                      htmlFor=''
                    >
                      Message
                    </label>
                    <textarea
                      className='block py-2 px-4 w-full h-32 text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500 resize-none'
                      placeholder='Enter your message'
                      defaultValue={''}
                    />
                  </div>
                  <button variant="classic"
                    className='group relative flex items-center justify-center px-5 h-14 w-full text-base font-semibold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                    type='submit'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Submit</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-5/12 xl:w-1/2 px-4 mb-16 lg:mb-0'>
                <div className='flex flex-col h-full max-w-sm'>
                  <h1 className='font-heading tracking-tight text-4xl xs:text-5xl font-bold mb-4'>
                    Get in touch!
                  </h1>
                  <p className='max-w-xs text-lg text-gray-500 mb-10'>
                    Looking for more information or want to try out our paid
                    plans?
                  </p>
                  <div className='mt-auto p-8 bg-gray-50 border border-gray-200 rounded-xl'>
                    <img
                      className='block mb-8'
                      src='aurora-assets/logos/logo-aurora-dark.svg'
                      alt=''
                    />
                    <p className='font-medium'>
                      “We use Aurora daily and we’re very happy that they are
                      created this amazing bookmarking platform.”
                    </p>
                  </div>
                </div>
              </div>
              <div className='w-full lg:w-7/12 xl:w-1/2 px-4'>
                <div className='p-8 border border-gray-100 rounded-xl'>
                  <form action=''>
                    <div className='mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Name
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                        type='text'
                        placeholder='Your name'
                      />
                    </div>
                    <div className='mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Email
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                        type='email'
                        placeholder='john@email.com'
                      />
                    </div>
                    <div className='mb-8'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Message
                      </label>
                      <textarea
                        className='block py-2 px-4 w-full h-44 text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500 resize-none'
                        placeholder='Enter your message'
                        defaultValue={''}
                      />
                    </div>
                    <button variant="classic"
                      className='group relative flex items-center justify-center px-5 h-14 w-full text-base font-semibold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                      type='submit'
                    >
                      <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                      <span>Submit</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative overflow-hidden'>
          <div className='pt-24 pb-80 md:pb-52 lg:pb-24 bg-gray-800'>
            <div className='container px-4 mx-auto'>
              <div className='max-w-sm'>
                <h1 className='font-heading tracking-tight text-4xl xs:text-5xl font-bold text-white mb-4'>
                  Contact Us
                </h1>
                <p className='max-w-xs text-lg text-gray-400'>
                  Submit your info and we’ll get back to you as soon as
                  possible.
                </p>
              </div>
            </div>
          </div>
          <div className='py-12 md:py-24 lg:pb-24 bg-gray-900'>
            <div className='relative container px-4 mx-auto'>
              <div className='-mt-60 relative z-10 lg:ml-auto max-w-xl p-8 bg-gray-900 border border-gray-800 rounded-xl'>
                <form action=''>
                  <div className='flex flex-wrap -mx-4 mb-8 items-center'>
                    <div className='w-full md:w-1/2 px-4 mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        First name
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-md outline-none ring ring-transparent focus:ring-yellowGreen-800'
                        type='text'
                        placeholder='First name'
                      />
                    </div>
                    <div className='w-full md:w-1/2 px-4 mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Last name
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-md outline-none ring ring-transparent focus:ring-yellowGreen-800'
                        type='text'
                        placeholder='Last name'
                      />
                    </div>
                    <div className='w-full md:w-1/2 px-4 mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Email
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-md outline-none ring ring-transparent focus:ring-yellowGreen-800'
                        type='email'
                        placeholder='john@email.com'
                      />
                    </div>
                    <div className='w-full md:w-1/2 px-4 mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Phone number
                      </label>
                      <div className='flex h-11 bg-gray-800 border border-gray-700 rounded-md'>
                        <div className='relative'>
                          <select
                            className='relative z-10 pl-3 pr-6 w-full h-full bg-transparent focus:outline-none appearance-none cursor-pointer'
                            name=''
                            id=''
                          >
                            <option value=''>US</option>
                            <option value=''>GB</option>
                            <option value=''>EU</option>
                          </select>
                          <div className='absolute top-1/2 right-0 transform -translate-y-1/2'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M5 7.5L10 12.5L15 7.5'
                                stroke='#6C7F73'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </div>
                        </div>
                        <input
                          className='py-2 pl-2 pr-4 h-full text-gray-500 placeholder-gray-500 border-0 bg-transparent focus:outline-none'
                          type='text'
                          placeholder='+1 (555) 000-0000'
                        />
                      </div>
                    </div>
                    <div className='w-full px-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Message
                      </label>
                      <textarea
                        className='block py-2 px-4 w-full h-44 text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-md outline-none ring ring-transparent focus:ring-yellowGreen-800 resize-none'
                        placeholder='Enter your message'
                        defaultValue={''}
                      />
                    </div>
                  </div>
                  <button variant="classic"
                    className='xs:flex-shrink-0 group relative w-full mb-8 h-12 flex xs:inline-flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
                    type='submit'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Submit</span>
                  </button>
                  <p className='text-sm text-gray-500'>
                    <span className='mr-1'>
                      We process your information in accordance with our
                    </span>
                    <a
                      className='inline-block font-semibold text-yellowGreen-700 hover:text-yellowGreen-600'
                      href='#'
                    >
                      Privacy Policy
                    </a>
                  </p>
                </form>
              </div>
              <img
                className='block h-64 md:h-auto lg:h-64 xl:h-auto mt-20 lg:-mt-80 mx-auto md:mr-0 lg:mx-0'
                src='aurora-assets/contact/contact-left-images-dark.png'
                alt=''
              />
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-50'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-5/12 xl:w-1/2 px-4 mb-16 lg:mb-0'>
                <div className='flex flex-col h-full max-w-sm'>
                  <h1 className='font-heading tracking-tight text-4xl xs:text-5xl font-bold mb-4'>
                    Get in touch!
                  </h1>
                  <p className='max-w-xs text-lg text-gray-500 mb-10'>
                    Looking for more information or want to try out our paid
                    plans?
                  </p>
                  <div className='mt-auto p-8 bg-white border border-gray-200 rounded-xl'>
                    <img
                      className='block mb-8'
                      src='aurora-assets/logos/logo-aurora-dark.svg'
                      alt=''
                    />
                    <p className='font-medium'>
                      “We use Aurora daily and we’re very happy that they are
                      created this amazing bookmarking platform.”
                    </p>
                  </div>
                </div>
              </div>
              <div className='w-full lg:w-7/12 xl:w-1/2 px-4'>
                <div className='bg-white p-8 border border-gray-100 rounded-xl'>
                  <form action=''>
                    <div className='mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Name
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                        type='text'
                        placeholder='Your name'
                      />
                    </div>
                    <div className='mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Email
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                        type='email'
                        placeholder='john@email.com'
                      />
                    </div>
                    <div className='mb-8'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Message
                      </label>
                      <textarea
                        className='block py-2 px-4 w-full h-44 text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500 resize-none'
                        placeholder='Enter your message'
                        defaultValue={''}
                      />
                    </div>
                    <button variant="classic"
                      className='group relative flex items-center justify-center px-5 h-14 w-full text-base font-semibold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                      type='submit'
                    >
                      <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                      <span>Submit</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 overflow-hidden'>
          <div className='relative container px-4 mx-auto'>
            <div className='absolute top-0 left-0 w-full h-full px-4'>
              <div className='px-8 pt-96 bg-teal-900 rounded-3xl'>
                <img
                  className='block mx-auto'
                  src='aurora-assets/contact/lines-background-green.png'
                  alt=''
                />
              </div>
            </div>
            <div className='relative px-10 py-16'>
              <div className='max-w-lg mb-12 mx-auto text-center'>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                  Get in touch with us for more information
                </h1>
                <p className='text-lg text-gray-400'>
                  If you need help or have a question, we’re here for you
                </p>
              </div>
              <div className='max-w-xl mx-auto p-8 bg-white border border-gray-100 rounded-xl'>
                <form action=''>
                  <div className='mb-4'>
                    <label
                      className='block mb-1 text-sm font-medium'
                      htmlFor=''
                    >
                      Name
                    </label>
                    <input
                      className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                      type='text'
                      placeholder='Your name'
                    />
                  </div>
                  <div className='mb-4'>
                    <label
                      className='block mb-1 text-sm font-medium'
                      htmlFor=''
                    >
                      Email
                    </label>
                    <input
                      className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                      type='email'
                      placeholder='john@email.com'
                    />
                  </div>
                  <div className='mb-4'>
                    <label
                      className='block mb-1 text-sm font-medium'
                      htmlFor=''
                    >
                      Subject
                    </label>
                    <div className='relative w-full'>
                      <select
                        className='relative z-10 py-2 px-4 h-11 w-full cursor-pointer bg-transparent text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 outline-none ring ring-transparent focus:ring-yellowGreen-500 rounded-md appearance-none'
                        name=''
                        id=''
                      >
                        <option value=''>Select subject</option>
                        <option value=''>Marketing</option>
                        <option value=''>Development</option>
                      </select>
                      <span className='absolute top-1/2 right-0 mr-3 transform -translate-y-1/2'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M5 7.5L10 12.5L15 7.5'
                            stroke='#9CABA2'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div className='mb-8'>
                    <label
                      className='block mb-1 text-sm font-medium'
                      htmlFor=''
                    >
                      Message
                    </label>
                    <textarea
                      className='block py-2 px-4 w-full h-32 text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500 resize-none'
                      placeholder='Enter your message'
                      defaultValue={''}
                    />
                  </div>
                  <button variant="classic"
                    className='group relative flex items-center justify-center px-5 h-14 w-full text-base font-semibold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                    type='submit'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Submit</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-5/12 xl:w-1/2 px-4 mb-16 lg:mb-0'>
                <div className='flex flex-col h-full max-w-sm'>
                  <h1 className='font-heading tracking-tight text-4xl xs:text-5xl font-bold text-white mb-4'>
                    Get in touch!
                  </h1>
                  <p className='max-w-xs text-lg text-gray-400 mb-10'>
                    Looking for more information or want to try out our paid
                    plans?
                  </p>
                  <div className='mt-auto p-8 bg-white rounded-xl'>
                    <img
                      className='block mb-8'
                      src='aurora-assets/logos/logo-aurora-dark.svg'
                      alt=''
                    />
                    <p className='font-medium'>
                      “We use Aurora daily and we’re very happy that they are
                      created this amazing bookmarking platform.”
                    </p>
                  </div>
                </div>
              </div>
              <div className='w-full lg:w-7/12 xl:w-1/2 px100-4'>
                <div className='p-8 bg-white border border-gray-100 rounded-xl'>
                  <form action=''>
                    <div className='mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Name
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                        type='text'
                        placeholder='Your name'
                      />
                    </div>
                    <div className='mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Email
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                        type='email'
                        placeholder='john@email.com'
                      />
                    </div>
                    <div className='mb-8'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Message
                      </label>
                      <textarea
                        className='block py-2 px-4 w-full h-44 text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500 resize-none'
                        placeholder='Enter your message'
                        defaultValue={''}
                      />
                    </div>
                    <button variant="classic"
                      className='group relative flex items-center justify-center px-5 h-14 w-full p-px text-base font-semibold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                      type='submit'
                    >
                      <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                      <span>Submit</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-gray-900 overflow-hidden'>
          <div className='relative container px-4 mx-auto'>
            <div className='absolute top-0 left-0 w-full h-full px-4'>
              <div className='px-8 pt-96 bg-gray-800 rounded-3xl'>
                <img
                  className='block mx-auto'
                  src='aurora-assets/contact/lines-background-green.png'
                  alt=''
                />
              </div>
            </div>
            <div className='relative px-10 py-16'>
              <div className='max-w-lg mb-12 mx-auto text-center'>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                  Get in touch with us for more information
                </h1>
                <p className='text-lg text-gray-400'>
                  If you need help or have a question, we’re here for you
                </p>
              </div>
              <div className='max-w-xl mx-auto p-8 bg-gray-900 border border-gray-700 rounded-xl'>
                <form action=''>
                  <div className='mb-4'>
                    <label
                      className='block mb-1 text-sm font-medium'
                      htmlFor=''
                    >
                      Name
                    </label>
                    <input
                      className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-md outline-none ring ring-transparent focus:ring-yellowGreen-800'
                      type='text'
                      placeholder='Your name'
                    />
                  </div>
                  <div className='mb-4'>
                    <label
                      className='block mb-1 text-sm font-medium'
                      htmlFor=''
                    >
                      Email
                    </label>
                    <input
                      className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-md outline-none ring ring-transparent focus:ring-yellowGreen-800'
                      type='email'
                      placeholder='john@email.com'
                    />
                  </div>
                  <div className='mb-4'>
                    <label
                      className='block mb-1 text-sm font-medium'
                      htmlFor=''
                    >
                      Subject
                    </label>
                    <div className='relative w-full bg-gray-800'>
                      <select
                        className='relative z-10 py-2 px-4 h-11 w-full cursor-pointer bg-transparent text-gray-500 placeholder-gray-500 border border-gray-700 focus:border-yellowGreen-800 rounded-md outline-none ring ring-transparent focus:ring-yellowGreen-800 appearance-none'
                        name=''
                        id=''
                      >
                        <option value=''>Select subject</option>
                        <option value=''>Marketing</option>
                        <option value=''>Development</option>
                      </select>
                      <span className='absolute top-1/2 right-0 mr-3 transform -translate-y-1/2'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M5 7.5L10 12.5L15 7.5'
                            stroke='#9CABA2'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div className='mb-8'>
                    <label
                      className='block mb-1 text-sm font-medium'
                      htmlFor=''
                    >
                      Message
                    </label>
                    <textarea
                      className='block py-2 px-4 w-full h-32 text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-md outline-none ring ring-transparent focus:ring-yellowGreen-800 resize-none'
                      placeholder='Enter your message'
                      defaultValue={''}
                    />
                  </div>
                  <button variant="classic"
                    className='xs:flex-shrink-0 group relative flex w-full h-14 xs:inline-flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
                    type='submit'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Submit</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className='relative overflow-hidden'>
          <div className='flex flex-wrap'>
            <div className='relative w-full md:w-5/12 pt-12 pb-52 sm:pb-64 md:pb-80 lg:pb-0 md:pt-24 px-6 lg:pr-10 bg-radial-dark-green'>
              <div className='mx-auto lg:mr-0 max-w-sm'>
                <h1 className='font-heading tracking-tight text-4xl xs:text-5xl font-bold text-white mb-4'>
                  Get in touch!
                </h1>
                <p className='max-w-xs text-lg text-gray-400'>
                  Looking for more information or want to try out our paid
                  plans?
                </p>
              </div>
              <img
                className='absolute bottom-0 left-0'
                src='aurora-assets/contact/line-half-line-gradient.png'
                alt=''
              />
            </div>
            <div className='w-full md:w-7/12 py-12 md:py-24 px-6 md:pl-12 xl:pl-24 bg-gray-900'>
              <div className='max-w-lg mx-auto lg:mx-0 lg:max-w-2xl'>
                <form action=''>
                  <div className='flex flex-wrap -mx-4 mb-8 items-center'>
                    <div className='w-full lg:w-1/2 px-4 mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium text-white'
                        htmlFor=''
                      >
                        First name
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-md outline-none ring ring-transparent focus:ring-yellowGreen-800'
                        type='text'
                        placeholder='First name'
                      />
                    </div>
                    <div className='w-full lg:w-1/2 px-4 mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium text-white'
                        htmlFor=''
                      >
                        Last name
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-md outline-none ring ring-transparent focus:ring-yellowGreen-800'
                        type='text'
                        placeholder='Last name'
                      />
                    </div>
                    <div className='w-full lg:w-1/2 px-4 mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium text-white'
                        htmlFor=''
                      >
                        Email
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-md outline-none ring ring-transparent focus:ring-yellowGreen-800'
                        type='email'
                        placeholder='john@email.com'
                      />
                    </div>
                    <div className='w-full lg:w-1/2 px-4 mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium text-white'
                        htmlFor=''
                      >
                        Phone number
                      </label>
                      <div className='flex h-11 bg-gray-800 border border-gray-700 rounded-md'>
                        <div className='relative'>
                          <select
                            className='relative z-10 pl-3 pr-6 w-full h-full text-gray-500 bg-transparent focus:outline-none appearance-none cursor-pointer'
                            name=''
                            id=''
                          >
                            <option value=''>US</option>
                            <option value=''>GB</option>
                            <option value=''>EU</option>
                          </select>
                          <div className='absolute top-1/2 right-0 transform -translate-y-1/2'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M5 7.5L10 12.5L15 7.5'
                                stroke='#6C7F73'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </div>
                        </div>
                        <input
                          className='py-2 pl-2 pr-4 h-full text-gray-500 placeholder-gray-500 border-0 bg-transparent focus:outline-none'
                          type='text'
                          placeholder='+1 (555) 000-0000'
                        />
                      </div>
                    </div>
                    <div className='w-full px-4'>
                      <label
                        className='block mb-1 text-sm font-medium text-white'
                        htmlFor=''
                      >
                        Message
                      </label>
                      <textarea
                        className='block py-2 px-4 w-full h-44 text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-md outline-none ring ring-transparent focus:ring-yellowGreen-800 resize-none'
                        placeholder='Enter your message'
                        defaultValue={''}
                      />
                    </div>
                  </div>
                  <button variant="classic"
                    className='group relative inline-flex items-center justify-center h-12 w-32 px-5 text-center text-base text-teal-800 font-bold bg-white rounded-lg transition duration-300'
                    type='submit'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-400 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Submit</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className='relative overflow-hidden'>
          <div className='flex flex-wrap'>
            <div className='relative w-full md:w-5/12 pt-12 pb-52 sm:pb-64 md:pb-80 lg:pb-0 md:pt-24 px-6 lg:pr-10 bg-gray-800'>
              <div className='mx-auto lg:mr-0 max-w-sm'>
                <h1 className='font-heading tracking-tight text-4xl xs:text-5xl font-bold text-white mb-4'>
                  Get in touch!
                </h1>
                <p className='max-w-xs text-lg text-gray-400'>
                  Looking for more information or want to try out our paid
                  plans?
                </p>
              </div>
              <img
                className='absolute bottom-0 left-0'
                src='aurora-assets/contact/line-half-circle-dark.png'
                alt=''
              />
            </div>
            <div className='w-full md:w-7/12 py-12 md:py-24 px-6 md:pl-12 xl:pl-24 bg-gray-900'>
              <div className='max-w-lg mx-auto lg:mx-0 lg:max-w-2xl'>
                <form action=''>
                  <div className='flex flex-wrap -mx-4 mb-8 items-center'>
                    <div className='w-full lg:w-1/2 px-4 mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        First name
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-md outline-none ring ring-transparent focus:ring-yellowGreen-800'
                        type='text'
                        placeholder='First name'
                      />
                    </div>
                    <div className='w-full lg:w-1/2 px-4 mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Last name
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-md outline-none ring ring-transparent focus:ring-yellowGreen-800'
                        type='text'
                        placeholder='Last name'
                      />
                    </div>
                    <div className='w-full lg:w-1/2 px-4 mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Email
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-md outline-none ring ring-transparent focus:ring-yellowGreen-800'
                        type='email'
                        placeholder='john@email.com'
                      />
                    </div>
                    <div className='w-full lg:w-1/2 px-4 mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Phone number
                      </label>
                      <div className='flex h-11 bg-gray-800 border border-gray-700 rounded-md'>
                        <div className='relative'>
                          <select
                            className='relative z-10 pl-3 pr-6 w-full h-full text-gray-500 bg-transparent focus:outline-none appearance-none cursor-pointer'
                            name=''
                            id=''
                          >
                            <option value=''>US</option>
                            <option value=''>GB</option>
                            <option value=''>EU</option>
                          </select>
                          <div className='absolute top-1/2 right-0 transform -translate-y-1/2'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M5 7.5L10 12.5L15 7.5'
                                stroke='#6C7F73'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </div>
                        </div>
                        <input
                          className='py-2 pl-2 pr-4 h-full text-gray-500 placeholder-gray-500 border-0 bg-transparent focus:outline-none'
                          type='text'
                          placeholder='+1 (555) 000-0000'
                        />
                      </div>
                    </div>
                    <div className='w-full px-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Message
                      </label>
                      <textarea
                        className='block py-2 px-4 w-full h-44 text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-md outline-none ring ring-transparent focus:ring-yellowGreen-800 resize-none'
                        placeholder='Enter your message'
                        defaultValue={''}
                      />
                    </div>
                  </div>
                  <button variant="classic"
                    className='group relative inline-flex items-center justify-center h-12 w-32 px-5 text-center text-base text-teal-800 font-bold bg-white rounded-lg transition duration-300'
                    type='submit'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-400 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Submit</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className='relative overflow-hidden'>
          <div className='flex flex-wrap'>
            <div className='relative w-full md:w-5/12 pt-12 pb-52 sm:pb-64 md:pb-80 lg:pb-0 md:pt-24 px-6 lg:pr-10 bg-gray-800'>
              <div className='mx-auto lg:mr-0 max-w-sm'>
                <h1 className='font-heading tracking-tight text-4xl xs:text-5xl font-bold text-white mb-4'>
                  Get in touch!
                </h1>
                <p className='max-w-xs text-lg text-gray-400'>
                  Looking for more information or want to try out our paid
                  plans?
                </p>
              </div>
              <img
                className='absolute bottom-0 left-0'
                src='aurora-assets/contact/line-half-circle-dark.png'
                alt=''
              />
            </div>
            <div className='w-full md:w-7/12 py-12 md:py-24 px-6 md:pl-12 xl:pl-24 bg-gray-900'>
              <div className='max-w-lg mx-auto lg:mx-0 lg:max-w-2xl'>
                <form action=''>
                  <div className='flex flex-wrap -mx-4 mb-8 items-center'>
                    <div className='w-full lg:w-1/2 px-4 mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        First name
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-md outline-none ring ring-transparent focus:ring-yellowGreen-800'
                        type='text'
                        placeholder='First name'
                      />
                    </div>
                    <div className='w-full lg:w-1/2 px-4 mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Last name
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-md outline-none ring ring-transparent focus:ring-yellowGreen-800'
                        type='text'
                        placeholder='Last name'
                      />
                    </div>
                    <div className='w-full lg:w-1/2 px-4 mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Email
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-md outline-none ring ring-transparent focus:ring-yellowGreen-800'
                        type='email'
                        placeholder='john@email.com'
                      />
                    </div>
                    <div className='w-full lg:w-1/2 px-4 mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Phone number
                      </label>
                      <div className='flex h-11 bg-gray-800 border border-gray-700 rounded-md'>
                        <div className='relative'>
                          <select
                            className='relative z-10 pl-3 pr-6 w-full h-full text-gray-500 bg-transparent focus:outline-none appearance-none cursor-pointer'
                            name=''
                            id=''
                          >
                            <option value=''>US</option>
                            <option value=''>GB</option>
                            <option value=''>EU</option>
                          </select>
                          <div className='absolute top-1/2 right-0 transform -translate-y-1/2'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M5 7.5L10 12.5L15 7.5'
                                stroke='#6C7F73'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </div>
                        </div>
                        <input
                          className='py-2 pl-2 pr-4 h-full text-gray-500 placeholder-gray-500 border-0 bg-transparent focus:outline-none'
                          type='text'
                          placeholder='+1 (555) 000-0000'
                        />
                      </div>
                    </div>
                    <div className='w-full px-4'>
                      <label
                        className='block mb-1 text-sm font-medium'
                        htmlFor=''
                      >
                        Message
                      </label>
                      <textarea
                        className='block py-2 px-4 w-full h-44 text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-md outline-none ring ring-transparent focus:ring-yellowGreen-800 resize-none'
                        placeholder='Enter your message'
                        defaultValue={''}
                      />
                    </div>
                  </div>
                  <button variant="classic"
                    className='group relative inline-flex items-center justify-center h-12 w-32 px-5 text-center text-base text-teal-800 font-bold bg-white rounded-lg transition duration-300'
                    type='submit'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-400 animate-pulse group-hover:ring-0 transition duration-300' />
                    <span>Submit</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-5/12 xl:w-1/2 px-4 mb-16 lg:mb-0'>
                <div className='flex flex-col h-full max-w-sm'>
                  <h1 className='font-heading tracking-tight text-4xl xs:text-5xl font-bold text-white mb-4'>
                    Get in touch!
                  </h1>
                  <p className='max-w-xs text-lg text-gray-400 mb-10'>
                    Looking for more information or want to try out our paid
                    plans?
                  </p>
                  <div className='mt-auto p-8 border border-gray-700 rounded-xl'>
                    <img
                      className='block mb-8'
                      src='aurora-assets/logos/logo-aurora-white.svg'
                      alt=''
                    />
                    <p className='font-medium text-white'>
                      “We use Aurora daily and we’re very happy that they are
                      created this amazing bookmarking platform.”
                    </p>
                  </div>
                </div>
              </div>
              <div className='w-full lg:w-7/12 xl:w-1/2 px100-4'>
                <div className='p-8 border border-gray-700 rounded-xl'>
                  <form action=''>
                    <div className='mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium text-white'
                        htmlFor=''
                      >
                        Name
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                        type='text'
                        placeholder='Your name'
                      />
                    </div>
                    <div className='mb-4'>
                      <label
                        className='block mb-1 text-sm font-medium text-white'
                        htmlFor=''
                      >
                        Email
                      </label>
                      <input
                        className='py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                        type='email'
                        placeholder='john@email.com'
                      />
                    </div>
                    <div className='mb-8'>
                      <label
                        className='block mb-1 text-sm font-medium text-white'
                        htmlFor=''
                      >
                        Message
                      </label>
                      <textarea
                        className='block py-2 px-4 w-full h-44 text-gray-500 placeholder-gray-500 bg-gray-800 border border-gray-700 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500 resize-none'
                        placeholder='Enter your message'
                        defaultValue={''}
                      />
                    </div>
                    <button variant="classic"
                      className='xs:flex-shrink-0 group relative flex w-full h-14 xs:inline-flex items-center justify-center px-4 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
                      type='submit'
                    >
                      <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                      <span>Submit</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    <FooterComponent /></React.Fragment>
  );
}

