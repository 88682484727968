import React from 'react';

import {FooterComponent} from './Footers.jsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Features2() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='relative py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <div className='relative pb-24 border-b border-yellowGreen-400 overflow-hidden'>
              <div className='max-w-2xl mx-auto mb-24 text-center'>
                <span className='inline-flex items-center h-6 mb-6 px-2 text-xs uppercase font-medium text-yellowGreen-700 bg-yellowGreen-200 rounded-full'>
                  Feature
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold'>
                  Easily organize your files
                </h1>
              </div>
              <div className='flex flex-wrap -mx-4 -mb-16'>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-gray-50'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2 11.4V4.6C2 4.26863 2.26863 4 2.6 4H11.0122C11.2128 4 11.4002 4.10026 11.5115 4.26718L13.7781 7.66718C13.9125 7.86872 13.9125 8.13128 13.7781 8.33282L11.5115 11.7328C11.4002 11.8997 11.2128 12 11.0122 12H2.6C2.26863 12 2 11.7314 2 11.4Z'
                          stroke='#041109'
                          strokeWidth='1.5'
                        />
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold mb-1'>Auto-tagging</h5>
                      <p className='text-lg text-gray-500'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-gray-50'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21659)'>
                          <path
                            d='M2 7.33332V3.26666C2 2.93529 2.26863 2.66666 2.6 2.66666H6.44471C6.58794 2.66666 6.72644 2.71789 6.83519 2.8111L8.83148 4.52221C8.94023 4.61542 9.07873 4.66666 9.22195 4.66666H14.7333C15.0647 4.66666 15.3333 4.93529 15.3333 5.26666V7.33332M2 7.33332V12.7333C2 13.0647 2.26863 13.3333 2.6 13.3333H14.7333C15.0647 13.3333 15.3333 13.0647 15.3333 12.7333V7.33332M2 7.33332H15.3333'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold mb-1'>
                        Categorized by collections
                      </h5>
                      <p className='text-lg text-gray-500'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-gray-50'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21666)'>
                          <path
                            d='M9.66602 7.99841C9.66602 6.33726 8.12137 4.66666 6.23744 4.66666C6.01429 4.66666 5.27871 4.66666 5.09459 4.66666C3.20104 4.66666 1.66602 6.15833 1.66602 7.99841C1.66602 9.58402 2.80583 10.9109 4.33268 11.2476C4.57772 11.3016 4.83272 11.3302 5.09459 11.3302'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7 7.99842C7 9.65957 8.54464 11.3302 10.4286 11.3302C10.6517 11.3302 11.3873 11.3302 11.5714 11.3302C13.465 11.3302 15 9.83849 15 7.99842C15 6.41281 13.8602 5.08591 12.3333 4.74923C12.0883 4.6952 11.8333 4.66667 11.5714 4.66667'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold mb-1'>Share links</h5>
                      <p className='text-lg text-gray-500'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-gray-50'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21674)'>
                          <path
                            d='M0.666992 13.3333V12.6667C0.666992 10.0893 2.75633 8 5.33366 8V8C7.91099 8 10.0003 10.0893 10.0003 12.6667V13.3333'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                          />
                          <path
                            d='M8.66699 9.33333V9.33333C8.66699 7.49238 10.1594 6 12.0003 6V6C13.8413 6 15.3337 7.49239 15.3337 9.33334V9.66667'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                          />
                          <path
                            d='M5.33366 8C6.80642 8 8.00033 6.8061 8.00033 5.33334C8.00033 3.86058 6.80642 2.66667 5.33366 2.66667C3.8609 2.66667 2.66699 3.86058 2.66699 5.33334C2.66699 6.8061 3.8609 8 5.33366 8Z'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M12 6C13.1046 6 14 5.10457 14 4C14 2.89543 13.1046 2 12 2C10.8954 2 10 2.89543 10 4C10 5.10457 10.8954 6 12 6Z'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold mb-1'>
                        Invite team members
                      </h5>
                      <p className='text-lg text-gray-500'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-gray-50'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M14.667 2.6V13.4C14.667 13.7314 14.3984 14 14.067 14H3.26699C2.93562 14 2.66699 13.7314 2.66699 13.4V2.6C2.66699 2.26863 2.93562 2 3.26699 2H14.067C14.3984 2 14.667 2.26863 14.667 2.6Z'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M7.56468 5.84629C7.16468 5.61772 6.66699 5.90654 6.66699 6.36724V9.63275C6.66699 10.0934 7.16468 10.3823 7.56468 10.1537L10.422 8.52094C10.8251 8.29061 10.8251 7.70939 10.422 7.47905L7.56468 5.84629Z'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold mb-1'>
                        Video live preview
                      </h5>
                      <p className='text-lg text-gray-500'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-gray-50'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21688)'>
                          <path
                            d='M5.33203 12L7.33203 14L10.6654 10.6667'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M13.3327 11.7382C14.3285 11.3481 15.3327 10.4593 15.3327 8.66666C15.3327 6 13.1105 5.33333 11.9993 5.33333C11.9993 3.99999 11.9993 1.33333 7.99935 1.33333C3.99935 1.33333 3.99935 3.99999 3.99935 5.33333C2.88824 5.33333 0.666016 6 0.666016 8.66666C0.666016 10.4593 1.6702 11.3481 2.66602 11.7382'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold mb-1'>
                        100GB cloud storage
                      </h5>
                      <p className='text-lg text-gray-500'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <img
                className='absolute bottom-0 left-1/2 transform -translate-x-1/2 xs:-mb-10 md:-mb-20 lg:-mb-32 block'
                src='aurora-assets/features/green-light-shadow.png'
                alt=''
              />
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <div className='xs:max-w-sm md:max-w-sm lg:max-w-4xl mx-auto text-center mb-24'>
                <span className='inline-flex items-center h-6 mb-4 px-2 text-xs uppercase font-medium text-yellowGreen-700 bg-yellowGreen-200 rounded-full'>
                  HOW IT WORKS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-4'>
                  Organize assets in 15 seconds
                </h1>
                <p className='text-lg text-gray-500'>
                  Using our platform is easy and straightforward. Here's how it
                  works:
                </p>
              </div>
              <div className='relative flex flex-wrap items-center'>
                <img
                  className='hidden md:block absolute top-0 left-0 mt-7 w-full'
                  src='aurora-assets/how-it-works/line-gray.svg'
                  alt=''
                />
                <div className='relative w-full md:w-1/3 px-4 mb-16 md:mb-0'>
                  <div className='text-center max-w-xs px-6 md:px-0 mx-auto md:mx-0'>
                    <div className='flex-shrink-0 inline-flex w-14 h-14 mx-auto mb-8 items-center justify-center text-xl font-bold bg-gray-50 border border-gray-200 rounded-full transition duration-200'>
                      1
                    </div>
                    <h6 className='text-xl font-bold mb-1'>Sign up</h6>
                    <p className='text-sm leading-snug text-gray-500'>
                      Create an account by simply setting up your email and
                      password.
                    </p>
                  </div>
                </div>
                <div className='relative w-full md:w-1/3 px-4 mb-16 md:mb-0'>
                  <div className='text-center max-w-xs px-6 md:px-0 mx-auto md:mx-0'>
                    <div className='flex-shrink-0 inline-flex w-14 h-14 mx-auto mb-8 items-center justify-center text-xl font-bold bg-gray-50 border border-gray-200 rounded-full transition duration-200'>
                      2
                    </div>
                    <h6 className='text-xl font-bold mb-1'>Explore</h6>
                    <p className='text-sm leading-snug text-gray-500'>
                      Browse through our intuitive interface to discover the
                      features.
                    </p>
                  </div>
                </div>
                <div className='relative w-full md:w-1/3 px-4'>
                  <div className='text-center max-w-xs px-6 md:px-0 mx-auto md:mx-0'>
                    <div className='flex-shrink-0 inline-flex w-14 h-14 mx-auto mb-8 items-center justify-center text-xl font-bold bg-gray-50 border border-gray-200 rounded-full transition duration-200'>
                      3
                    </div>
                    <h6 className='text-xl font-bold mb-1'>
                      Upload &amp; Organize
                    </h6>
                    <p className='text-sm leading-snug text-gray-500'>
                      Drag and drop your assets and organize it into collections
                      to keep it neat.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-50'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-7xl mx-auto'>
              <div className='text-center mb-24'>
                <span className='inline-flex items-center h-6 mb-4 px-2 text-xs uppercase font-medium text-gray-700 bg-gray-100 rounded-full'>
                  HOW IT WORKS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold'>
                  Organize assets in 15 seconds
                </h1>
              </div>
              <div className='flex flex-wrap items-center -mx-4 mb-8'>
                <div className='w-full lg:w-1/3 px-4 mb-4 lg:mb-0'>
                  <button className='group flex items-start text-left p-4 rounded-xl bg-transparent hover:bg-white focus:outline-none transition duration-200'>
                    <div className='flex-shrink-0 inline-flex w-8 h-8 mr-3 items-center justify-center font-bold bg-gray-100 rounded-full transition duration-200'>
                      1
                    </div>
                    <p className='text-lg'>
                      <span className='text-gray-900 font-bold'>Sign up</span>
                      <span className='text-gray-500'>
                        - Setup an account with email and password.
                      </span>
                    </p>
                  </button>
                </div>
                <div className='w-full lg:w-1/3 px-4 mb-4 lg:mb-0'>
                  <button className='group flex items-start text-left p-4 rounded-xl bg-white focus:outline-none transition duration-200'>
                    <div className='flex-shrink-0 inline-flex w-8 h-8 mr-3 items-center justify-center font-bold bg-gray-100 rounded-full transition duration-200'>
                      2
                    </div>
                    <p className='text-lg'>
                      <span className='text-gray-900 font-bold'>Explore</span>
                      <span className='text-gray-500'>
                        - Browse our interface to discover the features.
                      </span>
                    </p>
                  </button>
                </div>
                <div className='w-full lg:w-1/3 px-4'>
                  <button className='group flex items-start text-left p-4 rounded-xl bg-transparent hover:bg-white focus:outline-none transition duration-200'>
                    <div className='flex-shrink-0 inline-flex w-8 h-8 mr-3 items-center justify-center font-bold bg-gray-100 rounded-full transition duration-200'>
                      3
                    </div>
                    <p className='text-lg'>
                      <span className='text-gray-900 font-bold'>
                        Upload &amp; Organize
                      </span>
                      <span className='text-gray-500'>
                        - Drag &amp; drop your assets and organize it.
                      </span>
                    </p>
                  </button>
                </div>
              </div>
              <img
                className='block w-full mx-auto'
                src='aurora-assets/how-it-works/image-center.png'
                alt=''
              />
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-7xl mx-auto'>
              <div className='text-center mb-24'>
                <span className='inline-flex items-center h-6 mb-4 px-2 text-xs uppercase font-medium text-white bg-teal-800 rounded-full'>
                  HOW IT WORKS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white'>
                  Organize assets in 15 seconds
                </h1>
              </div>
              <div className='flex flex-wrap items-center -mx-4 mb-8'>
                <div className='w-full lg:w-1/3 px-4 mb-4 lg:mb-0'>
                  <button className='group flex items-start text-left p-4 rounded-xl bg-transparent hover:bg-white hover:bg-opacity-10 focus:outline-none transition duration-200'>
                    <div className='flex-shrink-0 inline-flex w-8 h-8 mr-3 items-center justify-center text-white font-bold bg-white bg-opacity-10 rounded-full transition duration-200'>
                      1
                    </div>
                    <p className='text-lg'>
                      <span className='text-white font-bold'>Sign up</span>
                      <span className='text-gray-400'>
                        - Setup an account with email and password.
                      </span>
                    </p>
                  </button>
                </div>
                <div className='w-full lg:w-1/3 px-4 mb-4 lg:mb-0'>
                  <button className='group flex items-start text-left p-4 rounded-xl bg-white bg-opacity-10 focus:outline-none transition duration-200'>
                    <div className='flex-shrink-0 inline-flex w-8 h-8 mr-3 items-center justify-center text-white font-bold bg-white bg-opacity-10 rounded-full transition duration-200'>
                      2
                    </div>
                    <p className='text-lg'>
                      <span className='text-white font-bold'>Explore</span>
                      <span className='text-gray-400'>
                        - Browse our interface to discover the features.
                      </span>
                    </p>
                  </button>
                </div>
                <div className='w-full lg:w-1/3 px-4'>
                  <button className='group flex items-start text-left p-4 rounded-xl bg-transparent hover:bg-white hover:bg-opacity-10 focus:outline-none transition duration-200'>
                    <div className='flex-shrink-0 inline-flex w-8 h-8 mr-3 items-center justify-center text-white font-bold bg-white bg-opacity-10 rounded-full transition duration-200'>
                      3
                    </div>
                    <p className='text-lg'>
                      <span className='text-white font-bold'>
                        Upload &amp; Organize
                      </span>
                      <span className='text-gray-400'>
                        - Drag &amp; drop your assets and organize it.
                      </span>
                    </p>
                  </button>
                </div>
              </div>
              <img
                className='block w-full mx-auto'
                src='aurora-assets/how-it-works/image-center-gradient.png'
                alt=''
              />
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-50'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <div className='max-w-4xl mb-24'>
                <span className='inline-flex items-center h-6 mb-4 px-2 text-xs uppercase font-medium text-gray-700 bg-gray-100 rounded-full'>
                  HOW IT WORKS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-4'>
                  We revolutionized the way we manage digital content
                </h1>
                <p className='text-lg text-gray-500'>
                  Using our platform is easy and straightforward. Here's how it
                  works:
                </p>
              </div>
              <div className='flex flex-wrap items-center'>
                <div className='w-full md:w-1/3 md:pr-4 mb-4 md:mb-0'>
                  <div className='md:flex items-center'>
                    <div className='w-full max-w-xs mx-auto md:max-w-none md:mr-4 p-px rounded-xl bg-gradient-to-br from-cyanGreen-800 to-cyan-800'>
                      <div className='p-4 rounded-xl bg-white'>
                        <div className='flex-shrink-0 inline-flex w-10 h-10 mb-4 items-center justify-center font-bold bg-gray-100 border border-gray-200 rounded-full transition duration-200'>
                          1
                        </div>
                        <p className='max-w-xs font-medium'>
                          Create an account by setting up your email and
                          password.
                        </p>
                      </div>
                    </div>
                    <span className='hidden md:inline-block'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 4L10 8L6 12'
                          stroke='#9CABA2'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <div className='flex items justify-center transform rotate-90 mt-4 md:hidden'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 4L10 8L6 12'
                          stroke='#9CABA2'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/3 md:pr-4 mb-4 md:mb-0'>
                  <div className='md:flex items-center'>
                    <div className='w-full max-w-xs mx-auto md:max-w-none md:mr-4 p-px rounded-xl border border-gray-200'>
                      <div className='p-4 rounded-xl bg-white'>
                        <div className='flex-shrink-0 inline-flex w-10 h-10 mb-4 items-center justify-center font-bold bg-gray-100 border border-gray-200 rounded-full transition duration-200'>
                          2
                        </div>
                        <p className='max-w-xs font-medium text-gray-500'>
                          Browse through our intuitive interface to discover the
                          features.
                        </p>
                      </div>
                    </div>
                    <span className='hidden md:inline-block'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 4L10 8L6 12'
                          stroke='#9CABA2'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <div className='flex items justify-center transform rotate-90 mt-4 md:hidden'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 4L10 8L6 12'
                          stroke='#9CABA2'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/3 xl:pr-8'>
                  <div className='w-full max-w-xs mx-auto md:max-w-none p-px rounded-xl border border-gray-200'>
                    <div className='p-4 rounded-xl bg-white'>
                      <div className='flex-shrink-0 inline-flex w-10 h-10 mb-4 items-center justify-center font-bold bg-gray-100 border border-gray-200 rounded-full transition duration-200'>
                        3
                      </div>
                      <p className='max-w-xs font-medium text-gray-500'>
                        Drag &amp; drop your assets, organize it into
                        collections to keep it neat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <div className='xs:max-w-sm md:max-w-sm lg:max-w-4xl mx-auto text-center mb-24'>
                <span className='inline-flex items-center h-6 mb-4 px-2 text-xs uppercase font-medium text-white bg-teal-800 rounded-full'>
                  HOW IT WORKS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                  Organize assets in 15 seconds
                </h1>
                <p className='text-lg text-gray-200'>
                  Using our platform is easy and straightforward. Here's how it
                  works:
                </p>
              </div>
              <div className='flex flex-wrap -mx-4 md:-mx-6 items-center'>
                <div className='w-full lg:w-1/3 px-4 md:px-6 mb-16 lg:mb-0'>
                  <div className='max-w-xs mx-auto lg:mx-0'>
                    <img
                      className='block w-full mb-8'
                      src='aurora-assets/how-it-works/image-gradient-opacity-1.png'
                      alt=''
                    />
                    <div className='flex'>
                      <div className='flex-shrink-0 inline-flex w-8 h-8 items-center justify-center text-base font-bold text-white bg-white bg-opacity-10 rounded-full transition duration-200'>
                        1
                      </div>
                      <div className='ml-3'>
                        <h6 className='text-xl font-bold text-white mb-1'>
                          Sign up
                        </h6>
                        <p className='text-lg text-gray-200'>
                          Create an account by simply setting up your email and
                          password.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4 md:px-6 mb-16 lg:mb-0'>
                  <div className='max-w-xs mx-auto lg:mx-0'>
                    <img
                      className='block w-full mb-8'
                      src='aurora-assets/how-it-works/image-gradient-opacity-2.png'
                      alt=''
                    />
                    <div className='flex'>
                      <div className='flex-shrink-0 inline-flex w-8 h-8 items-center justify-center text-base font-bold text-white bg-white bg-opacity-10 rounded-full transition duration-200'>
                        2
                      </div>
                      <div className='ml-3'>
                        <h6 className='text-xl font-bold text-white mb-1'>
                          Explore
                        </h6>
                        <p className='text-lg text-gray-200'>
                          Browse through our intuitive interface to discover the
                          features.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4 md:px-6'>
                  <div className='max-w-xs mx-auto lg:mx-0'>
                    <img
                      className='block w-full mb-8'
                      src='aurora-assets/how-it-works/image-gradient-opacity-3.png'
                      alt=''
                    />
                    <div className='flex'>
                      <div className='flex-shrink-0 inline-flex w-8 h-8 items-center justify-center text-base font-bold text-white bg-white bg-opacity-10 rounded-full transition duration-200'>
                        3
                      </div>
                      <div className='ml-3'>
                        <h6 className='text-xl font-bold text-white mb-1'>
                          Upload &amp; Organize
                        </h6>
                        <p className='text-lg text-gray-200'>
                          Drag and drop your assets and organize it into
                          collections to keep it neat.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-2xl mx-auto text-center mb-24'>
              <span className='inline-flex items-center h-6 mb-4 px-2 text-xs uppercase font-medium text-white bg-teal-800 rounded-full'>
                HOW IT WORKS
              </span>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white'>
                We revolutionized the way we manage digital content
              </h1>
            </div>
            <div className='max-w-lg lg:max-w-5xl xl:max-w-7xl mx-auto'>
              <div className='px-6 md:px-10 xl:px-18 py-10 bg-white bg-opacity-10 rounded-xl'>
                <div className='flex flex-wrap items-center -mx-4'>
                  <div className='w-full lg:w-1/2 px-4 mb-12 lg:mb-0'>
                    <div className='max-w-lg mx-auto'>
                      <button className='group flex items-center text-left pl-6 py-6 pr-10 rounded-xl hover:bg-white hover:bg-opacity-10 border border-transparent hover:border-white hover:border-opacity-20 focus:outline-none transition duration-100'>
                        <div className='relative flex-shrink-0 inline-flex w-10 h-10 mr-3 items-center justify-center font-bold text-white bg-white bg-opacity-10 border border-white border-opacity-20 rounded-full transition duration-200'>
                          <span className='relative z-10 text-white'>1</span>
                          <img
                            className='hidden group-hover:block absolute top-0 left-0 w-full h-full'
                            src='aurora-assets/how-it-works/bg-gradient-circle.svg'
                            alt=''
                          />
                        </div>
                        <p>
                          <span className='text-white font-bold'>Sign up</span>
                          <span className='text-gray-200'>
                            - Create an account by simply setting up your email
                            and password.
                          </span>
                        </p>
                      </button>
                      <button className='group flex items-center text-left pl-6 py-6 pr-10 rounded-xl hover:bg-white hover:bg-opacity-10 border border-transparent hover:border-white hover:border-opacity-20 focus:outline-none transition duration-100'>
                        <div className='relative flex-shrink-0 inline-flex w-10 h-10 mr-3 items-center justify-center font-bold text-white bg-white bg-opacity-10 border border-white border-opacity-20 rounded-full transition duration-200'>
                          <span className='relative z-10 text-white'>2</span>
                          <img
                            className='hidden group-hover:block absolute top-0 left-0 w-full h-full'
                            src='aurora-assets/how-it-works/bg-gradient-circle.svg'
                            alt=''
                          />
                        </div>
                        <p>
                          <span className='text-white font-bold'>Explore</span>
                          <span className='text-gray-200'>
                            - Browse our interface to discover the features.
                          </span>
                        </p>
                      </button>
                      <button className='group flex items-center text-left pl-6 py-6 pr-10 rounded-xl hover:bg-white hover:bg-opacity-10 border border-transparent hover:border-white hover:border-opacity-20 focus:outline-none transition duration-100'>
                        <div className='relative flex-shrink-0 inline-flex w-10 h-10 mr-3 items-center justify-center font-bold text-white bg-white bg-opacity-10 border border-white border-opacity-20 rounded-full transition duration-200'>
                          <span className='relative z-10 text-white'>3</span>
                          <img
                            className='hidden group-hover:block absolute top-0 left-0 w-full h-full'
                            src='aurora-assets/how-it-works/bg-gradient-circle.svg'
                            alt=''
                          />
                        </div>
                        <p>
                          <span className='text-white font-bold'>
                            Upload &amp; Organize
                          </span>
                          <span className='text-gray-200'>
                            - Drag and drop your assets and organize it into
                            collections to keep it neat.
                          </span>
                        </p>
                      </button>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4'>
                    <img
                      className='block w-full max-w-sm mx-auto lg:ml-auto'
                      src='aurora-assets/how-it-works/image-right.png'
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <div className='max-w-4xl mb-24'>
                <span className='inline-flex items-center h-6 mb-4 px-2 text-xs uppercase font-medium text-white bg-teal-800 rounded-full'>
                  HOW IT WORKS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                  We revolutionized the way we manage digital content
                </h1>
                <p className='text-lg text-gray-200'>
                  Using our platform is easy and straightforward. Here's how it
                  works:
                </p>
              </div>
              <div className='flex flex-wrap items-center'>
                <div className='w-full md:w-1/3 md:pr-4 mb-4 md:mb-0'>
                  <div className='md:flex items-center'>
                    <div className='w-full max-w-xs mx-auto md:max-w-none md:mr-4 p-px rounded-xl bg-gradient-to-br from-cyanGreen-800 to-cyan-800'>
                      <div className='rounded-xl bg-teal-700'>
                        <div className='w-full h-full p-4 rounded-xl bg-white bg-opacity-10'>
                          <div className='flex-shrink-0 inline-flex w-10 h-10 mb-4 items-center justify-center font-bold text-white bg-white bg-opacity-10 rounded-full transition duration-200'>
                            1
                          </div>
                          <p className='max-w-xs font-medium text-white'>
                            Create an account by setting up your email and
                            password.
                          </p>
                        </div>
                      </div>
                    </div>
                    <span className='hidden md:inline-block'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 4L10 8L6 12'
                          stroke='#9CABA2'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <div className='flex items justify-center transform rotate-90 mt-4 md:hidden'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 4L10 8L6 12'
                          stroke='#9CABA2'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/3 md:pr-4 mb-4 md:mb-0'>
                  <div className='md:flex items-center'>
                    <div className='w-full max-w-xs mx-auto md:max-w-none md:mr-4 p-px rounded-xl border border-white border-opacity-20'>
                      <div className='p-4 rounded-xl bg-white bg-opacity-10'>
                        <div className='flex-shrink-0 inline-flex w-10 h-10 mb-4 items-center justify-center font-bold text-white bg-white bg-opacity-10 rounded-full transition duration-200'>
                          2
                        </div>
                        <p className='max-w-xs font-medium text-gray-200'>
                          Browse through our intuitive interface to discover the
                          features.
                        </p>
                      </div>
                    </div>
                    <span className='hidden md:inline-block'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 4L10 8L6 12'
                          stroke='#9CABA2'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <div className='flex items justify-center transform rotate-90 mt-4 md:hidden'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 4L10 8L6 12'
                          stroke='#9CABA2'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/3 xl:pr-8'>
                  <div className='w-full max-w-xs mx-auto md:max-w-none p-px rounded-xl border border-white border-opacity-20'>
                    <div className='p-4 rounded-xl bg-white bg-opacity-10'>
                      <div className='flex-shrink-0 inline-flex w-10 h-10 mb-4 items-center justify-center font-bold text-white bg-white bg-opacity-10 rounded-full transition duration-200'>
                        3
                      </div>
                      <p className='max-w-xs font-medium text-gray-200'>
                        Drag &amp; drop your assets, organize it into
                        collections to keep it neat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-7xl mx-auto'>
              <div className='text-center mb-24'>
                <span className='inline-flex items-center h-6 mb-4 px-2 text-xs uppercase font-medium text-white bg-gray-800 rounded-full'>
                  HOW IT WORKS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white'>
                  Organize assets in 15 seconds
                </h1>
              </div>
              <div className='flex flex-wrap items-center -mx-4 mb-8'>
                <div className='w-full lg:w-1/3 px-4 mb-4 lg:mb-0'>
                  <button className='group flex items-start text-left p-4 rounded-xl bg-transparent hover:bg-gray-800 focus:outline-none transition duration-200'>
                    <div className='flex-shrink-0 inline-flex w-8 h-8 mr-3 items-center justify-center text-white font-bold bg-gray-800 group-hover:bg-gray-700 rounded-full transition duration-200'>
                      1
                    </div>
                    <p className='text-lg'>
                      <span className='text-white font-bold'>Sign up</span>
                      <span className='text-gray-400'>
                        - Setup an account with email and password.
                      </span>
                    </p>
                  </button>
                </div>
                <div className='w-full lg:w-1/3 px-4 mb-4 lg:mb-0'>
                  <button className='group flex items-start text-left p-4 rounded-xl bg-gray-800 focus:outline-none transition duration-200'>
                    <div className='flex-shrink-0 inline-flex w-8 h-8 mr-3 items-center justify-center text-white font-bold bg-gray-700 rounded-full transition duration-200'>
                      2
                    </div>
                    <p className='text-lg'>
                      <span className='text-white font-bold'>Explore</span>
                      <span className='text-gray-400'>
                        - Browse our interface to discover the features.
                      </span>
                    </p>
                  </button>
                </div>
                <div className='w-full lg:w-1/3 px-4'>
                  <button className='group flex items-start text-left p-4 rounded-xl bg-transparent hover:bg-gray-800 focus:outline-none transition duration-200'>
                    <div className='flex-shrink-0 inline-flex w-8 h-8 mr-3 items-center justify-center text-white font-bold bg-gray-800 group-hover:bg-gray-700 rounded-full transition duration-200'>
                      3
                    </div>
                    <p className='text-lg'>
                      <span className='text-white font-bold'>
                        Upload &amp; Organize
                      </span>
                      <span className='text-gray-400'>
                        - Drag &amp; drop your assets and organize it.
                      </span>
                    </p>
                  </button>
                </div>
              </div>
              <img
                className='block w-full mx-auto'
                src='aurora-assets/how-it-works/image-center-dark.png'
                alt=''
              />
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <div className='xs:max-w-sm md:max-w-sm lg:max-w-4xl mx-auto text-center mb-24'>
                <span className='inline-flex items-center h-6 mb-4 px-2 text-xs uppercase font-medium text-white bg-gray-800 rounded-full'>
                  HOW IT WORKS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                  Organize assets in 15 seconds
                </h1>
                <p className='text-lg text-gray-400'>
                  Using our platform is easy and straightforward. Here's how it
                  works:
                </p>
              </div>
              <div className='flex flex-wrap -mx-4 md:-mx-6 items-center'>
                <div className='w-full lg:w-1/3 px-4 md:px-6 mb-16 lg:mb-0'>
                  <div className='max-w-xs mx-auto lg:mx-0'>
                    <img
                      className='block w-full mb-8'
                      src='aurora-assets/how-it-works/image-dark-md-1.png'
                      alt=''
                    />
                    <div className='flex'>
                      <div className='flex-shrink-0 inline-flex w-8 h-8 items-center justify-center text-base font-bold text-white bg-gray-800 rounded-full transition duration-200'>
                        1
                      </div>
                      <div className='ml-3'>
                        <h6 className='text-xl font-bold text-white mb-1'>
                          Sign up
                        </h6>
                        <p className='text-lg text-gray-400'>
                          Create an account by simply setting up your email and
                          password.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4 md:px-6 mb-16 lg:mb-0'>
                  <div className='max-w-xs mx-auto lg:mx-0'>
                    <img
                      className='block w-full mb-8'
                      src='aurora-assets/how-it-works/image-dark-md-3.png'
                      alt=''
                    />
                    <div className='flex'>
                      <div className='flex-shrink-0 inline-flex w-8 h-8 items-center justify-center text-base font-bold text-white bg-gray-800 rounded-full transition duration-200'>
                        2
                      </div>
                      <div className='ml-3'>
                        <h6 className='text-xl font-bold text-white mb-1'>
                          Explore
                        </h6>
                        <p className='text-lg text-gray-400'>
                          Browse through our intuitive interface to discover the
                          features.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4 md:px-6'>
                  <div className='max-w-xs mx-auto lg:mx-0'>
                    <img
                      className='block w-full mb-8'
                      src='aurora-assets/how-it-works/image-dark-md-2.png'
                      alt=''
                    />
                    <div className='flex'>
                      <div className='flex-shrink-0 inline-flex w-8 h-8 items-center justify-center text-base font-bold text-white bg-gray-800 rounded-full transition duration-200'>
                        3
                      </div>
                      <div className='ml-3'>
                        <h6 className='text-xl font-bold text-white mb-1'>
                          Upload &amp; Organize
                        </h6>
                        <p className='text-lg text-gray-400'>
                          Drag and drop your assets and organize it into
                          collections to keep it neat.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-2xl mx-auto text-center mb-24'>
              <span className='inline-flex items-center h-6 mb-4 px-2 text-xs uppercase font-medium text-white bg-gray-800 rounded-full'>
                HOW IT WORKS
              </span>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white'>
                We revolutionized the way we manage digital content
              </h1>
            </div>
            <div className='max-w-lg lg:max-w-5xl xl:max-w-7xl mx-auto'>
              <div className='px-6 md:px-10 xl:px-18 py-10 rounded-xl border border-gray-800'>
                <div className='flex flex-wrap items-center -mx-4'>
                  <div className='w-full lg:w-1/2 px-4 mb-12 lg:mb-0'>
                    <div className='max-w-lg mx-auto'>
                      <button className='group flex items-center text-left pl-6 py-6 pr-10 rounded-xl hover:bg-gray-800 border border-transparent hover:border-gray-700 focus:outline-none transition duration-100'>
                        <div className='relative flex-shrink-0 inline-flex w-10 h-10 mr-3 items-center justify-center font-bold text-white bg-gray-800 border border-gray-700 rounded-full transition duration-200'>
                          <span className='relative z-10 text-white'>1</span>
                          <img
                            className='hidden group-hover:block absolute top-0 left-0 w-full h-full'
                            src='aurora-assets/how-it-works/bg-gradient-circle.svg'
                            alt=''
                          />
                        </div>
                        <p>
                          <span className='text-white font-bold'>Sign up</span>
                          <span className='text-gray-400'>
                            - Create an account by simply setting up your email
                            and password.
                          </span>
                        </p>
                      </button>
                      <button className='group flex items-center text-left pl-6 py-6 pr-10 rounded-xl hover:bg-gray-800 border border-transparent hover:border-gray-700 focus:outline-none transition duration-100'>
                        <div className='relative flex-shrink-0 inline-flex w-10 h-10 mr-3 items-center justify-center font-bold text-white bg-gray-800 border border-gray-700 rounded-full transition duration-200'>
                          <span className='relative z-10 text-white'>2</span>
                          <img
                            className='hidden group-hover:block absolute top-0 left-0 w-full h-full'
                            src='aurora-assets/how-it-works/bg-gradient-circle.svg'
                            alt=''
                          />
                        </div>
                        <p>
                          <span className='text-white font-bold'>Explore</span>
                          <span className='text-gray-400'>
                            - Browse our interface to discover the features.
                          </span>
                        </p>
                      </button>
                      <button className='group flex items-center text-left pl-6 py-6 pr-10 rounded-xl hover:bg-gray-800 border border-transparent hover:border-gray-700 focus:outline-none transition duration-100'>
                        <div className='relative flex-shrink-0 inline-flex w-10 h-10 mr-3 items-center justify-center font-bold text-white bg-gray-800 border border-gray-700 rounded-full transition duration-200'>
                          <span className='relative z-10 text-white'>3</span>
                          <img
                            className='hidden group-hover:block absolute top-0 left-0 w-full h-full'
                            src='aurora-assets/how-it-works/bg-gradient-circle.svg'
                            alt=''
                          />
                        </div>
                        <p>
                          <span className='text-white font-bold'>
                            Upload &amp; Organize
                          </span>
                          <span className='text-gray-400'>
                            - Drag and drop your assets and organize it into
                            collections to keep it neat.
                          </span>
                        </p>
                      </button>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4'>
                    <img
                      className='block w-full max-w-sm mx-auto lg:ml-auto'
                      src='aurora-assets/how-it-works/image-right-dark.png'
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto'>
              <div className='max-w-4xl mb-24'>
                <span className='inline-flex items-center h-6 mb-4 px-2 text-xs uppercase font-medium text-white bg-gray-800 rounded-full'>
                  HOW IT WORKS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                  We revolutionized the way we manage digital content
                </h1>
                <p className='text-lg text-gray-400'>
                  Using our platform is easy and straightforward. Here's how it
                  works:
                </p>
              </div>
              <div className='flex flex-wrap items-center'>
                <div className='w-full md:w-1/3 md:pr-4 mb-4 md:mb-0'>
                  <div className='md:flex items-center'>
                    <div className='w-full max-w-xs mx-auto md:max-w-none md:mr-4 p-px rounded-xl bg-gradient-to-br from-cyanGreen-800 to-cyan-800'>
                      <div className='p-4 rounded-xl bg-gray-900'>
                        <div className='flex-shrink-0 inline-flex w-10 h-10 mb-4 items-center justify-center font-bold text-white bg-gray-800 rounded-full transition duration-200'>
                          1
                        </div>
                        <p className='max-w-xs font-medium text-white'>
                          Create an account by setting up your email and
                          password.
                        </p>
                      </div>
                    </div>
                    <span className='hidden md:inline-block'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 4L10 8L6 12'
                          stroke='#9CABA2'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <div className='flex items justify-center transform rotate-90 mt-4 md:hidden'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 4L10 8L6 12'
                          stroke='#9CABA2'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/3 md:pr-4 mb-4 md:mb-0'>
                  <div className='md:flex items-center'>
                    <div className='w-full max-w-xs mx-auto md:max-w-none md:mr-4 p-px rounded-xl border border-gray-800'>
                      <div className='p-4 rounded-xl bg-gray-900'>
                        <div className='flex-shrink-0 inline-flex w-10 h-10 mb-4 items-center justify-center font-bold text-white bg-gray-800 rounded-full transition duration-200'>
                          2
                        </div>
                        <p className='max-w-xs font-medium text-gray-400'>
                          Browse through our intuitive interface to discover the
                          features.
                        </p>
                      </div>
                    </div>
                    <span className='hidden md:inline-block'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 4L10 8L6 12'
                          stroke='#9CABA2'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <div className='flex items justify-center transform rotate-90 mt-4 md:hidden'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 4L10 8L6 12'
                          stroke='#9CABA2'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/3 xl:pr-8'>
                  <div className='w-full max-w-xs mx-auto md:max-w-none p-px rounded-xl border border-gray-800'>
                    <div className='p-4 rounded-xl bg-gray-900'>
                      <div className='flex-shrink-0 inline-flex w-10 h-10 mb-4 items-center justify-center font-bold text-white bg-gray-800 rounded-full transition duration-200'>
                        3
                      </div>
                      <p className='max-w-xs font-medium text-gray-400'>
                        Drag &amp; drop your assets, organize it into
                        collections to keep it neat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    <FooterComponent /></React.Fragment>
  );
}

