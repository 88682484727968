import React from 'react';

import { Flex, Text } from '@radix-ui/themes';


import {FooterComponent} from './Footers.jsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Cookies() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <div className='z-50 top-0 w-full py-8'>
          <div className='max-w-6xl px-4 xl:px-0 mx-auto'>
            <div className='p-6 bg-teal-800 rounded-xl border-2 border-teal-700 shadow'>
              <div className='flex flex-wrap items-center -mx-4'>
                <div className='w-full md:w-8/12 px-4 mb-8 lg:mb-0'>
                  <div className='max-w-xl'>
                    <p className='text-gray-200 mb-4'>
                      We use technologies such as cookies, to process
                      information about you and your devices to operate,
                      analyze, and improve this site.
                    </p>
                    <a
                      className='inline-block text-sm text-white hover:text-yellowGreen-600 font-semibold'
                      href='#'
                    >
                      Cookie Policy
                    </a>
                  </div>
                </div>
                <div className='w-full md:w-4/12 px-4 text-right'>
                  <a
                    className='relative inline-flex items-center justify-center h-12 px-5 text-center text-base text-teal-800 font-bold bg-white hover:bg-yellowGreen-600 rounded-lg transition duration-300'
                    href='#'
                  >
                    <span>Good to know</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='fixed z-50 top-0 w-full py-8'>
          <div className='container px-4 mx-auto'>
            <div className='p-6 bg-gray-900 rounded-xl border-2 border-teal-700 shadow-3xl'>
              <div className='flex flex-wrap items-center -mx-4'>
                <div className='w-full lg:w-8/12 px-4 mb-8 lg:mb-0'>
                  <div className='flex items-start'>
                    <img
                      className='block'
                      src='aurora-assets/cookies/cookies3.svg'
                      alt=''
                    />
                    <div className='ml-4 max-w-xl'>
                      <p className='text-gray-200'>
                        By clicking “Allow”, you agree to the storing of cookies
                        on your device to personalize your size experience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-4/12 px-4 text-right'>
                  <div className='xs:flex items-center justify-end'>
                    <a
                      className='flex xs:inline-flex mb-3 xs:mb-0 xs:mr-4 items-center justify-center h-12 px-5 text-center text-base text-white hover:text-yellowGreen-700 font-semibold border border-gray-800 hover:border-gray-700 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                      href='#'
                    >
                      Decline
                    </a>
                    <a
                      className='group inline-block justify-center p-1 text-center text-base text-white font-semibold rounded-lg'
                      href='#'
                    >
                      <div className='inline-flex items-stretch h-12 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                        <div className='flex items-center px-5 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                          <span className='-mb-px'>Allow</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='fixed z-50 top-0 w-full py-8'>
          <div className='max-w-6xl px-4 xl:px-0 mx-auto'>
            <div className='p-6 bg-gray-900 rounded-xl border-2 border-teal-700 shadow-3xl'>
              <div className='flex flex-wrap items-center -mx-4'>
                <div className='w-full md:w-8/12 px-4 mb-8 lg:mb-0'>
                  <div className='max-w-xl'>
                    <p className='text-gray-200 mb-4'>
                      We use technologies such as cookies, to process
                      information about you and your devices to operate,
                      analyze, and improve this site.
                    </p>
                    <a
                      className='inline-block text-sm text-white hover:text-yellowGreen-600 font-semibold'
                      href='#'
                    >
                      Cookie Policy
                    </a>
                  </div>
                </div>
                <div className='w-full md:w-4/12 px-4 text-right'>
                  <a
                    className='group inline-block justify-center p-1 text-center text-base text-white font-semibold rounded-lg'
                    href='#'
                  >
                    <div className='inline-flex items-stretch h-12 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                      <div className='flex items-center px-5 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                        <span className='-mb-px'>Good to know</span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='fixed z-50 top-0 w-full py-8'>
          <div className='container px-4 mx-auto'>
            <div className='py-6 px-6 lg:px-16 bg-white rounded-xl border-2 border-gray-100 shadow-4xl'>
              <div className='flex flex-wrap items-center -mx-4'>
                <div className='w-full lg:w-8/12 px-4 mb-8 lg:mb-0'>
                  <div className='max-w-2xl'>
                    <h5 className='font-bold mb-1'>Privacy Settings</h5>
                    <p className='text-gray-500 mb-6'>
                      This site uses third-party website tracking technologies
                      to provide and continually improve our services, and to
                      display advertisements according to users’ interests.
                    </p>
                    <div className='xs:flex items-center'>
                      <a
                        className='inline-block mr-4 text-sm text-gray-500 hover:text-gray-900 font-semibold'
                        href='#'
                      >
                        Privacy Policy
                      </a>
                      <a
                        className='inline-block text-sm text-gray-500 hover:text-gray-900 font-semibold'
                        href='#'
                      >
                        Imprint
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-4/12 px-4 text-right'>
                  <div className='xs:flex items-center justify-end'>
                    <a
                      className='flex xs:inline-flex mb-3 xs:mb-0 xs:mr-4 items-center justify-center h-12 px-5 text-center text-base text-gray-700 hover:text-yellowGreen-700 font-semibold border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                      href='#'
                    >
                      Deny
                    </a>
                    <a
                      className='group relative block xs:inline-block p-px text-base font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                      href='#'
                    >
                      <div className='relative flex items-center justify-center px-5 h-12 bg-gradient-to-br from-cyanGreen-800 to-cyan-800 group-hover:text-green-200 rounded-lg transition duration-300'>
                        <span>Accept All</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='fixed z-50 top-0 w-full py-8'>
          <div className='container px-4 mx-auto'>
            <div className='p-6 bg-white rounded-xl border-2 border-gray-100 shadow-4xl'>
              <div className='flex flex-wrap items-center -mx-4'>
                <div className='w-full lg:w-8/12 px-4 mb-8 lg:mb-0'>
                  <div className='flex items-start'>
                    <img
                      className='block'
                      src='aurora-assets/cookies/cookies2.svg'
                      alt=''
                    />
                    <div className='ml-4 max-w-xl'>
                      <p className='text-gray-700'>
                        By clicking “Allow”, you agree to the storing of cookies
                        on your device to personalize your size experience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-4/12 px-4 text-right'>
                  <div className='xs:flex items-center justify-end'>
                    <a
                      className='flex xs:inline-flex mb-3 xs:mb-0 xs:mr-4 items-center justify-center h-12 px-5 text-center text-base text-gray-700 hover:text-yellowGreen-700 font-semibold border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                      href='#'
                    >
                      Decline
                    </a>
                    <a
                      className='group relative block xs:inline-block p-px text-base font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                      href='#'
                    >
                      <div className='relative flex items-center justify-center px-5 h-12 bg-gradient-to-br from-cyanGreen-800 to-cyan-800 group-hover:text-green-200 rounded-lg transition duration-300'>
                        <span>Allow</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='fixed z-50 top-0 w-full py-8'>
          <div className='container px-4 mx-auto'>
            <div className='p-6 bg-gray-900 rounded-xl border-2 border-teal-700 shadow-3xl'>
              <div className='flex flex-wrap items-center -mx-4'>
                <div className='w-full lg:w-8/12 px-4 mb-8 lg:mb-0'>
                  <div className='flex items-start'>
                    <img
                      className='block'
                      src='aurora-assets/cookies/cookies1.svg'
                      alt=''
                    />
                    <div className='ml-4 max-w-2xl'>
                      <p className='text-white'>
                        By clicking “Accept Cookies”, you agree to the storing
                        of cookies on your device to enhance site navigation,
                        analyze site usage, and assist in our marketing efforts.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-4/12 px-4 text-right'>
                  <a
                    className='group inline-block justify-center p-1 text-center text-base text-white font-semibold rounded-lg'
                    href='#'
                  >
                    <div className='inline-flex items-stretch h-12 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                      <div className='flex items-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                        <span className='-mb-px'>Accept All Cookies</span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='fixed z-50 top-0 w-full py-8'>
          <div className='container px-4 mx-auto'>
            <div className='p-6 bg-teal-800 rounded-xl border-2 border-teal-700 shadow'>
              <div className='flex flex-wrap items-center -mx-4'>
                <div className='w-full lg:w-8/12 px-4 mb-8 lg:mb-0'>
                  <div className='flex items-start'>
                    <img
                      className='block'
                      src='aurora-assets/cookies/cookies1.svg'
                      alt=''
                    />
                    <div className='ml-4 max-w-2xl'>
                      <p className='text-gray-200'>
                        By clicking “Accept Cookies”, you agree to the storing
                        of cookies on your device to enhance site navigation,
                        analyze site usage, and assist in our marketing efforts.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-4/12 px-4 text-right'>
                  <a
                    className='relative inline-flex items-center justify-center h-12 px-5 text-center text-base text-teal-800 font-bold bg-white hover:bg-yellowGreen-600 rounded-lg transition duration-300'
                    href='#'
                  >
                    <span>Accept Cookies</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='fixed z-50 top-0 w-full py-8'>
          <div className='container px-4 mx-auto'>
            <div className='py-6 px-6 lg:px-16 bg-teal-800 rounded-xl border-2 border-teal-700 shadow'>
              <div className='flex flex-wrap items-center -mx-4'>
                <div className='w-full lg:w-8/12 px-4 mb-8 lg:mb-0'>
                  <div className='max-w-2xl'>
                    <h5 className='font-bold text-white mb-1'>
                      Privacy Settings
                    </h5>
                    <p className='text-gray-400 mb-6'>
                      This site uses third-party website tracking technologies
                      to provide and continually improve our services, and to
                      display advertisements according to users’ interests.
                    </p>
                    <div className='xs:flex items-center'>
                      <a
                        className='inline-block mr-4 text-sm text-white hover:text-yellowGreen-600 font-semibold'
                        href='#'
                      >
                        Privacy Policy
                      </a>
                      <a
                        className='inline-block text-sm text-white hover:text-yellowGreen-600 font-semibold'
                        href='#'
                      >
                        Imprint
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-4/12 px-4 text-right'>
                  <div className='xs:flex items-center justify-end'>
                    <a
                      className='flex xs:inline-flex mb-3 xs:mb-0 xs:mr-4 items-center justify-center h-12 px-5 text-center text-base text-white hover:text-yellowGreen-700 font-semibold rounded-lg transition duration-200'
                      href='#'
                    >
                      Deny
                    </a>
                    <a
                      className='relative inline-flex items-center justify-center h-12 px-5 text-center text-base text-teal-800 font-bold bg-white hover:bg-yellowGreen-600 rounded-lg transition duration-300'
                      href='#'
                    >
                      <span>Accept All</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className='py-12 md:pt-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4 mb-32'>
              <div className='w-full lg:w-1/3 px-4 mb-16'>
                <div>
                  <img
                    className='block h-8 mb-4'
                    src='aurora-assets/logos/logo-aurora-sm-white.svg'
                    alt=''
                  />
                  <span className='block text-gray-400 mb-16'>
                    Make collecting fun again.
                  </span>
                  <div className='flex items-center'>
                    <a
                      className='inline-flex w-12 h-12 mr-3 text-white items-center justify-center bg-gray-800 hover:bg-gray-700 rounded-lg transition duration-200'
                      href='#'
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M22.46 6C21.69 6.35 20.86 6.58 20 6.69C20.88 6.16 21.56 5.32 21.88 4.31C21.05 4.81 20.13 5.16 19.16 5.36C18.37 4.5 17.26 4 16 4C13.65 4 11.73 5.92 11.73 8.29C11.73 8.63 11.77 8.96 11.84 9.27C8.28004 9.09 5.11004 7.38 3.00004 4.79C2.63004 5.42 2.42004 6.16 2.42004 6.94C2.42004 8.43 3.17004 9.75 4.33004 10.5C3.62004 10.5 2.96004 10.3 2.38004 10V10.03C2.38004 12.11 3.86004 13.85 5.82004 14.24C5.19077 14.4122 4.53013 14.4362 3.89004 14.31C4.16165 15.1625 4.69358 15.9084 5.41106 16.4429C6.12854 16.9775 6.99549 17.2737 7.89004 17.29C6.37367 18.4904 4.49404 19.1393 2.56004 19.13C2.22004 19.13 1.88004 19.11 1.54004 19.07C3.44004 20.29 5.70004 21 8.12004 21C16 21 20.33 14.46 20.33 8.79C20.33 8.6 20.33 8.42 20.32 8.23C21.16 7.63 21.88 6.87 22.46 6Z'
                          fill='currentColor'
                        />
                      </svg>
                    </a>
                    <a
                      className='inline-flex w-12 h-12 mr-3 text-white items-center justify-center bg-gray-800 hover:bg-gray-700 rounded-lg transition duration-200'
                      href='#'
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19ZM18.5 18.5V13.2C18.5 12.3354 18.1565 11.5062 17.5452 10.8948C16.9338 10.2835 16.1046 9.94 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17C14.6813 12.17 15.0374 12.3175 15.2999 12.5801C15.5625 12.8426 15.71 13.1987 15.71 13.57V18.5H18.5ZM6.88 8.56C7.32556 8.56 7.75288 8.383 8.06794 8.06794C8.383 7.75288 8.56 7.32556 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19C6.43178 5.19 6.00193 5.36805 5.68499 5.68499C5.36805 6.00193 5.19 6.43178 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56ZM8.27 18.5V10.13H5.5V18.5H8.27Z'
                          fill='currentColor'
                        />
                      </svg>
                    </a>
                    <a
                      className='inline-flex w-12 h-12 mr-3 text-white items-center justify-center bg-gray-800 hover:bg-gray-700 rounded-lg transition duration-200'
                      href='#'
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z'
                          fill='currentColor'
                        />
                      </svg>
                    </a>
                    <a
                      className='inline-flex w-12 h-12 text-white items-center justify-center bg-gray-800 hover:bg-gray-700 rounded-lg transition duration-200'
                      href='#'
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 16.84 5.44 20.87 10 21.8V15H8V12H10V9.5C10 7.57 11.57 6 13.5 6H16V9H14C13.45 9 13 9.45 13 10V12H16V15H13V21.95C18.05 21.45 22 17.19 22 12Z'
                          fill='currentColor'
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div className='w-full lg:w-2/3 px-4'>
                <div className='flex flex-wrap -mx-4 -mb-8'>
                  <div className='w-full xs:w-1/2 md:w-1/4 px-4 mb-8'>
                    <h5 className='text-xl font-semibold text-white mb-6'>
                      Product
                    </h5>
                    <ul>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Features
                        </a>
                      </li>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Solutions
                        </a>
                      </li>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Pricing
                        </a>
                      </li>
                      <li>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Tutorials
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='w-full xs:w-1/2 md:w-1/4 px-4 mb-8'>
                    <h5 className='text-xl font-semibold text-white mb-6'>
                      Company
                    </h5>
                    <ul>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          About
                        </a>
                      </li>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Our Mission
                        </a>
                      </li>
                      <li className='flex items-center mb-6'>
                        <a
                          className='inline-block mr-2 text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          <span>Careers</span>
                        </a>
                        <span className='inline-flex h-6 px-2 items-center text-white bg-teal-600 rounded-full'>
                          <svg
                            width={8}
                            height={8}
                            viewBox='0 0 8 8'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <circle cx={4} cy={4} r={3} fill='white' />
                          </svg>
                          <span className='ml-1 text-xs text-gray-50'>
                            Hiring
                          </span>
                        </span>
                      </li>
                      <li>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Contact
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='w-full xs:w-1/2 md:w-1/4 px-4 mb-8'>
                    <h5 className='text-xl font-semibold text-white mb-6'>
                      Resources
                    </h5>
                    <ul>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Press
                        </a>
                      </li>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Blog
                        </a>
                      </li>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Help Center
                        </a>
                      </li>
                      <li>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Support
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='w-full xs:w-1/2 md:w-1/4 px-4 mb-8'>
                    <h5 className='text-xl font-semibold text-white mb-6'>
                      Legal
                    </h5>
                    <ul>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Terms of Use
                        </a>
                      </li>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Privacy Policy
                        </a>
                      </li>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Legal Notice
                        </a>
                      </li>
                      <li>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Cookie Settings
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='text-right'>
              <p className='text-gray-400'>
                Made with heart © 2023 Aurora. All rights reserved.
              </p>
            </div>
          </div>
        </section>
        <div className='fixed z-50 top-0 w-full py-8'>
          <div className='container px-4 mx-auto'>
            <div className='p-6 bg-teal-800 rounded-xl border-2 border-teal-700 shadow'>
              <div className='flex flex-wrap items-center -mx-4'>
                <div className='w-full lg:w-8/12 px-4 mb-8 lg:mb-0'>
                  <div className='flex items-start'>
                    <img
                      className='block'
                      src='aurora-assets/cookies/cookies3.svg'
                      alt=''
                    />
                    <div className='ml-4 max-w-xl'>
                      <p className='text-gray-200'>
                        By clicking “Allow”, you agree to the storing of cookies
                        on your device to personalize your size experience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-4/12 px-4 text-right'>
                  <div className='xs:flex items-center justify-end'>
                    <a
                      className='flex xs:inline-flex mb-3 xs:mb-0 xs:mr-4 items-center justify-center h-12 px-5 text-center text-base text-white hover:text-yellowGreen-700 font-semibold rounded-lg transition duration-200'
                      href='#'
                    >
                      Decline
                    </a>
                    <a
                      className='relative inline-flex items-center justify-center h-12 px-5 text-center text-base text-teal-800 font-bold bg-white hover:bg-yellowGreen-600 rounded-lg transition duration-300'
                      href='#'
                    >
                      <span>Allow</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='fixed z-50 top-0 w-full py-8'>
          <div className='container px-4 mx-auto'>
            <div className='p-6 bg-radial-dark-green rounded-xl'>
              <div className='flex flex-wrap items-center -mx-4'>
                <div className='w-full lg:w-1/2 xl:w-8/12 px-4 mb-8 lg:mb-0'>
                  <p className='text-white'>
                    By clicking “Accept All Cookies”, you agree to the storing
                    of cookies on your device.
                  </p>
                </div>
                <div className='w-full lg:w-1/2 xl:w-4/12 px-4'>
                  <div className='xs:flex items-center justify-end'>
                    <a
                      className='flex xs:inline-flex mb-3 xs:mb-0 xs:mr-4 items-center justify-center h-12 px-5 text-center text-base text-white font-semibold border border-teal-200 border-opacity-10 hover:bg-white hover:bg-opacity-10 rounded-lg transition duration-200'
                      href='#'
                    >
                      Cookies Settings
                    </a>
                    <a
                      className='flex xs:inline-flex justify-center items-center h-12 px-5 text-center text-base text-white bg-white bg-opacity-20 hover:bg-opacity-30 border border-white border-opacity-20 font-semibold rounded-lg'
                      href='#'
                    >
                      <span>Accept All Cookies</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='fixed z-50 top-0 w-full py-8'>
          <div className='max-w-6xl px-4 xl:px-0 mx-auto'>
            <div className='p-6 bg-radial-dark-green rounded-xl'>
              <div className='flex flex-wrap items-center -mx-4'>
                <div className='w-full md:w-8/12 px-4 mb-8 lg:mb-0'>
                  <div className='max-w-xl'>
                    <p className='text-gray-200 mb-4'>
                      We use technologies such as cookies, to process
                      information about you and your devices to operate,
                      analyze, and improve this site.
                    </p>
                    <a
                      className='inline-block text-sm text-white hover:text-gray-900 font-semibold'
                      href='#'
                    >
                      Cookie Policy
                    </a>
                  </div>
                </div>
                <div className='w-full md:w-4/12 px-4 text-right'>
                  <a
                    className='flex xs:inline-flex justify-center items-center h-12 px-5 text-center text-base text-white bg-white bg-opacity-20 hover:bg-opacity-30 border border-white border-opacity-20 font-semibold rounded-lg'
                    href='#'
                  >
                    <span>Good to know</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className='py-12 md:pt-24 bg-gray-900'>
          <div className='mb-12 pb-24 border-b border-gray-800'>
            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/2 px-4 mb-16'>
                  <div>
                    <img
                      className='block h-8 mb-4'
                      src='aurora-assets/logos/logo-aurora-sm-white.svg'
                      alt=''
                    />
                    <span className='block text-lg text-gray-400'>
                      Aurora make collecting fun again.
                    </span>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 ml-auto px-4'>
                  <div className='flex flex-wrap -mx-4 -mb-8'>
                    <div className='w-1/2 xs:w-1/3 px-4 mb-8'>
                      <h5 className='text-xl font-semibold text-white mb-6'>
                        Product
                      </h5>
                      <ul>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Features
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Solutions
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Pricing
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Tutorials
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='w-1/2 xs:w-1/3 px-4 mb-8'>
                      <h5 className='text-xl font-semibold text-white mb-6'>
                        Company
                      </h5>
                      <ul>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            About
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Our Mission
                          </a>
                        </li>
                        <li className='flex items-center mb-6'>
                          <a
                            className='inline-block mr-2 text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            <span>Careers</span>
                          </a>
                          <span className='inline-flex h-6 px-2 items-center text-white bg-teal-600 rounded-full'>
                            <svg
                              width={8}
                              height={8}
                              viewBox='0 0 8 8'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle cx={4} cy={4} r={3} fill='white' />
                            </svg>
                            <span className='ml-1 text-xs text-gray-50'>
                              Hiring
                            </span>
                          </span>
                        </li>
                        <li>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='w-full xs:w-1/3 px-4 mb-8'>
                      <h5 className='text-xl font-semibold text-white mb-6'>
                        Resources
                      </h5>
                      <ul>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Press
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Blog
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Help Center
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-lg text-gray-400 hover:text-white'
                            href='#'
                          >
                            Support
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container px-4 mx-auto'>
            <div className='md:flex items-center justify-between'>
              <p className='text-gray-400 mb-10 md:mb-0'>
                Made with heart © 2023 Aurora. All rights reserved.
              </p>
              <div className='flex items-center'>
                <a
                  className='inline-flex w-10 h-10 p-2 mr-3 text-white hover:text-teal-500 items-center justify-center bg-gray-800 rounded-full transition duration-100'
                  href='#'
                >
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M22.46 6C21.69 6.35 20.86 6.58 20 6.69C20.88 6.16 21.56 5.32 21.88 4.31C21.05 4.81 20.13 5.16 19.16 5.36C18.37 4.5 17.26 4 16 4C13.65 4 11.73 5.92 11.73 8.29C11.73 8.63 11.77 8.96 11.84 9.27C8.28004 9.09 5.11004 7.38 3.00004 4.79C2.63004 5.42 2.42004 6.16 2.42004 6.94C2.42004 8.43 3.17004 9.75 4.33004 10.5C3.62004 10.5 2.96004 10.3 2.38004 10V10.03C2.38004 12.11 3.86004 13.85 5.82004 14.24C5.19077 14.4122 4.53013 14.4362 3.89004 14.31C4.16165 15.1625 4.69358 15.9084 5.41106 16.4429C6.12854 16.9775 6.99549 17.2737 7.89004 17.29C6.37367 18.4904 4.49404 19.1393 2.56004 19.13C2.22004 19.13 1.88004 19.11 1.54004 19.07C3.44004 20.29 5.70004 21 8.12004 21C16 21 20.33 14.46 20.33 8.79C20.33 8.6 20.33 8.42 20.32 8.23C21.16 7.63 21.88 6.87 22.46 6Z'
                      fill='currentColor'
                    />
                  </svg>
                </a>
                <a
                  className='inline-flex w-10 h-10 p-2 mr-3 text-white hover:text-teal-500 items-center justify-center bg-gray-800 rounded-full transition duration-100'
                  href='#'
                >
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19ZM18.5 18.5V13.2C18.5 12.3354 18.1565 11.5062 17.5452 10.8948C16.9338 10.2835 16.1046 9.94 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17C14.6813 12.17 15.0374 12.3175 15.2999 12.5801C15.5625 12.8426 15.71 13.1987 15.71 13.57V18.5H18.5ZM6.88 8.56C7.32556 8.56 7.75288 8.383 8.06794 8.06794C8.383 7.75288 8.56 7.32556 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19C6.43178 5.19 6.00193 5.36805 5.68499 5.68499C5.36805 6.00193 5.19 6.43178 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56ZM8.27 18.5V10.13H5.5V18.5H8.27Z'
                      fill='currentColor'
                    />
                  </svg>
                </a>
                <a
                  className='inline-flex w-10 h-10 p-2 mr-3 text-white hover:text-teal-500 items-center justify-center bg-gray-800 rounded-full transition duration-100'
                  href='#'
                >
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z'
                      fill='currentColor'
                    />
                  </svg>
                </a>
                <a
                  className='inline-flex w-10 h-10 p-2 text-white hover:text-teal-500 items-center justify-center bg-gray-800 rounded-full transition duration-100'
                  href='#'
                >
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 16.84 5.44 20.87 10 21.8V15H8V12H10V9.5C10 7.57 11.57 6 13.5 6H16V9H14C13.45 9 13 9.45 13 10V12H16V15H13V21.95C18.05 21.45 22 17.19 22 12Z'
                      fill='currentColor'
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:pt-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4 mb-32'>
              <div className='w-full lg:w-1/3 px-4 mb-16'>
                <div>
                  <img
                    className='block h-8 mb-4'
                    src='aurora-assets/logos/logo-aurora-sm-white.svg'
                    alt=''
                  />
                  <span className='block text-gray-400 mb-16'>
                    Make collecting fun again.
                  </span>
                  <div className='flex items-center'>
                    <a
                      className='inline-flex w-12 h-12 mr-3 text-white items-center justify-center bg-white bg-opacity-10 hover:bg-opacity-20 rounded-lg transition duration-200'
                      href='#'
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M22.46 6C21.69 6.35 20.86 6.58 20 6.69C20.88 6.16 21.56 5.32 21.88 4.31C21.05 4.81 20.13 5.16 19.16 5.36C18.37 4.5 17.26 4 16 4C13.65 4 11.73 5.92 11.73 8.29C11.73 8.63 11.77 8.96 11.84 9.27C8.28004 9.09 5.11004 7.38 3.00004 4.79C2.63004 5.42 2.42004 6.16 2.42004 6.94C2.42004 8.43 3.17004 9.75 4.33004 10.5C3.62004 10.5 2.96004 10.3 2.38004 10V10.03C2.38004 12.11 3.86004 13.85 5.82004 14.24C5.19077 14.4122 4.53013 14.4362 3.89004 14.31C4.16165 15.1625 4.69358 15.9084 5.41106 16.4429C6.12854 16.9775 6.99549 17.2737 7.89004 17.29C6.37367 18.4904 4.49404 19.1393 2.56004 19.13C2.22004 19.13 1.88004 19.11 1.54004 19.07C3.44004 20.29 5.70004 21 8.12004 21C16 21 20.33 14.46 20.33 8.79C20.33 8.6 20.33 8.42 20.32 8.23C21.16 7.63 21.88 6.87 22.46 6Z'
                          fill='currentColor'
                        />
                      </svg>
                    </a>
                    <a
                      className='inline-flex w-12 h-12 mr-3 text-white items-center justify-center bg-white bg-opacity-10 hover:bg-opacity-20 rounded-lg transition duration-200'
                      href='#'
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19ZM18.5 18.5V13.2C18.5 12.3354 18.1565 11.5062 17.5452 10.8948C16.9338 10.2835 16.1046 9.94 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17C14.6813 12.17 15.0374 12.3175 15.2999 12.5801C15.5625 12.8426 15.71 13.1987 15.71 13.57V18.5H18.5ZM6.88 8.56C7.32556 8.56 7.75288 8.383 8.06794 8.06794C8.383 7.75288 8.56 7.32556 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19C6.43178 5.19 6.00193 5.36805 5.68499 5.68499C5.36805 6.00193 5.19 6.43178 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56ZM8.27 18.5V10.13H5.5V18.5H8.27Z'
                          fill='currentColor'
                        />
                      </svg>
                    </a>
                    <a
                      className='inline-flex w-12 h-12 mr-3 text-white items-center justify-center bg-white bg-opacity-10 hover:bg-opacity-20 rounded-lg transition duration-200'
                      href='#'
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z'
                          fill='currentColor'
                        />
                      </svg>
                    </a>
                    <a
                      className='inline-flex w-12 h-12 text-white items-center justify-center bg-white bg-opacity-10 hover:bg-opacity-20 rounded-lg transition duration-200'
                      href='#'
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 16.84 5.44 20.87 10 21.8V15H8V12H10V9.5C10 7.57 11.57 6 13.5 6H16V9H14C13.45 9 13 9.45 13 10V12H16V15H13V21.95C18.05 21.45 22 17.19 22 12Z'
                          fill='currentColor'
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div className='w-full lg:w-2/3 px-4'>
                <div className='flex flex-wrap -mx-4 -mb-8'>
                  <div className='w-full xs:w-1/2 md:w-1/4 px-4 mb-8'>
                    <h5 className='text-xl font-semibold text-white mb-6'>
                      Product
                    </h5>
                    <ul>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Features
                        </a>
                      </li>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Solutions
                        </a>
                      </li>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Pricing
                        </a>
                      </li>
                      <li>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Tutorials
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='w-full xs:w-1/2 md:w-1/4 px-4 mb-8'>
                    <h5 className='text-xl font-semibold text-white mb-6'>
                      Company
                    </h5>
                    <ul>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          About
                        </a>
                      </li>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Our Mission
                        </a>
                      </li>
                      <li className='flex items-center mb-6'>
                        <a
                          className='inline-block mr-2 text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          <span>Careers</span>
                        </a>
                        <span className='inline-flex h-6 px-2 items-center text-white bg-teal-600 rounded-full'>
                          <svg
                            width={8}
                            height={8}
                            viewBox='0 0 8 8'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <circle cx={4} cy={4} r={3} fill='white' />
                          </svg>
                          <span className='ml-1 text-xs text-gray-50'>
                            Hiring
                          </span>
                        </span>
                      </li>
                      <li>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Contact
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='w-full xs:w-1/2 md:w-1/4 px-4 mb-8'>
                    <h5 className='text-xl font-semibold text-white mb-6'>
                      Resources
                    </h5>
                    <ul>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Press
                        </a>
                      </li>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Blog
                        </a>
                      </li>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Help Center
                        </a>
                      </li>
                      <li>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Support
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='w-full xs:w-1/2 md:w-1/4 px-4 mb-8'>
                    <h5 className='text-xl font-semibold text-white mb-6'>
                      Legal
                    </h5>
                    <ul>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Terms of Use
                        </a>
                      </li>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Privacy Policy
                        </a>
                      </li>
                      <li className='mb-6'>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Legal Notice
                        </a>
                      </li>
                      <li>
                        <a
                          className='inline-block text-lg text-gray-400 hover:text-white'
                          href='#'
                        >
                          Cookie Settings
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='text-right'>
              <p className='text-gray-200'>
                Made with heart © 2023 Aurora. All rights reserved.
              </p>
            </div>
          </div>
        </section>
        <section className='py-12 md:pt-24 bg-gray-900'>
          <div className='mb-12 pb-24 border-b border-gray-800'>
            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/2 px-4 mb-16'>
                  <div>
                    <img
                      className='block h-10 mb-24'
                      src='aurora-assets/logos/logo-aurora-sm-white.svg'
                      alt=''
                    />
                    <div>
                      <h6 className='text-lg font-semibold text-white mb-4'>
                        Join our newsletter
                      </h6>
                      <div className='xs:flex items-center max-w-md mb-6'>
                        <input
                          className='w-full h-10 mb-3 xs:mb-0 xs:mr-3 py-2 px-3 text-sm text-gray-400 placeholder-gray-400 bg-gray-800 border border-teal-900 focus:border-yellowGreen-800 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-800'
                          type='email'
                          placeholder='Enter your email'
                        />
                        <button variant="classic"
                          className='xs:flex-shrink-0 group relative w-full xs:w-auto h-10 flex xs:inline-flex items-center justify-center px-4 py-px font-bold text-base text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300'
                          type='submit'
                        >
                          <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                          <span>Subscribe</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 ml-auto px-4'>
                  <div className='flex flex-wrap -mx-4 -mb-8'>
                    <div className='w-1/2 xs:w-1/3 px-4 mb-8'>
                      <h5 className='text-xl font-semibold text-white mb-6'>
                        Product
                      </h5>
                      <ul>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            Features
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            Solutions
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            Pricing
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            Tutorials
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='w-1/2 xs:w-1/3 px-4 mb-8'>
                      <h5 className='text-xl font-semibold text-white mb-6'>
                        Company
                      </h5>
                      <ul>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            About
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            Our Mission
                          </a>
                        </li>
                        <li className='flex items-center mb-6'>
                          <a
                            className='inline-block mr-2 text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            <span>Careers</span>
                          </a>
                          <span className='inline-flex h-6 px-2 items-center text-white bg-teal-600 rounded-full'>
                            <svg
                              width={8}
                              height={8}
                              viewBox='0 0 8 8'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle cx={4} cy={4} r={3} fill='white' />
                            </svg>
                            <span className='ml-1 text-xs text-gray-50'>
                              Hiring
                            </span>
                          </span>
                        </li>
                        <li>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='w-full xs:w-1/3 px-4 mb-8'>
                      <h5 className='text-xl font-semibold text-white mb-6'>
                        Resources
                      </h5>
                      <ul>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            Press
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            Blog
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            Help Center
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            Support
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container px-4 mx-auto'>
            <div className='md:flex items-center justify-between'>
              <p className='text-gray-500 mb-8 md:mb-0'>
                Made with heart © 2023 Aurora. All rights reserved.
              </p>
              <div className='flex flex-col xs:flex-row items-center'>
                <a
                  className='inline-block font-medium text-gray-400 hover:text-white'
                  href='#'
                >
                  Privacy Policy
                </a>
                <span className='block my-4 xs:my-0 mx-6'>
                  <svg
                    width={8}
                    height={8}
                    viewBox='0 0 8 8'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle cx={4} cy={4} r={3} fill='#6C7F73' />
                  </svg>
                </span>
                <a
                  className='inline-block font-medium text-gray-400 hover:text-white'
                  href='#'
                >
                  Terms of Conditions
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:pt-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-5xl mx-auto'>
              <div className='flex flex-wrap -mx-4 -mb-8'>
                <div className='w-full xs:w-1/2 md:w-1/4 px-4 mb-8'>
                  <h5 className='text-xl font-semibold text-gray-500 mb-6'>
                    Product
                  </h5>
                  <ul>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Features
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Solutions
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Pricing
                      </a>
                    </li>
                    <li>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Tutorials
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='w-full xs:w-1/2 md:w-1/4 px-4 mb-8'>
                  <h5 className='text-xl font-semibold text-gray-500 mb-6'>
                    Company
                  </h5>
                  <ul>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        About
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Our Mission
                      </a>
                    </li>
                    <li className='flex items-center mb-6'>
                      <a
                        className='inline-block mr-2 text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        <span>Careers</span>
                      </a>
                      <span className='inline-flex h-6 px-2 items-center text-white bg-teal-600 rounded-full'>
                        <svg
                          width={8}
                          height={8}
                          viewBox='0 0 8 8'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={4} cy={4} r={3} fill='white' />
                        </svg>
                        <span className='ml-1 text-xs text-gray-50'>
                          Hiring
                        </span>
                      </span>
                    </li>
                    <li>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='w-full xs:w-1/2 md:w-1/4 px-4 mb-8'>
                  <h5 className='text-xl font-semibold text-gray-500 mb-6'>
                    Resources
                  </h5>
                  <ul>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Press
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Blog
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Help Center
                      </a>
                    </li>
                    <li>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Support
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='w-full xs:w-1/2 md:w-1/4 px-4 mb-8'>
                  <h5 className='text-xl font-semibold text-gray-500 mb-6'>
                    Legal
                  </h5>
                  <ul>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Terms of Use
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Legal Notice
                      </a>
                    </li>
                    <li>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Cookie Settings
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='mt-32 pt-12 border-t border-gray-800'>
              <div className='flex flex-wrap items-center -mx-4'>
                <div className='w-full lg:w-1/3 px-4'>
                  <img
                    src='aurora-assets/logos/logo-aurora-sm-white.svg'
                    alt=''
                  />
                </div>
                <div className='w-full lg:w-1/3 px-4'>
                  <p className='text-gray-500'>
                    Made with heart © 2023 Aurora. All rights reserved.
                  </p>
                </div>
                <div className='w-full lg:w-1/3 px-4'>
                  <div className='flex items-center justify-end'>
                    <a
                      className='inline-flex mr-6 text-gray-400 hover:text-gray-50 items-center justify-center transition duration-200'
                      href='#'
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M22.46 6C21.69 6.35 20.86 6.58 20 6.69C20.88 6.16 21.56 5.32 21.88 4.31C21.05 4.81 20.13 5.16 19.16 5.36C18.37 4.5 17.26 4 16 4C13.65 4 11.73 5.92 11.73 8.29C11.73 8.63 11.77 8.96 11.84 9.27C8.28004 9.09 5.11004 7.38 3.00004 4.79C2.63004 5.42 2.42004 6.16 2.42004 6.94C2.42004 8.43 3.17004 9.75 4.33004 10.5C3.62004 10.5 2.96004 10.3 2.38004 10V10.03C2.38004 12.11 3.86004 13.85 5.82004 14.24C5.19077 14.4122 4.53013 14.4362 3.89004 14.31C4.16165 15.1625 4.69358 15.9084 5.41106 16.4429C6.12854 16.9775 6.99549 17.2737 7.89004 17.29C6.37367 18.4904 4.49404 19.1393 2.56004 19.13C2.22004 19.13 1.88004 19.11 1.54004 19.07C3.44004 20.29 5.70004 21 8.12004 21C16 21 20.33 14.46 20.33 8.79C20.33 8.6 20.33 8.42 20.32 8.23C21.16 7.63 21.88 6.87 22.46 6Z'
                          fill='currentColor'
                        />
                      </svg>
                    </a>
                    <a
                      className='inline-flex mr-6 text-gray-400 hover:text-gray-50 items-center justify-center transition duration-200'
                      href='#'
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19ZM18.5 18.5V13.2C18.5 12.3354 18.1565 11.5062 17.5452 10.8948C16.9338 10.2835 16.1046 9.94 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17C14.6813 12.17 15.0374 12.3175 15.2999 12.5801C15.5625 12.8426 15.71 13.1987 15.71 13.57V18.5H18.5ZM6.88 8.56C7.32556 8.56 7.75288 8.383 8.06794 8.06794C8.383 7.75288 8.56 7.32556 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19C6.43178 5.19 6.00193 5.36805 5.68499 5.68499C5.36805 6.00193 5.19 6.43178 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56ZM8.27 18.5V10.13H5.5V18.5H8.27Z'
                          fill='currentColor'
                        />
                      </svg>
                    </a>
                    <a
                      className='inline-flex text-gray-400 hover:text-gray-50 items-center justify-center transition duration-200'
                      href='#'
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z'
                          fill='currentColor'
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='pb-12 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='pt-12 md:pt-24 px-5 xs:px-12 xl:px-24 pb-8 mb-12 max-w-6xl mx-auto border-l border-b border-r border-gray-700 rounded-b-2xl'>
              <div className='flex flex-wrap -mx-4 -mb-8'>
                <div className='w-1/2 md:w-1/4 px-4 mb-8'>
                  <h5 className='text-xl font-semibold text-white mb-6'>
                    Product
                  </h5>
                  <ul>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Features
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Solutions
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Pricing
                      </a>
                    </li>
                    <li>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Tutorials
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='w-1/2 md:w-1/4 px-4 mb-8'>
                  <h5 className='text-xl font-semibold text-white mb-6'>
                    Company
                  </h5>
                  <ul>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        About
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Our Mission
                      </a>
                    </li>
                    <li className='flex items-center mb-6'>
                      <a
                        className='inline-block mr-2 text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        <span>Careers</span>
                      </a>
                      <span className='inline-flex h-6 px-2 items-center text-white bg-teal-600 rounded-full'>
                        <svg
                          width={8}
                          height={8}
                          viewBox='0 0 8 8'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={4} cy={4} r={3} fill='white' />
                        </svg>
                        <span className='ml-1 text-xs text-gray-50'>
                          Hiring
                        </span>
                      </span>
                    </li>
                    <li>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='w-1/2 md:w-1/4 px-4 mb-8'>
                  <h5 className='text-xl font-semibold text-white mb-6'>
                    Resources
                  </h5>
                  <ul>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Press
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Blog
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Help Center
                      </a>
                    </li>
                    <li>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Support
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='w-1/2 md:w-1/4 px-4 mb-8'>
                  <h5 className='text-xl font-semibold text-white mb-6'>
                    Legal
                  </h5>
                  <ul>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Terms of Use
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Legal Notice
                      </a>
                    </li>
                    <li>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Cookie Settings
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='mt-24'>
                <div className='flex flex-col sm:flex-row items-center justify-between'>
                  <img
                    className='block h-8 mb-6 sm:mb-0'
                    src='aurora-assets/logos/logo-sign-md.png'
                    alt=''
                  />
                  <div className='flex flex-col sm:flex-row items-center'>
                    <a
                      className='inline-block font-medium text-gray-400 hover:text-white'
                      href='#'
                    >
                      Privacy Policy
                    </a>
                    <span className='block my-4 sm:my-0 mx-6'>
                      <svg
                        width={8}
                        height={8}
                        viewBox='0 0 8 8'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <circle cx={4} cy={4} r={3} fill='#6C7F73' />
                      </svg>
                    </span>
                    <a
                      className='inline-block font-medium text-gray-400 hover:text-white'
                      href='#'
                    >
                      Terms of Conditions
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='text-center'>
              <p className='text-gray-500'>
                Made with heart © 2023 Aurora. All rights reserved.
              </p>
            </div>
          </div>
        </section>
        <section className='py-12 md:pt-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-5xl mx-auto'>
              <div className='flex flex-wrap -mx-4 -mb-8'>
                <div className='w-full xs:w-1/2 md:w-1/4 px-4 mb-8'>
                  <h5 className='text-xl font-semibold text-gray-500 mb-6'>
                    Product
                  </h5>
                  <ul>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Features
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Solutions
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Pricing
                      </a>
                    </li>
                    <li>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Tutorials
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='w-full xs:w-1/2 md:w-1/4 px-4 mb-8'>
                  <h5 className='text-xl font-semibold text-gray-500 mb-6'>
                    Company
                  </h5>
                  <ul>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        About
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Our Mission
                      </a>
                    </li>
                    <li className='flex items-center mb-6'>
                      <a
                        className='inline-block mr-2 text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        <span>Careers</span>
                      </a>
                      <span className='inline-flex h-6 px-2 items-center text-white bg-teal-600 rounded-full'>
                        <svg
                          width={8}
                          height={8}
                          viewBox='0 0 8 8'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={4} cy={4} r={3} fill='white' />
                        </svg>
                        <span className='ml-1 text-xs text-gray-50'>
                          Hiring
                        </span>
                      </span>
                    </li>
                    <li>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='w-full xs:w-1/2 md:w-1/4 px-4 mb-8'>
                  <h5 className='text-xl font-semibold text-gray-500 mb-6'>
                    Resources
                  </h5>
                  <ul>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Press
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Blog
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Help Center
                      </a>
                    </li>
                    <li>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Support
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='w-full xs:w-1/2 md:w-1/4 px-4 mb-8'>
                  <h5 className='text-xl font-semibold text-gray-500 mb-6'>
                    Legal
                  </h5>
                  <ul>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Terms of Use
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Legal Notice
                      </a>
                    </li>
                    <li>
                      <a
                        className='inline-block text-lg text-white hover:text-gray-400'
                        href='#'
                      >
                        Cookie Settings
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='mt-32 pt-12 border-t border-gray-800'>
              <div className='flex flex-wrap items-center -mx-4'>
                <div className='w-full lg:w-1/3 px-4'>
                  <img
                    src='aurora-assets/logos/logo-aurora-sm-white.svg'
                    alt=''
                  />
                </div>
                <div className='w-full lg:w-1/3 px-4'>
                  <p className='text-gray-500'>
                    Made with heart © 2023 Aurora. All rights reserved.
                  </p>
                </div>
                <div className='w-full lg:w-1/3 px-4'>
                  <div className='flex items-center justify-end'>
                    <a
                      className='inline-flex mr-6 text-gray-400 hover:text-gray-50 items-center justify-center transition duration-200'
                      href='#'
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M22.46 6C21.69 6.35 20.86 6.58 20 6.69C20.88 6.16 21.56 5.32 21.88 4.31C21.05 4.81 20.13 5.16 19.16 5.36C18.37 4.5 17.26 4 16 4C13.65 4 11.73 5.92 11.73 8.29C11.73 8.63 11.77 8.96 11.84 9.27C8.28004 9.09 5.11004 7.38 3.00004 4.79C2.63004 5.42 2.42004 6.16 2.42004 6.94C2.42004 8.43 3.17004 9.75 4.33004 10.5C3.62004 10.5 2.96004 10.3 2.38004 10V10.03C2.38004 12.11 3.86004 13.85 5.82004 14.24C5.19077 14.4122 4.53013 14.4362 3.89004 14.31C4.16165 15.1625 4.69358 15.9084 5.41106 16.4429C6.12854 16.9775 6.99549 17.2737 7.89004 17.29C6.37367 18.4904 4.49404 19.1393 2.56004 19.13C2.22004 19.13 1.88004 19.11 1.54004 19.07C3.44004 20.29 5.70004 21 8.12004 21C16 21 20.33 14.46 20.33 8.79C20.33 8.6 20.33 8.42 20.32 8.23C21.16 7.63 21.88 6.87 22.46 6Z'
                          fill='currentColor'
                        />
                      </svg>
                    </a>
                    <a
                      className='inline-flex mr-6 text-gray-400 hover:text-gray-50 items-center justify-center transition duration-200'
                      href='#'
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19ZM18.5 18.5V13.2C18.5 12.3354 18.1565 11.5062 17.5452 10.8948C16.9338 10.2835 16.1046 9.94 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17C14.6813 12.17 15.0374 12.3175 15.2999 12.5801C15.5625 12.8426 15.71 13.1987 15.71 13.57V18.5H18.5ZM6.88 8.56C7.32556 8.56 7.75288 8.383 8.06794 8.06794C8.383 7.75288 8.56 7.32556 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19C6.43178 5.19 6.00193 5.36805 5.68499 5.68499C5.36805 6.00193 5.19 6.43178 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56ZM8.27 18.5V10.13H5.5V18.5H8.27Z'
                          fill='currentColor'
                        />
                      </svg>
                    </a>
                    <a
                      className='inline-flex text-gray-400 hover:text-gray-50 items-center justify-center transition duration-200'
                      href='#'
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z'
                          fill='currentColor'
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:pt-24 bg-radial-dark-green'>
          <div className='mb-12 pb-24 border-b border-gray-400'>
            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/2 px-4 mb-16'>
                  <div>
                    <img
                      className='block h-10 mb-24'
                      src='aurora-assets/logos/logo-aurora-sm-white.svg'
                      alt=''
                    />
                    <div>
                      <h6 className='text-lg font-semibold text-white mb-4'>
                        Join our newsletter
                      </h6>
                      <div className='xs:flex items-center max-w-md mb-6'>
                        <input
                          className='w-full h-10 mb-3 xs:mb-0 xs:mr-3 py-2 px-3 text-sm text-gray-400 placeholder-gray-400 bg-white bg-opacity-10 border border-teal-700 focus:border-yellowGreen-800 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-800'
                          type='email'
                          placeholder='Enter your email'
                        />
                        <button variant="classic"
                          className='xs:flex-shrink-0 group relative w-full xs:w-auto h-10 flex xs:inline-flex items-center justify-center px-4 p-px font-bold text-gray-900 hover:text-gray-50 bg-white hover:bg-teal-800 rounded-lg transition duration-300 focus:outline-none'
                          type='submit'
                        >
                          <span>Subscribe</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 ml-auto px-4'>
                  <div className='flex flex-wrap -mx-4 -mb-8'>
                    <div className='w-1/2 xs:w-1/3 px-4 mb-8'>
                      <h5 className='text-xl font-semibold text-white mb-6'>
                        Product
                      </h5>
                      <ul>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            Features
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            Solutions
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            Pricing
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            Tutorials
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='w-1/2 xs:w-1/3 px-4 mb-8'>
                      <h5 className='text-xl font-semibold text-white mb-6'>
                        Company
                      </h5>
                      <ul>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            About
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            Our Mission
                          </a>
                        </li>
                        <li className='flex items-center mb-6'>
                          <a
                            className='inline-block mr-2 text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            <span>Careers</span>
                          </a>
                          <span className='inline-flex h-6 px-2 items-center text-white bg-teal-600 rounded-full'>
                            <svg
                              width={8}
                              height={8}
                              viewBox='0 0 8 8'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle cx={4} cy={4} r={3} fill='white' />
                            </svg>
                            <span className='ml-1 text-xs text-gray-50'>
                              Hiring
                            </span>
                          </span>
                        </li>
                        <li>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='w-full xs:w-1/3 px-4 mb-8'>
                      <h5 className='text-xl font-semibold text-white mb-6'>
                        Resources
                      </h5>
                      <ul>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            Press
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            Blog
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            Help Center
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-lg text-gray-300 hover:text-white'
                            href='#'
                          >
                            Support
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container px-4 mx-auto'>
            <div className='md:flex items-center justify-between'>
              <p className='text-gray-200 mb-8 md:mb-0'>
                Made with heart © 2023 Aurora. All rights reserved.
              </p>
              <div className='flex flex-col xs:flex-row items-center'>
                <a
                  className='inline-block font-medium text-gray-200 hover:text-white'
                  href='#'
                >
                  Privacy Policy
                </a>
                <span className='block my-4 xs:my-0 mx-6'>
                  <svg
                    width={8}
                    height={8}
                    viewBox='0 0 8 8'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle cx={4} cy={4} r={3} fill='#D2DAD5' />
                  </svg>
                </span>
                <a
                  className='inline-block font-medium text-gray-200 hover:text-white'
                  href='#'
                >
                  Terms of Conditions
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className='pb-12 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='pt-12 md:pt-24 px-5 xs:px-12 xl:px-24 pb-8 mb-12 max-w-6xl mx-auto bg-white bg-opacity-10 border-l border-b border-r border-white border-opacity-20 rounded-b-2xl'>
              <div className='flex flex-wrap -mx-4 -mb-8'>
                <div className='w-1/2 md:w-1/4 px-4 mb-8'>
                  <h5 className='text-xl font-semibold text-white mb-6'>
                    Product
                  </h5>
                  <ul>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Features
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Solutions
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Pricing
                      </a>
                    </li>
                    <li>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Tutorials
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='w-1/2 md:w-1/4 px-4 mb-8'>
                  <h5 className='text-xl font-semibold text-white mb-6'>
                    Company
                  </h5>
                  <ul>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        About
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Our Mission
                      </a>
                    </li>
                    <li className='flex items-center mb-6'>
                      <a
                        className='inline-block mr-2 text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        <span>Careers</span>
                      </a>
                      <span className='inline-flex h-6 px-2 items-center text-white bg-teal-600 rounded-full'>
                        <svg
                          width={8}
                          height={8}
                          viewBox='0 0 8 8'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={4} cy={4} r={3} fill='white' />
                        </svg>
                        <span className='ml-1 text-xs text-gray-50'>
                          Hiring
                        </span>
                      </span>
                    </li>
                    <li>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='w-1/2 md:w-1/4 px-4 mb-8'>
                  <h5 className='text-xl font-semibold text-white mb-6'>
                    Resources
                  </h5>
                  <ul>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Press
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Blog
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Help Center
                      </a>
                    </li>
                    <li>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Support
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='w-1/2 md:w-1/4 px-4 mb-8'>
                  <h5 className='text-xl font-semibold text-white mb-6'>
                    Legal
                  </h5>
                  <ul>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Terms of Use
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Legal Notice
                      </a>
                    </li>
                    <li>
                      <a
                        className='inline-block text-lg text-gray-400 hover:text-white'
                        href='#'
                      >
                        Cookie Settings
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='mt-24'>
                <div className='flex flex-col sm:flex-row items-center justify-between'>
                  <img
                    className='block h-8 mb-6 sm:mb-0'
                    src='aurora-assets/logos/logo-sign-md.png'
                    alt=''
                  />
                  <div className='flex flex-col sm:flex-row items-center'>
                    <a
                      className='inline-block font-medium text-gray-200 hover:text-white'
                      href='#'
                    >
                      Privacy Policy
                    </a>
                    <span className='block my-4 sm:my-0 mx-6'>
                      <svg
                        width={8}
                        height={8}
                        viewBox='0 0 8 8'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <circle cx={4} cy={4} r={3} fill='#D2DAD5' />
                      </svg>
                    </span>
                    <a
                      className='inline-block font-medium text-gray-200 hover:text-white'
                      href='#'
                    >
                      Terms of Conditions
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='text-center'>
              <p className='text-gray-200'>
                Made with heart © 2023 Aurora. All rights reserved.
              </p>
            </div>
          </div>
        </section>
        <section className='pb-12'>
          <div className='container px-4 mx-auto'>
            <div className='pt-12 md:pt-24 px-5 xs:px-12 xl:px-24 pb-8 mb-12 max-w-6xl mx-auto border-l border-b border-r border-gray-200 rounded-b-2xl'>
              <div className='flex flex-wrap -mx-4 -mb-8'>
                <div className='w-1/2 md:w-1/4 px-4 mb-8'>
                  <h5 className='text-xl font-semibold mb-6'>Product</h5>
                  <ul>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-500 hover:text-gray-900'
                        href='#'
                      >
                        Features
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-500 hover:text-gray-900'
                        href='#'
                      >
                        Solutions
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-500 hover:text-gray-900'
                        href='#'
                      >
                        Pricing
                      </a>
                    </li>
                    <li>
                      <a
                        className='inline-block text-lg text-gray-500 hover:text-gray-900'
                        href='#'
                      >
                        Tutorials
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='w-1/2 md:w-1/4 px-4 mb-8'>
                  <h5 className='text-xl font-semibold mb-6'>Company</h5>
                  <ul>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-500 hover:text-gray-900'
                        href='#'
                      >
                        About
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-500 hover:text-gray-900'
                        href='#'
                      >
                        Our Mission
                      </a>
                    </li>
                    <li className='flex items-center mb-6'>
                      <a
                        className='inline-block mr-2 text-lg text-gray-500 hover:text-gray-900'
                        href='#'
                      >
                        <span>Careers</span>
                      </a>
                      <span className='inline-flex h-6 px-2 items-center text-white bg-teal-600 rounded-full'>
                        <svg
                          width={8}
                          height={8}
                          viewBox='0 0 8 8'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle cx={4} cy={4} r={3} fill='white' />
                        </svg>
                        <span className='ml-1 text-xs text-gray-50'>
                          Hiring
                        </span>
                      </span>
                    </li>
                    <li>
                      <a
                        className='inline-block text-lg text-gray-500 hover:text-gray-900'
                        href='#'
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='w-1/2 md:w-1/4 px-4 mb-8'>
                  <h5 className='text-xl font-semibold mb-6'>Resources</h5>
                  <ul>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-500 hover:text-gray-900'
                        href='#'
                      >
                        Press
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-500 hover:text-gray-900'
                        href='#'
                      >
                        Blog
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-500 hover:text-gray-900'
                        href='#'
                      >
                        Help Center
                      </a>
                    </li>
                    <li>
                      <a
                        className='inline-block text-lg text-gray-500 hover:text-gray-900'
                        href='#'
                      >
                        Support
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='w-1/2 md:w-1/4 px-4 mb-8'>
                  <h5 className='text-xl font-semibold mb-6'>Legal</h5>
                  <ul>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-500 hover:text-gray-900'
                        href='#'
                      >
                        Terms of Use
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-500 hover:text-gray-900'
                        href='#'
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li className='mb-6'>
                      <a
                        className='inline-block text-lg text-gray-500 hover:text-gray-900'
                        href='#'
                      >
                        Legal Notice
                      </a>
                    </li>
                    <li>
                      <a
                        className='inline-block text-lg text-gray-500 hover:text-gray-900'
                        href='#'
                      >
                        Cookie Settings
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='mt-24'>
                <div className='flex flex-col sm:flex-row items-center justify-between'>
                  <img
                    className='block h-8 mb-6 sm:mb-0'
                    src='aurora-assets/logos/logo-sign-md.png'
                    alt=''
                  />
                  <div className='flex flex-col sm:flex-row items-center'>
                    <a
                      className='inline-block font-medium text-gray-500 hover:text-gray-900'
                      href='#'
                    >
                      Privacy Policy
                    </a>
                    <span className='block my-4 sm:my-0 mx-6'>
                      <svg
                        width={8}
                        height={8}
                        viewBox='0 0 8 8'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <circle cx={4} cy='4.00012' r={3} fill='#D2DAD5' />
                      </svg>
                    </span>
                    <a
                      className='inline-block font-medium text-gray-500 hover:text-gray-900'
                      href='#'
                    >
                      Terms of Conditions
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='text-center'>
              <p className='text-gray-500'>
                Made with heart © 2023 Aurora. All rights reserved.
              </p>
            </div>
          </div>
        </section>
        <section className='py-12 md:pt-24 bg-gray-50'>
          <div className='mb-12 pb-24 border-b border-gray-200'>
            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/2 px-4 mb-16'>
                  <div>
                    <img
                      className='block h-10 mb-24'
                      src='aurora-assets/logos/logo-aurora-sm.svg'
                      alt=''
                    />
                    <div>
                      <h6 className='text-lg font-semibold mb-4'>
                        Join our newsletter
                      </h6>
                      <div className='xs:flex items-center max-w-md mb-6'>
                        <input
                          className='w-full h-10 mb-3 xs:mb-0 xs:mr-3 py-2 px-3 text-sm text-gray-500 placeholder-gray-500 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500'
                          type='email'
                          placeholder='Enter your email'
                        />
                        <button variant="classic"
                          className='xs:flex-shrink-0 group relative block w-full xs:w-auto h-10 xs:inline-block p-px text-sm font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300 focus:outline-none'
                          type='submit'
                        >
                          <div className='relative flex items-center justify-center px-5 h-full bg-gradient-to-br from-cyanGreen-800 to-cyan-800 group-hover:text-green-200 rounded-lg transition duration-300'>
                            <span className='-mb-px'>Subscribe</span>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 ml-auto px-4'>
                  <div className='flex flex-wrap -mx-4 -mb-8'>
                    <div className='w-1/2 xs:w-1/3 px-4 mb-8'>
                      <h5 className='text-xl font-semibold mb-6'>Product</h5>
                      <ul>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-500 hover:text-gray-900'
                            href='#'
                          >
                            Features
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-500 hover:text-gray-900'
                            href='#'
                          >
                            Solutions
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-500 hover:text-gray-900'
                            href='#'
                          >
                            Pricing
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-lg text-gray-500 hover:text-gray-900'
                            href='#'
                          >
                            Tutorials
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='w-1/2 xs:w-1/3 px-4 mb-8'>
                      <h5 className='text-xl font-semibold mb-6'>Company</h5>
                      <ul>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-500 hover:text-gray-900'
                            href='#'
                          >
                            About
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-500 hover:text-gray-900'
                            href='#'
                          >
                            Our Mission
                          </a>
                        </li>
                        <li className='flex items-center mb-6'>
                          <a
                            className='inline-block mr-2 text-lg text-gray-500 hover:text-gray-900'
                            href='#'
                          >
                            <span>Careers</span>
                          </a>
                          <span className='inline-flex h-6 px-2 items-center text-white bg-teal-600 rounded-full'>
                            <svg
                              width={8}
                              height={8}
                              viewBox='0 0 8 8'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle cx={4} cy={4} r={3} fill='white' />
                            </svg>
                            <span className='ml-1 text-xs text-gray-50'>
                              Hiring
                            </span>
                          </span>
                        </li>
                        <li>
                          <a
                            className='inline-block text-lg text-gray-500 hover:text-gray-900'
                            href='#'
                          >
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='w-full xs:w-1/3 px-4 mb-8'>
                      <h5 className='text-xl font-semibold mb-6'>Resources</h5>
                      <ul>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-500 hover:text-gray-900'
                            href='#'
                          >
                            Press
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-500 hover:text-gray-900'
                            href='#'
                          >
                            Blog
                          </a>
                        </li>
                        <li className='mb-6'>
                          <a
                            className='inline-block text-lg text-gray-500 hover:text-gray-900'
                            href='#'
                          >
                            Help Center
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-lg text-gray-500 hover:text-gray-900'
                            href='#'
                          >
                            Support
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container px-4 mx-auto'>
            <div className='md:flex items-center justify-between'>
              <p className='text-gray-500 mb-8 md:mb-0'>
                Made with heart © 2023 Aurora. All rights reserved.
              </p>
              <div className='flex flex-col xs:flex-row items-center'>
                <a
                  className='inline-block font-medium text-gray-500 hover:text-gray-900'
                  href='#'
                >
                  Privacy Policy
                </a>
                <span className='block my-4 xs:my-0 mx-6'>
                  <svg
                    width={8}
                    height={8}
                    viewBox='0 0 8 8'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle cx={4} cy='4.00012' r={3} fill='#D2DAD5' />
                  </svg>
                </span>
                <a
                  className='inline-block font-medium text-gray-500 hover:text-gray-900'
                  href='#'
                >
                  Terms of Conditions
                </a>
              </div>
            </div>
          </div>
        </section>
      </>
    <FooterComponent /></React.Fragment>
  );
}

