import React from 'react';

import { Flex, Text } from '@radix-ui/themes';


import {FooterComponent} from './Footers.jsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Testimonials() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto text-center'>
              <span className='inline-flex items-center h-6 mb-16 px-2 text-xs uppercase font-medium text-yellowGreen-700 bg-yellowGreen-200 rounded-full'>
                TESTIMONIALS
              </span>
              <h1 className='font-heading tracking-tight text-3xl xs:text-4xl sm:text-5xl font-semibold mb-16'>
                <span>We use Aurora daily and we’re</span>
                <span className='inline-block ml-2 pb-px px-2 bg-yellowGreen-600 text-white rounded-lg'>
                  very happy
                </span>
                <span>
                  that they are created this amazing bookmarking platform.
                </span>
              </h1>
              <div className='mb-16'>
                <span className='block text-xl font-semibold'>Ethan Rauf</span>
                <span className='text-base text-gray-500'>
                  Founder of AIME Inc.
                </span>
              </div>
              <div className='flex justify-center items-center'>
                <button variant="classic" className='inline-block pt-1.5 w-4 transform hover:scale-x-150 mr-2 bg-gray-200 hover:bg-gray-400 transition duration-200 rounded-full' />
                <button variant="classic" className='inline-block pt-1.5 w-4 mr-2 transform hover:scale-x-150 bg-gray-200 hover:bg-gray-400 transition duration-200 rounded-full' />
                <button variant="classic" className='inline-block pt-1.5 w-4 transform hover:scale-x-150 bg-gray-200 hover:bg-gray-400 transition duration-200 rounded-full' />
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto text-center'>
              <span className='inline-flex items-center h-6 mb-16 px-2 text-xs uppercase font-medium text-teal-50 bg-teal-800 rounded-full'>
                TESTIMONIALS
              </span>
              <h1 className='font-heading tracking-tight text-3xl xs:text-4xl sm:text-5xl font-semibold text-white mb-16'>
                <span>We use Aurora daily and we’re</span>
                <span className='inline-block ml-2 pb-px px-2 bg-yellowGreen-500 text-teal-900 rounded-lg'>
                  very happy
                </span>
                <span>
                  that they are created this amazing bookmarking platform.
                </span>
              </h1>
              <div className='mb-16'>
                <span className='block text-xl font-semibold text-white'>
                  Ethan Rauf
                </span>
                <span className='text-base text-gray-200'>
                  Founder of AIME Inc.
                </span>
              </div>
              <div className='flex justify-center items-center'>
                <button variant="classic" className='inline-block pt-1.5 w-4 transform hover:scale-x-150 mr-2 bg-gray-400 hover:bg-white transition duration-200 rounded-full' />
                <button variant="classic" className='inline-block pt-1.5 w-4 mr-2 transform hover:scale-x-150 bg-gray-400 hover:bg-white transition duration-200 rounded-full' />
                <button variant="classic" className='inline-block pt-1.5 w-4 transform hover:scale-x-150 bg-gray-400 hover:bg-white transition duration-200 rounded-full' />
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-7xl mx-auto'>
              <div className='max-w-2xl mx-auto mb-20 text-center'>
                <span className='inline-flex items-center h-6 mb-6 px-2 text-xs uppercase font-medium text-teal-50 bg-teal-800 rounded-full'>
                  TESTIMONIALS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white'>
                  Aurora is used by hundreds of companies and happy users
                </h1>
              </div>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/3 px-4 mb-10 lg:mb-0'>
                  <div className='flex flex-col max-w-sm mx-auto lg:max-w-none items-start h-full p-8 bg-teal-800 rounded-xl'>
                    <img
                      className='block h-8 mb-8'
                      src='aurora-assets/logo-clouds/logo-white-8.svg'
                      alt=''
                    />
                    <div className='pr-4'>
                      <p className='text-lg text-white font-medium mb-20'>
                        “We use Aurora daily and we’re very happy that they are
                        created this amazing bookmarking platform.”
                      </p>
                      <div className='flex items-center mt-auto'>
                        <img
                          className='block h-12 w-12 rounded-full'
                          src='aurora-assets/blog/avatar-medium.png'
                          alt=''
                        />
                        <div className='pl-4'>
                          <span className='block font-semibold text-white'>
                            Ethan Rauf
                          </span>
                          <span className='text-sm text-gray-400'>
                            Founder of AIME Inc.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4 mb-10 lg:mb-0'>
                  <div className='flex flex-col max-w-sm mx-auto lg:max-w-none items-start h-full p-8 bg-teal-800 rounded-xl'>
                    <img
                      className='block h-8 mb-8'
                      src='aurora-assets/logo-clouds/logo-white-2.svg'
                      alt=''
                    />
                    <div className='pr-4'>
                      <p className='text-lg text-white font-medium mb-20'>
                        “We use Aurora daily and we’re very happy that they are
                        created this amazing bookmarking platform.”
                      </p>
                      <div className='flex items-center mt-auto'>
                        <img
                          className='block h-12 w-12 rounded-full'
                          src='aurora-assets/blog/avatar-medium.png'
                          alt=''
                        />
                        <div className='pl-4'>
                          <span className='block font-semibold text-white'>
                            Hanna Stanton
                          </span>
                          <span className='text-sm text-gray-400'>
                            Founder of AIME Inc.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4'>
                  <div className='flex flex-col max-w-sm mx-auto lg:max-w-none items-start h-full p-8 bg-teal-800 rounded-xl'>
                    <img
                      className='block h-8 mb-8'
                      src='aurora-assets/logo-clouds/logo-white-3.svg'
                      alt=''
                    />
                    <div className='pr-4'>
                      <p className='text-lg text-white font-medium mb-20'>
                        “We use Aurora daily and we’re very happy that they are
                        created this amazing bookmarking platform.”
                      </p>
                      <div className='flex items-center mt-auto'>
                        <img
                          className='block h-12 w-12 rounded-full'
                          src='aurora-assets/blog/avatar-medium.png'
                          alt=''
                        />
                        <div className='pl-4'>
                          <span className='block font-semibold text-white'>
                            Cooper Levin
                          </span>
                          <span className='text-sm text-gray-400'>
                            Founder of AIME Inc.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-50'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-6xl mx-auto text-center'>
              <span className='inline-flex items-center h-6 mb-16 px-2 text-xs uppercase font-medium text-gray-700 bg-gray-100 rounded-full'>
                TESTIMONIALS
              </span>
              <h1 className='font-heading tracking-tight text-3xl xs:text-4xl sm:text-5xl font-semibold mb-16'>
                <span>We use Aurora daily and we’re</span>
                <span className='inline-block ml-2 pb-px px-2 bg-yellowGreen-600 text-white rounded-lg'>
                  very happy
                </span>
                <span>
                  that they are created this amazing bookmarking platform.
                </span>
              </h1>
              <div className='mb-16'>
                <span className='block text-xl font-semibold'>Ethan Rauf</span>
                <span className='text-base text-gray-500'>
                  Founder of AIME Inc.
                </span>
              </div>
              <div className='flex justify-center items-center'>
                <button variant="classic" className='inline-block pt-1.5 w-4 transform hover:scale-x-150 mr-2 bg-gray-200 hover:bg-gray-400 transition duration-200 rounded-full' />
                <button variant="classic" className='inline-block pt-1.5 w-4 mr-2 transform hover:scale-x-150 bg-gray-200 hover:bg-gray-400 transition duration-200 rounded-full' />
                <button variant="classic" className='inline-block pt-1.5 w-4 transform hover:scale-x-150 bg-gray-200 hover:bg-gray-400 transition duration-200 rounded-full' />
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-7xl mx-auto'>
              <div className='max-w-2xl mx-auto mb-20 text-center'>
                <span className='inline-flex items-center h-6 mb-6 px-2 text-xs uppercase font-medium text-teal-50 bg-teal-800 rounded-full'>
                  TESTIMONIALS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white'>
                  Aurora is used by hundreds of companies and happy users
                </h1>
              </div>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/3 px-4 mb-10 lg:mb-0'>
                  <div className='flex flex-col max-w-sm mx-auto lg:max-w-none items-start h-full p-8 bg-white bg-opacity-10 border border-white border-opacity-20 rounded-xl'>
                    <img
                      className='block h-8 mb-8'
                      src='aurora-assets/logo-clouds/logo-white-8.svg'
                      alt=''
                    />
                    <div className='pr-4'>
                      <p className='text-lg text-white font-medium mb-20'>
                        “We use Aurora daily and we’re very happy that they are
                        created this amazing bookmarking platform.”
                      </p>
                      <div className='flex items-center mt-auto'>
                        <img
                          className='block h-12 w-12 rounded-full'
                          src='aurora-assets/blog/avatar-medium.png'
                          alt=''
                        />
                        <div className='pl-4'>
                          <span className='block font-semibold text-white'>
                            Ethan Rauf
                          </span>
                          <span className='text-sm text-gray-200'>
                            Founder of AIME Inc.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4 mb-10 lg:mb-0'>
                  <div className='flex flex-col max-w-sm mx-auto lg:max-w-none items-start h-full p-8 bg-white bg-opacity-10 border border-white border-opacity-20 rounded-xl'>
                    <img
                      className='block h-8 mb-8'
                      src='aurora-assets/logo-clouds/logo-white-2.svg'
                      alt=''
                    />
                    <div className='pr-4'>
                      <p className='text-lg text-white font-medium mb-20'>
                        “We use Aurora daily and we’re very happy that they are
                        created this amazing bookmarking platform.”
                      </p>
                      <div className='flex items-center mt-auto'>
                        <img
                          className='block h-12 w-12 rounded-full'
                          src='aurora-assets/blog/avatar-medium.png'
                          alt=''
                        />
                        <div className='pl-4'>
                          <span className='block font-semibold text-white'>
                            Hanna Stanton
                          </span>
                          <span className='text-sm text-gray-200'>
                            Founder of AIME Inc.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4'>
                  <div className='flex flex-col max-w-sm mx-auto lg:max-w-none items-start h-full p-8 bg-white bg-opacity-10 border border-white border-opacity-20 rounded-xl'>
                    <img
                      className='block h-8 mb-8'
                      src='aurora-assets/logo-clouds/logo-white-3.svg'
                      alt=''
                    />
                    <div className='pr-4'>
                      <p className='text-lg text-white font-medium mb-20'>
                        “We use Aurora daily and we’re very happy that they are
                        created this amazing bookmarking platform.”
                      </p>
                      <div className='flex items-center mt-auto'>
                        <img
                          className='block h-12 w-12 rounded-full'
                          src='aurora-assets/blog/avatar-medium.png'
                          alt=''
                        />
                        <div className='pl-4'>
                          <span className='block font-semibold text-white'>
                            Cooper Levin
                          </span>
                          <span className='text-sm text-gray-200'>
                            Founder of AIME Inc.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-7xl mx-auto'>
              <div className='max-w-2xl mx-auto mb-20 text-center'>
                <span className='inline-flex items-center h-6 mb-6 px-2 text-xs uppercase font-medium text-teal-50 bg-teal-800 rounded-full'>
                  TESTIMONIALS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white'>
                  Aurora is used by hundreds of companies and happy users
                </h1>
              </div>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/3 px-4 mb-10 lg:mb-0'>
                  <div className='flex flex-col max-w-sm mx-auto lg:max-w-none items-start h-full p-8 bg-white bg-opacity-10 border border-white border-opacity-20 rounded-xl'>
                    <img
                      className='block h-8 mb-8'
                      src='aurora-assets/logo-clouds/logo-white-8.svg'
                      alt=''
                    />
                    <div className='pr-4'>
                      <p className='text-lg text-white font-medium mb-20'>
                        “We use Aurora daily and we’re very happy that they are
                        created this amazing bookmarking platform.”
                      </p>
                      <div className='flex items-center mt-auto'>
                        <img
                          className='block h-12 w-12 rounded-full'
                          src='aurora-assets/blog/avatar-medium.png'
                          alt=''
                        />
                        <div className='pl-4'>
                          <span className='block font-semibold text-white'>
                            Ethan Rauf
                          </span>
                          <span className='text-sm text-gray-200'>
                            Founder of AIME Inc.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4 mb-10 lg:mb-0'>
                  <div className='flex flex-col max-w-sm mx-auto lg:max-w-none items-start h-full p-8 bg-white bg-opacity-10 border border-white border-opacity-20 rounded-xl'>
                    <img
                      className='block h-8 mb-8'
                      src='aurora-assets/logo-clouds/logo-white-2.svg'
                      alt=''
                    />
                    <div className='pr-4'>
                      <p className='text-lg text-white font-medium mb-20'>
                        “We use Aurora daily and we’re very happy that they are
                        created this amazing bookmarking platform.”
                      </p>
                      <div className='flex items-center mt-auto'>
                        <img
                          className='block h-12 w-12 rounded-full'
                          src='aurora-assets/blog/avatar-medium.png'
                          alt=''
                        />
                        <div className='pl-4'>
                          <span className='block font-semibold text-white'>
                            Hanna Stanton
                          </span>
                          <span className='text-sm text-gray-200'>
                            Founder of AIME Inc.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4'>
                  <div className='flex flex-col max-w-sm mx-auto lg:max-w-none items-start h-full p-8 bg-white bg-opacity-10 border border-white border-opacity-20 rounded-xl'>
                    <img
                      className='block h-8 mb-8'
                      src='aurora-assets/logo-clouds/logo-white-3.svg'
                      alt=''
                    />
                    <div className='pr-4'>
                      <p className='text-lg text-white font-medium mb-20'>
                        “We use Aurora daily and we’re very happy that they are
                        created this amazing bookmarking platform.”
                      </p>
                      <div className='flex items-center mt-auto'>
                        <img
                          className='block h-12 w-12 rounded-full'
                          src='aurora-assets/blog/avatar-medium.png'
                          alt=''
                        />
                        <div className='pl-4'>
                          <span className='block font-semibold text-white'>
                            Cooper Levin
                          </span>
                          <span className='text-sm text-gray-200'>
                            Founder of AIME Inc.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-md lg:max-w-7xl mx-auto py-12 px-6 sm:px-12 bg-white bg-opacity-10 border border-white border-opacity-20 rounded-xl'>
              <div className='flex flex-wrap justify-between'>
                <div className='w-full lg:w-6/12 xl:w-7/12 mb-16 lg:mb-0'>
                  <div className='max-w-lg'>
                    <a className='inline-block mb-12' href='#'>
                      <img
                        className='h-10'
                        src='aurora-assets/logos/logo-aurora-white.svg'
                        alt=''
                      />
                    </a>
                    <p className='text-xl font-medium text-white mb-12'>
                      “I've tried numerous assets manager platforms, but this
                      one truly stands out! Intuitive UI and robust features
                      boost efficiency.“
                    </p>
                    <div>
                      <span className='block text-base font-semibold text-white'>
                        Ethan Rauf
                      </span>
                      <span className='text-base text-gray-300'>
                        Founder of AIME Inc.
                      </span>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-5/12 xl:w-4/12 pt-16 lg:pt-0 border-t lg:border-t-0 lg:border-l border-white border-opacity-20'>
                  <img
                    className='block mx-auto lg:mr-0 w-52 sm:w-72 h-52 sm:h-72 rounded-full object-cover object-top'
                    src='aurora-assets/testimonials/testimonials-avatar2.png'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-sm md:max-w-7xl mx-auto'>
              <div className='max-w-2xl mx-auto mb-20 text-center'>
                <span className='inline-flex items-center h-6 mb-4 px-2 text-xs uppercase font-medium text-teal-50 bg-white bg-opacity-10 rounded-full'>
                  TESTIMONIALS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white'>
                  What they say about us
                </h1>
              </div>
              <div className='flex flex-wrap'>
                <div className='w-full md:w-1/2 lg:w-1/3'>
                  <div className='pt-10 px-6 xs:px-10 pb-14 bg-white bg-opacity-10 rounded-t-xl md:rounded-none md:rounded-tl-xl'>
                    <div className='flex items-center justify-between mb-8 pb-8 border-b border-white border-opacity-20'>
                      <div>
                        <span className='block text-base font-semibold text-white'>
                          Ethan Rauf
                        </span>
                        <span className='text-base text-gray-300'>
                          Founder of AIME Inc.
                        </span>
                      </div>
                      <div>
                        <a
                          className='inline-block text-gray-300 hover:text-gray-200 transition duration-200'
                          href='#'
                        >
                          <svg
                            width={32}
                            height={32}
                            viewBox='0 0 32 32'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M29.947 7.99999C28.9204 8.46666 27.8137 8.77333 26.667 8.91999C27.8404 8.21333 28.747 7.09333 29.1737 5.74666C28.067 6.41333 26.8404 6.87999 25.547 7.14666C24.4937 6 23.0137 5.33333 21.3337 5.33333C18.2004 5.33333 15.6404 7.89333 15.6404 11.0533C15.6404 11.5067 15.6937 11.9467 15.787 12.36C11.0404 12.12 6.81371 9.83999 4.00038 6.38666C3.50704 7.22666 3.22704 8.21333 3.22704 9.25333C3.22704 11.24 4.22704 13 5.77371 14C4.82704 14 3.94704 13.7333 3.17371 13.3333V13.3733C3.17371 16.1467 5.14704 18.4667 7.76038 18.9867C6.92135 19.2163 6.0405 19.2482 5.18704 19.08C5.54919 20.2166 6.25843 21.2112 7.21507 21.9239C8.17171 22.6366 9.32764 23.0316 10.5204 23.0533C8.49855 24.6539 5.99237 25.5191 3.41371 25.5067C2.96038 25.5067 2.50704 25.48 2.05371 25.4267C4.58704 27.0533 7.60038 28 10.827 28C21.3337 28 27.107 19.28 27.107 11.72C27.107 11.4667 27.107 11.2267 27.0937 10.9733C28.2137 10.1733 29.1737 9.15999 29.947 7.99999Z'
                              fill='currentColor'
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div>
                      <p className='text-2xl font-semibold text-white'>
                        “We've saved tons of time already. Thanks guys, keep up
                        the good work!”
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3'>
                  <div className='pt-10 px-10 pb-14'>
                    <div className='flex items-center justify-between mb-8 pb-8 border-b border-white border-opacity-20'>
                      <div>
                        <span className='block text-base font-semibold text-white'>
                          Ethan Rauf
                        </span>
                        <span className='text-base text-gray-300'>
                          Founder of AIME Inc.
                        </span>
                      </div>
                      <div>
                        <a
                          className='inline-block text-gray-300 hover:text-gray-200 transition duration-200'
                          href='#'
                        >
                          <svg
                            width={32}
                            height={32}
                            viewBox='0 0 32 32'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M29.947 7.99999C28.9204 8.46666 27.8137 8.77333 26.667 8.91999C27.8404 8.21333 28.747 7.09333 29.1737 5.74666C28.067 6.41333 26.8404 6.87999 25.547 7.14666C24.4937 6 23.0137 5.33333 21.3337 5.33333C18.2004 5.33333 15.6404 7.89333 15.6404 11.0533C15.6404 11.5067 15.6937 11.9467 15.787 12.36C11.0404 12.12 6.81371 9.83999 4.00038 6.38666C3.50704 7.22666 3.22704 8.21333 3.22704 9.25333C3.22704 11.24 4.22704 13 5.77371 14C4.82704 14 3.94704 13.7333 3.17371 13.3333V13.3733C3.17371 16.1467 5.14704 18.4667 7.76038 18.9867C6.92135 19.2163 6.0405 19.2482 5.18704 19.08C5.54919 20.2166 6.25843 21.2112 7.21507 21.9239C8.17171 22.6366 9.32764 23.0316 10.5204 23.0533C8.49855 24.6539 5.99237 25.5191 3.41371 25.5067C2.96038 25.5067 2.50704 25.48 2.05371 25.4267C4.58704 27.0533 7.60038 28 10.827 28C21.3337 28 27.107 19.28 27.107 11.72C27.107 11.4667 27.107 11.2267 27.0937 10.9733C28.2137 10.1733 29.1737 9.15999 29.947 7.99999Z'
                              fill='currentColor'
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div>
                      <p className='text-2xl font-semibold text-white'>
                        “We've saved tons of time already. Thanks guys, keep up
                        the good work!”
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3'>
                  <div className='pt-10 px-6 xs:px-10 pb-14 bg-white bg-opacity-10 md:bg-transparent lg:bg-white lg:bg-opacity-10 rounded-xl md:rounded-none lg:rounded-tr-xl'>
                    <div className='flex items-center justify-between mb-8 pb-8 border-b border-white border-opacity-20'>
                      <div>
                        <span className='block text-base font-semibold text-white'>
                          Ethan Rauf
                        </span>
                        <span className='text-base text-gray-300'>
                          Founder of AIME Inc.
                        </span>
                      </div>
                      <div>
                        <a
                          className='inline-block text-gray-300 hover:text-gray-200 transition duration-200'
                          href='#'
                        >
                          <svg
                            width={32}
                            height={32}
                            viewBox='0 0 32 32'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M29.947 7.99999C28.9204 8.46666 27.8137 8.77333 26.667 8.91999C27.8404 8.21333 28.747 7.09333 29.1737 5.74666C28.067 6.41333 26.8404 6.87999 25.547 7.14666C24.4937 6 23.0137 5.33333 21.3337 5.33333C18.2004 5.33333 15.6404 7.89333 15.6404 11.0533C15.6404 11.5067 15.6937 11.9467 15.787 12.36C11.0404 12.12 6.81371 9.83999 4.00038 6.38666C3.50704 7.22666 3.22704 8.21333 3.22704 9.25333C3.22704 11.24 4.22704 13 5.77371 14C4.82704 14 3.94704 13.7333 3.17371 13.3333V13.3733C3.17371 16.1467 5.14704 18.4667 7.76038 18.9867C6.92135 19.2163 6.0405 19.2482 5.18704 19.08C5.54919 20.2166 6.25843 21.2112 7.21507 21.9239C8.17171 22.6366 9.32764 23.0316 10.5204 23.0533C8.49855 24.6539 5.99237 25.5191 3.41371 25.5067C2.96038 25.5067 2.50704 25.48 2.05371 25.4267C4.58704 27.0533 7.60038 28 10.827 28C21.3337 28 27.107 19.28 27.107 11.72C27.107 11.4667 27.107 11.2267 27.0937 10.9733C28.2137 10.1733 29.1737 9.15999 29.947 7.99999Z'
                              fill='currentColor'
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div>
                      <p className='text-2xl font-semibold text-white'>
                        “We've saved tons of time already. Thanks guys, keep up
                        the good work!”
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3'>
                  <div className='pt-10 px-6 xs:px-10 pb-14 md:bg-white md:bg-opacity-10 lg:bg-transparent rounded-r-xl lg:rounded-none'>
                    <div className='flex items-center justify-between mb-8 pb-8 border-b border-white border-opacity-20'>
                      <div>
                        <span className='block text-base font-semibold text-white'>
                          Ethan Rauf
                        </span>
                        <span className='text-base text-gray-300'>
                          Founder of AIME Inc.
                        </span>
                      </div>
                      <div>
                        <a
                          className='inline-block text-gray-300 hover:text-gray-200 transition duration-200'
                          href='#'
                        >
                          <svg
                            width={32}
                            height={32}
                            viewBox='0 0 32 32'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M29.947 7.99999C28.9204 8.46666 27.8137 8.77333 26.667 8.91999C27.8404 8.21333 28.747 7.09333 29.1737 5.74666C28.067 6.41333 26.8404 6.87999 25.547 7.14666C24.4937 6 23.0137 5.33333 21.3337 5.33333C18.2004 5.33333 15.6404 7.89333 15.6404 11.0533C15.6404 11.5067 15.6937 11.9467 15.787 12.36C11.0404 12.12 6.81371 9.83999 4.00038 6.38666C3.50704 7.22666 3.22704 8.21333 3.22704 9.25333C3.22704 11.24 4.22704 13 5.77371 14C4.82704 14 3.94704 13.7333 3.17371 13.3333V13.3733C3.17371 16.1467 5.14704 18.4667 7.76038 18.9867C6.92135 19.2163 6.0405 19.2482 5.18704 19.08C5.54919 20.2166 6.25843 21.2112 7.21507 21.9239C8.17171 22.6366 9.32764 23.0316 10.5204 23.0533C8.49855 24.6539 5.99237 25.5191 3.41371 25.5067C2.96038 25.5067 2.50704 25.48 2.05371 25.4267C4.58704 27.0533 7.60038 28 10.827 28C21.3337 28 27.107 19.28 27.107 11.72C27.107 11.4667 27.107 11.2267 27.0937 10.9733C28.2137 10.1733 29.1737 9.15999 29.947 7.99999Z'
                              fill='currentColor'
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div>
                      <p className='text-2xl font-semibold text-white'>
                        “We've saved tons of time already. Thanks guys, keep up
                        the good work!”
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3'>
                  <div className='pt-10 px-6 xs:px-10 pb-14 bg-white bg-opacity-10 rounded-b-xl md:rounded-r-none md:rounded-bl-xl lg:rounded-b-xl'>
                    <div className='flex items-center justify-between mb-8 pb-8 border-b border-white border-opacity-20'>
                      <div>
                        <span className='block text-base font-semibold text-white'>
                          Ethan Rauf
                        </span>
                        <span className='text-base text-gray-300'>
                          Founder of AIME Inc.
                        </span>
                      </div>
                      <div>
                        <a
                          className='inline-block text-gray-300 hover:text-gray-200 transition duration-200'
                          href='#'
                        >
                          <svg
                            width={32}
                            height={32}
                            viewBox='0 0 32 32'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M29.947 7.99999C28.9204 8.46666 27.8137 8.77333 26.667 8.91999C27.8404 8.21333 28.747 7.09333 29.1737 5.74666C28.067 6.41333 26.8404 6.87999 25.547 7.14666C24.4937 6 23.0137 5.33333 21.3337 5.33333C18.2004 5.33333 15.6404 7.89333 15.6404 11.0533C15.6404 11.5067 15.6937 11.9467 15.787 12.36C11.0404 12.12 6.81371 9.83999 4.00038 6.38666C3.50704 7.22666 3.22704 8.21333 3.22704 9.25333C3.22704 11.24 4.22704 13 5.77371 14C4.82704 14 3.94704 13.7333 3.17371 13.3333V13.3733C3.17371 16.1467 5.14704 18.4667 7.76038 18.9867C6.92135 19.2163 6.0405 19.2482 5.18704 19.08C5.54919 20.2166 6.25843 21.2112 7.21507 21.9239C8.17171 22.6366 9.32764 23.0316 10.5204 23.0533C8.49855 24.6539 5.99237 25.5191 3.41371 25.5067C2.96038 25.5067 2.50704 25.48 2.05371 25.4267C4.58704 27.0533 7.60038 28 10.827 28C21.3337 28 27.107 19.28 27.107 11.72C27.107 11.4667 27.107 11.2267 27.0937 10.9733C28.2137 10.1733 29.1737 9.15999 29.947 7.99999Z'
                              fill='currentColor'
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div>
                      <p className='text-2xl font-semibold text-white'>
                        “We've saved tons of time already. Thanks guys, keep up
                        the good work!”
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3'>
                  <div className='pt-10 px-10 pb-14'>
                    <div className='flex items-center justify-between mb-8 pb-8 border-b border-white border-opacity-20'>
                      <div>
                        <span className='block text-base font-semibold text-white'>
                          Ethan Rauf
                        </span>
                        <span className='text-base text-gray-300'>
                          Founder of AIME Inc.
                        </span>
                      </div>
                      <div>
                        <a
                          className='inline-block text-gray-300 hover:text-gray-200 transition duration-200'
                          href='#'
                        >
                          <svg
                            width={32}
                            height={32}
                            viewBox='0 0 32 32'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M29.947 7.99999C28.9204 8.46666 27.8137 8.77333 26.667 8.91999C27.8404 8.21333 28.747 7.09333 29.1737 5.74666C28.067 6.41333 26.8404 6.87999 25.547 7.14666C24.4937 6 23.0137 5.33333 21.3337 5.33333C18.2004 5.33333 15.6404 7.89333 15.6404 11.0533C15.6404 11.5067 15.6937 11.9467 15.787 12.36C11.0404 12.12 6.81371 9.83999 4.00038 6.38666C3.50704 7.22666 3.22704 8.21333 3.22704 9.25333C3.22704 11.24 4.22704 13 5.77371 14C4.82704 14 3.94704 13.7333 3.17371 13.3333V13.3733C3.17371 16.1467 5.14704 18.4667 7.76038 18.9867C6.92135 19.2163 6.0405 19.2482 5.18704 19.08C5.54919 20.2166 6.25843 21.2112 7.21507 21.9239C8.17171 22.6366 9.32764 23.0316 10.5204 23.0533C8.49855 24.6539 5.99237 25.5191 3.41371 25.5067C2.96038 25.5067 2.50704 25.48 2.05371 25.4267C4.58704 27.0533 7.60038 28 10.827 28C21.3337 28 27.107 19.28 27.107 11.72C27.107 11.4667 27.107 11.2267 27.0937 10.9733C28.2137 10.1733 29.1737 9.15999 29.947 7.99999Z'
                              fill='currentColor'
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div>
                      <p className='text-2xl font-semibold text-white'>
                        “We've saved tons of time already. Thanks guys, keep up
                        the good work!”
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-md lg:max-w-7xl mx-auto py-12 px-6 sm:px-12 bg-gray-900 border-2 border-gray-800 shadow-4xl rounded-xl'>
              <div className='flex flex-wrap justify-between'>
                <div className='w-full lg:w-6/12 xl:w-7/12 mb-16 lg:mb-0'>
                  <div className='max-w-lg'>
                    <a className='inline-block mb-12' href='#'>
                      <img
                        className='h-10'
                        src='aurora-assets/logos/logo-aurora-white.svg'
                        alt=''
                      />
                    </a>
                    <p className='text-xl font-medium text-white mb-12'>
                      “I've tried numerous assets manager platforms, but this
                      one truly stands out! Intuitive UI and robust features
                      boost efficiency.“
                    </p>
                    <div>
                      <span className='block text-base font-semibold text-white'>
                        Ethan Rauf
                      </span>
                      <span className='text-base text-gray-400'>
                        Founder of AIME Inc.
                      </span>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-5/12 xl:w-4/12 pt-16 lg:pt-0 border-t lg:border-t-0 lg:border-l border-gray-800'>
                  <img
                    className='block mx-auto lg:mr-0 w-52 sm:w-72 h-52 sm:h-72 rounded-full object-cover object-top'
                    src='aurora-assets/testimonials/testimonials-avatar2.png'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-sm md:max-w-7xl mx-auto'>
              <div className='max-w-2xl mx-auto mb-20 text-center'>
                <span className='inline-flex items-center h-6 mb-4 px-2 text-xs uppercase font-medium text-teal-50 bg-gray-800 rounded-full'>
                  TESTIMONIALS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white'>
                  What they say about us
                </h1>
              </div>
              <div className='flex flex-wrap'>
                <div className='w-full md:w-1/2 lg:w-1/3'>
                  <div className='pt-10 px-6 xs:px-10 pb-14 bg-gray-800 rounded-t-xl md:rounded-none md:rounded-tl-xl'>
                    <div className='flex items-center justify-between mb-8 pb-8 border-b border-gray-700'>
                      <div>
                        <span className='block text-base font-semibold text-white'>
                          Ethan Rauf
                        </span>
                        <span className='text-base text-gray-400'>
                          Founder of AIME Inc.
                        </span>
                      </div>
                      <div>
                        <a
                          className='inline-block text-gray-400 hover:text-gray-200 transition duration-200'
                          href='#'
                        >
                          <svg
                            width={32}
                            height={32}
                            viewBox='0 0 32 32'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M29.947 7.99999C28.9204 8.46666 27.8137 8.77333 26.667 8.91999C27.8404 8.21333 28.747 7.09333 29.1737 5.74666C28.067 6.41333 26.8404 6.87999 25.547 7.14666C24.4937 6 23.0137 5.33333 21.3337 5.33333C18.2004 5.33333 15.6404 7.89333 15.6404 11.0533C15.6404 11.5067 15.6937 11.9467 15.787 12.36C11.0404 12.12 6.81371 9.83999 4.00038 6.38666C3.50704 7.22666 3.22704 8.21333 3.22704 9.25333C3.22704 11.24 4.22704 13 5.77371 14C4.82704 14 3.94704 13.7333 3.17371 13.3333V13.3733C3.17371 16.1467 5.14704 18.4667 7.76038 18.9867C6.92135 19.2163 6.0405 19.2482 5.18704 19.08C5.54919 20.2166 6.25843 21.2112 7.21507 21.9239C8.17171 22.6366 9.32764 23.0316 10.5204 23.0533C8.49855 24.6539 5.99237 25.5191 3.41371 25.5067C2.96038 25.5067 2.50704 25.48 2.05371 25.4267C4.58704 27.0533 7.60038 28 10.827 28C21.3337 28 27.107 19.28 27.107 11.72C27.107 11.4667 27.107 11.2267 27.0937 10.9733C28.2137 10.1733 29.1737 9.15999 29.947 7.99999Z'
                              fill='currentColor'
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div>
                      <p className='text-2xl font-semibold text-white'>
                        “We've saved tons of time already. Thanks guys, keep up
                        the good work!”
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3'>
                  <div className='pt-10 px-10 pb-14'>
                    <div className='flex items-center justify-between mb-8 pb-8 border-b border-gray-700'>
                      <div>
                        <span className='block text-base font-semibold text-white'>
                          Ethan Rauf
                        </span>
                        <span className='text-base text-gray-400'>
                          Founder of AIME Inc.
                        </span>
                      </div>
                      <div>
                        <a
                          className='inline-block text-gray-400 hover:text-gray-200 transition duration-200'
                          href='#'
                        >
                          <svg
                            width={32}
                            height={32}
                            viewBox='0 0 32 32'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M29.947 7.99999C28.9204 8.46666 27.8137 8.77333 26.667 8.91999C27.8404 8.21333 28.747 7.09333 29.1737 5.74666C28.067 6.41333 26.8404 6.87999 25.547 7.14666C24.4937 6 23.0137 5.33333 21.3337 5.33333C18.2004 5.33333 15.6404 7.89333 15.6404 11.0533C15.6404 11.5067 15.6937 11.9467 15.787 12.36C11.0404 12.12 6.81371 9.83999 4.00038 6.38666C3.50704 7.22666 3.22704 8.21333 3.22704 9.25333C3.22704 11.24 4.22704 13 5.77371 14C4.82704 14 3.94704 13.7333 3.17371 13.3333V13.3733C3.17371 16.1467 5.14704 18.4667 7.76038 18.9867C6.92135 19.2163 6.0405 19.2482 5.18704 19.08C5.54919 20.2166 6.25843 21.2112 7.21507 21.9239C8.17171 22.6366 9.32764 23.0316 10.5204 23.0533C8.49855 24.6539 5.99237 25.5191 3.41371 25.5067C2.96038 25.5067 2.50704 25.48 2.05371 25.4267C4.58704 27.0533 7.60038 28 10.827 28C21.3337 28 27.107 19.28 27.107 11.72C27.107 11.4667 27.107 11.2267 27.0937 10.9733C28.2137 10.1733 29.1737 9.15999 29.947 7.99999Z'
                              fill='currentColor'
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div>
                      <p className='text-2xl font-semibold text-white'>
                        “We've saved tons of time already. Thanks guys, keep up
                        the good work!”
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3'>
                  <div className='pt-10 px-6 xs:px-10 pb-14 bg-gray-800 md:bg-transparent lg:bg-gray-800 rounded-xl md:rounded-none lg:rounded-tr-xl'>
                    <div className='flex items-center justify-between mb-8 pb-8 border-b border-gray-700'>
                      <div>
                        <span className='block text-base font-semibold text-white'>
                          Ethan Rauf
                        </span>
                        <span className='text-base text-gray-400'>
                          Founder of AIME Inc.
                        </span>
                      </div>
                      <div>
                        <a
                          className='inline-block text-gray-400 hover:text-gray-200 transition duration-200'
                          href='#'
                        >
                          <svg
                            width={32}
                            height={32}
                            viewBox='0 0 32 32'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M29.947 7.99999C28.9204 8.46666 27.8137 8.77333 26.667 8.91999C27.8404 8.21333 28.747 7.09333 29.1737 5.74666C28.067 6.41333 26.8404 6.87999 25.547 7.14666C24.4937 6 23.0137 5.33333 21.3337 5.33333C18.2004 5.33333 15.6404 7.89333 15.6404 11.0533C15.6404 11.5067 15.6937 11.9467 15.787 12.36C11.0404 12.12 6.81371 9.83999 4.00038 6.38666C3.50704 7.22666 3.22704 8.21333 3.22704 9.25333C3.22704 11.24 4.22704 13 5.77371 14C4.82704 14 3.94704 13.7333 3.17371 13.3333V13.3733C3.17371 16.1467 5.14704 18.4667 7.76038 18.9867C6.92135 19.2163 6.0405 19.2482 5.18704 19.08C5.54919 20.2166 6.25843 21.2112 7.21507 21.9239C8.17171 22.6366 9.32764 23.0316 10.5204 23.0533C8.49855 24.6539 5.99237 25.5191 3.41371 25.5067C2.96038 25.5067 2.50704 25.48 2.05371 25.4267C4.58704 27.0533 7.60038 28 10.827 28C21.3337 28 27.107 19.28 27.107 11.72C27.107 11.4667 27.107 11.2267 27.0937 10.9733C28.2137 10.1733 29.1737 9.15999 29.947 7.99999Z'
                              fill='currentColor'
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div>
                      <p className='text-2xl font-semibold text-white'>
                        “We've saved tons of time already. Thanks guys, keep up
                        the good work!”
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3'>
                  <div className='pt-10 px-6 xs:px-10 pb-14 md:bg-gray-800 lg:bg-transparent rounded-r-xl lg:rounded-none'>
                    <div className='flex items-center justify-between mb-8 pb-8 border-b border-gray-700'>
                      <div>
                        <span className='block text-base font-semibold text-white'>
                          Ethan Rauf
                        </span>
                        <span className='text-base text-gray-400'>
                          Founder of AIME Inc.
                        </span>
                      </div>
                      <div>
                        <a
                          className='inline-block text-gray-400 hover:text-gray-200 transition duration-200'
                          href='#'
                        >
                          <svg
                            width={32}
                            height={32}
                            viewBox='0 0 32 32'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M29.947 7.99999C28.9204 8.46666 27.8137 8.77333 26.667 8.91999C27.8404 8.21333 28.747 7.09333 29.1737 5.74666C28.067 6.41333 26.8404 6.87999 25.547 7.14666C24.4937 6 23.0137 5.33333 21.3337 5.33333C18.2004 5.33333 15.6404 7.89333 15.6404 11.0533C15.6404 11.5067 15.6937 11.9467 15.787 12.36C11.0404 12.12 6.81371 9.83999 4.00038 6.38666C3.50704 7.22666 3.22704 8.21333 3.22704 9.25333C3.22704 11.24 4.22704 13 5.77371 14C4.82704 14 3.94704 13.7333 3.17371 13.3333V13.3733C3.17371 16.1467 5.14704 18.4667 7.76038 18.9867C6.92135 19.2163 6.0405 19.2482 5.18704 19.08C5.54919 20.2166 6.25843 21.2112 7.21507 21.9239C8.17171 22.6366 9.32764 23.0316 10.5204 23.0533C8.49855 24.6539 5.99237 25.5191 3.41371 25.5067C2.96038 25.5067 2.50704 25.48 2.05371 25.4267C4.58704 27.0533 7.60038 28 10.827 28C21.3337 28 27.107 19.28 27.107 11.72C27.107 11.4667 27.107 11.2267 27.0937 10.9733C28.2137 10.1733 29.1737 9.15999 29.947 7.99999Z'
                              fill='currentColor'
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div>
                      <p className='text-2xl font-semibold text-white'>
                        “We've saved tons of time already. Thanks guys, keep up
                        the good work!”
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3'>
                  <div className='pt-10 px-6 xs:px-10 pb-14 bg-gray-800 rounded-b-xl md:rounded-r-none md:rounded-bl-xl lg:rounded-b-xl'>
                    <div className='flex items-center justify-between mb-8 pb-8 border-b border-gray-700'>
                      <div>
                        <span className='block text-base font-semibold text-white'>
                          Ethan Rauf
                        </span>
                        <span className='text-base text-gray-400'>
                          Founder of AIME Inc.
                        </span>
                      </div>
                      <div>
                        <a
                          className='inline-block text-gray-400 hover:text-gray-200 transition duration-200'
                          href='#'
                        >
                          <svg
                            width={32}
                            height={32}
                            viewBox='0 0 32 32'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M29.947 7.99999C28.9204 8.46666 27.8137 8.77333 26.667 8.91999C27.8404 8.21333 28.747 7.09333 29.1737 5.74666C28.067 6.41333 26.8404 6.87999 25.547 7.14666C24.4937 6 23.0137 5.33333 21.3337 5.33333C18.2004 5.33333 15.6404 7.89333 15.6404 11.0533C15.6404 11.5067 15.6937 11.9467 15.787 12.36C11.0404 12.12 6.81371 9.83999 4.00038 6.38666C3.50704 7.22666 3.22704 8.21333 3.22704 9.25333C3.22704 11.24 4.22704 13 5.77371 14C4.82704 14 3.94704 13.7333 3.17371 13.3333V13.3733C3.17371 16.1467 5.14704 18.4667 7.76038 18.9867C6.92135 19.2163 6.0405 19.2482 5.18704 19.08C5.54919 20.2166 6.25843 21.2112 7.21507 21.9239C8.17171 22.6366 9.32764 23.0316 10.5204 23.0533C8.49855 24.6539 5.99237 25.5191 3.41371 25.5067C2.96038 25.5067 2.50704 25.48 2.05371 25.4267C4.58704 27.0533 7.60038 28 10.827 28C21.3337 28 27.107 19.28 27.107 11.72C27.107 11.4667 27.107 11.2267 27.0937 10.9733C28.2137 10.1733 29.1737 9.15999 29.947 7.99999Z'
                              fill='currentColor'
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div>
                      <p className='text-2xl font-semibold text-white'>
                        “We've saved tons of time already. Thanks guys, keep up
                        the good work!”
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/3'>
                  <div className='pt-10 px-10 pb-14'>
                    <div className='flex items-center justify-between mb-8 pb-8 border-b border-gray-700'>
                      <div>
                        <span className='block text-base font-semibold text-white'>
                          Ethan Rauf
                        </span>
                        <span className='text-base text-gray-400'>
                          Founder of AIME Inc.
                        </span>
                      </div>
                      <div>
                        <a
                          className='inline-block text-gray-400 hover:text-gray-200 transition duration-200'
                          href='#'
                        >
                          <svg
                            width={32}
                            height={32}
                            viewBox='0 0 32 32'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M29.947 7.99999C28.9204 8.46666 27.8137 8.77333 26.667 8.91999C27.8404 8.21333 28.747 7.09333 29.1737 5.74666C28.067 6.41333 26.8404 6.87999 25.547 7.14666C24.4937 6 23.0137 5.33333 21.3337 5.33333C18.2004 5.33333 15.6404 7.89333 15.6404 11.0533C15.6404 11.5067 15.6937 11.9467 15.787 12.36C11.0404 12.12 6.81371 9.83999 4.00038 6.38666C3.50704 7.22666 3.22704 8.21333 3.22704 9.25333C3.22704 11.24 4.22704 13 5.77371 14C4.82704 14 3.94704 13.7333 3.17371 13.3333V13.3733C3.17371 16.1467 5.14704 18.4667 7.76038 18.9867C6.92135 19.2163 6.0405 19.2482 5.18704 19.08C5.54919 20.2166 6.25843 21.2112 7.21507 21.9239C8.17171 22.6366 9.32764 23.0316 10.5204 23.0533C8.49855 24.6539 5.99237 25.5191 3.41371 25.5067C2.96038 25.5067 2.50704 25.48 2.05371 25.4267C4.58704 27.0533 7.60038 28 10.827 28C21.3337 28 27.107 19.28 27.107 11.72C27.107 11.4667 27.107 11.2267 27.0937 10.9733C28.2137 10.1733 29.1737 9.15999 29.947 7.99999Z'
                              fill='currentColor'
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div>
                      <p className='text-2xl font-semibold text-white'>
                        “We've saved tons of time already. Thanks guys, keep up
                        the good work!”
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    <FooterComponent /></React.Fragment>
  );
}

