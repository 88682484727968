import React from 'react';

import { Flex, Text, Button } from '@radix-ui/themes';


import {FooterComponent} from './Footers.jsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function HttpCodes() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='py-24 lg:py-40 bg-gray-50'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-lg mx-auto text-center'>
              <img
                className='block mx-auto mb-10'
                src='aurora-assets/http-codes/404.png'
                alt=''
              />
              <h4 className='text-4xl font-bold mb-4'>
                Oops. That page doesn’t exist.
              </h4>
              <p className='text-xl text-gray-500 mb-10'>
                Something went wrong, so this page is broken.
              </p>
              <a
                className='inline-block text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                href='#'
              >
                Go back to Homepage
              </a>
            </div>
          </div>
        </section>
        <section className='py-24 lg:py-40 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-sm md:max-w-xl lg:max-w-6xl mx-auto'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full md:w-4/12 px-4 mb-10 md:mb-0'>
                  <img
                    className='block'
                    src='aurora-assets/http-codes/image-circle-green.png'
                    alt=''
                  />
                </div>
                <div className='w-full md:w-8/12 px-4'>
                  <div>
                    <span className='block text-5xl font-bold text-teal-700 mb-4'>
                      404
                    </span>
                    <h3 className='text-4xl font-bold text-white mb-4'>
                      This page could not be found!
                    </h3>
                    <p className='text-xl text-gray-400 mb-6'>
                      This page you are looking does not exist, has been removed
                      or is temporarily unavailable. Please try again later.
                    </p>
                    <a
                      className='inline-block text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                      href='#'
                    >
                      Go back to Homepage
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-4xl mx-auto'>
              <img
                className='block w-full mb-16'
                src='aurora-assets/http-codes/image-banner-green.png'
                alt=''
              />
              <div className='text-center'>
                <h3 className='text-4xl md:text-5xl font-bold text-white mb-4'>
                  Oops. That page doesn’t exist.
                </h3>
                <p className='text-xl text-gray-400'>
                  Something went wrong, so this page is broken.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='py-24'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-4xl mx-auto'>
              <img
                className='block w-full mb-16'
                src='aurora-assets/http-codes/banner-light.png'
                alt=''
              />
              <div className='text-center'>
                <h3 className='text-4xl md:text-5xl font-bold mb-4'>
                  Oops. That page doesn’t exist.
                </h3>
                <p className='text-xl text-gray-500'>
                  Something went wrong, so this page is broken.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='py-24 lg:py-40'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-sm md:max-w-xl lg:max-w-6xl mx-auto'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full md:w-4/12 px-4 mb-10 md:mb-0'>
                  <img
                    className='block'
                    src='aurora-assets/http-codes/circle-folders.png'
                    alt=''
                  />
                </div>
                <div className='w-full md:w-8/12 px-4'>
                  <div>
                    <span className='block text-5xl font-bold text-gray-300 mb-4'>
                      404
                    </span>
                    <h3 className='text-4xl font-bold mb-4'>
                      This page could not be found!
                    </h3>
                    <p className='text-xl text-gray-500 mb-6'>
                      This page you are looking does not exist, has been removed
                      or is temporarily unavailable. Please try again later.
                    </p>
                    <a
                      className='inline-block text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                      href='#'
                    >
                      Go back to Homepage
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-24 lg:py-40 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-lg mx-auto text-center'>
              <img
                className='block mx-auto mb-10'
                src='aurora-assets/http-codes/404-green.png'
                alt=''
              />
              <h4 className='text-4xl font-bold text-white mb-4'>
                Oops. That page doesn’t exist.
              </h4>
              <p className='text-xl text-gray-400 mb-10'>
                Something went wrong, so this page is broken.
              </p>
              <a
                className='inline-block text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-700'
                href='#'
              >
                Go back to Homepage
              </a>
            </div>
          </div>
        </section>
        <section className='py-24 lg:py-40 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div>
              <img
                className='block mb-3'
                src='aurora-assets/http-codes/404-sm-dark.png'
                alt=''
              />
              <h4 className='text-4xl sm:text-5xl font-bold text-white mb-4'>
                Page not found
              </h4>
              <p className='text-xl text-gray-400 mb-10'>
                Something went wrong, so this page is broken.
              </p>
              <a
                className='group inline-block justify-center p-1 text-center text-base text-white font-semibold rounded-lg'
                href='#'
              >
                <div className='inline-flex items-stretch h-12 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                  <div className='flex items-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                    <span>Back to Home</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </section>
        <section className='py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-4xl mx-auto'>
              <img
                className='block w-full mb-16'
                src='aurora-assets/http-codes/bannre-image-dark.png'
                alt=''
              />
              <div className='text-center'>
                <h3 className='text-4xl md:text-5xl font-bold text-white mb-4'>
                  Oops. That page doesn’t exist.
                </h3>
                <p className='text-xl text-gray-400'>
                  Something went wrong, so this page is broken.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='py-24 lg:py-40 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div>
              <img
                className='block mb-3'
                src='aurora-assets/http-codes/404-sm-dark.png'
                alt=''
              />
              <h4 className='text-4xl sm:text-5xl font-bold text-white mb-4'>
                Page not found
              </h4>
              <p className='text-xl text-gray-400 mb-10'>
                Something went wrong, so this page is broken.
              </p>
              <a
                className='group inline-block justify-center p-1 text-center text-base text-white font-semibold rounded-lg'
                href='#'
              >
                <div className='inline-flex items-stretch h-12 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                  <div className='flex items-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                    <span>Back to Home</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </section>
        <section className='py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-4xl mx-auto'>
              <img
                className='block w-full mb-16'
                src='aurora-assets/http-codes/banner-gradient.png'
                alt=''
              />
              <div className='text-center'>
                <h3 className='text-4xl md:text-5xl font-bold text-white mb-4'>
                  Oops. That page doesn’t exist.
                </h3>
                <p className='text-xl text-gray-300'>
                  Something went wrong, so this page is broken.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='py-24 lg:py-40 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-sm md:max-w-xl lg:max-w-6xl mx-auto'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full md:w-4/12 px-4 mb-10 md:mb-0'>
                  <img
                    className='block'
                    src='aurora-assets/http-codes/circle-image-dark.png'
                    alt=''
                  />
                </div>
                <div className='w-full md:w-8/12 px-4'>
                  <div>
                    <span className='block text-5xl font-bold text-teal-700 mb-4'>
                      404
                    </span>
                    <h3 className='text-4xl font-bold text-white mb-4'>
                      This page could not be found!
                    </h3>
                    <p className='text-xl text-gray-400 mb-6'>
                      This page you are looking does not exist, has been removed
                      or is temporarily unavailable. Please try again later.
                    </p>
                    <a
                      className='inline-block text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                      href='#'
                    >
                      Go back to Homepage
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-24 lg:py-40'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-lg mx-auto text-center'>
              <img
                className='block mx-auto mb-10'
                src='aurora-assets/http-codes/404.png'
                alt=''
              />
              <h4 className='text-4xl font-bold mb-4'>
                Oops. That page doesn’t exist.
              </h4>
              <p className='text-xl text-gray-500 mb-10'>
                Something went wrong, so this page is broken.
              </p>
              <a
                className='inline-block text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600'
                href='#'
              >
                Go back to Homepage
              </a>
            </div>
          </div>
        </section>
      </>
    <FooterComponent /></React.Fragment>
  );
}

