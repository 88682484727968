import React from 'react';

import { Flex, Text, Button } from '@radix-ui/themes';


import {FooterComponent} from './Footers.jsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function LogoClouds() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='py-12 md:py-24 bg-gray-50'>
          <div className='container px-4 mx-auto'>
            <div className='text-center mb-10'>
              <h5 className='text-gray-400 text-sm'>TRUSTED BY THE BEST</h5>
            </div>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-gray-7.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-gray-6.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-gray-8.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-gray-5.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-gray-4.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-gray-3.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-gray-2.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-gray-1.svg'
                  alt=''
                />
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='text-center mb-10'>
              <h5 className='text-gray-200 text-sm'>TRUSTED BY THE BEST</h5>
            </div>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-gray-7.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-gray-6.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-gray-8.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-gray-5.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-gray-4.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-gray-3.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-gray-2.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-gray-1.svg'
                  alt=''
                />
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='text-center mb-10'>
              <h5 className='text-white text-sm'>TRUSTED BY THE BEST</h5>
            </div>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-white-7.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-white-6.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-white-8.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-white-5.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-white-4.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-white-3.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-white-2.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-white-1.svg'
                  alt=''
                />
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='py-12 md:py-18 px-8 md:px-10 lg:px-18 border-2 border-teal-800 rounded-xl'>
              <div className='text-center mb-20'>
                <h3 className='font-heading tracking-tight text-4xl md:text-5xl font-bold text-white'>
                  Let the numbers do the talking
                </h3>
              </div>
              <div className='flex flex-wrap -mx-4 -mb-10'>
                <div className='w-full md:w-1/3 px-4 mb-10'>
                  <div className='p-6 text-center bg-teal-800 border-2 border-teal-700 rounded-xl'>
                    <span className='block mb-4 text-4xl font-bold text-white'>
                      4.7
                    </span>
                    <span className='text-lg text-gray-400'>
                      Stars on App Store
                    </span>
                  </div>
                </div>
                <div className='w-full md:w-1/3 px-4 mb-10'>
                  <div className='p-6 text-center bg-teal-800 border-2 border-teal-700 rounded-xl'>
                    <span className='block mb-4 text-4xl font-bold text-white'>
                      2M+
                    </span>
                    <span className='text-lg text-gray-400'>
                      Happy Global Users
                    </span>
                  </div>
                </div>
                <div className='w-full md:w-1/3 px-4 mb-10'>
                  <div className='p-6 text-center bg-teal-800 border-2 border-teal-700 rounded-xl'>
                    <span className='block mb-4 text-4xl font-bold text-white'>
                      80%
                    </span>
                    <span className='text-lg text-gray-400'>
                      Productivity Increased
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-teal-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-7.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-teal-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-6.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-teal-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-8.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-teal-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-5.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-teal-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-4.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-teal-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-3.svg'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-gray-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-7.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-gray-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-6.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-gray-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-8.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-gray-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-5.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-gray-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-4.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-gray-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-3.svg'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='relative max-w-6xl w-full mx-auto overflow-hidden'>
              <div className='relative flex items-center mb-10'>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-7.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-6.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-8.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-5.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-4.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-7.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-6.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-8.svg' alt='' />
                </div>
              </div>
              <div className='relative flex items-center justify-end'>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-7.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-6.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-8.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-5.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-4.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-7.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-6.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-8.svg' alt='' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='relative max-w-6xl w-full mx-auto overflow-hidden'>
              <div className='relative flex items-center mb-10'>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-white border-opacity-20 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-7.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-white border-opacity-20 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-6.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-white border-opacity-20 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-8.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-white border-opacity-20 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-5.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-white border-opacity-20 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-4.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-white border-opacity-20 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-7.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-white border-opacity-20 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-6.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-white border-opacity-20 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-8.svg' alt='' />
                </div>
              </div>
              <div className='relative flex items-center justify-end'>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-white border-opacity-20 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-7.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-white border-opacity-20 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-6.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-white border-opacity-20 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-8.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-white border-opacity-20 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-5.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-white border-opacity-20 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-4.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-white border-opacity-20 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-7.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-white border-opacity-20 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-6.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-white border-opacity-20 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-8.svg' alt='' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='relative max-w-6xl w-full mx-auto overflow-hidden'>
              <div className='relative flex items-center mb-10'>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-7.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-6.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-8.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-5.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-4.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-7.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-6.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-8.svg' alt='' />
                </div>
              </div>
              <div className='relative flex items-center justify-end'>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-7.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-6.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-8.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-5.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-4.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-7.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-6.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-8.svg' alt='' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <div className='relative max-w-6xl w-full mx-auto overflow-hidden'>
              <div className='relative flex items-center mb-10'>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-7.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-6.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-8.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-5.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-4.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-7.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-6.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-8.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='relative flex items-center justify-end'>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-7.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-6.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-8.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-5.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-4.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-7.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-6.svg'
                    alt=''
                  />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 border border-gray-200 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-ipsum-8.svg'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-gray-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-7.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-gray-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-6.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-gray-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-8.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-gray-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-5.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-gray-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-4.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-gray-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-3.svg'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='relative max-w-6xl w-full mx-auto overflow-hidden'>
              <div className='relative flex items-center mb-10'>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-7.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-6.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-8.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-5.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-4.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-7.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-6.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-8.svg' alt='' />
                </div>
              </div>
              <div className='relative flex items-center justify-end'>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-7.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-6.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-8.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-5.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-4.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-7.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-6.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-gray-900 border border-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-8.svg' alt='' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-50'>
          <div className='container px-4 mx-auto'>
            <div className='text-center mb-10'>
              <h5 className='text-gray-400 text-lg'>
                <span>Used by</span>
                <span className='text-gray-500'>10,000+ businesses</span>
                <span>from independent users to SME</span>
              </h5>
            </div>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <img
                  className='block mx-auto'
                  src='aurora-assets/logo-clouds/logo-gray-7.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <img
                  className='block mx-auto'
                  src='aurora-assets/logo-clouds/logo-gray-6.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <img
                  className='block mx-auto'
                  src='aurora-assets/logo-clouds/logo-gray-8.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <img
                  className='block mx-auto'
                  src='aurora-assets/logo-clouds/logo-gray-5.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <img
                  className='block mx-auto'
                  src='aurora-assets/logo-clouds/logo-gray-4.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <img
                  className='block mx-auto'
                  src='aurora-assets/logo-clouds/logo-gray-3.svg'
                  alt=''
                />
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-50'>
          <div className='container px-4 mx-auto'>
            <div className='text-center mb-10'>
              <h5 className='text-gray-400 text-sm'>TRUSTED BY THE BEST</h5>
            </div>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <img
                  className='block mx-auto'
                  src='aurora-assets/logo-clouds/logo-gray-7.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <img
                  className='block mx-auto'
                  src='aurora-assets/logo-clouds/logo-gray-6.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <img
                  className='block mx-auto'
                  src='aurora-assets/logo-clouds/logo-gray-8.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <img
                  className='block mx-auto'
                  src='aurora-assets/logo-clouds/logo-gray-5.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <img
                  className='block mx-auto'
                  src='aurora-assets/logo-clouds/logo-gray-4.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <img
                  className='block mx-auto'
                  src='aurora-assets/logo-clouds/logo-gray-3.svg'
                  alt=''
                />
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-teal-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-7.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-teal-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-6.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-teal-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-8.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-teal-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-5.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-teal-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-4.svg'
                    alt=''
                  />
                </div>
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10'>
                <div className='flex items-center justify-center lg:w-40 h-full px-5 py-3 bg-teal-800 rounded-xl'>
                  <img
                    src='aurora-assets/logo-clouds/logo-white-3.svg'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='relative max-w-6xl w-full mx-auto overflow-hidden'>
              <div className='relative flex items-center mb-10'>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-7.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-6.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-8.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-5.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-4.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-7.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-6.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingLeft flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-8.svg' alt='' />
                </div>
              </div>
              <div className='relative flex items-center justify-end'>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-7.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-6.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-8.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-5.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-4.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-7.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-6.svg' alt='' />
                </div>
                <div className='flex-shrink-0 animate-movingRight flex items-center justify-center w-48 h-14 mr-6 py-1 px-6 bg-teal-800 rounded-xl'>
                  <img src='aurora-assets/logo-clouds/logo-gray-8.svg' alt='' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='text-center mb-10'>
              <h5 className='text-white text-sm'>TRUSTED BY THE BEST</h5>
            </div>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-white-7.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-white-6.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-white-8.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-white-5.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-white-4.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-white-3.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-white-2.svg'
                  alt=''
                />
              </div>
              <div className='w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-10'>
                <img
                  className='block mx-auto md:mx-0'
                  src='aurora-assets/logo-clouds/logo-white-1.svg'
                  alt=''
                />
              </div>
            </div>
          </div>
        </section>
      </>
    <FooterComponent /></React.Fragment>
  );
}

