import React from 'react';

import { Flex, Text, Button } from '@radix-ui/themes';


import {FooterComponent} from './Footers.jsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Features() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='relative py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='relative pb-24 border-b border-opacity-70 border-yellowGreen-800 overflow-hidden'>
              <div className='max-w-2xl mx-auto mb-24 text-center'>
                <span className='inline-flex items-center h-6 mb-6 px-2 text-xs uppercase font-medium text-white bg-gray-800 rounded-full'>
                  Feature
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white'>
                  Easily organize your files
                </h1>
              </div>
              <div className='flex flex-wrap -mx-4 -mb-16'>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-gray-800'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2 11.4V4.6C2 4.26863 2.26863 4 2.6 4H11.0122C11.2128 4 11.4002 4.10026 11.5115 4.26718L13.7781 7.66718C13.9125 7.86872 13.9125 8.13128 13.7781 8.33282L11.5115 11.7328C11.4002 11.8997 11.2128 12 11.0122 12H2.6C2.26863 12 2 11.7314 2 11.4Z'
                          stroke='white'
                          strokeWidth='1.5'
                        />
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold text-white mb-1'>
                        Auto-tagging
                      </h5>
                      <p className='text-lg text-gray-400'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-gray-800'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21659)'>
                          <path
                            d='M2 7.33332V3.26666C2 2.93529 2.26863 2.66666 2.6 2.66666H6.44471C6.58794 2.66666 6.72644 2.71789 6.83519 2.8111L8.83148 4.52221C8.94023 4.61542 9.07873 4.66666 9.22195 4.66666H14.7333C15.0647 4.66666 15.3333 4.93529 15.3333 5.26666V7.33332M2 7.33332V12.7333C2 13.0647 2.26863 13.3333 2.6 13.3333H14.7333C15.0647 13.3333 15.3333 13.0647 15.3333 12.7333V7.33332M2 7.33332H15.3333'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold text-white mb-1'>
                        Categorized by collections
                      </h5>
                      <p className='text-lg text-gray-400'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-gray-800'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21666)'>
                          <path
                            d='M9.66602 7.99841C9.66602 6.33726 8.12137 4.66666 6.23744 4.66666C6.01429 4.66666 5.27871 4.66666 5.09459 4.66666C3.20104 4.66666 1.66602 6.15833 1.66602 7.99841C1.66602 9.58402 2.80583 10.9109 4.33268 11.2476C4.57772 11.3016 4.83272 11.3302 5.09459 11.3302'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7 7.99842C7 9.65957 8.54464 11.3302 10.4286 11.3302C10.6517 11.3302 11.3873 11.3302 11.5714 11.3302C13.465 11.3302 15 9.83849 15 7.99842C15 6.41281 13.8602 5.08591 12.3333 4.74923C12.0883 4.6952 11.8333 4.66667 11.5714 4.66667'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold text-white mb-1'>
                        Share links
                      </h5>
                      <p className='text-lg text-gray-400'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-gray-800'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21674)'>
                          <path
                            d='M0.666992 13.3333V12.6667C0.666992 10.0893 2.75633 8 5.33366 8V8C7.91099 8 10.0003 10.0893 10.0003 12.6667V13.3333'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                          />
                          <path
                            d='M8.66699 9.33333V9.33333C8.66699 7.49238 10.1594 6 12.0003 6V6C13.8413 6 15.3337 7.49239 15.3337 9.33334V9.66667'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                          />
                          <path
                            d='M5.33366 8C6.80642 8 8.00033 6.8061 8.00033 5.33334C8.00033 3.86058 6.80642 2.66667 5.33366 2.66667C3.8609 2.66667 2.66699 3.86058 2.66699 5.33334C2.66699 6.8061 3.8609 8 5.33366 8Z'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M12 6C13.1046 6 14 5.10457 14 4C14 2.89543 13.1046 2 12 2C10.8954 2 10 2.89543 10 4C10 5.10457 10.8954 6 12 6Z'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold text-white mb-1'>
                        Invite team members
                      </h5>
                      <p className='text-lg text-gray-400'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-gray-800'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M14.667 2.6V13.4C14.667 13.7314 14.3984 14 14.067 14H3.26699C2.93562 14 2.66699 13.7314 2.66699 13.4V2.6C2.66699 2.26863 2.93562 2 3.26699 2H14.067C14.3984 2 14.667 2.26863 14.667 2.6Z'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M7.56468 5.84629C7.16468 5.61772 6.66699 5.90654 6.66699 6.36724V9.63275C6.66699 10.0934 7.16468 10.3823 7.56468 10.1537L10.422 8.52094C10.8251 8.29061 10.8251 7.70939 10.422 7.47905L7.56468 5.84629Z'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold text-white mb-1'>
                        Video live preview
                      </h5>
                      <p className='text-lg text-gray-400'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-gray-800'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21688)'>
                          <path
                            d='M5.33203 12L7.33203 14L10.6654 10.6667'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M13.3327 11.7382C14.3285 11.3481 15.3327 10.4593 15.3327 8.66666C15.3327 6 13.1105 5.33333 11.9993 5.33333C11.9993 3.99999 11.9993 1.33333 7.99935 1.33333C3.99935 1.33333 3.99935 3.99999 3.99935 5.33333C2.88824 5.33333 0.666016 6 0.666016 8.66666C0.666016 10.4593 1.6702 11.3481 2.66602 11.7382'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold text-white mb-1'>
                        100GB cloud storage
                      </h5>
                      <p className='text-lg text-gray-400'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <img
                className='absolute bottom-0 left-1/2 transform -translate-x-1/2 xs:-mb-10 md:-mb-20 lg:-mb-32 block opacity-20'
                src='aurora-assets/features/teal-light-bottom.png'
                alt=''
              />
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24'>
          <img
            className='absolute bottom-0 right-0'
            src='aurora-assets/features/light-right-shadow.png'
            alt=''
          />
          <img
            className='absolute top-0 right-0'
            src='aurora-assets/features/stars-right.svg'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-5/12 px-4 mb-20 lg:mb-0'>
                <div className='max-w-xs mb-10'>
                  <span className='inline-flex items-center h-6 mb-4 px-2 text-sm uppercase font-medium text-yellowGreen-700 bg-yellowGreen-200 rounded-full'>
                    Feature
                  </span>
                  <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-4'>
                    Easily organize your files
                  </h1>
                  <p className='text-gray-700'>
                    Increase your marketing team efficiency with a quick search
                    and ease of use features.
                  </p>
                </div>
                <a
                  className='xs:flex-shrink-0 group relative w-full xs:w-auto h-12 flex xs:inline-flex items-center justify-center px-5 font-bold text-gray-700 bg-white border border-gray-200 hover:border-yellowGreen-600 shadow-md hover:shadow-none rounded-lg transition-all duration-300 focus:outline-none'
                  href='#'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-gray-100 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span className='mr-2'>Learn more</span>
                  <span className='transform group-hover:translate-x-1 transition duration-300'>
                    <svg
                      width={20}
                      height={20}
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.5 5L12.5 10L7.5 15'
                        stroke='#3B5444'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <div className='w-full lg:w-7/12 px-4'>
                <div>
                  <div className='flex items-center'>
                    <span className='mr-6 text-sm font-medium text-gray-700'>
                      Support
                    </span>
                    <ul className='flex flex-wrap -mb-2 items-center'>
                      <li className='mb-2 mr-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M14 2.6V13.4C14 13.7314 13.7314 14 13.4 14H2.6C2.26863 14 2 13.7314 2 13.4V2.6C2 2.26863 2.26863 2 2.6 2H13.4C13.7314 2 14 2.26863 14 2.6Z'
                              stroke='url(#paint0_linear_3021_28405)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M2 10.6667L6.66667 8.66666L14 12'
                              stroke='url(#paint1_linear_3021_28405)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M10.6667 6.66667C9.93029 6.66667 9.33333 6.06971 9.33333 5.33333C9.33333 4.59695 9.93029 4 10.6667 4C11.403 4 12 4.59695 12 5.33333C12 6.06971 11.403 6.66667 10.6667 6.66667Z'
                              stroke='url(#paint2_linear_3021_28405)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28405'
                                x1='14.1875'
                                y1='17.75'
                                x2={8}
                                y2={2}
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint1_linear_3021_28405'
                                x1='1.8125'
                                y1='7.62499'
                                x2='2.35713'
                                y2='12.6158'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint2_linear_3021_28405'
                                x1='12.0417'
                                y1='3.16667'
                                x2='10.6667'
                                y2='6.66667'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-gray-500 font-semibold'>
                            Photo
                          </span>
                        </a>
                      </li>
                      <li className='mb-2 mr-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M14 2.6V13.4C14 13.7314 13.7314 14 13.4 14H2.6C2.26863 14 2 13.7314 2 13.4V2.6C2 2.26863 2.26863 2 2.6 2H13.4C13.7314 2 14 2.26863 14 2.6Z'
                              stroke='url(#paint0_linear_3021_28409)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M6.89768 5.84631C6.49769 5.61774 6 5.90656 6 6.36725V9.63277C6 10.0935 6.49769 10.3823 6.89768 10.1537L9.75501 8.52096C10.1581 8.29062 10.1581 7.7094 9.75501 7.47906L6.89768 5.84631Z'
                              stroke='url(#paint1_linear_3021_28409)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28409'
                                x1='14.1875'
                                y1='17.75'
                                x2={8}
                                y2={2}
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint1_linear_3021_28409'
                                x1='5.92708'
                                y1='3.66668'
                                x2='8.94636'
                                y2='10.3914'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-gray-500 font-semibold'>
                            Video
                          </span>
                        </a>
                      </li>
                      <li className='mb-2 mr-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12 2L4 2C2.89543 2 2 2.89543 2 4L2 12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V4C14 2.89543 13.1046 2 12 2Z'
                              stroke='url(#paint0_linear_3021_28415)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M4.66699 4.66666L11.3337 4.66666'
                              stroke='url(#paint1_linear_3021_28415)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M4.66699 8L11.3337 8'
                              stroke='url(#paint2_linear_3021_28415)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M4.66699 11.3333L8.66699 11.3333'
                              stroke='url(#paint3_linear_3021_28415)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28415'
                                x1='-1.75'
                                y1='1.8125'
                                x2={14}
                                y2={8}
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint1_linear_3021_28415'
                                x1='2.58366'
                                y1='4.68228'
                                x2='3.8688'
                                y2='1.31644'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint2_linear_3021_28415'
                                x1='2.58366'
                                y1='8.01562'
                                x2='3.8688'
                                y2='4.64978'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint3_linear_3021_28415'
                                x1='3.41699'
                                y1='11.349'
                                x2='5.16378'
                                y2='8.60402'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-gray-500 font-semibold'>
                            File
                          </span>
                        </a>
                      </li>
                      <li className='mb-2 mr-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M2.66699 4V2.6C2.66699 2.26863 2.93562 2 3.26699 2H12.7337C13.065 2 13.3337 2.26863 13.3337 2.6V4'
                              stroke='url(#paint0_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                            />
                            <path
                              d='M2.66699 12V13.4C2.66699 13.7314 2.93562 14 3.26699 14H12.7337C13.065 14 13.3337 13.7314 13.3337 13.4V12'
                              stroke='url(#paint1_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                            />
                            <path
                              d='M10.333 10V6H12.333'
                              stroke='url(#paint2_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M10.333 8H11.6663'
                              stroke='url(#paint3_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M8 10L8 6'
                              stroke='url(#paint4_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M5.66699 6H3.66699L3.66699 10L5.66699 10V8.4'
                              stroke='url(#paint5_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28422'
                                x1='2.50033'
                                y1='1.375'
                                x2='2.72232'
                                y2='4.38878'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint1_linear_3021_28422'
                                x1='2.50033'
                                y1='14.625'
                                x2='2.72232'
                                y2='11.6112'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint2_linear_3021_28422'
                                x1='9.70801'
                                y1='10.0625'
                                x2='12.6256'
                                y2='9.48941'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint3_linear_3021_28422'
                                x1='9.91634'
                                y1='8.01562'
                                x2='11.5015'
                                y2='7.1853'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint4_linear_3021_28422'
                                x1='7.6875'
                                y1='10.0625'
                                x2='9.18809'
                                y2='9.91512'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint5_linear_3021_28422'
                                x1='3.04199'
                                y1='10.0625'
                                x2='5.95955'
                                y2='9.48941'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-gray-500 font-semibold'>
                            GIF
                          </span>
                        </a>
                      </li>
                      <li className='mb-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M9 4L6.66667 12.3333M4.33333 5.66667L2 8L4.33333 10.3333M11.6667 5.66667L14 8L11.6667 10.3333'
                              stroke='url(#paint0_linear_3021_28440)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28440'
                                x1='1.8125'
                                y1='1.39583'
                                x2='5.01836'
                                y2='13.1468'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-gray-500 font-semibold'>
                            Code Snippets
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <img
                    className='block w-full'
                    src='aurora-assets/features/image-feature-2.png'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full md:w-1/2 lg:w-5/12 px-4 mb-20 lg:mb-0'>
                <div className='max-w-xs mb-10'>
                  <span className='inline-flex items-center h-6 mb-4 px-2 text-sm uppercase font-medium text-yellowGreen-700 bg-yellowGreen-200 rounded-full'>
                    Feature
                  </span>
                  <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-4'>
                    Easily organize your files
                  </h1>
                  <p className='text-gray-700 mb-10'>
                    Increase your marketing team efficiency with a quick search
                    and ease of use features.
                  </p>
                  <ul>
                    <li className='flex mb-5 items-center'>
                      <div className='flex items-center justify-center w-8 h-8 mr-3 p-px rounded-full bg-gradient-to-br from-cyanGreen-800 to-cyan-800'>
                        <div className='flex items-center justify-center bg-white h-full w-full rounded-full'>
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M2 11.6V4.4C2 4.29391 2.04214 4.19217 2.11716 4.11716C2.19217 4.04214 2.29391 4 2.4 4H11.1193C11.2527 4 11.378 4.06667 11.4527 4.178L13.8527 7.778C13.8965 7.84373 13.9199 7.92098 13.9199 8C13.9199 8.07902 13.8965 8.15627 13.8527 8.222L11.4527 11.822C11.4161 11.8768 11.3665 11.9218 11.3083 11.9528C11.2502 11.9839 11.1853 12.0001 11.1193 12H2.4C2.34747 12 2.29546 11.9897 2.24693 11.9696C2.1984 11.9495 2.1543 11.92 2.11716 11.8828C2.08001 11.8457 2.05055 11.8016 2.03045 11.7531C2.01035 11.7045 2 11.6525 2 11.6Z'
                              stroke='url(#paint0_linear_3018_20784)'
                              strokeWidth='1.5'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3018_20784'
                                x1='1.81375'
                                y1='1.5'
                                x2='4.80178'
                                y2='12.8327'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                        </div>
                      </div>
                      <span className='font-semibold'>Auto-tagging</span>
                    </li>
                    <li className='flex mb-5 items-center'>
                      <div className='flex items-center justify-center w-8 h-8 mr-3 p-px rounded-full bg-gradient-to-br from-gray-200 to-gray-200'>
                        <div className='flex items-center justify-center bg-white h-full w-full rounded-full'>
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <g clipPath='url(#clip0_3018_20789)'>
                              <path
                                d='M3.33333 2.66667H6L8 4.66667H12.6667C13.0203 4.66667 13.3594 4.80715 13.6095 5.0572C13.8595 5.30724 14 5.64638 14 6.00001V11.3333C14 11.687 13.8595 12.0261 13.6095 12.2761C13.3594 12.5262 13.0203 12.6667 12.6667 12.6667H3.33333C2.97971 12.6667 2.64057 12.5262 2.39052 12.2761C2.14048 12.0261 2 11.687 2 11.3333V4.00001C2 3.64638 2.14048 3.30724 2.39052 3.0572C2.64057 2.80715 2.97971 2.66667 3.33333 2.66667Z'
                                stroke='#6C7F73'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </g>
                            <defs>
                              <clipPath id='clip0_3018_20789'>
                                <rect width={16} height={16} fill='white' />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                      <span className='text-gray-500'>
                        Categorized by collections
                      </span>
                    </li>
                    <li className='flex items-center'>
                      <div className='flex items-center justify-center w-8 h-8 mr-3 p-px rounded-full bg-gradient-to-br from-gray-200 to-gray-200'>
                        <div className='flex items-center justify-center bg-white h-full w-full rounded-full'>
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12 14.6667C13.1046 14.6667 14 13.7712 14 12.6667C14 11.5621 13.1046 10.6667 12 10.6667C10.8954 10.6667 10 11.5621 10 12.6667C10 13.7712 10.8954 14.6667 12 14.6667Z'
                              stroke='#6C7F73'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M12 5.33334C13.1046 5.33334 14 4.43791 14 3.33334C14 2.22877 13.1046 1.33334 12 1.33334C10.8954 1.33334 10 2.22877 10 3.33334C10 4.43791 10.8954 5.33334 12 5.33334Z'
                              stroke='#6C7F73'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z'
                              stroke='#6C7F73'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M10.3337 4.33334L5.66699 7'
                              stroke='#6C7F73'
                              strokeWidth='1.5'
                            />
                            <path
                              d='M5.66699 9L10.3337 11.6667'
                              stroke='#6C7F73'
                              strokeWidth='1.5'
                            />
                          </svg>
                        </div>
                      </div>
                      <span className='text-gray-500'>
                        Share to others with links
                      </span>
                    </li>
                  </ul>
                </div>
                <a
                  className='xs:flex-shrink-0 group relative w-full xs:w-auto h-12 flex xs:inline-flex items-center justify-center px-5 font-bold text-gray-700 bg-white border border-gray-200 hover:border-yellowGreen-600 shadow-md hover:shadow-none rounded-lg transition-all duration-300 focus:outline-none'
                  href='#'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-gray-100 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span className='mr-2'>Learn more</span>
                  <span className='transform group-hover:translate-x-1 transition duration-300'>
                    <svg
                      width={20}
                      height={20}
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.5 5L12.5 10L7.5 15'
                        stroke='#3B5444'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <div className='w-full md:w-1/2 lg:w-7/12 px-4'>
                <div>
                  <div className='flex items-start max-w-xl mb-12'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 mr-6 p-px rounded-full bg-gradient-to-br from-cyanGreen-800 to-cyan-800'>
                      <div className='flex items-center justify-center bg-white h-full w-full rounded-full'>
                        <svg
                          width={16}
                          height={16}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M2 11.6V4.4C2 4.29391 2.04214 4.19217 2.11716 4.11716C2.19217 4.04214 2.29391 4 2.4 4H11.1193C11.2527 4 11.378 4.06667 11.4527 4.178L13.8527 7.778C13.8965 7.84373 13.9199 7.92098 13.9199 8C13.9199 8.07902 13.8965 8.15627 13.8527 8.222L11.4527 11.822C11.4161 11.8768 11.3665 11.9218 11.3083 11.9528C11.2502 11.9839 11.1853 12.0001 11.1193 12H2.4C2.34747 12 2.29546 11.9897 2.24693 11.9696C2.1984 11.9495 2.1543 11.92 2.11716 11.8828C2.08001 11.8457 2.05055 11.8016 2.03045 11.7531C2.01035 11.7045 2 11.6525 2 11.6Z'
                            stroke='url(#paint0_linear_3018_20784)'
                            strokeWidth='1.5'
                          />
                          <defs>
                            <linearGradient
                              id='paint0_linear_3018_20784'
                              x1='1.81375'
                              y1='1.5'
                              x2='4.80178'
                              y2='12.8327'
                              gradientUnits='userSpaceOnUse'
                            >
                              <stop stopColor='#ACE348'>
                                <stop offset={1} stopColor='#25ACA0' />
                              </stop>
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    <div>
                      <h5 className='text-lg font-semibold mb-1'>
                        Auto-tagging
                      </h5>
                      <p className='text-gray-500'>
                        Useful for large collections of files, such as photo or
                        video, where manually tagging each file would be
                        impractical.
                      </p>
                    </div>
                  </div>
                  <div>
                    <img
                      className='block'
                      src='aurora-assets/features/feature-image-right.png'
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-gray-50'>
          <img
            className='absolute bottom-0 right-0'
            src='aurora-assets/features/light-right-shadow.png'
            alt=''
          />
          <img
            className='absolute top-0 right-0'
            src='aurora-assets/features/stars-right.svg'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-5/12 px-4 mb-20 lg:mb-0'>
                <div className='max-w-xs mb-10'>
                  <span className='inline-flex items-center h-6 mb-4 px-2 text-sm uppercase font-medium text-gray-700 bg-gray-100 rounded-full'>
                    Feature
                  </span>
                  <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-4'>
                    Easily organize your files
                  </h1>
                  <p className='text-gray-700'>
                    Increase your marketing team efficiency with a quick search
                    and ease of use features.
                  </p>
                </div>
                <a
                  className='xs:flex-shrink-0 group relative w-full xs:w-auto h-12 flex xs:inline-flex items-center justify-center px-5 font-bold text-gray-700 bg-white border border-gray-200 hover:border-yellowGreen-600 shadow-md hover:shadow-none rounded-lg transition-all duration-300 focus:outline-none'
                  href='#'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-gray-100 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span className='mr-2'>Learn more</span>
                  <span className='transform group-hover:translate-x-1 transition duration-300'>
                    <svg
                      width={20}
                      height={20}
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.5 5L12.5 10L7.5 15'
                        stroke='#3B5444'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <div className='w-full lg:w-7/12 px-4'>
                <div>
                  <div className='flex items-center'>
                    <span className='mr-6 text-sm font-medium text-gray-700'>
                      Support
                    </span>
                    <ul className='flex flex-wrap -mb-2 items-center'>
                      <li className='mb-2 mr-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 bg-white border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M14 2.6V13.4C14 13.7314 13.7314 14 13.4 14H2.6C2.26863 14 2 13.7314 2 13.4V2.6C2 2.26863 2.26863 2 2.6 2H13.4C13.7314 2 14 2.26863 14 2.6Z'
                              stroke='url(#paint0_linear_3021_28405)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M2 10.6667L6.66667 8.66666L14 12'
                              stroke='url(#paint1_linear_3021_28405)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M10.6667 6.66667C9.93029 6.66667 9.33333 6.06971 9.33333 5.33333C9.33333 4.59695 9.93029 4 10.6667 4C11.403 4 12 4.59695 12 5.33333C12 6.06971 11.403 6.66667 10.6667 6.66667Z'
                              stroke='url(#paint2_linear_3021_28405)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28405'
                                x1='14.1875'
                                y1='17.75'
                                x2={8}
                                y2={2}
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint1_linear_3021_28405'
                                x1='1.8125'
                                y1='7.62499'
                                x2='2.35713'
                                y2='12.6158'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint2_linear_3021_28405'
                                x1='12.0417'
                                y1='3.16667'
                                x2='10.6667'
                                y2='6.66667'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-gray-500 font-semibold'>
                            Photo
                          </span>
                        </a>
                      </li>
                      <li className='mb-2 mr-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 bg-white border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M14 2.6V13.4C14 13.7314 13.7314 14 13.4 14H2.6C2.26863 14 2 13.7314 2 13.4V2.6C2 2.26863 2.26863 2 2.6 2H13.4C13.7314 2 14 2.26863 14 2.6Z'
                              stroke='url(#paint0_linear_3021_28409)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M6.89768 5.84631C6.49769 5.61774 6 5.90656 6 6.36725V9.63277C6 10.0935 6.49769 10.3823 6.89768 10.1537L9.75501 8.52096C10.1581 8.29062 10.1581 7.7094 9.75501 7.47906L6.89768 5.84631Z'
                              stroke='url(#paint1_linear_3021_28409)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28409'
                                x1='14.1875'
                                y1='17.75'
                                x2={8}
                                y2={2}
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint1_linear_3021_28409'
                                x1='5.92708'
                                y1='3.66668'
                                x2='8.94636'
                                y2='10.3914'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-gray-500 font-semibold'>
                            Video
                          </span>
                        </a>
                      </li>
                      <li className='mb-2 mr-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 bg-white border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12 2L4 2C2.89543 2 2 2.89543 2 4L2 12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V4C14 2.89543 13.1046 2 12 2Z'
                              stroke='url(#paint0_linear_3021_28415)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M4.66699 4.66666L11.3337 4.66666'
                              stroke='url(#paint1_linear_3021_28415)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M4.66699 8L11.3337 8'
                              stroke='url(#paint2_linear_3021_28415)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M4.66699 11.3333L8.66699 11.3333'
                              stroke='url(#paint3_linear_3021_28415)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28415'
                                x1='-1.75'
                                y1='1.8125'
                                x2={14}
                                y2={8}
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint1_linear_3021_28415'
                                x1='2.58366'
                                y1='4.68228'
                                x2='3.8688'
                                y2='1.31644'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint2_linear_3021_28415'
                                x1='2.58366'
                                y1='8.01562'
                                x2='3.8688'
                                y2='4.64978'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint3_linear_3021_28415'
                                x1='3.41699'
                                y1='11.349'
                                x2='5.16378'
                                y2='8.60402'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-gray-500 font-semibold'>
                            File
                          </span>
                        </a>
                      </li>
                      <li className='mb-2 mr-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 bg-white border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M2.66699 4V2.6C2.66699 2.26863 2.93562 2 3.26699 2H12.7337C13.065 2 13.3337 2.26863 13.3337 2.6V4'
                              stroke='url(#paint0_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                            />
                            <path
                              d='M2.66699 12V13.4C2.66699 13.7314 2.93562 14 3.26699 14H12.7337C13.065 14 13.3337 13.7314 13.3337 13.4V12'
                              stroke='url(#paint1_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                            />
                            <path
                              d='M10.333 10V6H12.333'
                              stroke='url(#paint2_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M10.333 8H11.6663'
                              stroke='url(#paint3_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M8 10L8 6'
                              stroke='url(#paint4_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M5.66699 6H3.66699L3.66699 10L5.66699 10V8.4'
                              stroke='url(#paint5_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28422'
                                x1='2.50033'
                                y1='1.375'
                                x2='2.72232'
                                y2='4.38878'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint1_linear_3021_28422'
                                x1='2.50033'
                                y1='14.625'
                                x2='2.72232'
                                y2='11.6112'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint2_linear_3021_28422'
                                x1='9.70801'
                                y1='10.0625'
                                x2='12.6256'
                                y2='9.48941'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint3_linear_3021_28422'
                                x1='9.91634'
                                y1='8.01562'
                                x2='11.5015'
                                y2='7.1853'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint4_linear_3021_28422'
                                x1='7.6875'
                                y1='10.0625'
                                x2='9.18809'
                                y2='9.91512'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint5_linear_3021_28422'
                                x1='3.04199'
                                y1='10.0625'
                                x2='5.95955'
                                y2='9.48941'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-gray-500 font-semibold'>
                            GIF
                          </span>
                        </a>
                      </li>
                      <li className='mb-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 bg-white border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M9 4L6.66667 12.3333M4.33333 5.66667L2 8L4.33333 10.3333M11.6667 5.66667L14 8L11.6667 10.3333'
                              stroke='url(#paint0_linear_3021_28440)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28440'
                                x1='1.8125'
                                y1='1.39583'
                                x2='5.01836'
                                y2='13.1468'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-gray-500 font-semibold'>
                            Code Snippets
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <img
                    className='block w-full'
                    src='aurora-assets/features/image-feature-2.png'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-teal-900'>
          <img
            className='absolute bottom-0 right-0 opacity-30'
            src='aurora-assets/features/light-right-shadow.png'
            alt=''
          />
          <img
            className='absolute top-0 right-0'
            src='aurora-assets/features/stars-green-bg.svg'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-5/12 px-4 mb-20 lg:mb-0'>
                <div className='max-w-xs mb-10'>
                  <span className='inline-flex items-center h-6 mb-4 px-2 text-xs uppercase font-medium text-gray-50 bg-teal-800 rounded-full'>
                    Feature
                  </span>
                  <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                    Easily organize your files
                  </h1>
                  <p className='text-gray-400'>
                    Increase your marketing team efficiency with a quick search
                    and ease of use features.
                  </p>
                </div>
                <a
                  className='xs:flex-shrink-0 group relative w-full xs:w-auto h-12 flex xs:inline-flex items-center justify-center px-5 font-bold text-gray-700 bg-white border border-gray-200 shadow-md hover:shadow-none rounded-lg transition-all duration-300 focus:outline-none'
                  href='#'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-gray-100 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span className='mr-2'>Learn more</span>
                  <span className='transform group-hover:translate-x-1 transition duration-300'>
                    <svg
                      width={20}
                      height={20}
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.5 5L12.5 10L7.5 15'
                        stroke='#3B5444'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <div className='w-full lg:w-7/12 px-4'>
                <div>
                  <div className='flex items-center'>
                    <span className='mr-6 text-sm font-medium text-gray-400'>
                      Support
                    </span>
                    <ul className='flex flex-wrap -mb-2 items-center'>
                      <li className='mb-2 mr-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 border border-teal-800 hover:border-yellowGreen-600 rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M14 2.6V13.4C14 13.7314 13.7314 14 13.4 14H2.6C2.26863 14 2 13.7314 2 13.4V2.6C2 2.26863 2.26863 2 2.6 2H13.4C13.7314 2 14 2.26863 14 2.6Z'
                              stroke='url(#paint0_linear_3021_28405)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M2 10.6667L6.66667 8.66666L14 12'
                              stroke='url(#paint1_linear_3021_28405)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M10.6667 6.66667C9.93029 6.66667 9.33333 6.06971 9.33333 5.33333C9.33333 4.59695 9.93029 4 10.6667 4C11.403 4 12 4.59695 12 5.33333C12 6.06971 11.403 6.66667 10.6667 6.66667Z'
                              stroke='url(#paint2_linear_3021_28405)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28405'
                                x1='14.1875'
                                y1='17.75'
                                x2={8}
                                y2={2}
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint1_linear_3021_28405'
                                x1='1.8125'
                                y1='7.62499'
                                x2='2.35713'
                                y2='12.6158'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint2_linear_3021_28405'
                                x1='12.0417'
                                y1='3.16667'
                                x2='10.6667'
                                y2='6.66667'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-white font-semibold'>
                            Photo
                          </span>
                        </a>
                      </li>
                      <li className='mb-2 mr-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 border border-teal-800 hover:border-yellowGreen-600 rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M14 2.6V13.4C14 13.7314 13.7314 14 13.4 14H2.6C2.26863 14 2 13.7314 2 13.4V2.6C2 2.26863 2.26863 2 2.6 2H13.4C13.7314 2 14 2.26863 14 2.6Z'
                              stroke='url(#paint0_linear_3021_28409)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M6.89768 5.84631C6.49769 5.61774 6 5.90656 6 6.36725V9.63277C6 10.0935 6.49769 10.3823 6.89768 10.1537L9.75501 8.52096C10.1581 8.29062 10.1581 7.7094 9.75501 7.47906L6.89768 5.84631Z'
                              stroke='url(#paint1_linear_3021_28409)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28409'
                                x1='14.1875'
                                y1='17.75'
                                x2={8}
                                y2={2}
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint1_linear_3021_28409'
                                x1='5.92708'
                                y1='3.66668'
                                x2='8.94636'
                                y2='10.3914'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-white font-semibold'>
                            Video
                          </span>
                        </a>
                      </li>
                      <li className='mb-2 mr-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 border border-teal-800 hover:border-yellowGreen-600 rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12 2L4 2C2.89543 2 2 2.89543 2 4L2 12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V4C14 2.89543 13.1046 2 12 2Z'
                              stroke='url(#paint0_linear_3021_28415)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M4.66699 4.66666L11.3337 4.66666'
                              stroke='url(#paint1_linear_3021_28415)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M4.66699 8L11.3337 8'
                              stroke='url(#paint2_linear_3021_28415)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M4.66699 11.3333L8.66699 11.3333'
                              stroke='url(#paint3_linear_3021_28415)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28415'
                                x1='-1.75'
                                y1='1.8125'
                                x2={14}
                                y2={8}
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint1_linear_3021_28415'
                                x1='2.58366'
                                y1='4.68228'
                                x2='3.8688'
                                y2='1.31644'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint2_linear_3021_28415'
                                x1='2.58366'
                                y1='8.01562'
                                x2='3.8688'
                                y2='4.64978'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint3_linear_3021_28415'
                                x1='3.41699'
                                y1='11.349'
                                x2='5.16378'
                                y2='8.60402'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-white font-semibold'>
                            File
                          </span>
                        </a>
                      </li>
                      <li className='mb-2 mr-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 border border-teal-800 hover:border-yellowGreen-600 rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M2.66699 4V2.6C2.66699 2.26863 2.93562 2 3.26699 2H12.7337C13.065 2 13.3337 2.26863 13.3337 2.6V4'
                              stroke='url(#paint0_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                            />
                            <path
                              d='M2.66699 12V13.4C2.66699 13.7314 2.93562 14 3.26699 14H12.7337C13.065 14 13.3337 13.7314 13.3337 13.4V12'
                              stroke='url(#paint1_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                            />
                            <path
                              d='M10.333 10V6H12.333'
                              stroke='url(#paint2_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M10.333 8H11.6663'
                              stroke='url(#paint3_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M8 10L8 6'
                              stroke='url(#paint4_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M5.66699 6H3.66699L3.66699 10L5.66699 10V8.4'
                              stroke='url(#paint5_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28422'
                                x1='2.50033'
                                y1='1.375'
                                x2='2.72232'
                                y2='4.38878'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint1_linear_3021_28422'
                                x1='2.50033'
                                y1='14.625'
                                x2='2.72232'
                                y2='11.6112'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint2_linear_3021_28422'
                                x1='9.70801'
                                y1='10.0625'
                                x2='12.6256'
                                y2='9.48941'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint3_linear_3021_28422'
                                x1='9.91634'
                                y1='8.01562'
                                x2='11.5015'
                                y2='7.1853'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint4_linear_3021_28422'
                                x1='7.6875'
                                y1='10.0625'
                                x2='9.18809'
                                y2='9.91512'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint5_linear_3021_28422'
                                x1='3.04199'
                                y1='10.0625'
                                x2='5.95955'
                                y2='9.48941'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-white font-semibold'>
                            GIF
                          </span>
                        </a>
                      </li>
                      <li className='mb-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 border border-teal-800 hover:border-yellowGreen-600 rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M9 4L6.66667 12.3333M4.33333 5.66667L2 8L4.33333 10.3333M11.6667 5.66667L14 8L11.6667 10.3333'
                              stroke='url(#paint0_linear_3021_28440)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28440'
                                x1='1.8125'
                                y1='1.39583'
                                x2='5.01836'
                                y2='13.1468'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-white font-semibold'>
                            Code Snippets
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <img
                    className='block w-full'
                    src='aurora-assets/features/image-feature-2.png'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-radial-dark-green'>
          <img
            className='absolute top-0 right-0'
            src='aurora-assets/features/stars-right.svg'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-5/12 px-4 mb-20 lg:mb-0'>
                <div className='max-w-xs mb-10'>
                  <span className='inline-flex items-center h-6 mb-4 px-2 text-xs uppercase font-medium text-white bg-gray-800 rounded-full'>
                    Feature
                  </span>
                  <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                    Easily organize your files
                  </h1>
                  <p className='text-gray-400'>
                    Increase your marketing team efficiency with a quick search
                    and ease of use features.
                  </p>
                </div>
                <a
                  className='xs:flex-shrink-0 group relative w-full xs:w-auto h-12 flex xs:inline-flex items-center justify-center px-5 font-bold text-gray-700 bg-white border border-gray-200 shadow-md hover:shadow-none rounded-lg transition-all duration-300 focus:outline-none'
                  href='#'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-300 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span className='mr-2'>Learn more</span>
                  <span className='transform group-hover:translate-x-1 transition duration-300'>
                    <svg
                      width={20}
                      height={20}
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.5 5L12.5 10L7.5 15'
                        stroke='#3B5444'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <div className='w-full lg:w-7/12 px-4'>
                <div>
                  <div className='flex items-center'>
                    <span className='mr-6 text-sm font-medium text-gray-400'>
                      Support
                    </span>
                    <ul className='flex flex-wrap -mb-2 items-center'>
                      <li className='mb-2 mr-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 bg-white bg-opacity-10 hover:bg-opacity-20 border border-white border-opacity-20 rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M14 2.6V13.4C14 13.7314 13.7314 14 13.4 14H2.6C2.26863 14 2 13.7314 2 13.4V2.6C2 2.26863 2.26863 2 2.6 2H13.4C13.7314 2 14 2.26863 14 2.6Z'
                              stroke='url(#paint0_linear_3021_28405)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M2 10.6667L6.66667 8.66666L14 12'
                              stroke='url(#paint1_linear_3021_28405)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M10.6667 6.66667C9.93029 6.66667 9.33333 6.06971 9.33333 5.33333C9.33333 4.59695 9.93029 4 10.6667 4C11.403 4 12 4.59695 12 5.33333C12 6.06971 11.403 6.66667 10.6667 6.66667Z'
                              stroke='url(#paint2_linear_3021_28405)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28405'
                                x1='14.1875'
                                y1='17.75'
                                x2={8}
                                y2={2}
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint1_linear_3021_28405'
                                x1='1.8125'
                                y1='7.62499'
                                x2='2.35713'
                                y2='12.6158'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint2_linear_3021_28405'
                                x1='12.0417'
                                y1='3.16667'
                                x2='10.6667'
                                y2='6.66667'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-white font-semibold'>
                            Photo
                          </span>
                        </a>
                      </li>
                      <li className='mb-2 mr-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 bg-white bg-opacity-10 hover:bg-opacity-20 border border-white border-opacity-20 rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M14 2.6V13.4C14 13.7314 13.7314 14 13.4 14H2.6C2.26863 14 2 13.7314 2 13.4V2.6C2 2.26863 2.26863 2 2.6 2H13.4C13.7314 2 14 2.26863 14 2.6Z'
                              stroke='url(#paint0_linear_3021_28409)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M6.89768 5.84631C6.49769 5.61774 6 5.90656 6 6.36725V9.63277C6 10.0935 6.49769 10.3823 6.89768 10.1537L9.75501 8.52096C10.1581 8.29062 10.1581 7.7094 9.75501 7.47906L6.89768 5.84631Z'
                              stroke='url(#paint1_linear_3021_28409)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28409'
                                x1='14.1875'
                                y1='17.75'
                                x2={8}
                                y2={2}
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint1_linear_3021_28409'
                                x1='5.92708'
                                y1='3.66668'
                                x2='8.94636'
                                y2='10.3914'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-white font-semibold'>
                            Video
                          </span>
                        </a>
                      </li>
                      <li className='mb-2 mr-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 bg-white bg-opacity-10 hover:bg-opacity-20 border border-white border-opacity-20 rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12 2L4 2C2.89543 2 2 2.89543 2 4L2 12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V4C14 2.89543 13.1046 2 12 2Z'
                              stroke='url(#paint0_linear_3021_28415)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M4.66699 4.66666L11.3337 4.66666'
                              stroke='url(#paint1_linear_3021_28415)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M4.66699 8L11.3337 8'
                              stroke='url(#paint2_linear_3021_28415)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M4.66699 11.3333L8.66699 11.3333'
                              stroke='url(#paint3_linear_3021_28415)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28415'
                                x1='-1.75'
                                y1='1.8125'
                                x2={14}
                                y2={8}
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint1_linear_3021_28415'
                                x1='2.58366'
                                y1='4.68228'
                                x2='3.8688'
                                y2='1.31644'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint2_linear_3021_28415'
                                x1='2.58366'
                                y1='8.01562'
                                x2='3.8688'
                                y2='4.64978'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint3_linear_3021_28415'
                                x1='3.41699'
                                y1='11.349'
                                x2='5.16378'
                                y2='8.60402'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-white font-semibold'>
                            File
                          </span>
                        </a>
                      </li>
                      <li className='mb-2 mr-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 bg-white bg-opacity-10 hover:bg-opacity-20 border border-white border-opacity-20 rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M2.66699 4V2.6C2.66699 2.26863 2.93562 2 3.26699 2H12.7337C13.065 2 13.3337 2.26863 13.3337 2.6V4'
                              stroke='url(#paint0_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                            />
                            <path
                              d='M2.66699 12V13.4C2.66699 13.7314 2.93562 14 3.26699 14H12.7337C13.065 14 13.3337 13.7314 13.3337 13.4V12'
                              stroke='url(#paint1_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                            />
                            <path
                              d='M10.333 10V6H12.333'
                              stroke='url(#paint2_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M10.333 8H11.6663'
                              stroke='url(#paint3_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M8 10L8 6'
                              stroke='url(#paint4_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M5.66699 6H3.66699L3.66699 10L5.66699 10V8.4'
                              stroke='url(#paint5_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28422'
                                x1='2.50033'
                                y1='1.375'
                                x2='2.72232'
                                y2='4.38878'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint1_linear_3021_28422'
                                x1='2.50033'
                                y1='14.625'
                                x2='2.72232'
                                y2='11.6112'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint2_linear_3021_28422'
                                x1='9.70801'
                                y1='10.0625'
                                x2='12.6256'
                                y2='9.48941'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint3_linear_3021_28422'
                                x1='9.91634'
                                y1='8.01562'
                                x2='11.5015'
                                y2='7.1853'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint4_linear_3021_28422'
                                x1='7.6875'
                                y1='10.0625'
                                x2='9.18809'
                                y2='9.91512'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint5_linear_3021_28422'
                                x1='3.04199'
                                y1='10.0625'
                                x2='5.95955'
                                y2='9.48941'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-white font-semibold'>
                            GIF
                          </span>
                        </a>
                      </li>
                      <li className='mb-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 bg-white bg-opacity-10 hover:bg-opacity-20 border border-white border-opacity-20 rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M9 4L6.66667 12.3333M4.33333 5.66667L2 8L4.33333 10.3333M11.6667 5.66667L14 8L11.6667 10.3333'
                              stroke='url(#paint0_linear_3021_28440)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28440'
                                x1='1.8125'
                                y1='1.39583'
                                x2='5.01836'
                                y2='13.1468'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-white font-semibold'>
                            Code Snippets
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <img
                    className='block w-full'
                    src='aurora-assets/features/dark-photo-right.png'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-gray-900'>
          <img
            className='absolute bottom-0 right-0 opacity-40'
            src='aurora-assets/features/light-right-shadow.png'
            alt=''
          />
          <img
            className='absolute top-0 right-0'
            src='aurora-assets/features/stars-right.svg'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-5/12 px-4 mb-20 lg:mb-0'>
                <div className='max-w-xs mb-10'>
                  <span className='inline-flex items-center h-6 mb-4 px-2 text-xs uppercase font-medium text-white bg-gray-800 rounded-full'>
                    Feature
                  </span>
                  <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                    Easily organize your files
                  </h1>
                  <p className='text-gray-400'>
                    Increase your marketing team efficiency with a quick search
                    and ease of use features.
                  </p>
                </div>
                <a
                  className='group inline-block justify-center p-1 text-center text-base text-white font-semibold rounded-lg'
                  href='#'
                >
                  <div className='inline-flex items-stretch h-12 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                    <div className='flex items-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                      <span className='mr-2'>Learn more</span>
                      <span className='transform group-hover:translate-x-1 transition duration-300'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M7.5 5L12.5 10L7.5 15'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div className='w-full lg:w-7/12 px-4'>
                <div>
                  <div className='flex items-center'>
                    <span className='mr-6 text-sm font-medium text-gray-400'>
                      Support
                    </span>
                    <ul className='flex flex-wrap -mb-2 items-center'>
                      <li className='mb-2 mr-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 bg-gray-800 border border-gray-700 hover:border-yellowGreen-800 rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M14 2.6V13.4C14 13.7314 13.7314 14 13.4 14H2.6C2.26863 14 2 13.7314 2 13.4V2.6C2 2.26863 2.26863 2 2.6 2H13.4C13.7314 2 14 2.26863 14 2.6Z'
                              stroke='url(#paint0_linear_3021_28405)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M2 10.6667L6.66667 8.66666L14 12'
                              stroke='url(#paint1_linear_3021_28405)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M10.6667 6.66667C9.93029 6.66667 9.33333 6.06971 9.33333 5.33333C9.33333 4.59695 9.93029 4 10.6667 4C11.403 4 12 4.59695 12 5.33333C12 6.06971 11.403 6.66667 10.6667 6.66667Z'
                              stroke='url(#paint2_linear_3021_28405)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28405'
                                x1='14.1875'
                                y1='17.75'
                                x2={8}
                                y2={2}
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint1_linear_3021_28405'
                                x1='1.8125'
                                y1='7.62499'
                                x2='2.35713'
                                y2='12.6158'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint2_linear_3021_28405'
                                x1='12.0417'
                                y1='3.16667'
                                x2='10.6667'
                                y2='6.66667'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-white font-semibold'>
                            Photo
                          </span>
                        </a>
                      </li>
                      <li className='mb-2 mr-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 bg-gray-800 border border-gray-700 hover:border-yellowGreen-800 rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M14 2.6V13.4C14 13.7314 13.7314 14 13.4 14H2.6C2.26863 14 2 13.7314 2 13.4V2.6C2 2.26863 2.26863 2 2.6 2H13.4C13.7314 2 14 2.26863 14 2.6Z'
                              stroke='url(#paint0_linear_3021_28409)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M6.89768 5.84631C6.49769 5.61774 6 5.90656 6 6.36725V9.63277C6 10.0935 6.49769 10.3823 6.89768 10.1537L9.75501 8.52096C10.1581 8.29062 10.1581 7.7094 9.75501 7.47906L6.89768 5.84631Z'
                              stroke='url(#paint1_linear_3021_28409)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28409'
                                x1='14.1875'
                                y1='17.75'
                                x2={8}
                                y2={2}
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint1_linear_3021_28409'
                                x1='5.92708'
                                y1='3.66668'
                                x2='8.94636'
                                y2='10.3914'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-white font-semibold'>
                            Video
                          </span>
                        </a>
                      </li>
                      <li className='mb-2 mr-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 bg-gray-800 border border-gray-700 hover:border-yellowGreen-800 rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12 2L4 2C2.89543 2 2 2.89543 2 4L2 12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V4C14 2.89543 13.1046 2 12 2Z'
                              stroke='url(#paint0_linear_3021_28415)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M4.66699 4.66666L11.3337 4.66666'
                              stroke='url(#paint1_linear_3021_28415)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M4.66699 8L11.3337 8'
                              stroke='url(#paint2_linear_3021_28415)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M4.66699 11.3333L8.66699 11.3333'
                              stroke='url(#paint3_linear_3021_28415)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28415'
                                x1='-1.75'
                                y1='1.8125'
                                x2={14}
                                y2={8}
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint1_linear_3021_28415'
                                x1='2.58366'
                                y1='4.68228'
                                x2='3.8688'
                                y2='1.31644'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint2_linear_3021_28415'
                                x1='2.58366'
                                y1='8.01562'
                                x2='3.8688'
                                y2='4.64978'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint3_linear_3021_28415'
                                x1='3.41699'
                                y1='11.349'
                                x2='5.16378'
                                y2='8.60402'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-white font-semibold'>
                            File
                          </span>
                        </a>
                      </li>
                      <li className='mb-2 mr-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 bg-gray-800 border border-gray-700 hover:border-yellowGreen-800 rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M2.66699 4V2.6C2.66699 2.26863 2.93562 2 3.26699 2H12.7337C13.065 2 13.3337 2.26863 13.3337 2.6V4'
                              stroke='url(#paint0_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                            />
                            <path
                              d='M2.66699 12V13.4C2.66699 13.7314 2.93562 14 3.26699 14H12.7337C13.065 14 13.3337 13.7314 13.3337 13.4V12'
                              stroke='url(#paint1_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                            />
                            <path
                              d='M10.333 10V6H12.333'
                              stroke='url(#paint2_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M10.333 8H11.6663'
                              stroke='url(#paint3_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M8 10L8 6'
                              stroke='url(#paint4_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M5.66699 6H3.66699L3.66699 10L5.66699 10V8.4'
                              stroke='url(#paint5_linear_3021_28422)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28422'
                                x1='2.50033'
                                y1='1.375'
                                x2='2.72232'
                                y2='4.38878'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint1_linear_3021_28422'
                                x1='2.50033'
                                y1='14.625'
                                x2='2.72232'
                                y2='11.6112'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint2_linear_3021_28422'
                                x1='9.70801'
                                y1='10.0625'
                                x2='12.6256'
                                y2='9.48941'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint3_linear_3021_28422'
                                x1='9.91634'
                                y1='8.01562'
                                x2='11.5015'
                                y2='7.1853'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint4_linear_3021_28422'
                                x1='7.6875'
                                y1='10.0625'
                                x2='9.18809'
                                y2='9.91512'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                              <linearGradient
                                id='paint5_linear_3021_28422'
                                x1='3.04199'
                                y1='10.0625'
                                x2='5.95955'
                                y2='9.48941'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-white font-semibold'>
                            GIF
                          </span>
                        </a>
                      </li>
                      <li className='mb-2'>
                        <a
                          className='inline-flex items-center h-8 px-4 bg-gray-800 border border-gray-700 hover:border-yellowGreen-800 rounded-full'
                          href='#'
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M9 4L6.66667 12.3333M4.33333 5.66667L2 8L4.33333 10.3333M11.6667 5.66667L14 8L11.6667 10.3333'
                              stroke='url(#paint0_linear_3021_28440)'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <defs>
                              <linearGradient
                                id='paint0_linear_3021_28440'
                                x1='1.8125'
                                y1='1.39583'
                                x2='5.01836'
                                y2='13.1468'
                                gradientUnits='userSpaceOnUse'
                              >
                                <stop stopColor='#ACE348'>
                                  <stop offset={1} stopColor='#25ACA0' />
                                </stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className='ml-2 text-xs text-white font-semibold'>
                            Code Snippets
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <img
                    className='block w-full'
                    src='aurora-assets/features/dark-photo-right.png'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <div className='relative pb-24 border-b border-yellowGreen-400 overflow-hidden'>
              <div className='max-w-2xl mx-auto mb-24 text-center'>
                <span className='inline-flex items-center h-6 mb-6 px-2 text-xs uppercase font-medium text-yellowGreen-700 bg-yellowGreen-200 rounded-full'>
                  Feature
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold'>
                  Easily organize your files
                </h1>
              </div>
              <div className='flex flex-wrap -mx-4 -mb-16'>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-gray-50'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2 11.4V4.6C2 4.26863 2.26863 4 2.6 4H11.0122C11.2128 4 11.4002 4.10026 11.5115 4.26718L13.7781 7.66718C13.9125 7.86872 13.9125 8.13128 13.7781 8.33282L11.5115 11.7328C11.4002 11.8997 11.2128 12 11.0122 12H2.6C2.26863 12 2 11.7314 2 11.4Z'
                          stroke='#041109'
                          strokeWidth='1.5'
                        />
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold mb-1'>Auto-tagging</h5>
                      <p className='text-lg text-gray-500'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-gray-50'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21659)'>
                          <path
                            d='M2 7.33332V3.26666C2 2.93529 2.26863 2.66666 2.6 2.66666H6.44471C6.58794 2.66666 6.72644 2.71789 6.83519 2.8111L8.83148 4.52221C8.94023 4.61542 9.07873 4.66666 9.22195 4.66666H14.7333C15.0647 4.66666 15.3333 4.93529 15.3333 5.26666V7.33332M2 7.33332V12.7333C2 13.0647 2.26863 13.3333 2.6 13.3333H14.7333C15.0647 13.3333 15.3333 13.0647 15.3333 12.7333V7.33332M2 7.33332H15.3333'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold mb-1'>
                        Categorized by collections
                      </h5>
                      <p className='text-lg text-gray-500'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-gray-50'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21666)'>
                          <path
                            d='M9.66602 7.99841C9.66602 6.33726 8.12137 4.66666 6.23744 4.66666C6.01429 4.66666 5.27871 4.66666 5.09459 4.66666C3.20104 4.66666 1.66602 6.15833 1.66602 7.99841C1.66602 9.58402 2.80583 10.9109 4.33268 11.2476C4.57772 11.3016 4.83272 11.3302 5.09459 11.3302'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7 7.99842C7 9.65957 8.54464 11.3302 10.4286 11.3302C10.6517 11.3302 11.3873 11.3302 11.5714 11.3302C13.465 11.3302 15 9.83849 15 7.99842C15 6.41281 13.8602 5.08591 12.3333 4.74923C12.0883 4.6952 11.8333 4.66667 11.5714 4.66667'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold mb-1'>Share links</h5>
                      <p className='text-lg text-gray-500'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-gray-50'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21674)'>
                          <path
                            d='M0.666992 13.3333V12.6667C0.666992 10.0893 2.75633 8 5.33366 8V8C7.91099 8 10.0003 10.0893 10.0003 12.6667V13.3333'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                          />
                          <path
                            d='M8.66699 9.33333V9.33333C8.66699 7.49238 10.1594 6 12.0003 6V6C13.8413 6 15.3337 7.49239 15.3337 9.33334V9.66667'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                          />
                          <path
                            d='M5.33366 8C6.80642 8 8.00033 6.8061 8.00033 5.33334C8.00033 3.86058 6.80642 2.66667 5.33366 2.66667C3.8609 2.66667 2.66699 3.86058 2.66699 5.33334C2.66699 6.8061 3.8609 8 5.33366 8Z'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M12 6C13.1046 6 14 5.10457 14 4C14 2.89543 13.1046 2 12 2C10.8954 2 10 2.89543 10 4C10 5.10457 10.8954 6 12 6Z'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold mb-1'>
                        Invite team members
                      </h5>
                      <p className='text-lg text-gray-500'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-gray-50'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M14.667 2.6V13.4C14.667 13.7314 14.3984 14 14.067 14H3.26699C2.93562 14 2.66699 13.7314 2.66699 13.4V2.6C2.66699 2.26863 2.93562 2 3.26699 2H14.067C14.3984 2 14.667 2.26863 14.667 2.6Z'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M7.56468 5.84629C7.16468 5.61772 6.66699 5.90654 6.66699 6.36724V9.63275C6.66699 10.0934 7.16468 10.3823 7.56468 10.1537L10.422 8.52094C10.8251 8.29061 10.8251 7.70939 10.422 7.47905L7.56468 5.84629Z'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold mb-1'>
                        Video live preview
                      </h5>
                      <p className='text-lg text-gray-500'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-gray-50'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21688)'>
                          <path
                            d='M5.33203 12L7.33203 14L10.6654 10.6667'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M13.3327 11.7382C14.3285 11.3481 15.3327 10.4593 15.3327 8.66666C15.3327 6 13.1105 5.33333 11.9993 5.33333C11.9993 3.99999 11.9993 1.33333 7.99935 1.33333C3.99935 1.33333 3.99935 3.99999 3.99935 5.33333C2.88824 5.33333 0.666016 6 0.666016 8.66666C0.666016 10.4593 1.6702 11.3481 2.66602 11.7382'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold mb-1'>
                        100GB cloud storage
                      </h5>
                      <p className='text-lg text-gray-500'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <img
                className='absolute bottom-0 left-1/2 transform -translate-x-1/2 xs:-mb-10 md:-mb-20 lg:-mb-32 block'
                src='aurora-assets/features/green-light-shadow.png'
                alt=''
              />
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-gray-50'>
          <div className='container px-4 mx-auto'>
            <div className='relative pb-24 border-b border-teal-200 overflow-hidden'>
              <div className='max-w-2xl mx-auto mb-24 text-center'>
                <span className='inline-flex items-center h-6 mb-6 px-2 text-xs uppercase font-medium text-gray-700 bg-gray-100 rounded-full'>
                  Feature
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold'>
                  Easily organize your files
                </h1>
              </div>
              <div className='flex flex-wrap -mx-4 -mb-16'>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-white'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2 11.4V4.6C2 4.26863 2.26863 4 2.6 4H11.0122C11.2128 4 11.4002 4.10026 11.5115 4.26718L13.7781 7.66718C13.9125 7.86872 13.9125 8.13128 13.7781 8.33282L11.5115 11.7328C11.4002 11.8997 11.2128 12 11.0122 12H2.6C2.26863 12 2 11.7314 2 11.4Z'
                          stroke='#041109'
                          strokeWidth='1.5'
                        />
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold mb-1'>Auto-tagging</h5>
                      <p className='text-lg text-gray-500'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-white'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21659)'>
                          <path
                            d='M2 7.33332V3.26666C2 2.93529 2.26863 2.66666 2.6 2.66666H6.44471C6.58794 2.66666 6.72644 2.71789 6.83519 2.8111L8.83148 4.52221C8.94023 4.61542 9.07873 4.66666 9.22195 4.66666H14.7333C15.0647 4.66666 15.3333 4.93529 15.3333 5.26666V7.33332M2 7.33332V12.7333C2 13.0647 2.26863 13.3333 2.6 13.3333H14.7333C15.0647 13.3333 15.3333 13.0647 15.3333 12.7333V7.33332M2 7.33332H15.3333'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold mb-1'>
                        Categorized by collections
                      </h5>
                      <p className='text-lg text-gray-500'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-white'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21666)'>
                          <path
                            d='M9.66602 7.99841C9.66602 6.33726 8.12137 4.66666 6.23744 4.66666C6.01429 4.66666 5.27871 4.66666 5.09459 4.66666C3.20104 4.66666 1.66602 6.15833 1.66602 7.99841C1.66602 9.58402 2.80583 10.9109 4.33268 11.2476C4.57772 11.3016 4.83272 11.3302 5.09459 11.3302'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7 7.99842C7 9.65957 8.54464 11.3302 10.4286 11.3302C10.6517 11.3302 11.3873 11.3302 11.5714 11.3302C13.465 11.3302 15 9.83849 15 7.99842C15 6.41281 13.8602 5.08591 12.3333 4.74923C12.0883 4.6952 11.8333 4.66667 11.5714 4.66667'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold mb-1'>Share links</h5>
                      <p className='text-lg text-gray-500'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-white'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21674)'>
                          <path
                            d='M0.666992 13.3333V12.6667C0.666992 10.0893 2.75633 8 5.33366 8V8C7.91099 8 10.0003 10.0893 10.0003 12.6667V13.3333'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                          />
                          <path
                            d='M8.66699 9.33333V9.33333C8.66699 7.49238 10.1594 6 12.0003 6V6C13.8413 6 15.3337 7.49239 15.3337 9.33334V9.66667'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                          />
                          <path
                            d='M5.33366 8C6.80642 8 8.00033 6.8061 8.00033 5.33334C8.00033 3.86058 6.80642 2.66667 5.33366 2.66667C3.8609 2.66667 2.66699 3.86058 2.66699 5.33334C2.66699 6.8061 3.8609 8 5.33366 8Z'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M12 6C13.1046 6 14 5.10457 14 4C14 2.89543 13.1046 2 12 2C10.8954 2 10 2.89543 10 4C10 5.10457 10.8954 6 12 6Z'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold mb-1'>
                        Invite team members
                      </h5>
                      <p className='text-lg text-gray-500'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-white'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M14.667 2.6V13.4C14.667 13.7314 14.3984 14 14.067 14H3.26699C2.93562 14 2.66699 13.7314 2.66699 13.4V2.6C2.66699 2.26863 2.93562 2 3.26699 2H14.067C14.3984 2 14.667 2.26863 14.667 2.6Z'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M7.56468 5.84629C7.16468 5.61772 6.66699 5.90654 6.66699 6.36724V9.63275C6.66699 10.0934 7.16468 10.3823 7.56468 10.1537L10.422 8.52094C10.8251 8.29061 10.8251 7.70939 10.422 7.47905L7.56468 5.84629Z'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold mb-1'>
                        Video live preview
                      </h5>
                      <p className='text-lg text-gray-500'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-white'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21688)'>
                          <path
                            d='M5.33203 12L7.33203 14L10.6654 10.6667'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M13.3327 11.7382C14.3285 11.3481 15.3327 10.4593 15.3327 8.66666C15.3327 6 13.1105 5.33333 11.9993 5.33333C11.9993 3.99999 11.9993 1.33333 7.99935 1.33333C3.99935 1.33333 3.99935 3.99999 3.99935 5.33333C2.88824 5.33333 0.666016 6 0.666016 8.66666C0.666016 10.4593 1.6702 11.3481 2.66602 11.7382'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold mb-1'>
                        100GB cloud storage
                      </h5>
                      <p className='text-lg text-gray-500'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <img
                className='absolute bottom-0 left-1/2 transform -translate-x-1/2 xs:-mb-10 md:-mb-20 lg:-mb-32 block'
                src='aurora-assets/features/teal-light-bottom.png'
                alt=''
              />
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='relative pb-24 border-b border-teal-800 overflow-hidden'>
              <div className='max-w-2xl mx-auto mb-24 text-center'>
                <span className='inline-flex items-center h-6 mb-6 px-2 text-xs uppercase font-medium text-gray-50 bg-teal-800 rounded-full'>
                  Feature
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white'>
                  Easily organize your files
                </h1>
              </div>
              <div className='flex flex-wrap -mx-4 -mb-16'>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-teal-800'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2 11.4V4.6C2 4.26863 2.26863 4 2.6 4H11.0122C11.2128 4 11.4002 4.10026 11.5115 4.26718L13.7781 7.66718C13.9125 7.86872 13.9125 8.13128 13.7781 8.33282L11.5115 11.7328C11.4002 11.8997 11.2128 12 11.0122 12H2.6C2.26863 12 2 11.7314 2 11.4Z'
                          stroke='white'
                          strokeWidth='1.5'
                        />
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold text-white mb-1'>
                        Auto-tagging
                      </h5>
                      <p className='text-lg text-gray-400'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-teal-800'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21659)'>
                          <path
                            d='M2 7.33332V3.26666C2 2.93529 2.26863 2.66666 2.6 2.66666H6.44471C6.58794 2.66666 6.72644 2.71789 6.83519 2.8111L8.83148 4.52221C8.94023 4.61542 9.07873 4.66666 9.22195 4.66666H14.7333C15.0647 4.66666 15.3333 4.93529 15.3333 5.26666V7.33332M2 7.33332V12.7333C2 13.0647 2.26863 13.3333 2.6 13.3333H14.7333C15.0647 13.3333 15.3333 13.0647 15.3333 12.7333V7.33332M2 7.33332H15.3333'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold text-white mb-1'>
                        Categorized by collections
                      </h5>
                      <p className='text-lg text-gray-400'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-teal-800'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21666)'>
                          <path
                            d='M9.66602 7.99841C9.66602 6.33726 8.12137 4.66666 6.23744 4.66666C6.01429 4.66666 5.27871 4.66666 5.09459 4.66666C3.20104 4.66666 1.66602 6.15833 1.66602 7.99841C1.66602 9.58402 2.80583 10.9109 4.33268 11.2476C4.57772 11.3016 4.83272 11.3302 5.09459 11.3302'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7 7.99842C7 9.65957 8.54464 11.3302 10.4286 11.3302C10.6517 11.3302 11.3873 11.3302 11.5714 11.3302C13.465 11.3302 15 9.83849 15 7.99842C15 6.41281 13.8602 5.08591 12.3333 4.74923C12.0883 4.6952 11.8333 4.66667 11.5714 4.66667'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold text-white mb-1'>
                        Share links
                      </h5>
                      <p className='text-lg text-gray-400'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-teal-800'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21674)'>
                          <path
                            d='M0.666992 13.3333V12.6667C0.666992 10.0893 2.75633 8 5.33366 8V8C7.91099 8 10.0003 10.0893 10.0003 12.6667V13.3333'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                          />
                          <path
                            d='M8.66699 9.33333V9.33333C8.66699 7.49238 10.1594 6 12.0003 6V6C13.8413 6 15.3337 7.49239 15.3337 9.33334V9.66667'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                          />
                          <path
                            d='M5.33366 8C6.80642 8 8.00033 6.8061 8.00033 5.33334C8.00033 3.86058 6.80642 2.66667 5.33366 2.66667C3.8609 2.66667 2.66699 3.86058 2.66699 5.33334C2.66699 6.8061 3.8609 8 5.33366 8Z'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M12 6C13.1046 6 14 5.10457 14 4C14 2.89543 13.1046 2 12 2C10.8954 2 10 2.89543 10 4C10 5.10457 10.8954 6 12 6Z'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold text-white mb-1'>
                        Invite team members
                      </h5>
                      <p className='text-lg text-gray-400'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-teal-800'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M14.667 2.6V13.4C14.667 13.7314 14.3984 14 14.067 14H3.26699C2.93562 14 2.66699 13.7314 2.66699 13.4V2.6C2.66699 2.26863 2.93562 2 3.26699 2H14.067C14.3984 2 14.667 2.26863 14.667 2.6Z'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M7.56468 5.84629C7.16468 5.61772 6.66699 5.90654 6.66699 6.36724V9.63275C6.66699 10.0934 7.16468 10.3823 7.56468 10.1537L10.422 8.52094C10.8251 8.29061 10.8251 7.70939 10.422 7.47905L7.56468 5.84629Z'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold text-white mb-1'>
                        Video live preview
                      </h5>
                      <p className='text-lg text-gray-400'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-teal-800'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21688)'>
                          <path
                            d='M5.33203 12L7.33203 14L10.6654 10.6667'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M13.3327 11.7382C14.3285 11.3481 15.3327 10.4593 15.3327 8.66666C15.3327 6 13.1105 5.33333 11.9993 5.33333C11.9993 3.99999 11.9993 1.33333 7.99935 1.33333C3.99935 1.33333 3.99935 3.99999 3.99935 5.33333C2.88824 5.33333 0.666016 6 0.666016 8.66666C0.666016 10.4593 1.6702 11.3481 2.66602 11.7382'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold text-white mb-1'>
                        100GB cloud storage
                      </h5>
                      <p className='text-lg text-gray-400'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <img
                className='absolute bottom-0 left-1/2 transform -translate-x-1/2 xs:-mb-10 md:-mb-20 lg:-mb-32 block opacity-20'
                src='aurora-assets/features/teal-light-bottom.png'
                alt=''
              />
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='relative pb-24 border-b border-yellowGreen-700 overflow-hidden'>
              <div className='max-w-2xl mx-auto mb-24 text-center'>
                <span className='inline-flex items-center h-6 mb-6 px-2 text-xs uppercase font-medium text-white bg-gray-800 rounded-full'>
                  Feature
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white'>
                  Easily organize your files
                </h1>
              </div>
              <div className='flex flex-wrap -mx-4 -mb-16'>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-white bg-opacity-10'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2 11.4V4.6C2 4.26863 2.26863 4 2.6 4H11.0122C11.2128 4 11.4002 4.10026 11.5115 4.26718L13.7781 7.66718C13.9125 7.86872 13.9125 8.13128 13.7781 8.33282L11.5115 11.7328C11.4002 11.8997 11.2128 12 11.0122 12H2.6C2.26863 12 2 11.7314 2 11.4Z'
                          stroke='white'
                          strokeWidth='1.5'
                        />
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold text-white mb-1'>
                        Auto-tagging
                      </h5>
                      <p className='text-lg text-gray-200'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-white bg-opacity-10'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21659)'>
                          <path
                            d='M2 7.33332V3.26666C2 2.93529 2.26863 2.66666 2.6 2.66666H6.44471C6.58794 2.66666 6.72644 2.71789 6.83519 2.8111L8.83148 4.52221C8.94023 4.61542 9.07873 4.66666 9.22195 4.66666H14.7333C15.0647 4.66666 15.3333 4.93529 15.3333 5.26666V7.33332M2 7.33332V12.7333C2 13.0647 2.26863 13.3333 2.6 13.3333H14.7333C15.0647 13.3333 15.3333 13.0647 15.3333 12.7333V7.33332M2 7.33332H15.3333'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold text-white mb-1'>
                        Categorized by collections
                      </h5>
                      <p className='text-lg text-gray-200'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-white bg-opacity-10'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21666)'>
                          <path
                            d='M9.66602 7.99841C9.66602 6.33726 8.12137 4.66666 6.23744 4.66666C6.01429 4.66666 5.27871 4.66666 5.09459 4.66666C3.20104 4.66666 1.66602 6.15833 1.66602 7.99841C1.66602 9.58402 2.80583 10.9109 4.33268 11.2476C4.57772 11.3016 4.83272 11.3302 5.09459 11.3302'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7 7.99842C7 9.65957 8.54464 11.3302 10.4286 11.3302C10.6517 11.3302 11.3873 11.3302 11.5714 11.3302C13.465 11.3302 15 9.83849 15 7.99842C15 6.41281 13.8602 5.08591 12.3333 4.74923C12.0883 4.6952 11.8333 4.66667 11.5714 4.66667'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold text-white mb-1'>
                        Share links
                      </h5>
                      <p className='text-lg text-gray-200'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-white bg-opacity-10'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21674)'>
                          <path
                            d='M0.666992 13.3333V12.6667C0.666992 10.0893 2.75633 8 5.33366 8V8C7.91099 8 10.0003 10.0893 10.0003 12.6667V13.3333'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                          />
                          <path
                            d='M8.66699 9.33333V9.33333C8.66699 7.49238 10.1594 6 12.0003 6V6C13.8413 6 15.3337 7.49239 15.3337 9.33334V9.66667'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                          />
                          <path
                            d='M5.33366 8C6.80642 8 8.00033 6.8061 8.00033 5.33334C8.00033 3.86058 6.80642 2.66667 5.33366 2.66667C3.8609 2.66667 2.66699 3.86058 2.66699 5.33334C2.66699 6.8061 3.8609 8 5.33366 8Z'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M12 6C13.1046 6 14 5.10457 14 4C14 2.89543 13.1046 2 12 2C10.8954 2 10 2.89543 10 4C10 5.10457 10.8954 6 12 6Z'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold text-white mb-1'>
                        Invite team members
                      </h5>
                      <p className='text-lg text-gray-200'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-white bg-opacity-10'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M14.667 2.6V13.4C14.667 13.7314 14.3984 14 14.067 14H3.26699C2.93562 14 2.66699 13.7314 2.66699 13.4V2.6C2.66699 2.26863 2.93562 2 3.26699 2H14.067C14.3984 2 14.667 2.26863 14.667 2.6Z'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M7.56468 5.84629C7.16468 5.61772 6.66699 5.90654 6.66699 6.36724V9.63275C6.66699 10.0934 7.16468 10.3823 7.56468 10.1537L10.422 8.52094C10.8251 8.29061 10.8251 7.70939 10.422 7.47905L7.56468 5.84629Z'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold text-white mb-1'>
                        Video live preview
                      </h5>
                      <p className='text-lg text-gray-200'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 lg:w-1/3 px-4 mb-16'>
                  <div className='sm:flex items-start max-w-xs mx-auto sm:mx-0'>
                    <div className='flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-white bg-opacity-10'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clipPath='url(#clip0_3009_21688)'>
                          <path
                            d='M5.33203 12L7.33203 14L10.6654 10.6667'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M13.3327 11.7382C14.3285 11.3481 15.3327 10.4593 15.3327 8.66666C15.3327 6 13.1105 5.33333 11.9993 5.33333C11.9993 3.99999 11.9993 1.33333 7.99935 1.33333C3.99935 1.33333 3.99935 3.99999 3.99935 5.33333C2.88824 5.33333 0.666016 6 0.666016 8.66666C0.666016 10.4593 1.6702 11.3481 2.66602 11.7382'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                      </svg>
                    </div>
                    <div className='mt-4 sm:mt-0 sm:ml-3'>
                      <h5 className='text-xl font-bold text-white mb-1'>
                        100GB cloud storage
                      </h5>
                      <p className='text-lg text-gray-200'>
                        We care for your well-being with dental and vision
                        benefits.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <img
                className='absolute bottom-0 left-1/2 transform -translate-x-1/2 xs:-mb-10 md:-mb-20 lg:-mb-32 block opacity-20'
                src='aurora-assets/features/teal-light-bottom.png'
                alt=''
              />
            </div>
          </div>
        </section>
      </>
    <FooterComponent /></React.Fragment>
  );
}

