import React from 'react';

import { Flex, Text } from '@radix-ui/themes';


import {FooterComponent} from './Footers.jsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Pricing() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-md lg:max-w-6xl mx-auto'>
              <div className='flex flex-wrap items-end -mx-4 mb-24'>
                <div className='w-full lg:w-1/2 px-4 mb-10 lg:mb-0'>
                  <div className='max-w-sm sm:max-w-md'>
                    <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold text-white mb-4'>
                      Flexible pricing plan for your startup
                    </h1>
                    <p className='text-lg text-gray-400'>
                      Pricing that scales with your business immediately.
                    </p>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 px-4'>
                  <div className='flex items-center lg:justify-end'>
                    <span className='font-medium text-white'>Monthly</span>
                    <div className='inline-flex items-center h-6 px-px mx-4 bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-full'>
                      <button variant="classic" className='inline-block w-5 h-5 rounded-full bg-transparent focus:outline-none' />
                      <button variant="classic" className='inline-block w-5 h-5 rounded-full bg-white focus:outline-none' />
                    </div>
                    <div className='flex items-center'>
                      <span className='font-medium text-white'>Yearly</span>
                      <span className='inline-block ml-2 px-2 py-px bg-teal-800 text-xs text-white rounded-full'>
                        15% OFF
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/3 px-4 mb-18 lg:mb-0'>
                  <div className='p-8 bg-white border border-gray-100 rounded-xl shadow-sm'>
                    <div className='text-center mb-8'>
                      <h5 className='text-xl font-medium mb-4'>Starter</h5>
                      <div className='mb-4'>
                        <span className='text-5xl font-semibold tracking-tighter'>
                          $0
                        </span>
                        <span className='text-lg text-gray-500 font-medium'>
                          /month
                        </span>
                      </div>
                      <span className='text-gray-500'>Billed annually</span>
                    </div>
                    <a
                      className='flex items-center justify-center w-full h-12 px-4 mb-8 text-center text-base text-gray-700 hover:text-yellowGreen-700 font-bold border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                      href='#'
                    >
                      Get Started
                    </a>
                    <ul>
                      <li className='flex items-center mb-4'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 border-2 border-gray-100 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='#041109'
                            />
                          </svg>
                        </div>
                        <span>Complete documentation</span>
                      </li>
                      <li className='flex items-center mb-4'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 border-2 border-gray-100 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='#041109'
                            />
                          </svg>
                        </div>
                        <span>Working materials in Figma</span>
                      </li>
                      <li className='flex items-center'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 border-2 border-gray-100 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='#041109'
                            />
                          </svg>
                        </div>
                        <span>100GB cloud storage</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4 mb-8 lg:mb-0'>
                  <div className='relative'>
                    <div className='absolute top-0 left-0 w-full -mt-10 pt-2 px-2 pb-14 text-center rounded-t-xl bg-teal-800'>
                      <span className='text-xs font-semibold text-white'>
                        RECOMMENDED
                      </span>
                    </div>
                    <div className='relative p-8 bg-white border border-gray-100 rounded-xl shadow-sm'>
                      <div className='text-center mb-8'>
                        <h5 className='text-xl font-medium mb-4'>Team</h5>
                        <div className='mb-4'>
                          <span className='text-5xl font-semibold tracking-tighter'>
                            $10
                          </span>
                          <span className='text-lg text-gray-500 font-medium'>
                            /month
                          </span>
                        </div>
                        <span className='text-gray-500'>Billed annually</span>
                      </div>
                      <a
                        className='group relative flex items-center justify-center px-5 h-12 mb-8 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                        href='#'
                      >
                        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                        <span>Get Started</span>
                      </a>
                      <ul>
                        <li className='flex items-center mb-4'>
                          <div className='flex items-center justify-center w-5 h-5 mr-3 border-2 border-gray-100 rounded-full'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                fill='#041109'
                              />
                            </svg>
                          </div>
                          <span>Complete documentation</span>
                        </li>
                        <li className='flex items-center mb-4'>
                          <div className='flex items-center justify-center w-5 h-5 mr-3 border-2 border-gray-100 rounded-full'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                fill='#041109'
                              />
                            </svg>
                          </div>
                          <span>Working materials in Figma</span>
                        </li>
                        <li className='flex items-center'>
                          <div className='flex items-center justify-center w-5 h-5 mr-3 border-2 border-gray-100 rounded-full'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                fill='#041109'
                              />
                            </svg>
                          </div>
                          <span>100GB cloud storage</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4'>
                  <div className='p-8 bg-white border border-gray-100 rounded-xl shadow-sm'>
                    <div className='text-center mb-8'>
                      <h5 className='text-xl font-medium mb-4'>Enterprise</h5>
                      <div className='mb-4'>
                        <span className='text-5xl font-semibold tracking-tighter'>
                          Custom
                        </span>
                      </div>
                      <span className='text-gray-500'>
                        Please contact sales for details
                      </span>
                    </div>
                    <a
                      className='flex items-center justify-center w-full h-12 px-4 mb-8 text-center text-base text-gray-700 hover:text-yellowGreen-700 font-bold border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                      href='#'
                    >
                      Contact Sales
                    </a>
                    <ul>
                      <li className='flex items-center mb-4'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 border-2 border-gray-100 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='#041109'
                            />
                          </svg>
                        </div>
                        <span>Complete documentation</span>
                      </li>
                      <li className='flex items-center mb-4'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 border-2 border-gray-100 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='#041109'
                            />
                          </svg>
                        </div>
                        <span>Working materials in Figma</span>
                      </li>
                      <li className='flex items-center'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 border-2 border-gray-100 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='#041109'
                            />
                          </svg>
                        </div>
                        <span>100GB cloud storage</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='pt-24 pb-32 lg:pb-24 bg-gray-50'>
            <div className='container px-4 mx-auto'>
              <div className='max-w-md lg:max-w-6xl mx-auto mb-28'>
                <div className='text-center mb-16'>
                  <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-4'>
                    Flexible pricing plan
                  </h1>
                  <p className='text-lg text-gray-500'>
                    Pricing that scales with your business immediately.
                  </p>
                </div>
                <div className='flex justify-center'>
                  <div className='inline-flex items-center'>
                    <a
                      className='inline-block px-4 mr-1 py-2.5 text-sm text-gray-500 bg-transparent border border-transparent rounded-lg'
                      href='#'
                    >
                      Monthly
                    </a>
                    <a
                      className='inline-block px-4 py-2.5 text-sm font-medium text-gray-900 bg-white border border-gray-100 rounded-lg'
                      href='#'
                    >
                      Annually
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='pb-16 lg:pb-0 lg:h-112 bg-white'>
            <div className='container px-4 mx-auto'>
              <div className='max-w-md lg:max-w-6xl mx-auto'>
                <div className='flex flex-wrap -mx-4'>
                  <div className='w-full lg:w-1/3 px-4 mb-48'>
                    <div className='-mt-40 p-8 bg-white border border-gray-100 rounded-xl shadow-xl'>
                      <div className='flex mb-8 items-center'>
                        <span className='mr-3'>
                          <svg
                            width={24}
                            height={24}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15 3.6V14.4C15 14.7314 14.7314 15 14.4 15H3.6C3.26863 15 3 14.7314 3 14.4V3.6C3 3.26863 3.26863 3 3.6 3H14.4C14.7314 3 15 3.26863 15 3.6Z'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M13.5 21H16.5'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M21 13.5V16.5'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M21 19.5V20.4C21 20.7314 20.7314 21 20.4 21H19.5'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M10.5 21H9.6C9.26863 21 9 20.7314 9 20.4V19.5'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M19.5 9H20.4C20.7314 9 21 9.26863 21 9.6V10.5'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M16.5 9H9.6C9.26863 9 9 9.26863 9 9.6V16.5'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                        <span className='text-base font-semibold uppercase'>
                          Starter
                        </span>
                      </div>
                      <div className='flex items-end mb-4'>
                        <span className='mr-2 text-5xl font-semibold tracking-tighter'>
                          $0
                        </span>
                        <span className='inline-block pb-2 text-lg text-gray-500'>
                          /month
                        </span>
                      </div>
                      <span className='block text-gray-500 mb-8'>
                        No credit card required!
                      </span>
                      <div className='p-4 mb-8 border border-gray-200 rounded-xl'>
                        <span className='block mb-4 text-sm font-semibold'>
                          What’s included
                        </span>
                        <ul>
                          <li className='flex items-center mb-4'>
                            <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                  fill='#041109'
                                />
                              </svg>
                            </div>
                            <span className='text-sm'>
                              Complete documentation
                            </span>
                          </li>
                          <li className='flex items-center mb-4'>
                            <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                  fill='#041109'
                                />
                              </svg>
                            </div>
                            <span className='text-sm'>
                              Working materials in Figma
                            </span>
                          </li>
                          <li className='flex items-center'>
                            <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                  fill='#041109'
                                />
                              </svg>
                            </div>
                            <span className='text-sm'>100GB cloud storage</span>
                          </li>
                        </ul>
                      </div>
                      <a
                        className='flex items-center justify-center w-full h-12 px-4 text-center text-base text-gray-700 hover:text-yellowGreen-700 font-bold border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                        href='#'
                      >
                        Get Started
                      </a>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/3 px-4 mb-48'>
                    <div className='relative -mt-40 p-8 bg-white border border-gray-100 rounded-xl shadow-xl'>
                      <span className='absolute top-0 right-0 m-8 inline-block px-2.5 leading-relaxed text-sm text-white bg-gray-800 uppercase rounded-full'>
                        Popular
                      </span>
                      <div className='flex mb-8 items-center'>
                        <span className='mr-3'>
                          <svg
                            width={24}
                            height={24}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M10.5 15H9.6C9.26863 15 9 15.2686 9 15.6V20.4C9 20.7314 9.26863 21 9.6 21H20.4C20.7314 21 21 20.7314 21 20.4V9.6C21 9.26863 20.7314 9 20.4 9H15.6C15.2686 9 15 9.26863 15 9.6V10.5'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M13.5 15H14.4C14.7314 15 15 14.7314 15 14.4V13.5'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M9 13.5V14.4C9 14.7314 8.73137 15 8.4 15H3.6C3.26863 15 3 14.7314 3 14.4V3.6C3 3.26863 3.26863 3 3.6 3H14.4C14.7314 3 15 3.26863 15 3.6V8.4C15 8.73137 14.7314 9 14.4 9H13.5'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M9 10.5V9.6C9 9.26863 9.26863 9 9.6 9H10.5'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                        <span className='text-base font-semibold uppercase'>
                          Team
                        </span>
                      </div>
                      <div className='flex items-end mb-4'>
                        <span className='mr-2 text-5xl font-semibold tracking-tighter'>
                          $10
                        </span>
                        <span className='inline-block pb-2 text-lg text-gray-500'>
                          /month
                        </span>
                      </div>
                      <span className='block text-gray-500 mb-8'>
                        No credit card required!
                      </span>
                      <div className='p-4 mb-8 border border-gray-200 rounded-xl'>
                        <span className='block mb-4 text-sm font-semibold'>
                          All in the Starter plan plus:
                        </span>
                        <ul>
                          <li className='flex items-center mb-4'>
                            <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                  fill='#041109'
                                />
                              </svg>
                            </div>
                            <span className='text-sm'>
                              Complete documentation
                            </span>
                          </li>
                          <li className='flex items-center mb-4'>
                            <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                  fill='#041109'
                                />
                              </svg>
                            </div>
                            <span className='text-sm'>
                              Working materials in Figma
                            </span>
                          </li>
                          <li className='flex items-center'>
                            <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                  fill='#041109'
                                />
                              </svg>
                            </div>
                            <span className='text-sm'>100GB cloud storage</span>
                          </li>
                        </ul>
                      </div>
                      <a
                        className='group relative flex items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                        href='#'
                      >
                        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                        <span>Get Started</span>
                      </a>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/3 px-4'>
                    <div className='-mt-40 p-8 bg-white border border-gray-100 rounded-xl shadow-xl'>
                      <div className='flex mb-8 items-center'>
                        <span className='mr-3'>
                          <svg
                            width={24}
                            height={24}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15 9H20.4C20.7314 9 21 9.26863 21 9.6V20.4C21 20.7314 20.7314 21 20.4 21H9.6C9.26863 21 9 20.7314 9 20.4V15'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M15 9V3.6C15 3.26863 14.7314 3 14.4 3H3.6C3.26863 3 3 3.26863 3 3.6V14.4C3 14.7314 3.26863 15 3.6 15H9'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                        <span className='text-base font-semibold uppercase'>
                          Enterprise
                        </span>
                      </div>
                      <div className='mb-4'>
                        <span className='text-5xl font-semibold tracking-tighter'>
                          Custom
                        </span>
                      </div>
                      <span className='block text-gray-500 mb-8'>
                        Please contact sales for details
                      </span>
                      <div className='p-4 mb-8 border border-gray-200 rounded-xl'>
                        <span className='block mb-4 text-sm font-semibold'>
                          All in the Team plan plus:
                        </span>
                        <ul>
                          <li className='flex items-center mb-4'>
                            <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                  fill='#041109'
                                />
                              </svg>
                            </div>
                            <span className='text-sm'>
                              Complete documentation
                            </span>
                          </li>
                          <li className='flex items-center mb-4'>
                            <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                  fill='#041109'
                                />
                              </svg>
                            </div>
                            <span className='text-sm'>
                              Working materials in Figma
                            </span>
                          </li>
                          <li className='flex items-center'>
                            <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                  fill='#041109'
                                />
                              </svg>
                            </div>
                            <span className='text-sm'>100GB cloud storage</span>
                          </li>
                        </ul>
                      </div>
                      <a
                        className='flex items-center justify-center w-full h-12 px-4 text-center text-base text-gray-700 hover:text-yellowGreen-700 font-bold border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                        href='#'
                      >
                        Contact Sales
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-50'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-sm md:max-w-md lg:max-w-6xl mx-auto'>
              <div className='text-center mb-16'>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-4'>
                  Flexible pricing plan
                </h1>
                <p className='text-lg text-gray-500'>
                  Plan that scales with your business immediately.
                </p>
              </div>
              <div className='flex mb-24 justify-center'>
                <div className='inline-flex items-center p-1 bg-gray-50 rounded-lg'>
                  <a
                    className='inline-block px-4 mr-1 py-2.5 text-sm text-gray-500 bg-transparent border border-transparent rounded-lg'
                    href='#'
                  >
                    Monthly
                  </a>
                  <a
                    className='inline-block px-4 py-2.5 text-sm font-medium text-gray-900 bg-white border border-gray-100 rounded-lg'
                    href='#'
                  >
                    Annually
                  </a>
                </div>
              </div>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/3 px-4 mb-12 lg:mb-0'>
                  <div className='p-8 mb-10 bg-white border border-gray-100 rounded-xl shadow-sm'>
                    <div className='flex mb-8 items-center'>
                      <span className='mr-3'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M15 3.6V14.4C15 14.7314 14.7314 15 14.4 15H3.6C3.26863 15 3 14.7314 3 14.4V3.6C3 3.26863 3.26863 3 3.6 3H14.4C14.7314 3 15 3.26863 15 3.6Z'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M13.5 21H16.5'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M21 13.5V16.5'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M21 19.5V20.4C21 20.7314 20.7314 21 20.4 21H19.5'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M10.5 21H9.6C9.26863 21 9 20.7314 9 20.4V19.5'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M19.5 9H20.4C20.7314 9 21 9.26863 21 9.6V10.5'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M16.5 9H9.6C9.26863 9 9 9.26863 9 9.6V16.5'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <span className='text-base font-semibold uppercase'>
                        Starter
                      </span>
                    </div>
                    <div className='flex items-end mb-4'>
                      <span className='mr-2 text-5xl font-semibold tracking-tighter'>
                        $0
                      </span>
                      <span className='inline-block pb-2 text-lg text-gray-500'>
                        /month
                      </span>
                    </div>
                    <span className='block text-gray-500 mb-8'>
                      For individual or freelancers
                    </span>
                    <div className='p-4 mb-8 border border-gray-200 rounded-xl'>
                      <div className='mb-4'>
                        <span className='font-medium'>25</span>
                        <span className='font-medium text-gray-500'>
                          shared users
                        </span>
                      </div>
                      <div>
                        <span className='font-medium'>50GB</span>
                        <span className='font-medium text-gray-500'>
                          cloud storage
                        </span>
                      </div>
                    </div>
                    <a
                      className='flex items-center justify-center w-full h-12 px-4 text-center text-base text-gray-700 hover:text-yellowGreen-700 font-bold border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                      href='#'
                    >
                      Get Started
                    </a>
                  </div>
                  <div>
                    <div className='flex mb-10 items-start'>
                      <span className='flex-shrink-0 inline-flex w-5 h-5 items-center justify-center bg-white border border-gray-100 rounded-full'>
                        <svg
                          width={14}
                          height={14}
                          viewBox='0 0 14 14'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M2.91699 7.58337L5.25033 9.91671L11.0837 4.08337'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <div className='ml-4'>
                        <p className='text-sm'>
                          <span className='font-semibold text-gray-900'>
                            10 collections.
                          </span>
                          <span className='text-gray-500'>
                            Enough to get you started. Start collecting your
                            assets now.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className='flex items-start'>
                      <span className='flex-shrink-0 inline-flex w-5 h-5 items-center justify-center bg-white border border-gray-100 rounded-full'>
                        <svg
                          width={14}
                          height={14}
                          viewBox='0 0 14 14'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M2.91699 7.58337L5.25033 9.91671L11.0837 4.08337'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <div className='ml-4'>
                        <p className='text-sm'>
                          <span className='font-semibold text-gray-900'>
                            5 file extensions.
                          </span>
                          <span className='text-gray-500'>
                            Save files in .png, .svg, .jpg, .mp4, and .gif.
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4 mb-12 lg:mb-0'>
                  <div className='relative p-8 mb-10 bg-white border border-gray-100 rounded-xl shadow-sm'>
                    <span className='absolute top-0 right-0 m-8 inline-block px-2.5 leading-relaxed text-sm text-white bg-gray-800 uppercase rounded-full'>
                      Popular
                    </span>
                    <div className='flex mb-8 items-center'>
                      <span className='mr-3'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M10.5 15H9.6C9.26863 15 9 15.2686 9 15.6V20.4C9 20.7314 9.26863 21 9.6 21H20.4C20.7314 21 21 20.7314 21 20.4V9.6C21 9.26863 20.7314 9 20.4 9H15.6C15.2686 9 15 9.26863 15 9.6V10.5'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M13.5 15H14.4C14.7314 15 15 14.7314 15 14.4V13.5'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M9 13.5V14.4C9 14.7314 8.73137 15 8.4 15H3.6C3.26863 15 3 14.7314 3 14.4V3.6C3 3.26863 3.26863 3 3.6 3H14.4C14.7314 3 15 3.26863 15 3.6V8.4C15 8.73137 14.7314 9 14.4 9H13.5'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M9 10.5V9.6C9 9.26863 9.26863 9 9.6 9H10.5'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <span className='text-base font-semibold uppercase'>
                        Team
                      </span>
                    </div>
                    <div className='flex items-end mb-4'>
                      <span className='mr-2 text-5xl font-semibold tracking-tighter'>
                        $0
                      </span>
                      <span className='inline-block pb-2 text-lg text-gray-500'>
                        /month
                      </span>
                    </div>
                    <span className='block text-gray-500 mb-8'>
                      For individual or freelancers
                    </span>
                    <div className='p-4 mb-8 border border-gray-200 rounded-xl'>
                      <div className='mb-4'>
                        <span className='text-sm font-medium'>100</span>
                        <span className='text-sm font-medium text-gray-500'>
                          shared users
                        </span>
                      </div>
                      <div>
                        <span className='text-sm font-medium'>200GB</span>
                        <span className='text-sm font-medium text-gray-500'>
                          cloud storage
                        </span>
                      </div>
                    </div>
                    <a
                      className='group relative flex items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                      href='#'
                    >
                      <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                      <span>Get Started</span>
                    </a>
                  </div>
                  <div className='flex mb-10 items-start'>
                    <span className='flex-shrink-0 inline-flex w-5 h-5 items-center justify-center bg-white border border-gray-100 rounded-full'>
                      <svg
                        width={14}
                        height={14}
                        viewBox='0 0 14 14'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2.91699 7.58337L5.25033 9.91671L11.0837 4.08337'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <div className='ml-4'>
                      <p className='text-sm'>
                        <span className='font-semibold text-gray-900'>
                          Unlimited collections.
                        </span>
                        <span className='text-gray-500'>
                          Each collection can includes unlimited folders.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='flex items-start'>
                    <span className='flex-shrink-0 inline-flex w-5 h-5 items-center justify-center bg-white border border-gray-100 rounded-full'>
                      <svg
                        width={14}
                        height={14}
                        viewBox='0 0 14 14'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2.91699 7.58337L5.25033 9.91671L11.0837 4.08337'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <div className='ml-4'>
                      <p className='text-sm'>
                        <span className='font-semibold text-gray-900'>
                          80+ file extensions.
                        </span>
                        <span className='text-gray-500'>
                          We support over 80 type of file extensions.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4'>
                  <div className='p-8 mb-10 bg-teal-900 rounded-xl'>
                    <div className='flex mb-8 items-center'>
                      <span className='mr-3'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M15 9H20.4C20.7314 9 21 9.26863 21 9.6V20.4C21 20.7314 20.7314 21 20.4 21H9.6C9.26863 21 9 20.7314 9 20.4V15'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M15 9V3.6C15 3.26863 14.7314 3 14.4 3H3.6C3.26863 3 3 3.26863 3 3.6V14.4C3 14.7314 3.26863 15 3.6 15H9'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <span className='text-base font-semibold text-white uppercase'>
                        Enterprise
                      </span>
                    </div>
                    <div className='mb-4'>
                      <span className='text-5xl font-semibold text-white tracking-tighter'>
                        Custom
                      </span>
                    </div>
                    <span className='block text-gray-400 mb-8'>
                      Please contact sales for details
                    </span>
                    <div className='p-4 mb-8 border border-teal-800 rounded-xl'>
                      <div className='mb-4'>
                        <span className='text-sm font-medium text-white'>
                          Up to 500
                        </span>
                        <span className='text-sm font-medium text-gray-400'>
                          shared users
                        </span>
                      </div>
                      <div>
                        <span className='text-sm font-medium text-white'>
                          1TB
                        </span>
                        <span className='text-sm font-medium text-gray-400'>
                          cloud storage
                        </span>
                      </div>
                    </div>
                    <a
                      className='flex items-center justify-center w-full h-12 px-4 text-center text-base text-gray-900 hover:text-white font-bold bg-white hover:bg-gray-900 rounded-lg transition duration-200'
                      href='#'
                    >
                      Contact Sales
                    </a>
                  </div>
                  <div className='flex mb-10 items-start'>
                    <span className='flex-shrink-0 inline-flex w-5 h-5 items-center justify-center bg-white border border-gray-100 rounded-full'>
                      <svg
                        width={14}
                        height={14}
                        viewBox='0 0 14 14'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2.91699 7.58337L5.25033 9.91671L11.0837 4.08337'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <div className='ml-4'>
                      <p className='text-sm'>
                        <span className='font-semibold text-gray-900'>
                          Unlimited team members.
                        </span>
                        <span className='text-gray-500'>
                          Invite your teams to organize and collect company’s
                          assets.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='flex items-start'>
                    <span className='flex-shrink-0 inline-flex w-5 h-5 items-center justify-center bg-white border border-gray-100 rounded-full'>
                      <svg
                        width={14}
                        height={14}
                        viewBox='0 0 14 14'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2.91699 7.58337L5.25033 9.91671L11.0837 4.08337'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <div className='ml-4'>
                      <p className='text-sm'>
                        <span className='font-semibold text-gray-900'>
                          Live preview for video.
                        </span>
                        <span className='text-gray-500'>
                          Enough to get you started.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-sm md:max-w-md lg:max-w-6xl mx-auto'>
              <div className='text-center mb-16'>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                  Flexible pricing plan
                </h1>
                <p className='text-lg text-gray-400'>
                  Plan that scales with your business immediately.
                </p>
              </div>
              <div className='flex mb-24 justify-center'>
                <div className='inline-flex items-center p-1 bg-white bg-opacity-10 border border-white border-opacity-20 rounded-lg'>
                  <a
                    className='inline-block px-4 mr-1 py-2.5 text-sm text-gray-400 bg-transparent border border-transparent rounded-lg'
                    href='#'
                  >
                    Monthly
                  </a>
                  <a
                    className='inline-block px-4 py-2.5 text-sm font-medium text-white bg-white bg-opacity-10 border border-white border-opacity-20 rounded-lg'
                    href='#'
                  >
                    Annually
                  </a>
                </div>
              </div>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/3 px-4 mb-12 lg:mb-0'>
                  <div className='p-8 mb-10 bg-white bg-opacity-10 border border-white border-opacity-20 rounded-xl shadow-sm'>
                    <div className='flex mb-8 items-center'>
                      <span className='mr-3'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M15 3.6V14.4C15 14.7314 14.7314 15 14.4 15H3.6C3.26863 15 3 14.7314 3 14.4V3.6C3 3.26863 3.26863 3 3.6 3H14.4C14.7314 3 15 3.26863 15 3.6Z'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M13.5 21H16.5'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M21 13.5V16.5'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M21 19.5V20.4C21 20.7314 20.7314 21 20.4 21H19.5'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M10.5 21H9.6C9.26863 21 9 20.7314 9 20.4V19.5'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M19.5 9H20.4C20.7314 9 21 9.26863 21 9.6V10.5'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M16.5 9H9.6C9.26863 9 9 9.26863 9 9.6V16.5'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <span className='text-base font-semibold text-white uppercase'>
                        Starter
                      </span>
                    </div>
                    <div className='flex items-end mb-4'>
                      <span className='mr-2 text-5xl font-semibold text-white tracking-tighter'>
                        $0
                      </span>
                      <span className='inline-block pb-2 text-lg text-gray-200'>
                        /month
                      </span>
                    </div>
                    <span className='block text-gray-200 mb-8'>
                      For individual or freelancers
                    </span>
                    <div className='p-4 mb-8 border border-white border-opacity-20 rounded-xl'>
                      <div className='mb-4'>
                        <span className='text-white font-medium'>25</span>
                        <span className='font-medium text-gray-200'>
                          shared users
                        </span>
                      </div>
                      <div>
                        <span className='text-white font-medium'>50GB</span>
                        <span className='font-medium text-gray-200'>
                          cloud storage
                        </span>
                      </div>
                    </div>
                    <a
                      className='flex justify-center items-center w-full h-12 p-1 text-center text-base text-white bg-white bg-opacity-20 hover:bg-opacity-30 border border-white border-opacity-20 font-semibold rounded-lg'
                      href='#'
                    >
                      <span>Get Started</span>
                    </a>
                  </div>
                  <div>
                    <div className='flex mb-10 items-start'>
                      <span className='flex-shrink-0 inline-flex w-5 h-5 items-center justify-center bg-white bg-opacity-10 border border-white border-opacity-20 rounded-full'>
                        <svg
                          width={14}
                          height={14}
                          viewBox='0 0 14 14'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M2.91699 7.58337L5.25033 9.91671L11.0837 4.08337'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <div className='ml-4'>
                        <p className='text-sm'>
                          <span className='font-semibold text-white'>
                            10 collections.
                          </span>
                          <span className='text-gray-200'>
                            Enough to get you started. Start collecting your
                            assets now.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className='flex items-start'>
                      <span className='flex-shrink-0 inline-flex w-5 h-5 items-center justify-center bg-white bg-opacity-10 border border-white border-opacity-20 rounded-full'>
                        <svg
                          width={14}
                          height={14}
                          viewBox='0 0 14 14'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M2.91699 7.58337L5.25033 9.91671L11.0837 4.08337'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <div className='ml-4'>
                        <p className='text-sm'>
                          <span className='font-semibold text-white'>
                            5 file extensions.
                          </span>
                          <span className='text-gray-200'>
                            Save files in .png, .svg, .jpg, .mp4, and .gif.
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4 mb-12 lg:mb-0'>
                  <div className='relative p-8 mb-10 bg-white border border-gray-100 rounded-xl shadow-sm'>
                    <span className='absolute top-0 right-0 m-8 inline-block px-2.5 leading-relaxed text-sm text-white bg-gray-800 uppercase rounded-full'>
                      Popular
                    </span>
                    <div className='flex mb-8 items-center'>
                      <span className='mr-3'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M10.5 15H9.6C9.26863 15 9 15.2686 9 15.6V20.4C9 20.7314 9.26863 21 9.6 21H20.4C20.7314 21 21 20.7314 21 20.4V9.6C21 9.26863 20.7314 9 20.4 9H15.6C15.2686 9 15 9.26863 15 9.6V10.5'
                            stroke='black'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M13.5 15H14.4C14.7314 15 15 14.7314 15 14.4V13.5'
                            stroke='black'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M9 13.5V14.4C9 14.7314 8.73137 15 8.4 15H3.6C3.26863 15 3 14.7314 3 14.4V3.6C3 3.26863 3.26863 3 3.6 3H14.4C14.7314 3 15 3.26863 15 3.6V8.4C15 8.73137 14.7314 9 14.4 9H13.5'
                            stroke='black'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M9 10.5V9.6C9 9.26863 9.26863 9 9.6 9H10.5'
                            stroke='black'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <span className='text-base font-semibold uppercase'>
                        Team
                      </span>
                    </div>
                    <div className='flex items-end mb-4'>
                      <span className='mr-2 text-5xl font-semibold tracking-tighter'>
                        $10
                      </span>
                      <span className='inline-block pb-2 text-lg text-gray-400'>
                        /month
                      </span>
                    </div>
                    <span className='block text-gray-400 mb-8'>
                      For individual or freelancers
                    </span>
                    <div className='p-4 mb-8 border border-gray-200 rounded-xl'>
                      <div className='mb-4'>
                        <span className='text-sm font-medium'>100</span>
                        <span className='text-sm font-medium text-gray-500'>
                          shared users
                        </span>
                      </div>
                      <div>
                        <span className='text-sm font-medium'>200GB</span>
                        <span className='text-sm font-medium text-gray-500'>
                          cloud storage
                        </span>
                      </div>
                    </div>
                    <a
                      className='group relative flex items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                      href='#'
                    >
                      <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                      <span>Get Started</span>
                    </a>
                  </div>
                  <div className='flex mb-10 items-start'>
                    <span className='flex-shrink-0 inline-flex w-5 h-5 items-center justify-center bg-white bg-opacity-10 border border-white border-opacity-20 rounded-full'>
                      <svg
                        width={14}
                        height={14}
                        viewBox='0 0 14 14'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2.91699 7.58337L5.25033 9.91671L11.0837 4.08337'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <div className='ml-4'>
                      <p className='text-sm'>
                        <span className='font-semibold text-white'>
                          Unlimited collections.
                        </span>
                        <span className='text-gray-200'>
                          Each collection can includes unlimited folders.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='flex items-start'>
                    <span className='flex-shrink-0 inline-flex w-5 h-5 items-center justify-center bg-white bg-opacity-10 border border-white border-opacity-20 rounded-full'>
                      <svg
                        width={14}
                        height={14}
                        viewBox='0 0 14 14'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2.91699 7.58337L5.25033 9.91671L11.0837 4.08337'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <div className='ml-4'>
                      <p className='text-sm'>
                        <span className='font-semibold text-white'>
                          80+ file extensions.
                        </span>
                        <span className='text-gray-200'>
                          We support over 80 type of file extensions.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4'>
                  <div className='p-8 mb-10 bg-teal-800 rounded-xl'>
                    <div className='flex mb-8 items-center'>
                      <span className='mr-3'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M15 9H20.4C20.7314 9 21 9.26863 21 9.6V20.4C21 20.7314 20.7314 21 20.4 21H9.6C9.26863 21 9 20.7314 9 20.4V15'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M15 9V3.6C15 3.26863 14.7314 3 14.4 3H3.6C3.26863 3 3 3.26863 3 3.6V14.4C3 14.7314 3.26863 15 3.6 15H9'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <span className='text-base font-semibold text-white uppercase'>
                        Enterprise
                      </span>
                    </div>
                    <div className='mb-4'>
                      <span className='text-5xl font-semibold text-white tracking-tighter'>
                        Custom
                      </span>
                    </div>
                    <span className='block text-gray-400 mb-8'>
                      Please contact sales for details
                    </span>
                    <div className='p-4 mb-8 border border-teal-700 rounded-xl'>
                      <div className='mb-4'>
                        <span className='text-sm font-medium text-white'>
                          25
                        </span>
                        <span className='text-sm font-medium text-gray-400'>
                          shared users
                        </span>
                      </div>
                      <div>
                        <span className='text-sm font-medium text-white'>
                          50GB
                        </span>
                        <span className='text-sm font-medium text-gray-400'>
                          cloud storage
                        </span>
                      </div>
                    </div>
                    <a
                      className='flex items-center justify-center w-full h-12 px-4 text-center text-base text-gray-900 hover:text-white font-bold bg-white hover:bg-gray-900 rounded-lg transition duration-200'
                      href='#'
                    >
                      Contact Sales
                    </a>
                  </div>
                  <div className='flex mb-10 items-start'>
                    <span className='flex-shrink-0 inline-flex w-5 h-5 items-center justify-center bg-white bg-opacity-10 border border-white border-opacity-20 rounded-full'>
                      <svg
                        width={14}
                        height={14}
                        viewBox='0 0 14 14'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2.91699 7.58337L5.25033 9.91671L11.0837 4.08337'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <div className='ml-4'>
                      <p className='text-sm'>
                        <span className='font-semibold text-white'>
                          Unlimited team members.
                        </span>
                        <span className='text-gray-200'>
                          Invite your teams to organize and collect company’s
                          assets.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='flex items-start'>
                    <span className='flex-shrink-0 inline-flex w-5 h-5 items-center justify-center bg-white bg-opacity-10 border border-white border-opacity-20 rounded-full'>
                      <svg
                        width={14}
                        height={14}
                        viewBox='0 0 14 14'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2.91699 7.58337L5.25033 9.91671L11.0837 4.08337'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <div className='ml-4'>
                      <p className='text-sm'>
                        <span className='font-semibold text-white'>
                          Live preview for video.
                        </span>
                        <span className='text-gray-200'>
                          Enough to get you started.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-sm md:max-w-md lg:max-w-6xl mx-auto'>
              <div className='text-center mb-16'>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-4'>
                  Flexible pricing plan
                </h1>
                <p className='text-lg text-gray-500'>
                  Plan that scales with your business immediately.
                </p>
              </div>
              <div className='flex mb-24 justify-center'>
                <div className='inline-flex items-center p-1 bg-gray-50 rounded-lg'>
                  <a
                    className='inline-block px-4 mr-1 py-2.5 text-sm text-gray-500 bg-transparent border border-transparent rounded-lg'
                    href='#'
                  >
                    Monthly
                  </a>
                  <a
                    className='inline-block px-4 py-2.5 text-sm font-medium text-gray-900 bg-white border border-gray-100 rounded-lg'
                    href='#'
                  >
                    Annually
                  </a>
                </div>
              </div>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/3 px-4 mb-12 lg:mb-0'>
                  <div className='p-8 mb-10 bg-white border border-gray-100 rounded-xl shadow-sm'>
                    <div className='flex mb-8 items-center'>
                      <span className='mr-3'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M15 3.6V14.4C15 14.7314 14.7314 15 14.4 15H3.6C3.26863 15 3 14.7314 3 14.4V3.6C3 3.26863 3.26863 3 3.6 3H14.4C14.7314 3 15 3.26863 15 3.6Z'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M13.5 21H16.5'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M21 13.5V16.5'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M21 19.5V20.4C21 20.7314 20.7314 21 20.4 21H19.5'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M10.5 21H9.6C9.26863 21 9 20.7314 9 20.4V19.5'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M19.5 9H20.4C20.7314 9 21 9.26863 21 9.6V10.5'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M16.5 9H9.6C9.26863 9 9 9.26863 9 9.6V16.5'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <span className='text-base font-semibold uppercase'>
                        Starter
                      </span>
                    </div>
                    <div className='flex items-end mb-4'>
                      <span className='mr-2 text-5xl font-semibold tracking-tighter'>
                        $0
                      </span>
                      <span className='inline-block pb-2 text-lg text-gray-500'>
                        /month
                      </span>
                    </div>
                    <span className='block text-gray-500 mb-8'>
                      For individual or freelancers
                    </span>
                    <div className='p-4 mb-8 border border-gray-200 rounded-xl'>
                      <div className='mb-4'>
                        <span className='font-medium'>25</span>
                        <span className='font-medium text-gray-500'>
                          shared users
                        </span>
                      </div>
                      <div>
                        <span className='font-medium'>50GB</span>
                        <span className='font-medium text-gray-500'>
                          cloud storage
                        </span>
                      </div>
                    </div>
                    <a
                      className='flex items-center justify-center w-full h-12 px-4 text-center text-base text-gray-700 hover:text-yellowGreen-700 font-bold border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                      href='#'
                    >
                      Get Started
                    </a>
                  </div>
                  <div>
                    <div className='flex mb-10 items-start'>
                      <span className='flex-shrink-0 inline-flex w-5 h-5 items-center justify-center border border-gray-100 rounded-full'>
                        <svg
                          width={14}
                          height={14}
                          viewBox='0 0 14 14'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M2.91699 7.58337L5.25033 9.91671L11.0837 4.08337'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <div className='ml-4'>
                        <p className='text-sm'>
                          <span className='font-semibold text-gray-900'>
                            10 collections.
                          </span>
                          <span className='text-gray-500'>
                            Enough to get you started. Start collecting your
                            assets now.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className='flex items-start'>
                      <span className='flex-shrink-0 inline-flex w-5 h-5 items-center justify-center border border-gray-100 rounded-full'>
                        <svg
                          width={14}
                          height={14}
                          viewBox='0 0 14 14'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M2.91699 7.58337L5.25033 9.91671L11.0837 4.08337'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <div className='ml-4'>
                        <p className='text-sm'>
                          <span className='font-semibold text-gray-900'>
                            5 file extensions.
                          </span>
                          <span className='text-gray-500'>
                            Save files in .png, .svg, .jpg, .mp4, and .gif.
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4 mb-12 lg:mb-0'>
                  <div className='relative p-8 mb-10 bg-white border border-gray-100 rounded-xl shadow-sm'>
                    <span className='absolute top-0 right-0 m-8 inline-block px-2.5 leading-relaxed text-sm text-white bg-gray-800 uppercase rounded-full'>
                      Popular
                    </span>
                    <div className='flex mb-8 items-center'>
                      <span className='mr-3'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M10.5 15H9.6C9.26863 15 9 15.2686 9 15.6V20.4C9 20.7314 9.26863 21 9.6 21H20.4C20.7314 21 21 20.7314 21 20.4V9.6C21 9.26863 20.7314 9 20.4 9H15.6C15.2686 9 15 9.26863 15 9.6V10.5'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M13.5 15H14.4C14.7314 15 15 14.7314 15 14.4V13.5'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M9 13.5V14.4C9 14.7314 8.73137 15 8.4 15H3.6C3.26863 15 3 14.7314 3 14.4V3.6C3 3.26863 3.26863 3 3.6 3H14.4C14.7314 3 15 3.26863 15 3.6V8.4C15 8.73137 14.7314 9 14.4 9H13.5'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M9 10.5V9.6C9 9.26863 9.26863 9 9.6 9H10.5'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <span className='text-base font-semibold uppercase'>
                        Team
                      </span>
                    </div>
                    <div className='flex items-end mb-4'>
                      <span className='mr-2 text-5xl font-semibold tracking-tighter'>
                        $0
                      </span>
                      <span className='inline-block pb-2 text-lg text-gray-500'>
                        /month
                      </span>
                    </div>
                    <span className='block text-gray-500 mb-8'>
                      For individual or freelancers
                    </span>
                    <div className='p-4 mb-8 border border-gray-200 rounded-xl'>
                      <div className='mb-4'>
                        <span className='text-sm font-medium'>100</span>
                        <span className='text-sm font-medium text-gray-500'>
                          shared users
                        </span>
                      </div>
                      <div>
                        <span className='text-sm font-medium'>200GB</span>
                        <span className='text-sm font-medium text-gray-500'>
                          cloud storage
                        </span>
                      </div>
                    </div>
                    <a
                      className='group relative flex items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                      href='#'
                    >
                      <div className='absolute top-0 left-0 w-full h-full rounded-lg ring-4 ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                      <span>Get Started</span>
                    </a>
                  </div>
                  <div className='flex mb-10 items-start'>
                    <span className='flex-shrink-0 inline-flex w-5 h-5 items-center justify-center border border-gray-100 rounded-full'>
                      <svg
                        width={14}
                        height={14}
                        viewBox='0 0 14 14'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2.91699 7.58337L5.25033 9.91671L11.0837 4.08337'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <div className='ml-4'>
                      <p className='text-sm'>
                        <span className='font-semibold text-gray-900'>
                          Unlimited collections.
                        </span>
                        <span className='text-gray-500'>
                          Each collection can includes unlimited folders.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='flex items-start'>
                    <span className='flex-shrink-0 inline-flex w-5 h-5 items-center justify-center border border-gray-100 rounded-full'>
                      <svg
                        width={14}
                        height={14}
                        viewBox='0 0 14 14'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2.91699 7.58337L5.25033 9.91671L11.0837 4.08337'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <div className='ml-4'>
                      <p className='text-sm'>
                        <span className='font-semibold text-gray-900'>
                          80+ file extensions.
                        </span>
                        <span className='text-gray-500'>
                          We support over 80 type of file extensions.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4'>
                  <div className='p-8 mb-10 bg-teal-900 rounded-xl'>
                    <div className='flex mb-8 items-center'>
                      <span className='mr-3'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M15 9H20.4C20.7314 9 21 9.26863 21 9.6V20.4C21 20.7314 20.7314 21 20.4 21H9.6C9.26863 21 9 20.7314 9 20.4V15'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M15 9V3.6C15 3.26863 14.7314 3 14.4 3H3.6C3.26863 3 3 3.26863 3 3.6V14.4C3 14.7314 3.26863 15 3.6 15H9'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                      <span className='text-base font-semibold text-white uppercase'>
                        Enterprise
                      </span>
                    </div>
                    <div className='mb-4'>
                      <span className='text-5xl font-semibold text-white tracking-tighter'>
                        Custom
                      </span>
                    </div>
                    <span className='block text-gray-400 mb-8'>
                      Please contact sales for details
                    </span>
                    <div className='p-4 mb-8 border border-teal-800 rounded-xl'>
                      <div className='mb-4'>
                        <span className='text-sm font-medium text-white'>
                          Up to 500
                        </span>
                        <span className='text-sm font-medium text-gray-400'>
                          shared users
                        </span>
                      </div>
                      <div>
                        <span className='text-sm font-medium text-white'>
                          1TB
                        </span>
                        <span className='text-sm font-medium text-gray-400'>
                          cloud storage
                        </span>
                      </div>
                    </div>
                    <a
                      className='flex items-center justify-center w-full h-12 px-4 text-center text-base text-gray-900 hover:text-white font-bold bg-white hover:bg-gray-900 rounded-lg transition duration-200'
                      href='#'
                    >
                      Contact Sales
                    </a>
                  </div>
                  <div className='flex mb-10 items-start'>
                    <span className='flex-shrink-0 inline-flex w-5 h-5 items-center justify-center border border-gray-100 rounded-full'>
                      <svg
                        width={14}
                        height={14}
                        viewBox='0 0 14 14'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2.91699 7.58337L5.25033 9.91671L11.0837 4.08337'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <div className='ml-4'>
                      <p className='text-sm'>
                        <span className='font-semibold text-gray-900'>
                          Unlimited team members.
                        </span>
                        <span className='text-gray-500'>
                          Invite your teams to organize and collect company’s
                          assets.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='flex items-start'>
                    <span className='flex-shrink-0 inline-flex w-5 h-5 items-center justify-center border border-gray-100 rounded-full'>
                      <svg
                        width={14}
                        height={14}
                        viewBox='0 0 14 14'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2.91699 7.58337L5.25033 9.91671L11.0837 4.08337'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <div className='ml-4'>
                      <p className='text-sm'>
                        <span className='font-semibold text-gray-900'>
                          Live preview for video.
                        </span>
                        <span className='text-gray-500'>
                          Enough to get you started.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='pt-24 pb-32 lg:pb-24 bg-white'>
            <div className='container px-4 mx-auto'>
              <div className='max-w-md lg:max-w-6xl mx-auto mb-28'>
                <div className='text-center mb-16'>
                  <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-4'>
                    Flexible pricing plan
                  </h1>
                  <p className='text-lg text-gray-500'>
                    Pricing that scales with your business immediately.
                  </p>
                </div>
                <div className='flex justify-center'>
                  <div className='inline-flex items-center p-1 bg-gray-50 rounded-lg'>
                    <a
                      className='inline-block px-4 mr-1 py-2.5 text-sm text-gray-500 bg-transparent border border-transparent rounded-lg'
                      href='#'
                    >
                      Monthly
                    </a>
                    <a
                      className='inline-block px-4 py-2.5 text-sm font-medium text-gray-900 bg-white border border-gray-100 rounded-lg'
                      href='#'
                    >
                      Annually
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='pb-16 lg:pb-0 lg:h-112 bg-gray-50'>
            <div className='container px-4 mx-auto'>
              <div className='max-w-md lg:max-w-6xl mx-auto'>
                <div className='flex flex-wrap -mx-4'>
                  <div className='w-full lg:w-1/3 px-4 mb-48'>
                    <div className='-mt-40 p-8 bg-white border border-gray-100 rounded-xl shadow-xl'>
                      <div className='flex mb-8 items-center'>
                        <span className='mr-3'>
                          <svg
                            width={24}
                            height={24}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15 3.6V14.4C15 14.7314 14.7314 15 14.4 15H3.6C3.26863 15 3 14.7314 3 14.4V3.6C3 3.26863 3.26863 3 3.6 3H14.4C14.7314 3 15 3.26863 15 3.6Z'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M13.5 21H16.5'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M21 13.5V16.5'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M21 19.5V20.4C21 20.7314 20.7314 21 20.4 21H19.5'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M10.5 21H9.6C9.26863 21 9 20.7314 9 20.4V19.5'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M19.5 9H20.4C20.7314 9 21 9.26863 21 9.6V10.5'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M16.5 9H9.6C9.26863 9 9 9.26863 9 9.6V16.5'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                        <span className='text-base font-semibold uppercase'>
                          Starter
                        </span>
                      </div>
                      <div className='flex items-end mb-4'>
                        <span className='mr-2 text-5xl font-semibold tracking-tighter'>
                          $0
                        </span>
                        <span className='inline-block pb-2 text-lg text-gray-500'>
                          /month
                        </span>
                      </div>
                      <span className='block text-gray-500 mb-8'>
                        No credit card required!
                      </span>
                      <div className='p-4 mb-8 border border-gray-200 rounded-xl'>
                        <span className='block mb-4 text-sm font-semibold'>
                          What’s included
                        </span>
                        <ul>
                          <li className='flex items-center mb-4'>
                            <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                  fill='#041109'
                                />
                              </svg>
                            </div>
                            <span className='text-sm'>
                              Complete documentation
                            </span>
                          </li>
                          <li className='flex items-center mb-4'>
                            <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                  fill='#041109'
                                />
                              </svg>
                            </div>
                            <span className='text-sm'>
                              Working materials in Figma
                            </span>
                          </li>
                          <li className='flex items-center'>
                            <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                  fill='#041109'
                                />
                              </svg>
                            </div>
                            <span className='text-sm'>100GB cloud storage</span>
                          </li>
                        </ul>
                      </div>
                      <a
                        className='flex items-center justify-center w-full h-12 px-4 text-center text-base text-gray-700 hover:text-yellowGreen-700 font-bold border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                        href='#'
                      >
                        Get Started
                      </a>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/3 px-4 mb-48'>
                    <div className='relative -mt-40 p-8 bg-white border border-gray-100 rounded-xl shadow-xl'>
                      <span className='absolute top-0 right-0 m-8 inline-block px-2.5 leading-relaxed text-sm text-white bg-gray-800 uppercase rounded-full'>
                        Popular
                      </span>
                      <div className='flex mb-8 items-center'>
                        <span className='mr-3'>
                          <svg
                            width={24}
                            height={24}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M10.5 15H9.6C9.26863 15 9 15.2686 9 15.6V20.4C9 20.7314 9.26863 21 9.6 21H20.4C20.7314 21 21 20.7314 21 20.4V9.6C21 9.26863 20.7314 9 20.4 9H15.6C15.2686 9 15 9.26863 15 9.6V10.5'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M13.5 15H14.4C14.7314 15 15 14.7314 15 14.4V13.5'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M9 13.5V14.4C9 14.7314 8.73137 15 8.4 15H3.6C3.26863 15 3 14.7314 3 14.4V3.6C3 3.26863 3.26863 3 3.6 3H14.4C14.7314 3 15 3.26863 15 3.6V8.4C15 8.73137 14.7314 9 14.4 9H13.5'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M9 10.5V9.6C9 9.26863 9.26863 9 9.6 9H10.5'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                        <span className='text-base font-semibold uppercase'>
                          Team
                        </span>
                      </div>
                      <div className='flex items-end mb-4'>
                        <span className='mr-2 text-5xl font-semibold tracking-tighter'>
                          $10
                        </span>
                        <span className='inline-block pb-2 text-lg text-gray-500'>
                          /month
                        </span>
                      </div>
                      <span className='block text-gray-500 mb-8'>
                        No credit card required!
                      </span>
                      <div className='p-4 mb-8 border border-gray-200 rounded-xl'>
                        <span className='block mb-4 text-sm font-semibold'>
                          All in the Starter plan plus:
                        </span>
                        <ul>
                          <li className='flex items-center mb-4'>
                            <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                  fill='#041109'
                                />
                              </svg>
                            </div>
                            <span className='text-sm'>
                              Complete documentation
                            </span>
                          </li>
                          <li className='flex items-center mb-4'>
                            <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                  fill='#041109'
                                />
                              </svg>
                            </div>
                            <span className='text-sm'>
                              Working materials in Figma
                            </span>
                          </li>
                          <li className='flex items-center'>
                            <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                  fill='#041109'
                                />
                              </svg>
                            </div>
                            <span className='text-sm'>100GB cloud storage</span>
                          </li>
                        </ul>
                      </div>
                      <a
                        className='group relative flex items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                        href='#'
                      >
                        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                        <span>Get Started</span>
                      </a>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/3 px-4'>
                    <div className='-mt-40 p-8 bg-white border border-gray-100 rounded-xl shadow-xl'>
                      <div className='flex mb-8 items-center'>
                        <span className='mr-3'>
                          <svg
                            width={24}
                            height={24}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15 9H20.4C20.7314 9 21 9.26863 21 9.6V20.4C21 20.7314 20.7314 21 20.4 21H9.6C9.26863 21 9 20.7314 9 20.4V15'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M15 9V3.6C15 3.26863 14.7314 3 14.4 3H3.6C3.26863 3 3 3.26863 3 3.6V14.4C3 14.7314 3.26863 15 3.6 15H9'
                              stroke='#041109'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                        <span className='text-base font-semibold uppercase'>
                          Enterprise
                        </span>
                      </div>
                      <div className='mb-4'>
                        <span className='text-5xl font-semibold tracking-tighter'>
                          Custom
                        </span>
                      </div>
                      <span className='block text-gray-500 mb-8'>
                        Please contact sales for details
                      </span>
                      <div className='p-4 mb-8 border border-gray-200 rounded-xl'>
                        <span className='block mb-4 text-sm font-semibold'>
                          All in the Team plan plus:
                        </span>
                        <ul>
                          <li className='flex items-center mb-4'>
                            <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                  fill='#041109'
                                />
                              </svg>
                            </div>
                            <span className='text-sm'>
                              Complete documentation
                            </span>
                          </li>
                          <li className='flex items-center mb-4'>
                            <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                  fill='#041109'
                                />
                              </svg>
                            </div>
                            <span className='text-sm'>
                              Working materials in Figma
                            </span>
                          </li>
                          <li className='flex items-center'>
                            <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                              <svg
                                width={20}
                                height={20}
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                  fill='#041109'
                                />
                              </svg>
                            </div>
                            <span className='text-sm'>100GB cloud storage</span>
                          </li>
                        </ul>
                      </div>
                      <a
                        className='flex items-center justify-center w-full h-12 px-4 text-center text-base text-gray-700 hover:text-yellowGreen-700 font-bold border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                        href='#'
                      >
                        Contact Sales
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-50'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-md lg:max-w-6xl mx-auto'>
              <div className='flex flex-wrap items-end -mx-4 mb-24'>
                <div className='w-full lg:w-1/2 px-4 mb-10 lg:mb-0'>
                  <div className='max-w-sm sm:max-w-md'>
                    <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold mb-4'>
                      Flexible pricing plan
                    </h1>
                    <p className='text-lg text-gray-500'>
                      Plan that scales with your business immediately.
                    </p>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 px-4'>
                  <div className='flex justify-end'>
                    <div className='inline-flex items-center'>
                      <a
                        className='inline-block px-4 mr-1 py-2.5 text-sm text-gray-500 bg-transparent border border-transparent rounded-lg'
                        href='#'
                      >
                        Monthly
                      </a>
                      <a
                        className='inline-block px-4 py-2.5 text-sm font-medium text-gray-900 bg-white border border-gray-100 rounded-lg'
                        href='#'
                      >
                        Annually
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/3 px-4 mb-8 lg:mb-0'>
                  <div className='p-8 bg-white border border-gray-100 rounded-xl shadow-xl'>
                    <h5 className='text-xl font-medium mb-4'>Starter</h5>
                    <span className='block mb-2 text-3xl font-semibold tracking-tighter'>
                      $0
                    </span>
                    <span className='block mb-6 text-gray-500'>per month</span>
                    <a
                      className='flex items-center justify-center w-full h-12 px-4 mb-8 text-center text-base text-gray-700 hover:text-yellowGreen-700 font-bold border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                      href='#'
                    >
                      Get Started
                    </a>
                    <span className='block mb-4 text-sm font-semibold'>
                      What’s included
                    </span>
                    <ul>
                      <li className='flex items-center mb-4'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='#041109'
                            />
                          </svg>
                        </div>
                        <span>Complete documentation</span>
                      </li>
                      <li className='flex items-center mb-4'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='#041109'
                            />
                          </svg>
                        </div>
                        <span>Working materials in Figma</span>
                      </li>
                      <li className='flex items-center'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='#041109'
                            />
                          </svg>
                        </div>
                        <span>100GB cloud storage</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4 mb-8 lg:mb-0'>
                  <div className='relative p-8 bg-teal-900 rounded-xl'>
                    <span className='absolute top-0 right-0 m-8 inline-block px-2 py-px text-xs text-white bg-teal-800 rounded-full'>
                      Most Popular
                    </span>
                    <h5 className='text-xl font-medium text-white mb-4'>
                      Team
                    </h5>
                    <span className='block mb-2 text-3xl font-semibold tracking-tighter text-white'>
                      $10
                    </span>
                    <span className='block mb-6 text-gray-400'>per month</span>
                    <a
                      className='group relative w-full h-12 mb-8 flex items-center justify-center px-5 p-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300'
                      href='#'
                    >
                      <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                      <span>Get Started</span>
                    </a>
                    <span className='block mb-4 text-sm font-semibold text-white'>
                      All features in the Plan plan plus:
                    </span>
                    <ul>
                      <li className='flex items-center mb-4'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='#041109'
                            />
                          </svg>
                        </div>
                        <span className='text-white'>
                          Complete documentation
                        </span>
                      </li>
                      <li className='flex items-center mb-4'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='#041109'
                            />
                          </svg>
                        </div>
                        <span className='text-white'>
                          Working materials in Figma
                        </span>
                      </li>
                      <li className='flex items-center'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='#041109'
                            />
                          </svg>
                        </div>
                        <span className='text-white'>100GB cloud storage</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4'>
                  <div className='p-8 bg-white border border-gray-100 rounded-xl shadow-xl'>
                    <h5 className='text-xl font-medium mb-4'>Enterprise</h5>
                    <span className='block mb-2 text-3xl font-semibold tracking-tighter'>
                      Custom
                    </span>
                    <span className='block mb-6 text-gray-500'>
                      Please contact sales for details
                    </span>
                    <a
                      className='flex items-center justify-center w-full h-12 px-4 mb-8 text-center text-base text-gray-700 hover:text-yellowGreen-700 font-bold border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                      href='#'
                    >
                      Contact Sales
                    </a>
                    <span className='block mb-4 text-sm font-semibold'>
                      What’s included
                    </span>
                    <ul>
                      <li className='flex items-center mb-4'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='#041109'
                            />
                          </svg>
                        </div>
                        <span>Complete documentation</span>
                      </li>
                      <li className='flex items-center mb-4'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='#041109'
                            />
                          </svg>
                        </div>
                        <span>Working materials in Figma</span>
                      </li>
                      <li className='flex items-center'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 bg-gray-50 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='#041109'
                            />
                          </svg>
                        </div>
                        <span>100GB cloud storage</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-md lg:max-w-6xl mx-auto'>
              <div className='flex flex-wrap items-end -mx-4 mb-24'>
                <div className='w-full lg:w-1/2 px-4 mb-10 lg:mb-0'>
                  <div className='max-w-sm sm:max-w-md'>
                    <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold text-white mb-4'>
                      Flexible pricing plan for your startup
                    </h1>
                    <p className='text-lg text-gray-400'>
                      Pricing that scales with your business immediately.
                    </p>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 px-4'>
                  <div className='flex items-center lg:justify-end'>
                    <span className='font-medium text-white'>Monthly</span>
                    <div className='inline-flex items-center h-6 px-px mx-4 bg-yellowGreen-600 rounded-full'>
                      <button variant="classic" className='inline-block w-5 h-5 rounded-full bg-transparent focus:outline-none' />
                      <button variant="classic" className='inline-block w-5 h-5 rounded-full bg-white focus:outline-none' />
                    </div>
                    <div className='flex items-center'>
                      <span className='font-medium text-white'>Yearly</span>
                      <span className='inline-block ml-2 px-2 py-px bg-gray-800 text-xs text-white rounded-full'>
                        15% OFF
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/3 px-4 mb-18 lg:mb-0'>
                  <div className='h-full p-8 bg-white bg-opacity-10 border border-white border-opacity-20 rounded-xl'>
                    <div className='text-center mb-8'>
                      <h5 className='text-xl font-medium text-white mb-4'>
                        Starter
                      </h5>
                      <div className='mb-4'>
                        <span className='text-5xl font-semibold text-white tracking-tighter'>
                          $0
                        </span>
                        <span className='text-lg text-gray-200 font-medium'>
                          /month
                        </span>
                      </div>
                      <span className='text-gray-200'>Billed annually</span>
                    </div>
                    <a
                      className='flex justify-center items-center w-full h-12 mb-8 p-1 text-center text-base text-white bg-white bg-opacity-20 hover:bg-opacity-30 border border-white border-opacity-20 font-semibold rounded-lg'
                      href='#'
                    >
                      <span>Get Started</span>
                    </a>
                    <ul>
                      <li className='flex items-center mb-4'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 bg-white bg-opacity-10 border border-white border-opacity-20 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='white'
                            />
                          </svg>
                        </div>
                        <span className='text-white'>
                          Complete documentation
                        </span>
                      </li>
                      <li className='flex items-center mb-4'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 bg-white bg-opacity-10 border border-white border-opacity-20 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='white'
                            />
                          </svg>
                        </div>
                        <span className='text-white'>
                          Working materials in Figma
                        </span>
                      </li>
                      <li className='flex items-center'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 bg-white bg-opacity-10 border border-white border-opacity-20 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='white'
                            />
                          </svg>
                        </div>
                        <span className='text-white'>100GB cloud storage</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4 mb-8 lg:mb-0 -mt-10'>
                  <div className='relative h-full pt-10'>
                    <div className='absolute top-0 bottom-0 inset-0 left-0 w-full h-full pt-2 px-2 text-center rounded-xl bg-white bg-opacity-5'>
                      <span className='text-xs font-semibold text-white'>
                        RECOMMENDED
                      </span>
                    </div>
                    <div className='relative h-full p-8 bg-white bg-opacity-5 border border-white border-opacity-20 rounded-xl'>
                      <div className='text-center mb-8'>
                        <h5 className='text-xl font-medium text-white mb-4'>
                          Team
                        </h5>
                        <div className='mb-4'>
                          <span className='text-5xl font-semibold text-white tracking-tighter'>
                            $10
                          </span>
                          <span className='text-lg text-gray-200 font-medium'>
                            /month
                          </span>
                        </div>
                        <span className='text-gray-200'>Billed annually</span>
                      </div>
                      <a
                        className='group relative w-full h-12 mb-8 flex items-center justify-center px-5 p-px font-bold text-gray-900 bg-white rounded-lg transition-all duration-300'
                        href='#'
                      >
                        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-gray-400 animate-pulse group-hover:ring-0 transition duration-300' />
                        <span>Get Started</span>
                      </a>
                      <ul>
                        <li className='flex items-center mb-4'>
                          <div className='flex items-center justify-center w-5 h-5 mr-3 bg-white bg-opacity-10 border border-white border-opacity-20 rounded-full'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                fill='white'
                              />
                            </svg>
                          </div>
                          <span className='text-white'>
                            Complete documentation
                          </span>
                        </li>
                        <li className='flex items-center mb-4'>
                          <div className='flex items-center justify-center w-5 h-5 mr-3 bg-white bg-opacity-10 border border-white border-opacity-20 rounded-full'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                fill='white'
                              />
                            </svg>
                          </div>
                          <span className='text-white'>
                            Working materials in Figma
                          </span>
                        </li>
                        <li className='flex items-center'>
                          <div className='flex items-center justify-center w-5 h-5 mr-3 bg-white bg-opacity-10 border border-white border-opacity-20 rounded-full'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                                fill='white'
                              />
                            </svg>
                          </div>
                          <span className='text-white'>
                            100GB cloud storage
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4'>
                  <div className='h-full p-8 bg-white bg-opacity-10 border border-white border-opacity-20 rounded-xl'>
                    <div className='text-center mb-8'>
                      <h5 className='text-xl font-medium text-white mb-4'>
                        Enterprise
                      </h5>
                      <div className='mb-4'>
                        <span className='text-5xl font-semibold text-white tracking-tighter'>
                          Custom
                        </span>
                      </div>
                      <span className='text-gray-200'>
                        Please contact sales for details
                      </span>
                    </div>
                    <a
                      className='flex justify-center items-center w-full h-12 mb-8 p-1 text-center text-base text-white bg-white bg-opacity-20 hover:bg-opacity-30 border border-white border-opacity-20 font-semibold rounded-lg'
                      href='#'
                    >
                      <span>Get Started</span>
                    </a>
                    <ul>
                      <li className='flex items-center mb-4'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 bg-white bg-opacity-10 border border-white border-opacity-20 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='white'
                            />
                          </svg>
                        </div>
                        <span className='text-white'>
                          Complete documentation
                        </span>
                      </li>
                      <li className='flex items-center mb-4'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 bg-white bg-opacity-10 border border-white border-opacity-20 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='white'
                            />
                          </svg>
                        </div>
                        <span className='text-white'>
                          Working materials in Figma
                        </span>
                      </li>
                      <li className='flex items-center'>
                        <div className='flex items-center justify-center w-5 h-5 mr-3 bg-white bg-opacity-10 border border-white border-opacity-20 rounded-full'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z'
                              fill='white'
                            />
                          </svg>
                        </div>
                        <span className='text-white'>100GB cloud storage</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-lg mb-24 text-center mx-auto'>
              <span className='inline-flex items-center h-6 mb-4 px-2 text-xs uppercase font-medium text-white bg-gray-800 rounded-full'>
                Pricing
              </span>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white'>
                Flexible pricing plan
              </h1>
            </div>
            <div className='max-w-md lg:max-w-6xl mx-auto'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/3 px-4 mb-8 lg:mb-0'>
                  <div className='pt-4 pb-8 px-4 bg-gray-900 border border-gray-800 rounded-xl'>
                    <div className='p-6 mb-8 bg-gray-800 rounded-xl'>
                      <h5 className='text-base font-semibold uppercase text-white mb-4'>
                        Starter
                      </h5>
                      <div className='flex mb-4 items-end'>
                        <span className='mr-1 text-5xl font-semibold text-white tracking-tighter'>
                          $0
                        </span>
                        <span className='inline-block pb-2 text-gray-400'>
                          /month
                        </span>
                      </div>
                      <span className='block text-gray-400'>
                        No credit card required!
                      </span>
                    </div>
                    <div className='px-4'>
                      <span className='block mb-4 text-sm font-semibold text-white'>
                        What’s included
                      </span>
                      <ul className='mb-8'>
                        <li className='flex items-center mb-4'>
                          <div className='flex items-center justify-center w-5 h-5 mr-3 bg-white rounded-full'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M15.5917 6.00828C15.5142 5.93017 15.4221 5.86818 15.3205 5.82587C15.219 5.78356 15.11 5.76178 15 5.76178C14.89 5.76178 14.7811 5.78356 14.6796 5.82587C14.578 5.86818 14.4858 5.93017 14.4084 6.00828L8.20004 12.2249L5.59171 9.60828C5.51127 9.53058 5.41632 9.46948 5.31227 9.42848C5.20823 9.38748 5.09713 9.36737 4.98531 9.3693C4.87349 9.37124 4.76315 9.39518 4.66058 9.43976C4.55802 9.48433 4.46524 9.54868 4.38754 9.62911C4.30984 9.70955 4.24875 9.8045 4.20774 9.90854C4.16674 10.0126 4.14663 10.1237 4.14856 10.2355C4.1505 10.3473 4.17444 10.4577 4.21902 10.5602C4.2636 10.6628 4.32794 10.7556 4.40837 10.8333L7.60837 14.0333C7.68584 14.1114 7.77801 14.1734 7.87956 14.2157C7.98111 14.258 8.09003 14.2798 8.20004 14.2798C8.31005 14.2798 8.41897 14.258 8.52052 14.2157C8.62207 14.1734 8.71424 14.1114 8.79171 14.0333L15.5917 7.23328C15.6763 7.15524 15.7438 7.06053 15.79 6.95512C15.8361 6.8497 15.86 6.73586 15.86 6.62078C15.86 6.50569 15.8361 6.39186 15.79 6.28644C15.7438 6.18102 15.6763 6.08631 15.5917 6.00828V6.00828Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                          <span className='text-white'>
                            Complete documentation
                          </span>
                        </li>
                        <li className='flex items-center mb-4'>
                          <div className='flex items-center justify-center w-5 h-5 mr-3 bg-white rounded-full'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M15.5917 6.00828C15.5142 5.93017 15.4221 5.86818 15.3205 5.82587C15.219 5.78356 15.11 5.76178 15 5.76178C14.89 5.76178 14.7811 5.78356 14.6796 5.82587C14.578 5.86818 14.4858 5.93017 14.4084 6.00828L8.20004 12.2249L5.59171 9.60828C5.51127 9.53058 5.41632 9.46948 5.31227 9.42848C5.20823 9.38748 5.09713 9.36737 4.98531 9.3693C4.87349 9.37124 4.76315 9.39518 4.66058 9.43976C4.55802 9.48433 4.46524 9.54868 4.38754 9.62911C4.30984 9.70955 4.24875 9.8045 4.20774 9.90854C4.16674 10.0126 4.14663 10.1237 4.14856 10.2355C4.1505 10.3473 4.17444 10.4577 4.21902 10.5602C4.2636 10.6628 4.32794 10.7556 4.40837 10.8333L7.60837 14.0333C7.68584 14.1114 7.77801 14.1734 7.87956 14.2157C7.98111 14.258 8.09003 14.2798 8.20004 14.2798C8.31005 14.2798 8.41897 14.258 8.52052 14.2157C8.62207 14.1734 8.71424 14.1114 8.79171 14.0333L15.5917 7.23328C15.6763 7.15524 15.7438 7.06053 15.79 6.95512C15.8361 6.8497 15.86 6.73586 15.86 6.62078C15.86 6.50569 15.8361 6.39186 15.79 6.28644C15.7438 6.18102 15.6763 6.08631 15.5917 6.00828V6.00828Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                          <span className='text-white'>
                            Working materials in Figma
                          </span>
                        </li>
                        <li className='flex items-center'>
                          <div className='flex items-center justify-center w-5 h-5 mr-3 bg-white rounded-full'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M15.5917 6.00828C15.5142 5.93017 15.4221 5.86818 15.3205 5.82587C15.219 5.78356 15.11 5.76178 15 5.76178C14.89 5.76178 14.7811 5.78356 14.6796 5.82587C14.578 5.86818 14.4858 5.93017 14.4084 6.00828L8.20004 12.2249L5.59171 9.60828C5.51127 9.53058 5.41632 9.46948 5.31227 9.42848C5.20823 9.38748 5.09713 9.36737 4.98531 9.3693C4.87349 9.37124 4.76315 9.39518 4.66058 9.43976C4.55802 9.48433 4.46524 9.54868 4.38754 9.62911C4.30984 9.70955 4.24875 9.8045 4.20774 9.90854C4.16674 10.0126 4.14663 10.1237 4.14856 10.2355C4.1505 10.3473 4.17444 10.4577 4.21902 10.5602C4.2636 10.6628 4.32794 10.7556 4.40837 10.8333L7.60837 14.0333C7.68584 14.1114 7.77801 14.1734 7.87956 14.2157C7.98111 14.258 8.09003 14.2798 8.20004 14.2798C8.31005 14.2798 8.41897 14.258 8.52052 14.2157C8.62207 14.1734 8.71424 14.1114 8.79171 14.0333L15.5917 7.23328C15.6763 7.15524 15.7438 7.06053 15.79 6.95512C15.8361 6.8497 15.86 6.73586 15.86 6.62078C15.86 6.50569 15.8361 6.39186 15.79 6.28644C15.7438 6.18102 15.6763 6.08631 15.5917 6.00828V6.00828Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                          <span className='text-white'>
                            100GB cloud storage
                          </span>
                        </li>
                      </ul>
                      <a
                        className='flex items-center justify-center w-full h-12 px-4 text-center text-base text-white font-bold border border-gray-800 bg-gray-900 hover:bg-gray-800 rounded-lg transition duration-200'
                        href='#'
                      >
                        Get Started
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4 mb-8 lg:mb-0'>
                  <div className='h-full pt-4 pb-8 px-4 bg-gray-800 border border-gray-700 rounded-xl shadow-xl'>
                    <div className='p-6 mb-8 bg-gray-700 rounded-xl'>
                      <h5 className='text-base font-semibold uppercase text-white mb-4'>
                        TEAM
                      </h5>
                      <div className='flex mb-4 items-end'>
                        <span className='mr-1 text-5xl font-semibold text-white tracking-tighter'>
                          $10
                        </span>
                        <span className='inline-block pb-2 text-gray-500'>
                          /month
                        </span>
                      </div>
                      <span className='block text-gray-400'>
                        Please contact sales for details
                      </span>
                    </div>
                    <div className='px-4'>
                      <span className='block mb-4 text-sm font-semibold text-white'>
                        All features in Starter plan plus:
                      </span>
                      <ul className='mb-8'>
                        <li className='flex items-center mb-4'>
                          <div className='flex items-center justify-center w-5 h-5 mr-3 bg-white rounded-full'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M15.5917 6.00828C15.5142 5.93017 15.4221 5.86818 15.3205 5.82587C15.219 5.78356 15.11 5.76178 15 5.76178C14.89 5.76178 14.7811 5.78356 14.6796 5.82587C14.578 5.86818 14.4858 5.93017 14.4084 6.00828L8.20004 12.2249L5.59171 9.60828C5.51127 9.53058 5.41632 9.46948 5.31227 9.42848C5.20823 9.38748 5.09713 9.36737 4.98531 9.3693C4.87349 9.37124 4.76315 9.39518 4.66058 9.43976C4.55802 9.48433 4.46524 9.54868 4.38754 9.62911C4.30984 9.70955 4.24875 9.8045 4.20774 9.90854C4.16674 10.0126 4.14663 10.1237 4.14856 10.2355C4.1505 10.3473 4.17444 10.4577 4.21902 10.5602C4.2636 10.6628 4.32794 10.7556 4.40837 10.8333L7.60837 14.0333C7.68584 14.1114 7.77801 14.1734 7.87956 14.2157C7.98111 14.258 8.09003 14.2798 8.20004 14.2798C8.31005 14.2798 8.41897 14.258 8.52052 14.2157C8.62207 14.1734 8.71424 14.1114 8.79171 14.0333L15.5917 7.23328C15.6763 7.15524 15.7438 7.06053 15.79 6.95512C15.8361 6.8497 15.86 6.73586 15.86 6.62078C15.86 6.50569 15.8361 6.39186 15.79 6.28644C15.7438 6.18102 15.6763 6.08631 15.5917 6.00828V6.00828Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                          <span className='text-white'>
                            Up to 500 shared users
                          </span>
                        </li>
                        <li className='flex items-center mb-4'>
                          <div className='flex items-center justify-center w-5 h-5 mr-3 bg-white rounded-full'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M15.5917 6.00828C15.5142 5.93017 15.4221 5.86818 15.3205 5.82587C15.219 5.78356 15.11 5.76178 15 5.76178C14.89 5.76178 14.7811 5.78356 14.6796 5.82587C14.578 5.86818 14.4858 5.93017 14.4084 6.00828L8.20004 12.2249L5.59171 9.60828C5.51127 9.53058 5.41632 9.46948 5.31227 9.42848C5.20823 9.38748 5.09713 9.36737 4.98531 9.3693C4.87349 9.37124 4.76315 9.39518 4.66058 9.43976C4.55802 9.48433 4.46524 9.54868 4.38754 9.62911C4.30984 9.70955 4.24875 9.8045 4.20774 9.90854C4.16674 10.0126 4.14663 10.1237 4.14856 10.2355C4.1505 10.3473 4.17444 10.4577 4.21902 10.5602C4.2636 10.6628 4.32794 10.7556 4.40837 10.8333L7.60837 14.0333C7.68584 14.1114 7.77801 14.1734 7.87956 14.2157C7.98111 14.258 8.09003 14.2798 8.20004 14.2798C8.31005 14.2798 8.41897 14.258 8.52052 14.2157C8.62207 14.1734 8.71424 14.1114 8.79171 14.0333L15.5917 7.23328C15.6763 7.15524 15.7438 7.06053 15.79 6.95512C15.8361 6.8497 15.86 6.73586 15.86 6.62078C15.86 6.50569 15.8361 6.39186 15.79 6.28644C15.7438 6.18102 15.6763 6.08631 15.5917 6.00828V6.00828Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                          <span className='text-white'>2 company accounts</span>
                        </li>
                        <li className='flex items-center'>
                          <div className='flex items-center justify-center w-5 h-5 mr-3 bg-white rounded-full'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M15.5917 6.00828C15.5142 5.93017 15.4221 5.86818 15.3205 5.82587C15.219 5.78356 15.11 5.76178 15 5.76178C14.89 5.76178 14.7811 5.78356 14.6796 5.82587C14.578 5.86818 14.4858 5.93017 14.4084 6.00828L8.20004 12.2249L5.59171 9.60828C5.51127 9.53058 5.41632 9.46948 5.31227 9.42848C5.20823 9.38748 5.09713 9.36737 4.98531 9.3693C4.87349 9.37124 4.76315 9.39518 4.66058 9.43976C4.55802 9.48433 4.46524 9.54868 4.38754 9.62911C4.30984 9.70955 4.24875 9.8045 4.20774 9.90854C4.16674 10.0126 4.14663 10.1237 4.14856 10.2355C4.1505 10.3473 4.17444 10.4577 4.21902 10.5602C4.2636 10.6628 4.32794 10.7556 4.40837 10.8333L7.60837 14.0333C7.68584 14.1114 7.77801 14.1734 7.87956 14.2157C7.98111 14.258 8.09003 14.2798 8.20004 14.2798C8.31005 14.2798 8.41897 14.258 8.52052 14.2157C8.62207 14.1734 8.71424 14.1114 8.79171 14.0333L15.5917 7.23328C15.6763 7.15524 15.7438 7.06053 15.79 6.95512C15.8361 6.8497 15.86 6.73586 15.86 6.62078C15.86 6.50569 15.8361 6.39186 15.79 6.28644C15.7438 6.18102 15.6763 6.08631 15.5917 6.00828V6.00828Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                          <span className='text-white'>1TB cloud storage</span>
                        </li>
                      </ul>
                      <a
                        className='flex items-center justify-center w-full h-12 px-4 text-center text-base text-gray-900 font-bold bg-yellowGreen-600 hover:bg-white border border-transparent hover:border-gray-900 rounded-lg transition duration-200'
                        href='#'
                      >
                        Get Started
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4'>
                  <div className='pt-4 pb-8 px-4 bg-gray-900 border border-gray-800 rounded-xl'>
                    <div className='p-6 mb-8 bg-gray-800 rounded-xl'>
                      <h5 className='text-base font-semibold uppercase text-white mb-4'>
                        Starter
                      </h5>
                      <span className='block text-5xl font-semibold text-white tracking-tighter mb-4'>
                        Custom
                      </span>
                      <span className='block text-gray-400'>
                        No credit card required!
                      </span>
                    </div>
                    <div className='px-4'>
                      <span className='block mb-4 text-sm font-semibold text-white'>
                        What’s included
                      </span>
                      <ul className='mb-8'>
                        <li className='flex items-center mb-4'>
                          <div className='flex items-center justify-center w-5 h-5 mr-3 bg-white rounded-full'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M15.5917 6.00828C15.5142 5.93017 15.4221 5.86818 15.3205 5.82587C15.219 5.78356 15.11 5.76178 15 5.76178C14.89 5.76178 14.7811 5.78356 14.6796 5.82587C14.578 5.86818 14.4858 5.93017 14.4084 6.00828L8.20004 12.2249L5.59171 9.60828C5.51127 9.53058 5.41632 9.46948 5.31227 9.42848C5.20823 9.38748 5.09713 9.36737 4.98531 9.3693C4.87349 9.37124 4.76315 9.39518 4.66058 9.43976C4.55802 9.48433 4.46524 9.54868 4.38754 9.62911C4.30984 9.70955 4.24875 9.8045 4.20774 9.90854C4.16674 10.0126 4.14663 10.1237 4.14856 10.2355C4.1505 10.3473 4.17444 10.4577 4.21902 10.5602C4.2636 10.6628 4.32794 10.7556 4.40837 10.8333L7.60837 14.0333C7.68584 14.1114 7.77801 14.1734 7.87956 14.2157C7.98111 14.258 8.09003 14.2798 8.20004 14.2798C8.31005 14.2798 8.41897 14.258 8.52052 14.2157C8.62207 14.1734 8.71424 14.1114 8.79171 14.0333L15.5917 7.23328C15.6763 7.15524 15.7438 7.06053 15.79 6.95512C15.8361 6.8497 15.86 6.73586 15.86 6.62078C15.86 6.50569 15.8361 6.39186 15.79 6.28644C15.7438 6.18102 15.6763 6.08631 15.5917 6.00828V6.00828Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                          <span className='text-white'>
                            Complete documentation
                          </span>
                        </li>
                        <li className='flex items-center mb-4'>
                          <div className='flex items-center justify-center w-5 h-5 mr-3 bg-white rounded-full'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M15.5917 6.00828C15.5142 5.93017 15.4221 5.86818 15.3205 5.82587C15.219 5.78356 15.11 5.76178 15 5.76178C14.89 5.76178 14.7811 5.78356 14.6796 5.82587C14.578 5.86818 14.4858 5.93017 14.4084 6.00828L8.20004 12.2249L5.59171 9.60828C5.51127 9.53058 5.41632 9.46948 5.31227 9.42848C5.20823 9.38748 5.09713 9.36737 4.98531 9.3693C4.87349 9.37124 4.76315 9.39518 4.66058 9.43976C4.55802 9.48433 4.46524 9.54868 4.38754 9.62911C4.30984 9.70955 4.24875 9.8045 4.20774 9.90854C4.16674 10.0126 4.14663 10.1237 4.14856 10.2355C4.1505 10.3473 4.17444 10.4577 4.21902 10.5602C4.2636 10.6628 4.32794 10.7556 4.40837 10.8333L7.60837 14.0333C7.68584 14.1114 7.77801 14.1734 7.87956 14.2157C7.98111 14.258 8.09003 14.2798 8.20004 14.2798C8.31005 14.2798 8.41897 14.258 8.52052 14.2157C8.62207 14.1734 8.71424 14.1114 8.79171 14.0333L15.5917 7.23328C15.6763 7.15524 15.7438 7.06053 15.79 6.95512C15.8361 6.8497 15.86 6.73586 15.86 6.62078C15.86 6.50569 15.8361 6.39186 15.79 6.28644C15.7438 6.18102 15.6763 6.08631 15.5917 6.00828V6.00828Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                          <span className='text-white'>
                            Working materials in Figma
                          </span>
                        </li>
                        <li className='flex items-center'>
                          <div className='flex items-center justify-center w-5 h-5 mr-3 bg-white rounded-full'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M15.5917 6.00828C15.5142 5.93017 15.4221 5.86818 15.3205 5.82587C15.219 5.78356 15.11 5.76178 15 5.76178C14.89 5.76178 14.7811 5.78356 14.6796 5.82587C14.578 5.86818 14.4858 5.93017 14.4084 6.00828L8.20004 12.2249L5.59171 9.60828C5.51127 9.53058 5.41632 9.46948 5.31227 9.42848C5.20823 9.38748 5.09713 9.36737 4.98531 9.3693C4.87349 9.37124 4.76315 9.39518 4.66058 9.43976C4.55802 9.48433 4.46524 9.54868 4.38754 9.62911C4.30984 9.70955 4.24875 9.8045 4.20774 9.90854C4.16674 10.0126 4.14663 10.1237 4.14856 10.2355C4.1505 10.3473 4.17444 10.4577 4.21902 10.5602C4.2636 10.6628 4.32794 10.7556 4.40837 10.8333L7.60837 14.0333C7.68584 14.1114 7.77801 14.1734 7.87956 14.2157C7.98111 14.258 8.09003 14.2798 8.20004 14.2798C8.31005 14.2798 8.41897 14.258 8.52052 14.2157C8.62207 14.1734 8.71424 14.1114 8.79171 14.0333L15.5917 7.23328C15.6763 7.15524 15.7438 7.06053 15.79 6.95512C15.8361 6.8497 15.86 6.73586 15.86 6.62078C15.86 6.50569 15.8361 6.39186 15.79 6.28644C15.7438 6.18102 15.6763 6.08631 15.5917 6.00828V6.00828Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                          <span className='text-white'>
                            100GB cloud storage
                          </span>
                        </li>
                      </ul>
                      <a
                        className='group flex justify-center w-full h-12 p-1 text-center text-base text-white font-semibold rounded-lg'
                        href='#'
                      >
                        <div className='inline-flex items-stretch w-full h-12 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                          <div className='flex w-full items-center justify-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                            <span>Contact Sales</span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    <FooterComponent /></React.Fragment>
  );
}

