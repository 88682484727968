import React from "react"

import { Flex, Text, Button, DropdownMenu, IconButton} from "@radix-ui/themes"
import {CaretDownIcon, MagnifyingGlassIcon } from "@radix-ui/react-icons"

import { FooterComponent } from "./Footers.jsx"
import { Helmet, HelmetProvider } from "react-helmet-async"

const meta = {
    title: "",
    meta: [],
    link: [],
    script: [],
}

export default function Index() {
    return (
        <React.Fragment>
            <HelmetProvider>
                <Helmet {...meta}></Helmet>
            </HelmetProvider>
            <>
                <section className="relative bg-gray-900">
                    <nav className="relative">
                        <div className="container mx-auto px-4">
                            <div className="relative flex h-24 items-center">
                                <a className="inline-block" href="#">
                                    <img
                                        className="h-10"
                                        src="aurora-assets/logos/aurora-logo-white.svg"
                                        alt=""
                                    />
                                </a>
                                <Button
                                    variant="classic"
                                    className="lg:hidden navbar-burger flex items-center justify-center h-10 w-10 ml-auto border border-teal-900 bg-teal-200 bg-opacity-30 hover:bg-teal-700 rounded-lg">
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3 5H21"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M3 12H21"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M3 19H21"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </Button>
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden lg:flex items-center">
                                    <a
                                        className="inline-block text-white hover:text-teal-400 mr-10"
                                        href="#">
                                        <div className="group flex items-center">
                                            <span className="mr-3">
                                                Product
                                            </span>
                                            <svg
                                                width={10}
                                                height={6}
                                                viewBox="0 0 10 6"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M1 1L5 5L9 1"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </a>
                                    <div className="group-hover:block absolute m-0 hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] md:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 w-full hidden z-10 top-full left-0 min-w-[15rem] bg-white md:shadow-2xl rounded-lg py-2 md:p-4 dark:bg-gray-800 dark:divide-gray-700 before:absolute before:-top-5 before:left-0 before:w-full before:h-5">
                                        <a
                                            className="inline-block text-white hover:text-teal-400 mr-10"
                                            href="#"></a>
                                        <div className="md:grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                                            <a
                                                className="inline-block text-white hover:text-teal-400 mr-10"
                                                href="#"></a>
                                            <div className="flex flex-col mx-1 md:mx-0">
                                                <a
                                                    className="inline-block text-white hover:text-teal-400 mr-10"
                                                    href="#"></a>
                                                <a
                                                    className="group flex gap-x-5 text-gray-800 hover:bg-gray-100 rounded-md p-4 dark:text-gray-200 dark:hover:bg-gray-900"
                                                    href="#">
                                                    <svg
                                                        className="flex-shrink-0 w-6 h-6 mt-1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={16}
                                                        height={16}
                                                        fill="currentColor"
                                                        viewBox="0 0 16 16">
                                                        <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                                                    </svg>
                                                    <div className="grow">
                                                        <p className="font-medium text-gray-800 dark:text-gray-200">
                                                            Support Docs
                                                        </p>
                                                        <p className="text-sm text-gray-500 group-hover:text-gray-800 dark:group-hover:text-gray-200">
                                                            Explore advice and
                                                            explanations for all
                                                            of Preline's
                                                            features.
                                                        </p>
                                                    </div>
                                                </a>
                                                <a
                                                    className="group flex gap-x-5 text-gray-800 hover:bg-gray-100 rounded-md p-4 dark:text-gray-200 dark:hover:bg-gray-900"
                                                    href="#">
                                                    <svg
                                                        className="flex-shrink-0 w-6 h-6 mt-1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={16}
                                                        height={16}
                                                        fill="currentColor"
                                                        viewBox="0 0 16 16">
                                                        <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09zM4.157 8.5H7a.5.5 0 0 1 .478.647L6.11 13.59l5.732-6.09H9a.5.5 0 0 1-.478-.647L9.89 2.41 4.157 8.5z" />
                                                    </svg>
                                                    <div className="grow">
                                                        <p className="font-medium text-gray-800 dark:text-gray-200">
                                                            Integrations
                                                        </p>
                                                        <p className="text-sm text-gray-500 group-hover:text-gray-800 dark:group-hover:text-gray-200">
                                                            Discover the huge
                                                            range of tools that
                                                            Preline integrates
                                                            with.
                                                        </p>
                                                    </div>
                                                </a>
                                                <a
                                                    className="group flex gap-x-5 text-gray-800 hover:bg-gray-100 rounded-md p-4 dark:text-gray-200 dark:hover:bg-gray-900"
                                                    href="#">
                                                    <svg
                                                        className="flex-shrink-0 w-6 h-6 mt-1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={16}
                                                        height={16}
                                                        fill="currentColor"
                                                        viewBox="0 0 16 16">
                                                        <path d="M6 9a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3A.5.5 0 0 1 6 9zM3.854 4.146a.5.5 0 1 0-.708.708L4.793 6.5 3.146 8.146a.5.5 0 1 0 .708.708l2-2a.5.5 0 0 0 0-.708l-2-2z" />
                                                        <path d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h12z" />
                                                    </svg>
                                                    <div className="grow">
                                                        <p className="font-medium text-gray-800 dark:text-gray-200">
                                                            API Reference
                                                        </p>
                                                        <p className="text-sm text-gray-500 group-hover:text-gray-800 dark:group-hover:text-gray-200">
                                                            Build custom
                                                            integrations with
                                                            our first-class API.
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="flex flex-col mx-1 md:mx-0">
                                                <a
                                                    className="group flex gap-x-5 text-gray-800 hover:bg-gray-100 rounded-md p-4 dark:text-gray-200 dark:hover:bg-gray-900"
                                                    href="#">
                                                    <svg
                                                        className="flex-shrink-0 w-6 h-6 mt-1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={16}
                                                        height={16}
                                                        fill="currentColor"
                                                        viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                                                    </svg>
                                                    <div className="grow">
                                                        <p className="font-medium text-gray-800 dark:text-gray-200">
                                                            Help Center
                                                        </p>
                                                        <p className="text-sm text-gray-500 group-hover:text-gray-800 dark:group-hover:text-gray-200">
                                                            Learn how to
                                                            install, set up, and
                                                            use Preline.
                                                        </p>
                                                    </div>
                                                </a>
                                                <a
                                                    className="group flex gap-x-5 text-gray-800 hover:bg-gray-100 rounded-md p-4 dark:text-gray-200 dark:hover:bg-gray-900"
                                                    href="#">
                                                    <svg
                                                        className="flex-shrink-0 w-6 h-6 mt-1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={16}
                                                        height={16}
                                                        fill="currentColor"
                                                        viewBox="0 0 16 16">
                                                        <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z" />
                                                    </svg>
                                                    <div className="grow">
                                                        <p className="font-medium text-gray-800 dark:text-gray-200">
                                                            Developer Hub
                                                        </p>
                                                        <p className="text-sm text-gray-500 group-hover:text-gray-800 dark:group-hover:text-gray-200">
                                                            Learn how to
                                                            integrate or build
                                                            on top of Preline.
                                                        </p>
                                                    </div>
                                                </a>
                                                <a
                                                    className="group flex gap-x-5 text-gray-800 hover:bg-gray-100 rounded-md p-4 dark:text-gray-200 dark:hover:bg-gray-900"
                                                    href="#">
                                                    <svg
                                                        className="flex-shrink-0 w-6 h-6 mt-1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={16}
                                                        height={16}
                                                        fill="currentColor"
                                                        viewBox="0 0 16 16">
                                                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                                                    </svg>
                                                    <div className="grow">
                                                        <p className="font-medium text-gray-800 dark:text-gray-200">
                                                            Community Forum
                                                        </p>
                                                        <p className="text-sm text-gray-500 group-hover:text-gray-800 dark:group-hover:text-gray-200">
                                                            Learn, share, and
                                                            connect with other
                                                            Preline users.
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="flex flex-col pt-4 md:pt-0 mx-1 md:mx-0">
                                                <span className="text-sm font-semibold uppercase text-gray-800 dark:text-gray-200">
                                                    Customer stories
                                                </span>
                                                <a
                                                    className="mt-4 group flex gap-x-5 items-center"
                                                    href="#">
                                                    <img
                                                        className="w-32 h-32 rounded-lg"
                                                        src="https://images.unsplash.com/photo-1648737967328-690548aec14f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=320&q=80"
                                                        alt="Image Description"
                                                    />
                                                    <div className="grow">
                                                        <p className="text-gray-800 dark:text-gray-200">
                                                            Preline Projects has
                                                            proved to be most
                                                            efficient cloud
                                                            based project
                                                            tracking and bug
                                                            tracking tool.
                                                        </p>
                                                        <p className="mt-3 inline-flex items-center gap-x-2 text-sm font-semibold text-gray-800 dark:text-gray-200">
                                                            Learn more
                                                            <svg
                                                                className="w-2.5 h-2.5 transition ease-in-out group-hover:translate-x-1"
                                                                width={16}
                                                                height={16}
                                                                viewBox="0 0 16 16"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M0.975821 6.92249C0.43689 6.92249 -3.50468e-07 7.34222 -3.27835e-07 7.85999C-3.05203e-07 8.37775 0.43689 8.79749 0.975821 8.79749L12.7694 8.79748L7.60447 13.7596C7.22339 14.1257 7.22339 14.7193 7.60447 15.0854C7.98555 15.4515 8.60341 15.4515 8.98449 15.0854L15.6427 8.68862C16.1191 8.23098 16.1191 7.48899 15.6427 7.03134L8.98449 0.634573C8.60341 0.268455 7.98555 0.268456 7.60447 0.634573C7.22339 1.00069 7.22339 1.59428 7.60447 1.9604L12.7694 6.92248L0.975821 6.92249Z"
                                                                    fill="currentColor"
                                                                />
                                                            </svg>
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <a
                                        className="inline-block text-white hover:text-teal-400 mr-10"
                                        href="#">
                                        Features
                                    </a>
                                    <a
                                        className="inline-block text-white hover:text-teal-400 mr-10"
                                        href="#">
                                        Pricing
                                    </a>
                                    <a
                                        className="inline-block text-white hover:text-teal-400"
                                        href="#">
                                        Resources
                                    </a>
                                </div>
                                <div className="hidden lg:block ml-auto">
                                <Flex gap="3">
  <IconButton variant="classic">
    <MagnifyingGlassIcon width="18" height="18" />
  </IconButton>
                                <DropdownMenu.Root>
  <DropdownMenu.Trigger>
    <Button variant="soft">
      Options
      <CaretDownIcon />
    </Button>
  </DropdownMenu.Trigger>
  <DropdownMenu.Content>
    <DropdownMenu.Item shortcut="⌘ E">Edit</DropdownMenu.Item>
    <DropdownMenu.Item shortcut="⌘ D">Duplicate</DropdownMenu.Item>
    <DropdownMenu.Separator />
    <DropdownMenu.Item shortcut="⌘ N">Archive</DropdownMenu.Item>

    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger>More</DropdownMenu.SubTrigger>
      <DropdownMenu.SubContent>
        <DropdownMenu.Item>Move to project…</DropdownMenu.Item>
        <DropdownMenu.Item>Move to folder…</DropdownMenu.Item>

        <DropdownMenu.Separator />
        <DropdownMenu.Item>Advanced options…</DropdownMenu.Item>
      </DropdownMenu.SubContent>
    </DropdownMenu.Sub>

    <DropdownMenu.Separator />
    <DropdownMenu.Item>Share</DropdownMenu.Item>
    <DropdownMenu.Item>Add to favorites</DropdownMenu.Item>
    <DropdownMenu.Separator />
    <DropdownMenu.Item shortcut="⌘ ⌫" color="red">
      Delete
    </DropdownMenu.Item>
  </DropdownMenu.Content>
</DropdownMenu.Root>
                                    <Button variant="classic">
                                        Get Started
                                        </Button>
                                        </Flex>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <div className="relative pt-12 md:pt-24">
                        <div className="container px-4 mx-auto">
                            <div className="max-w-2xl mb-14 mx-auto text-center">
                                <a
                                    className="group relative inline-flex mb-6 items-center rounded-full overflow-hidden shadow-3xl"
                                    href="#">
                                    <div className="animate-showLine absolute bottom-0 left-1/2 transform -translate-x-1/2 p-px mx-auto w-auto bg-gradient-to-r from-transparent via-yellowGreen-500 to-transparent" />
                                    <div className="flex flex-wrap sm:flex-nowrap items-center px-6 sm:px-4 py-4 sm:py-2 bg-gray-800 group-hover:bg-opacity-60 rounded-full transition duration-300">
                                        <span className="text-sm text-white">
                                            Get ahead of the game with insights
                                            from 1200+ top marketers.
                                        </span>
                                        <div className="mt-1 sm:mt-0 mx-auto sm:mr-0 sm:ml-2 flex items-center">
                                            <span className="text-transparent bg-clip-text bg-gradient-to-br font-bold from-green-500 via-teal-500 to-cyan-800 transition duration-300">
                                                Read more
                                            </span>
                                            <span className="ml-3">
                                                <svg
                                                    width={11}
                                                    height={10}
                                                    viewBox="0 0 11 10"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M1.00004 5H9.33337M9.33337 5L5.33337 1M9.33337 5L5.33337 9"
                                                        stroke="url(#paint0_linear_3112_14890)"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <defs>
                                                        <linearGradient
                                                            id="paint0_linear_3112_14890"
                                                            x1="9.46358"
                                                            y1="-1.5"
                                                            x2="5.46163"
                                                            y2="9.11123"
                                                            gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#ACE348">
                                                                <stop
                                                                    offset={1}
                                                                    stopColor="#25ACA0"
                                                                />
                                                            </stop>
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </a>
                                <div className="max-w-lg sm:max-w-xl lg:max-w-none mx-auto">
                                    <h1 className="font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-bold text-white mb-6">
                                        Your marketing assets, at your
                                        fingertips.
                                    </h1>
                                    <p className="max-w-lg mx-auto text-lg text-gray-300 mb-10">
                                        Empowers marketing team to easily store,
                                        organize, and access valuable assets
                                        from anywhere.
                                    </p>
                                    <a
                                        className="group relative flex xs:inline-flex items-center justify-center px-5 h-12 font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300"
                                        href="#">
                                        <div className="absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300" />
                                        <span className="mr-3">
                                            Request Access
                                        </span>
                                        <span className="transform group-hover:translate-x-1 transition duration-300">
                                            <svg
                                                width={7}
                                                height={12}
                                                viewBox="0 0 7 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M1 1L6 6L1 11"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <img
                                className="block mx-auto"
                                src="aurora-assets/headers/header-dark-theme-center.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50">
                        <div className="navbar-backdrop fixed inset-0 bg-teal-800 opacity-70" />
                        <nav className="relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto">
                            <div className="flex mb-auto items-center">
                                <a className="inline-block mr-auto" href="#">
                                    <img
                                        className="h-10"
                                        src="aurora-assets/logos/aurora-logo.svg"
                                        alt=""
                                    />
                                </a>
                                <a className="navbar-close" href="#">
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6 18L18 6M6 6L18 18"
                                            stroke="#111827"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </a>
                            </div>
                            <div className="py-12 mb-auto">
                                <ul className="flex-col">
                                    <li className="mb-6">
                                        <a
                                            className="inline-block text-black"
                                            href="#">
                                            Product
                                        </a>
                                    </li>
                                    <li className="mb-6">
                                        <a
                                            className="inline-block text-black"
                                            href="#">
                                            Features
                                        </a>
                                    </li>
                                    <li className="mb-6">
                                        <a
                                            className="inline-block text-black"
                                            href="#">
                                            Pricing
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="inline-block text-black"
                                            href="#">
                                            Resources
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <a
                                    className="flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200"
                                    href="#">
                                    Login
                                </a>
                                <a
                                    className="flex items-center justify-center h-12 px-4 text-center text-base text-white font-semibold bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg transition duration-200"
                                    href="#">
                                    Get Started
                                </a>
                            </div>
                        </nav>
                    </div>
                </section>
                <section className="relative py-12 md:py-24 bg-radial-dark-green">
                    <div className="container px-4 mx-auto">
                        <div className="text-center mb-20">
                            <h1 className="font-heading tracking-tight text-4xl sm:text-5xl font-semibold text-white">
                                Frequently asked questions
                            </h1>
                        </div>
                        <div className="max-w-6xl mx-auto">
                            <div className="flex flex-wrap -mx-4">
                                <div className="w-full md:w-1/2 px-4 mb-3 md:mb-0">
                                    <button
                                        variant="classic"
                                        className="group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none">
                                        <div className="pr-4">
                                            <h6 className="font-semibold text-white">
                                                What is Aurora?
                                            </h6>
                                            <p className="hidden group-hover:block mt-2 text-sm text-gray-300">
                                                Orders are usually shipped
                                                within 1-2 business days after
                                                placing the order.
                                            </p>
                                        </div>
                                        <div className="flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full">
                                            <svg
                                                className="group-hover:hidden"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 6L8 10L12 6"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <svg
                                                className="hidden group-hover:block"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 10L8 6L12 10"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                    <button
                                        variant="classic"
                                        className="group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none">
                                        <div className="pr-4">
                                            <h6 className="font-semibold text-white">
                                                How does it work?
                                            </h6>
                                            <p className="hidden group-hover:block mt-2 text-sm text-gray-300">
                                                Orders are usually shipped
                                                within 1-2 business days after
                                                placing the order.
                                            </p>
                                        </div>
                                        <div className="flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full">
                                            <svg
                                                className="group-hover:hidden"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 6L8 10L12 6"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <svg
                                                className="hidden group-hover:block"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 10L8 6L12 10"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                    <button
                                        variant="classic"
                                        className="group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none">
                                        <div className="pr-4">
                                            <h6 className="font-semibold text-white">
                                                How much does it cost?
                                            </h6>
                                            <p className="hidden group-hover:block mt-2 text-sm text-gray-300">
                                                Orders are usually shipped
                                                within 1-2 business days after
                                                placing the order.
                                            </p>
                                        </div>
                                        <div className="flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full">
                                            <svg
                                                className="group-hover:hidden"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 6L8 10L12 6"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <svg
                                                className="hidden group-hover:block"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 10L8 6L12 10"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                    <button
                                        variant="classic"
                                        className="group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none">
                                        <div className="pr-4">
                                            <h6 className="font-semibold text-white">
                                                Is there a free trial available?
                                            </h6>
                                            <p className="hidden group-hover:block mt-2 text-sm text-gray-300">
                                                Orders are usually shipped
                                                within 1-2 business days after
                                                placing the order.
                                            </p>
                                        </div>
                                        <div className="flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full">
                                            <svg
                                                className="group-hover:hidden"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 6L8 10L12 6"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <svg
                                                className="hidden group-hover:block"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 10L8 6L12 10"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                    <button
                                        variant="classic"
                                        className="group flex items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none">
                                        <div className="pr-4">
                                            <h6 className="font-semibold text-white">
                                                How to export my assets?
                                            </h6>
                                            <p className="hidden group-hover:block mt-2 text-sm text-gray-300">
                                                Orders are usually shipped
                                                within 1-2 business days after
                                                placing the order.
                                            </p>
                                        </div>
                                        <div className="flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full">
                                            <svg
                                                className="group-hover:hidden"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 6L8 10L12 6"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <svg
                                                className="hidden group-hover:block"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 10L8 6L12 10"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                </div>
                                <div className="w-full md:w-1/2 px-4">
                                    <button
                                        variant="classic"
                                        className="group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none">
                                        <div className="pr-4">
                                            <h6 className="font-semibold text-white">
                                                What is Aurora?
                                            </h6>
                                            <p className="hidden group-hover:block mt-2 text-sm text-gray-300">
                                                Orders are usually shipped
                                                within 1-2 business days after
                                                placing the order.
                                            </p>
                                        </div>
                                        <div className="flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full">
                                            <svg
                                                className="group-hover:hidden"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 6L8 10L12 6"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <svg
                                                className="hidden group-hover:block"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 10L8 6L12 10"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                    <button
                                        variant="classic"
                                        className="group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none">
                                        <div className="pr-4">
                                            <h6 className="font-semibold text-white">
                                                How does it work?
                                            </h6>
                                            <p className="hidden group-hover:block mt-2 text-sm text-gray-300">
                                                Orders are usually shipped
                                                within 1-2 business days after
                                                placing the order.
                                            </p>
                                        </div>
                                        <div className="flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full">
                                            <svg
                                                className="group-hover:hidden"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 6L8 10L12 6"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <svg
                                                className="hidden group-hover:block"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 10L8 6L12 10"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                    <button
                                        variant="classic"
                                        className="group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none">
                                        <div className="pr-4">
                                            <h6 className="font-semibold text-white">
                                                How much does it cost?
                                            </h6>
                                            <p className="hidden group-hover:block mt-2 text-sm text-gray-300">
                                                Orders are usually shipped
                                                within 1-2 business days after
                                                placing the order.
                                            </p>
                                        </div>
                                        <div className="flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full">
                                            <svg
                                                className="group-hover:hidden"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 6L8 10L12 6"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <svg
                                                className="hidden group-hover:block"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 10L8 6L12 10"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                    <button
                                        variant="classic"
                                        className="group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none">
                                        <div className="pr-4">
                                            <h6 className="font-semibold text-white">
                                                Is there a free trial available?
                                            </h6>
                                            <p className="hidden group-hover:block mt-2 text-sm text-gray-300">
                                                Orders are usually shipped
                                                within 1-2 business days after
                                                placing the order.
                                            </p>
                                        </div>
                                        <div className="flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full">
                                            <svg
                                                className="group-hover:hidden"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 6L8 10L12 6"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <svg
                                                className="hidden group-hover:block"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 10L8 6L12 10"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                    <button
                                        variant="classic"
                                        className="group flex items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none">
                                        <div className="pr-4">
                                            <h6 className="font-semibold text-white">
                                                How to export my assets?
                                            </h6>
                                            <p className="hidden group-hover:block mt-2 text-sm text-gray-300">
                                                Orders are usually shipped
                                                within 1-2 business days after
                                                placing the order.
                                            </p>
                                        </div>
                                        <div className="flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full">
                                            <svg
                                                className="group-hover:hidden"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 6L8 10L12 6"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <svg
                                                className="hidden group-hover:block"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 10L8 6L12 10"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="relative bg-radial-dark-green">
                    <nav className="relative">
                        <div className="container mx-auto px-4">
                            <div className="relative flex h-24 items-center">
                                <a className="inline-block" href="#">
                                    <img
                                        className="h-10"
                                        src="aurora-assets/logos/aurora-logo-white.svg"
                                        alt=""
                                    />
                                </a>
                                <button
                                    variant="classic"
                                    className="lg:hidden navbar-burger flex items-center justify-center h-10 w-10 ml-auto border border-teal-900 bg-teal-200 bg-opacity-30 hover:bg-teal-700 rounded-lg">
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3 5H21"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M3 12H21"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M3 19H21"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </button>
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden lg:flex items-center">
                                    <a
                                        className="inline-block text-white hover:text-teal-400 mr-10"
                                        href="#">
                                        <div className="flex items-center">
                                            <span className="mr-3">
                                                Product
                                            </span>
                                            <svg
                                                width={10}
                                                height={6}
                                                viewBox="0 0 10 6"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M1 1L5 5L9 1"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </a>
                                    <a
                                        className="inline-block text-white hover:text-teal-400 mr-10"
                                        href="#">
                                        Features
                                    </a>
                                    <a
                                        className="inline-block text-white hover:text-teal-400 mr-10"
                                        href="#">
                                        Pricing
                                    </a>
                                    <a
                                        className="inline-block text-white hover:text-teal-400"
                                        href="#">
                                        Resources
                                    </a>
                                </div>
                                <div className="hidden lg:block ml-auto">
                                    <a
                                        className="inline-flex items-center justify-center h-10 mr-4 px-4 text-center text-sm text-white hover:text-teal-400 font-semibold transition duration-200"
                                        href="#">
                                        Login
                                    </a>
                                    <a
                                        className="group inline-block justify-center p-1 text-center text-sm text-white font-semibold rounded-lg"
                                        href="#">
                                        <div className="inline-flex items-stretch h-10 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800">
                                            <div className="flex items-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300">
                                                <span>Get Started</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <div className="relative pt-24">
                        <div className="container px-4 mx-auto">
                            <div className="max-w-2xl mb-14 mx-auto text-center">
                                <a
                                    className="group relative inline-flex mb-6 items-center rounded-full overflow-hidden shadow-3xl"
                                    href="#">
                                    <div className="animate-showLine absolute bottom-0 left-1/2 transform -translate-x-1/2 p-px mx-auto w-auto bg-gradient-to-r from-transparent via-yellowGreen-500 to-transparent" />
                                    <div className="flex flex-wrap sm:flex-nowrap items-center px-4 py-4 sm:py-2 bg-teal-800 group-hover:bg-opacity-80 rounded-full transition duration-300">
                                        <span className="text-sm text-white">
                                            Get ahead of the game with insights
                                            from 1200+ top marketers.
                                        </span>
                                        <div className="mt-1 sm:mt-0 mx-auto sm:mr-0 sm:ml-2 flex items-center">
                                            <span className="text-transparent bg-clip-text bg-gradient-to-b font-bold from-green-500 via-green-500 to-teal-400 transition duration-300">
                                                Read more
                                            </span>
                                            <span className="ml-3">
                                                <svg
                                                    width={11}
                                                    height={10}
                                                    viewBox="0 0 11 10"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M1.00004 5H9.33337M9.33337 5L5.33337 1M9.33337 5L5.33337 9"
                                                        stroke="url(#paint0_linear_3112_14890)"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <defs>
                                                        <linearGradient
                                                            id="paint0_linear_3112_14890"
                                                            x1="9.46358"
                                                            y1="-1.5"
                                                            x2="5.46163"
                                                            y2="9.11123"
                                                            gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#ACE348">
                                                                <stop
                                                                    offset={1}
                                                                    stopColor="#25ACA0"
                                                                />
                                                            </stop>
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </a>
                                <div className="max-w-lg sm:max-w-xl lg:max-w-none mx-auto">
                                    <h1 className="font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-bold text-white mb-6">
                                        Your marketing assets, at your
                                        fingertips.
                                    </h1>
                                    <p className="max-w-lg mx-auto text-lg text-gray-300 mb-10">
                                        Empowers marketing team to easily store,
                                        organize, and access valuable assets
                                        from anywhere.
                                    </p>
                                    <a
                                        className="group relative flex xs:inline-flex items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300"
                                        href="#">
                                        <div className="absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-600 animate-pulse group-hover:ring-0 transition duration-300" />
                                        <span className="mr-3">
                                            Request Access
                                        </span>
                                        <span className="transform group-hover:translate-x-1 transition duration-100">
                                            <svg
                                                width={7}
                                                height={12}
                                                viewBox="0 0 7 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M1 1L6 6L1 11"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <img
                                className="block mx-auto"
                                src="aurora-assets/headers/header-dark-theme-center.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50">
                        <div className="navbar-backdrop fixed inset-0 bg-teal-800 opacity-70" />
                        <nav className="relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto">
                            <div className="flex mb-auto items-center">
                                <a className="inline-block mr-auto" href="#">
                                    <img
                                        className="h-10"
                                        src="aurora-assets/logos/aurora-logo.svg"
                                        alt=""
                                    />
                                </a>
                                <a className="navbar-close" href="#">
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6 18L18 6M6 6L18 18"
                                            stroke="#111827"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </a>
                            </div>
                            <div className="py-12 mb-auto">
                                <ul className="flex-col">
                                    <li className="mb-6">
                                        <a
                                            className="inline-block text-black"
                                            href="#">
                                            Product
                                        </a>
                                    </li>
                                    <li className="mb-6">
                                        <a
                                            className="inline-block text-black"
                                            href="#">
                                            Features
                                        </a>
                                    </li>
                                    <li className="mb-6">
                                        <a
                                            className="inline-block text-black"
                                            href="#">
                                            Pricing
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="inline-block text-black"
                                            href="#">
                                            Resources
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <a
                                    className="flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200"
                                    href="#">
                                    Login
                                </a>
                                <a
                                    className="flex items-center justify-center h-12 px-4 text-center text-base text-white font-semibold bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg transition duration-200"
                                    href="#">
                                    Get Started
                                </a>
                            </div>
                        </nav>
                    </div>
                </section>
                <section className="relative py-12 md:py-24 bg-teal-900">
                    <img
                        className="absolute bottom-0 right-0 opacity-30"
                        src="aurora-assets/features/light-right-shadow.png"
                        alt=""
                    />
                    <img
                        className="absolute top-0 right-0"
                        src="aurora-assets/features/stars-green-bg.svg"
                        alt=""
                    />
                    <div className="relative container px-4 mx-auto">
                        <div className="flex flex-wrap -mx-4">
                            <div className="w-full lg:w-5/12 px-4 mb-20 lg:mb-0">
                                <div className="max-w-xs mb-10">
                                    <span className="inline-flex items-center h-6 mb-4 px-2 text-xs uppercase font-medium text-gray-50 bg-teal-800 rounded-full">
                                        Feature
                                    </span>
                                    <h1 className="font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4">
                                        Easily organize your files
                                    </h1>
                                    <p className="text-gray-400">
                                        Increase your marketing team efficiency
                                        with a quick search and ease of use
                                        features.
                                    </p>
                                </div>
                                <a
                                    className="xs:flex-shrink-0 group relative w-full xs:w-auto h-12 flex xs:inline-flex items-center justify-center px-5 font-bold text-gray-700 bg-white border border-gray-200 shadow-md hover:shadow-none rounded-lg transition-all duration-300 focus:outline-none"
                                    href="#">
                                    <div className="absolute top-0 left-0 w-full h-full rounded-lg ring ring-gray-100 animate-pulse group-hover:ring-0 transition duration-300" />
                                    <span className="mr-2">Learn more</span>
                                    <span className="transform group-hover:translate-x-1 transition duration-300">
                                        <svg
                                            width={20}
                                            height={20}
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.5 5L12.5 10L7.5 15"
                                                stroke="#3B5444"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </span>
                                </a>
                            </div>
                            <div className="w-full lg:w-7/12 px-4">
                                <div>
                                    <div className="flex items-center">
                                        <span className="mr-6 text-sm font-medium text-gray-400">
                                            Support
                                        </span>
                                        <ul className="flex flex-wrap -mb-2 items-center">
                                            <li className="mb-2 mr-2">
                                                <a
                                                    className="inline-flex items-center h-8 px-4 border border-teal-800 hover:border-yellowGreen-600 rounded-full"
                                                    href="#">
                                                    <svg
                                                        width={16}
                                                        height={16}
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M14 2.6V13.4C14 13.7314 13.7314 14 13.4 14H2.6C2.26863 14 2 13.7314 2 13.4V2.6C2 2.26863 2.26863 2 2.6 2H13.4C13.7314 2 14 2.26863 14 2.6Z"
                                                            stroke="url(#paint0_linear_3021_28405)"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M2 10.6667L6.66667 8.66666L14 12"
                                                            stroke="url(#paint1_linear_3021_28405)"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M10.6667 6.66667C9.93029 6.66667 9.33333 6.06971 9.33333 5.33333C9.33333 4.59695 9.93029 4 10.6667 4C11.403 4 12 4.59695 12 5.33333C12 6.06971 11.403 6.66667 10.6667 6.66667Z"
                                                            stroke="url(#paint2_linear_3021_28405)"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <defs>
                                                            <linearGradient
                                                                id="paint0_linear_3021_28405"
                                                                x1="14.1875"
                                                                y1="17.75"
                                                                x2={8}
                                                                y2={2}
                                                                gradientUnits="userSpaceOnUse">
                                                                <stop stopColor="#ACE348">
                                                                    <stop
                                                                        offset={
                                                                            1
                                                                        }
                                                                        stopColor="#25ACA0"
                                                                    />
                                                                </stop>
                                                            </linearGradient>
                                                            <linearGradient
                                                                id="paint1_linear_3021_28405"
                                                                x1="1.8125"
                                                                y1="7.62499"
                                                                x2="2.35713"
                                                                y2="12.6158"
                                                                gradientUnits="userSpaceOnUse">
                                                                <stop stopColor="#ACE348">
                                                                    <stop
                                                                        offset={
                                                                            1
                                                                        }
                                                                        stopColor="#25ACA0"
                                                                    />
                                                                </stop>
                                                            </linearGradient>
                                                            <linearGradient
                                                                id="paint2_linear_3021_28405"
                                                                x1="12.0417"
                                                                y1="3.16667"
                                                                x2="10.6667"
                                                                y2="6.66667"
                                                                gradientUnits="userSpaceOnUse">
                                                                <stop stopColor="#ACE348">
                                                                    <stop
                                                                        offset={
                                                                            1
                                                                        }
                                                                        stopColor="#25ACA0"
                                                                    />
                                                                </stop>
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                    <span className="ml-2 text-xs text-white font-semibold">
                                                        Photo
                                                    </span>
                                                </a>
                                            </li>
                                            <li className="mb-2 mr-2">
                                                <a
                                                    className="inline-flex items-center h-8 px-4 border border-teal-800 hover:border-yellowGreen-600 rounded-full"
                                                    href="#">
                                                    <svg
                                                        width={16}
                                                        height={16}
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M14 2.6V13.4C14 13.7314 13.7314 14 13.4 14H2.6C2.26863 14 2 13.7314 2 13.4V2.6C2 2.26863 2.26863 2 2.6 2H13.4C13.7314 2 14 2.26863 14 2.6Z"
                                                            stroke="url(#paint0_linear_3021_28409)"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M6.89768 5.84631C6.49769 5.61774 6 5.90656 6 6.36725V9.63277C6 10.0935 6.49769 10.3823 6.89768 10.1537L9.75501 8.52096C10.1581 8.29062 10.1581 7.7094 9.75501 7.47906L6.89768 5.84631Z"
                                                            stroke="url(#paint1_linear_3021_28409)"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <defs>
                                                            <linearGradient
                                                                id="paint0_linear_3021_28409"
                                                                x1="14.1875"
                                                                y1="17.75"
                                                                x2={8}
                                                                y2={2}
                                                                gradientUnits="userSpaceOnUse">
                                                                <stop stopColor="#ACE348">
                                                                    <stop
                                                                        offset={
                                                                            1
                                                                        }
                                                                        stopColor="#25ACA0"
                                                                    />
                                                                </stop>
                                                            </linearGradient>
                                                            <linearGradient
                                                                id="paint1_linear_3021_28409"
                                                                x1="5.92708"
                                                                y1="3.66668"
                                                                x2="8.94636"
                                                                y2="10.3914"
                                                                gradientUnits="userSpaceOnUse">
                                                                <stop stopColor="#ACE348">
                                                                    <stop
                                                                        offset={
                                                                            1
                                                                        }
                                                                        stopColor="#25ACA0"
                                                                    />
                                                                </stop>
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                    <span className="ml-2 text-xs text-white font-semibold">
                                                        Video
                                                    </span>
                                                </a>
                                            </li>
                                            <li className="mb-2 mr-2">
                                                <a
                                                    className="inline-flex items-center h-8 px-4 border border-teal-800 hover:border-yellowGreen-600 rounded-full"
                                                    href="#">
                                                    <svg
                                                        width={16}
                                                        height={16}
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12 2L4 2C2.89543 2 2 2.89543 2 4L2 12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V4C14 2.89543 13.1046 2 12 2Z"
                                                            stroke="url(#paint0_linear_3021_28415)"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M4.66699 4.66666L11.3337 4.66666"
                                                            stroke="url(#paint1_linear_3021_28415)"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M4.66699 8L11.3337 8"
                                                            stroke="url(#paint2_linear_3021_28415)"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M4.66699 11.3333L8.66699 11.3333"
                                                            stroke="url(#paint3_linear_3021_28415)"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <defs>
                                                            <linearGradient
                                                                id="paint0_linear_3021_28415"
                                                                x1="-1.75"
                                                                y1="1.8125"
                                                                x2={14}
                                                                y2={8}
                                                                gradientUnits="userSpaceOnUse">
                                                                <stop stopColor="#ACE348">
                                                                    <stop
                                                                        offset={
                                                                            1
                                                                        }
                                                                        stopColor="#25ACA0"
                                                                    />
                                                                </stop>
                                                            </linearGradient>
                                                            <linearGradient
                                                                id="paint1_linear_3021_28415"
                                                                x1="2.58366"
                                                                y1="4.68228"
                                                                x2="3.8688"
                                                                y2="1.31644"
                                                                gradientUnits="userSpaceOnUse">
                                                                <stop stopColor="#ACE348">
                                                                    <stop
                                                                        offset={
                                                                            1
                                                                        }
                                                                        stopColor="#25ACA0"
                                                                    />
                                                                </stop>
                                                            </linearGradient>
                                                            <linearGradient
                                                                id="paint2_linear_3021_28415"
                                                                x1="2.58366"
                                                                y1="8.01562"
                                                                x2="3.8688"
                                                                y2="4.64978"
                                                                gradientUnits="userSpaceOnUse">
                                                                <stop stopColor="#ACE348">
                                                                    <stop
                                                                        offset={
                                                                            1
                                                                        }
                                                                        stopColor="#25ACA0"
                                                                    />
                                                                </stop>
                                                            </linearGradient>
                                                            <linearGradient
                                                                id="paint3_linear_3021_28415"
                                                                x1="3.41699"
                                                                y1="11.349"
                                                                x2="5.16378"
                                                                y2="8.60402"
                                                                gradientUnits="userSpaceOnUse">
                                                                <stop stopColor="#ACE348">
                                                                    <stop
                                                                        offset={
                                                                            1
                                                                        }
                                                                        stopColor="#25ACA0"
                                                                    />
                                                                </stop>
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                    <span className="ml-2 text-xs text-white font-semibold">
                                                        File
                                                    </span>
                                                </a>
                                            </li>
                                            <li className="mb-2 mr-2">
                                                <a
                                                    className="inline-flex items-center h-8 px-4 border border-teal-800 hover:border-yellowGreen-600 rounded-full"
                                                    href="#">
                                                    <svg
                                                        width={16}
                                                        height={16}
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.66699 4V2.6C2.66699 2.26863 2.93562 2 3.26699 2H12.7337C13.065 2 13.3337 2.26863 13.3337 2.6V4"
                                                            stroke="url(#paint0_linear_3021_28422)"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                        <path
                                                            d="M2.66699 12V13.4C2.66699 13.7314 2.93562 14 3.26699 14H12.7337C13.065 14 13.3337 13.7314 13.3337 13.4V12"
                                                            stroke="url(#paint1_linear_3021_28422)"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                        <path
                                                            d="M10.333 10V6H12.333"
                                                            stroke="url(#paint2_linear_3021_28422)"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M10.333 8H11.6663"
                                                            stroke="url(#paint3_linear_3021_28422)"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M8 10L8 6"
                                                            stroke="url(#paint4_linear_3021_28422)"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M5.66699 6H3.66699L3.66699 10L5.66699 10V8.4"
                                                            stroke="url(#paint5_linear_3021_28422)"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <defs>
                                                            <linearGradient
                                                                id="paint0_linear_3021_28422"
                                                                x1="2.50033"
                                                                y1="1.375"
                                                                x2="2.72232"
                                                                y2="4.38878"
                                                                gradientUnits="userSpaceOnUse">
                                                                <stop stopColor="#ACE348">
                                                                    <stop
                                                                        offset={
                                                                            1
                                                                        }
                                                                        stopColor="#25ACA0"
                                                                    />
                                                                </stop>
                                                            </linearGradient>
                                                            <linearGradient
                                                                id="paint1_linear_3021_28422"
                                                                x1="2.50033"
                                                                y1="14.625"
                                                                x2="2.72232"
                                                                y2="11.6112"
                                                                gradientUnits="userSpaceOnUse">
                                                                <stop stopColor="#ACE348">
                                                                    <stop
                                                                        offset={
                                                                            1
                                                                        }
                                                                        stopColor="#25ACA0"
                                                                    />
                                                                </stop>
                                                            </linearGradient>
                                                            <linearGradient
                                                                id="paint2_linear_3021_28422"
                                                                x1="9.70801"
                                                                y1="10.0625"
                                                                x2="12.6256"
                                                                y2="9.48941"
                                                                gradientUnits="userSpaceOnUse">
                                                                <stop stopColor="#ACE348">
                                                                    <stop
                                                                        offset={
                                                                            1
                                                                        }
                                                                        stopColor="#25ACA0"
                                                                    />
                                                                </stop>
                                                            </linearGradient>
                                                            <linearGradient
                                                                id="paint3_linear_3021_28422"
                                                                x1="9.91634"
                                                                y1="8.01562"
                                                                x2="11.5015"
                                                                y2="7.1853"
                                                                gradientUnits="userSpaceOnUse">
                                                                <stop stopColor="#ACE348">
                                                                    <stop
                                                                        offset={
                                                                            1
                                                                        }
                                                                        stopColor="#25ACA0"
                                                                    />
                                                                </stop>
                                                            </linearGradient>
                                                            <linearGradient
                                                                id="paint4_linear_3021_28422"
                                                                x1="7.6875"
                                                                y1="10.0625"
                                                                x2="9.18809"
                                                                y2="9.91512"
                                                                gradientUnits="userSpaceOnUse">
                                                                <stop stopColor="#ACE348">
                                                                    <stop
                                                                        offset={
                                                                            1
                                                                        }
                                                                        stopColor="#25ACA0"
                                                                    />
                                                                </stop>
                                                            </linearGradient>
                                                            <linearGradient
                                                                id="paint5_linear_3021_28422"
                                                                x1="3.04199"
                                                                y1="10.0625"
                                                                x2="5.95955"
                                                                y2="9.48941"
                                                                gradientUnits="userSpaceOnUse">
                                                                <stop stopColor="#ACE348">
                                                                    <stop
                                                                        offset={
                                                                            1
                                                                        }
                                                                        stopColor="#25ACA0"
                                                                    />
                                                                </stop>
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                    <span className="ml-2 text-xs text-white font-semibold">
                                                        GIF
                                                    </span>
                                                </a>
                                            </li>
                                            <li className="mb-2">
                                                <a
                                                    className="inline-flex items-center h-8 px-4 border border-teal-800 hover:border-yellowGreen-600 rounded-full"
                                                    href="#">
                                                    <svg
                                                        width={16}
                                                        height={16}
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M9 4L6.66667 12.3333M4.33333 5.66667L2 8L4.33333 10.3333M11.6667 5.66667L14 8L11.6667 10.3333"
                                                            stroke="url(#paint0_linear_3021_28440)"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <defs>
                                                            <linearGradient
                                                                id="paint0_linear_3021_28440"
                                                                x1="1.8125"
                                                                y1="1.39583"
                                                                x2="5.01836"
                                                                y2="13.1468"
                                                                gradientUnits="userSpaceOnUse">
                                                                <stop stopColor="#ACE348">
                                                                    <stop
                                                                        offset={
                                                                            1
                                                                        }
                                                                        stopColor="#25ACA0"
                                                                    />
                                                                </stop>
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                    <span className="ml-2 text-xs text-white font-semibold">
                                                        Code Snippets
                                                    </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <img
                                        className="block w-full"
                                        src="aurora-assets/features/image-feature-2.png"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-teal-900 pb-24">
                    <div className="hidden md:block relative h-16 px-8 bg-teal-900">
                        <img
                            className="absolute bottom-0 left-1/2 transform -translate-x-1/2"
                            src="aurora-assets/headers/shadow-bottom-green.png"
                            alt=""
                        />
                        <div className="relative flex h-full items-center justify-center">
                            <span className="text-sm lg:text-base text-white">
                                This announcement bar can be used to inform
                                visitors of something important
                            </span>
                            <div className="h-6 w-px mx-4 bg-white opacity-20" />
                            <a
                                className="inline-flex items-center font-semibold text-yellowGreen-500 hover:text-yellowGreen-800"
                                href="#">
                                <span className="mr-2">Read more</span>
                                <svg
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4.00016 8H12.3335M12.3335 8L8.3335 4M12.3335 8L8.3335 12"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <nav className="relative mb-12 md:mb-24">
                        <div className="container mx-auto px-4">
                            <div className="relative flex h-24 items-center">
                                <a className="inline-block" href="#">
                                    <img
                                        className="h-10"
                                        src="aurora-assets/logos/aurora-logo-white.svg"
                                        alt=""
                                    />
                                </a>
                                <button
                                    variant="classic"
                                    className="lg:hidden navbar-burger flex items-center justify-center h-10 w-10 ml-auto border border-teal-900 bg-teal-200 bg-opacity-30 hover:bg-teal-700 rounded-lg">
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3 5H21"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M3 12H21"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M3 19H21"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </button>
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden lg:flex items-center">
                                    <a
                                        className="inline-block text-white hover:text-teal-400 mr-10"
                                        href="#">
                                        <div className="flex items-center">
                                            <span className="mr-3">
                                                Product
                                            </span>
                                            <svg
                                                width={10}
                                                height={6}
                                                viewBox="0 0 10 6"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M1 1L5 5L9 1"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </a>
                                    <a
                                        className="inline-block text-white hover:text-teal-400 mr-10"
                                        href="#">
                                        Features
                                    </a>
                                    <a
                                        className="inline-block text-white hover:text-teal-400 mr-10"
                                        href="#">
                                        Pricing
                                    </a>
                                    <a
                                        className="inline-block text-white hover:text-teal-400"
                                        href="#">
                                        Resources
                                    </a>
                                </div>
                                <div className="hidden lg:block ml-auto">
                                    <a
                                        className="inline-flex items-center justify-center h-10 mr-4 px-4 text-center text-sm text-white hover:text-teal-400 font-semibold transition duration-200"
                                        href="#">
                                        Login
                                    </a>
                                    <a
                                        className="group inline-block justify-center p-1 text-center text-sm text-white font-semibold rounded-lg"
                                        href="#">
                                        <div className="inline-flex items-stretch h-10 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800">
                                            <div className="flex items-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300">
                                                <span>Get Started</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <div className="relative">
                        <img
                            className="hidden lg:block absolute top-0 right-0 w-full max-w-lg xl:max-w-xl"
                            src="aurora-assets/headers/header-5-right.png"
                            alt=""
                        />
                        <div className="container px-4 mx-auto">
                            <div className="max-w-sm sm:max-w-lg xl:max-w-2xl mx-auto lg:mx-0 lg:pt-10 lg:pr-10 pb-12 lg:pb-28">
                                <h1 className="font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-bold text-white mb-6">
                                    Your marketing assets at your fingertips
                                </h1>
                                <p className="max-w-sm xl:max-w-none text-lg text-gray-200 mb-10">
                                    Easily store, organize, and access valuable
                                    assets from anywhere.
                                </p>
                                <div className="flex flex-col xs:flex-row items-center mb-10">
                                    <a
                                        className="group relative mb-4 xs:mb-0 xs:mr-8 flex w-full xs:w-auto items-center justify-center h-12 px-4 text-center text-base text-teal-800 font-bold bg-white rounded-lg transition duration-300"
                                        href="#">
                                        <div className="absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-700 animate-pulse group-hover:ring-0 transition duration-300" />
                                        <span>Try Aurora for Free</span>
                                    </a>
                                    <a
                                        className="group inline-flex items-center font-bold text-white hover:text-teal-400 transition duration-300"
                                        href="#">
                                        <span className="mr-2">
                                            Request a Demo
                                        </span>
                                        <span>
                                            <svg
                                                width={20}
                                                height={20}
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M7.5 5L12.5 10L7.5 15"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                                <div className="flex flex-col xs:flex-row items-center">
                                    <span className="block text-sm text-gray-400 opacity-50">
                                        30-days free trial
                                    </span>
                                    <span className="inline-block my-4 xs:my-0 mx-auto xs:mx-4">
                                        <svg
                                            width={12}
                                            height={12}
                                            viewBox="0 0 12 12"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_3008_28918)">
                                                <mask
                                                    id="mask0_3008_28918"
                                                    style={{
                                                        maskType: "luminance"
                                                    }}
                                                    maskUnits="userSpaceOnUse"
                                                    x={0}
                                                    y={0}
                                                    width={12}
                                                    height={12}>
                                                    <path
                                                        d="M12 0H0V12H12V0Z"
                                                        fill="white"
                                                    />
                                                </mask>
                                                <g mask="url(#mask0_3008_28918)">
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M0 6L4.57763e-07 0H6H12V6C8.6868 6 6.00078 3.3145 6 0.00143555C5.99922 3.3145 3.31322 6 0 6ZM6 12C6 8.68626 8.68632 6 12 6V12H6ZM6 12C6 8.68626 3.31371 6 0 6V12H6Z"
                                                        fill="#4B6354"
                                                    />
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                    <span className="block text-sm text-gray-400 opacity-50">
                                        No credit card required
                                    </span>
                                </div>
                            </div>
                        </div>
                        <img
                            className="lg:hidden block w-full pl-8 ml-auto max-w-3xl"
                            src="aurora-assets/headers/header-5-right.png"
                            alt=""
                        />
                    </div>
                    <div className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50">
                        <div className="navbar-backdrop fixed inset-0 bg-teal-800 opacity-70" />
                        <nav className="relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto">
                            <div className="flex mb-auto items-center">
                                <a className="inline-block mr-auto" href="#">
                                    <img
                                        className="h-10"
                                        src="aurora-assets/logos/aurora-logo.svg"
                                        alt=""
                                    />
                                </a>
                                <a className="navbar-close" href="#">
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6 18L18 6M6 6L18 18"
                                            stroke="#111827"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </a>
                            </div>
                            <div className="py-12 mb-auto">
                                <ul className="flex-col">
                                    <li className="mb-6">
                                        <a
                                            className="inline-block text-black"
                                            href="#">
                                            Product
                                        </a>
                                    </li>
                                    <li className="mb-6">
                                        <a
                                            className="inline-block text-black"
                                            href="#">
                                            Features
                                        </a>
                                    </li>
                                    <li className="mb-6">
                                        <a
                                            className="inline-block text-black"
                                            href="#">
                                            Pricing
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="inline-block text-black"
                                            href="#">
                                            Resources
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <a
                                    className="flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200"
                                    href="#">
                                    Login
                                </a>
                                <a
                                    className="flex items-center justify-center h-12 px-4 text-center text-base text-white font-semibold bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg transition duration-200"
                                    href="#">
                                    Get Started
                                </a>
                            </div>
                        </nav>
                    </div>
                </section>
            </>
            <FooterComponent />
        </React.Fragment>
    )
}
