import React from 'react';

import { Flex, Text, Button } from '@radix-ui/themes';


import {FooterComponent} from './Footers.jsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Faq() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-7xl mx-auto'>
              <div className='max-w-2xl mx-auto mb-20 text-center'>
                <span className='inline-flex items-center h-6 mb-6 px-2 text-xs uppercase font-medium text-gray-700 bg-gray-100 rounded-full'>
                  TESTIMONIALS
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold'>
                  Aurora is used by hundreds of companies and happy users
                </h1>
              </div>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/3 px-4 mb-10 lg:mb-0'>
                  <div className='flex flex-col max-w-sm mx-auto lg:max-w-none items-start h-full p-8 bg-gray-50 border border-gray-200 rounded-xl'>
                    <img
                      className='block h-8 mb-8'
                      src='aurora-assets/logo-clouds/logo-ipsum-6.svg'
                      alt=''
                    />
                    <div className='pr-4'>
                      <p className='text-lg font-medium mb-20'>
                        “We use Aurora daily and we’re very happy that they are
                        created this amazing bookmarking platform.”
                      </p>
                      <div className='flex items-center mt-auto'>
                        <img
                          className='block h-12 w-12 rounded-full'
                          src='aurora-assets/blog/avatar-medium.png'
                          alt=''
                        />
                        <div className='pl-4'>
                          <span className='block font-semibold'>
                            Ethan Rauf
                          </span>
                          <span className='text-sm text-gray-500'>
                            Founder of AIME Inc.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4 mb-10 lg:mb-0'>
                  <div className='flex flex-col max-w-sm mx-auto lg:max-w-none items-start h-full p-8 bg-gray-50 border border-gray-200 rounded-xl'>
                    <img
                      className='block h-8 mb-8'
                      src='aurora-assets/logo-clouds/logo-ipsum-1.svg'
                      alt=''
                    />
                    <div className='pr-4'>
                      <p className='text-lg font-medium mb-20'>
                        “We use Aurora daily and we’re very happy that they are
                        created this amazing bookmarking platform.”
                      </p>
                      <div className='flex items-center mt-auto'>
                        <img
                          className='block h-12 w-12 rounded-full'
                          src='aurora-assets/blog/avatar-medium.png'
                          alt=''
                        />
                        <div className='pl-4'>
                          <span className='block font-semibold'>
                            Hanna Stanton
                          </span>
                          <span className='text-sm text-gray-500'>
                            Founder of AIME Inc.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4'>
                  <div className='flex flex-col max-w-sm mx-auto lg:max-w-none items-start h-full p-8 bg-gray-50 border border-gray-200 rounded-xl'>
                    <img
                      className='block h-8 mb-8'
                      src='aurora-assets/logo-clouds/logo-ipsum-2.svg'
                      alt=''
                    />
                    <div className='pr-4'>
                      <p className='text-lg font-medium mb-20'>
                        “We use Aurora daily and we’re very happy that they are
                        created this amazing bookmarking platform.”
                      </p>
                      <div className='flex items-center mt-auto'>
                        <img
                          className='block h-12 w-12 rounded-full'
                          src='aurora-assets/blog/avatar-medium.png'
                          alt=''
                        />
                        <div className='pl-4'>
                          <span className='block font-semibold'>
                            Cooper Levin
                          </span>
                          <span className='text-sm text-gray-500'>
                            Founder of AIME Inc.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='text-center mb-20'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold text-white'>
                Frequently asked questions
              </h1>
            </div>
            <div className='max-w-6xl mx-auto'>
              <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-teal-800 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                <div className='pr-4'>
                  <h6 className='font-semibold text-white'>What is Aurora?</h6>
                  <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
                <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-teal-700 rounded-full'>
                  <svg
                    className='group-hover:hidden'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 6L8 10L12 6'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <svg
                    className='hidden group-hover:block'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 10L8 6L12 10'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </button>
              <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-teal-800 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                <div className='pr-4'>
                  <h6 className='font-semibold text-white'>
                    How does it work?
                  </h6>
                  <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
                <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-teal-700 rounded-full'>
                  <svg
                    className='group-hover:hidden'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 6L8 10L12 6'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <svg
                    className='hidden group-hover:block'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 10L8 6L12 10'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </button>
              <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-teal-800 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                <div className='pr-4'>
                  <h6 className='font-semibold text-white'>
                    How much does it cost?
                  </h6>
                  <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
                <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-teal-700 rounded-full'>
                  <svg
                    className='group-hover:hidden'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 6L8 10L12 6'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <svg
                    className='hidden group-hover:block'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 10L8 6L12 10'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </button>
              <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-teal-800 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                <div className='pr-4'>
                  <h6 className='font-semibold text-white'>
                    Is there a free trial available?
                  </h6>
                  <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
                <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-teal-700 rounded-full'>
                  <svg
                    className='group-hover:hidden'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 6L8 10L12 6'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <svg
                    className='hidden group-hover:block'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 10L8 6L12 10'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </button>
              <button variant="classic" className='group flex items-center justify-between text-left w-full px-6 py-5 bg-teal-800 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                <div className='pr-4'>
                  <h6 className='font-semibold text-white'>
                    How to export my assets?
                  </h6>
                  <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
                <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-teal-700 rounded-full'>
                  <svg
                    className='group-hover:hidden'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 6L8 10L12 6'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <svg
                    className='hidden group-hover:block'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 10L8 6L12 10'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </section>
        <section className='relative pt-12 pb-12 lg:pb-0 md:pt-24 bg-gray-50'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-1/2 px-4 mb-20 lg:mb-0 lg:pb-64'>
                <div className='max-w-sm'>
                  <span className='inline-flex items-center h-6 mb-6 px-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-full'>
                    FAQ
                  </span>
                  <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold mb-10'>
                    Frequently asked questions
                  </h1>
                  <a
                    className='xs:flex-shrink-0 group relative w-full xs:w-auto h-10 flex xs:inline-flex items-center justify-center px-4 p-px font-bold text-white bg-gray-900 rounded-lg transition-all duration-300 focus:outline-none'
                    href='#'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-gray-500 animate-pulse group-hover:ring-0 transition duration-300' />
                    <svg
                      width={16}
                      height={16}
                      viewBox='0 0 16 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M13.3332 7.33333C13.3332 4.38781 10.9454 2 7.99984 2C5.05432 2 2.6665 4.38781 2.6665 7.33333'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M1.3335 9.77178V9.56155C1.3335 8.64381 1.95809 7.84385 2.84843 7.62126L3.25464 7.51971C3.63333 7.42504 4.00016 7.71145 4.00016 8.10179V11.2315C4.00016 11.6219 3.63333 11.9083 3.25464 11.8136L2.84842 11.7121C1.95809 11.4895 1.3335 10.6895 1.3335 9.77178Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                      <path
                        d='M14.6667 9.77178V9.56155C14.6667 8.64381 14.0421 7.84385 13.1517 7.62126L12.7455 7.51971C12.3668 7.42504 12 7.71145 12 8.10179V11.2315C12 11.6219 12.3668 11.9083 12.7455 11.8136L13.1517 11.7121C14.0421 11.4895 14.6667 10.6895 14.6667 9.77178Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                      <path
                        d='M13.3332 12V12C13.3332 12.9205 12.587 13.6667 11.6665 13.6667H9.6665'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                      <path
                        d='M9 14.6667H7C6.44772 14.6667 6 14.219 6 13.6667C6 13.1144 6.44772 12.6667 7 12.6667H9C9.55228 12.6667 10 13.1144 10 13.6667C10 14.219 9.55228 14.6667 9 14.6667Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                    </svg>
                    <span className='ml-2'>Talk to us</span>
                  </a>
                </div>
              </div>
              <div className='w-full lg:w-1/2 px-4'>
                <div>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white rounded-xl shadow-sm border border-gray-200 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold'>What is Aurora?</h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-500'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-gray-100 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white rounded-xl shadow-sm border border-gray-200 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold'>How does it work?</h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-500'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-gray-100 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white rounded-xl shadow-sm border border-gray-200 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold'>How much does it cost?</h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-500'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-gray-100 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white rounded-xl shadow-sm border border-gray-200 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold'>
                        Is there a free trial available?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-500'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-gray-100 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex items-center justify-between text-left w-full px-6 py-5 bg-white rounded-xl shadow-sm border border-gray-200 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold'>
                        How to export my assets?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-500'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-gray-100 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='text-center mb-20'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold text-white'>
                Frequently asked questions
              </h1>
            </div>
            <div className='max-w-6xl mx-auto'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full md:w-1/2 px-4 mb-3 md:mb-0'>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        What is Aurora?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How does it work?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How much does it cost?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        Is there a free trial available?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How to export my assets?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                </div>
                <div className='w-full md:w-1/2 px-4'>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        What is Aurora?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How does it work?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How much does it cost?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        Is there a free trial available?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How to export my assets?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='text-center mb-20'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold text-white'>
                Frequently asked questions
              </h1>
            </div>
            <div className='max-w-6xl mx-auto'>
              <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl focus:outline-none'>
                <div className='pr-4'>
                  <h6 className='font-semibold text-white'>What is Aurora?</h6>
                  <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
                <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                  <svg
                    className='group-hover:hidden'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 6L8 10L12 6'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <svg
                    className='hidden group-hover:block'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 10L8 6L12 10'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </button>
              <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl focus:outline-none'>
                <div className='pr-4'>
                  <h6 className='font-semibold text-white'>
                    How does it work?
                  </h6>
                  <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
                <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                  <svg
                    className='group-hover:hidden'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 6L8 10L12 6'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <svg
                    className='hidden group-hover:block'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 10L8 6L12 10'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </button>
              <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl focus:outline-none'>
                <div className='pr-4'>
                  <h6 className='font-semibold text-white'>
                    How much does it cost?
                  </h6>
                  <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
                <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                  <svg
                    className='group-hover:hidden'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 6L8 10L12 6'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <svg
                    className='hidden group-hover:block'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 10L8 6L12 10'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </button>
              <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl focus:outline-none'>
                <div className='pr-4'>
                  <h6 className='font-semibold text-white'>
                    Is there a free trial available?
                  </h6>
                  <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
                <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                  <svg
                    className='group-hover:hidden'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 6L8 10L12 6'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <svg
                    className='hidden group-hover:block'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 10L8 6L12 10'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </button>
              <button variant="classic" className='group flex items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl focus:outline-none'>
                <div className='pr-4'>
                  <h6 className='font-semibold text-white'>
                    How to export my assets?
                  </h6>
                  <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
                <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                  <svg
                    className='group-hover:hidden'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 6L8 10L12 6'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <svg
                    className='hidden group-hover:block'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 10L8 6L12 10'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </section>
        <section className='relative pt-12 pb-12 lg:pb-0 md:pt-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-1/2 px-4 mb-20 lg:mb-0 lg:pb-64'>
                <div className='max-w-sm'>
                  <span className='inline-flex items-center h-6 mb-6 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                    FAQ
                  </span>
                  <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold text-white mb-10'>
                    Frequently asked questions
                  </h1>
                  <a
                    className='xs:flex-shrink-0 group relative h-10 inline-flex w-full xs:w-auto items-center justify-center px-4 text-center text-sm text-teal-800 font-bold bg-white rounded-lg transition duration-300 focus:outline-none'
                    href='#'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-600 animate-pulse group-hover:ring-0 transition duration-300' />
                    <svg
                      width={16}
                      height={16}
                      viewBox='0 0 16 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M13.3332 7.33333C13.3332 4.38781 10.9454 2 7.99984 2C5.05432 2 2.6665 4.38781 2.6665 7.33333'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M1.3335 9.77178V9.56155C1.3335 8.64381 1.95809 7.84385 2.84843 7.62126L3.25464 7.51971C3.63333 7.42504 4.00016 7.71145 4.00016 8.10179V11.2315C4.00016 11.6219 3.63333 11.9083 3.25464 11.8136L2.84842 11.7121C1.95809 11.4895 1.3335 10.6895 1.3335 9.77178Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                      <path
                        d='M14.6667 9.77178V9.56155C14.6667 8.64381 14.0421 7.84385 13.1517 7.62126L12.7455 7.51971C12.3668 7.42504 12 7.71145 12 8.10179V11.2315C12 11.6219 12.3668 11.9083 12.7455 11.8136L13.1517 11.7121C14.0421 11.4895 14.6667 10.6895 14.6667 9.77178Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                      <path
                        d='M13.3332 12V12C13.3332 12.9205 12.587 13.6667 11.6665 13.6667H9.6665'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                      <path
                        d='M9 14.6667H7C6.44772 14.6667 6 14.219 6 13.6667C6 13.1144 6.44772 12.6667 7 12.6667H9C9.55228 12.6667 10 13.1144 10 13.6667C10 14.219 9.55228 14.6667 9 14.6667Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                    </svg>
                    <span className='ml-2'>Talk to us</span>
                  </a>
                </div>
              </div>
              <div className='w-full lg:w-1/2 px-4'>
                <div>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        What is Aurora?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How does it work?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How much does it cost?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        Is there a free trial available?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How to export my assets?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative pt-12 pb-12 lg:pb-0 md:pt-24'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-1/2 px-4 mb-20 lg:mb-0 lg:pb-64'>
                <div className='max-w-sm'>
                  <span className='inline-flex items-center h-6 mb-6 px-2 text-sm font-medium text-yellowGreen-700 bg-yellowGreen-200 rounded-full'>
                    FAQ
                  </span>
                  <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold mb-10'>
                    Frequently asked questions
                  </h1>
                  <a
                    className='group relative block xs:inline-block p-px text-sm font-semibold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                    href='#'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                    <div className='relative flex items-center justify-center px-4 h-10 bg-gradient-to-br from-cyanGreen-800 to-cyan-800 group-hover:text-green-200 rounded-lg transition duration-300'>
                      <svg
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M13.3332 7.33333C13.3332 4.38781 10.9454 2 7.99984 2C5.05432 2 2.6665 4.38781 2.6665 7.33333'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M1.3335 9.77178V9.56155C1.3335 8.64381 1.95809 7.84385 2.84843 7.62126L3.25464 7.51971C3.63333 7.42504 4.00016 7.71145 4.00016 8.10179V11.2315C4.00016 11.6219 3.63333 11.9083 3.25464 11.8136L2.84842 11.7121C1.95809 11.4895 1.3335 10.6895 1.3335 9.77178Z'
                          stroke='currentColor'
                          strokeWidth='1.5'
                        />
                        <path
                          d='M14.6667 9.77178V9.56155C14.6667 8.64381 14.0421 7.84385 13.1517 7.62126L12.7455 7.51971C12.3668 7.42504 12 7.71145 12 8.10179V11.2315C12 11.6219 12.3668 11.9083 12.7455 11.8136L13.1517 11.7121C14.0421 11.4895 14.6667 10.6895 14.6667 9.77178Z'
                          stroke='currentColor'
                          strokeWidth='1.5'
                        />
                        <path
                          d='M13.3332 12V12C13.3332 12.9205 12.587 13.6667 11.6665 13.6667H9.6665'
                          stroke='currentColor'
                          strokeWidth='1.5'
                        />
                        <path
                          d='M9 14.6667H7C6.44772 14.6667 6 14.219 6 13.6667C6 13.1144 6.44772 12.6667 7 12.6667H9C9.55228 12.6667 10 13.1144 10 13.6667C10 14.219 9.55228 14.6667 9 14.6667Z'
                          stroke='currentColor'
                          strokeWidth='1.5'
                        />
                      </svg>
                      <span className='ml-2'>Talk to us</span>
                    </div>
                  </a>
                </div>
              </div>
              <div className='w-full lg:w-1/2 px-4'>
                <div>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 rounded-xl shadow-sm border border-gray-100 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold'>What is Aurora?</h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-500'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-gray-100 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 rounded-xl shadow-sm border border-gray-100 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold'>How does it work?</h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-500'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-gray-100 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 rounded-xl shadow-sm border border-gray-100 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold'>How much does it cost?</h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-500'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-gray-100 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 rounded-xl shadow-sm border border-gray-100 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold'>
                        Is there a free trial available?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-500'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-gray-100 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex items-center justify-between text-left w-full px-6 py-5 rounded-xl shadow-sm border border-gray-100 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold'>
                        How to export my assets?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-500'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-gray-100 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='text-center mb-20'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold text-white mb-6'>
                Frequently asked questions
              </h1>
              <p className='text-gray-400'>
                Everything you need to know about the product.
              </p>
            </div>
            <div className='flex flex-wrap -mx-4 lg:-mx-12 -mb-16'>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    What is Aurora?
                  </h6>
                  <p className='text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    How does it work?
                  </h6>
                  <p className='text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    How much does it cost?
                  </h6>
                  <p className='text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    How to export my assets?
                  </h6>
                  <p className='text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    How do I invite my team?
                  </h6>
                  <p className='text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    Is there a free trial available?
                  </h6>
                  <p className='text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
            </div>
            <div className='mt-16 py-4 px-6 bg-teal-800 rounded-xl'>
              <div className='sm:flex items-center'>
                <div className='xs:flex mb-6 md:mb-0 items-center'>
                  <div className='flex-shrink-0 flex items-center'>
                    <div className='flex items-center justify-center w-14 h-14 bg-yellowGreen-200 rounded-full'>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8 10L12 10L16 10'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M8 14L10 14L12 14'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.8214 2.48697 15.5291 3.33782 17L2.5 21.5L7 20.6622C8.47087 21.513 10.1786 22 12 22Z'
                          stroke='#041109'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                    <div className='-ml-4 inline-flex items-center justify-center p-1 bg-teal-800 rounded-full'>
                      <img
                        className='block w-14 h-14'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='mt-6 md:mt-0 xs:ml-6'>
                    <h6 className='font-semibold text-white mb-1'>
                      Still have questions?
                    </h6>
                    <p className='text-sm text-gray-400'>
                      Can’t find the answer you’re looking for? Please chat to
                      our team.
                    </p>
                  </div>
                </div>
                <div className='flex-shrink-0 ml-auto sm:pl-4 text-right'>
                  <a
                    className='group inline-block justify-center p-1 text-center text-base text-white font-semibold rounded-lg'
                    href='#'
                  >
                    <div className='inline-flex items-stretch h-12 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                      <div className='flex items-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                        <span className='mr-2'>Get in touch</span>
                        <span className='transform group-hover:translate-x-1 transition duration-200'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M5.00033 10H15.417M15.417 10L10.417 5M15.417 10L10.417 15'
                              stroke='white'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative pt-12 pb-12 lg:pb-0 md:pt-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-1/2 px-4 mb-20 lg:mb-0 lg:pb-64'>
                <div className='max-w-sm'>
                  <span className='inline-flex items-center h-6 mb-6 px-2 text-sm font-medium text-gray-50 bg-teal-800 rounded-full'>
                    FAQ
                  </span>
                  <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold text-white mb-10'>
                    Frequently asked questions
                  </h1>
                  <a
                    className='xs:flex-shrink-0 group relative w-full xs:w-auto flex xs:inline-flex items-center justify-center h-10 px-4 py-px font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
                    href='#'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                    <svg
                      width={16}
                      height={16}
                      viewBox='0 0 16 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M13.3332 7.33333C13.3332 4.38781 10.9454 2 7.99984 2C5.05432 2 2.6665 4.38781 2.6665 7.33333'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M1.3335 9.77178V9.56155C1.3335 8.64381 1.95809 7.84385 2.84843 7.62126L3.25464 7.51971C3.63333 7.42504 4.00016 7.71145 4.00016 8.10179V11.2315C4.00016 11.6219 3.63333 11.9083 3.25464 11.8136L2.84842 11.7121C1.95809 11.4895 1.3335 10.6895 1.3335 9.77178Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                      <path
                        d='M14.6667 9.77178V9.56155C14.6667 8.64381 14.0421 7.84385 13.1517 7.62126L12.7455 7.51971C12.3668 7.42504 12 7.71145 12 8.10179V11.2315C12 11.6219 12.3668 11.9083 12.7455 11.8136L13.1517 11.7121C14.0421 11.4895 14.6667 10.6895 14.6667 9.77178Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                      <path
                        d='M13.3332 12V12C13.3332 12.9205 12.587 13.6667 11.6665 13.6667H9.6665'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                      <path
                        d='M9 14.6667H7C6.44772 14.6667 6 14.219 6 13.6667C6 13.1144 6.44772 12.6667 7 12.6667H9C9.55228 12.6667 10 13.1144 10 13.6667C10 14.219 9.55228 14.6667 9 14.6667Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                    </svg>
                    <span className='ml-2'>Talk to us</span>
                  </a>
                </div>
              </div>
              <div className='w-full lg:w-1/2 px-4'>
                <div>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-teal-800 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        What is Aurora?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-teal-700 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-teal-800 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How does it work?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-teal-700 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-teal-800 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How much does it cost?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-teal-700 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-teal-800 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        Is there a free trial available?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-teal-700 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex items-center justify-between text-left w-full px-6 py-5 bg-teal-800 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How to export my assets?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-teal-700 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative pt-12 pb-12 lg:pb-0 md:pt-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-1/2 px-4 mb-20 lg:mb-0 lg:pb-64'>
                <div className='max-w-sm'>
                  <span className='inline-flex items-center h-6 mb-6 px-2 text-sm font-medium text-white bg-white bg-opacity-10 rounded-full'>
                    FAQ
                  </span>
                  <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold text-white mb-10'>
                    Frequently asked questions
                  </h1>
                  <a
                    className='xs:flex-shrink-0 group relative h-10 inline-flex w-full xs:w-auto items-center justify-center px-4 text-center text-sm text-teal-800 font-bold bg-white rounded-lg transition duration-300 focus:outline-none'
                    href='#'
                  >
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-600 animate-pulse group-hover:ring-0 transition duration-300' />
                    <svg
                      width={16}
                      height={16}
                      viewBox='0 0 16 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M13.3332 7.33333C13.3332 4.38781 10.9454 2 7.99984 2C5.05432 2 2.6665 4.38781 2.6665 7.33333'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M1.3335 9.77178V9.56155C1.3335 8.64381 1.95809 7.84385 2.84843 7.62126L3.25464 7.51971C3.63333 7.42504 4.00016 7.71145 4.00016 8.10179V11.2315C4.00016 11.6219 3.63333 11.9083 3.25464 11.8136L2.84842 11.7121C1.95809 11.4895 1.3335 10.6895 1.3335 9.77178Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                      <path
                        d='M14.6667 9.77178V9.56155C14.6667 8.64381 14.0421 7.84385 13.1517 7.62126L12.7455 7.51971C12.3668 7.42504 12 7.71145 12 8.10179V11.2315C12 11.6219 12.3668 11.9083 12.7455 11.8136L13.1517 11.7121C14.0421 11.4895 14.6667 10.6895 14.6667 9.77178Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                      <path
                        d='M13.3332 12V12C13.3332 12.9205 12.587 13.6667 11.6665 13.6667H9.6665'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                      <path
                        d='M9 14.6667H7C6.44772 14.6667 6 14.219 6 13.6667C6 13.1144 6.44772 12.6667 7 12.6667H9C9.55228 12.6667 10 13.1144 10 13.6667C10 14.219 9.55228 14.6667 9 14.6667Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                      />
                    </svg>
                    <span className='ml-2'>Talk to us</span>
                  </a>
                </div>
              </div>
              <div className='w-full lg:w-1/2 px-4'>
                <div>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        What is Aurora?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How does it work?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How much does it cost?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        Is there a free trial available?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How to export my assets?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='text-center mb-20'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold text-white mb-6'>
                Frequently asked questions
              </h1>
              <p className='text-gray-400'>
                Everything you need to know about the product.
              </p>
            </div>
            <div className='flex flex-wrap -mx-4 lg:-mx-12 -mb-16'>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    What is Aurora?
                  </h6>
                  <p className='text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    How does it work?
                  </h6>
                  <p className='text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    How much does it cost?
                  </h6>
                  <p className='text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    How to export my assets?
                  </h6>
                  <p className='text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    How do I invite my team?
                  </h6>
                  <p className='text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    Is there a free trial available?
                  </h6>
                  <p className='text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
            </div>
            <div className='mt-16 py-4 px-6 bg-gray-800 rounded-xl'>
              <div className='sm:flex items-center'>
                <div className='xs:flex mb-6 md:mb-0 items-center'>
                  <div className='flex-shrink-0 flex items-center'>
                    <div className='inline-flex items-center justify-center p-1 bg-gray-800 rounded-full'>
                      <div className='inline-flex items-center justify-center w-14 h-14 bg-teal-700 rounded-full'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8 10L12 10L16 10'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M8 14L10 14L12 14'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.8214 2.48697 15.5291 3.33782 17L2.5 21.5L7 20.6622C8.47087 21.513 10.1786 22 12 22Z'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                    </div>
                    <div className='-ml-4 inline-flex items-center justify-center p-1 bg-gray-800 rounded-full'>
                      <img
                        className='block w-14 h-14'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='mt-6 md:mt-0 xs:ml-6'>
                    <h6 className='font-semibold text-white mb-1'>
                      Still have questions?
                    </h6>
                    <p className='text-sm text-gray-400'>
                      Can’t find the answer you’re looking for? Please chat to
                      our team.
                    </p>
                  </div>
                </div>
                <div className='flex-shrink-0 ml-auto sm:pl-4 text-right'>
                  <a
                    className='group inline-block justify-center p-1 text-center text-base text-white font-semibold rounded-lg'
                    href='#'
                  >
                    <div className='inline-flex items-stretch h-12 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                      <div className='flex items-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                        <span className='mr-2'>Get in touch</span>
                        <span className='transform group-hover:translate-x-1 transition duration-200'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M5.00033 10H15.417M15.417 10L10.417 5M15.417 10L10.417 15'
                              stroke='white'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='text-center mb-20'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold text-white'>
                Frequently asked questions
              </h1>
            </div>
            <div className='max-w-6xl mx-auto'>
              <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-gray-800 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                <div className='pr-4'>
                  <h6 className='font-semibold text-white'>What is Aurora?</h6>
                  <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
                <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-gray-700 rounded-full'>
                  <svg
                    className='group-hover:hidden'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 6L8 10L12 6'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <svg
                    className='hidden group-hover:block'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 10L8 6L12 10'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </button>
              <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-gray-800 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                <div className='pr-4'>
                  <h6 className='font-semibold text-white'>
                    How does it work?
                  </h6>
                  <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
                <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-gray-700 rounded-full'>
                  <svg
                    className='group-hover:hidden'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 6L8 10L12 6'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <svg
                    className='hidden group-hover:block'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 10L8 6L12 10'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </button>
              <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-gray-800 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                <div className='pr-4'>
                  <h6 className='font-semibold text-white'>
                    How much does it cost?
                  </h6>
                  <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
                <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-gray-700 rounded-full'>
                  <svg
                    className='group-hover:hidden'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 6L8 10L12 6'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <svg
                    className='hidden group-hover:block'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 10L8 6L12 10'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </button>
              <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-gray-800 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                <div className='pr-4'>
                  <h6 className='font-semibold text-white'>
                    Is there a free trial available?
                  </h6>
                  <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
                <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-gray-700 rounded-full'>
                  <svg
                    className='group-hover:hidden'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 6L8 10L12 6'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <svg
                    className='hidden group-hover:block'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 10L8 6L12 10'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </button>
              <button variant="classic" className='group flex items-center justify-between text-left w-full px-6 py-5 bg-gray-800 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                <div className='pr-4'>
                  <h6 className='font-semibold text-white'>
                    How to export my assets?
                  </h6>
                  <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
                <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-gray-700 rounded-full'>
                  <svg
                    className='group-hover:hidden'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 6L8 10L12 6'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <svg
                    className='hidden group-hover:block'
                    width={16}
                    height={16}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 10L8 6L12 10'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </section>
        <section className='relative overflow-hidden'>
          <div className='flex flex-wrap'>
            <div className='relative w-full md:w-5/12 pb-52 xs:pb-64 md:pb-80 xl:pb-112 pt-12 md:pt-28 px-6 lg:pr-10 bg-gray-800'>
              <div className='mx-auto max-w-md'>
                <span className='inline-flex items-center h-6 mb-6 px-2 text-sm font-medium text-white bg-gray-700 rounded-full'>
                  FAQ
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold text-white mb-6'>
                  FAQs
                </h1>
                <p className='text-gray-400'>
                  Everything you need to know about the product. Can’t find the
                  answer you’re looking for? Please chat to our team.
                </p>
              </div>
              <img
                className='absolute bottom-0 left-0'
                src='aurora-assets/contact/line-half-circle-dark.png'
                alt=''
              />
            </div>
            <div className='w-full md:w-7/12 px-6 py-28 md:pl-12 xl:pl-24 bg-gray-900'>
              <div className='max-w-lg mx-auto lg:mx-0 lg:max-w-xl'>
                <div>
                  <button variant="classic" className='group flex mb-8 items-center justify-between text-left w-full pb-5 border-b border-gray-700 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='text-xl font-semibold text-white'>
                        What is Aurora?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='text-white group-hover:text-yellowGreen-700 transform group-hover:rotate-45 transition duration-200'>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 12H12M18 12H12M12 12V6M12 12V18'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-8 items-center justify-between text-left w-full pb-5 border-b border-gray-700 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='text-xl font-semibold text-white'>
                        How does it work?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='text-white group-hover:text-yellowGreen-700 transform group-hover:rotate-45 transition duration-200'>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 12H12M18 12H12M12 12V6M12 12V18'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-8 items-center justify-between text-left w-full pb-5 border-b border-gray-700 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='text-xl font-semibold text-white'>
                        How much does it cost?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='text-white group-hover:text-yellowGreen-700 transform group-hover:rotate-45 transition duration-200'>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 12H12M18 12H12M12 12V6M12 12V18'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-8 items-center justify-between text-left w-full pb-5 border-b border-gray-700 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='text-xl font-semibold text-white'>
                        Is there a free trial available?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='text-white group-hover:text-yellowGreen-700 transform group-hover:rotate-45 transition duration-200'>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 12H12M18 12H12M12 12V6M12 12V18'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-8 items-center justify-between text-left w-full pb-5 border-b border-gray-700 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='text-xl font-semibold text-white'>
                        How much does it cost?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='text-white group-hover:text-yellowGreen-700 transform group-hover:rotate-45 transition duration-200'>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 12H12M18 12H12M12 12V6M12 12V18'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex items-center justify-between text-left w-full pb-5 border-b border-gray-700 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='text-xl font-semibold text-white'>
                        Is there a free trial available?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-400'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='text-white group-hover:text-yellowGreen-700 transform group-hover:rotate-45 transition duration-200'>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 12H12M18 12H12M12 12V6M12 12V18'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='text-center mb-20'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold text-white'>
                Frequently asked questions
              </h1>
            </div>
            <div className='max-w-6xl mx-auto'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full md:w-1/2 px-4 mb-3 md:mb-0'>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        What is Aurora?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How does it work?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How much does it cost?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        Is there a free trial available?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How to export my assets?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                </div>
                <div className='w-full md:w-1/2 px-4'>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        What is Aurora?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How does it work?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How much does it cost?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex mb-3 items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        Is there a free trial available?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                  <button variant="classic" className='group flex items-center justify-between text-left w-full px-6 py-5 bg-white bg-opacity-10 rounded-xl border border-transparent hover:border-yellowGreen-800 ring ring-transparent hover:ring-yellowGreen-800 focus:outline-none'>
                    <div className='pr-4'>
                      <h6 className='font-semibold text-white'>
                        How to export my assets?
                      </h6>
                      <p className='hidden group-hover:block mt-2 text-sm text-gray-300'>
                        Orders are usually shipped within 1-2 business days
                        after placing the order.
                      </p>
                    </div>
                    <div className='flex-shrink-0 flex w-7 h-7 items-center justify-center bg-white bg-opacity-20 rounded-full'>
                      <svg
                        className='group-hover:hidden'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <svg
                        className='hidden group-hover:block'
                        width={16}
                        height={16}
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 10L8 6L12 10'
                          stroke='white'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='text-center mb-20'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold text-white mb-6'>
                Frequently asked questions
              </h1>
              <p className='text-gray-400'>
                Everything you need to know about the product.
              </p>
            </div>
            <div className='flex flex-wrap -mx-4 lg:-mx-12 -mb-16'>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    What is Aurora?
                  </h6>
                  <p className='text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    How does it work?
                  </h6>
                  <p className='text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    How much does it cost?
                  </h6>
                  <p className='text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    How to export my assets?
                  </h6>
                  <p className='text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    How do I invite my team?
                  </h6>
                  <p className='text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    Is there a free trial available?
                  </h6>
                  <p className='text-gray-400'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
            </div>
            <div className='mt-16 py-4 px-6 bg-gray-800 rounded-xl'>
              <div className='sm:flex items-center'>
                <div className='xs:flex mb-6 md:mb-0 items-center'>
                  <div className='flex-shrink-0 flex items-center'>
                    <div className='inline-flex items-center justify-center p-1 bg-gray-800 rounded-full'>
                      <div className='inline-flex items-center justify-center w-14 h-14 bg-teal-700 rounded-full'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8 10L12 10L16 10'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M8 14L10 14L12 14'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.8214 2.48697 15.5291 3.33782 17L2.5 21.5L7 20.6622C8.47087 21.513 10.1786 22 12 22Z'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                    </div>
                    <div className='-ml-4 inline-flex items-center justify-center p-1 bg-gray-800 rounded-full'>
                      <img
                        className='block w-14 h-14'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='mt-6 md:mt-0 xs:ml-6'>
                    <h6 className='font-semibold text-white mb-1'>
                      Still have questions?
                    </h6>
                    <p className='text-sm text-gray-400'>
                      Can’t find the answer you’re looking for? Please chat to
                      our team.
                    </p>
                  </div>
                </div>
                <div className='flex-shrink-0 ml-auto sm:pl-4 text-right'>
                  <a
                    className='group inline-block justify-center p-1 text-center text-base text-white font-semibold rounded-lg'
                    href='#'
                  >
                    <div className='inline-flex items-stretch h-12 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'>
                      <div className='flex items-center px-4 bg-gray-700 group-hover:bg-opacity-40 rounded-md transition duration-300'>
                        <span className='mr-2'>Get in touch</span>
                        <span className='transform group-hover:translate-x-1 transition duration-200'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M5.00033 10H15.417M15.417 10L10.417 5M15.417 10L10.417 15'
                              stroke='white'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='text-center mb-20'>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-semibold text-white mb-6'>
                Frequently asked questions
              </h1>
              <p className='text-gray-200'>
                Everything you need to know about the product.
              </p>
            </div>
            <div className='flex flex-wrap -mx-4 lg:-mx-12 -mb-16'>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    What is Aurora?
                  </h6>
                  <p className='text-gray-200'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    How does it work?
                  </h6>
                  <p className='text-gray-200'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    How much does it cost?
                  </h6>
                  <p className='text-gray-200'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    How to export my assets?
                  </h6>
                  <p className='text-gray-200'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    How do I invite my team?
                  </h6>
                  <p className='text-gray-200'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
              <div className='w-full xs:w-1/2 md:w-1/3 px-4 lg:px-12 mb-16'>
                <div className='max-w-sm'>
                  <h6 className='text-xl font-semibold text-white mb-1'>
                    Is there a free trial available?
                  </h6>
                  <p className='text-gray-200'>
                    Orders are usually shipped within 1-2 business days after
                    placing the order.
                  </p>
                </div>
              </div>
            </div>
            <div className='mt-16 py-4 px-6 bg-white bg-opacity-10 rounded-xl'>
              <div className='sm:flex items-center'>
                <div className='xs:flex mb-6 md:mb-0 items-center'>
                  <div className='flex-shrink-0 flex items-center'>
                    <div className='inline-flex items-center justify-center p-1 bg-teal-400 rounded-full'>
                      <div className='inline-flex items-center justify-center w-14 h-14 bg-teal-700 rounded-full'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8 10L12 10L16 10'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M8 14L10 14L12 14'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.8214 2.48697 15.5291 3.33782 17L2.5 21.5L7 20.6622C8.47087 21.513 10.1786 22 12 22Z'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                    </div>
                    <div className='-ml-4 inline-flex items-center justify-center p-1 bg-teal-400 rounded-full'>
                      <img
                        className='block w-14 h-14'
                        src='aurora-assets/blog/avatar-medium.png'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='mt-6 md:mt-0 xs:ml-6'>
                    <h6 className='font-semibold text-white mb-1'>
                      Still have questions?
                    </h6>
                    <p className='text-sm text-gray-200'>
                      Can’t find the answer you’re looking for? Please chat to
                      our team.
                    </p>
                  </div>
                </div>
                <div className='flex-shrink-0 ml-auto sm:pl-4 text-right'>
                  <a
                    className='xs:flex-shrink-0 group relative h-12 inline-flex w-full xs:w-auto items-center justify-center px-4 text-center text-base text-teal-800 font-bold bg-white rounded-lg transition duration-300 focus:outline-none'
                    href='#'
                  >
                    <span className='mr-2'>Get in touch</span>
                    <span className='transform group-hover:translate-x-1 transition duration-200'>
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M5.00033 10H15.417M15.417 10L10.417 5M15.417 10L10.417 15'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    <FooterComponent /></React.Fragment>
  );
}

