import React from 'react';

import { Flex, Text, Button } from '@radix-ui/themes';


import {FooterComponent} from './Footers.jsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function CallToAction() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='relative pb-16 border-b border-yellowGreen-900 border-opacity-50 overflow-hidden'>
              <img
                className='absolute bottom-0 left-1/2 md:-mb-2 lg:-mb-20 transform -translate-x-1/2 opacity-20'
                src='aurora-assets/cta/line-green-bottom.png'
                alt=''
              />
              <img
                className='absolute bottom-0 left-1/2 -mb-5 sm:-mb-10 md:-mb-16 lg:-mb-28 transform -translate-x-1/2 opacity-20'
                src='aurora-assets/cta/light-green-bottom.png'
                alt=''
              />
              <div className='relative max-w-sm md:max-w-xl mx-auto text-center'>
                <Button variant="classic">
                  Get Started
                </Button>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-10'>
                  Start organizing your digital assets
                </h1>
                <a
                  className='group relative flex xs:inline-flex items-center justify-center px-5 h-12 font-bold text-sm text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300'
                  href='#'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span className='mr-2'>Start your 14-day trial</span>
                  <span className='transform group-hover:translate-x-1 transition duration-200'>
                    <svg
                      width={17}
                      height={16}
                      viewBox='0 0 17 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M4.5 8H12.8333M12.8333 8L8.83334 4M12.8333 8L8.83334 12'
                        stroke='#041109'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className='pt-12 md:py-24 bg-gray-50'>
          <div className='container px-4 mx-auto'>
            <div className='relative pb-16 border-b border-gray-200 overflow-hidden'>
              <img
                className='absolute bottom-0 left-1/2 md:-mb-2 lg:-mb-20 transform -translate-x-1/2'
                src='aurora-assets/cta/line-light-background.png'
                alt=''
              />
              <img
                className='absolute bottom-0 left-1/2 -mb-5 sm:-mb-10 md:-mb-16 lg:-mb-28 transform -translate-x-1/2'
                src='aurora-assets/cta/light-teal-bottom-blur.png'
                alt=''
              />
              <div className='relative max-w-sm md:max-w-xl mx-auto text-center'>
                <span className='inline-flex items-center h-6 mb-4 px-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-full'>
                  Get Started
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-10'>
                  Start organizing your digital assets
                </h1>
                <a
                  className='group relative flex xs:inline-flex items-center justify-center px-5 h-12 font-bold text-sm text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                  href='#'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring-4 ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span className='mr-2'>Start your 14-day trial</span>
                  <span className='transform group-hover:translate-x-1 transition duration-200'>
                    <svg
                      width={17}
                      height={16}
                      viewBox='0 0 17 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M4.5 8H12.8333M12.8333 8L8.83334 4M12.8333 8L8.83334 12'
                        stroke='white'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-24 bg-radial-dark-green'>
          <img
            className='absolute top-0 left-1/2 -mb-5 sm:-mb-10 md:-mb-16 lg:-mb-28 transform -translate-x-1/2 opacity-20'
            src='aurora-assets/cta/light-green-top.png'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <div className='relative max-w-md mx-auto text-center'>
              <span className='inline-flex items-center h-6 mb-4 px-2 text-sm font-medium text-white bg-teal-800 rounded-full'>
                14-day free trial
              </span>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                Try Aurora today
              </h1>
              <p className='text-gray-200 mb-10'>
                Make organizing your digital assets fun again!
              </p>
              <div className='xs:flex items-center'>
                <input
                  className='py-3 px-4 h-12 w-full mb-3 xs:mb-0 xs:mr-3 text-gray-400 placeholder-gray-400 bg-white bg-opacity-10 border border-gray-500 focus:border-teal-600 outline-none ring ring-transparent focus:ring-teal-600 rounded-lg'
                  type='email'
                  placeholder='Enter your email address'
                />
                <Button variant="classic"
                  className='xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center h-12 px-5 text-center text-base text-teal-800 font-bold bg-white rounded-lg transition duration-300 focus:outline-none'
                  type='submit'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-600 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span>Get Started</span>
                </Button>
              </div>
            </div>
          </div>
        </section>
        <section className='relative bg-radial-dark-green overflow-hidden'>
          <div className='relative max-w-xs xs:max-w-md sm:max-w-lg lg:max-w-2xl xl:max-w-3xl px-4 mx-auto py-36'>
            <div className='relative max-w-md mx-auto px-4 sm:px-0 text-center'>
              <img
                className='block mx-auto mb-10'
                src='aurora-assets/logos/logo-sign-md.png'
                alt=''
              />
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                Try Aurora today
              </h1>
              <p className='text-gray-400 mb-10'>
                Start organizing your digital marketing assets today!
              </p>
              <div className='xs:flex items-center justify-center'>
                <a
                  className='group relative flex xs:inline-flex mb-3 xs:mb-0 xs:mr-8 w-full xs:w-auto items-center justify-center h-10 px-5 text-center text-sm text-teal-800 font-bold bg-white rounded-lg transition duration-300 focus:outline-none'
                  href='#'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-600 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span className='mr-2'>Get Started</span>
                  <span className='transform group-hover:translate-x-1 transition duration-200'>
                    <svg
                      width={17}
                      height={16}
                      viewBox='0 0 17 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M4.5 8H12.8333M12.8333 8L8.83334 4M12.8333 8L8.83334 12'
                        stroke='#041109'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                </a>
                <a
                  className='flex xs:inline-flex items-center justify-center h-11 text-center text-sm font-semibold text-white hover:text-yellowGreen-600 rounded-lg transition duration-200'
                  href='#'
                >
                  Book a Demo
                </a>
              </div>
            </div>
            <div className='absolute z-10 top-0 left-0 mt-20 h-2.5 w-2.5 transform -translate-y-1/2 -translate-x-1/2 bg-teal-900 rounded-full' />
            <div className='absolute z-10 top-0 right-0 mt-20 h-2.5 w-2.5 transform -translate-y-1/2 translate-x-1/2 bg-teal-900 rounded-full' />
            <div className='absolute z-10 bottom-0 left-0 mb-20 h-2.5 w-2.5 transform translate-y-1/2 -translate-x-1/2 bg-teal-500 rounded-full' />
            <div className='absolute z-10 bottom-0 right-0 mb-20 h-2.5 w-2.5 transform translate-y-1/2 translate-x-1/2 bg-teal-500 rounded-full' />
            <div className='absolute bottom-0 mb-20 left-1/2 transform -translate-x-1/2 w-full h-px bg-white bg-opacity-10' />
            <div className='absolute top-0 left-1/2 transform -translate-x-1/2 w-screen mt-20 h-px bg-white bg-opacity-10' />
            <div className='absolute top-0 left-0 h-full w-px bg-white bg-opacity-10' />
            <div className='absolute top-0 right-0 h-full w-px bg-white bg-opacity-10' />
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='rounded-3xl bg-radial-dark-green'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/2 px-12 pt-16 md:pl-18 pb-20'>
                  <div className='max-w-md'>
                    <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-10'>
                      Start organizing your digital assets
                    </h1>
                    <div className='xs:flex mb-10 items-center'>
                      <input
                        className='py-3 px-4 h-12 w-full mb-3 xs:mb-0 xs:mr-3 text-gray-300 placeholder-gray-400 bg-white bg-opacity-10 border border-gray-500 focus:border-teal-600 outline-none ring ring-transparent focus:ring-teal-600 rounded-lg'
                        type='email'
                        placeholder='Enter your email address'
                      />
                      <Button variant="classic"
                        className='xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center h-12 px-5 text-center text-base text-teal-800 font-bold bg-white rounded-lg transition duration-300 focus:outline-none'
                        type='submit'
                      >
                        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-600 animate-pulse group-hover:ring-0 transition duration-300' />
                        <span>Get Started</span>
                      </Button>
                    </div>
                    <div className='flex flex-wrap -mb-3 items-center'>
                      <div className='flex mr-3 mb-3 items-center'>
                        <svg
                          width={16}
                          height={16}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.33337 8.66675L6.00004 11.3334L12.6667 4.66675'
                            stroke='#A3E33A'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <span className='ml-2 text-sm text-gray-200'>
                          Free 14-day trial
                        </span>
                      </div>
                      <div className='flex mr-3 mb-3 items-center'>
                        <svg
                          width={16}
                          height={16}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.33337 8.66675L6.00004 11.3334L12.6667 4.66675'
                            stroke='#A3E33A'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <span className='ml-2 text-sm text-gray-200'>
                          Unlimited assets
                        </span>
                      </div>
                      <div className='flex mb-3 items-center'>
                        <svg
                          width={16}
                          height={16}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.33337 8.66675L6.00004 11.3334L12.6667 4.66675'
                            stroke='#A3E33A'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <span className='ml-2 text-sm text-gray-200'>
                          1 TB cloud storage
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 px-4 pl-10 xl:pl-0 self-end'>
                  <img
                    className='block ml-auto'
                    src='aurora-assets/cta/image-right-dark.png'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-24 bg-radial-dark-green'>
          <img
            className='absolute top-0 left-1/2 -mb-5 sm:-mb-10 md:-mb-16 lg:-mb-28 transform -translate-x-1/2 opacity-20'
            src='aurora-assets/cta/light-green-top.png'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <div className='relative max-w-md mx-auto text-center'>
              <span className='inline-flex items-center h-6 mb-4 px-2 text-sm font-medium text-white bg-teal-800 rounded-full'>
                14-day free trial
              </span>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                Try Aurora today
              </h1>
              <p className='text-gray-200 mb-10'>
                Make organizing your digital assets fun again!
              </p>
              <div className='xs:flex items-center'>
                <input
                  className='py-3 px-4 h-12 w-full mb-3 xs:mb-0 xs:mr-3 text-gray-400 placeholder-gray-400 bg-white bg-opacity-10 border border-gray-500 focus:border-teal-600 outline-none ring ring-transparent focus:ring-teal-600 rounded-lg'
                  type='email'
                  placeholder='Enter your email address'
                />
                <Button variant="classic"
                  className='xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center h-12 px-5 text-center text-base text-teal-800 font-bold bg-white rounded-lg transition duration-300 focus:outline-none'
                  type='submit'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-600 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span>Get Started</span>
                </Button>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 overflow-hidden'>
          <div className='relative container px-4 mx-auto'>
            <div className='bg-teal-900 border border-gray-200 rounded-3xl overflow-hidden'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/2 pt-16 lg:pt-24 px-12 md:pl-16 lg:pl-24'>
                  <div className='max-w-md'>
                    <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white'>
                      Start organizing your digital assets
                    </h1>
                  </div>
                </div>
                <div className='relative w-full lg:w-1/2 pt-16 px-4 h-full self-end'>
                  <img
                    className='absolute top-0 left-0'
                    src='aurora-assets/cta/starts.png'
                    alt=''
                  />
                  <div
                    className='absolute bottom-0 left-0 -mb-16 w-full h-full bg-teal-700'
                    style={{ filter: 'blur(50px)' }}
                  />
                  <div className='relative bg-teal-900 pt-16 pb-16 xl:pb-24 px-8 sm:px-12 lg:pl-12 xl:pr-20 border-t lg:border-l border-teal-700 lg:rounded-tl-xl'>
                    <div className='xs:flex mb-10 items-center'>
                      <input
                        className='py-3 px-4 h-12 w-full mb-3 xs:mb-0 xs:mr-3 text-gray-400 placeholder-gray-400 bg-teal-800 border border-teal-800 focus:border-yellowGreen-800 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-800'
                        type='email'
                        placeholder='Enter your email address'
                      />
                      <Button variant="classic"
                        className='xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center px-5 h-12 font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
                        type='submit'
                      >
                        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                        <span>Get Started</span>
                      </Button>
                    </div>
                    <div className='flex flex-wrap -mb-3 items-center xl:justify-between'>
                      <div className='flex mr-3 mb-3 items-center'>
                        <svg
                          width={16}
                          height={16}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.33337 8.66667L6.00004 11.3333L12.6667 4.66667'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <span className='ml-2 text-sm text-gray-400'>
                          Free 14-day trial
                        </span>
                      </div>
                      <div className='flex mr-3 mb-3 items-center'>
                        <svg
                          width={16}
                          height={16}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.33337 8.66667L6.00004 11.3333L12.6667 4.66667'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <span className='ml-2 text-sm text-gray-400'>
                          Unlimited assets
                        </span>
                      </div>
                      <div className='flex mb-3 items-center'>
                        <svg
                          width={16}
                          height={16}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.33337 8.66667L6.00004 11.3333L12.6667 4.66667'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <span className='ml-2 text-sm text-gray-400'>
                          1 TB cloud storage
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='relative pb-16 border-b border-yellowGreen-900 overflow-hidden'>
              <img
                className='absolute bottom-0 left-1/2 md:-mb-2 lg:-mb-20 transform -translate-x-1/2'
                src='aurora-assets/cta/line-green-dark.png'
                alt=''
              />
              <img
                className='absolute bottom-0 left-1/2 -mb-5 sm:-mb-10 md:-mb-16 lg:-mb-28 transform -translate-x-1/2 opacity-30'
                src='aurora-assets/cta/light-green-bottom.png'
                alt=''
              />
              <div className='relative max-w-sm md:max-w-xl mx-auto text-center'>
                <span className='inline-flex items-center h-6 mb-4 px-2 text-sm font-medium text-white bg-teal-800 rounded-full'>
                  Get Started
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-10'>
                  Start organizing your digital assets
                </h1>
                <a
                  className='group relative flex xs:inline-flex items-center justify-center px-5 h-12 font-bold text-sm text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300'
                  href='#'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span className='mr-2'>Start your 14-day trial</span>
                  <span className='transform group-hover:translate-x-1 transition duration-200'>
                    <svg
                      width={17}
                      height={16}
                      viewBox='0 0 17 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M4.5 8H12.8333M12.8333 8L8.83334 4M12.8333 8L8.83334 12'
                        stroke='#041109'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <div className='relative bg-gray-900 rounded-xl overflow-hidden'>
              <div className='flex flex-wrap'>
                <div className='w-full md:w-7/12 xl:w-1/2 py-16 px-8 lg:px-18'>
                  <div className='max-w-sm mx-auto lg:max-w-lg lg:pr-10'>
                    <h3 className='font-heading tracking-tight text-4xl font-bold text-white mb-4'>
                      Join Newsletter
                    </h3>
                    <p className='text-lg text-gray-500 mb-10'>
                      Get the latest updates delivered into your inbox monthly.
                      No spam. Unsubscribe anytime.
                    </p>
                    <div className='sm:flex mb-5 items-center'>
                      <input
                        className='py-3 px-4 h-12 w-full mb-3 sm:mb-0 sm:mr-3 text-gray-400 placeholder-gray-400 bg-gray-800 border border-gray-700 outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                        type='email'
                        placeholder='Your email'
                      />
                      <Button variant="classic"
                        className='sm:flex-shrink-0 group relative w-full sm:w-auto h-12 flex sm:inline-flex items-center justify-center px-4 p-px font-bold text-gray-700 bg-white rounded-lg transition-all duration-300 focus:outline-none'
                        type='submit'
                      >
                        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-gray-500 animate-pulse group-hover:ring-0 transition duration-300' />
                        <span>Subscribe</span>
                      </Button>
                    </div>
                  </div>
                </div>
                <div className='relative w-full md:w-5/12 xl:w-1/2 py-32 lg:py-0'>
                  <div className='absolute top-0 left-0 w-24 h-24 md:mt-8 bg-gray-800' />
                  <div className='absolute top-0 left-0 w-24 h-24 mt-32 md:ml-24 bg-gray-800' />
                  <div className='absolute top-0 right-0 w-24 h-24 mr-24 xl:mr-32 -mt-8 lg:mt-8 bg-gray-800' />
                  <div className='absolute bottom-0 right-0 w-24 h-24 mb-24 bg-gray-800' />
                  <div className='hidden sm:block md:hidden absolute bottom-0 left-0 w-24 h-24 mb-32 ml-52 bg-gray-800' />
                  <div className='absolute bottom-0 right-1/2 transform translate-x-1/2 -mb-5 w-24 h-24 bg-gray-800' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='relative pb-16 border-b border-yellowGreen-900 overflow-hidden'>
              <img
                className='absolute bottom-0 left-1/2 md:-mb-2 lg:-mb-20 transform -translate-x-1/2'
                src='aurora-assets/cta/line-green-dark.png'
                alt=''
              />
              <img
                className='absolute bottom-0 left-1/2 -mb-5 sm:-mb-10 md:-mb-16 lg:-mb-28 transform -translate-x-1/2 opacity-30'
                src='aurora-assets/cta/light-green-bottom.png'
                alt=''
              />
              <div className='relative max-w-sm md:max-w-xl mx-auto text-center'>
                <span className='inline-flex items-center h-6 mb-4 px-2 text-sm font-medium text-white bg-teal-800 rounded-full'>
                  Get Started
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-10'>
                  Start organizing your digital assets
                </h1>
                <a
                  className='group relative flex xs:inline-flex items-center justify-center px-5 h-12 font-bold text-sm text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300'
                  href='#'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span className='mr-2'>Start your 14-day trial</span>
                  <span className='transform group-hover:translate-x-1 transition duration-200'>
                    <svg
                      width={17}
                      height={16}
                      viewBox='0 0 17 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M4.5 8H12.8333M12.8333 8L8.83334 4M12.8333 8L8.83334 12'
                        stroke='#041109'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className='relative bg-gray-50 overflow-hidden'>
          <div className='relative max-w-xs xs:max-w-md sm:max-w-lg lg:max-w-2xl xl:max-w-3xl px-4 mx-auto py-36'>
            <div className='relative max-w-md mx-auto px-4 sm:px-0 text-center'>
              <img
                className='block mx-auto mb-10'
                src='aurora-assets/logos/logo-sign-md.png'
                alt=''
              />
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-4'>
                Try Aurora today
              </h1>
              <p className='text-gray-500 mb-10'>
                Start organizing your digital marketing assets today!
              </p>
              <div className='xs:flex items-center justify-center'>
                <a
                  className='group relative block xs:inline-block mb-3 xs:mb-0 xs:mr-4 text-sm font-semibold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300'
                  href='#'
                >
                  <div className='relative flex items-center justify-center px-4 h-10 bg-gradient-to-br from-cyanGreen-800 to-cyan-800 group-hover:text-green-200 rounded-lg transition duration-300'>
                    <span className='-mb-px mr-2'>Get Started</span>
                    <span className='transform group-hover:translate-x-1 transition duration-200'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4.5 8H12.8333M12.8333 8L8.83334 4M12.8333 8L8.83334 12'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                  </div>
                </a>
                <a
                  className='flex xs:inline-flex items-center justify-center h-10 px-4 text-center text-sm font-semibold leading-6 text-gray-700 hover:text-yellowGreen-700 border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200'
                  href='#'
                >
                  Book a Demo
                </a>
              </div>
            </div>
            <div className='absolute z-10 top-0 left-0 mt-20 h-2.5 w-2.5 transform -translate-y-1/2 -translate-x-1/2 bg-gray-200 rounded-full' />
            <div className='absolute z-10 top-0 right-0 mt-20 h-2.5 w-2.5 transform -translate-y-1/2 translate-x-1/2 bg-gray-200 rounded-full' />
            <div className='absolute z-10 bottom-0 left-0 mb-20 h-2.5 w-2.5 transform translate-y-1/2 -translate-x-1/2 bg-gray-200 rounded-full' />
            <div className='absolute z-10 bottom-0 right-0 mb-20 h-2.5 w-2.5 transform translate-y-1/2 translate-x-1/2 bg-gray-200 rounded-full' />
            <div className='absolute bottom-0 mb-20 left-1/2 transform -translate-x-1/2 w-full h-px bg-gray-300' />
            <div className='absolute top-0 left-1/2 transform -translate-x-1/2 w-screen mt-20 h-px bg-gray-300' />
            <div className='absolute top-0 left-0 h-full w-px bg-gray-300' />
            <div className='absolute top-0 right-0 h-full w-px bg-gray-300' />
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-50'>
          <img
            className='absolute top-0 left-1/2 -mb-5 sm:-mb-10 md:-mb-16 lg:-mb-28 transform -translate-x-1/2'
            src='aurora-assets/cta/light-gray-top.png'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <div className='relative max-w-md mx-auto text-center'>
              <span className='inline-flex items-center h-6 mb-4 px-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-full'>
                14-day free trial
              </span>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-4'>
                Try Aurora today
              </h1>
              <p className='text-gray-500 mb-10'>
                Make organizing your digital assets fun again!
              </p>
              <div className='xs:flex items-center'>
                <input
                  className='py-3 px-4 h-12 w-full mb-3 xs:mb-0 xs:mr-3 text-gray-500 placeholder-gray-500 border border-gray-200 focus:border-yellowGreen-500 outline-none ring ring-transparent focus:ring-yellowGreen-500 rounded-lg'
                  type='email'
                  placeholder='Enter your email address'
                />
                <Button variant="classic"
                  className='xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300 focus:outline-none'
                  type='submit'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span>Get Started</span>
                </Button>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-50'>
          <div className='container px-4 mx-auto'>
            <div className='rounded-3xl bg-white'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/2 px-12 pt-16 md:pl-18 pb-20'>
                  <div className='max-w-md'>
                    <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-10'>
                      Start organizing your digital assets
                    </h1>
                    <div className='xs:flex mb-10 items-center'>
                      <input
                        className='py-3 px-4 h-12 w-full mb-3 xs:mb-0 xs:mr-3 text-gray-500 placeholder-gray-500 border border-gray-200 focus:border-yellowGreen-500 outline-none ring ring-transparent focus:ring-yellowGreen-500 rounded-lg'
                        type='email'
                        placeholder='Enter your email address'
                      />
                      <Button variant="classic"
                        className='xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300 focus:outline-none'
                        type='submit'
                      >
                        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300' />
                        <span>Get Started</span>
                      </Button>
                    </div>
                    <div className='flex flex-wrap -mb-3 items-center'>
                      <div className='flex mr-6 mb-3 items-center'>
                        <svg
                          width={16}
                          height={16}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.33337 8.66667L6.00004 11.3333L12.6667 4.66667'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <span className='ml-2 text-sm text-gray-500'>
                          Free 14-day trial
                        </span>
                      </div>
                      <div className='flex mr-6 mb-3 items-center'>
                        <svg
                          width={16}
                          height={16}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.33337 8.66667L6.00004 11.3333L12.6667 4.66667'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <span className='ml-2 text-sm text-gray-500'>
                          Unlimited assets
                        </span>
                      </div>
                      <div className='flex mb-3 items-center'>
                        <svg
                          width={16}
                          height={16}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.33337 8.66667L6.00004 11.3333L12.6667 4.66667'
                            stroke='#041109'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <span className='ml-2 text-sm text-gray-500'>
                          1 TB cloud storage
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 px-4 pl-10 xl:pl-0 self-end'>
                  <img
                    className='block ml-auto'
                    src='aurora-assets/cta/image-right-assets.png'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 overflow-hidden'>
          <div className='relative container px-4 mx-auto'>
            <div className='bg-teal-900 border border-gray-200 rounded-3xl overflow-hidden'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/2 pt-16 lg:pt-24 px-12 md:pl-16 lg:pl-24'>
                  <div className='max-w-md'>
                    <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white'>
                      Start organizing your digital assets
                    </h1>
                  </div>
                </div>
                <div className='relative w-full lg:w-1/2 pt-16 px-4 h-full self-end'>
                  <img
                    className='absolute top-0 left-0'
                    src='aurora-assets/cta/starts.png'
                    alt=''
                  />
                  <div
                    className='absolute bottom-0 left-0 -mb-16 w-full h-full bg-teal-700'
                    style={{ filter: 'blur(50px)' }}
                  />
                  <div className='relative bg-teal-900 pt-16 pb-16 xl:pb-24 px-8 sm:px-12 lg:pl-12 xl:pr-20 border-t lg:border-l border-teal-700 lg:rounded-tl-xl'>
                    <div className='xs:flex mb-10 items-center'>
                      <input
                        className='py-3 px-4 h-12 w-full mb-3 xs:mb-0 xs:mr-3 text-gray-400 placeholder-gray-400 bg-teal-800 border border-teal-800 focus:border-yellowGreen-800 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-800'
                        type='email'
                        placeholder='Enter your email address'
                      />
                      <Button variant="classic"
                        className='xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center px-5 h-12 font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
                        type='submit'
                      >
                        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                        <span>Get Started</span>
                      </Button>
                    </div>
                    <div className='flex flex-wrap -mb-3 items-center xl:justify-between'>
                      <div className='flex mr-3 mb-3 items-center'>
                        <svg
                          width={16}
                          height={16}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.33337 8.66667L6.00004 11.3333L12.6667 4.66667'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <span className='ml-2 text-sm text-gray-400'>
                          Free 14-day trial
                        </span>
                      </div>
                      <div className='flex mr-3 mb-3 items-center'>
                        <svg
                          width={16}
                          height={16}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.33337 8.66667L6.00004 11.3333L12.6667 4.66667'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <span className='ml-2 text-sm text-gray-400'>
                          Unlimited assets
                        </span>
                      </div>
                      <div className='flex mb-3 items-center'>
                        <svg
                          width={16}
                          height={16}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.33337 8.66667L6.00004 11.3333L12.6667 4.66667'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <span className='ml-2 text-sm text-gray-400'>
                          1 TB cloud storage
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='relative pb-16 border-b border-yellowGreen-700 border-opacity-30 overflow-hidden'>
              <img
                className='absolute bottom-0 left-1/2 md:-mb-2 lg:-mb-20 transform -translate-x-1/2 opacity-10'
                src='aurora-assets/cta/line-light-background.png'
                alt=''
              />
              <img
                className='absolute bottom-0 left-1/2 -mb-5 sm:-mb-10 md:-mb-16 lg:-mb-28 transform -translate-x-1/2 opacity-50'
                src='aurora-assets/cta/light-green-bottom.png'
                alt=''
              />
              <div className='relative max-w-sm md:max-w-xl mx-auto text-center'>
                <span className='inline-flex items-center h-6 mb-4 px-2 text-sm font-medium text-white bg-teal-800 rounded-full'>
                  Get Started
                </span>
                <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-10'>
                  Start organizing your digital assets
                </h1>
                <a
                  className='group relative flex xs:inline-flex items-center justify-center h-12 px-5 text-center text-base text-teal-800 font-bold bg-white rounded-lg transition duration-300'
                  href='#'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-600 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span className='mr-2'>Start your 14-day trial</span>
                  <span className='transform group-hover:translate-x-1 transition duration-200'>
                    <svg
                      width={17}
                      height={16}
                      viewBox='0 0 17 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M4.5 8H12.8333M12.8333 8L8.83334 4M12.8333 8L8.83334 12'
                        stroke='#041109'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='rounded-3xl bg-radial-dark-green'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/2 px-12 pt-16 md:pl-18 pb-20'>
                  <div className='max-w-md'>
                    <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-10'>
                      Start organizing your digital assets
                    </h1>
                    <div className='xs:flex mb-10 items-center'>
                      <input
                        className='py-3 px-4 h-12 w-full mb-3 xs:mb-0 xs:mr-3 text-gray-300 placeholder-gray-400 bg-white bg-opacity-10 border border-gray-500 focus:border-teal-600 outline-none ring ring-transparent focus:ring-teal-600 rounded-lg'
                        type='email'
                        placeholder='Enter your email address'
                      />
                      <Button variant="classic"
                        className='xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center h-12 px-5 text-center text-base text-teal-800 font-bold bg-white rounded-lg transition duration-300 focus:outline-none'
                        type='submit'
                      >
                        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-600 animate-pulse group-hover:ring-0 transition duration-300' />
                        <span>Get Started</span>
                      </Button>
                    </div>
                    <div className='flex flex-wrap -mb-3 items-center'>
                      <div className='flex mr-3 mb-3 items-center'>
                        <svg
                          width={16}
                          height={16}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.33337 8.66675L6.00004 11.3334L12.6667 4.66675'
                            stroke='#A3E33A'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <span className='ml-2 text-sm text-gray-200'>
                          Free 14-day trial
                        </span>
                      </div>
                      <div className='flex mr-3 mb-3 items-center'>
                        <svg
                          width={16}
                          height={16}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.33337 8.66675L6.00004 11.3334L12.6667 4.66675'
                            stroke='#A3E33A'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <span className='ml-2 text-sm text-gray-200'>
                          Unlimited assets
                        </span>
                      </div>
                      <div className='flex mb-3 items-center'>
                        <svg
                          width={16}
                          height={16}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.33337 8.66675L6.00004 11.3334L12.6667 4.66675'
                            stroke='#A3E33A'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <span className='ml-2 text-sm text-gray-200'>
                          1 TB cloud storage
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 px-4 pl-10 xl:pl-0 self-end'>
                  <img
                    className='block ml-auto'
                    src='aurora-assets/cta/image-right-dark.png'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-gray-900 overflow-hidden'>
          <div className='relative container px-4 mx-auto'>
            <div className='bg-radial-dark-green rounded-3xl overflow-hidden'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/2 pt-16 lg:pt-24 px-12 md:pl-16 lg:pl-24'>
                  <div className='max-w-md'>
                    <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white'>
                      Start organizing your digital assets
                    </h1>
                  </div>
                </div>
                <div className='relative w-full lg:w-1/2 pt-16 px-4 h-full self-end'>
                  <img
                    className='absolute top-0 left-0'
                    src='aurora-assets/cta/starts.png'
                    alt=''
                  />
                  <div className='relative pt-16 pb-16 xl:pb-24 px-8 sm:px-12 lg:pl-12 xl:pr-20 lg:rounded-tl-xl border-t border-l border-teal-900 border-opacity-20 bg-gray-900 bg-opacity-10'>
                    <div className='xs:flex mb-10 items-center'>
                      <input
                        className='py-3 px-4 h-12 w-full mb-3 xs:mb-0 xs:mr-3 text-gray-400 placeholder-gray-400 bg-white bg-opacity-10 border border-gray-500 focus:border-teal-700 outline-none ring ring-transparent focus:ring-teal-700 rounded-lg'
                        type='email'
                        placeholder='Enter your email address'
                      />
                      <Button variant="classic"
                        className='xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center h-12 px-5 text-center text-base text-teal-800 font-bold bg-white rounded-lg transition duration-300 focus:outline-none'
                        type='submit'
                      >
                        <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-teal-600 animate-pulse group-hover:ring-0 transition duration-300' />
                        <span>Get Started</span>
                      </Button>
                    </div>
                    <div className='flex flex-wrap -mb-3 items-center xl:justify-between'>
                      <div className='flex mr-3 mb-3 items-center'>
                        <svg
                          width={16}
                          height={16}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.33337 8.66675L6.00004 11.3334L12.6667 4.66675'
                            stroke='#A3E33A'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <span className='ml-2 text-sm text-gray-200'>
                          Free 14-day trial
                        </span>
                      </div>
                      <div className='flex mr-3 mb-3 items-center'>
                        <svg
                          width={16}
                          height={16}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.33337 8.66675L6.00004 11.3334L12.6667 4.66675'
                            stroke='#A3E33A'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <span className='ml-2 text-sm text-gray-200'>
                          Unlimited assets
                        </span>
                      </div>
                      <div className='flex mb-3 items-center'>
                        <svg
                          width={16}
                          height={16}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.33337 8.66675L6.00004 11.3334L12.6667 4.66675'
                            stroke='#A3E33A'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <span className='ml-2 text-sm text-gray-200'>
                          1 TB cloud storage
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative bg-gray-900 overflow-hidden'>
          <div className='relative max-w-xs xs:max-w-md sm:max-w-lg lg:max-w-2xl xl:max-w-3xl px-4 mx-auto py-36'>
            <div className='relative max-w-md mx-auto px-4 sm:px-0 text-center'>
              <img
                className='block mx-auto mb-10'
                src='aurora-assets/logos/logo-sign-md.png'
                alt=''
              />
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                Try Aurora today
              </h1>
              <p className='text-gray-400 mb-10'>
                Start organizing your digital marketing assets today!
              </p>
              <div className='xs:flex items-center justify-center'>
                <a
                  className='xs:flex-shrink-0 group relative mb-3 xs:mb-0 xs:mr-8 h-10 flex xs:inline-flex w-full xs:w-auto items-center justify-center px-4 p-px text-sm font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
                  href='#'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span className='mr-2'>Get Started</span>
                  <span className='transform group-hover:translate-x-1 transition duration-200'>
                    <svg
                      width={17}
                      height={16}
                      viewBox='0 0 17 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M4.5 8H12.8333M12.8333 8L8.83334 4M12.8333 8L8.83334 12'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                </a>
                <a
                  className='flex xs:inline-flex items-center justify-center h-10 text-center text-sm font-semibold text-white hover:text-yellowGreen-600 rounded-lg transition duration-200'
                  href='#'
                >
                  Book a Demo
                </a>
              </div>
            </div>
            <div className='absolute z-10 top-0 left-0 mt-20 h-2.5 w-2.5 transform -translate-y-1/2 -translate-x-1/2 bg-gray-700 rounded-full' />
            <div className='absolute z-10 top-0 right-0 mt-20 h-2.5 w-2.5 transform -translate-y-1/2 translate-x-1/2 bg-gray-700 rounded-full' />
            <div className='absolute z-10 bottom-0 left-0 mb-20 h-2.5 w-2.5 transform translate-y-1/2 -translate-x-1/2 bg-gray-700 rounded-full' />
            <div className='absolute z-10 bottom-0 right-0 mb-20 h-2.5 w-2.5 transform translate-y-1/2 translate-x-1/2 bg-gray-700 rounded-full' />
            <div className='absolute bottom-0 mb-20 left-1/2 transform -translate-x-1/2 w-full h-px bg-gray-700' />
            <div className='absolute top-0 left-1/2 transform -translate-x-1/2 w-screen mt-20 h-px bg-gray-700' />
            <div className='absolute top-0 left-0 h-full w-px bg-gray-700' />
            <div className='absolute top-0 right-0 h-full w-px bg-gray-700' />
          </div>
        </section>
        <section className='relative py-24 bg-gray-900'>
          <img
            className='absolute top-0 left-1/2 -mb-5 sm:-mb-10 md:-mb-16 lg:-mb-28 transform -translate-x-1/2 opacity-20'
            src='aurora-assets/cta/light-green-top.png'
            alt=''
          />
          <div className='relative container px-4 mx-auto'>
            <div className='relative max-w-md mx-auto text-center'>
              <span className='inline-flex items-center h-6 mb-4 px-2 text-sm font-medium text-white bg-gray-800 rounded-full'>
                14-day free trial
              </span>
              <h1 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-4'>
                Try Aurora today
              </h1>
              <p className='text-gray-400 mb-10'>
                Make organizing your digital assets fun again!
              </p>
              <div className='xs:flex items-center'>
                <input
                  className='py-3 px-4 h-12 w-full mb-3 xs:mb-0 xs:mr-3 text-gray-400 placeholder-gray-400 bg-gray-800 border border-gray-700 focus:border-yellowGreen-800 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-800'
                  type='email'
                  placeholder='Enter your email address'
                />
                <Button variant="classic"
                  className='xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center px-5 h-12 font-bold text-gray-900 bg-yellowGreen-600 rounded-lg transition-all duration-300 focus:outline-none'
                  type='submit'
                >
                  <div className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-yellowGreen-900 animate-pulse group-hover:ring-0 transition duration-300' />
                  <span>Get Started</span>
                </Button>
              </div>
            </div>
          </div>
        </section>
      </>
    <FooterComponent /></React.Fragment>
  );
}

