import React from 'react';

import { Flex, Text, Button } from '@radix-ui/themes';


import {FooterComponent} from './Footers.jsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Content() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='relative py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-4xl mx-auto text-center mb-12'>
              <a
                className='inline-flex mb-12 items-center font-bold text-white hover:text-teal-600'
                href='#'
              >
                <svg
                  width={20}
                  height={20}
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M15.4167 10H5M5 10L10 5M5 10L10 15'
                    stroke='currentColor'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                <span className='ml-2'>Back to Blog</span>
              </a>
              <h2 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white'>
                How We Built AI-Collections: Wrapping Up
              </h2>
            </div>
            <img
              className='block mb-12 w-full object-cover rounded-xl'
              src='aurora-assets/content/folders.png'
              alt=''
            />
            <div className='max-w-4xl mx-auto'>
              <div className='flex mb-12 py-6 px-6 justify-between items-center border-t border-b border-teal-800'>
                <div className='flex items-center'>
                  <img
                    className='block w-12 h-12 rounded-full object-contain'
                    src='aurora-assets/blog/avatar-medium.png'
                    alt=''
                  />
                  <div className='ml-3'>
                    <span className='block text-base font-semibold text-white'>
                      Max Ranver
                    </span>
                    <span className='block text-sm font-medium text-gray-300'>
                      Content Writer, Aurora
                    </span>
                  </div>
                </div>
                <div className='flex items-center'>
                  <span className='inline-flex items-center h-6 px-2 text-sm text-gray-50 font-medium bg-gray-700 rounded-full'>
                    Product
                  </span>
                  <span className='inline-block mx-4'>
                    <svg
                      width={1}
                      height={24}
                      viewBox='0 0 1 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <line
                        x1='0.5'
                        y1='2.18558e-08'
                        x2='0.499999'
                        y2={24}
                        stroke='#3B5444'
                      />
                    </svg>
                  </span>
                  <span className='text-sm text-gray-400'>20 Jul 2023</span>
                  <span className='mx-3'>
                    <svg
                      width={4}
                      height={4}
                      viewBox='0 0 4 4'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <circle cx={2} cy={2} r={2} fill='#4B6354' />
                    </svg>
                  </span>
                  <span className='text-sm text-gray-400'>4 min read</span>
                </div>
              </div>
              <div className='mb-12'>
                <h4 className='text-3xl text-white font-bold mb-6'>
                  Introduction
                </h4>
                <p className='text-lg text-gray-300 mb-6'>
                  As we come to the end of our journey in building
                  AI-Collections, we reflect on the incredible experience and
                  the technological advancements that have shaped this
                  groundbreaking project. From its inception, our goal was to
                  create a sophisticated system that leverages the power of
                  artificial intelligence to curate and organize digital
                  collections seamlessly. The journey involved a dedicated team
                  of engineers, data scientists, and designers who collaborated
                  tirelessly to bring this vision to life. We'll delve into the
                  challenges we faced, the technologies we utilized, and the
                  exciting potential AI-Collections holds for the future.
                </p>
                <span className='block text-lg text-gray-300'>
                  1. Defining the Vision
                </span>
                <span className='block text-lg text-gray-300'>
                  2. Team Collaboration
                </span>
                <span className='block text-lg text-gray-300'>
                  3. Data Acquisition and Management
                </span>
              </div>
              <div className='mb-12'>
                <h4 className='text-3xl text-white font-bold mb-6'>
                  General content
                </h4>
                <p className='text-lg text-gray-300 mb-6'>
                  The development process was marked by countless hours of
                  research, prototyping, and iteration. We explored various
                  machine learning algorithms and deep neural network
                  architectures to ensure optimal performance and accuracy in
                  content classification. Through rigorous testing and
                  fine-tuning, we trained our AI model to recognize patterns,
                  categorize assets, and recommend relevant collections with
                  impressive precision.
                </p>
                <div className='flex mb-6'>
                  <span className='block mr-2 text-lg text-gray-300'>1.</span>
                  <div>
                    <p>
                      <span className='inline-block mr-1 text-lg text-white font-bold'>
                        Defining the Vision:
                      </span>
                      <span className='text-lg text-gray-300'>
                        We begin by discussing the initial vision behind
                        AI-Collections and the objectives we aimed to achieve.
                        We highlight the need for a sophisticated content
                        curation system that leverages AI capabilities to
                        enhance user experiences and streamline information
                        discovery.
                      </span>
                    </p>
                  </div>
                </div>
                <div className='flex mb-6'>
                  <span className='block mr-2 text-lg text-gray-300'>2.</span>
                  <div>
                    <p>
                      <span className='inline-block mr-1 text-lg text-white font-bold'>
                        Team Collaboration:
                      </span>
                      <span className='text-lg text-gray-300'>
                        Successful projects require effective teamwork. We
                        emphasize the importance of collaboration and detail how
                        our team of experts, including developers, data
                        scientists, and designers, worked together to bring
                        AI-Collections to life. We share insights on how we
                        fostered a creative and innovative environment
                        throughout the development process.
                      </span>
                    </p>
                  </div>
                </div>
                <div className='flex'>
                  <span className='block mr-2 text-lg text-gray-300'>3.</span>
                  <div>
                    <p>
                      <span className='inline-block mr-1 text-lg text-white font-bold'>
                        Data Acquisition and Management:
                      </span>
                      <span className='text-lg text-gray-300'>
                        Content demands a reliable and extensive dataset. We
                        provide an overview of the strategies we employed to
                        gather and manage the data required for AI-Collections.
                        This includes sourcing relevant content, data cleaning,
                        and ensuring data quality and diversity.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className='mb-12 pb-12 border-b border-teal-800'>
                <div className='mb-12'>
                  <h4 className='text-3xl text-white font-bold mb-6'>
                    Additional reading
                  </h4>
                  <p className='text-lg text-gray-300 mb-6'>
                    Design played a crucial role in the development of
                    AI-Collections. We wanted to create an intuitive user
                    interface that empowers users to effortlessly manage their
                    digital assets. Our design team conducted extensive user
                    research and feedback sessions to understand the pain points
                    and needs of content creators. The result is a sleek,
                    user-friendly interface that allows for seamless navigation,
                    quick bookmarking, and efficient organization of assets.
                  </p>
                  <p className='text-lg text-gray-300'>
                    Throughout the process, we prioritized data privacy and
                    security. We implemented robust encryption protocols and
                    strict access controls to ensure that sensitive user
                    information remains protected at all times. Building trust
                    with our users has been of utmost importance, and we are
                    committed to maintaining a secure environment for their
                    valuable assets.
                  </p>
                </div>
                <div className='mb-12'>
                  <h4 className='text-3xl text-white font-bold mb-6'>
                    Conclusion
                  </h4>
                  <p className='text-lg text-gray-300'>
                    As we wrap up the development phase, we're excited about the
                    potential AI-Collections holds for content creators,
                    marketers, and businesses alike. Our AI-powered solution
                    brings a new level of efficiency and productivity to digital
                    asset management, freeing up valuable time and resources for
                    creativity and innovation. We're grateful for the
                    opportunity to contribute to the ever-evolving landscape of
                    AI technology, and we look forward to seeing how
                    AI-Collections transforms the way assets are curated and
                    organized in the digital world.
                  </p>
                </div>
                <div className='py-4 px-6 border border-teal-700 bg-teal-700 rounded-lg'>
                  <span className='inline-block mr-2 text-lg text-white'>
                    Interested in more tips like this?
                  </span>
                  <a
                    className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-400 transition duration-100'
                    href='#'
                  >
                    <span className='mr-2'>Subscribe to our newsletter</span>
                    <span className='transform group-hover:translate-x-1 transition duration-100'>
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4.99984 10H15.4165M15.4165 10L10.4165 5M15.4165 10L10.4165 15'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
              <div className='flex items-center justify-center'>
                <a
                  className='group inline-flex mr-12 items-center text-white hover:text-yellowGreen-600'
                  href='#'
                >
                  <div className='flex items-center justify-center h-16 w-16 border border-gray-700 group-hover:border-yellowGreen-600 rounded-full'>
                    <svg
                      width={25}
                      height={24}
                      viewBox='0 0 25 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M16.9724 20H4.6C4.26863 20 4 19.7314 4 19.4V9.6C4 9.26863 4.26863 9 4.6 9H7.36762C8.07015 9 8.72116 8.6314 9.0826 8.02899L11.793 3.51161C12.3779 2.53688 13.7554 2.44422 14.4655 3.33186C14.8002 3.75025 14.9081 4.30635 14.7541 4.81956L13.7317 8.22759C13.6162 8.61256 13.9045 9 14.3064 9H18.8815C20.2002 9 21.158 10.254 20.811 11.5262L18.9019 18.5262C18.6646 19.3964 17.8743 20 16.9724 20Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                      />
                      <path
                        d='M7.5 20L7.5 9'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                  <span className='ml-3'>256</span>
                </a>
                <a
                  className='group inline-flex items-center text-white hover:text-yellowGreen-600'
                  href='#'
                >
                  <div className='flex items-center justify-center h-16 w-16 border border-gray-700 group-hover:border-yellowGreen-600 rounded-full'>
                    <svg
                      width={25}
                      height={24}
                      viewBox='0 0 25 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 13.8214 2.98697 15.5291 3.83782 17L3 21.5L7.5 20.6622C8.97087 21.513 10.6786 22 12.5 22Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                  <span className='ml-3'>10</span>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24'>
          <div className='container px-4 mx-auto'>
            <a
              className='inline-flex mb-12 items-center font-bold text-gray-500 hover:text-teal-600'
              href='#'
            >
              <svg
                width={20}
                height={20}
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M15.4167 10H5M5 10L10 5M5 10L10 15'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <span className='ml-2'>Back to Blog</span>
            </a>
            <img
              className='block mb-12 w-full object-cover rounded-xl'
              src='aurora-assets/content/folders.png'
              alt=''
            />
            <div className='max-w-4xl mx-auto'>
              <div className='flex mb-4 items-center justify-center'>
                <span className='inline-flex items-center h-6 px-2 text-sm text-gray-700 font-medium bg-gray-100 rounded-full'>
                  Product
                </span>
                <span className='inline-block mx-4'>
                  <svg
                    width={1}
                    height={24}
                    viewBox='0 0 1 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <line
                      x1='0.5'
                      y1='2.18558e-08'
                      x2='0.499999'
                      y2={24}
                      stroke='#E3E8E5'
                    />
                  </svg>
                </span>
                <span className='text-sm text-gray-500'>20 Jul 2023</span>
                <span className='mx-3'>
                  <svg
                    width={4}
                    height={4}
                    viewBox='0 0 4 4'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                  </svg>
                </span>
                <span className='text-sm text-gray-500'>4 min read</span>
              </div>
              <div className='mb-12 pb-12 text-center border-b border-gray-200'>
                <h2 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold'>
                  How We Built AI-Collections: Wrapping Up
                </h2>
              </div>
              <div className='mb-12'>
                <h4 className='text-3xl text-gray-900 font-bold mb-6'>
                  Introduction
                </h4>
                <p className='text-lg text-gray-700 mb-6'>
                  As we come to the end of our journey in building
                  AI-Collections, we reflect on the incredible experience and
                  the technological advancements that have shaped this
                  groundbreaking project. From its inception, our goal was to
                  create a sophisticated system that leverages the power of
                  artificial intelligence to curate and organize digital
                  collections seamlessly. The journey involved a dedicated team
                  of engineers, data scientists, and designers who collaborated
                  tirelessly to bring this vision to life. We'll delve into the
                  challenges we faced, the technologies we utilized, and the
                  exciting potential AI-Collections holds for the future.
                </p>
                <span className='block text-lg text-gray-700'>
                  1. Defining the Vision
                </span>
                <span className='block text-lg text-gray-700'>
                  2. Team Collaboration
                </span>
                <span className='block text-lg text-gray-700'>
                  3. Data Acquisition and Management
                </span>
              </div>
              <div className='mb-12'>
                <h4 className='text-3xl text-gray-900 font-bold mb-6'>
                  General content
                </h4>
                <p className='text-lg text-gray-700 mb-6'>
                  The development process was marked by countless hours of
                  research, prototyping, and iteration. We explored various
                  machine learning algorithms and deep neural network
                  architectures to ensure optimal performance and accuracy in
                  content classification. Through rigorous testing and
                  fine-tuning, we trained our AI model to recognize patterns,
                  categorize assets, and recommend relevant collections with
                  impressive precision.
                </p>
                <div className='flex mb-6'>
                  <span className='block mr-2 text-lg text-gray-700'>1.</span>
                  <div>
                    <p>
                      <span className='inline-block mr-1 text-lg text-gray-900 font-bold'>
                        Defining the Vision:
                      </span>
                      <span className='text-lg text-gray-700'>
                        We begin by discussing the initial vision behind
                        AI-Collections and the objectives we aimed to achieve.
                        We highlight the need for a sophisticated content
                        curation system that leverages AI capabilities to
                        enhance user experiences and streamline information
                        discovery.
                      </span>
                    </p>
                  </div>
                </div>
                <div className='flex mb-6'>
                  <span className='block mr-2 text-lg text-gray-700'>2.</span>
                  <div>
                    <p>
                      <span className='inline-block mr-1 text-lg text-gray-900 font-bold'>
                        Team Collaboration:
                      </span>
                      <span className='text-lg text-gray-700'>
                        Successful projects require effective teamwork. We
                        emphasize the importance of collaboration and detail how
                        our team of experts, including developers, data
                        scientists, and designers, worked together to bring
                        AI-Collections to life. We share insights on how we
                        fostered a creative and innovative environment
                        throughout the development process.
                      </span>
                    </p>
                  </div>
                </div>
                <div className='flex'>
                  <span className='block mr-2 text-lg text-gray-700'>3.</span>
                  <div>
                    <p>
                      <span className='inline-block mr-1 text-lg text-gray-900 font-bold'>
                        Data Acquisition and Management:
                      </span>
                      <span className='text-lg text-gray-700'>
                        Content demands a reliable and extensive dataset. We
                        provide an overview of the strategies we employed to
                        gather and manage the data required for AI-Collections.
                        This includes sourcing relevant content, data cleaning,
                        and ensuring data quality and diversity.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className='mb-12'>
                <h4 className='text-3xl text-gray-900 font-bold mb-6'>
                  Additional reading
                </h4>
                <p className='text-lg text-gray-700 mb-6'>
                  Design played a crucial role in the development of
                  AI-Collections. We wanted to create an intuitive user
                  interface that empowers users to effortlessly manage their
                  digital assets. Our design team conducted extensive user
                  research and feedback sessions to understand the pain points
                  and needs of content creators. The result is a sleek,
                  user-friendly interface that allows for seamless navigation,
                  quick bookmarking, and efficient organization of assets.
                </p>
                <p className='text-lg text-gray-700'>
                  Throughout the process, we prioritized data privacy and
                  security. We implemented robust encryption protocols and
                  strict access controls to ensure that sensitive user
                  information remains protected at all times. Building trust
                  with our users has been of utmost importance, and we are
                  committed to maintaining a secure environment for their
                  valuable assets.
                </p>
              </div>
              <div className='mb-12'>
                <h4 className='text-3xl text-gray-900 font-bold mb-6'>
                  Conclusion
                </h4>
                <p className='text-lg text-gray-700'>
                  As we wrap up the development phase, we're excited about the
                  potential AI-Collections holds for content creators,
                  marketers, and businesses alike. Our AI-powered solution
                  brings a new level of efficiency and productivity to digital
                  asset management, freeing up valuable time and resources for
                  creativity and innovation. We're grateful for the opportunity
                  to contribute to the ever-evolving landscape of AI technology,
                  and we look forward to seeing how AI-Collections transforms
                  the way assets are curated and organized in the digital world.
                </p>
              </div>
              <div className='py-4 px-6 border border-gray-200 bg-gray-50 rounded-lg'>
                <span className='inline-block mr-2 text-lg text-gray-700'>
                  Interested in more tips like this?
                </span>
                <a
                  className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600 transition duration-100'
                  href='#'
                >
                  <span className='mr-2'>Subscribe to our newsletter</span>
                  <span className='transform group-hover:translate-x-1 transition duration-100'>
                    <svg
                      width={20}
                      height={20}
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M4.99984 10H15.4165M15.4165 10L10.4165 5M15.4165 10L10.4165 15'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-gray-50'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-1/3 lg:pt-24 px-4'>
                <h4 className='font-bold text-gray-900 mb-5'>
                  TABLE OF CONTENTS
                </h4>
                <ul className='mb-12'>
                  <li className='mb-5'>
                    <a
                      className='inline-block px-6 text-lg font-medium text-teal-500'
                      href='#'
                    >
                      Introduction
                    </a>
                  </li>
                  <li className='mb-5'>
                    <a
                      className='inline-block px-6 text-lg font-medium text-gray-500 hover:text-teal-500'
                      href='#'
                    >
                      General content
                    </a>
                  </li>
                  <li className='mb-5'>
                    <a
                      className='inline-block px-6 text-lg font-medium text-gray-500 hover:text-teal-500'
                      href='#'
                    >
                      Additional reading
                    </a>
                  </li>
                  <li className='mb-5'>
                    <a
                      className='inline-block px-6 text-lg font-medium text-gray-500 hover:text-teal-500'
                      href='#'
                    >
                      Conclusion
                    </a>
                  </li>
                </ul>
              </div>
              <div className='w-full lg:w-2/3 px-4'>
                <a
                  className='inline-flex mb-12 items-center font-bold text-gray-500 hover:text-teal-600'
                  href='#'
                >
                  <svg
                    width={20}
                    height={20}
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M15.4167 10H5M5 10L10 5M5 10L10 15'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <span className='ml-2'>Back to Blog</span>
                </a>
                <img
                  className='block mb-12 w-full object-cover rounded-xl'
                  src='aurora-assets/content/folders-dark.png'
                  alt=''
                />
                <div className='flex mb-4 items-center'>
                  <span className='inline-flex items-center h-6 px-2 text-sm text-gray-700 font-medium bg-gray-100 rounded-full'>
                    Product
                  </span>
                  <span className='inline-block mx-4'>
                    <svg
                      width={1}
                      height={24}
                      viewBox='0 0 1 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <line
                        x1='0.5'
                        y1='2.18558e-08'
                        x2='0.499999'
                        y2={24}
                        stroke='#E3E8E5'
                      />
                    </svg>
                  </span>
                  <span className='text-sm text-gray-500'>20 Jul 2023</span>
                  <span className='mx-3'>
                    <svg
                      width={4}
                      height={4}
                      viewBox='0 0 4 4'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <circle cx={2} cy={2} r={2} fill='#D2DAD5' />
                    </svg>
                  </span>
                  <span className='text-sm text-gray-500'>4 min read</span>
                </div>
                <h2 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-12'>
                  How We Built AI-Collections: Wrapping Up
                </h2>
                <div className='mb-12'>
                  <h4 className='text-3xl text-gray-900 font-bold mb-6'>
                    Introduction
                  </h4>
                  <p className='text-lg text-gray-700 mb-6'>
                    As we come to the end of our journey in building
                    AI-Collections, we reflect on the incredible experience and
                    the technological advancements that have shaped this
                    groundbreaking project. From its inception, our goal was to
                    create a sophisticated system that leverages the power of
                    artificial intelligence to curate and organize digital
                    collections seamlessly. The journey involved a dedicated
                    team of engineers, data scientists, and designers who
                    collaborated tirelessly to bring this vision to life. We'll
                    delve into the challenges we faced, the technologies we
                    utilized, and the exciting potential AI-Collections holds
                    for the future.
                  </p>
                  <span className='block text-lg text-gray-700'>
                    1. Defining the Vision
                  </span>
                  <span className='block text-lg text-gray-700'>
                    2. Team Collaboration
                  </span>
                  <span className='block text-lg text-gray-700'>
                    3. Data Acquisition and Management
                  </span>
                </div>
                <div className='mb-12'>
                  <h4 className='text-3xl text-gray-900 font-bold mb-6'>
                    General content
                  </h4>
                  <p className='text-lg text-gray-700 mb-6'>
                    The development process was marked by countless hours of
                    research, prototyping, and iteration. We explored various
                    machine learning algorithms and deep neural network
                    architectures to ensure optimal performance and accuracy in
                    content classification. Through rigorous testing and
                    fine-tuning, we trained our AI model to recognize patterns,
                    categorize assets, and recommend relevant collections with
                    impressive precision.
                  </p>
                  <div className='flex mb-6'>
                    <span className='block mr-2 text-lg text-gray-700'>1.</span>
                    <div>
                      <p>
                        <span className='inline-block mr-1 text-lg text-gray-900 font-bold'>
                          Defining the Vision:
                        </span>
                        <span className='text-lg text-gray-700'>
                          We begin by discussing the initial vision behind
                          AI-Collections and the objectives we aimed to achieve.
                          We highlight the need for a sophisticated content
                          curation system that leverages AI capabilities to
                          enhance user experiences and streamline information
                          discovery.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='flex mb-6'>
                    <span className='block mr-2 text-lg text-gray-700'>2.</span>
                    <div>
                      <p>
                        <span className='inline-block mr-1 text-lg text-gray-900 font-bold'>
                          Team Collaboration:
                        </span>
                        <span className='text-lg text-gray-700'>
                          Successful projects require effective teamwork. We
                          emphasize the importance of collaboration and detail
                          how our team of experts, including developers, data
                          scientists, and designers, worked together to bring
                          AI-Collections to life. We share insights on how we
                          fostered a creative and innovative environment
                          throughout the development process.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='flex'>
                    <span className='block mr-2 text-lg text-gray-700'>3.</span>
                    <div>
                      <p>
                        <span className='inline-block mr-1 text-lg text-gray-900 font-bold'>
                          Data Acquisition and Management:
                        </span>
                        <span className='text-lg text-gray-700'>
                          Content demands a reliable and extensive dataset. We
                          provide an overview of the strategies we employed to
                          gather and manage the data required for
                          AI-Collections. This includes sourcing relevant
                          content, data cleaning, and ensuring data quality and
                          diversity.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='mb-12 pb-12 border-b border-gray-200'>
                  <div className='mb-12'>
                    <h4 className='text-3xl text-gray-900 font-bold mb-6'>
                      Additional reading
                    </h4>
                    <p className='text-lg text-gray-700 mb-6'>
                      Design played a crucial role in the development of
                      AI-Collections. We wanted to create an intuitive user
                      interface that empowers users to effortlessly manage their
                      digital assets. Our design team conducted extensive user
                      research and feedback sessions to understand the pain
                      points and needs of content creators. The result is a
                      sleek, user-friendly interface that allows for seamless
                      navigation, quick bookmarking, and efficient organization
                      of assets.
                    </p>
                    <p className='text-lg text-gray-700'>
                      Throughout the process, we prioritized data privacy and
                      security. We implemented robust encryption protocols and
                      strict access controls to ensure that sensitive user
                      information remains protected at all times. Building trust
                      with our users has been of utmost importance, and we are
                      committed to maintaining a secure environment for their
                      valuable assets.
                    </p>
                  </div>
                  <div className='mb-12'>
                    <h4 className='text-3xl text-gray-900 font-bold mb-6'>
                      Conclusion
                    </h4>
                    <p className='text-lg text-gray-700'>
                      As we wrap up the development phase, we're excited about
                      the potential AI-Collections holds for content creators,
                      marketers, and businesses alike. Our AI-powered solution
                      brings a new level of efficiency and productivity to
                      digital asset management, freeing up valuable time and
                      resources for creativity and innovation. We're grateful
                      for the opportunity to contribute to the ever-evolving
                      landscape of AI technology, and we look forward to seeing
                      how AI-Collections transforms the way assets are curated
                      and organized in the digital world.
                    </p>
                  </div>
                  <div className='py-4 px-6 border border-gray-200 bg-gray-100 rounded-lg'>
                    <span className='inline-block mr-2 text-lg text-gray-700'>
                      Interested in more tips like this?
                    </span>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-700 hover:text-yellowGreen-600 transition duration-100'
                      href='#'
                    >
                      <span className='mr-2'>Subscribe to our newsletter</span>
                      <span className='transform group-hover:translate-x-1 transition duration-100'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10H15.4165M15.4165 10L10.4165 5M15.4165 10L10.4165 15'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <div className='flex items-center justify-center'>
                  <a
                    className='group inline-flex mr-12 items-center text-gray-900 hover:text-yellowGreen-600'
                    href='#'
                  >
                    <div className='flex items-center justify-center h-16 w-16 border border-gray-200 group-hover:border-yellowGreen-600 rounded-full'>
                      <svg
                        width={25}
                        height={24}
                        viewBox='0 0 25 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M16.9724 20H4.6C4.26863 20 4 19.7314 4 19.4V9.6C4 9.26863 4.26863 9 4.6 9H7.36762C8.07015 9 8.72116 8.6314 9.0826 8.02899L11.793 3.51161C12.3779 2.53688 13.7554 2.44422 14.4655 3.33186C14.8002 3.75025 14.9081 4.30635 14.7541 4.81956L13.7317 8.22759C13.6162 8.61256 13.9045 9 14.3064 9H18.8815C20.2002 9 21.158 10.254 20.811 11.5262L18.9019 18.5262C18.6646 19.3964 17.8743 20 16.9724 20Z'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                        />
                        <path
                          d='M7.5 20L7.5 9'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                    <span className='ml-3'>256</span>
                  </a>
                  <a
                    className='group inline-flex items-center text-gray-900 hover:text-yellowGreen-600'
                    href='#'
                  >
                    <div className='flex items-center justify-center h-16 w-16 border border-gray-200 group-hover:border-yellowGreen-600 rounded-full'>
                      <svg
                        width={25}
                        height={24}
                        viewBox='0 0 25 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 13.8214 2.98697 15.5291 3.83782 17L3 21.5L7.5 20.6622C8.97087 21.513 10.6786 22 12.5 22Z'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                    <span className='ml-3'>10</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-teal-900'>
          <div className='container px-4 mx-auto'>
            <a
              className='inline-flex mb-12 items-center font-bold text-white hover:text-teal-600'
              href='#'
            >
              <svg
                width={20}
                height={20}
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M15.4167 10H5M5 10L10 5M5 10L10 15'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <span className='ml-2'>Back to Blog</span>
            </a>
            <img
              className='block mb-12 w-full object-cover rounded-xl'
              src='aurora-assets/content/folders.png'
              alt=''
            />
            <div className='max-w-4xl mx-auto'>
              <div className='flex mb-4 items-center'>
                <span className='inline-flex items-center h-6 px-2 text-sm text-gray-50 font-medium bg-gray-700 rounded-full'>
                  Product
                </span>
                <span className='inline-block mx-4'>
                  <svg
                    width={1}
                    height={24}
                    viewBox='0 0 1 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <line
                      x1='0.5'
                      y1='2.18558e-08'
                      x2='0.499999'
                      y2={24}
                      stroke='#3B5444'
                    />
                  </svg>
                </span>
                <span className='text-sm text-gray-400'>20 Jul 2023</span>
                <span className='mx-3'>
                  <svg
                    width={4}
                    height={4}
                    viewBox='0 0 4 4'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle cx={2} cy={2} r={2} fill='#4B6354' />
                  </svg>
                </span>
                <span className='text-sm text-gray-400'>4 min read</span>
              </div>
              <h2 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-12'>
                How We Built AI-Collections: Wrapping Up
              </h2>
              <div className='mb-12'>
                <h4 className='text-3xl text-white font-bold mb-6'>
                  Introduction
                </h4>
                <p className='text-lg text-gray-300 mb-6'>
                  As we come to the end of our journey in building
                  AI-Collections, we reflect on the incredible experience and
                  the technological advancements that have shaped this
                  groundbreaking project. From its inception, our goal was to
                  create a sophisticated system that leverages the power of
                  artificial intelligence to curate and organize digital
                  collections seamlessly. The journey involved a dedicated team
                  of engineers, data scientists, and designers who collaborated
                  tirelessly to bring this vision to life. We'll delve into the
                  challenges we faced, the technologies we utilized, and the
                  exciting potential AI-Collections holds for the future.
                </p>
                <span className='block text-lg text-gray-300'>
                  1. Defining the Vision
                </span>
                <span className='block text-lg text-gray-300'>
                  2. Team Collaboration
                </span>
                <span className='block text-lg text-gray-300'>
                  3. Data Acquisition and Management
                </span>
              </div>
              <div className='mb-12'>
                <h4 className='text-3xl text-white font-bold mb-6'>
                  General content
                </h4>
                <p className='text-lg text-gray-300 mb-6'>
                  The development process was marked by countless hours of
                  research, prototyping, and iteration. We explored various
                  machine learning algorithms and deep neural network
                  architectures to ensure optimal performance and accuracy in
                  content classification. Through rigorous testing and
                  fine-tuning, we trained our AI model to recognize patterns,
                  categorize assets, and recommend relevant collections with
                  impressive precision.
                </p>
                <div className='flex mb-6'>
                  <span className='block mr-2 text-lg text-gray-300'>1.</span>
                  <div>
                    <p>
                      <span className='inline-block mr-1 text-lg text-white font-bold'>
                        Defining the Vision:
                      </span>
                      <span className='text-lg text-gray-300'>
                        We begin by discussing the initial vision behind
                        AI-Collections and the objectives we aimed to achieve.
                        We highlight the need for a sophisticated content
                        curation system that leverages AI capabilities to
                        enhance user experiences and streamline information
                        discovery.
                      </span>
                    </p>
                  </div>
                </div>
                <div className='flex mb-6'>
                  <span className='block mr-2 text-lg text-gray-300'>2.</span>
                  <div>
                    <p>
                      <span className='inline-block mr-1 text-lg text-white font-bold'>
                        Team Collaboration:
                      </span>
                      <span className='text-lg text-gray-300'>
                        Successful projects require effective teamwork. We
                        emphasize the importance of collaboration and detail how
                        our team of experts, including developers, data
                        scientists, and designers, worked together to bring
                        AI-Collections to life. We share insights on how we
                        fostered a creative and innovative environment
                        throughout the development process.
                      </span>
                    </p>
                  </div>
                </div>
                <div className='flex'>
                  <span className='block mr-2 text-lg text-gray-300'>3.</span>
                  <div>
                    <p>
                      <span className='inline-block mr-1 text-lg text-white font-bold'>
                        Data Acquisition and Management:
                      </span>
                      <span className='text-lg text-gray-300'>
                        Content demands a reliable and extensive dataset. We
                        provide an overview of the strategies we employed to
                        gather and manage the data required for AI-Collections.
                        This includes sourcing relevant content, data cleaning,
                        and ensuring data quality and diversity.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className='mb-12 pb-12 border-b border-teal-800'>
                <div className='mb-12'>
                  <h4 className='text-3xl text-white font-bold mb-6'>
                    Additional reading
                  </h4>
                  <p className='text-lg text-gray-300 mb-6'>
                    Design played a crucial role in the development of
                    AI-Collections. We wanted to create an intuitive user
                    interface that empowers users to effortlessly manage their
                    digital assets. Our design team conducted extensive user
                    research and feedback sessions to understand the pain points
                    and needs of content creators. The result is a sleek,
                    user-friendly interface that allows for seamless navigation,
                    quick bookmarking, and efficient organization of assets.
                  </p>
                  <p className='text-lg text-gray-300'>
                    Throughout the process, we prioritized data privacy and
                    security. We implemented robust encryption protocols and
                    strict access controls to ensure that sensitive user
                    information remains protected at all times. Building trust
                    with our users has been of utmost importance, and we are
                    committed to maintaining a secure environment for their
                    valuable assets.
                  </p>
                </div>
                <div className='mb-12'>
                  <h4 className='text-3xl text-white font-bold mb-6'>
                    Conclusion
                  </h4>
                  <p className='text-lg text-gray-300'>
                    As we wrap up the development phase, we're excited about the
                    potential AI-Collections holds for content creators,
                    marketers, and businesses alike. Our AI-powered solution
                    brings a new level of efficiency and productivity to digital
                    asset management, freeing up valuable time and resources for
                    creativity and innovation. We're grateful for the
                    opportunity to contribute to the ever-evolving landscape of
                    AI technology, and we look forward to seeing how
                    AI-Collections transforms the way assets are curated and
                    organized in the digital world.
                  </p>
                </div>
                <div className='py-4 px-6 border border-teal-700 bg-teal-700 rounded-lg'>
                  <span className='inline-block mr-2 text-lg text-white'>
                    Interested in more tips like this?
                  </span>
                  <a
                    className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-400 transition duration-100'
                    href='#'
                  >
                    <span className='mr-2'>Subscribe to our newsletter</span>
                    <span className='transform group-hover:translate-x-1 transition duration-100'>
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4.99984 10H15.4165M15.4165 10L10.4165 5M15.4165 10L10.4165 15'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <img
                  className='block w-16 mb-4 mx-auto'
                  src='aurora-assets/content/avatar-man.png'
                  alt=''
                />
                <span className='block text-lg font-semibold text-white'>
                  Max Ranver
                </span>
                <span className='block text-base font-medium text-gray-300'>
                  Content Writer, Aurora
                </span>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-4xl mx-auto text-center mb-12'>
              <a
                className='inline-flex mb-12 items-center font-bold text-white hover:text-teal-600'
                href='#'
              >
                <svg
                  width={20}
                  height={20}
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M15.4167 10H5M5 10L10 5M5 10L10 15'
                    stroke='currentColor'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                <span className='ml-2'>Back to Blog</span>
              </a>
              <h2 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white'>
                How We Built AI-Collections: Wrapping Up
              </h2>
            </div>
            <img
              className='block mb-12 w-full object-cover rounded-xl'
              src='aurora-assets/content/folders.png'
              alt=''
            />
            <div className='max-w-4xl mx-auto'>
              <div className='flex mb-12 py-6 px-6 justify-between items-center border-t border-b border-white border-opacity-20'>
                <div className='flex items-center'>
                  <img
                    className='block w-12 h-12 rounded-full object-contain'
                    src='aurora-assets/blog/avatar-medium.png'
                    alt=''
                  />
                  <div className='ml-3'>
                    <span className='block text-base font-semibold text-white'>
                      Max Ranver
                    </span>
                    <span className='block text-sm font-medium text-gray-300'>
                      Content Writer, Aurora
                    </span>
                  </div>
                </div>
                <div className='flex items-center'>
                  <span className='inline-flex items-center h-6 px-2 text-sm text-gray-50 font-medium bg-gray-700 rounded-full'>
                    Product
                  </span>
                  <span className='inline-block mx-4'>
                    <svg
                      width={1}
                      height={24}
                      viewBox='0 0 1 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <line
                        x1='0.5'
                        y1='2.18558e-08'
                        x2='0.499999'
                        y2={24}
                        stroke='#3B5444'
                      />
                    </svg>
                  </span>
                  <span className='text-sm text-gray-400'>20 Jul 2023</span>
                  <span className='mx-3'>
                    <svg
                      width={4}
                      height={4}
                      viewBox='0 0 4 4'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <circle cx={2} cy={2} r={2} fill='#4B6354' />
                    </svg>
                  </span>
                  <span className='text-sm text-gray-400'>4 min read</span>
                </div>
              </div>
              <div className='mb-12'>
                <h4 className='text-3xl text-white font-bold mb-6'>
                  Introduction
                </h4>
                <p className='text-lg text-gray-300 mb-6'>
                  As we come to the end of our journey in building
                  AI-Collections, we reflect on the incredible experience and
                  the technological advancements that have shaped this
                  groundbreaking project. From its inception, our goal was to
                  create a sophisticated system that leverages the power of
                  artificial intelligence to curate and organize digital
                  collections seamlessly. The journey involved a dedicated team
                  of engineers, data scientists, and designers who collaborated
                  tirelessly to bring this vision to life. We'll delve into the
                  challenges we faced, the technologies we utilized, and the
                  exciting potential AI-Collections holds for the future.
                </p>
                <span className='block text-lg text-gray-300'>
                  1. Defining the Vision
                </span>
                <span className='block text-lg text-gray-300'>
                  2. Team Collaboration
                </span>
                <span className='block text-lg text-gray-300'>
                  3. Data Acquisition and Management
                </span>
              </div>
              <div className='mb-12'>
                <h4 className='text-3xl text-white font-bold mb-6'>
                  General content
                </h4>
                <p className='text-lg text-gray-300 mb-6'>
                  The development process was marked by countless hours of
                  research, prototyping, and iteration. We explored various
                  machine learning algorithms and deep neural network
                  architectures to ensure optimal performance and accuracy in
                  content classification. Through rigorous testing and
                  fine-tuning, we trained our AI model to recognize patterns,
                  categorize assets, and recommend relevant collections with
                  impressive precision.
                </p>
                <div className='flex mb-6'>
                  <span className='block mr-2 text-lg text-gray-300'>1.</span>
                  <div>
                    <p>
                      <span className='inline-block mr-1 text-lg text-white font-bold'>
                        Defining the Vision:
                      </span>
                      <span className='text-lg text-gray-300'>
                        We begin by discussing the initial vision behind
                        AI-Collections and the objectives we aimed to achieve.
                        We highlight the need for a sophisticated content
                        curation system that leverages AI capabilities to
                        enhance user experiences and streamline information
                        discovery.
                      </span>
                    </p>
                  </div>
                </div>
                <div className='flex mb-6'>
                  <span className='block mr-2 text-lg text-gray-300'>2.</span>
                  <div>
                    <p>
                      <span className='inline-block mr-1 text-lg text-white font-bold'>
                        Team Collaboration:
                      </span>
                      <span className='text-lg text-gray-300'>
                        Successful projects require effective teamwork. We
                        emphasize the importance of collaboration and detail how
                        our team of experts, including developers, data
                        scientists, and designers, worked together to bring
                        AI-Collections to life. We share insights on how we
                        fostered a creative and innovative environment
                        throughout the development process.
                      </span>
                    </p>
                  </div>
                </div>
                <div className='flex'>
                  <span className='block mr-2 text-lg text-gray-300'>3.</span>
                  <div>
                    <p>
                      <span className='inline-block mr-1 text-lg text-white font-bold'>
                        Data Acquisition and Management:
                      </span>
                      <span className='text-lg text-gray-300'>
                        Content demands a reliable and extensive dataset. We
                        provide an overview of the strategies we employed to
                        gather and manage the data required for AI-Collections.
                        This includes sourcing relevant content, data cleaning,
                        and ensuring data quality and diversity.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className='mb-12 pb-12 border-b border-white border-opacity-20'>
                <div className='mb-12'>
                  <h4 className='text-3xl text-white font-bold mb-6'>
                    Additional reading
                  </h4>
                  <p className='text-lg text-gray-300 mb-6'>
                    Design played a crucial role in the development of
                    AI-Collections. We wanted to create an intuitive user
                    interface that empowers users to effortlessly manage their
                    digital assets. Our design team conducted extensive user
                    research and feedback sessions to understand the pain points
                    and needs of content creators. The result is a sleek,
                    user-friendly interface that allows for seamless navigation,
                    quick bookmarking, and efficient organization of assets.
                  </p>
                  <p className='text-lg text-gray-300'>
                    Throughout the process, we prioritized data privacy and
                    security. We implemented robust encryption protocols and
                    strict access controls to ensure that sensitive user
                    information remains protected at all times. Building trust
                    with our users has been of utmost importance, and we are
                    committed to maintaining a secure environment for their
                    valuable assets.
                  </p>
                </div>
                <div className='mb-12'>
                  <h4 className='text-3xl text-white font-bold mb-6'>
                    Conclusion
                  </h4>
                  <p className='text-lg text-gray-300'>
                    As we wrap up the development phase, we're excited about the
                    potential AI-Collections holds for content creators,
                    marketers, and businesses alike. Our AI-powered solution
                    brings a new level of efficiency and productivity to digital
                    asset management, freeing up valuable time and resources for
                    creativity and innovation. We're grateful for the
                    opportunity to contribute to the ever-evolving landscape of
                    AI technology, and we look forward to seeing how
                    AI-Collections transforms the way assets are curated and
                    organized in the digital world.
                  </p>
                </div>
                <div className='py-4 px-6 border border-white border-opacity-20 bg-teal-700 rounded-lg'>
                  <span className='inline-block mr-2 text-lg text-white'>
                    Interested in more tips like this?
                  </span>
                  <a
                    className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-400 transition duration-100'
                    href='#'
                  >
                    <span className='mr-2'>Subscribe to our newsletter</span>
                    <span className='transform group-hover:translate-x-1 transition duration-100'>
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4.99984 10H15.4165M15.4165 10L10.4165 5M15.4165 10L10.4165 15'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
              <div className='flex items-center justify-center'>
                <a
                  className='group inline-flex mr-12 items-center text-white hover:text-yellowGreen-600'
                  href='#'
                >
                  <div className='flex items-center justify-center h-16 w-16 border border-white border-opacity-20 group-hover:border-yellowGreen-600 rounded-full'>
                    <svg
                      width={25}
                      height={24}
                      viewBox='0 0 25 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M16.9724 20H4.6C4.26863 20 4 19.7314 4 19.4V9.6C4 9.26863 4.26863 9 4.6 9H7.36762C8.07015 9 8.72116 8.6314 9.0826 8.02899L11.793 3.51161C12.3779 2.53688 13.7554 2.44422 14.4655 3.33186C14.8002 3.75025 14.9081 4.30635 14.7541 4.81956L13.7317 8.22759C13.6162 8.61256 13.9045 9 14.3064 9H18.8815C20.2002 9 21.158 10.254 20.811 11.5262L18.9019 18.5262C18.6646 19.3964 17.8743 20 16.9724 20Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                      />
                      <path
                        d='M7.5 20L7.5 9'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                  <span className='ml-3'>256</span>
                </a>
                <a
                  className='group inline-flex items-center text-white hover:text-yellowGreen-600'
                  href='#'
                >
                  <div className='flex items-center justify-center h-16 w-16 border border-white border-opacity-20 group-hover:border-yellowGreen-600 rounded-full'>
                    <svg
                      width={25}
                      height={24}
                      viewBox='0 0 25 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 13.8214 2.98697 15.5291 3.83782 17L3 21.5L7.5 20.6622C8.97087 21.513 10.6786 22 12.5 22Z'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                  <span className='ml-3'>10</span>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-radial-dark-green'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-1/3 px-4 mb-12 lg:mb-0'>
                <a
                  className='inline-flex items-center font-bold text-white hover:text-teal-600'
                  href='#'
                >
                  <svg
                    width={20}
                    height={20}
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M15.4167 10H5M5 10L10 5M5 10L10 15'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <span className='ml-2'>Back to Blog</span>
                </a>
              </div>
              <div className='w-full lg:w-2/3 px-4 pb-16'>
                <div className='flex mb-4 items-center'>
                  <span className='text-base text-gray-400'>20 Jul 2023</span>
                  <span className='mx-3'>
                    <svg
                      width={4}
                      height={4}
                      viewBox='0 0 4 4'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <circle cx={2} cy={2} r={2} fill='#4B6354' />
                    </svg>
                  </span>
                  <span className='text-base text-gray-400'>4 min read</span>
                </div>
                <h2 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-8'>
                  How We Built AI-Collections: Wrapping Up
                </h2>
                <div className='flex items-center'>
                  <img
                    className='block w-12 h-12 rounded-full object-cover'
                    src='aurora-assets/blog/avatar-medium.png'
                    alt=''
                  />
                  <div className='ml-2'>
                    <span className='block text-base font-semibold text-white'>
                      Max Ranver
                    </span>
                    <span className='block text-sm font-medium text-gray-400'>
                      Content Writer, Aurora
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full lg:w-1/3 px-4 mb-12 lg:mb-0'>
                <h4 className='font-bold text-white mb-5'>TABLE OF CONTENTS</h4>
                <ul>
                  <li className='mb-5'>
                    <a
                      className='inline-block px-6 text-lg font-medium text-yellowGreen-600'
                      href='#'
                    >
                      Introduction
                    </a>
                  </li>
                  <li className='mb-5'>
                    <a
                      className='inline-block px-6 text-lg font-medium text-gray-400 hover:text-yellowGreen-600'
                      href='#'
                    >
                      General content
                    </a>
                  </li>
                  <li className='mb-5'>
                    <a
                      className='inline-block px-6 text-lg font-medium text-gray-400 hover:text-yellowGreen-600'
                      href='#'
                    >
                      Additional reading
                    </a>
                  </li>
                  <li className='mb-5'>
                    <a
                      className='inline-block px-6 text-lg font-medium text-gray-400 hover:text-yellowGreen-600'
                      href='#'
                    >
                      Conclusion
                    </a>
                  </li>
                </ul>
              </div>
              <div className='w-full lg:w-2/3 px-4'>
                <div className='mb-12 pb-12 border-b border-white border-opacity-20'>
                  <img
                    className='block mb-16 w-full object-cover rounded-xl'
                    src='aurora-assets/content/folders.png'
                    alt=''
                  />
                  <div className='mb-12'>
                    <h4 className='text-3xl text-white font-bold mb-6'>
                      Introduction
                    </h4>
                    <p className='text-lg text-gray-300 mb-6'>
                      As we come to the end of our journey in building
                      AI-Collections, we reflect on the incredible experience
                      and the technological advancements that have shaped this
                      groundbreaking project. From its inception, our goal was
                      to create a sophisticated system that leverages the power
                      of artificial intelligence to curate and organize digital
                      collections seamlessly. The journey involved a dedicated
                      team of engineers, data scientists, and designers who
                      collaborated tirelessly to bring this vision to life.
                      We'll delve into the challenges we faced, the technologies
                      we utilized, and the exciting potential AI-Collections
                      holds for the future.
                    </p>
                    <span className='block text-lg text-gray-300'>
                      1. Defining the Vision
                    </span>
                    <span className='block text-lg text-gray-300'>
                      2. Team Collaboration
                    </span>
                    <span className='block text-lg text-gray-300'>
                      3. Data Acquisition and Management
                    </span>
                  </div>
                  <div className='mb-12'>
                    <h4 className='text-3xl text-white font-bold mb-6'>
                      General content
                    </h4>
                    <p className='text-lg text-gray-300 mb-6'>
                      The development process was marked by countless hours of
                      research, prototyping, and iteration. We explored various
                      machine learning algorithms and deep neural network
                      architectures to ensure optimal performance and accuracy
                      in content classification. Through rigorous testing and
                      fine-tuning, we trained our AI model to recognize
                      patterns, categorize assets, and recommend relevant
                      collections with impressive precision.
                    </p>
                    <div className='flex mb-6'>
                      <span className='block mr-2 text-lg text-gray-300'>
                        1.
                      </span>
                      <div>
                        <p>
                          <span className='inline-block mr-1 text-lg text-white font-bold'>
                            Defining the Vision:
                          </span>
                          <span className='text-lg text-gray-300'>
                            We begin by discussing the initial vision behind
                            AI-Collections and the objectives we aimed to
                            achieve. We highlight the need for a sophisticated
                            content curation system that leverages AI
                            capabilities to enhance user experiences and
                            streamline information discovery.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className='flex mb-6'>
                      <span className='block mr-2 text-lg text-gray-300'>
                        2.
                      </span>
                      <div>
                        <p>
                          <span className='inline-block mr-1 text-lg text-white font-bold'>
                            Team Collaboration:
                          </span>
                          <span className='text-lg text-gray-300'>
                            Successful projects require effective teamwork. We
                            emphasize the importance of collaboration and detail
                            how our team of experts, including developers, data
                            scientists, and designers, worked together to bring
                            AI-Collections to life. We share insights on how we
                            fostered a creative and innovative environment
                            throughout the development process.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className='flex'>
                      <span className='block mr-2 text-lg text-gray-300'>
                        3.
                      </span>
                      <div>
                        <p>
                          <span className='inline-block mr-1 text-lg text-white font-bold'>
                            Data Acquisition and Management:
                          </span>
                          <span className='text-lg text-gray-300'>
                            Content demands a reliable and extensive dataset. We
                            provide an overview of the strategies we employed to
                            gather and manage the data required for
                            AI-Collections. This includes sourcing relevant
                            content, data cleaning, and ensuring data quality
                            and diversity.
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='mb-12'>
                    <h4 className='text-3xl text-white font-bold mb-6'>
                      Additional reading
                    </h4>
                    <p className='text-lg text-gray-300 mb-6'>
                      Design played a crucial role in the development of
                      AI-Collections. We wanted to create an intuitive user
                      interface that empowers users to effortlessly manage their
                      digital assets. Our design team conducted extensive user
                      research and feedback sessions to understand the pain
                      points and needs of content creators. The result is a
                      sleek, user-friendly interface that allows for seamless
                      navigation, quick bookmarking, and efficient organization
                      of assets.
                    </p>
                    <p className='text-lg text-gray-300'>
                      Throughout the process, we prioritized data privacy and
                      security. We implemented robust encryption protocols and
                      strict access controls to ensure that sensitive user
                      information remains protected at all times. Building trust
                      with our users has been of utmost importance, and we are
                      committed to maintaining a secure environment for their
                      valuable assets.
                    </p>
                  </div>
                  <div className='mb-12'>
                    <h4 className='text-3xl text-white font-bold mb-6'>
                      Conclusion
                    </h4>
                    <p className='text-lg text-gray-300'>
                      As we wrap up the development phase, we're excited about
                      the potential AI-Collections holds for content creators,
                      marketers, and businesses alike. Our AI-powered solution
                      brings a new level of efficiency and productivity to
                      digital asset management, freeing up valuable time and
                      resources for creativity and innovation. We're grateful
                      for the opportunity to contribute to the ever-evolving
                      landscape of AI technology, and we look forward to seeing
                      how AI-Collections transforms the way assets are curated
                      and organized in the digital world.
                    </p>
                  </div>
                  <div className='py-4 px-6 border border-white border-opacity-20 bg-teal-700 rounded-lg'>
                    <span className='inline-block mr-2 text-lg text-white'>
                      Interested in more tips like this?
                    </span>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-400 transition duration-100'
                      href='#'
                    >
                      <span className='mr-2'>Subscribe to our newsletter</span>
                      <span className='transform group-hover:translate-x-1 transition duration-100'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10H15.4165M15.4165 10L10.4165 5M15.4165 10L10.4165 15'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <div className='flex items-center justify-center'>
                  <a
                    className='group inline-flex mr-12 items-center text-white hover:text-yellowGreen-600'
                    href='#'
                  >
                    <div className='flex items-center justify-center h-16 w-16 border border-white border-opacity-20 group-hover:border-yellowGreen-600 rounded-full'>
                      <svg
                        width={25}
                        height={24}
                        viewBox='0 0 25 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M16.9724 20H4.6C4.26863 20 4 19.7314 4 19.4V9.6C4 9.26863 4.26863 9 4.6 9H7.36762C8.07015 9 8.72116 8.6314 9.0826 8.02899L11.793 3.51161C12.3779 2.53688 13.7554 2.44422 14.4655 3.33186C14.8002 3.75025 14.9081 4.30635 14.7541 4.81956L13.7317 8.22759C13.6162 8.61256 13.9045 9 14.3064 9H18.8815C20.2002 9 21.158 10.254 20.811 11.5262L18.9019 18.5262C18.6646 19.3964 17.8743 20 16.9724 20Z'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                        />
                        <path
                          d='M7.5 20L7.5 9'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                    <span className='ml-3'>256</span>
                  </a>
                  <a
                    className='group inline-flex items-center text-white hover:text-yellowGreen-600'
                    href='#'
                  >
                    <div className='flex items-center justify-center h-16 w-16 border border-white border-opacity-20 group-hover:border-yellowGreen-600 rounded-full'>
                      <svg
                        width={25}
                        height={24}
                        viewBox='0 0 25 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 13.8214 2.98697 15.5291 3.83782 17L3 21.5L7.5 20.6622C8.97087 21.513 10.6786 22 12.5 22Z'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                    <span className='ml-3'>10</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-1/3 px-4 mb-12 lg:mb-0'>
                <a
                  className='inline-flex items-center font-bold text-white hover:text-teal-600'
                  href='#'
                >
                  <svg
                    width={20}
                    height={20}
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M15.4167 10H5M5 10L10 5M5 10L10 15'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <span className='ml-2'>Back to Blog</span>
                </a>
              </div>
              <div className='w-full lg:w-2/3 px-4 pb-16'>
                <div className='flex mb-4 items-center'>
                  <span className='text-base text-gray-400'>20 Jul 2023</span>
                  <span className='mx-3'>
                    <svg
                      width={4}
                      height={4}
                      viewBox='0 0 4 4'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <circle cx={2} cy={2} r={2} fill='#4B6354' />
                    </svg>
                  </span>
                  <span className='text-base text-gray-400'>4 min read</span>
                </div>
                <h2 className='font-heading tracking-tight text-4xl sm:text-5xl font-bold text-white mb-8'>
                  How We Built AI-Collections: Wrapping Up
                </h2>
                <div className='flex items-center'>
                  <img
                    className='block w-12 h-12 rounded-full object-cover'
                    src='aurora-assets/blog/avatar-medium.png'
                    alt=''
                  />
                  <div className='ml-2'>
                    <span className='block text-base font-semibold text-white'>
                      Max Ranver
                    </span>
                    <span className='block text-sm font-medium text-gray-400'>
                      Content Writer, Aurora
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full lg:w-1/3 px-4 mb-12 lg:mb-0'>
                <h4 className='font-bold text-white mb-5'>TABLE OF CONTENTS</h4>
                <ul>
                  <li className='mb-5'>
                    <a
                      className='inline-block px-6 text-lg font-medium text-yellowGreen-600'
                      href='#'
                    >
                      Introduction
                    </a>
                  </li>
                  <li className='mb-5'>
                    <a
                      className='inline-block px-6 text-lg font-medium text-gray-400 hover:text-yellowGreen-600'
                      href='#'
                    >
                      General content
                    </a>
                  </li>
                  <li className='mb-5'>
                    <a
                      className='inline-block px-6 text-lg font-medium text-gray-400 hover:text-yellowGreen-600'
                      href='#'
                    >
                      Additional reading
                    </a>
                  </li>
                  <li className='mb-5'>
                    <a
                      className='inline-block px-6 text-lg font-medium text-gray-400 hover:text-yellowGreen-600'
                      href='#'
                    >
                      Conclusion
                    </a>
                  </li>
                </ul>
              </div>
              <div className='w-full lg:w-2/3 px-4'>
                <div className='mb-12 pb-12 border-b border-gray-700'>
                  <img
                    className='block mb-16 w-full object-cover rounded-xl'
                    src='aurora-assets/content/folders-dark.png'
                    alt=''
                  />
                  <div className='mb-12'>
                    <h4 className='text-3xl text-white font-bold mb-6'>
                      Introduction
                    </h4>
                    <p className='text-lg text-gray-400 mb-6'>
                      As we come to the end of our journey in building
                      AI-Collections, we reflect on the incredible experience
                      and the technological advancements that have shaped this
                      groundbreaking project. From its inception, our goal was
                      to create a sophisticated system that leverages the power
                      of artificial intelligence to curate and organize digital
                      collections seamlessly. The journey involved a dedicated
                      team of engineers, data scientists, and designers who
                      collaborated tirelessly to bring this vision to life.
                      We'll delve into the challenges we faced, the technologies
                      we utilized, and the exciting potential AI-Collections
                      holds for the future.
                    </p>
                    <span className='block text-lg text-gray-400'>
                      1. Defining the Vision
                    </span>
                    <span className='block text-lg text-gray-400'>
                      2. Team Collaboration
                    </span>
                    <span className='block text-lg text-gray-400'>
                      3. Data Acquisition and Management
                    </span>
                  </div>
                  <div className='mb-12'>
                    <h4 className='text-3xl text-white font-bold mb-6'>
                      General content
                    </h4>
                    <p className='text-lg text-gray-400 mb-6'>
                      The development process was marked by countless hours of
                      research, prototyping, and iteration. We explored various
                      machine learning algorithms and deep neural network
                      architectures to ensure optimal performance and accuracy
                      in content classification. Through rigorous testing and
                      fine-tuning, we trained our AI model to recognize
                      patterns, categorize assets, and recommend relevant
                      collections with impressive precision.
                    </p>
                    <div className='flex mb-6'>
                      <span className='block mr-2 text-lg text-gray-400'>
                        1.
                      </span>
                      <div>
                        <p>
                          <span className='inline-block mr-1 text-lg text-white font-bold'>
                            Defining the Vision:
                          </span>
                          <span className='text-lg text-gray-400'>
                            We begin by discussing the initial vision behind
                            AI-Collections and the objectives we aimed to
                            achieve. We highlight the need for a sophisticated
                            content curation system that leverages AI
                            capabilities to enhance user experiences and
                            streamline information discovery.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className='flex mb-6'>
                      <span className='block mr-2 text-lg text-gray-400'>
                        2.
                      </span>
                      <div>
                        <p>
                          <span className='inline-block mr-1 text-lg text-white font-bold'>
                            Team Collaboration:
                          </span>
                          <span className='text-lg text-gray-400'>
                            Successful projects require effective teamwork. We
                            emphasize the importance of collaboration and detail
                            how our team of experts, including developers, data
                            scientists, and designers, worked together to bring
                            AI-Collections to life. We share insights on how we
                            fostered a creative and innovative environment
                            throughout the development process.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className='flex'>
                      <span className='block mr-2 text-lg text-gray-400'>
                        3.
                      </span>
                      <div>
                        <p>
                          <span className='inline-block mr-1 text-lg text-white font-bold'>
                            Data Acquisition and Management:
                          </span>
                          <span className='text-lg text-gray-400'>
                            Content demands a reliable and extensive dataset. We
                            provide an overview of the strategies we employed to
                            gather and manage the data required for
                            AI-Collections. This includes sourcing relevant
                            content, data cleaning, and ensuring data quality
                            and diversity.
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='mb-12'>
                    <h4 className='text-3xl text-white font-bold mb-6'>
                      Additional reading
                    </h4>
                    <p className='text-lg text-gray-400 mb-6'>
                      Design played a crucial role in the development of
                      AI-Collections. We wanted to create an intuitive user
                      interface that empowers users to effortlessly manage their
                      digital assets. Our design team conducted extensive user
                      research and feedback sessions to understand the pain
                      points and needs of content creators. The result is a
                      sleek, user-friendly interface that allows for seamless
                      navigation, quick bookmarking, and efficient organization
                      of assets.
                    </p>
                    <p className='text-lg text-gray-400'>
                      Throughout the process, we prioritized data privacy and
                      security. We implemented robust encryption protocols and
                      strict access controls to ensure that sensitive user
                      information remains protected at all times. Building trust
                      with our users has been of utmost importance, and we are
                      committed to maintaining a secure environment for their
                      valuable assets.
                    </p>
                  </div>
                  <div className='mb-12'>
                    <h4 className='text-3xl text-white font-bold mb-6'>
                      Conclusion
                    </h4>
                    <p className='text-lg text-gray-400'>
                      As we wrap up the development phase, we're excited about
                      the potential AI-Collections holds for content creators,
                      marketers, and businesses alike. Our AI-powered solution
                      brings a new level of efficiency and productivity to
                      digital asset management, freeing up valuable time and
                      resources for creativity and innovation. We're grateful
                      for the opportunity to contribute to the ever-evolving
                      landscape of AI technology, and we look forward to seeing
                      how AI-Collections transforms the way assets are curated
                      and organized in the digital world.
                    </p>
                  </div>
                  <div className='py-4 px-6 border border-gray-800 bg-gray-800 rounded-lg'>
                    <span className='inline-block mr-2 text-lg text-gray-300'>
                      Interested in more tips like this?
                    </span>
                    <a
                      className='group inline-flex items-center text-base font-bold text-yellowGreen-600 hover:text-yellowGreen-400 transition duration-100'
                      href='#'
                    >
                      <span className='mr-2'>Subscribe to our newsletter</span>
                      <span className='transform group-hover:translate-x-1 transition duration-100'>
                        <svg
                          width={20}
                          height={20}
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.99984 10H15.4165M15.4165 10L10.4165 5M15.4165 10L10.4165 15'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <div className='flex items-center justify-center'>
                  <a
                    className='group inline-flex mr-12 items-center text-white hover:text-yellowGreen-600'
                    href='#'
                  >
                    <div className='flex items-center justify-center h-16 w-16 bg-gray-800 rounded-full'>
                      <svg
                        width={25}
                        height={24}
                        viewBox='0 0 25 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M16.9724 20H4.6C4.26863 20 4 19.7314 4 19.4V9.6C4 9.26863 4.26863 9 4.6 9H7.36762C8.07015 9 8.72116 8.6314 9.0826 8.02899L11.793 3.51161C12.3779 2.53688 13.7554 2.44422 14.4655 3.33186C14.8002 3.75025 14.9081 4.30635 14.7541 4.81956L13.7317 8.22759C13.6162 8.61256 13.9045 9 14.3064 9H18.8815C20.2002 9 21.158 10.254 20.811 11.5262L18.9019 18.5262C18.6646 19.3964 17.8743 20 16.9724 20Z'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                        />
                        <path
                          d='M7.5 20L7.5 9'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                    <span className='ml-3'>256</span>
                  </a>
                  <a
                    className='group inline-flex items-center text-white hover:text-yellowGreen-600'
                    href='#'
                  >
                    <div className='flex items-center justify-center h-16 w-16 bg-gray-800 rounded-full'>
                      <svg
                        width={25}
                        height={24}
                        viewBox='0 0 25 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 13.8214 2.98697 15.5291 3.83782 17L3 21.5L7.5 20.6622C8.97087 21.513 10.6786 22 12.5 22Z'
                          stroke='currentColor'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                    <span className='ml-3'>10</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    <FooterComponent /></React.Fragment>
  );
}

